import moment from "moment";
import { User as WpUser } from ".";
import { Account, User as NsUser } from "../northstar";

enum Status {
    Pending = "pending",
    Sent = "sent",
    Accepted = "accepted",
    Declined = "declined",
    Cancelled = "cancelled",
}

export class OrganizationInvitation {
    static Status = Status;

    public static isOpen(invitation: Pick<OrganizationInvitation, "status" | "createdDate" | "expiresAt">) {
        return (
            (invitation.status === Status.Pending || invitation.status === Status.Sent) &&
            moment().isSameOrBefore(moment(invitation.expiresAt * 1000), "seconds")
        );
    }

    public static isClosed(invitation: Pick<OrganizationInvitation, "status" | "createdDate" | "expiresAt">) {
        return !OrganizationInvitation.isOpen(invitation);
    }

    id: string;
    userEmail: string;
    accountId: string;
    createdDate: string | Date;
    createdByNsUserId: string;
    createdByWpUserId: string;
    status: Status;
    joinAsAdmin: boolean;
    // seconds since epoch
    expiresAt: number;

    createdByWpUser: WpUser;
    createdByNsUser: NsUser;
    account: Account;

    // virtual, calculated based on expiresAt
    expired: boolean;
    // virtual, calculated based environment and id
    link: string;
}

export interface SendInvitationBody {
    accountId: string;
    email: string;
    doNotSend?: boolean; 
    joinAsAdmin?: boolean;
}