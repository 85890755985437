import type { AnnotationOptions } from "chartjs-plugin-annotation";
import React from "react";
import { Bar } from "react-chartjs-2";

import type { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";

import { config } from "../ChartConfig";
import { useThresholds } from "../Hooks/useThresholds";

const ANNOTATION_POSITION_BY_ORDER_NUMBER: Pick<AnnotationOptions, "xMin" | "xMax">[] = [
    { xMin: undefined, xMax: 0.5 },
    { xMin: 0.5, xMax: 1.5 },
    { xMin: 1.5, xMax: 2.5 },
    { xMin: 2.5, xMax: 3.5 },
    { xMin: 3.5, xMax: 4.5 },
    { xMin: 4.5, xMax: 5.5 },
    { xMin: 5.5, xMax: 6.5 },
];

export function CurrentScoreChart({ motorCarrier }: { motorCarrier: MotorCarrier }): JSX.Element {
    const { latest } = motorCarrier.smsResults;
    const labels = useLabels({ motorCarrier, includeScore: false, useAbbreviation: true });
    const annotations = useAnnotations({ motorCarrier });
    return (
        <Bar
            className="ia-transition-element aspect-video w-full max-w-[600px]"
            width={"100%"}
            data={{
                labels,
                datasets: [
                    {
                        // label: "SMS Score",
                        data: config.order.map((basic) => latest.getBasic(basic).score /** || 2 */),
                        backgroundColor: config.order.map((basic) => config.colors[basic]),
                    },
                ],
            }}
            options={{
                maintainAspectRatio: false,
                scales: {
                    y: {
                        suggestedMin: 0,
                        beginAtZero: config.chart.bar.axis.y.beginAtZero,
                        min: config.chart.bar.axis.y.min,
                        max: config.chart.bar.axis.y.max,
                        ticks: {
                            format: {
                                style: "unit",
                                unit: "percent",
                            },
                            stepSize: 10,
                            autoSkip: false,
                            color: config.chart.bar.labels.font.color,
                        },
                        grid: config.chart.bar.grid,
                    },
                    x: {
                        grid: config.chart.bar.grid,
                        ticks: {
                            color: config.chart.bar.labels.font.color,
                        },
                    },
                },
                plugins: {
                    annotation: {
                        annotations: config.order.map((basic) => annotations.get(basic)) as AnnotationOptions[],
                    },
                    legend: {
                        display: false, // config.chart.bar.legend.display,
                        labels: {
                            font: {
                                size: config.chart.bar.labels.font.size,
                            },
                        },
                    },
                },
            }}
        />
    );
}

function useLabels({
    motorCarrier,
    includeScore,
    useAbbreviation,
}: {
    motorCarrier: MotorCarrier;
    includeScore?: boolean;
    useAbbreviation?: boolean;
}): string[] {
    return React.useMemo(() => {
        const { latest } = motorCarrier.smsResults;

        const labels: string[] = [];

        config.order
            .map((basic) => ({
                basic,
                score: latest.getBasic(basic).score,
            }))
            .forEach(({ basic, score }) => {
                const label: (string | number)[] = [];
                if (useAbbreviation) {
                    label.push(FMCSA.BasicName.getAbbreviation(basic));
                } else {
                    label.push(basic);
                }
                if (includeScore) {
                    label.push("-");
                    if (!score || isNaN(score)) {
                        label.push("n/a");
                    } else {
                        label.push(score as number);
                    }
                }
                labels.push(label.join(" "));
            });

        return labels;
    }, [motorCarrier.smsResults, useAbbreviation, includeScore]);
}

/**
 * Generates the threshold line annotations
 */
function useAnnotations({ motorCarrier }: { motorCarrier: MotorCarrier }): Map<FMCSA.BasicName, AnnotationOptions> {
    const thresholds = useThresholds({ motorCarrier });

    return React.useMemo(() => {
        const { latest } = motorCarrier.smsResults;
        const annotationMap = new Map<FMCSA.BasicName, AnnotationOptions>();

        config.order.forEach((basic, orderNumber) => {
            annotationMap.set(basic, {
                type: "line",
                drawTime: "afterDatasetsDraw",
                ...ANNOTATION_POSITION_BY_ORDER_NUMBER[orderNumber],
                yMax: thresholds.get(basic),
                yMin: thresholds.get(basic),
                display: true,
                borderColor: latest.getBasic(basic).isScoreAboveThreshold ? "red" : "rgba(0,0,0,0.5)",
                borderWidth: 3,
                // label: {
                //     content: thresholds.get(basic)?.toString() || "",
                //     display: false,
                //     position: "center",
                //     backgroundColor: "rgba(0,0,0,0.2)",
                //     color: "black",
                // },
            });
        });

        return annotationMap;
    }, [motorCarrier.smsResults, thresholds]);
}
