import { ButtonHTMLAttributes, ClassAttributes, forwardRef } from "react";
import { classNames } from "../classNames/classNames";

export type ActionButtonProps = ClassAttributes<HTMLButtonElement> &
    ButtonHTMLAttributes<HTMLButtonElement> & {
        primary?: boolean;
    };

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(({ className, primary, ...props }, ref) => {
    return (
        <button
            className={classNames(
                "flex items-center gap-2 rounded border border-stone-300 bg-white px-2 py-1 text-sm font-medium text-stone-600 transition-all focus:border-transparent focus:ring-2 focus:ring-current disabled:opacity-60 enabled:hover:border-navigator-blue enabled:hover:text-navigator-primary",
                className,
                {
                    "border-none !bg-blue-700 text-white active:!shadow-none enabled:hover:!bg-blue-800 enabled:hover:!text-white": primary,
                }
            )}
            type="button"
            {...props}
            ref={ref}
        />
    );
});
