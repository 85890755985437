import environment from "../../environment";
import { auth0 } from "./auth0TokenExposer";
import {
    BadRequestError,
    ConnectionError,
    ContentTooLargeError,
    NoSubscriptionError,
    NotFoundError,
    ResponseError,
    ServerUnavailableError,
    ThrottleError,
    UnauthenticatedError,
    UnauthorizedError,
} from "./exceptions";

export const blazar = {
    getBaseUrl() {
        return environment.blazar.url;
    },
    async fetch(...[url, options]: Parameters<typeof fetch>) {
        const headers = new Headers(options?.headers);
        const token = await auth0.getAccessToken();
        if (token) {
            headers.append("Authorization", `Bearer ${token}`);
            const response = await fetch(blazar.getBaseUrl() + "api" + (url.toString().startsWith("/") ? "" : "/") + url, {
                ...options,
                headers,
            }).catch(() => {
                throw new ConnectionError();
            });
            if (response.status >= 400) {
                switch (response.status) {
                    case 400: {
                        let message;
                        try {
                            message = (await response.json()).message;
                        } catch {
                            // noop
                        }
                        throw new BadRequestError(message);
                    }
                    case 401: {
                        throw new UnauthenticatedError();
                    }
                    case 403: {
                        let message;
                        try {
                            message = (await response.json()).message;
                        } catch {
                            // noop
                        }
                        if (message === "Subscription required") {
                            throw new NoSubscriptionError();
                        }
                        throw new UnauthorizedError();
                    }
                    case 404: {
                        throw new NotFoundError("Not found");
                    }
                    case 413: {
                        throw new ContentTooLargeError("File too large");
                    }
                    case 429: {
                        throw new ThrottleError(response);
                    }
                    case 503: {
                        throw new ServerUnavailableError();
                    }
                    default: {
                        let message = response.statusText;
                        try {
                            message = await response.text();
                        } catch {
                            // noop
                        }
                        throw new ResponseError(response.status, message);
                    }
                }
            } else {
                return response;
            }
        }
        throw new UnauthenticatedError();
    },

    async fetchJson<DataType>(...[url, options]: Parameters<typeof fetch>): Promise<DataType> {
        const headers = new Headers(options?.headers);
        headers.set("Content-Type", "application/json");
        const response = await blazar.fetch(url, { ...options, headers });
        if ((await response.clone().arrayBuffer()).byteLength === 0) {
            return undefined as DataType;
        }
        return await response.json();
    },

    reISO: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/,

    changeDateStringsToDateObjects<T>(obj: T): T {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newObject: any = Array.isArray(obj) ? [] : {};
        for (const key in obj) {
            const value = obj[key];
            if (value && typeof value === "string") {
                const a = blazar.reISO.exec(value);
                if (a) {
                    newObject[key] = new Date(value);
                } else {
                    newObject[key] = value;
                }
            } else if (value && typeof value === "object") {
                newObject[key] = blazar.changeDateStringsToDateObjects(obj[key]);
            } else {
                newObject[key] = value;
            }
        }
        return newObject;
    },
};
