import { OrganizationInvitation } from "@deathstar/types/waypoint";
import { ActionButton, classNames, DataTable, Popover } from "@deathstar/ui";
import { CheckIcon, EllipsisVerticalIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../api/api";
import { queryClient } from "../../../util/queryClient";
import InviteCodeInput from "../../invites/InviteCodeInput";

const columns: ColumnDef<OrganizationInvitation>[] = [
    {
        id: "mobile",
        header: () => null,
        cell: ({ row }) => (
            <Popover>
                <Popover.Button className="flex w-full items-center justify-between">
                    <div className="text-left">
                        <p>{row.original.account.company.name}</p>
                        <p className="text-stone-600">
                            {"Received "}
                            {moment(row.original.createdDate).fromNow()}
                        </p>
                    </div>
                    <EllipsisVerticalIcon className="h-4 w-4 text-stone-500" />
                </Popover.Button>
                <Popover.Content className="w-max space-y-2">
                    <Popover.Item
                        className="text-left"
                        onClick={async () => {
                            await api.invitations.accept(row.original.id);
                            queryClient.invalidateQueries({ queryKey: api.user.queryKeys.me() });
                        }}
                        disabled={
                            row.original.expired ||
                            (row.original.status !== OrganizationInvitation.Status.Pending &&
                                row.original.status !== OrganizationInvitation.Status.Sent)
                        }
                    >
                        <CheckIcon className="h-4 w-4 text-emerald-600" />
                        Accept invitation
                    </Popover.Item>
                    <hr />
                    <Popover.Item
                        className="text-left"
                        onClick={async () => {
                            await api.invitations.decline(row.original.id);
                            queryClient.invalidateQueries({ queryKey: api.user.queryKeys.me() });
                        }}
                        disabled={
                            row.original.expired ||
                            (row.original.status !== OrganizationInvitation.Status.Pending &&
                                row.original.status !== OrganizationInvitation.Status.Sent)
                        }
                    >
                        <XMarkIcon className="h-4 w-4 text-red-600" />
                        Decline invitation
                    </Popover.Item>
                </Popover.Content>
            </Popover>
        ),
        meta: { responsive: true, classes: { header: "hidden" } },
    },
    {
        id: "createdDate",
        accessorKey: "createdDate",
        header: () => null,
        cell: ({ getValue }) => <div className="text-gray-500">{moment(getValue() as Date).fromNow()}</div>,
    },
    {
        id: "accountName",
        accessorFn: (row) => row.account.company.name,
        header: () => null,
    },
    {
        id: "accept",
        header: () => null,
        cell: ({ row }) => (
            <div className="flex h-[30px] justify-end">
                {!row.original.expired &&
                (row.original.status === OrganizationInvitation.Status.Pending ||
                    row.original.status === OrganizationInvitation.Status.Sent) ? (
                    <ActionButton
                        className="border-waypoint-blue text-waypoint-blue hover:!border-waypoint-blue-dark hover:bg-waypoint-blue-light/10"
                        onClick={async () => {
                            await api.invitations.accept(row.original.id);
                            queryClient.invalidateQueries({ queryKey: api.user.queryKeys.me() });
                        }}
                    >
                        Join
                    </ActionButton>
                ) : null}
            </div>
        ),
    },
    {
        id: "decline",
        header: () => null,
        cell: ({ row }) => (
            <div className="flex h-[30px] justify-end">
                {!row.original.expired &&
                (row.original.status === OrganizationInvitation.Status.Pending ||
                    row.original.status === OrganizationInvitation.Status.Sent) ? (
                    <button
                        className="text-stone-500 hover:text-red-600"
                        onClick={async () => {
                            await api.invitations.decline(row.original.id);
                            queryClient.invalidateQueries({ queryKey: api.user.queryKeys.me() });
                        }}
                    >
                        <TrashIcon className="h-5 w-5" />
                    </button>
                ) : null}
            </div>
        ),
    },
];

export default function MyInvites({ className }: { className?: string }) {
    const { data: user } = api.user.useMe();

    const data = useMemo(() => user?.invitations ?? [], [user]);
    const navigate = useNavigate();

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className={classNames("flex flex-col items-end gap-8 md:w-max md:min-w-[600px]", className)}>
            <InviteCodeInput
                onAccept={(code) => {
                    navigate(`/sol/${code.accountId}`);
                }}
            />
            <DataTable
                table={table}
                disableSearch
                className="w-full col-spacing-8"
                noDataLabel="Your organization invites will appear here after someone sends you one!"
            />
        </div>
    );
}
