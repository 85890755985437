import { Ams360User } from "./Ams360User";
import { GeneralLedgerEntity } from "./GeneralLedgerEntity";
import { Notation } from "./Notation";
import { RelationshipValue } from "./RelationshipValue";

export class Client {
    id: string;
    number: number;
    accountId: string;
    name?: string;
    isActive?: boolean;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    formalSalutation?: string;
    informalSalutation?: string;
    dba?: string;
    accountManager?: Ams360User;
    agent?: Ams360User;
    type?: Client.Type;
    generalLedgerDivision?: GeneralLedgerEntity;
    generalLedgerBranch?: GeneralLedgerEntity;
    generalLedgerDepartment?: GeneralLedgerEntity;
    generalLedgerGroup?: GeneralLedgerEntity;
    billName?: string;
    email?: string;
    email2?: string;
    address1?: string;
    address2?: string;
    city?: string;
    county?: string;
    state?: string;
    zipCode?: string;
    billAddress1?: string;
    billAddress2?: string;
    billCity?: string;
    billCounty?: string;
    billState?: string;
    billZipCode?: string;
    autoApplyPay?: string;
    brokerCode?: string;
    businessEntity?: string;
    businessAreaCode?: string;
    businessPhone?: string;
    businessExtension?: string;
    cellAreaCode?: string;
    cellPhone?: string;
    cellExtension?: string;
    homeAreaCode?: string;
    homePhone?: string;
    homeExtension?: string;
    faxAreaCode?: string;
    faxPhone?: string;
    faxExtension?: string;
    otherAreaCode?: string;
    otherPhone?: string;
    otherExtension?: string;
    collectionLetter?: boolean;
    webAddress?: string;
    dateOfBirth?: Date;
    driversLicense?: string;
    dunsNo?: string;
    fein?: string;
    groupingOption?: string;
    knownSince?: string;
    inBusinessSince?: string;
    yearEmployed?: string;
    occupation?: string;
    businessOriginCode?: string;
    latitude?: number;
    longitude?: number;
    isPrintDirectBill?: boolean;
    isSecured?: boolean;
    maritalStatus?: Client.MaritalStatus;
    isDeriveAttrFlagsCust?: boolean;
    isExcludedFromPurgeDelete?: boolean;
    lateCharge?: boolean;
    naics?: string;
    sic?: string;
    ssn?: string;
    methodOfDistribution?: string;
    notation?: Notation;

    changedBy: Ams360User;
    notes?: string;
    glCode?: string;
    masterSubTrack?: string;
    masterSubType?: string;
    reportOption?: string;
    typeName?: string;
    dateCustomerAdded?: Date;
    isBillAddressSameAsClient: boolean;
    isBillNameSameAsClient: boolean;
    modifiedDate?: Date;
    accountBalance?: number;
    relationshipValue?: RelationshipValue;
}

export namespace Client {
    export enum Type {
        CLIENT = "Client",
        PROSPECT = "Propect",
        SUSPECT = "Suspect",
    }

    export enum MaritalStatus {
        MARRIED = "Married",
        SINGLE = "Single",
        OTHER = "Other",
    }
}
