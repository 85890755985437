import {
    getSubscriptionTierName,
    PaymentInterval,
    PaymentMethodInfo,
    ScheduledSubscription,
    Subscription,
    SubscriptionTier,
} from "@deathstar/types/waypoint";
import { classNames, PrimaryButton, useSnackbar } from "@deathstar/ui";
import { InformationCircleIcon, StarIcon } from "@heroicons/react/24/outline";
import { BoltIcon } from "@heroicons/react/24/solid";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import api from "../../../../api/api";
import { useAccountId } from "../../../../api/useAccountId";
import { UnauthorizedError } from "../../../../api/util/exceptions";
import { Dialog } from "../../../../components/dialog/Dialog";
import Unauthorized from "../../../../components/error-screens/Unauthorized";
import UncaughtException from "../../../../components/error-screens/UncaughtException";
import Loader from "../../../../components/loader/Loader";
import SimpleConfirmation from "../../../../components/simple-confirmation/SimpleConfirmation";
import { queryClient } from "../../../../util/queryClient";
import PlanHeader from "./PlanHeader";
import ProductIncluded from "./ProductIncluded";
import ProductUnavailable from "./ProductUnavailable";

export default function PricingTable() {
    const accountId = useAccountId();

    const { data: organization, error: organizationError } = api.organization.useById(accountId!);
    const { data: profile, error: profileError } = api.billing.useProfile(accountId!);
    const { data: subscription, isLoading: isLoadingSubscription, error: subscriptionError } = api.billing.useSubscription(accountId!);

    const { data: plans } = api.billing.usePlans(accountId!);

    const [paymentInterval, setPaymentInterval] = useState<PaymentInterval>(PaymentInterval.Yearly);
    const [managing, setManaging] = useState(false);
    const [defaultSubscriptionTier, setDefaultSubscriptionTier] = useState<SubscriptionTier>(SubscriptionTier.Enterprise);

    const { data: scheduledSubscription } = api.billing.useScheduledSubscription(accountId!);

    const refetchData = useCallback(() => {
        queryClient.refetchQueries({ queryKey: api.billing.queryKeys.subscription(accountId!) });
        queryClient.refetchQueries({ queryKey: api.organization.queryKeys.getById(accountId!) });
        queryClient.refetchQueries({ queryKey: api.billing.queryKeys.scheduledSubscription(accountId!) });
    }, [accountId]);

    useEffect(() => {
        if (subscription) {
            setPaymentInterval(subscription.interval);
        }
    }, [subscription]);

    const isLoading = !organization || !plans || !profile || isLoadingSubscription;

    const [spring] = useSpring(
        {
            from: { tableOpacity: 0, tbodyOpacity: 0, tbodyY: -20 },
            to: [
                { tableOpacity: 1, tbodyOpacity: 0, tbodyY: -20 },
                { tableOpacity: 1, tbodyOpacity: 1, tbodyY: 0 },
            ],
            pause: isLoading,
        },
        [isLoading]
    );

    if (organizationError) {
        if (organizationError instanceof UnauthorizedError) {
            return <Unauthorized />;
        }

        return <UncaughtException />;
    }

    if (subscriptionError) {
        if (subscriptionError instanceof UnauthorizedError) {
            return <Unauthorized />;
        }

        return <UncaughtException />;
    }

    if (profileError) {
        if (profileError instanceof UnauthorizedError) {
            return <Unauthorized />;
        }

        return <UncaughtException />;
    }

    if (isLoading) return <Loader />;

    return (
        <>
            <SubscriptionManagerDialog
                open={managing}
                onClose={() => setManaging(false)}
                subscription={subscription}
                scheduledSubscription={scheduledSubscription}
                defaultTier={defaultSubscriptionTier}
                defaultPaymentInterval={paymentInterval}
                paymentMethod={profile.paymentMethods.find((pm) => pm.isDefault) || profile.paymentMethods[0]}
            />
            <div className="my-4 flex items-center justify-between">
                <div>
                    {organization.activeSubscriptionTier && !subscription && (
                        <div>
                            <p className="text-waypoint-blue-dark">
                                <InformationCircleIcon className="-mt-1 mr-2 inline h-5 w-5 text-blue-400" />
                                Your <span className="font-bold">{getSubscriptionTierName(organization.activeSubscriptionTier)}</span>{" "}
                                subscription is provided to you free of charge by your agent
                            </p>
                        </div>
                    )}
                </div>
                <div className="flex w-max rounded-lg bg-stone-200 p-1">
                    <button
                        className={classNames(
                            "rounded-lg p-2 text-stone-600 transition-all",
                            paymentInterval === "month" && "bg-white text-waypoint-blue-dark shadow"
                        )}
                        onClick={() => setPaymentInterval(PaymentInterval.Monthly)}
                    >
                        Monthly
                    </button>
                    <button
                        className={classNames(
                            "rounded-lg p-2 text-stone-600 transition-all",
                            paymentInterval === "year" && "bg-white text-waypoint-blue-dark shadow"
                        )}
                        onClick={() => setPaymentInterval(PaymentInterval.Yearly)}
                    >
                        <div>
                            Yearly
                            <p className="text-xs leading-none text-pink-600">save 24%</p>
                        </div>
                    </button>
                </div>
            </div>
            <animated.table style={{ opacity: spring.tableOpacity }} className="border-separate col-spacing-4 row-p-4 row-spacing-2">
                <colgroup>
                    <col></col>
                    <col
                        className={
                            organization.activeSubscriptionTier === SubscriptionTier.Standard
                                ? "rounded-lg bg-waypoint-orange bg-opacity-[7%]"
                                : ""
                        }
                    ></col>
                    <col
                        className={
                            organization.activeSubscriptionTier === SubscriptionTier.Premium
                                ? "rounded-lg bg-waypoint-orange bg-opacity-[7%]"
                                : ""
                        }
                    ></col>
                    <col
                        className={
                            organization.activeSubscriptionTier === SubscriptionTier.Enterprise
                                ? "rounded-lg bg-waypoint-orange bg-opacity-[7%]"
                                : ""
                        }
                    ></col>
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th className="rounded-t-lg pt-4 align-top">
                            <PlanHeader
                                price={plans[paymentInterval][SubscriptionTier.Standard]}
                                activeSubscriptionTier={organization.activeSubscriptionTier}
                                subscription={subscription}
                                scheduledSubscription={scheduledSubscription}
                                classes={{
                                    card: "bg-waypoint-blue-light/20 text-stone-700",
                                    button: "!bg-waypoint-blue !text-white hover:!bg-waypoint-blue-dark",
                                }}
                                onManage={() => {
                                    setDefaultSubscriptionTier(SubscriptionTier.Standard);
                                    setManaging(true);
                                }}
                                onCancelScheduled={async () => {
                                    if (scheduledSubscription) {
                                        await api.billing.cancelScheduledSubscription(accountId!, scheduledSubscription.scheduleId);
                                        refetchData();
                                    }
                                }}
                            />
                        </th>
                        <th className="rounded-t-lg pt-4 align-top">
                            <PlanHeader
                                price={plans[paymentInterval][SubscriptionTier.Premium]}
                                activeSubscriptionTier={organization.activeSubscriptionTier}
                                subscription={subscription}
                                scheduledSubscription={scheduledSubscription}
                                classes={{
                                    card: "bg-waypoint-blue text-white",
                                    button: "!bg-white !text-waypoint-blue hover:!bg-stone-100",
                                }}
                                onManage={() => {
                                    setDefaultSubscriptionTier(SubscriptionTier.Premium);
                                    setManaging(true);
                                }}
                                onCancelScheduled={async () => {
                                    if (scheduledSubscription) {
                                        await api.billing.cancelScheduledSubscription(accountId!, scheduledSubscription.scheduleId);
                                        refetchData();
                                    }
                                }}
                            />
                        </th>
                        <th className="rounded-t-lg pt-4 align-top">
                            <PlanHeader
                                price={plans[paymentInterval][SubscriptionTier.Enterprise]}
                                activeSubscriptionTier={organization.activeSubscriptionTier}
                                subscription={subscription}
                                scheduledSubscription={scheduledSubscription}
                                classes={{
                                    card: "bg-gradient-to-tl from-waypoint-orange to-waypoint-blue text-white",
                                    button: "!bg-white !text-waypoint-blue hover:!bg-stone-100 !ring-waypoint-blue",
                                }}
                                onManage={() => {
                                    setDefaultSubscriptionTier(SubscriptionTier.Enterprise);
                                    setManaging(true);
                                }}
                                onCancelScheduled={async () => {
                                    if (scheduledSubscription) {
                                        await api.billing.cancelScheduledSubscription(accountId!, scheduledSubscription.scheduleId);
                                        refetchData();
                                    }
                                }}
                            />
                        </th>
                    </tr>
                </thead>
                <animated.tbody style={{ y: spring.tbodyY, opacity: spring.tbodyOpacity }} className="[&>tr:last-child>td]:rounded-b-lg">
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>DOT Analysis Suite</FeatureLabel>

                        <td>
                            <ProductIncluded />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>DataQ Violations</FeatureLabel>

                        <td>
                            <ProductIncluded />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>DataQ Drugs/Alcohol</FeatureLabel>

                        <td>
                            <ProductUnavailable />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>DataQ Crashes</FeatureLabel>

                        <td>
                            <ProductUnavailable />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Contract Review</FeatureLabel>

                        <td>
                            <ProductUnavailable />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Compliance questions</FeatureLabel>

                        <td>
                            <ProductUnavailable />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Legal questions</FeatureLabel>

                        <td>
                            <ProductUnavailable />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                        <td>
                            <ProductIncluded />
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>MCS-150 Processing</FeatureLabel>

                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Driver rewards program</FeatureLabel>

                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Conditional rating repair</FeatureLabel>

                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Mock DOT audit</FeatureLabel>

                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                    <tr className="text-stone-600 even:bg-stone-400/5">
                        <FeatureLabel>Driver file review</FeatureLabel>

                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductUnavailable />
                            </div>
                        </td>
                        <td>
                            <div className="px-4">
                                <ProductIncluded />
                            </div>
                        </td>
                    </tr>
                </animated.tbody>
            </animated.table>
        </>
    );
}

function FeatureLabel({ children }: { children: React.ReactNode }) {
    return <td className="whitespace-nowrap font-medium text-stone-900">{children}</td>;
}

function SubscriptionManagerDialog({
    open,
    onClose,
    subscription,
    scheduledSubscription,
    defaultTier,
    defaultPaymentInterval,
    paymentMethod,
}: {
    open: boolean;
    onClose(): void;
    defaultTier?: SubscriptionTier;
    subscription?: Subscription | null;
    scheduledSubscription?: ScheduledSubscription | null;
    defaultPaymentInterval: PaymentInterval;
    paymentMethod?: PaymentMethodInfo;
}) {
    const [subscribing, setSubscribing] = useState(false);
    const [unsubscribing, setUnsubscribing] = useState(false);
    const [paymentInterval, setPaymentInterval] = useState<PaymentInterval>(defaultPaymentInterval);
    const [selected, setSelected] = useState<SubscriptionTier | null>(defaultTier || null);

    const accountId = useAccountId();
    const { data: plans } = api.billing.usePlans(accountId!);
    const { data: activeSubscriptionTier } = api.organization.useById(accountId!, { select: (x) => x.activeSubscriptionTier });
    const { data: proration } = api.billing.useProrationAmount(accountId!, selected!, paymentInterval, {
        enabled: !!selected && !!subscription,
    });

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: api.billing.queryKeys.prorationAmount(accountId!) });
    }, [accountId, selected, paymentInterval, subscription]);

    useEffect(() => {
        if (open) {
            setPaymentInterval(defaultPaymentInterval);
        }
    }, [open, defaultPaymentInterval]);

    useEffect(() => {
        if (open && activeSubscriptionTier) {
            setSelected(activeSubscriptionTier);
        } else if (open && !activeSubscriptionTier && defaultTier) {
            setSelected(defaultTier);
        }
    }, [open, activeSubscriptionTier, defaultTier]);

    const subscribe = useMutation({
        mutationFn: async () => {
            if (selected) {
                const response = await api.billing.subscribe(accountId!, selected, paymentInterval);
                if (response && response.sessionUrl) {
                    window.location.href = response.sessionUrl;
                } else {
                    useSnackbar.add("Your subscription has been received but may take a few minutes to be resolved.", { variant: "info" });
                    setTimeout(() => {
                        queryClient.refetchQueries({ queryKey: api.billing.queryKeys.subscription(accountId!) });
                        queryClient.refetchQueries({ queryKey: api.organization.queryKeys.getById(accountId!) });
                        queryClient.refetchQueries({ queryKey: api.billing.queryKeys.scheduledSubscription(accountId!) });
                        setSubscribing(false);
                        onClose();
                    }, 1000);
                }
            }
        },
    });

    const unsubscribe = useMutation({
        mutationFn: async () => {
            await api.billing.unsubscribe(accountId!);
            useSnackbar.add("Your cancel request has been received but may take a few minutes to be resolved.", { variant: "info" });
            setTimeout(() => {
                queryClient.refetchQueries({ queryKey: api.billing.queryKeys.subscription(accountId!) });
                queryClient.refetchQueries({ queryKey: api.organization.queryKeys.getById(accountId!) });
                queryClient.refetchQueries({ queryKey: api.billing.queryKeys.scheduledSubscription(accountId!) });
                setUnsubscribing(false);
                onClose();
            }, 1000);
        },
    });

    const isDowngrade =
        selected &&
        subscription &&
        activeSubscriptionTier &&
        [SubscriptionTier.Standard, SubscriptionTier.Premium, SubscriptionTier.Enterprise].indexOf(selected) <
            [SubscriptionTier.Standard, SubscriptionTier.Premium, SubscriptionTier.Enterprise].indexOf(activeSubscriptionTier);

    return (
        <>
            <Dialog open={open && !subscribing && !unsubscribing} onClose={onClose}>
                {plans ? (
                    <div className="w-screen p-8 md:max-w-3xl">
                        <div className="flex w-max rounded-lg bg-stone-200 p-1">
                            <button
                                className={classNames(
                                    "rounded-lg px-2 text-stone-600 transition-all",
                                    paymentInterval === "month" && "bg-white text-waypoint-blue-dark shadow"
                                )}
                                disabled={!!scheduledSubscription || !!subscription?.cancelDate}
                                onClick={() => setPaymentInterval(PaymentInterval.Monthly)}
                            >
                                Monthly
                            </button>
                            <button
                                className={classNames(
                                    "rounded-lg px-2 text-stone-600 transition-all",
                                    paymentInterval === "year" && "bg-white text-waypoint-blue-dark shadow"
                                )}
                                disabled={!!scheduledSubscription || !!subscription?.cancelDate}
                                onClick={() => setPaymentInterval(PaymentInterval.Yearly)}
                            >
                                Yearly
                            </button>
                        </div>
                        <div className="flex w-full flex-col justify-between gap-12 md:flex-row">
                            <div>
                                <div className="flex items-start gap-4 rounded-lg p-2 has-[:checked]:bg-blue-50">
                                    <input
                                        disabled={!!scheduledSubscription || !!subscription?.cancelDate}
                                        id="plan_enterprise"
                                        type="radio"
                                        checked={selected === SubscriptionTier.Enterprise}
                                        onChange={() => setSelected(SubscriptionTier.Enterprise)}
                                        name="plan"
                                        value={SubscriptionTier.Enterprise}
                                        className="peer form-radio mt-2 h-4 w-4 outline-waypoint-orange checked:bg-waypoint-orange"
                                    />
                                    <label htmlFor="plan_enterprise" className="grow peer-checked:!opacity-100 peer-disabled:opacity-60">
                                        <div className="flex justify-between gap-4">
                                            <div>
                                                <div className="flex flex-col-reverse gap-2 md:flex-row md:items-center">
                                                    <p className="text-lg font-medium text-waypoint-blue-dark">
                                                        {getSubscriptionTierName(SubscriptionTier.Enterprise)}
                                                    </p>
                                                    {activeSubscriptionTier === SubscriptionTier.Enterprise && (
                                                        <p className="w-max rounded-full border bg-white px-1 py-0.5 text-xs">
                                                            Current plan
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <p>
                                                    <span className="align-super text-xs text-stone-500">$</span>
                                                    <span className="text-lg text-stone-700">
                                                        {plans[paymentInterval][SubscriptionTier.Enterprise].price.toLocaleString(
                                                            "en-us",
                                                            {}
                                                        )}
                                                    </span>
                                                    <span className="text-xs text-stone-500">
                                                        <span className="-tracking-widest">{" / "}</span>
                                                        {plans[paymentInterval][SubscriptionTier.Enterprise].interval === "month"
                                                            ? "mo"
                                                            : "yr"}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <ul className="space-y-1 text-sm text-stone-500">
                                            <li>
                                                Everything in the {getSubscriptionTierName(SubscriptionTier.Premium)} plan + extensive
                                                insight into your business' compliance and longevity
                                            </li>
                                            <li>
                                                <BoltIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-orange" /> Driver rewards
                                                program
                                            </li>
                                            <li>
                                                <BoltIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-orange" /> Conditional rating
                                                repair
                                            </li>
                                            <li>
                                                <BoltIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-orange" /> Mock DOT audit
                                            </li>
                                            <li>
                                                <BoltIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-orange" /> Driver file review
                                            </li>
                                            <li>
                                                <BoltIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-orange" /> And more...
                                            </li>
                                        </ul>
                                    </label>
                                </div>
                                <div className="flex items-start gap-4 rounded-lg p-2 has-[:checked]:bg-blue-50">
                                    <input
                                        disabled={!!scheduledSubscription || !!subscription?.cancelDate}
                                        id="plan_premium"
                                        type="radio"
                                        checked={selected === SubscriptionTier.Premium}
                                        onChange={() => setSelected(SubscriptionTier.Premium)}
                                        name="plan"
                                        value={SubscriptionTier.Premium}
                                        className="peer form-radio mt-2 h-4 w-4 outline-waypoint-orange checked:bg-waypoint-orange"
                                    />
                                    <label htmlFor="plan_premium" className="grow peer-checked:!opacity-100 peer-disabled:opacity-60">
                                        <div className="flex justify-between gap-4">
                                            <div>
                                                <div className="flex flex-col-reverse gap-2 md:flex-row md:items-center">
                                                    <p className="text-lg font-medium text-waypoint-blue-dark">
                                                        {getSubscriptionTierName(SubscriptionTier.Premium)}
                                                    </p>
                                                    {activeSubscriptionTier === SubscriptionTier.Premium && (
                                                        <p className="w-max rounded-full border bg-white px-1 py-0.5 text-xs">
                                                            Current plan
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <p>
                                                    <span className="align-super text-xs text-stone-500">$</span>
                                                    <span className="text-lg text-stone-700">
                                                        {plans[paymentInterval][SubscriptionTier.Premium].price.toLocaleString("en-us", {})}
                                                    </span>
                                                    <span className="text-xs text-stone-500">
                                                        <span className="-tracking-widest">{" / "}</span>
                                                        {plans[paymentInterval][SubscriptionTier.Premium].interval === "month"
                                                            ? "mo"
                                                            : "yr"}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <ul className="space-y-1 text-sm text-stone-500">
                                            <li>
                                                Everything in the {getSubscriptionTierName(SubscriptionTier.Standard)} plan + greater
                                                improvements to your safety scores
                                            </li>
                                            <li>
                                                <StarIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-blue" /> DataQ Drugs/Alcohol
                                            </li>
                                            <li>
                                                <StarIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-blue" /> DataQ Crashes
                                            </li>
                                            <li>
                                                <StarIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-blue" /> MCS-150 Processing
                                            </li>
                                        </ul>
                                    </label>
                                </div>
                                <div className="flex items-start gap-4 rounded-lg p-2 has-[:checked]:bg-blue-50">
                                    <input
                                        disabled={!!scheduledSubscription || !!subscription?.cancelDate}
                                        id="plan_standard"
                                        type="radio"
                                        checked={selected === SubscriptionTier.Standard}
                                        onChange={() => setSelected(SubscriptionTier.Standard)}
                                        name="plan"
                                        value={SubscriptionTier.Standard}
                                        className="peer form-radio mt-2 h-4 w-4 outline-waypoint-orange checked:bg-waypoint-orange"
                                    />
                                    <label htmlFor="plan_standard" className="grow peer-checked:!opacity-100 peer-disabled:opacity-60">
                                        <div className="flex justify-between gap-4">
                                            <div>
                                                <div className="flex flex-col-reverse gap-2 md:flex-row md:items-center">
                                                    <p className="text-lg font-medium text-waypoint-blue-dark">
                                                        {getSubscriptionTierName(SubscriptionTier.Standard)}
                                                    </p>
                                                    {activeSubscriptionTier === SubscriptionTier.Standard && (
                                                        <p className="w-max rounded-full border bg-white px-1 py-0.5 text-xs">
                                                            Current plan
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <p>
                                                    <span className="align-super text-xs text-stone-500">$</span>
                                                    <span className="text-lg text-stone-700">
                                                        {plans[paymentInterval][SubscriptionTier.Standard].price.toLocaleString(
                                                            "en-us",
                                                            {}
                                                        )}
                                                    </span>
                                                    <span className="text-xs text-stone-500">
                                                        <span className="-tracking-widest">{" / "}</span>
                                                        {plans[paymentInterval][SubscriptionTier.Standard].interval === "month"
                                                            ? "mo"
                                                            : "yr"}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <ul className="space-y-1 text-sm text-stone-500">
                                            <li>
                                                <StarIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-blue" /> DOT Analysis
                                            </li>
                                            <li>
                                                <StarIcon className="-mt-1 mr-1 inline h-3 w-3 text-waypoint-blue" /> DataQ Violations
                                            </li>
                                        </ul>
                                    </label>
                                </div>
                            </div>
                            <div className="flex flex-col justify-between gap-4">
                                {subscription?.cancelDate && activeSubscriptionTier === selected ? (
                                    <PrimaryButton
                                        onClick={() => {
                                            setSubscribing(true);
                                        }}
                                        className="w-36 whitespace-nowrap"
                                    >
                                        Reactivate
                                    </PrimaryButton>
                                ) : activeSubscriptionTier ? (
                                    <PrimaryButton
                                        disabled={!selected || activeSubscriptionTier === selected}
                                        onClick={() => {
                                            setSubscribing(true);
                                        }}
                                        className="w-36 whitespace-nowrap"
                                    >
                                        Change plan
                                    </PrimaryButton>
                                ) : (
                                    <PrimaryButton
                                        disabled={!selected}
                                        onClick={() => {
                                            setSubscribing(true);
                                        }}
                                        className="w-36 whitespace-nowrap"
                                    >
                                        Subscribe
                                    </PrimaryButton>
                                )}
                                {subscription && !subscription.cancelDate && !!activeSubscriptionTier && (
                                    <button
                                        className="whitespace-nowrap rounded border px-2 py-1 text-sm text-stone-800 disabled:opacity-50 enabled:hover:bg-stone-100"
                                        onClick={() => {
                                            setUnsubscribing(true);
                                        }}
                                    >
                                        Cancel subscription
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </Dialog>
            {plans && (
                <SimpleConfirmation
                    open={open && subscribing}
                    onConfirm={() => subscribe.mutateAsync()}
                    onClose={() => setSubscribing(false)}
                    title={`${selected ? getSubscriptionTierName(selected) : "New"} Subscription`}
                    confirmLabel={paymentMethod ? "Subscribe" : "Continue"}
                    description={
                        selected && subscribing ? (
                            <>
                                {paymentMethod && (
                                    <>
                                        <p>Are you sure you want to move to the {getSubscriptionTierName(selected)} plan?</p>
                                        <br />
                                    </>
                                )}

                                {isDowngrade && subscription ? (
                                    <p>
                                        Your current subscription will continue until {moment(subscription.periodEnd).format("MM/DD/YYYY")},
                                        then your chosen plan will activate.
                                    </p>
                                ) : subscription ? (
                                    <p>
                                        Your will be charged a prorated amount of{" "}
                                        {proration?.amount.toLocaleString("en-us", {
                                            style: "currency",
                                            currency: "usd",
                                            maximumFractionDigits: 2,
                                        })}{" "}
                                        to move to the {getSubscriptionTierName(selected)} plan.
                                    </p>
                                ) : paymentMethod ? (
                                    <p>
                                        Your default payment method ({paymentMethod.description}) will be charged{" "}
                                        {plans[paymentInterval][selected].price.toLocaleString("en-us", {
                                            style: "currency",
                                            currency: "usd",
                                            maximumFractionDigits: 0,
                                        })}
                                        .
                                    </p>
                                ) : (
                                    <p>You will be forwarded to a payment page to review and complete your purchase.</p>
                                )}
                            </>
                        ) : null
                    }
                />
            )}
            <SimpleConfirmation
                open={open && unsubscribing}
                onConfirm={() => unsubscribe.mutateAsync()}
                onClose={() => setUnsubscribing(false)}
                title="Cancel subscription"
                description={
                    <>
                        <p>Are you sure you want to cancel your subscription?</p>
                        <br />

                        <p>
                            Your access will continue until{" "}
                            {subscription?.periodEnd
                                ? moment(subscription.periodEnd).format("MMMM D, YYYY")
                                : "the end of the current billing period"}
                            .
                        </p>
                    </>
                }
            />
        </>
    );
}
