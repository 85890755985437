import { CertificateWithHolderCount, CoverageOption } from "@deathstar/types/northstar";
import { BuildingStorefrontIcon, CalendarIcon, CheckBadgeIcon, PlusCircleIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { Link, To } from "react-router-dom";

export default function CertificateListItem({ certificate, linkTo }: { certificate: CertificateWithHolderCount; linkTo: To }) {
    return (
        <li className="w-full max-w-sm overflow-hidden rounded-lg border text-sm transition-all hover:border-waypoint-orange hover:shadow">
            <Link to={linkTo}>
                <div className="h-max">
                    <div className="bg-stone-50 p-3">
                        <div className="grid grid-cols-2 justify-items-start gap-2">
                            <div className="flex w-full items-center gap-2">
                                <BuildingStorefrontIcon className="h-4 w-4 shrink-0 text-waypoint-orange" />
                                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                    {certificate.primaryFirstNamedInsured}
                                </span>
                            </div>
                            <div className="flex w-full items-center gap-2">
                                <CalendarIcon className="h-4 w-4 shrink-0 text-waypoint-orange" />
                                <span>
                                    {certificate.primaryEffectiveDate ? moment(certificate.primaryEffectiveDate).format("MM/DD/YY") : "?"} -{" "}
                                    {certificate.primaryExpirationDate ? moment(certificate.primaryExpirationDate).format("MM/DD/YY") : "?"}
                                </span>
                            </div>
                            <div className="flex items-center justify-end gap-2">
                                <PlusCircleIcon className="h-4 w-4 shrink-0 text-waypoint-orange" />
                                <span className="">Created on {moment(certificate.createDate).format("MM/DD/YY")}</span>
                            </div>

                            <div className="flex w-full items-center gap-2">
                                <UserGroupIcon className="h-4 w-4 shrink-0 text-waypoint-orange" />
                                <span>{certificate.holderCount || 0} holders</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="p-3">
                        <ul className="space-y-1">
                            {certificate.coverageOptionIds.map((id) => (
                                <li key={id}>
                                    <div className="flex w-full items-center gap-2">
                                        <CheckBadgeIcon className="h-4 w-4 shrink-0 text-waypoint-blue" />
                                        <span className="text-black">{CoverageOption.getMetadata(id).name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Link>
        </li>
    );
}
