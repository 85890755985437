import { FMCSA } from "@deathstar/types";

enum Colors {
    ORANGE = "#D13F20",
    YELLOW = "#FFA143",
    GREEN = "#668135",
    BROWN = "#924E1D",
    TAN = "#CCA87D",
    BLUE = "#1D3A74",
    PURPLE = "#7E3582",
    LIGHT_GREY = "#b4b4b4",
    GREY = "#8A8A8A",
    DARK_GREY = "#333",
    DARKER_GREY = "#111",
    BLACK = "#000",
}
export const config = {
    violationOrder: [
        FMCSA.BasicName.UNSAFE_DRIVING,
        FMCSA.BasicName.HOS,
        FMCSA.BasicName.CONTROLLED_SUBSTANCES,
        FMCSA.BasicName.VEHICLE_MAINTENANCE,
        FMCSA.BasicName.DRIVER_FITNESS,
        FMCSA.BasicName.HAZMAT,
    ],
    order: [
        FMCSA.BasicName.CRASH_INDICATOR,
        FMCSA.BasicName.UNSAFE_DRIVING,
        FMCSA.BasicName.HOS,
        FMCSA.BasicName.CONTROLLED_SUBSTANCES,
        FMCSA.BasicName.VEHICLE_MAINTENANCE,
        FMCSA.BasicName.DRIVER_FITNESS,
        FMCSA.BasicName.HAZMAT,
    ],
    colors: {
        [FMCSA.BasicName.DRIVER_FITNESS]: Colors.ORANGE,
        [FMCSA.BasicName.VEHICLE_MAINTENANCE]: Colors.YELLOW,
        [FMCSA.BasicName.HOS]: Colors.GREEN,
        [FMCSA.BasicName.UNSAFE_DRIVING]: Colors.BLUE,
        [FMCSA.BasicName.CONTROLLED_SUBSTANCES]: Colors.TAN,
        [FMCSA.BasicName.HAZMAT]: Colors.BROWN,
        [FMCSA.BasicName.CRASH_INDICATOR]: Colors.BLACK,
        uncategorized: Colors.PURPLE,
        lightGrey: Colors.LIGHT_GREY,
        grey: Colors.GREY,
        darkGrey: Colors.DARK_GREY,
        darkerGrey: Colors.DARKER_GREY,
    },
    chart: {
        doughnut: {
            width: 150,
            height: 150,
            legend: {
                display: true,
            },
        },
        line: {
            width: 725,
            height: 490,
            legend: {
                display: true,
            },
            lineWidth: 3,
            lineTension: 0,
            labels: {
                font: {
                    size: 20,
                    color: Colors.DARK_GREY,
                },
            },
            grid: {
                color: Colors.LIGHT_GREY,
            },
            axis: {
                y: {
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                },
            },
        },
        bar: {
            includeHazmat: false,
            width: 200,
            height: 150,
            legend: {
                display: true,
            },
            labels: {
                font: {
                    size: 30,
                    color: Colors.DARK_GREY,
                },
            },
            grid: {
                color: Colors.LIGHT_GREY,
            },
            axis: {
                y: {
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                },
            },
        },
    },
} as const;
