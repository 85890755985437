import { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";
import { ChartDataset } from "chart.js";
import { config } from "../ChartConfig";

export type IDataset = ChartDataset<"line", (number | null)[]>;

export function useHistoricalScoreData({ motorCarrier }: { motorCarrier: MotorCarrier }): IDataset[] {
    const basics = Array.from(motorCarrier.smsResults.sortByDate("ASC"));

    const datasets: ChartDataset<"line", (number | null)[]>[] = [
        {
            label: `${FMCSA.BasicName.HAZMAT} Alert`,
            data: basics.map((basic) => (basic.hazmat.alert ? basic.hazmat.score : null)),
            backgroundColor: "transparent",
            borderColor: "transparent",
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth + 2,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: "#C43148",
            pointStyle: "triangle",
            hoverBorderWidth: config.chart.line.lineWidth + 2,
        },
        {
            label: FMCSA.BasicName.HAZMAT,
            data: basics.map((basic) => (typeof basic.hazmat.score === "number" && basic.hazmat.score > 0 ? basic.hazmat.score : null)),
            backgroundColor: config.colors[FMCSA.BasicName.HAZMAT],
            borderColor: config.colors[FMCSA.BasicName.HAZMAT],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: config.colors[FMCSA.BasicName.HAZMAT],
            pointBorderColor: config.colors[FMCSA.BasicName.HAZMAT],
            pointStyle: false as unknown as string,
        },
        {
            label: `${FMCSA.BasicName.CONTROLLED_SUBSTANCES} Alert`,
            data: basics.map((basic) => (basic.controlledSubstances.alert ? basic.controlledSubstances.score : null)),
            backgroundColor: "transparent",
            borderColor: "transparent",
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth + 2,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: "#C43148",
            pointStyle: "triangle",
            hoverBorderWidth: config.chart.line.lineWidth + 2,
        },
        {
            label: FMCSA.BasicName.CONTROLLED_SUBSTANCES,
            data: basics.map((basic) =>
                typeof basic.controlledSubstances.score === "number" && basic.controlledSubstances.score > 0
                    ? basic.controlledSubstances.score
                    : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
            borderColor: config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
            pointBorderColor: config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
            pointStyle: false as unknown as string,
        },
        {
            label: `${FMCSA.BasicName.DRIVER_FITNESS} Alert`,
            data: basics.map((basic) => (basic.driverFitness.alert ? basic.driverFitness.score : null)),
            backgroundColor: "transparent",
            borderColor: "transparent",
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth + 2,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: "#C43148",
            pointStyle: "triangle",
            hoverBorderWidth: config.chart.line.lineWidth + 2,
        },
        {
            label: FMCSA.BasicName.DRIVER_FITNESS,
            data: basics.map((basic) =>
                typeof basic.driverFitness.score === "number" && basic.driverFitness.score > 0 ? basic.driverFitness.score : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.DRIVER_FITNESS],
            borderColor: config.colors[FMCSA.BasicName.DRIVER_FITNESS],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: config.colors[FMCSA.BasicName.DRIVER_FITNESS],
            pointBorderColor: config.colors[FMCSA.BasicName.DRIVER_FITNESS],
            pointStyle: false as unknown as string,
        },
        {
            label: `${FMCSA.BasicName.VEHICLE_MAINTENANCE} Alert`,
            data: basics.map((basic) => (basic.vehicleMaintenance.alert ? basic.vehicleMaintenance.score : null)),
            backgroundColor: "transparent",
            borderColor: "transparent",
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth + 2,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: "#C43148",
            pointStyle: "triangle",
            hoverBorderWidth: config.chart.line.lineWidth + 2,
        },
        {
            label: FMCSA.BasicName.VEHICLE_MAINTENANCE,
            data: basics.map((basic) =>
                typeof basic.vehicleMaintenance.score === "number" && basic.vehicleMaintenance.score > 0
                    ? basic.vehicleMaintenance.score
                    : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
            borderColor: config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
            pointBorderColor: config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
            pointStyle: false as unknown as string,
        },
        {
            label: `${FMCSA.BasicName.HOS} Alert`,
            data: basics.map((basic) => (basic.hoursOfService.alert ? basic.hoursOfService.score : null)),
            backgroundColor: "transparent",
            borderColor: "transparent",
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth + 2,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: "#C43148",
            pointStyle: "triangle",
            hoverBorderWidth: config.chart.line.lineWidth + 2,
        },
        {
            label: FMCSA.BasicName.HOS,
            data: basics.map((basic) =>
                typeof basic.hoursOfService.score === "number" && basic.hoursOfService.score > 0 ? basic.hoursOfService.score : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.HOS],
            borderColor: config.colors[FMCSA.BasicName.HOS],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: config.colors[FMCSA.BasicName.HOS],
            pointBorderColor: config.colors[FMCSA.BasicName.HOS],
            pointStyle: false as unknown as string,
        },
        {
            label: `${FMCSA.BasicName.UNSAFE_DRIVING} Alert`,
            data: basics.map((basic) => (basic.unsafeDriving.alert ? basic.unsafeDriving.score : null)),
            backgroundColor: "transparent",
            borderColor: "transparent",
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth + 2,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: "#C43148",
            pointStyle: "triangle",
            hoverBorderWidth: config.chart.line.lineWidth + 2,
        },
        {
            label: FMCSA.BasicName.UNSAFE_DRIVING,
            data: basics.map((basic) =>
                typeof basic.unsafeDriving.score === "number" && basic.unsafeDriving.score > 0 ? basic.unsafeDriving.score : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
            borderColor: config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
            pointBorderColor: config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
            pointStyle: false as unknown as string,
        },
        {
            label: `${FMCSA.BasicName.CRASH_INDICATOR} Alert`,
            data: basics.map((basic) => (basic.crashIndicator.alert ? basic.crashIndicator.score : null)),
            backgroundColor: "transparent",
            borderColor: "transparent",
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth + 2,
            pointBackgroundColor: "#C43148", // same as --danger-red CSS custom property as defined in the inspection_analysis_pdf.html
            pointBorderColor: "#C43148",
            pointStyle: "triangle",
            hoverBorderWidth: config.chart.line.lineWidth + 2,
        },
        {
            label: FMCSA.BasicName.CRASH_INDICATOR,
            data: basics.map((basic) =>
                typeof basic.crashIndicator.score === "number" && basic.crashIndicator.score > 0 ? basic.crashIndicator.score : null
            ),
            backgroundColor: config.colors[FMCSA.BasicName.CRASH_INDICATOR],
            borderColor: config.colors[FMCSA.BasicName.CRASH_INDICATOR],
            tension: config.chart.line.lineTension,
            borderWidth: config.chart.line.lineWidth,
            pointBackgroundColor: config.colors[FMCSA.BasicName.CRASH_INDICATOR],
            pointBorderColor: config.colors[FMCSA.BasicName.CRASH_INDICATOR],
            pointStyle: false as unknown as string,
        },
    ].filter((dataset) => {
        if (dataset.data.some((it) => (typeof it === "number" ? it > 0 : false))) {
            return dataset;
        }
        return null;
    });

    return datasets;
}
