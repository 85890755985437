import { FMCSA } from "@deathstar/types";
import { Inspection } from "../../Inspection/Inspection";
import { SafetyEventGroup } from "../../SafetyEventGroup/SafetyEventGroup";
import { Basic } from "../Basic";

export class VehicleMaintenance extends Basic<SafetyEventGroup.GroupNames.VehicleMaintenance> {
    readonly isBestAddressedRoadside = true;

    // ========================================================================
    static get thresholdDefinition(): Basic.IThresholdDefinition {
        return {
            passenger: 65,
            hazmat: 75,
            general: 80,
        };
    }

    // ========================================================================
    public constructor(options: VehicleMaintenance.Options) {
        super({
            ...options,
            safetyEventGroup: new SafetyEventGroup.Group.VehicleMaintenance(options.carrier, options.totalVehicleInspections),
            basicName: FMCSA.BasicName.VEHICLE_MAINTENANCE,
            relevantInspectionDefinition: {
                levels: [1, 2, 5, 6],
                type: Inspection.Type.VEHICLE,
            },
        });
    }

    // ========================================================================
    override getThresholdDefinition(): Basic.IThresholdDefinition {
        return VehicleMaintenance.thresholdDefinition;
    }

    // ========================================================================
    override getFmcsaCalculatedMeasure(): number | null {
        return typeof this.rawSmsData?.VehicleMaintenanceMeasure === "number" ? this.rawSmsData?.VehicleMaintenanceMeasure : null;
    }

    // ========================================================================
    override getVariableData(): Record<string, string | number> {
        return {
            totalViolations: this.getTotalNumberOfViolations(),
            totalWeightOfApplicableViolations: this.getTotalWeightOfApplicableViolations(),
            totalTimeWeightOfRelevantInspections: this.getTotalTimeWeightOfRelevantInspections(),
        };
    }

    // ========================================================================
    protected override getMeasure(): number {
        if (typeof this.rawSmsData?.VehicleMaintenanceMeasure === "number" && !this.forceCalculateBasicMeasures) {
            return this.rawSmsData?.VehicleMaintenanceMeasure;
        }

        return this.calculateMeasure();
    }

    // ========================================================================
    protected override async getScore(): Promise<number | null> {
        if (typeof this.rawSmsData?.VehicleMaintenanceScore === "number" && !this.forceCalculateBasicScores) {
            return this.rawSmsData?.VehicleMaintenanceScore;
        }

        return this.calculateScore();
    }

    // ========================================================================
    protected override async calculateScore(): Promise<number | null> {
        if (
            this.measure === 0 ||
            this.safetyEventGroup.number === 0 ||
            !this.safetyEventGroup.name ||
            this.smsResult.totalVehicleInspections < 5
        )
            return 0;

        /*
            Get the total number of Vehicle Maintenance violations
        */
        const totalVehicleMaintenanceViolations = this.getTotalNumberOfViolations();

        if (totalVehicleMaintenanceViolations === 0) return 0;

        /*
            Get the total number of inspections with a Vehicle Maintenance violation
        */
        const totalVehicleMaintenanceInspectionsWithViolation = this.getTotalInspectionsWithViolation();

        if (totalVehicleMaintenanceInspectionsWithViolation < Basic.getMinimumRelevant(FMCSA.BasicName.VEHICLE_MAINTENANCE)) return 0;

        /*
            Get the number of Vehicle Maintenance violations within the previous 12 months
        */
        const totalVehicleMaintenanceViolationsWithinPreviousYear = this.getTotalViolationsWithinYear();

        if (totalVehicleMaintenanceViolationsWithinPreviousYear === 0) {
            const latestRelevantVehicleInspectionIncludesViolation = this.getLatestRelevantInspectionIncludesViolation();

            if (!latestRelevantVehicleInspectionIncludesViolation) return 0;
        }

        return this.fetchSmsScore();
    }
}
export namespace VehicleMaintenance {
    export interface Options extends Basic.BaseOptions {
        totalVehicleInspections: number;
    }
}
