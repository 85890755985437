/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

const GLOBAL_CACHE = new WeakMap<any, Map<symbol, unknown>>();

type DecoratedFn = (...args: unknown[]) => unknown;

function createMemoizer(propertyKey: string, fn: DecoratedFn) {
    return function (this: any, ...args: unknown[]) {
        let map = GLOBAL_CACHE.get(this);
        if (!map) {
            map = new Map();
            GLOBAL_CACHE.set(this, map);
        }

        const id = Symbol.for(`${propertyKey}-${JSON.stringify(args)}`);

        const memoizedValue = map.get(id);
        if (memoizedValue) {
            return memoizedValue;
        }

        const value = fn.apply(this, args);
        map.set(id, value);
        return value;
    };
}

function createAsyncMemoizer(propertyKey: string, fn: DecoratedFn) {
    return async function (this: any, ...args: unknown[]) {
        let map = GLOBAL_CACHE.get(this);
        if (!map) {
            map = new Map();
            GLOBAL_CACHE.set(this, map);
        }

        const id = Symbol.for(`${propertyKey}-${JSON.stringify(args)}`);

        const memoizedValue = map.get(id);
        if (memoizedValue) {
            return memoizedValue;
        }

        const value = await fn.apply(this, args);
        map.set(id, value);
        return value;
    };
}

type MomoizeFn = (_target: unknown, propertyKey: string, descriptor: PropertyDescriptor) => void;

/**
 * Stores the results of a function call and returns the cached result when the same inputs occur again
 */
export function memoize(): MomoizeFn {
    return function (_target: unknown, propertyKey: string, descriptor: PropertyDescriptor) {
        if (descriptor.get) {
            descriptor.get = createMemoizer(propertyKey, descriptor.get);
        } else if (descriptor.value) {
            descriptor.value = createMemoizer(propertyKey, descriptor.value);
        } else {
            throw new Error("Only get accessor and methods can be memoized");
        }
    };
}

memoize.async = function memoizeAsync() {
    return function (_target: unknown, propertyKey: string, descriptor: PropertyDescriptor) {
        if (descriptor.get) {
            descriptor.get = createAsyncMemoizer(propertyKey, descriptor.get);
        } else if (descriptor.value) {
            descriptor.value = createAsyncMemoizer(propertyKey, descriptor.value);
        } else {
            throw new Error("Only get accessor and methods can be memoized");
        }
    };
};
