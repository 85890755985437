export class Customer {
    static Code = {
        Navigator: "!GP",
        Acuity: {
            Parent: "!!7",
            Writing: "!!9",
        },
        Northland: {
            Parent_CRC: "!MU",
            Parent_RT_Specialty: "!G$",
            Parent_JM_Wilson: "!G:",
            Parent_Truckers: "!H-",
            Parent_Erickson_Larsen: "!K\\",
        },
        AMWins: "!F)",
        CentralMutual: "!KD",
        GreatAmerican: "!7S",
        GreatAmericanInsuranceCompany: "!7T",
        GreatWest: "!H;",
        Lancer: {
            Parent_Sunfront: '!H"',
            Parent_Transcom: "!L9",
        },
        NationalInterstate: "!/0",
        Travelers: "!3F",
        Logistiq: "!GE",
    } as const;

    id: string;
    code: string;
    name?: string;
    type: Customer.Type;
    naic?: string;
    parentCompanyCode?: string;
    addresses: Customer.Address[];
}

export namespace Customer {
    export enum Type {
        BROKER = "Broker",
        WRITING = "Writing",
        INSURANCE = "Insurance",
        SUBSCRIPTION = "Subscription",
        UNKNOWN = "Unknown",
    }

    export class Address {
        id: string;
        description: string;
        address1?: string;
        address2?: string;
        city?: string;
        state?: string;
        country?: string;
        zipCode?: string;
        areaCode?: string;
        phone?: string;
        extensions?: string;
        email?: string;
        isPrimaryAddress: boolean;
    }
}
