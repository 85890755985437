import { Doughnut } from "react-chartjs-2";

import { EmptyDoughnutChart } from "./EmptyDoughnut";

interface IData {
    color: string;
    score: number;
}

export function ViolationBreakdown({ data }: { data: IData[] }): JSX.Element {
    if (data.every((it) => it.score === 0)) {
        return <EmptyDoughnutChart />;
    }

    return (
        <Doughnut
            className="h-full w-full"
            data={{
                datasets: [
                    {
                        label: "",
                        data: data.map((it) => it.score),
                        backgroundColor: data.map((it) => it.color),
                        borderWidth: calculateBorderWidth(data),
                    },
                ],
            }}
            options={{
                responsive: true,
                maintainAspectRatio: false,
            }}
        />
    );
}

/**
 * If there is more than one score that is greater than 0, the border width should be 40
 */
function calculateBorderWidth(data: IData[]): number {
    return data.filter((it) => it.score > 0).length > 1 ? 2 : 0;
}
