export interface Vehicle {
    year: number | null;
    make: string | null;
    vin: string | null;
}

export class Vehicle {
    static toString(v: Vehicle): string {
        const _parts: (number | string)[] = [];
        if (v.year) _parts.push(v.year);
        if (v.make) _parts.push(v.make);
        if (v.vin) _parts.push(v.vin);
        return _parts.join(" ");
    }
}

export namespace Vehicle {
    export type Parts = "year" | "make" | "vin";
}
