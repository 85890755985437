import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function FmcsaLogoIcon(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props} viewBox="0 0 145 147">
            <g transform="translate(0.000000,147.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                <path
                    d="M555 1431 c-38 -11 -88 -27 -110 -37 l-40 -18 95 -7 c113 -7 189 -34
259 -89 56 -44 83 -79 113 -147 19 -42 22 -62 18 -158 -6 -182 -4 -210 14
-256 22 -57 68 -104 131 -136 43 -22 58 -25 121 -21 155 10 244 113 244 283 0
97 -28 185 -89 276 -95 144 -215 237 -382 295 -103 36 -273 43 -374 15z"
                />
                <path
                    d="M371 1314 c-120 -36 -210 -125 -282 -278 -58 -123 -76 -318 -43 -455
27 -111 94 -238 169 -318 86 -94 176 -152 122 -81 -117 155 -119 378 -6 511
32 37 64 58 163 106 138 66 176 102 201 185 19 64 19 111 -1 170 -20 57 -85
128 -140 151 -47 19 -134 24 -183 9z"
                />
                <path
                    d="M1387 627 c-46 -81 -120 -150 -205 -190 -57 -27 -78 -31 -142 -31
-91 1 -144 22 -245 98 -107 81 -130 90 -210 90 -104 0 -187 -51 -230 -141 -25
-53 -26 -175 -1 -225 110 -225 492 -263 767 -77 118 80 230 229 272 364 18 56
39 166 33 172 -2 2 -20 -25 -39 -60z"
                />
            </g>
        </SvgIcon>
    );
}
