import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { MotorCarrier } from "../MotorCarrier";
import { BasicAssessmentTrend, BasicSubCategoryFrequencyTrend, Trend } from "./Trend";

export class Trends {
    #positive: Trend[] = [];
    #negative: Trend[] = [];
    #carrier: MotorCarrier;
    #dateRange: MotorCarrier.IDateRange;
    #options: Trends.Options;
    constructor(options: Trends.Options) {
        this.#carrier = options.carrier;
        this.#dateRange = options.dateRange;
        this.#options = options;

        this.#calculate();
    }

    #calculate(): void {
        this.#options.trends.basicAssessmentTrend && this.#calculateBasicAssessmentTrend();
        this.#options.trends.basicSubCategoryFrequencyTrend && this.#calculateBasicSubCategoryFrequencyTrend();
    }

    #calculateBasicAssessmentTrend(): void {
        FMCSA.BasicName.array()
            .filter((b) => {
                if (b === FMCSA.BasicName.CRASH_INDICATOR) return false;
                if (b === FMCSA.BasicName.HAZMAT && !this.#carrier.isHazmat) return false;
                return true;
            })
            .forEach((basic) => {
                const trend = new BasicAssessmentTrend({
                    carrier: this.#carrier,
                    basic: basic as FMCSA.BasicName,
                    dateRange: this.#dateRange,
                });

                if (trend.isValid()) {
                    this.#positive.push(trend);
                }
            });
    }

    #calculateBasicSubCategoryFrequencyTrend(): void {
        const breakdowns = this.#carrier.violations.getBreakdowns({
            numberOfHistoricalBreakdowns: DateTime.differenceInCalendarMonths(this.#dateRange.to, this.#dateRange.from),
        });
        const { unsafeDriving, vehicleMaintenance, hoursOfService, controlledSubstances, driverFitness, hazmat } = breakdowns.getLatest();

        const basics = [
            {
                start: unsafeDriving,
                end: unsafeDriving.getHistory().at(-1),
                basic: FMCSA.BasicName.UNSAFE_DRIVING,
            },
            {
                start: hoursOfService,
                end: hoursOfService.getHistory().at(-1),
                basic: FMCSA.BasicName.UNSAFE_DRIVING,
            },
            {
                start: controlledSubstances,
                end: controlledSubstances.getHistory().at(-1),
                basic: FMCSA.BasicName.CONTROLLED_SUBSTANCES,
            },
            {
                start: vehicleMaintenance,
                end: vehicleMaintenance.getHistory().at(-1),
                basic: FMCSA.BasicName.VEHICLE_MAINTENANCE,
            },
            {
                start: driverFitness,
                end: driverFitness.getHistory().at(-1),
                basic: FMCSA.BasicName.DRIVER_FITNESS,
            },
            {
                start: hazmat,
                end: hazmat.getHistory().at(-1),
                basic: FMCSA.BasicName.HAZMAT,
            },
        ];

        basics.forEach(({ start, end, basic }) => {
            if (!end) return;
            start.groups.slice(0, 3).forEach((grp) => {
                const startingScore = [...end.groups].find((g) => g.name === grp.name)?.score || 0;
                const endingScore = grp.score || 0;
                const trend = BasicSubCategoryFrequencyTrend.new({
                    carrier: this.#carrier,
                    basic: basic,
                    dateRange: this.#dateRange,
                    endingScore,
                    startingScore,
                    minTrendDifference: 10,
                    subCategoryName: grp.name,
                });
                if (trend) {
                    if (trend.type === "Positive") {
                        this.#positive.push(trend);
                    } else {
                        this.#negative.push(trend);
                    }
                }
            });
        });
    }

    get positive(): Trend[] {
        return this.#positive;
    }

    get negative(): Trend[] {
        return this.#negative;
    }
}

export namespace Trends {
    export interface Options {
        carrier: MotorCarrier;
        dateRange: MotorCarrier.IDateRange;
        trends: {
            basicAssessmentTrend: boolean;
            basicSubCategoryFrequencyTrend: boolean;
        };
    }
}
