import { useMemo } from "react";

interface FormatterResult {
    value: string;
    caret: number;
}

export class FormatTemplate {
    constructor(
        public readonly template: string,
        public readonly character = "x",
        public readonly getShouldAppend: (input: string, character: string) => boolean = () => true
    ) {}
}

export const PhoneTemplate = new FormatTemplate("(xxx) xxx-xxxx");
export const DollarTemplate = new FormatTemplate("$xxx,xxx,xxx,xxx,xxx,xxx,xxx", "x", () => false);

export class InputFormatter {
    public static format(input: string, caret: number | null, format: FormatTemplate): FormatterResult {
        if (!input) {
            return { value: "", caret: 0 };
        }
        caret = Math.max(Math.min(caret || 0, input.length), 0);
        let value = "";
        let caretPosition = 0;
        const inputArray = input.split("");

        for (let i = 0; i < format.template.length; i++) {
            if (format.template[i] === format.character) {
                if (inputArray.length) {
                    value += inputArray.shift();
                } else {
                    break;
                }
            } else {
                if (inputArray.length) {
                    value += format.template[i];
                } else if (format.getShouldAppend(value, format.template[i])) {
                    value += format.template[i];
                }
            }
        }

        const indices = format.template
            .split("")
            .map((c, i) => (c === format.character ? i : null))
            .filter((i) => i !== null) as number[];

        caretPosition = caret > indices.length - 1 ? value.length : Math.max(0, Math.min(indices[caret] || indices[0] || 0, value.length));

        return { value, caret: caretPosition };
    }

    public static parse(input: string, caret: number | null, format: FormatTemplate): FormatterResult {
        const regex = /[0-9]/;
        let value = "";
        let caretPosition = 0;

        for (let i = 0; i < format.template.length && i < input.length; i++) {
            if (regex.test(input[i])) {
                value += input[i];
                if (caret !== null) {
                    if (caret === i) {
                        caretPosition = value.length - 1;
                    } else if (caret > i) {
                        caretPosition = value.length;
                    }
                }
            }
        }

        return { value, caret: caretPosition };
    }

    /**
     * A hook to format an input value. Provides handlers to bind to an input element.
     * InputFormatter handles caret positioning automatically.
     *
     *
     * @param value The current raw (unformatted) value of the input
     * @param onChange An onChange handler to be called when the input changes. This will be called with a parsed value.
     * @param format A FormatTemplate to use for formatting the input
     * @returns
     */
    public static use(value: string, onChange: (value: string) => void, format: FormatTemplate) {
        const { value: formattedValue } = InputFormatter.format(value, null, format);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useMemo(
            () => ({
                value: formattedValue,
                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    const target = e.currentTarget;
                    if (e.key === "Delete") {
                        e.preventDefault();

                        const text = target.value.slice(0, target.selectionStart!) + target.value.slice(target.selectionEnd! + 1);
                        const parsed = InputFormatter.parse(text, target.selectionStart!, format);
                        if (value === parsed.value && target.selectionEnd! > 0) {
                            const indices = format.template
                                .split("")
                                .map((c, i) => (c === format.character ? i : null))
                                .filter((i) => i !== null)
                                .reverse() as number[];
                            if (!indices.includes(target.selectionStart!)) {
                                for (const index of indices) {
                                    if (index < target.selectionStart!) {
                                        setTimeout(() => {
                                            target.setSelectionRange(index + 1, index + 1);
                                        });
                                        break;
                                    }
                                }
                            }
                        } else {
                            onChange(parsed.value);
                            const formatted = InputFormatter.format(parsed.value, parsed.caret, format);
                            setTimeout(() => {
                                target.setSelectionRange(formatted.caret, formatted.caret);
                            });
                        }
                    }
                    if (e.key === "Backspace") {
                        e.preventDefault();

                        const selectionStart = Math.max(
                            0,
                            e.metaKey
                                ? 0
                                : target.selectionStart === target.selectionEnd
                                ? target.selectionStart! - 1
                                : target.selectionStart!
                        );

                        const text = target.value.slice(0, Math.max(0, selectionStart)) + target.value.slice(target.selectionEnd!);
                        const parsed = InputFormatter.parse(text, selectionStart, format);
                        if (value === parsed.value && target.selectionEnd! > 0) {
                            const indices = format.template
                                .split("")
                                .map((c, i) => (c === format.character ? i : null))
                                .filter((i) => i !== null)
                                .reverse() as number[];
                            if (!indices.includes(selectionStart)) {
                                for (const index of indices) {
                                    if (index < selectionStart) {
                                        setTimeout(() => {
                                            target.setSelectionRange(index + 1, index + 1);
                                        });
                                        break;
                                    }
                                }
                            }
                        } else {
                            onChange(parsed.value);
                            const formatted = InputFormatter.format(parsed.value, parsed.caret, format);
                            setTimeout(() => {
                                target.setSelectionRange(formatted.caret, formatted.caret);
                            });
                        }
                    }
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value, caret } = InputFormatter.parse(e.target.value, e.target.selectionStart, format);
                    const formatted = InputFormatter.format(value, caret, format);
                    setTimeout(() => {
                        e.target.setSelectionRange(formatted.caret, formatted.caret);
                    });
                    onChange(value);
                },
            }),
            [formattedValue, onChange, format, value]
        );
    }
}
