import { FMCSA } from "@deathstar/types";
import { classNames } from "@deathstar/ui";
import { Typography } from "@material-ui/core";

import React from "react";
import { StopLight, StopLightOption } from "../Components/StopLight";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";

export function IssScores({ className }: { className?: string }): JSX.Element | null {
    const { motorCarrier } = React.useContext(Context);
    const issResult = motorCarrier.issResults.latest;

    const inspectionRecommendationString = React.useMemo(
        () => getInspectionRecommendation(issResult.inspectionRecommendation),
        [issResult.inspectionRecommendation]
    );

    const options: StopLightOption[] = React.useMemo(() => {
        return [
            {
                label: issResult.score ? (
                    <div
                        className={classNames("flex items-center gap-2", {
                            "justify-left": !issResult.score,
                            invisible: issResult.inspectionRecommendation !== FMCSA.IssInspectionRecommendation.INSPECT,
                        })}
                    >
                        <Typography variant="h5">{issResult.score}</Typography>
                        <Typography
                            className={classNames({
                                "text-gray-400": typeof issResult.score === "number",
                            })}
                        >
                            {inspectionRecommendationString}
                        </Typography>
                    </div>
                ) : (
                    FMCSA.IssInspectionRecommendation.INSPECT
                ),
                selected: issResult.inspectionRecommendation === FMCSA.IssInspectionRecommendation.INSPECT,
                red: true,
                tooltip: issResult.reason,
            },
            {
                label: FMCSA.IssInspectionRecommendation.OPTIONAL,
                selected: issResult.inspectionRecommendation === FMCSA.IssInspectionRecommendation.OPTIONAL,
                yellow: true,
                tooltip: issResult.reason,
            },
            {
                label: FMCSA.IssInspectionRecommendation.PASS,
                selected: issResult.inspectionRecommendation === FMCSA.IssInspectionRecommendation.PASS,
                green: true,
                tooltip: issResult.reason,
            },
        ] as StopLightOption[];
    }, [inspectionRecommendationString, issResult.inspectionRecommendation, issResult.reason, issResult.score]);

    if (!issResult.algorithmUsed) return null;

    return (
        <div className={classNames("bg-inherit ", className)}>
            <TitleGroup title={<Typography variant="h5">ISS Score</Typography>} />
            <div className="flex flex-col py-4">
                <StopLight options={options} />
            </div>
        </div>
    );
}

function getInspectionRecommendation(inspectionRecommendation: FMCSA.IssInspectionRecommendation | null): string | null {
    if (!inspectionRecommendation) return null;
    if (inspectionRecommendation === FMCSA.IssInspectionRecommendation.OPTIONAL) return "Warning";
    return inspectionRecommendation;
}
