import type { Account } from "./Account";
import { CoverageLinkedTrailer } from "./CoverageLinkedTrailer";
import { CoverageOption } from "./CoverageOption";
import { Driver } from "./Driver";
import type { EquipmentType } from "./EquipmentType";
import { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";
import { Property } from "./Property";
import { AccountEquipment, AccountEquipmentStatus } from "./Tractor";
import { User } from "./User";
import { Vehicle } from "./Vehicle";

export class Trailer {
    id: number;

    createDate: Date;

    updateDate: Date;

    createdByGuid: string;

    createdBy: User;

    submit: boolean;

    isDeleted: boolean;

    accountId: string;

    year: number | null;

    make: string | null;

    typeId: EquipmentType["id"];

    type: EquipmentType;

    vin: string | null;

    acv: boolean;

    comprehensive: boolean;

    collision: boolean;

    value: number | null;

    ownerOperator: boolean;

    vinValid: number | null;

    spare: boolean;

    unitNumber: string | null;

    comments: string | null;

    requiresApproval: boolean;
    requestComment: string;
    requestDate: string;
    isRequestNotificationSent: boolean;
    createdByWaypointUserId: string;

    drivers?: Driver[];

    account: Account;

    coverageLinks?: CoverageLinkedTrailer[];
    additionalInterests?: PolicyAdditionalInterest[];

    propertyId: number;

    property?: Property;

    policyUpcomingDate?: string | null | undefined;
    policyValue?: number | null | undefined;
    policyOwnerOperator?: boolean | null | undefined;
    policySpare?: boolean | null | undefined;
    policyAcv?: boolean | null | undefined;
    policyComprehensive?: boolean | null | undefined;
    policyCollision?: boolean | null | undefined;
    statuses?: AccountEquipmentStatus[];
    coverageOptions?: CoverageOption[];

    toString(): string {
        return Vehicle.toString(this);
    }
}

export type AccountTrailers = AccountEquipment<
    Trailer & {
        policyValue: number;
        policyOwnerOperator: boolean;
        policySpare: boolean;
        policyAcv: boolean;
        policyComprehensive: boolean;
        policyCollision: boolean;
    }
>;
