import { classNames } from "@deathstar/ui";
import { Typography, TypographyProps } from "@material-ui/core";

export function TitleGroup({
    title,
    description,
    descriptionProps,
    className,
    icon,
    align,
    variant,
}: {
    title: string | JSX.Element;
    description?: string | JSX.Element;
    descriptionProps?: TypographyProps;
    className?: string;
    icon?: JSX.Element;
    align?: "right" | "left" | "center";
    variant?: TypographyProps["variant"];
}): JSX.Element {
    return (
        <div
            className={classNames("flex flex-col gap-4", className, {
                "items-start": align === "left",
                "items-center": align === "center",
                "items-end": align === "right",
            })}
        >
            <div className="flex items-center gap-4">
                {icon && icon}
                <Title title={title} variant={variant} />
            </div>
            <div className="h-[2px] w-[60px] bg-navigator-primary bg-primary"></div>
            <Description description={description} descriptionProps={descriptionProps} />
        </div>
    );
}

function Description({
    description,
    descriptionProps,
}: {
    description?: string | JSX.Element;
    descriptionProps?: TypographyProps;
}): JSX.Element | null {
    if (!description) return null;
    if (typeof description === "string")
        return (
            <Typography
                {...descriptionProps}
                className={classNames("leading-2 max-w-[800px] text-sm text-gray-600", descriptionProps?.className)}
            >
                {description}
            </Typography>
        );
    return description;
}

function Title({ title, variant }: { title: string | JSX.Element; variant?: TypographyProps["variant"] }): JSX.Element {
    if (typeof title === "string") return <Typography variant={variant || "h5"}>{title}</Typography>;
    return title;
}
