import { DateTime, memoize } from "@deathstar/reuse";
import { Crash } from "../Crash/Crash";
import { MotorCarrier } from "../MotorCarrier";

export class Crashes {
    // ========================================================================
    static sorter(order: "ASC" | "DESC"): (c1: Crash, c2: Crash) => number {
        return (c1: Crash, c2: Crash) => {
            if (c1.get("ReportDate") < c2.get("ReportDate")) {
                return order === "ASC" ? -1 : 1;
            }
            if (c1.get("ReportDate") > c2.get("ReportDate")) {
                return order === "ASC" ? 1 : -1;
            }
            return 0;
        };
    }

    // ========================================================================
    static sort(crashes: Crash[], order: "ASC" | "DESC"): Crash[] {
        const sortFn = Crashes.sorter(order);
        return crashes.sort(sortFn);
    }

    // ========================================================================
    static of(carrier: MotorCarrier, crashes?: Crash[]): Crashes {
        const newCrashes = new Crashes(carrier);
        if (crashes) {
            newCrashes.#addCrashes(crashes);
        }
        return newCrashes;
    }

    // ========================================================================
    static #of(carrier: MotorCarrier, crashes?: Crash[], options?: { keepInvalidCrashes: boolean }): Crashes {
        const newCrashes = Crashes.of(carrier, crashes);
        if (options?.keepInvalidCrashes) {
            newCrashes.#keepInvalidCrashes = options.keepInvalidCrashes;
        }
        return newCrashes;
    }

    // ========================================================================
    static new(carrier: MotorCarrier, rawCrashes: Crash.Raw[]): Crashes {
        const crashes = new Crashes(carrier);
        crashes.#crashes = rawCrashes.map((rawCrash) => Crash.new(carrier, rawCrash));
        return crashes;
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #crashIds = new Set<string>();
    #crashes: Crash[] = [];
    #keepInvalidCrashes = false;
    private constructor(carrier: MotorCarrier) {
        this.#carrier = carrier;
    }

    // ========================================================================
    *[Symbol.iterator](): IterableIterator<Crash> {
        for (const crash of this.#crashes) {
            yield crash;
        }
    }

    // ========================================================================
    #recalculateValidCrashes = true;
    #validCrashes?: Crash[];
    #calculateValidCrashes(): Crash[] {
        this.#validCrashes = this.#crashes.filter((v) => v.isValid);
        this.#recalculateValidCrashes = false;
        return this.#validCrashes;
    }
    /**
     * The Violations that are included in the latest BASIC scores (based on the applicable date range)
     */
    get #valid(): Crash[] {
        if (this.#keepInvalidCrashes) return this.#crashes;
        return !this.#recalculateValidCrashes && this.#validCrashes ? this.#validCrashes : this.#calculateValidCrashes();
    }
    /**
     * The Violations that are included in the latest BASIC scores (based on the applicable date range)
     */
    set #valid(violations: Crash[]) {
        this.#validCrashes = violations.filter((v) => v.isValid);
    }

    // ========================================================================
    includeInvalid(): this {
        this.#keepInvalidCrashes = true;
        this.#calculateValidCrashes();
        return this;
    }

    // ========================================================================
    excludeInvalid(): this {
        this.#keepInvalidCrashes = false;
        this.#calculateValidCrashes();
        return this;
    }

    // ========================================================================
    #scored?: Crashes;
    #calculateScored(): Crashes {
        const activelyScored = this.#valid.filter((crash) => {
            if (crash.isScored) return crash;
            return null;
        });
        this.#scored = Crashes.of(this.#carrier, activelyScored);
        return this.#scored;
    }
    /**
     * The Violations that are included in the latest BASIC scores (based on the applicable date range)
     */
    get scored(): Crashes {
        return this.#scored ?? this.#calculateScored();
    }

    // ========================================================================
    get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    #addCrashes(crashes: Crash[]): void {
        crashes.forEach((crash) => {
            if (this.#crashIds.has(crash.id)) return;
            this.#crashIds.add(crash.id);
            this.#crashes.push(crash);
            this.#recalculateValidCrashes = true;
        });
    }

    // ========================================================================
    @memoize()
    get total(): number {
        const reportNumbers = new Set(this.#valid.map((c) => c.get("ReportNumber")));
        return reportNumbers.size;
    }

    // ========================================================================
    @memoize()
    get totalWithFatalities(): number {
        const reportNumbers = new Set(this.#valid.filter((crash) => crash.get("TotalFatalities") > 0).map((c) => c.get("ReportNumber")));
        return reportNumbers.size;
    }

    // ========================================================================
    @memoize()
    get totalFatalities(): number {
        return this.#valid.reduce((total, crash) => total + crash.get("TotalFatalities"), 0);
    }

    // ========================================================================
    @memoize()
    get totalWithInjuries(): number {
        const reportNumbers = new Set(this.#valid.filter((crash) => crash.get("TotalInjuries") > 0).map((c) => c.get("ReportNumber")));
        return reportNumbers.size;
    }

    // ========================================================================
    @memoize()
    get totalInjuries(): number {
        return this.#valid.reduce((total, crash) => total + crash.get("TotalInjuries"), 0);
    }

    // ========================================================================
    @memoize()
    get totalWithTowAway(): number {
        const reportNumbers = new Set(this.#valid.filter((crash) => crash.get("TowAway")).map((c) => c.get("ReportNumber")));
        return reportNumbers.size;
    }

    // ========================================================================
    @memoize()
    getStateBreakdown(): Map<string, number> {
        return this.#valid.reduce((acc, crash) => {
            const reportState = crash.get("ReportState");
            if (!acc.has(reportState)) {
                acc.set(reportState, 1);
                return acc;
            }
            acc.set(reportState, acc.get(reportState)! + 1);
            return acc;
        }, new Map() as Map<string, number>);
    }

    // ========================================================================
    #timeWeightMap = new Map<string, number>();
    getTotalTimeWeight(date = this.carrier.dateRange.to): number {
        if (this.#timeWeightMap.has(date.format("YYYYMMDD"))) {
            return this.#timeWeightMap.get(date.format("YYYYMMDD"))!;
        }

        const timeWeight = this.#valid.reduce((total, crash) => {
            total += crash.getTimeWeight(date);
            return total;
        }, 0);

        this.#timeWeightMap.set(date.format("YYYYMMDD"), timeWeight);

        return timeWeight;
    }

    // ========================================================================
    #totalWeight?: number;
    #calculateTotalWeight(timeWeightDate: DateTime): number {
        this.#totalWeight = this.#valid.reduce((total, crash) => {
            total += crash.getTotalWeight({ timeWeightDate });
            return total;
        }, 0);
        return this.#totalWeight;
    }
    /**
     * Total Weight of crashes as defined in SMS Methodology.
     */
    getTotalWeight({ timeWeightDate } = { timeWeightDate: this.carrier.dateRange.to }): number {
        return this.#totalWeight ?? this.#calculateTotalWeight(timeWeightDate);
    }

    // ========================================================================
    #filteredDateRanges = new Map<string, Crashes>();
    #calculateDateRange({ from, to }: { from: DateTime; to: DateTime }): Crashes {
        const dateRangeId = `${from.format("YYYYMMDD")}-${to.format("YYYYMMDD")}`;
        if (this.#filteredDateRanges.has(dateRangeId)) {
            return this.#filteredDateRanges.get(dateRangeId)!;
        }
        const crashes = Crashes.of(
            this.#carrier,
            this.#valid.filter((crash) => {
                if (crash.date.isBetween(from, to)) return crash;
                return null;
            })
        );
        this.#filteredDateRanges.set(dateRangeId, crashes);
        return crashes;
    }
    /**
     * Filters the Crashes by date range.
     */
    filterByDateRange({ from, to }: { from: DateTime; to: DateTime }): Crashes {
        return this.#calculateDateRange({ from, to });
    }

    // ========================================================================
    /**
     * Filters only the Crashes included in SMS Scores
     */
    filterByIncludedInSms(): Crashes {
        const crashes = this.#valid.filter((crash) => {
            if (crash.isIncludedInSms) return crash;
            return null;
        });
        return Crashes.of(this.#carrier, crashes);
    }

    // ========================================================================
    /**
     * Filters the Crashes by their "IsValid" indicator. This can be used to
     * filter out crashes that were potentially DataQ'd.
     */
    filterByInvalid(): Crashes {
        const crashes = this.#valid.filter((crash) => {
            if (!crash.isValid) return crash;
            return null;
        });
        return Crashes.#of(this.#carrier, crashes, { keepInvalidCrashes: true });
    }

    // ========================================================================
    /**
     * Filters the Crashes by the given `Crash` field and value.
     * @param field `Crash` field to filter by
     * @param value Value to filter by
     * @returns `Crashes` object with only the `Crash` objects that match the filter
     */
    filterByField<Field extends keyof Crash.Raw>(field: Field, value: Crash.Raw[Field]): Crashes {
        const crashes = this.#valid.filter((crash) => {
            if (crash.get(field) === value) return crash;
            return null;
        });
        return Crashes.of(this.#carrier, crashes);
    }

    // ========================================================================
    sortByDate(order: "ASC" | "DESC" = "DESC"): this {
        Crashes.sort(this.#valid, order);
        Crashes.sort(this.#crashes, order);
        return this;
    }

    // ========================================================================
    array(options?: { valid?: boolean }): Crash[] {
        if (options?.valid) return Array.from(this.#valid) ?? [];
        return Array.from(this.#crashes) ?? [];
    }

    // ========================================================================
    raw(): Crash.Raw[] {
        return this.#crashes.map((crash) => crash.raw());
    }
}

// ========================================================================
export namespace Crashes {
    export interface Options {
        carrier: MotorCarrier;
    }
}
