import { DateTime, memoize } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { Inspection } from "../Inspection/Inspection";
import { Inspections } from "../Inspections/Inspections";
import { MotorCarrier } from "../MotorCarrier";
import { TimeWeight } from "../TimeWeight/TimeWeight";
import { Unit } from "../Unit/Unit";
import { Units } from "../Units/Units";
import { Violation } from "../Violation/Violation";
import { ViolationType } from "../Violation/ViolationType";
import { Breakdowns } from "./Breakdown/Breakdowns";

class ViolationsTotals {
    // ========================================================================
    #totals: {
        unsafeDriving: number;
        hoursOfService: number;
        vehicleMaintenance: number;
        controlledSubstances: number;
        hazmat: number;
        driverFitness: number;
    } = {
        unsafeDriving: 0,
        hoursOfService: 0,
        vehicleMaintenance: 0,
        controlledSubstances: 0,
        hazmat: 0,
        driverFitness: 0,
    };

    constructor(violations: Violation[]) {
        this.#tallyBasics(violations);
    }

    // ========================================================================
    /**
     * Tally the Violations by BASIC
     */
    #tallyBasics(violations: Violation[]): void {
        violations.forEach((violation) => {
            switch (violation.get("Basic")) {
                case FMCSA.BasicName.CONTROLLED_SUBSTANCES:
                    this.#totals.controlledSubstances += 1;
                    break;
                case FMCSA.BasicName.DRIVER_FITNESS:
                    this.#totals.driverFitness += 1;
                    break;
                case FMCSA.BasicName.HAZMAT:
                    this.#totals.hazmat += 1;
                    break;
                case FMCSA.BasicName.HOS:
                    this.#totals.hoursOfService += 1;
                    break;
                case FMCSA.BasicName.UNSAFE_DRIVING:
                    this.#totals.unsafeDriving += 1;
                    break;
                case FMCSA.BasicName.VEHICLE_MAINTENANCE:
                    this.#totals.vehicleMaintenance += 1;
                    break;
            }
        });
    }

    // ========================================================================
    get total(): number {
        return Object.values(this.#totals).reduce((grandTotal, basicTotal) => {
            return grandTotal + basicTotal;
        }, 0);
    }

    // ========================================================================
    get unsafeDriving(): number {
        return this.#totals.unsafeDriving;
    }

    // ========================================================================
    get hoursOfService(): number {
        return this.#totals.hoursOfService;
    }

    // ========================================================================
    get vehicleMaintenance(): number {
        return this.#totals.vehicleMaintenance;
    }

    // ========================================================================
    get controlledSubstances(): number {
        return this.#totals.controlledSubstances;
    }

    // ========================================================================
    get hazmat(): number {
        return this.#totals.hazmat;
    }

    // ========================================================================
    get driverFitness(): number {
        return this.#totals.driverFitness;
    }
}

export class Violations {
    static Totals = ViolationsTotals;

    // ========================================================================
    static sorter(order: "ASC" | "DESC"): (v1: Violation, v2: Violation) => number {
        return (v1: Violation, v2: Violation) => {
            if (v1.date.isBefore(v2.date)) {
                return order === "ASC" ? -1 : 1;
            }
            if (v1.date.isAfter(v2.date)) {
                return order === "ASC" ? 1 : -1;
            }
            return 0;
        };
    }

    // ========================================================================
    static sort(violations: Violation[], order: "ASC" | "DESC"): Violation[] {
        const sortFn = Violations.sorter(order);
        return violations.sort(sortFn);
    }

    // ========================================================================
    static includeInvalid(violations: Violations): Violations {
        return Violations.#of(violations.carrier, [...violations.#violations], { keepInvalidViolations: true });
    }

    // ========================================================================
    static #totalExpiringPoints(violations: Violation[]): number {
        return violations.reduce((total, violation) => {
            total += violation.getExpiringWeight();
            return total;
        }, 0);
    }

    // ========================================================================
    static of(carrier: MotorCarrier, violations?: Violation[] | Violation.Raw[]): Violations {
        const newViolations = new Violations(carrier);
        if (violations) {
            newViolations.#addViolations(
                violations.map((v) => {
                    if (v instanceof Violation) {
                        return v;
                    }
                    const insp = [...carrier.inspections.array(), ...carrier.expiredInspections.array()].find(
                        (i) => i.id === v.InspectionUniqueId
                    );
                    if (!insp) {
                        throw new Error("Inspection associated with violation not found!");
                    }
                    return Violation.new(carrier, insp, v);
                })
            );
        }
        return newViolations;
    }

    // ========================================================================
    static #of(carrier: MotorCarrier, violations?: Violation[], options?: { keepInvalidViolations: boolean }): Violations {
        const newViolations = Violations.of(carrier, violations);
        if (options?.keepInvalidViolations) {
            newViolations.#keepInvalidViolations = true;
        }
        return newViolations;
    }

    // ========================================================================
    static from(violationsObj: Violations, violations?: Violation[]): Violations {
        const newViolations = new Violations(violationsObj.#carrier);
        if (violations?.length) {
            newViolations.#addViolations(violations);
        }
        return newViolations;
    }

    // ========================================================================
    static new(carrier: MotorCarrier, inspections: Inspections): Violations {
        const { rawViolationsMap } = MotorCarrier.getCachedFetcherResults(carrier);
        if (!rawViolationsMap) throw new Error("Raw violations not found");
        const newViolations = new Violations(carrier);
        const violations = Array.from(rawViolationsMap.values())
            .flat()
            .filter((v) => new DateTime(v.InspectionDate).isBetween(carrier.dateRange.from, carrier.dateRange.to))
            .map((rawViolation) => {
                return Violation.new(carrier, inspections.getById(rawViolation.InspectionUniqueId), rawViolation);
            });
        newViolations.#addViolations(violations);
        return newViolations;
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #basicTotals: Violations.Totals;
    #violationIds: Set<string> = new Set();
    #violations: Violation[] = [];
    #keepInvalidViolations = false;
    private constructor(carrier: MotorCarrier) {
        this.#carrier = carrier;
        this.#basicTotals = new Violations.Totals(this.#valid);
    }

    get keepInvalidViolations(): boolean {
        return this.#keepInvalidViolations;
    }

    // ========================================================================
    *[Symbol.iterator](): IterableIterator<Violation> {
        for (const violation of this.#violations) {
            yield violation;
        }
    }

    // ========================================================================
    get inspections(): Inspections {
        const { inspections } = this.#violations
            .map((viol) => viol.inspection)
            .reduce(
                (acc, insp) => {
                    if (acc.inspectionIds.has(insp.id)) return acc;

                    acc.inspectionIds.add(insp.id);
                    acc.inspections.push(insp);

                    return acc;
                },
                {
                    inspectionIds: new Set(),
                    inspections: [],
                } as {
                    inspectionIds: Set<number>;
                    inspections: Inspection[];
                }
            );
        return Inspections.of(this.#carrier, inspections);
    }

    // ========================================================================
    includeInvalid(): this {
        this.#keepInvalidViolations = true;
        this.#calculateValidViolations();
        return this;
    }

    // ========================================================================
    excludeInvalid(): this {
        this.#keepInvalidViolations = false;
        this.#calculateValidViolations();
        return this;
    }

    // ========================================================================
    #addViolations(violations: Violation[]): void {
        violations.forEach((violation) => {
            if (this.#violationIds.has(violation.id)) return;
            this.#violationIds.add(violation.id);
            this.#violations.push(violation);
            this.#recalculateValidViolations = true;
        });
    }

    // ========================================================================
    #recalculateValidViolations = true;
    #validViolations?: Violation[];
    #calculateValidViolations(): Violation[] {
        this.#validViolations = this.#violations.filter((v) => v.isValid);
        this.#recalculateValidViolations = false;
        return this.#validViolations;
    }
    /**
     * The Violations that are included in the latest BASIC scores (based on the applicable date range)
     */
    get #valid(): Violation[] {
        if (this.#keepInvalidViolations) return this.#violations;
        return !this.#recalculateValidViolations && this.#validViolations ? this.#validViolations : this.#calculateValidViolations();
    }

    // ========================================================================
    #units: Units | undefined;
    #calculateUnits(): Units {
        const units = this.#valid.map((violation) => violation.unit).filter((unit) => unit) as Unit[];
        this.#units = Units.of(this.#carrier, units, this);
        return this.#units;
    }
    get units(): Units {
        return this.#units || this.#calculateUnits();
    }

    // ========================================================================
    #scored?: Violations;
    #calculateScored(): Violations {
        const activelyScored = this.#valid.filter((viol) => {
            if (viol.isCurrentlyScored) return viol;
            return null;
        });
        this.#scored = Violations.of(this.#carrier, activelyScored);
        return this.#scored;
    }
    /**
     * The Violations that are included in the latest BASIC scores (based on the applicable date range)
     */
    get scored(): Violations {
        return this.#scored ?? this.#calculateScored();
    }

    // ========================================================================
    get total(): number {
        if (this.#keepInvalidViolations) return this.#violations.length;
        return this.#valid.length;
    }

    // ========================================================================
    get totalOutOfService(): number {
        if (this.#keepInvalidViolations) return this.#violations.filter((v) => v.isOutOfService).length;
        return this.#valid.filter((v) => v.isOutOfService).length;
    }

    // ========================================================================
    get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    filterByUnit(unit: Unit): Violations {
        const filteredViolations = this.#valid.filter((violation) => {
            if (violation.unit?.vin === unit.vin) {
                return violation;
            }
            return null;
        });
        return Violations.from(this, filteredViolations);
    }

    // ========================================================================
    filterByUnits(units: Unit[] | Units): Violations {
        let unitVins: Set<string>;
        if (units instanceof Units) {
            unitVins = new Set(Array.from(units).map((unit) => unit.vin));
        } else {
            unitVins = new Set(units.map((unit) => unit.vin));
        }
        const filteredViolations = this.#valid.filter((violation) => {
            if (violation.unit && unitVins.has(violation.unit.vin)) {
                return violation;
            }
            return null;
        });
        return Violations.from(this, filteredViolations);
    }

    // ========================================================================
    filterByInspection(inspection: Inspection): Violations {
        const filteredViolations = this.#violations.filter((violation) => {
            if (violation.get("InspectionUniqueId") === inspection.id) {
                return violation;
            }
            return null;
        });
        return Violations.from(this, filteredViolations);
    }

    // ========================================================================
    filterByInspections(inspections: Inspection[] | Inspections): Violations {
        let inspIds: Set<number>;
        if (inspections instanceof Inspections) {
            inspIds = new Set(Array.from(inspections).map((insp) => insp.id));
        } else {
            inspIds = new Set(inspections.map((insp) => insp.id));
        }
        const filteredViolations = this.#violations.filter((violation) => {
            if (inspIds.has(violation.get("InspectionUniqueId"))) {
                return violation;
            }
            return null;
        });
        return Violations.from(this, filteredViolations);
    }

    // ========================================================================
    filterByExpiring(): Violations {
        const expiringVilations = this.#valid.filter((violation) => {
            if (violation.isExpiring) return violation;
            return null;
        });
        return Violations.from(this, expiringVilations);
    }

    // ========================================================================
    get hasExpiringWeight(): boolean {
        return this.filterExpiringWeight().total > 0;
    }

    // ========================================================================
    @memoize()
    getTotalExpiringWeight(): number {
        return Violations.#totalExpiringPoints(this.#valid);
    }

    // ========================================================================
    @memoize()
    filterExpiringWeight(): Violations {
        const violationsWithExpiringPoints = this.#valid.filter((violation) => {
            if (violation.getExpiringWeight() > 0) return violation;
            return null;
        });
        return Violations.from(this, violationsWithExpiringPoints);
    }

    // ========================================================================
    filterByExpired(): Violations {
        const expiredViolations = this.#valid.filter((violation) => {
            if (violation.isExpired) return violation;
            return null;
        });
        return Violations.from(this, expiredViolations);
    }

    // ========================================================================
    filterByNew(): Violations {
        const newViolations = this.#valid.filter((violation) => {
            if (violation.isNew) return violation;
            return null;
        });
        return Violations.from(this, newViolations);
    }

    // ========================================================================
    /**
     * Gets the Motor Carrier's Violations with the highest Total Weight
     * (Severity Weight x Times Weight = Total Weight)
     *
     * @param options.filterDuplicates if only a single inspection should be represented in the Violation array
     * @param options.order sort order
     * @param options.timeWeightDate the date to use to calculate the time weight
     */
    sortByTop(
        { filterDuplicates, order, timeWeightDate }: Partial<Violations.IGetTopOptions> = {
            filterDuplicates: true,
            order: "DESC",
            timeWeightDate: this.carrier.dateRange.to,
        }
    ): Violations {
        const sortOrder = order ?? "DESC";
        const removeDuplicates = filterDuplicates ?? true;
        const timeWeightTargetDate = timeWeightDate ?? this.carrier.dateRange.to;
        const options = {
            timeWeightDate: timeWeightTargetDate,
        };

        let topViolations = this.#valid.sort((v1, v2) => {
            if (v2.getTotalWeight(options) > v1.getTotalWeight(options)) {
                return sortOrder === "ASC" ? -1 : 1;
            }
            if (v2.getTotalWeight(options) < v1.getTotalWeight(options)) {
                return sortOrder === "ASC" ? 1 : -1;
            }
            return 0;
        });

        if (removeDuplicates) {
            topViolations = topViolations.reduce((violations, violation) => {
                if (violations.some((v) => v.inspection.id === violation.inspection.id)) return violations;
                violations.push(violation);
                return violations;
            }, [] as Violation[]);
        }

        return Violations.from(this, topViolations);
    }

    // ========================================================================
    /**
     * Filters the Violations by BASIC
     */
    filterByBasic(basic: FMCSA.BasicName): Violations {
        return this.#valid.reduce((violations, violation) => {
            if (violation.basic === basic) {
                violations.#addViolations([violation]);
            }
            return violations;
        }, new Violations(this.#carrier));
    }

    // ========================================================================
    /**
     * Filters the Violations by BASIC
     */
    filterByBasics(basicsToInclude?: FMCSA.BasicName[]): MotorCarrier.Violations.IFilterByBasicReturn {
        const obj = {
            aggregate: new Violations(this.#carrier),
            controlledSubstances: new Violations(this.#carrier),
            driverFitness: new Violations(this.#carrier),
            hazmat: new Violations(this.#carrier),
            hoursOfService: new Violations(this.#carrier),
            unsafeDriving: new Violations(this.#carrier),
            vehicleMaintenance: new Violations(this.#carrier),
        };

        const includeControlledSubstances = !basicsToInclude ? true : basicsToInclude.includes(FMCSA.BasicName.CONTROLLED_SUBSTANCES);
        const includeDriverFitness = !basicsToInclude ? true : basicsToInclude.includes(FMCSA.BasicName.DRIVER_FITNESS);
        const includeHazmat = !basicsToInclude ? true : basicsToInclude.includes(FMCSA.BasicName.HAZMAT);
        const includehoursOfService = !basicsToInclude ? true : basicsToInclude.includes(FMCSA.BasicName.HOS);
        const includeUnsafeDriving = !basicsToInclude ? true : basicsToInclude.includes(FMCSA.BasicName.UNSAFE_DRIVING);
        const includeVehicleMaintenance = !basicsToInclude ? true : basicsToInclude.includes(FMCSA.BasicName.VEHICLE_MAINTENANCE);

        const reduced = this.#valid.reduce((violations, violation) => {
            if (includeControlledSubstances && violation.basic === FMCSA.BasicName.CONTROLLED_SUBSTANCES) {
                violations.controlledSubstances.#addViolations([violation]);
                violations.aggregate.#addViolations([violation]);
                return violations;
            }
            if (includeDriverFitness && violation.basic === FMCSA.BasicName.DRIVER_FITNESS) {
                violations.driverFitness.#addViolations([violation]);
                violations.aggregate.#addViolations([violation]);
                return violations;
            }
            if (includeHazmat && violation.basic === FMCSA.BasicName.HAZMAT) {
                violations.hazmat.#addViolations([violation]);
                violations.aggregate.#addViolations([violation]);
                return violations;
            }
            if (includehoursOfService && violation.basic === FMCSA.BasicName.HOS) {
                violations.hoursOfService.#addViolations([violation]);
                violations.aggregate.#addViolations([violation]);
                return violations;
            }
            if (includeUnsafeDriving && violation.basic === FMCSA.BasicName.UNSAFE_DRIVING) {
                violations.unsafeDriving.#addViolations([violation]);
                violations.aggregate.#addViolations([violation]);
                return violations;
            }
            if (includeVehicleMaintenance && violation.basic === FMCSA.BasicName.VEHICLE_MAINTENANCE) {
                violations.vehicleMaintenance.#addViolations([violation]);
                violations.aggregate.#addViolations([violation]);
                return violations;
            }
            return violations;
        }, obj);

        return {
            ...reduced,
            [FMCSA.BasicName.CONTROLLED_SUBSTANCES]: reduced.controlledSubstances,
            [FMCSA.BasicName.DRIVER_FITNESS]: reduced.driverFitness,
            [FMCSA.BasicName.HAZMAT]: reduced.hazmat,
            [FMCSA.BasicName.HOS]: reduced.hoursOfService,
            [FMCSA.BasicName.UNSAFE_DRIVING]: reduced.unsafeDriving,
            [FMCSA.BasicName.VEHICLE_MAINTENANCE]: reduced.vehicleMaintenance,
        };
    }

    // ========================================================================
    /**
     * Filters the Violations by their "IsValid" indicator. This can be used to
     * filter out violations that were potentially DataQ'd.
     */
    @memoize()
    filterByInvalid(): Violations {
        const violations = this.#violations.filter((viol) => {
            if (!viol.isValid) return viol;
            return null;
        });
        return Violations.#of(this.#carrier, violations, { keepInvalidViolations: true });
    }

    // ========================================================================
    /**
     * Filters the violations by the type of Violation - Driver, Tractor, Trialer, and Unknown
     */
    filterByType(): MotorCarrier.Violations.IByType {
        const violationsByType: ReturnType<Violations["filterByType"]> = this.#valid.reduce(
            (obj, violation) => {
                switch (violation.type) {
                    case ViolationType.DRIVER:
                        obj.driver.#addViolations([violation]);
                        break;
                    case ViolationType.TRACTOR:
                        obj.tractor.#addViolations([violation]);
                        break;
                    case ViolationType.TRAILER:
                        obj.trailer.#addViolations([violation]);
                        break;
                    default:
                        obj.unknown.#addViolations([violation]);
                }

                return obj;
            },
            {
                driver: new Violations(this.#carrier),
                tractor: new Violations(this.#carrier),
                trailer: new Violations(this.#carrier),
                unknown: new Violations(this.#carrier),
            }
        );

        return violationsByType;
    }

    // ========================================================================
    /**
     * Calculates the MotorCarrier's total score across all BASIC categories.
     * @param violations The violations to use in the calculation, defaults to all `MotorCarrier` violations
     * 
     * @example
     * ```txt
     * Violation 1: Driver Fitness Violation, Total Weight = 10
     * Violation 2: Vehicle Maintenance Violation, Total Weight = 15
     * Violation 3: Driver Fitness Violation, Total Weight = 6
     * Violation 4: Hours Of Service Violation, Total Weight = 20
         // => Would return 10 + 15 + 6 + 20 = 51
    * ```
    */
    getTotalWeight({ timeWeightDate } = { timeWeightDate: this.carrier.dateRange.to }): number {
        const weightMapByInspectionAndBasic = new Map<
            number,
            {
                timeWeight: number;
                basicMap: Map<FMCSA.BasicName, number>;
            }
        >();
        this.#valid.forEach((violation) => {
            const inspectionMap = weightMapByInspectionAndBasic.get(violation.inspection.id) || {
                timeWeight: TimeWeight.of({ inspectionDate: violation.date, targetDate: timeWeightDate }),
                basicMap: new Map<FMCSA.BasicName, number>(),
            };
            const currentWeight = inspectionMap.basicMap.get(violation.basic) ?? 0;
            const nextWeight = currentWeight + violation.severityWeight;
            inspectionMap.basicMap.set(violation.basic, nextWeight);
            weightMapByInspectionAndBasic.set(violation.inspection.id, inspectionMap);
        });
        weightMapByInspectionAndBasic.forEach(({ timeWeight, basicMap }) => {
            basicMap.forEach((weight, basic) => {
                const totalSeverityWeight = weight > 30 ? 30 : weight;

                basicMap.set(basic, totalSeverityWeight * timeWeight);
            });
        });
        const mapArray = Array.from(weightMapByInspectionAndBasic.values());

        return mapArray.reduce((total, { basicMap }) => {
            return total + Array.from(basicMap.values()).reduce((total, weight) => total + weight, 0);
        }, 0);
    }

    // ========================================================================
    get basicTotals(): Violations.Totals {
        return this.#basicTotals;
    }

    // ========================================================================
    json(): Violation.JSON[] {
        return this.#violations.map((v) => v.json());
    }

    // ========================================================================
    raw(): Violation.Raw[] {
        return this.#violations.map((violation) => violation.raw());
    }

    // ========================================================================
    take(amount: number): Violations {
        const newUnits = new Violations(this.#carrier);
        newUnits.#addViolations(Array.from(this).slice(0, amount));
        return newUnits;
    }

    // ========================================================================
    sortByDate(order: "ASC" | "DESC" = "DESC"): this {
        Violations.sort(this.#valid, order);
        Violations.sort(this.#violations, order);
        return this;
    }

    // ========================================================================
    #filteredDateRanges = new Map<string, Violations>();
    #calculateDateRange({ from, to }: { from: DateTime; to: DateTime }): Violations {
        const dateRangeId = `${from.format("YYYYMMDD")}-${to.format("YYYYMMDD")}`;
        if (this.#filteredDateRanges.has(dateRangeId)) {
            return this.#filteredDateRanges.get(dateRangeId)!;
        }
        const violations = Violations.of(
            this.#carrier,
            this.#valid.filter((viol) => {
                if (viol.date.isBetween(from, to)) return viol;
                return null;
            })
        );
        this.#filteredDateRanges.set(dateRangeId, violations);
        return violations;
    }
    /**
     * Filters the Violations by date range.
     */
    filterByDateRange({ from, to }: { from: DateTime; to: DateTime }): Violations {
        return this.#calculateDateRange({ from, to });
    }

    // ========================================================================
    /**
     * Filters the Violations by the given `Violation` field and value.
     * @param field `Violation` field to filter by
     * @param value Value to filter by
     * @returns `Violations` object with only the `Violation` objects that match the filter
     */
    filterByField<Field extends keyof Violation.Raw>(field: Field, value: Violation.Raw[Field]): Violations {
        const violations = this.#valid.filter((v) => {
            if (v.get(field) === value) return v;
            return null;
        });
        return Violations.of(this.#carrier, violations);
    }

    // ========================================================================
    /**
     * Returns true if the set of `Violations` includes a specific BASIC, otherwise false.
     */
    has(basic: FMCSA.BasicName): boolean {
        return this.#valid.some((viol) => viol.basic === basic);
    }

    // ========================================================================
    @memoize()
    getBreakdowns({ numberOfHistoricalBreakdowns } = { numberOfHistoricalBreakdowns: 6 }): Breakdowns {
        return new Breakdowns({
            carrier: this.#carrier,
            violations: this,
            numberOfHistoricalBreakdowns,
        });
    }

    // ========================================================================
    getIndex(index: number): Violation | null {
        return this.#violations[index] || null;
    }

    // ========================================================================
    array(options?: { valid?: boolean }): Violation[] {
        if (options?.valid) return Array.from(this.#valid) ?? [];
        return Array.from(this.#violations) ?? [];
    }
}

// ========================================================================
export namespace Violations {
    export interface IGetTopOptions {
        order: "ASC" | "DESC";
        filterDuplicates: boolean;
        timeWeightDate: DateTime;
    }
    export interface Totals extends ViolationsTotals {}
}
