import { Section } from "../Components/Section";
import { AuditResult } from "./AuditResult";
import { FmcsaReportedFigures } from "./FmcsaReportedFigures";
import { IssScores } from "./IssScores";
import { OutOfServiceRates } from "./OutOfServiceRates";

export function FmcsaSnapshot(): JSX.Element {
    return (
        <Section>
            <div className="grid grid-cols-4 gap-4">
                <OutOfServiceRates className="w-fit" />
                <IssScores className="w-fit" />
                <AuditResult className="w-fit" />
                <FmcsaReportedFigures className="w-fit" />
            </div>
        </Section>
    );
}
