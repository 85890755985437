import { Type, plainToClass } from "class-transformer";
import { isAfter, isBefore, isEqual } from "date-fns";
import { Attribute } from "../Attribute";
import { Comparison as ComparisonObj } from "../Comparison/Comparison";
import { EquipmentComparison } from "../Comparison/EquipmentComparison";
import { Policy } from "../Policy";
import { PolicyLayerMetadata } from "./Metadata";
import { PolicyLayerComparison } from "./PolicyLayerComparison";

export { EquipmentComparison, PolicyLayerComparison };

export class PolicyLayer
    implements
        PolicyLayer.IHasStatus,
        PolicyLayer.IHasPolicyWithEffectiveDate,
        PolicyLayer.IHasEffectiveDate,
        PolicyLayer.IHasCreatedDate,
        PolicyLayer.IHasType
{
    static Comparison = PolicyLayerComparison;
    static Metadata = PolicyLayerMetadata;

    static fromPolicy(policy: Policy): PolicyLayer {
        const layer = new PolicyLayer();
        if (!policy) {
            layer.description = "Invalid";
            layer.policyId = null;
            layer.type = null;
            layer.policy = null;
            layer.policyId = null;
            layer.status = null;
            layer.effectiveDate = new Date(0);
            layer.createdDate = new Date(0);
            return layer;
        }
        layer.description = policy.layerDescription || "None";
        layer.effectiveDate = policy.layerEffectiveDate || policy.createdDate;
        layer.policyId = policy.id;
        layer.type = policy.layerTransactionType;
        layer.createdDate = policy.createdDate;
        layer.status = policy.status;
        layer.policy = policy;
        return layer;
    }

    static fromMetadata(layerMetadata: PolicyLayerMetadata): PolicyLayer {
        const layer = new PolicyLayer();
        if (!layerMetadata) {
            layer.description = "Invalid";
            layer.policyId = null;
            layer.type = null;
            layer.policy = null;
            layer.policyId = null;
            layer.status = null;
            layer.effectiveDate = new Date(0);
            layer.createdDate = new Date(0);
            return layer;
        }
        layer.description = layerMetadata.description || "None";
        layer.effectiveDate = layerMetadata.effectiveDate || layerMetadata.createdDate;
        layer.policyId = layerMetadata.policyId;
        layer.type = layerMetadata.layerTransactionType;
        layer.createdDate = layerMetadata.createdDate;
        layer.status = layerMetadata.status;
        layer.policy = null;
        return layer;
    }

    static filterOutEquipmentAndDriverAttributes(layer: PolicyLayer): PolicyLayer {
        const _layer = plainToClass(PolicyLayer, layer);

        _layer.policy.coverages = _layer.policy.coverages.map((cvg) => {
            cvg.attributes = Attribute.filterOutEquipmentAndDriverAttributes(cvg.attributes);
            return cvg;
        });

        return _layer;
    }

    static filterUniqueLayers<T extends PolicyLayer.IFilterType>({
        layers,
        targetLayer,
        sortDirection,
    }: {
        layers: T[];
        targetLayer?: T;
        sortDirection?: PolicyLayer.SortDirection;
    }): T[] {
        const uniquePolicyLayers = new Set<string>();
        const sorted = sortDirection ? PolicyLayer.sort(layers, sortDirection) : layers;
        return sorted.reduce<T[]>((acc, layer) => {
            if (Policy.Status.includesNorthstarOnly([layer]) && targetLayer && !PolicyLayer.isEqual(layer, targetLayer)) return acc;
            const layerKey = layer.effectiveDate.toISOString();
            if (uniquePolicyLayers.has(layerKey)) return acc;
            uniquePolicyLayers.add(layerKey);

            acc.push(layer);
            return acc;
        }, []);
    }

    /**
     * Get all of the layers that are distinct by effective date. If there are multiple layers with the same effective date, only the latest one will be included in the list.
     */
    static filterDistinctLatestLayers<T extends PolicyLayer.IFilterType>(options: {
        layers: T[];
        targetLayer?: T;
        sortDirection?: PolicyLayer.SortDirection;
        removePreBindLayers?: boolean;
    }): T[] {
        const layerMap = new Map<string, T[]>(); // layerEffectiveDate -> layers
        options.layers.forEach((layer) => {
            if (options.removePreBindLayers && Policy.Status.isNorthstarOnly(layer.status)) return;
            const layerKey = layer.effectiveDate.toISOString();
            if (layerMap.has(layerKey)) {
                layerMap.get(layerKey).push(layer);
            } else {
                layerMap.set(layerKey, [layer]);
            }
        });

        const sortedLayerMap = new Map<string, T[]>(); // layerEffectiveDate -> layers
        for (const [layerKey, layers] of layerMap.entries()) {
            sortedLayerMap.set(layerKey, PolicyLayer.sort(layers));
        }

        const distinctLayers: T[] = [];
        for (const layers of sortedLayerMap.values()) {
            const latestLayer = PolicyLayer.getLatestLayer(layers);
            distinctLayers.push(latestLayer);
        }
        const sortDirection = options.sortDirection || "DESC";
        const sortedDistinctLayers = PolicyLayer.sort(distinctLayers, sortDirection);

        return sortedDistinctLayers.sort((layer1, layer2) => {
            if (layer1.status !== Policy.Status.DRAFT && layer2.status !== Policy.Status.DRAFT) return 0;
            if (layer1.status !== Policy.Status.DRAFT && layer2.status === Policy.Status.DRAFT) {
                return sortDirection === "DESC" ? -1 : 1;
            }
            if (layer1.status === Policy.Status.DRAFT && layer2.status === Policy.Status.DRAFT) {
                const l1Date = layer1.createdDate.getTime();
                const l2Date = layer2.createdDate.getTime();
                if (l1Date < l2Date) return sortDirection === "DESC" ? 1 : -1;
                if (l1Date > l2Date) return sortDirection === "DESC" ? -1 : 1;
                return 0;
            }
            return sortDirection === "DESC" ? 1 : -1;
        });
    }

    static getLatestLayer<T extends PolicyLayer.IFilterType>(layers: T[], options?: { removePreBindLayers?: boolean }): T {
        if (!layers.length) return null;
        if (layers.length === 1) return layers[0];
        let _layers = layers;
        if (options?.removePreBindLayers) {
            _layers = layers.filter((layer) => Policy.Status.includesValidAms([layer]));
        }
        return PolicyLayer.sort(_layers, "DESC")[0];
    }

    static isEqual(layer1: PolicyLayer.IHasEffectiveDate, layer2: PolicyLayer.IHasEffectiveDate): boolean {
        return layer1.effectiveDate.getTime() === layer2.effectiveDate.getTime();
    }

    static #getRelevantBilledLayers<T extends PolicyLayer.IRelevantBilledLayers>({
        layers,
        targetLayer,
        before,
        options,
    }: {
        layers: T[];
        targetLayer?: T;
        before?: boolean;
        options?: PolicyLayer.IGetRelevantBilledLayersOptions;
    }): T[] {
        const checkStatus = options?.checkStatus ?? true;
        const uniqueLayerDates = new Set<string>();
        return layers
            .filter((layer) => {
                if (checkStatus && Policy.Status.includesNorthstarOnly([layer])) return null;
                if (targetLayer && isEqual(layer.effectiveDate, targetLayer.policy.effectiveDate)) return null;
                if (
                    targetLayer &&
                    (before
                        ? isBefore(targetLayer.effectiveDate, layer.effectiveDate)
                        : isBefore(layer.effectiveDate, targetLayer.effectiveDate))
                )
                    return null;

                return layer;
            })
            .filter((layer) => {
                const effDateStr = layer.effectiveDate.toISOString();
                if (uniqueLayerDates.has(effDateStr)) return null;
                uniqueLayerDates.add(effDateStr);
                return layer;
            });
    }

    static isBindLayer<T extends PolicyLayer.IHasType>(layer: T): boolean {
        if ([Policy.TransactionType.NEW, Policy.TransactionType.RENEWAL, Policy.TransactionType.REWRITE].includes(layer.type)) return true;
        return false;
    }

    static getRelevantBilledLayersBeforeExcludingBindLayer<T extends PolicyLayer.IRelevantBilledLayers & PolicyLayer.IHasType>({
        layers,
        targetLayer,
        options,
    }: {
        layers: T[];
        targetLayer?: T;
        options?: PolicyLayer.IGetRelevantBilledLayersOptions;
    }): T[] {
        const relevantBilledLayers = PolicyLayer.#getRelevantBilledLayers({
            layers,
            targetLayer,
            before: true,
            options,
        });
        return relevantBilledLayers.filter((layer) => !PolicyLayer.isBindLayer(layer));
    }

    static getMonthlyReportLayersBeforeOrEqual<T extends PolicyLayer.IRelevantBilledLayers & PolicyLayer.IHasType>({
        layers,
        targetLayer,
    }: {
        layers: T[];
        targetLayer: T;
    }): T[] {
        return layers.filter((l) => {
            if (l.type !== Policy.TransactionType.MONTHLY_REPORT && l.type !== Policy.TransactionType.MONTHLY_REPORT_FINAL) return false;
            if (l.effectiveDate.valueOf() <= targetLayer.effectiveDate.valueOf()) return true;
            return false;
        });
    }

    /**
     * Filters the latest of each layer. If an endo layer has an edit, the edit will be included, the endo will not.
     */
    static getLatestLayers<T extends PolicyLayer.IRelevantBilledLayers & PolicyLayer.IHasCreatedDate>(options: { layers: T[] }): T[] {
        return PolicyLayer.#getRelevantBilledLayers({ layers: PolicyLayer.sort(options.layers, "DESC") });
    }

    static getRelevantBilledLayersBefore<T extends PolicyLayer.IRelevantBilledLayers>({
        layers,
        targetLayer,
        options,
    }: {
        layers: T[];
        targetLayer?: T;
        options?: PolicyLayer.IGetRelevantBilledLayersOptions;
    }): T[] {
        return PolicyLayer.#getRelevantBilledLayers({
            layers,
            targetLayer,
            before: true,
            options,
        });
    }

    static getRelevantBilledLayersAfter<T extends PolicyLayer.IRelevantBilledLayers>({
        layers,
        targetLayer,
        options,
    }: {
        layers: T[];
        targetLayer?: T;
        options?: PolicyLayer.IGetRelevantBilledLayersOptions;
    }): T[] {
        return PolicyLayer.#getRelevantBilledLayers({
            layers,
            targetLayer,
            before: false,
            options,
        });
    }

    static getFirstPostBindLayer<T extends PolicyLayer.IFilterType>(layers: T[]): T {
        if (!layers?.length) return null;
        return PolicyLayer.sort(layers).find((layer) => Policy.Status.includesValidAms([layer])) || null;
    }

    static getOriginalLayer<T extends PolicyLayer.IFilterType>(layers: T[]): T {
        if (!layers?.length) return null;
        if (Policy.Status.includesNorthstarOnly(layers)) return layers[layers.length - 1];
        const firstPostBindLayer = PolicyLayer.getFirstPostBindLayer(layers);
        const originalLayers = PolicyLayer.sort(layers).filter(
            (layer) => layer.effectiveDate.getTime() === firstPostBindLayer.effectiveDate.getTime()
        );
        return originalLayers[originalLayers.length - 1];
    }

    static isEdit({ type }: { type: Policy.TransactionType }): boolean {
        return type === Policy.TransactionType.EDIT;
    }

    static isEndorsement({ type, policyType }: { type: Policy.TransactionType; policyType: Policy.TransactionType }): boolean {
        if (!type || !policyType || PolicyLayer.isEdit({ type }) || policyType === type) return false;
        if (
            [
                Policy.TransactionType.MONTHLY_REPORT,
                Policy.TransactionType.MONTHLY_REPORT_FINAL,
                Policy.TransactionType.ANNIVERSARY_RE_RATE,
                Policy.TransactionType.BINDER_NEW_BUSINESS,
                Policy.TransactionType.BINDER_BILLABLE,
                Policy.TransactionType.BINDER_ENDORSEMENT,
                Policy.TransactionType.BINDER_RENEWAL,
                Policy.TransactionType.QUOTE,
                Policy.TransactionType.NON_RENEWAL_NOTIFIED_AGENCY,
                Policy.TransactionType.PREMIUM_AUDIT,
                Policy.TransactionType.CHANGE,
                Policy.TransactionType.CHANGE_QUOTE,
                Policy.TransactionType.INQUIRY,
                Policy.TransactionType.OTHER,
                Policy.TransactionType.REINSTATEMENT,
                Policy.TransactionType.REISSUE,
                Policy.TransactionType.REVERSAL_OF_NON_RENEWAL,
                Policy.TransactionType.RENEWAL_RE_QUOTE,
                Policy.TransactionType.RENEWAL_QUOTE,
                Policy.TransactionType.RENEWAL_REQUEST,
                Policy.TransactionType.NON_RENEWAL_NOTIFIED_POLICY_HOLDER,
                Policy.TransactionType.POLICY_SYNCHRONIZATION,
                Policy.TransactionType.POLICY_SYNCHRONIZATION_REQUEST,
                Policy.TransactionType.CANCELLATION_CONFIRMATION,
                Policy.TransactionType.CANCELLATION_REQUEST,
            ].includes(type)
        ) {
            return true;
        }
        return false;
    }

    static isUpdate({ type, policyType }: { type: Policy.TransactionType; policyType: Policy.TransactionType }): boolean {
        if (!type || !policyType || PolicyLayer.isEdit({ type }) || policyType === type) return false;
        if (type === Policy.TransactionType.UPDATE) return true;
        return false;
    }

    static hasEndorsement(policies: (Policy.IHasLayerTransactionType & Policy.IHasPolicyTransactionType)[]): boolean {
        if (policies.some((p) => PolicyLayer.isEndorsement({ type: p.layerTransactionType, policyType: p.policyTransactionType }))) {
            return true;
        }
        return false;
    }

    static hasEndorsementOrUpdate(policies: (Policy.IHasLayerTransactionType & Policy.IHasPolicyTransactionType)[]): boolean {
        if (
            policies.some(
                (p) =>
                    PolicyLayer.isUpdate({ type: p.layerTransactionType, policyType: p.policyTransactionType }) ||
                    PolicyLayer.isEndorsement({ type: p.layerTransactionType, policyType: p.policyTransactionType })
            )
        ) {
            return true;
        }
        return false;
    }

    static sort<T extends PolicyLayer.IHasDates>(layers: T[], direction: PolicyLayer.SortDirection = "ASC"): T[] {
        return layers?.slice().sort((a, b) => {
            if (!a.effectiveDate || !b.effectiveDate || isEqual(a.effectiveDate, b.effectiveDate)) {
                if (isBefore(a.createdDate, b.createdDate)) return direction === "DESC" ? 1 : -1;
                if (isAfter(a.createdDate, b.createdDate)) return direction === "DESC" ? -1 : 1;
            }
            if (isBefore(a.effectiveDate, b.effectiveDate)) return direction === "DESC" ? 1 : -1;
            if (isAfter(a.effectiveDate, b.effectiveDate)) return direction === "DESC" ? -1 : 1;
            return 0;
        });
    }

    static getNextEndorsementLayer<T extends PolicyLayer.IGetNextEndorsementLayerPolicy>({
        layer,
        allLayers,
    }: {
        layer: T;
        allLayers: T[];
    }): T {
        const layersAfter = PolicyLayer.getLayersAfter({
            layer,
            allLayers,
            dateToCompare: "effectiveDate",
        });
        return (
            PolicyLayer.sort(layersAfter)?.find((l) =>
                PolicyLayer.isEndorsement({
                    type: l.type,
                    policyType: l.policy.policyTransactionType,
                })
            ) || null
        );
    }

    static getLayersBeforeOrEqual<T1 extends PolicyLayer.IHasDates, T2 extends PolicyLayer.IHasDates>({
        layer,
        allLayers,
        dateToCompare,
    }: {
        layer: T1;
        allLayers: T2[];
        dateToCompare: PolicyLayer.DateToCompare;
    }): T2[] {
        return allLayers?.filter((l) => {
            if (isBefore(l[dateToCompare], layer[dateToCompare]) || isEqual(l[dateToCompare], layer[dateToCompare])) return l;
            return null;
        });
    }

    static getLayersBefore<T1 extends PolicyLayer.IHasDates, T2 extends PolicyLayer.IHasDates>({
        layer,
        allLayers,
        dateToCompare,
    }: {
        layer: T1;
        allLayers: T2[];
        dateToCompare: PolicyLayer.DateToCompare;
    }): T2[] {
        if (!layer) return [];
        return allLayers?.filter((l) => {
            if (isBefore(l[dateToCompare], layer[dateToCompare])) return l;
            return null;
        });
    }

    static getLayerBefore<T1 extends PolicyLayer.IHasDates, T2 extends PolicyLayer.IHasDates>(options: {
        layer: T1;
        allLayers: T2[];
        dateToCompare: PolicyLayer.DateToCompare;
    }): T2 {
        const relevantLayers = PolicyLayer.getLayersBefore(options);
        return PolicyLayer.sort(relevantLayers, "DESC")[0] || null;
    }

    static getLayersAfter<T1 extends PolicyLayer.IHasDates, T2 extends PolicyLayer.IHasDates>({
        layer,
        allLayers,
        dateToCompare,
    }: {
        layer: T1;
        allLayers: T2[];
        dateToCompare: PolicyLayer.DateToCompare;
    }): T2[] {
        if (!layer) return [];
        return allLayers?.filter((l) => {
            if (isAfter(l[dateToCompare], layer[dateToCompare])) return l;
            return null;
        });
    }

    static getLayerAfter<T1 extends PolicyLayer.IHasDates, T2 extends PolicyLayer.IHasDates>(options: {
        layer: T1;
        allLayers: T2[];
        dateToCompare: PolicyLayer.DateToCompare;
    }): T2 {
        const relevantLayers = PolicyLayer.getLayersAfter(options);
        const sortedLayers = relevantLayers.sort((a, b) => {
            if (isBefore(a.effectiveDate, b.effectiveDate)) return -1;
            if (isAfter(a.effectiveDate, b.effectiveDate)) return 1;
            return 0;
        });
        return sortedLayers[0] || null;
    }

    static isLayerABeforeLayerB<T extends PolicyLayer.IHasPolicyWithLayerEffectiveDate>(options: {
        layerA: T;
        layerB: T;
        compareDateOnly?: boolean;
        time?: {
            hours?: number;
            minutes?: number;
            seconds?: number;
            milliseconds?: number;
        };
    }): boolean {
        const layerEffectiveDate = options?.layerA?.policy?.layerEffectiveDate;
        const comparisonLayerEffectiveDate = options?.layerB?.policy?.layerEffectiveDate;
        if (!comparisonLayerEffectiveDate || !layerEffectiveDate) return false;
        if (options?.compareDateOnly) {
            const dateA = new Date(layerEffectiveDate);
            const dateB = new Date(comparisonLayerEffectiveDate);
            dateA.setUTCHours(5, 0, 0, 0);
            dateB.setUTCHours(5, 0, 0, 0);
            return isBefore(dateA, dateB);
        }
        if (options?.time) {
            const dateA = new Date(layerEffectiveDate);
            const dateB = new Date(comparisonLayerEffectiveDate);
            dateA.setHours(
                options.time.hours ?? dateA.getHours(),
                options.time.minutes ?? dateA.getMinutes(),
                options.time.seconds ?? dateA.getSeconds(),
                options.time.milliseconds ?? dateA.getMilliseconds()
            );
            dateB.setHours(
                options.time.hours ?? dateB.getHours(),
                options.time.minutes ?? dateB.getMinutes(),
                options.time.seconds ?? dateB.getSeconds(),
                options.time.milliseconds ?? dateB.getMilliseconds()
            );
            return isBefore(dateA, dateB);
        }
        return isBefore(layerEffectiveDate, comparisonLayerEffectiveDate);
    }

    static compare(
        { baseLayer, comparisonLayer }: { baseLayer: PolicyLayer; comparisonLayer: PolicyLayer },
        options?: PolicyLayerComparison.IComparisonOptions
    ) {
        return new PolicyLayerComparison(baseLayer, comparisonLayer, {
            equipment: options?.data?.equipment,
            coverageLimitOptions: options?.data?.coverageLimitOptions,
        }).compare(options);
    }

    static getNonEditLayers(layers: PolicyLayer[]): PolicyLayer[] {
        const endorsementLayers = layers.filter((l) =>
            PolicyLayer.isEndorsement({ type: l.type, policyType: l.policy.policyTransactionType })
        );
        const firstPostBindLayer = PolicyLayer.getFirstPostBindLayer(layers);
        return [firstPostBindLayer, ...endorsementLayers];
    }

    static getComparisonLayers(layers: PolicyLayer[]): PolicyLayer[] {
        if (!layers?.length) return [];

        const uniqueLayers = PolicyLayer.getLatestLayers({ layers });
        return uniqueLayers;
    }

    static hasFinalyMonthlyReportCompositeLayer({ allLayers }: { allLayers: PolicyLayer[] }) {
        return allLayers.some((layer2) => layer2.type === Policy.TransactionType.MONTHLY_REPORT_FINAL);
    }

    policyId: string;
    type: Policy.TransactionType;
    effectiveDate: Date;
    description: string;
    createdDate: Date;
    status: Policy.Status;

    @Type(() => Policy)
    policy: Policy;
    // createdBy: User;
}

export namespace PolicyLayer {
    export type DateToCompare = "effectiveDate" | "createdDate";

    export interface IHasPolicyWithPolicyTransactionType {
        policy: Policy.IHasPolicyTransactionType;
    }

    export interface IHasType {
        type: Policy.TransactionType;
    }

    export interface IHasStatus {
        status: Policy.Status;
    }

    export interface IHasPolicyWithEffectiveDate {
        policy: Policy.IHasEffectiveDate;
    }

    export interface IHasPolicyWithLayerEffectiveDate {
        policy: Policy.IHasLayerEffectiveDate;
    }

    export interface IHasEffectiveDate {
        effectiveDate: Date;
    }

    export interface IHasCreatedDate {
        createdDate: Date;
    }

    export interface IHasDates extends IHasEffectiveDate, IHasCreatedDate {}

    export type IGetNextEndorsementLayerPolicy = PolicyLayer.IHasEffectiveDate &
        PolicyLayer.IHasCreatedDate &
        PolicyLayer.IHasType &
        PolicyLayer.IHasPolicyWithPolicyTransactionType;

    export type IFilterType = PolicyLayer.IHasStatus & PolicyLayer.IHasDates;
    export type SortDirection = "ASC" | "DESC";
    export type IRelevantBilledLayers = PolicyLayer.IHasStatus &
        PolicyLayer.IHasEffectiveDate &
        PolicyLayer.IHasPolicyWithEffectiveDate;
    export interface IGetRelevantBilledLayersOptions {
        checkStatus?: boolean;
    }
    export type Comparison = PolicyLayerComparison;
    export namespace Comparison {
        export interface IChange<T> extends ComparisonObj.IChange<T> {}
    }
    export type Metadata = PolicyLayerMetadata;
}
