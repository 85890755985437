import { ArrowDownTrayIcon, ChevronDownIcon, ChevronLeftIcon, DocumentArrowUpIcon, FolderPlusIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import QueryString from "qs";
import { useContext, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ActionButton } from "../actionButton/ActionButton";
import { Checkbox } from "../checkbox/checkbox";
import { classNames } from "../classNames/classNames";
import { DataTable } from "../dataTable/DataTable";
import { Dropzone } from "../dropzone/Dropzone";
import { useSnackbar } from "../hooks/useSnackbar";
import { InputField } from "../inputField/inputField";
import { Popover } from "../popover/popover";
import { PrimaryButton } from "../primaryButton/primaryButton";
import { useAzureStorageBrowserColumns } from "./AzureStorageBrowserColumns";
import { AzureStorageBrowserContext } from "./AzureStorageBrowserContext";

const MAX_BREADCRUMBS = 5;

export function AzureStorageBrowser() {
    const { api, root, path, handlePathChange } = useContext(AzureStorageBrowserContext);
    const { data, isLoading, refetch } = api.useDirectory(root + path);
    const upload = api.useUpload({
        onError(error) {
            useSnackbar.add(error.message || "An error occurred while uploading", { variant: "error" });
        },
    });
    const downloadSnack = useRef<string | null>(null);
    const download = api.useDownload({
        onMutate() {
            if (downloadSnack.current) {
                useSnackbar.close(downloadSnack.current);
            }
            downloadSnack.current = useSnackbar.add("Preparing download...", { variant: "info", persist: true });
        },
        onError() {
            if (downloadSnack.current) {
                useSnackbar.update(downloadSnack.current, {
                    message: "An error occurred while downloading",
                    variant: "error",
                    persist: false,
                    autoHideDuration: 7000,
                });
                downloadSnack.current = null;
            }
        },
        onSuccess(file) {
            if (downloadSnack.current) {
                useSnackbar.update(downloadSnack.current, {
                    message: "Download complete",
                    variant: "success",
                    persist: false,
                    autoHideDuration: 7000,
                });
                downloadSnack.current = null;
            }
            const url = window.URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        },
    });
    const createDirectory = api.useCreateDirectory();

    const fileInput = useRef<HTMLInputElement>(null);
    const [search, setSearch] = useState("");
    const [newDirName, setNewDirName] = useState("");
    const folderNameInput = useRef<HTMLInputElement>(null);
    const [split, setSplit] = useState(false);
    const [splitSizeMb, setSplitSizeMb] = useState(19);

    const items = useMemo(() => {
        return data?.children || [];
    }, [data]);

    const pathFilter = useMemo(() => (search ? [] : [{ id: "path", value: data?.path }]), [search, data]);

    const table = useReactTable({
        data: items,
        columns: useAzureStorageBrowserColumns(refetch),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            columnFilters: pathFilter,
            globalFilter: search,
        },
        initialState: {
            sorting: [{ id: "name", desc: false }],
        },
        onGlobalFilterChange: setSearch,
    });

    const breadcrumbs: {
        name: string;
        path: string;
    }[] = [];

    root.split("/")
        .concat(path ? path.slice(1).split("/") : [])
        .forEach((breadcrumb, i, arr) => {
            if (breadcrumb) {
                breadcrumbs.push({
                    name: breadcrumb,
                    path: arr
                        .slice(0, i + 1)
                        .join("/")
                        .replace(root, ""),
                });
            }
        });

    return (
        <div className="relative w-max">
            {upload.isPending && (
                <div className="absolute inset-0 z-20 bg-black/10">
                    <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-2">
                        <CircularProgress size="3rem" classes={{ svg: "text-waypoint-orange" }} />
                        <p className="text-waypoint-blue-dark">Uploading files...</p>
                    </div>
                </div>
            )}
            <Dropzone
                id={`file-browser-${data?.path}`}
                onFileDrop={async (files) => {
                    if (data && !upload.isPending) {
                        if (files.length > 10) {
                            useSnackbar.add("You can only upload 10 files at a time", { variant: "error" });
                            return;
                        }
                        upload.mutate({ path: root + path, files });
                    }
                }}
            >
                {({ getProps, isDragActive, handleFileSelect }) => (
                    <div
                        {...getProps()}
                        className={classNames(
                            "w-max rounded border border-stone-300 p-4 lg:p-8",
                            isDragActive ? "bg-waypoint-orange/10" : "",
                            upload.isPending && "blur-[1px]"
                        )}
                    >
                        <input ref={fileInput} type="file" multiple onInput={handleFileSelect} className="hidden" />
                        <div className="mb-2 flex items-center gap-4">
                            <button
                                className="flex items-center gap-1 rounded-md p-2 disabled:opacity-50 enabled:hover:bg-stone-50"
                                disabled={data?.path === root}
                                onClick={() => {
                                    if (!data) {
                                        // if we can't find the directory from the URL, then go back to the root
                                        handlePathChange("");
                                    } else if (data.path !== root) {
                                        const newPath = data.path.replace(root, "").split("/").slice(0, -1).join("/");
                                        handlePathChange(newPath);
                                    }
                                }}
                            >
                                <ChevronLeftIcon className="h-5 w-5 text-stone-500" />
                            </button>
                            <div className="flex items-center">
                                {breadcrumbs.length > MAX_BREADCRUMBS && (
                                    <span className="whitespace-nowrap pr-2">
                                        <span className="px-2 text-stone-600">...</span>
                                        <span className="px-2 text-stone-400">/</span>
                                    </span>
                                )}
                                {breadcrumbs.slice(-MAX_BREADCRUMBS).map((breadcrumb, index, arr) => (
                                    <span key={index} className="whitespace-nowrap">
                                        <Link
                                            to={{
                                                search: QueryString.stringify({
                                                    path: breadcrumb.path,
                                                }),
                                            }}
                                        >
                                            {breadcrumb.name}
                                        </Link>
                                        {index < arr.length - 1 && <span className="px-2 text-stone-400">/</span>}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <DataTable
                            table={table}
                            onRowClick={async (row) => {
                                if (row.type === "directory") {
                                    // navigate
                                    handlePathChange(row.path.replace(root, ""));
                                } else {
                                    // download
                                    download.mutate({ path: row.path });
                                }
                            }}
                            headerRow={
                                <div className="flex items-center gap-2">
                                    <Popover>
                                        <Popover.Button
                                            className="flex items-center gap-2 p-2 enabled:hover:!border-waypoint-orange enabled:hover:!text-stone-900"
                                            as={ActionButton}
                                            disabled={!data}
                                        >
                                            <FolderPlusIcon className="h-4 w-4 text-waypoint-orange" />
                                            <span>New folder</span>
                                        </Popover.Button>
                                        <Popover.Content
                                            transitionProps={{
                                                afterEnter: () => {
                                                    folderNameInput.current?.focus();
                                                },
                                            }}
                                        >
                                            {({ close }) => (
                                                <form
                                                    onSubmit={async (e) => {
                                                        e.preventDefault();
                                                        if (!data) return;
                                                        if (newDirName && !newDirName.match(/[./\\]/)) {
                                                            createDirectory.mutate({ path: root + path, name: newDirName });
                                                            setNewDirName("");
                                                            refetch();
                                                            close();
                                                        } else {
                                                            useSnackbar.add("Invalid folder name", { variant: "error" });
                                                        }
                                                    }}
                                                >
                                                    <div className="flex w-max">
                                                        <InputField
                                                            ref={folderNameInput}
                                                            value={newDirName}
                                                            onChange={(e) => setNewDirName(e.target.value)}
                                                            placeholder="Folder name"
                                                            classes={{ inputContainer: "rounded-r-none ring-0" }}
                                                        />
                                                        <PrimaryButton type="submit" className="!rounded-l-none">
                                                            Create
                                                            <FolderPlusIcon className="ml-2 h-4 w-4" />
                                                        </PrimaryButton>
                                                    </div>
                                                </form>
                                            )}
                                        </Popover.Content>
                                    </Popover>
                                    <ActionButton className="" onClick={() => fileInput.current?.click()} disabled={!data}>
                                        <DocumentArrowUpIcon className="h-4 w-4 text-waypoint-blue" />
                                        Upload file
                                    </ActionButton>
                                    <div className="flex items-center rounded border border-stone-300 bg-white text-sm font-medium text-stone-600 hover:border-navigator-blue">
                                        <button
                                            className="flex items-center gap-2 rounded-l px-2 py-1 transition-all focus:border-transparent focus:ring-2 focus:ring-current disabled:opacity-60 enabled:hover:bg-stone-100 enabled:hover:text-navigator-primary"
                                            disabled={download.isPending}
                                            onClick={() => {
                                                download.mutate({
                                                    path: root + path,
                                                });
                                            }}
                                        >
                                            <ArrowDownTrayIcon className="h-4 w-4 text-waypoint-orange" />
                                            Download folder
                                        </button>
                                        <div className="h-[28px] w-px border-r"></div>
                                        <Popover>
                                            <Popover.Button className="flex h-[28px] items-center justify-center rounded-r px-2 enabled:hover:bg-stone-100 enabled:hover:text-navigator-blue">
                                                <ChevronDownIcon className="h-4 w-4" />
                                            </Popover.Button>
                                            <Popover.Content>
                                                {({ close }) => (
                                                    <div className="flex w-64 flex-col font-normal">
                                                        <div className="px-2 pb-2">
                                                            <Checkbox
                                                                classes={{ input: "w-4 h-4" }}
                                                                label="Split zip file"
                                                                checked={split}
                                                                onChange={(e) => setSplit(e.target.checked)}
                                                            />
                                                            {split && (
                                                                <div className="flex items-center gap-2 text-sm text-stone-600">
                                                                    <label htmlFor="splitSize">Split size (MB)</label>
                                                                    <input
                                                                        id="splitSize"
                                                                        className="w-12 rounded border p-1"
                                                                        type="number"
                                                                        value={splitSizeMb || "-"}
                                                                        onChange={(e) => {
                                                                            const value = e.target.valueAsNumber;
                                                                            if (value > 0) {
                                                                                setSplitSizeMb(e.target.valueAsNumber);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <button
                                                            className="flex w-full items-center gap-2 p-2 disabled:opacity-60 enabled:hover:!border-waypoint-orange enabled:hover:bg-stone-50 enabled:hover:!text-stone-900"
                                                            disabled={download.isPending}
                                                            onClick={() => {
                                                                download.mutate({
                                                                    path: root + path,
                                                                    splitSize: splitSizeMb ? splitSizeMb * 1024 * 1024 : undefined,
                                                                });
                                                                close();
                                                            }}
                                                        >
                                                            <ArrowDownTrayIcon className="h-4 w-4 text-waypoint-orange" />
                                                            <span>Download{split && " & split"}</span>
                                                        </button>
                                                        {split && (
                                                            <p className="mt-2 text-left text-sm text-stone-500">
                                                                You may not be able to open split zip files without using a 3rd-party tool
                                                            </p>
                                                        )}
                                                    </div>
                                                )}
                                            </Popover.Content>
                                        </Popover>
                                    </div>
                                </div>
                            }
                            className="bg-transparent lg:w-max lg:table-fixed [&>tbody>tr]:border-none [&>thead>tr:first-child>th]:static [&_th]:!static [&_th]:!bg-transparent"
                            isLoading={isLoading}
                        />
                    </div>
                )}
            </Dropzone>
        </div>
    );
}
