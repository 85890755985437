import { NumberFormatter } from "@deathstar/types/NumberFormatter";
import { Typography } from "@material-ui/core";
import React from "react";
import { PrintableTable } from "../Components/PrintableTable";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";

export function Mcs150History(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    return (
        <Section>
            <TitleGroup title={<Typography variant="h5">MCS-150 History</Typography>} className="mb-8" description={``} />

            <PrintableTable
                columnHeaders={["date", "mileage", "power units"]}
                data={motorCarrier.mcs150History.array().map((hist) => {
                    return {
                        key: hist.date.valueOf(),
                        date: hist.date.format("mm/dd/YY"),
                        mileage: NumberFormatter.Plain.format(hist.get("Mcs150MileageTotal") || 0),
                        "power units": hist.get("TotalPowerUnits") || 0,
                    };
                })}
            />
        </Section>
    );
}
