import { Member, MemberWithPermissions } from "@deathstar/types/waypoint";
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const members = {
    queryKeys: {
        list: (accountId: string) => [accountId, "members"] as QueryKey,
        get: (accountId: string, userId: string) => [accountId, "members", userId] as QueryKey,
    },

    list: (accountId: string) => {
        return blazar.fetchJson<Member[]>(`/waypoint/orgs/${accountId}/members`);
    },

    get: (accountId: string, userId: string) => {
        return blazar.fetchJson<MemberWithPermissions>(`/waypoint/orgs/${accountId}/members/${userId}`);
    },

    updatePermission: (accountId: string, userId: string, permissionId: string, granted: boolean) => {
        return blazar.fetchJson<MemberWithPermissions>(`/waypoint/orgs/${accountId}/members/${userId}`, {
            method: "PATCH",
            body: JSON.stringify({
                permissionId,
                granted,
            }),
        });
    },

    promote: (accountId: string, userId: string) => {
        return blazar.fetchJson<MemberWithPermissions>(`/waypoint/orgs/${accountId}/members/${userId}/promote`, {
            method: "POST",
        });
    },

    demote: (accountId: string, userId: string) => {
        return blazar.fetchJson<MemberWithPermissions>(`/waypoint/orgs/${accountId}/members/${userId}/demote`, {
            method: "POST",
        });
    },

    remove: (accountId: string, userId: string) => {
        return blazar.fetchJson<MemberWithPermissions>(`/waypoint/orgs/${accountId}/members/${userId}`, {
            method: "DELETE",
        });
    },

    useList: <T = Member[]>(accountId: string, config?: Partial<UseQueryOptions<Member[], ResponseError, T>>) =>
        useQuery<Member[], ResponseError, T>({
            queryKey: members.queryKeys.list(accountId),
            queryFn: () => members.list(accountId),
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            staleTime: 1000 * 60,
            ...config,
        }),

    useGet: <T = MemberWithPermissions>(
        accountId: string,
        userId: string,
        config?: Partial<UseQueryOptions<MemberWithPermissions, ResponseError, T>>
    ) =>
        useQuery<MemberWithPermissions, ResponseError, T>({
            queryKey: members.queryKeys.get(accountId, userId),
            queryFn: () => members.get(accountId, userId),
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            staleTime: 1000 * 60,
            ...config,
        }),
};
