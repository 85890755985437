import { classNames } from "@deathstar/ui";
import { CheckIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { stringifyAddress } from "../certificates/certificatesUtil";

export default function AddressSelector({ value, onChange }: { value: number; onChange(value: number | null): void }) {
    const accountId = useAccountId();
    const { data } = api.properties.useFind(accountId!, {
        select: (properties) => properties.filter((p) => p.typeString?.toLowerCase().includes("terminal")),
    });

    return (
        <div className="w-max">
            <p>
                Terminal <span className="text-xs text-stone-500">(click to select one)</span>
            </p>
            {data ? (
                data.map((property) => (
                    <button
                        type="button"
                        key={property.id}
                        className={classNames(
                            "flex w-full items-center gap-2 rounded px-2 py-1 text-stone-500 disabled:opacity-50 enabled:hover:bg-stone-100",
                            value === property.id ? "font-medium text-waypoint-blue" : ""
                        )}
                        onClick={() => {
                            if (value === property.id) {
                                onChange(null);
                            } else {
                                onChange(property.id);
                            }
                        }}
                    >
                        <div className="h-4 w-4">{value === property.id ? <CheckIcon className="h-4 w-4" /> : ""}</div>
                        {stringifyAddress({
                            street: property.street1,
                            street2: property.street2,
                            city: property.city,
                            state: property.state,
                            zip: property.zip,
                        })}
                    </button>
                ))
            ) : (
                <div className="flex w-full justify-center">
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}
