import { isBefore, isEqual } from "date-fns";

export class GeneralLedgerTransaction {
    static isPossiblePayment(glt: GeneralLedgerTransaction, policyEffectiveDate: Date) {
        if (
            ![3, 4].includes(glt.sourceId) ||
            isBefore(glt.effectiveDate || glt.enteredDate, policyEffectiveDate) ||
            isEqual(glt.effectiveDate || glt.enteredDate, policyEffectiveDate) ||
            glt.description === "Company Statement"
        ) {
            return false;
        }
        return true;
    }

    id: string;
    invoiceId: string;
    description?: string;
    debitAmount: number;
    creditAmount: number;
    enteredDate: Date;
    effectiveDate?: Date;
    glNumber: string;
    sourceId?: number;
    sourceTypeId?: number;
}
