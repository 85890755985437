import { Transform } from "class-transformer";
import { IsArray, IsBoolean, IsDateString, IsEnum, IsInt, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";

export interface CertificateHolderFormData {
    CertificateHolder_FullName: string;
    CertificateHolder_MailingAddress_LineOne: string;
    CertificateHolder_MailingAddress_LineTwo: string;
    CertificateHolder_MailingAddress_CityName: string;
    CertificateHolder_MailingAddress_StateOrProvinceCode: string;
    CertificateHolder_MailingAddress_PostalCode: string;
}

export interface CertificateFormData extends BaseCertificateFormData, CertificateHolderFormData {
    Form_CompletionDate: string;
    Producer_AuthorizedRepresentative_Signature: Buffer;
}
export class BaseCertificateFormData {
    NamedInsured_FullName: string;
    NamedInsured_MailingAddress_LineOne: string;
    NamedInsured_MailingAddress_LineTwo: string;
    NamedInsured_MailingAddress_CityName: string;
    NamedInsured_MailingAddress_StateOrProvinceCode: string;
    NamedInsured_MailingAddress_PostalCode: string;
    Producer_ContactPerson_FullName: string;
    Producer_ContactPerson_PhoneNumber: string;
    Producer_ContactPerson_EmailAddress: string;
    Producer_FaxNumber: string;
    Insurer_FullName_A: string;
    Insurer_NAICCode_A: string;
    Insurer_FullName_B: string;
    Insurer_NAICCode_B: string;
    Insurer_NAICCode_C: string;
    Insurer_FullName_C: string;
    Insurer_NAICCode_D: string;
    Insurer_FullName_D: string;
    Insurer_NAICCode_E: string;
    Insurer_FullName_E: string;
    Insurer_FullName_F: string;
    Insurer_NAICCode_F: string;
    GenLiab_CoverageIndicator: boolean;
    GenLiab_OccurrenceIndicator: boolean;
    GenLiab_OtherCoverageIndicator_A: boolean;
    GenLiab_ClaimsMadeIndicator: boolean;
    GenLiab_OtherCoverageIndicator_B: boolean;
    GenLiab_AdditionalInsuredIndicator: boolean;
    Policy_GenLiab_SubrogationWaivedIndicator: boolean;
    Policy_GenLiab_PolicyNumberIdentifier: string;
    Policy_GenLiab_EffectiveDate: string;
    Policy_GenLiab_ExpirationDate: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    GenLiab_EachOccurrence_LimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    GenLiab_FireDamageRentedPremises_EachOccurrenceLimitAmount: number;
    GenLiab_OtherCoverageDescription_A: string;
    GenLiab_OtherCoverageDescription_B: string;
    GenLiab_GeneralAggregate_LimitAppliesPerPolicyIndicator: boolean;
    GenLiab_GeneralAggregate_LimitAppliesToOtherIndicator: boolean;
    GenLiab_GeneralAggregate_LimitAppliesPerProjectIndicator: boolean;
    GenLiab_GeneralAggregate_LimitAppliesPerLocationIndicator: boolean;
    GenLiab_GeneralAggregate_LimitAppliesToCode: string;
    GenLiab_OtherCoverageLimitDescription: string;
    GenLiab_InsurerLetterCode: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    GenLiab_MedicalExpense_EachPersonLimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    GenLiab_PersonalAndAdvertisingInjury_LimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    GenLiab_GeneralAggregate_LimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    GenLiab_ProductsAndCompletedOperations_AggregateLimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    GenLiab_OtherCoverageLimitAmount: number;
    Vehicle_OtherCoveredAutoDescription_A: string;
    Vehicle_OtherCoveredAutoIndicator_A: boolean;
    Vehicle_OtherCoveredAutoIndicator_B: boolean;
    Vehicle_OtherCoveredAutoDescription_B: string;
    Vehicle_AnyAutoIndicator: boolean;
    Vehicle_AllOwnedAutosIndicator: boolean;
    Vehicle_HiredAutosIndicator: boolean;
    Vehicle_ScheduledAutosIndicator: boolean;
    Vehicle_NonOwnedAutosIndicator: boolean;
    AutomobileLiability_AdditionalInsuredIndicator: boolean;
    Policy_AutomobileLiability_SubrogationWaivedIndicator: boolean;
    Policy_AutomobileLiability_PolicyNumberIdentifier: string;
    Policy_AutomobileLiability_EffectiveDate: string;
    Policy_AutomobileLiability_ExpirationDate: string;
    Vehicle_InsurerLetterCode: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    Vehicle_CombinedSingleLimit_EachAccidentAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    Vehicle_BodilyInjury_PerPersonLimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    Vehicle_BodilyInjury_PerAccidentLimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    Vehicle_PropertyDamage_PerAccidentLimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    Vehicle_OtherCoverage_LimitAmount: number;
    Vehicle_OtherCoverage_CoverageDescription: string;
    Policy_PolicyType_UmbrellaIndicator: boolean;
    Policy_PolicyType_ExcessIndicator: boolean;
    Policy_ExcessLiability_PolicyNumberIdentifier: string;
    ExcessLiability_AdditionalInsuredIndicator: boolean;
    Policy_ExcessLiability_SubrogationWaivedIndicator: boolean;
    Policy_ExcessLiability_EffectiveDate: string;
    Policy_ExcessLiability_ExpirationDate: string;
    ExcessUmbrella_DeductibleIndicator: boolean;
    ExcessUmbrella_RetentionIndicator: boolean;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    ExcessUmbrella_Umbrella_DeductibleOrRetentionAmount: number;
    ExcessUmbrella_ClaimsMadeIndicator: boolean;
    ExcessUmbrella_OccurrenceIndicator: boolean;
    ExcessUmbrella_InsurerLetterCode: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    ExcessUmbrella_Umbrella_EachOccurrenceAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    ExcessUmbrella_Umbrella_AggregateAmount: number;
    ExcessUmbrella_OtherCoverageDescription: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    ExcessUmbrella_OtherCoverageLimitAmount: number;
    WorkersComp_OtherCoverageIndicator: boolean;
    WorkersComp_SubrogationWaivedIndicator: boolean;
    WorkersComp_AnyPersonsExcludedIndicator: boolean;
    WorkersComp_PolicyNumberIdentifier: string;
    WorkersComp_EffectiveDate: string;
    WorkersComp_ExpirationDate: string;
    WorkersComp_InsurerLetterCode: string;
    WorkersComp_StatutoryLimitIndicator: boolean;
    WorkersComp_OtherCoverageDescription: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    WorkersComp_DiseasePolicyLimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    WorkersComp_DiseaseEachEmployeeLimitAmount: number;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    WorkersComp_EachAccidentLimitAmount: number;
    OtherPolicy_InsurerLetterCode: string;
    OtherPolicy_OtherPolicyDescription: string;
    OtherPolicy_AdditionalInsuredIndicator: boolean;
    OtherPolicy_SubrogationWaivedIndicator: boolean;
    OtherPolicy_PolicyNumberIdentifier: string;
    OtherPolicy_PolicyEffectiveDate: string;
    OtherPolicy_CoverageCode_A: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    OtherPolicy_CoverageLimitAmount_A: number;
    OtherPolicy_CoverageCode_B: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    OtherPolicy_CoverageLimitAmount_B: number;
    OtherPolicy_CoverageCode_C: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    OtherPolicy_CoverageLimitAmount_C: number;
    OtherPolicy_PolicyExpirationDate: string;
    Producer_FullName: string;
    Producer_MailingAddress_LineOne: string;
    Producer_MailingAddress_LineTwo: string;
    Producer_MailingAddress_CityName: string;
    Producer_MailingAddress_StateOrProvinceCode: string;
    Producer_MailingAddress_PostalCode: string;
    RemarkText: string;
    @Transform(({ value }) => (typeof value === "number" ? value : value ? parseInt(value) : null))
    RevisionNumberIdentifier: number;
}

export enum CertificateMethods {
    SendToHolders,
    AddEachToNorthstarFolder,
    SendToOtherEmail,
    AddJoinedToNorthstarFolder,
}

export enum EffectiveDateMethod {
    AsOf,
    Latest,
    Now,
}

enum BuilderConfigItemId {
    HideCoverageWC,
    HideCoverageXSAL,
    HideCoverageXSC,
    HideCoveragePD,
    HideCoverageCargo,
    HideCoverageOCAC,
    HideCoveragePOLL,
    HideBlanketAI,
    HideAtOneLossAtAnyTerminal,
    HideNonTruckingCheckbox,
    HideHiredPhysicalDamage,
    HideWorkersCompensationExcludedPersons,
    HideTrailerInterchange,
    HideFirstDollarDeductible,
    AdditionalRemarks,
    AdditionalTractors,
    AdditionalTrailers,
    OwnerOperators,
}

export class CertificateBuilderConfigItem {
    static Id = BuilderConfigItemId;
    @IsEnum(BuilderConfigItemId)
    id: BuilderConfigItemId;

    @IsOptional()
    value?: unknown;
}

export class CertificateQueryOptions {
    @IsOptional()
    @IsDateString()
    effectiveDate?: string;

    @IsEnum(EffectiveDateMethod)
    method: EffectiveDateMethod;
}

export class CertificateOptions {
    @IsArray()
    @IsNumber({}, { each: true })
    holderIds: number[];

    @IsOptional()
    @IsString({ each: true })
    emailTo?: string[];

    @IsOptional()
    @IsString({ each: true })
    emailCC?: string[];

    @IsOptional()
    @IsString()
    body?: string;

    @IsOptional()
    @IsString()
    subject?: string;

    @IsOptional()
    @IsInt()
    sendMethod: CertificateMethods = CertificateMethods.SendToHolders;

    @IsOptional()
    @IsBoolean()
    includeInsuredCopy?: boolean;

    @IsOptional()
    @IsString()
    insuredCopyEmailTo?: string;

    @IsOptional()
    @IsArray()
    @ValidateNested({
        each: true,
    })
    builderOptions: CertificateBuilderConfigItem[];
}

export interface CertificateJob extends CertificateOptions {
    masterCertificateId: number;
    accountId: string;
    senderGuid: string;
    queryOptions: CertificateQueryOptions;
}

export interface DirectSendCertificateResult {
    email: { to: string[]; cc?: string[] };
    success: boolean;
}
