import { RawData } from "@deathstar/reuse";
import type { MotorCarrier } from "./MotorCarrier";

export abstract class Data<T> extends RawData<T> {
    // ========================================================================
    #carrier;

    constructor({ carrier, raw }: Data.Options<T>) {
        super(raw);
        this.#carrier = carrier;
    }

    // ========================================================================
    protected get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    protected get dot(): number {
        return this.#carrier.dot;
    }
}

export declare namespace Data {
    interface Options<T> {
        carrier: MotorCarrier;
        raw: T;
    }
}
