import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { Link, Navigate, Outlet, Route, Routes } from "react-router-dom";
import Logo from "../../components/logo/Logo";

enum Categories {
    Disclaimers = "Disclaimers",
    Privacy = "Privacy",
}

const categories = [Categories.Disclaimers, Categories.Privacy];

const definitions = [
    {
        path: "policy-disclaimer",
        title: "Policy disclaimer",
        category: Categories.Disclaimers,
    },
    {
        path: "resources-disclaimer",
        title: "Resources disclaimer",
        category: Categories.Disclaimers,
    },
    {
        path: "sms-opt-in",
        title: "SMS opt-in",
        category: Categories.Disclaimers,
    },
    {
        path: "askanything-privacy-policy",
        title: "Ask Anything privacy policy",
        category: Categories.Privacy,
    },
    {
        path: "refund-policy",
        title: "Refund policy",
        category: Categories.Disclaimers,
    },
];

export default function Legal() {
    return (
        <Routes>
            <Route
                element={
                    <div className="mx-auto w-max">
                        <Link to="/">
                            <div className="p-4">
                                <Logo />
                            </div>
                        </Link>
                        <div className="flex w-max flex-col-reverse gap-0 border-t md:flex-row">
                            <nav className="w-full shrink-0 space-y-4 border-t p-8 lg:w-max lg:border-r lg:border-t-0 lg:pt-12">
                                {categories.map((category) => (
                                    <div key={category}>
                                        <p className="font-light tracking-wide text-stone-500 small-caps">{category}</p>
                                        <ul className="ml-4 space-y-2">
                                            {definitions
                                                .filter((d) => d.category === category)
                                                .map((def) => (
                                                    <li key={def.path}>
                                                        <Link className="text-stone-700 hover:text-waypoint-blue" to={def.path}>
                                                            {def.title}
                                                        </Link>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                ))}
                            </nav>
                            <div className="markdown w-screen p-8 pt-12 lg:max-w-[800px]">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                }
            >
                <Route index element={<Navigate to={definitions[0].path} replace />} />

                {definitions.map((def) => (
                    <Route key={def.path} path={def.path} element={<MyMarkdown path={def.path} />} />
                ))}
            </Route>
        </Routes>
    );
}

function MyMarkdown({ path }: { path: string }) {
    const [markdown, setMarkdown] = useState<string>("");

    useEffect(() => {
        import(`../../assets/legal/${path}.md?raw`).then((x) => {
            setMarkdown(x.default);
        });
    });

    return <Markdown>{markdown}</Markdown>;
}
