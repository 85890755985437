import { MotorCarrier } from "@deathstar/motor-carrier";
import { createContext } from "react";

export interface IContext {
    activeTab: number;
    setActiveTab: (tab: number) => void;
    motorCarrier: MotorCarrier;
}

export const Context = createContext<IContext>({
    activeTab: NaN,
    setActiveTab: null!,
    motorCarrier: null!,
});
