import { Alert, InputField } from "@deathstar/ui";
import { validate } from "email-validator";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { UnauthorizedError } from "../../../api/util/exceptions";
import { ActionButton } from "../../../components/action-button/ActionButton";
import { PrimaryActionButton } from "../../../components/action-button/PrimaryActionButton";
import { Dialog } from "../../../components/dialog/Dialog";

export default function InviteUserDialog({ open, onClose }: { open: boolean; onClose(): void }) {
    const formRef = useRef<HTMLFormElement>(null);
    const accountId = useAccountId();
    const { register, handleSubmit, formState, ...form } = useForm({
        defaultValues: {
            email: "",
        },
    });

    const inviteUser = api.invitations.useSend(accountId!, {
        onSuccess: () => {
            setTimeout(() => {
                form.reset();
                onClose();
                inviteUser.reset();
            }, 2300);
        },
    });

    return (
        <Dialog open={open} onClose={onClose} className="w-full md:w-[448px]">
            <div className="w-full">
                <p className="p-4 text-lg font-medium leading-6 text-navigator-primary-dark">Invite organization member</p>
                <hr className="mb-4" />
                <div className="overflow-auto p-4">
                    <form
                        id="invite-user-form"
                        ref={formRef}
                        onSubmit={handleSubmit((data) => {
                            if (!inviteUser.isSuccess) {
                                inviteUser.mutate(data);
                            }
                        })}
                    >
                        <InputField
                            placeholder="johndoe@example.com"
                            {...register("email", {
                                validate: (value) => validate(value) || "Please enter a valid email address",
                            })}
                            error={!!formState.errors.email}
                            helperText={formState.errors.email?.message}
                        />
                    </form>
                </div>
                {inviteUser.isSuccess && <Alert variant="success">Invitation email sent!</Alert>}
                {inviteUser.isError && (
                    <Alert variant="error">
                        {inviteUser.error instanceof UnauthorizedError
                            ? "You do not have permission to invite users."
                            : "An error occurred. Please try again later."}
                    </Alert>
                )}
                <hr />
                <div className="flex items-center justify-end gap-4 p-4">
                    <ActionButton
                        onClick={() => {
                            form.reset();
                            onClose();
                            inviteUser.reset();
                        }}
                    >
                        Cancel
                    </ActionButton>

                    <PrimaryActionButton type="submit" form="invite-user-form" disabled={inviteUser.isSuccess}>
                        Invite user
                    </PrimaryActionButton>
                </div>
            </div>
        </Dialog>
    );
}
