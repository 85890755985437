import { TextField, TextFieldProps, withStyles } from "@material-ui/core";
import { classNames } from "../classNames/classNames";

const CustomTextField = withStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root": {
            color: "currentColor",
            "&:hover:not(.Mui-disabled) fieldset": {
                borderColor: theme.palette.primary.dark,
                transition: "border-color 0.15s ease-in-out",
            },
            "&.Mui-focused fieldset": {
                borderWidth: "2px",
            },
        },
    },
}))(TextField);

export function Input(props: Omit<TextFieldProps, "variant">): JSX.Element {
    return (
        <CustomTextField
            {...props}
            className={classNames("transition duration-0 hover:text-navigator-blue hover:duration-500", props?.className)}
            InputProps={{
                ...props?.InputProps,
                className: classNames("text-sm", props?.InputProps?.className),
            }}
            variant="outlined"
            size="small"
        />
    );
}
