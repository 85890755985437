import { Checkbox } from "@deathstar/ui";
import { Dialog } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";

export function DriverLinker({
    open,
    currentValue,
    onChange,
    onClose,
}: {
    open: boolean;
    currentValue: { id: number }[];
    onChange(value: { id: number }[]): unknown;
    onClose(): void;
}) {
    const accountId = useAccountId();
    const { data } = api.drivers.useFind(accountId!);

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: "p-4 max-w-none" }}>
            <table className="table-auto text-sm col-spacing-4 row-p-2 row-spacing-2">
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">Name</th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">License</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((driver) => {
                        const isSelected = currentValue.some((t) => t.id === driver.id);
                        return (
                            <tr
                                key={driver.id}
                                className="cursor-pointer hover:bg-gray-100 [&>td:first-child]:rounded-l [&>td:last-child]:rounded-r"
                                onClick={async () => {
                                    if (isSelected) {
                                        onChange(currentValue.filter((t) => t.id !== driver.id));
                                    } else {
                                        onChange([...currentValue, { id: driver.id }]);
                                    }
                                }}
                            >
                                <td>
                                    <Checkbox classes={{ input: "w-4 h-4" }} checked={isSelected} readOnly autoFocus={isSelected} />
                                </td>
                                <td>{driver.name}</td>
                                <td>{driver.license}</td>
                            </tr>
                        );
                    }) || (
                        <>
                            <tr>
                                <td colSpan={3}>
                                    <Skeleton variant="rect" width="100%" height={32} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <Skeleton variant="rect" width="100%" height={32} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <Skeleton variant="rect" width="100%" height={32} />
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </Dialog>
    );
}
