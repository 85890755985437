import { plainToClass, Type } from "class-transformer";
import { NumberFormatter } from "../util";
import { Attribute } from "./Attribute";
import { AttributeOption } from "./AttributeOption";
import { Comparison } from "./Comparison/Comparison";
import { Coverage } from "./Coverage";
import { CoverageLinkedVehicle } from "./CoverageLinkedVehicle";
import { CoverageOption } from "./CoverageOption";
import { EquipmentComparison } from "./PolicyLayer";
import { Tractor } from "./Tractor";

export class CoverageLinkedTractor implements CoverageLinkedVehicle, EquipmentComparison.IIsComparable {
    static init(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): CoverageLinkedTractor {
        const { linkedTractor } = options;
        if (linkedTractor instanceof CoverageLinkedTractor && linkedTractor.#initialized) return linkedTractor;

        const e = plainToClass(CoverageLinkedTractor, linkedTractor);
        e.coverage = options.coverage;
        e.attributes = CoverageLinkedTractor.getAttributes(options);
        e.statedValue = CoverageLinkedTractor.getStatedValue(options);
        e.hasCollision = CoverageLinkedTractor.hasCollision(options);
        e.uniqueCollisionDeductible = CoverageLinkedTractor.getUniqueCollisionDeductible(options);
        e.hasComprehensive = CoverageLinkedTractor.hasComprehensive(options);
        e.uniqueComprehensiveDeductible = CoverageLinkedTractor.getUniqueComprehensiveDeductible(options);
        e.isSpareRated = CoverageLinkedTractor.getIsSpareRated(options);
        e.isOwnerOperator = CoverageLinkedTractor.getIsOwnerOperator(options);
        e.isValuationActualCashValue = CoverageLinkedTractor.getIsValuationActualCashValue(options);
        e.#initialized = true;
        return e;
    }

    static getAttributes({ coverage, linkedTractor }: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): Attribute[] {
        if (!coverage) return [];
        return coverage.attributes.filter((a) => a.tractorId === linkedTractor.tractorId);
    }

    static hasAttribute({
        coverage,
        linkedTractor,
        attributeOptionId,
    }: {
        coverage?: Coverage;
        linkedTractor: CoverageLinkedTractor;
        attributeOptionId: AttributeOption.Id;
    }): boolean {
        const tractorAttributes = CoverageLinkedTractor.getAttributes({ coverage, linkedTractor });
        return tractorAttributes.some((a) => a.attributeOptionId === attributeOptionId);
    }

    static #getAttributes({ coverage, linkedTractor }: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): Attribute[] {
        return linkedTractor.attributes || CoverageLinkedTractor.getAttributes({ coverage, linkedTractor });
    }

    static #getComprehensiveAttribute(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): Attribute {
        return CoverageLinkedTractor.#getAttributes(options).find((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COMPREHENSIVE);
    }

    static hasComprehensive(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): boolean {
        return CoverageLinkedTractor.#getAttributes(options).some((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COMPREHENSIVE);
    }

    static #getCollisionAttribute(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): Attribute {
        return CoverageLinkedTractor.#getAttributes(options).find((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COLLISION);
    }

    static hasCollision(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): boolean {
        return CoverageLinkedTractor.#getAttributes(options).some((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COLLISION);
    }

    static getUniqueComprehensiveDeductible(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): number | null {
        const compAttr = CoverageLinkedTractor.#getComprehensiveAttribute(options);
        if (!compAttr) return null;
        return typeof compAttr.valueNumber === "number" ? compAttr.valueNumber : null;
    }

    static getUniqueCollisionDeductible(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): number | null {
        const compAttr = CoverageLinkedTractor.#getCollisionAttribute(options);
        if (!compAttr) return null;
        return typeof compAttr.valueNumber === "number" ? compAttr.valueNumber : null;
    }

    static getStatedValue(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): number | null {
        const statedValueAttr = CoverageLinkedTractor.#getAttributes(options).find(
            (a) => a.attributeOptionId === AttributeOption.Id.STATED_VALUE
        );
        return statedValueAttr?.valueNumber || null;
    }

    static getIsSpareRated(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): boolean {
        return CoverageLinkedTractor.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.UNIT_SPARE_RATED });
    }

    static getIsOwnerOperator(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): boolean {
        return CoverageLinkedTractor.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.UNIT_OWNER_OPERATOR });
    }

    static getIsValuationActualCashValue(options: { coverage?: Coverage; linkedTractor: CoverageLinkedTractor }): boolean {
        return CoverageLinkedTractor.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE });
    }

    static compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Comparison.IOptions<CoverageLinkedTractor>): Map<CoverageOption.Id, CoverageLinkedTractor.IComparisonReturn> {
        const map = new Map<CoverageOption.Id, CoverageLinkedTractor.IComparisonReturn>();

        if (compare) {
            for (const [cvgOptId, linkedTractor] of Object.entries(compare) as unknown as [CoverageOption.Id, CoverageLinkedTractor][]) {
                const from = base?.[cvgOptId];
                const to = linkedTractor;
                map.set(cvgOptId, CoverageLinkedTractor.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        if (base) {
            for (const [cvgOptId, linkedTractor] of Object.entries(base) as unknown as [CoverageOption.Id, CoverageLinkedTractor][]) {
                if (map.has(cvgOptId)) continue;
                const from = linkedTractor;
                const to = compare?.[cvgOptId];
                map.set(cvgOptId, CoverageLinkedTractor.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        return map;
    }

    static #compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Partial<Comparison.IOptions.Entity<CoverageLinkedTractor>>): CoverageLinkedTractor.IComparisonReturn {
        const comparison = new Comparison(CoverageLinkedTractor, base, compare) as CoverageLinkedTractor.IComparisonReturn;
        comparison.setField("tractorId", compare?.tractorId || base?.tractorId);
        comparison.setField("tractor", compare?.tractor || base?.tractor);

        CoverageLinkedVehicle.compare({
            comparison,
            base,
            compare,
            comparisonLayer,
            baseLayer,
            label: "Tractor",
            descriptions: {
                add: `Add tractor ${compare?.toString()}`,
                remove: `Delete tractor ${base?.tractor?.vin}`,
            },
        });

        return comparison;
    }

    #initialized = false;

    id: number;
    createdDate: Date;

    tractorId: number;
    @Type(() => Tractor)
    tractor?: Tractor;

    coverageId: number;
    coverageCreatedDate: Date;
    coverage?: Coverage;

    attributes?: Attribute[];
    statedValue?: number;
    hasCollision?: boolean;
    uniqueCollisionDeductible?: number | null;
    hasComprehensive?: boolean;
    uniqueComprehensiveDeductible?: number | null;
    isSpareRated?: boolean;
    isOwnerOperator?: boolean;
    isValuationActualCashValue?: boolean;
    comparison?: {
        isNewToPolicy?: boolean;
        isDeletedFromPolicy?: boolean;
    };

    compare(
        comparisonLinkedTractor: CoverageLinkedTractor,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CoverageLinkedTractor> {
        return CoverageLinkedTractor.#compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonLinkedTractor,
                },
                options
            )
        );
    }

    toString(): string {
        if (!this.tractor) {
            return `CoverageLinkedTractor: ${this.tractorId}`;
        }
        const baseStringParts = [this.tractor.toString()];
        if (this.isValuationActualCashValue) {
            baseStringParts.push("(ACV)");
        } else if (typeof this.statedValue === "number") {
            baseStringParts.push(`(SV: ${NumberFormatter.Currency.format(this.statedValue)})`);
        }
        return baseStringParts.join(" ");
    }
}

export namespace CoverageLinkedTractor {
    export interface IComparisonReturn extends Comparison<CoverageLinkedTractor> {
        tractorId: number;
        tractor?: Tractor;
    }
}
