import QueryString from "qs";

interface JotFormUrlData {
    companyName: string;
    userEmail: string;
    userName: string;
    policyNumber: string;
}

export interface JotForm<T = JotFormUrlData> {
    getUrl: (data: T) => string;
    title: string;
    description: string;
}
export const JotForms = {
    DriverQualifications: {
        getUrl: () => {
            return "https://form.jotform.com/71095662521152";
        },
        title: "View driver qualifications",
        description: "View driver qualifications",
    } as JotForm<unknown>,
    AddDriver: {
        getUrl: ({ companyName, userEmail, policyNumber }) => {
            return (
                "https://form.jotform.us/71584771018157" +
                QueryString.stringify({ companyName, email: userEmail, policyNumber }, { addQueryPrefix: true })
            );
        },
        title: "Add driver",
        description: "Add driver",
    } as JotForm<Pick<JotFormUrlData, "companyName" | "userEmail" | "policyNumber">>,
    RemoveDriver: {
        getUrl: ({ companyName, userEmail, policyNumber }) => {
            return (
                "https://form.jotform.us/71584859118165" +
                QueryString.stringify({ companyName, email: userEmail, policyNumber }, { addQueryPrefix: true })
            );
        },
        title: "Remove driver",
        description: "Remove driver",
    } as JotForm<Pick<JotFormUrlData, "companyName" | "userEmail" | "policyNumber">>,
    AddEquipment: {
        getUrl: ({ companyName, userEmail, policyNumber }) => {
            return (
                "https://form.jotform.us/71585749318166" +
                QueryString.stringify({ companyName, yourEmail: userEmail, policyNumber }, { addQueryPrefix: true })
            );
        },
        title: "Add equipment",
        description: "Add equipment",
    } as JotForm<Pick<JotFormUrlData, "companyName" | "userEmail" | "policyNumber">>,
    RemoveEquipment: {
        getUrl: ({ companyName, userEmail, policyNumber }) => {
            return (
                "https://form.jotform.us/71585101318148" +
                QueryString.stringify({ companyName, yourEmail: userEmail, policyNumber }, { addQueryPrefix: true })
            );
        },
        title: "Remove equipment",
        description: "Remove equipment",
    } as JotForm<Pick<JotFormUrlData, "companyName" | "userEmail" | "policyNumber">>,
    Mileage: {
        getUrl: ({ policyNumber }) => {
            return "https://form.jotform.us/71913995718168" + QueryString.stringify({ policyNumber }, { addQueryPrefix: true });
        },
        title: "Report mileage",
        description: "Report mileage",
    } as JotForm<JotFormUrlData>,
    EquipmentValues: {
        getUrl: ({ policyNumber }) => {
            return "https://form.jotform.us/71806555818162" + QueryString.stringify({ policyNumber }, { addQueryPrefix: true });
        },
        title: "Report equipment values",
        description: "Report equipment values",
    } as JotForm<Pick<JotFormUrlData, "policyNumber">>,
    Receipts: {
        getUrl: ({ userEmail, policyNumber }) => {
            return (
                "https://form.jotform.us/71913791018155" +
                QueryString.stringify({ policyNumber, yourEmail: userEmail }, { addQueryPrefix: true })
            );
        },
        title: "Report receipts",
        description: "Report receipts",
    } as JotForm<Pick<JotFormUrlData, "policyNumber" | "userEmail">>,
    WorkersComp: {
        getUrl: ({ userEmail, policyNumber }) => {
            return (
                "https://form.jotform.us/71914535418156" +
                QueryString.stringify({ yourEmail: userEmail, policyNumber }, { addQueryPrefix: true })
            );
        },
        title: "Report workers' comp.",
        description: "Report workers' comp.",
    } as JotForm<Pick<JotFormUrlData, "userEmail" | "policyNumber">>,
    PaymentInfoForDirectBillClients: {
        getUrl: () => {
            return "https://form.jotform.us/71694713518160";
        },
        title: "View payment info",
        description: "View payment info",
    } as JotForm<unknown>,
    ACHAuthForAgencyBillClients: {
        getUrl: ({ companyName, userEmail, userName, policyNumber }) => {
            const name = userName.split(" ");
            return (
                "https://form.jotform.us/71085341218148" +
                QueryString.stringify(
                    { name: { first: name?.[0], last: name?.[1] }, companyName, emailAddress: userEmail, policyNumber },
                    { addQueryPrefix: true }
                )
            );
        },
        title: "View ACH form",
        description: "View ACH authorization form",
    } as JotForm<JotFormUrlData>,
};
