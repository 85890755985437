import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import MemberSetupComponent from "../../features/member-setup/MemberSetup";

const MemberSetup = withAuthenticationRequired(
    function () {
        return (
            <Routes>
                <Route index element={<MemberSetupComponent />} />
            </Routes>
        );
    },
    {
        returnTo: window.location.pathname,
        loginOptions: { authorizationParams: { redirect_uri: window.location.origin + "/member-setup" } },
    }
);

export default MemberSetup;
