import { Interpolation, Theme } from "@emotion/react";
import { Collapse, CollapseProps, Fade, FadeProps, Grow, GrowProps, Slide, SlideProps } from "@material-ui/core";
import React from "react";
import { useInView } from "react-intersection-observer";

function Slider({
    children,
    className,
    delay,
    SlideProps,
    ...props
}: {
    children: React.ReactElement;
    className?: string;
    delay?: number;
    style?: React.CSSProperties;
    SlideProps?: SlideProps;
} & React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement> & {
        css?: Interpolation<Theme>;
    }) {
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    return (
        <div ref={ref} className={className} {...props}>
            <Slide
                {...SlideProps}
                in={inView}
                timeout={{
                    enter: 500,
                }}
                style={{
                    transitionDelay: inView && delay ? `${delay}ms` : "0ms",
                    ...SlideProps?.style,
                }}
            >
                {children}
            </Slide>
        </div>
    );
}

function Fader({
    children,
    className,
    delay,
    FadeProps,
    ...props
}: {
    children: React.ReactElement;
    className?: string;
    delay?: number;
    style?: React.CSSProperties;
    FadeProps?: FadeProps;
} & React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement> & {
        css?: Interpolation<Theme>;
    }) {
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    return (
        <div ref={ref} className={className} {...props}>
            <Fade
                {...FadeProps}
                in={inView}
                timeout={{
                    enter: 500,
                }}
                style={{
                    transitionDelay: inView && delay ? `${delay}ms` : "0ms",
                    ...FadeProps?.style,
                }}
            >
                {children}
            </Fade>
        </div>
    );
}

function Collapser({
    children,
    className,
    delay,
    CollapseProps,
    ...props
}: {
    children?: React.ReactElement;
    className?: string;
    delay?: number;
    style?: React.CSSProperties;
    CollapseProps?: CollapseProps;
} & React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement> & {
        css?: Interpolation<Theme>;
    }) {
    const { ref, inView } = useInView({
        threshold: 1,
        triggerOnce: true,
    });

    return (
        <div ref={ref} className={className} {...props}>
            <Collapse
                {...CollapseProps}
                in={inView}
                timeout={{
                    enter: 500,
                }}
                style={{
                    transitionDelay: inView && delay ? `${delay}ms` : "0ms",
                    ...CollapseProps?.style,
                }}
            >
                {children}
            </Collapse>
        </div>
    );
}

function Grower({
    children,
    className,
    delay,
    GrowProps,
    ...props
}: {
    children?: React.ReactElement;
    className?: string;
    delay?: number;
    style?: React.CSSProperties;
    GrowProps?: GrowProps;
} & React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement> & {
        css?: Interpolation<Theme>;
    }) {
    const { ref, inView } = useInView({
        threshold: 1,
        triggerOnce: true,
    });

    return (
        <div ref={ref} className={className} {...props}>
            <Grow
                in={inView}
                timeout={{
                    enter: 500,
                }}
                style={{
                    transitionDelay: inView && delay ? `${delay}ms` : "0ms",
                    ...GrowProps?.style,
                }}
                {...GrowProps}
            >
                {children}
            </Grow>
        </div>
    );
}

export const Transition = {
    Slide: Slider,
    Fade: Fader,
    Collapse: Collapser,
    Grow: Grower,
};
