import { IsEnum, IsInt, IsPhoneNumber, IsString, MaxLength } from "class-validator";
import { Driver } from "../northstar";

enum DriverOfTheYearSize {
    XS = "xs",
    S = "s",
    M = "m",
    L = "l",
    XL = "xl",
    XXL = "2xl",
    XXXL = "3xl",
    XXXXL = "4xl",
    LT = "l (tall)",
    XLT = "xl (tall)",
    XXLT = "2xl (tall)",
    XXXLT = "3xl (tall)",
}

enum DriverOfTheYearStatus {
    Processing = "processing",
    Shipped = "shipped",
}

export class DriverOfTheYear {
    public static readonly Size = DriverOfTheYearSize;
    public static readonly Status = DriverOfTheYearStatus;

    id: number;
    accountId: string;
    driverId: number;
    year: number;
    size: DriverOfTheYearSize;
    status: DriverOfTheYearStatus;

    addressLine1: string;
    addressLine2: string;
    addressCity: string;
    addressState: string;
    addressPostalCode: string;
    addressCountry: string;

    phone: string;

    createdDate: Date;
    shippedDate: Date;
    createdByWaypointUserId: string;

    driver?: Driver;
}

export class DriverOfTheYearDto {
    @IsInt()
    driverId: number;

    @IsString()
    @IsEnum(DriverOfTheYearSize)
    size: DriverOfTheYearSize;

    @IsString()
    @MaxLength(255)
    addressLine1: string;

    @IsString()
    @MaxLength(255)
    addressLine2: string;

    @IsString()
    @MaxLength(128)
    addressCity: string;

    @IsString()
    @MaxLength(2)
    addressState: string;

    @IsString()
    @MaxLength(12)
    addressPostalCode: string;

    @IsString()
    @MaxLength(64)
    addressCountry: string;

    @IsString()
    @IsPhoneNumber("US")
    phone: string;
}
