import { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";
import { DataTable } from "@deathstar/ui";
import { MenuItem } from "@material-ui/core";
import { Column, Table } from "@tanstack/react-table";
import React from "react";
import Columns from "./Columns";

export function Filters({ table }: { table: Table<MotorCarrier.IViolation> }): JSX.Element {
    const groupOptions = React.useMemo(() => {
        const set = new Set<string>();
        table.options.data.forEach((v) => {
            const d = v.get("GroupDescription");
            if (!d) return;
            set.add(d);
        });
        return Array.from(set.values()).sort();
    }, [table.options.data]);

    return (
        <div className="flex gap-2">
            <DataTable.ColumnOptionFilterButton<MotorCarrier.IViolation, string>
                column={table.getColumn(Columns.basic.id!) as Column<MotorCarrier.IViolation, string>}
                label="BASIC Category"
                menuTitle="Filter by BASIC"
                emptyValue="All"
                multiple
            >
                <MenuItem value={FMCSA.BasicName.UNSAFE_DRIVING}>{FMCSA.BasicName.UNSAFE_DRIVING}</MenuItem>
                <MenuItem value={FMCSA.BasicName.HOS}>{FMCSA.BasicName.HOS}</MenuItem>
                <MenuItem value={FMCSA.BasicName.CONTROLLED_SUBSTANCES}>{FMCSA.BasicName.CONTROLLED_SUBSTANCES}</MenuItem>
                <MenuItem value={FMCSA.BasicName.VEHICLE_MAINTENANCE}>{FMCSA.BasicName.VEHICLE_MAINTENANCE}</MenuItem>
                <MenuItem value={FMCSA.BasicName.DRIVER_FITNESS}>{FMCSA.BasicName.DRIVER_FITNESS}</MenuItem>
                <MenuItem value={FMCSA.BasicName.HAZMAT}>{FMCSA.BasicName.HAZMAT}</MenuItem>
            </DataTable.ColumnOptionFilterButton>

            <DataTable.ColumnOptionFilterButton<MotorCarrier.IViolation, string>
                column={table.getColumn(Columns.groupDescription.id!) as Column<MotorCarrier.IViolation, string>}
                label="Group"
                menuTitle="Filter by Group"
                emptyValue="All"
                multiple
            >
                {groupOptions.map((o) => (
                    <MenuItem key={o} value={o}>
                        {o}
                    </MenuItem>
                ))}
            </DataTable.ColumnOptionFilterButton>

            {/* <DataTable.ColumnOptionFilterButton<MotorCarrier.IViolation, string>
                column={table.getColumn(Columns.violationCategory.id!) as Column<MotorCarrier.IViolation, string>}
                label="Category"
                menuTitle="Filter by Category"
                emptyValue="All"
            >
                <MenuItem value={ViolationCategoryType.DRIVER}>{ViolationCategoryType.DRIVER}</MenuItem>
                <MenuItem value={ViolationCategoryType.VEHICLE}>{ViolationCategoryType.VEHICLE}</MenuItem>
                <MenuItem value={ViolationCategoryType.HAZMAT}>{ViolationCategoryType.HAZMAT}</MenuItem>
            </DataTable.ColumnOptionFilterButton> */}
        </div>
    );
}
