import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import NavigationTabs from "../../components/navigation-tabs/NavigationTabs";
import Title from "../../components/title/Title";
import PoliciesList from "./policies-list/PoliciesList";

export default function Policies() {
    return (
        <Routes>
            <Route
                element={
                    <div className="p-4 lg:p-12">
                        <div className="mb-2">
                            <div className="mb-2 flex justify-between">
                                <Title>Policies</Title>
                            </div>
                            <NavigationTabs
                                tabs={[
                                    { to: "active", label: "Active" },
                                    { to: "inactive", label: "Inactive" },
                                ]}
                            />
                        </div>
                        <Outlet />
                    </div>
                }
            >
                <Route index element={<Navigate to="active" />} />
                <Route path="active" element={<PoliciesList filter="active" />} />
                <Route path="inactive" element={<PoliciesList filter="inactive" />} />
            </Route>
        </Routes>
    );
}
