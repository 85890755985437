import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { UnauthorizedError } from "../../api/util/exceptions";
import Unauthorized from "../../components/error-screens/Unauthorized";
import UncaughtException from "../../components/error-screens/UncaughtException";
import Loader from "../../components/loader/Loader";
import Title from "../../components/title/Title";
import CertificateListItem from "./CertificateListItem";

export default function Certificates() {
    const accountId = useAccountId();
    const { data, isError, error, isLoading } = api.certificates.useFind(accountId!, { enabled: !!accountId });
    return (
        <div className="p-4 lg:p-12">
            <Title>Certificates</Title>
            {isLoading ? (
                <Loader />
            ) : isError ? (
                error instanceof UnauthorizedError ? (
                    <Unauthorized />
                ) : (
                    <UncaughtException />
                )
            ) : (
                <ul className="space-y-4 py-4">
                    {data?.length ? (
                        data.map((certificate) => (
                            <CertificateListItem certificate={certificate} key={certificate.id} linkTo={String(certificate.id)} />
                        ))
                    ) : (
                        <li className="text-stone-500">No certificates found</li>
                    )}
                </ul>
            )}
        </div>
    );
}
