import { Policy } from "@deathstar/types/northstar";
import moment from "moment";

export default function PolicyStatusLabel({ policy }: { policy: Policy }) {
    if (policy.status === Policy.Status.ACTIVE) {
        return <span className="font-bold tracking-wide text-emerald-700 small-caps">Active</span>;
    }
    if (policy.status === Policy.Status.PRE_CANCELLATION) {
        return <span className="font-bold tracking-wide text-orange-500 small-caps">Pre-Cancellation</span>;
    }
    if (moment().isBefore(moment.utc(policy.effectiveDate), "millisecond")) {
        return <span className="font-bold tracking-wide text-emerald-700 small-caps">Upcoming</span>;
    }
    if (moment().isAfter(moment.utc(policy.expirationDate), "millisecond")) {
        return <span className="font-bold tracking-wide text-stone-700 small-caps">Expired</span>;
    }
    if (policy.status === Policy.Status.CANCELLED) {
        return <span className="font-bold tracking-wide text-red-700 small-caps">Cancelled</span>;
    }
    if (policy.status === Policy.Status.EXPIRED) {
        return <span className="font-bold tracking-wide text-stone-700 small-caps">Expired</span>;
    }
    if (policy.status === Policy.Status.NON_RENEWED) {
        return <span className="font-bold tracking-wide text-yellow-700 small-caps">Non-Renewed</span>;
    }
    if (policy.status === Policy.Status.RENEWED) {
        return <span className="font-bold tracking-wide text-navigator-primary small-caps">Renewed</span>;
    }
    if (policy.status === Policy.Status.REWRITTEN) {
        return <span className="font-bold tracking-wide text-pink-700 small-caps">Rewritten</span>;
    }
    return <span className="font-bold tracking-wide text-pink-700 small-caps">Other</span>;
}
