import { MotorCarrier } from "@deathstar/motor-carrier";
import { classNames, DataTable, fuzzyFilter } from "@deathstar/ui";
import { Typography } from "@material-ui/core";
import { getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import React from "react";
import { animated, useSpring } from "react-spring";
import { PrintableTable } from "../../Components/PrintableTable";
import { Section } from "../../Components/Section";
import { Context } from "../../Context";
import { columns } from "./Columns";

const AnimatedDataTable = animated(DataTable<MotorCarrier.PrimaryScoreContributor>);

export function Table({ totalItemsPerTable }: { totalItemsPerTable: number }): JSX.Element {
    return (
        <>
            <WebTable className="print:hidden" totalItemsPerTable={totalItemsPerTable} />
            <PrintTable className="hidden print:block" totalItemsPerTable={totalItemsPerTable} />
        </>
    );
}

export function WebTable({ totalItemsPerTable, className }: { totalItemsPerTable: number; className?: string }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const data = React.useMemo(
        () => motorCarrier.getPrimaryDriverScoreContributors(totalItemsPerTable),
        [motorCarrier, totalItemsPerTable]
    );

    const table = useReactTable({
        data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: fuzzyFilter,
    });
    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });
    return <AnimatedDataTable className={className} style={spring} table={table} />;
}

export function PrintTable({ totalItemsPerTable, className }: { totalItemsPerTable: number; className?: string }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const data = React.useMemo(
        () =>
            motorCarrier.getPrimaryDriverScoreContributors(totalItemsPerTable).map((driver) => ({
                ...driver,
                key: `${driver.VIN}-${driver.License}`,
                VIN: driver.VIN.slice(-4),
                "% of Total Score": driver.PercentOfTotalScoreFormatted,
                "Total Violations": driver.TotalViolations,
            })),
        [motorCarrier, totalItemsPerTable]
    );

    return (
        <div className={classNames(className)}>
            <PrintableTable columnHeaders={["VIN", "Year", "Make", "License", "% of Total Score", "Total Violations"]} data={data} />
        </div>
    );
}

export function TopDriverScoreContributorsTable({ totalItemsPerTable }: { totalItemsPerTable: number }): JSX.Element {
    return (
        <Section>
            <div>
                <Typography variant="h5">Top 5 Driver Score Contributors</Typography>
            </div>
            <Table totalItemsPerTable={totalItemsPerTable} />
        </Section>
    );
}
