import { OrganizationInvitation } from "@deathstar/types/waypoint";
import { ActionButton, classNames, DataTable, Popover } from "@deathstar/ui";
import { ColumnDef, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import { useMemo } from "react";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { queryClient } from "../../../util/queryClient";

function getStatusColor(invitation: OrganizationInvitation) {
    return invitation.status === OrganizationInvitation.Status.Accepted
        ? "text-emerald-600"
        : invitation.status === OrganizationInvitation.Status.Cancelled
        ? "text-stone-500"
        : invitation.status === OrganizationInvitation.Status.Declined
        ? "text-red-600"
        : invitation.expired
        ? "text-stone-500"
        : invitation.status === OrganizationInvitation.Status.Pending
        ? "text-yellow-600"
        : invitation.status === OrganizationInvitation.Status.Sent
        ? "text-blue-600"
        : "text-stone-800";
}

const columns: ColumnDef<OrganizationInvitation>[] = [
    {
        id: "mobile",
        header: () => null,
        cell: ({ row }) => (
            <Popover>
                <Popover.Button className="w-full">
                    <div className="text-left">
                        <p>{row.original.userEmail}</p>
                        <p className={classNames("small-caps", getStatusColor(row.original))}>{row.original.status}</p>
                        <p className="text-stone-600">
                            {row.original.status === OrganizationInvitation.Status.Pending ? "Created" : "Sent"}{" "}
                            {moment(row.original.createdDate).fromNow()}
                        </p>
                    </div>
                </Popover.Button>
                <Popover.Content className="w-max">
                    <Popover.Item
                        className="text-left"
                        onClick={async () => {
                            await api.invitations.cancel(row.original.accountId, row.original.id);
                            queryClient.invalidateQueries({ queryKey: api.invitations.queryKeys.find(row.original.accountId) });
                        }}
                        disabled={
                            row.original.expired ||
                            (row.original.status !== OrganizationInvitation.Status.Pending &&
                                row.original.status !== OrganizationInvitation.Status.Sent)
                        }
                    >
                        Cancel invitation
                    </Popover.Item>
                </Popover.Content>
            </Popover>
        ),
        meta: { responsive: true, classes: { header: "hidden" } },
    },
    {
        id: "status",
        header: () => "Status",
        cell: ({ row }) => (
            <span className={classNames("small-caps", getStatusColor(row.original))}>
                {row.original.status === OrganizationInvitation.Status.Pending ? "Not sent" : row.original.status}
            </span>
        ),
    },
    {
        id: "userEmail",
        accessorKey: "userEmail",
        header: () => "Email",
    },
    {
        id: "createdDate",
        accessorKey: "createdDate",
        header: () => "Sent",
        cell: ({ getValue }) => <div className="text-gray-500">{moment(getValue() as Date).fromNow()}</div>,
    },
    {
        id: "cancel",
        header: () => null,
        cell: ({ row }) => (
            <div className="flex h-[30px] justify-end">
                {!row.original.expired &&
                (row.original.status === OrganizationInvitation.Status.Pending ||
                    row.original.status === OrganizationInvitation.Status.Sent) ? (
                    <ActionButton
                        onClick={async () => {
                            await api.invitations.cancel(row.original.accountId, row.original.id);
                            queryClient.invalidateQueries({ queryKey: api.invitations.queryKeys.find(row.original.accountId) });
                        }}
                    >
                        Cancel
                    </ActionButton>
                ) : null}
            </div>
        ),
        meta: { align: "right" },
    },
];

export default function InvitationsTable() {
    const accountId = useAccountId();
    const { data: invitations } = api.invitations.useFind(accountId!);
    const tableData = useMemo(() => invitations || [], [invitations]);

    const table = useReactTable({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),

        initialState: {
            pagination: {
                pageSize: 10,
            },
        },
    });

    return (
        <div>
            <p className="mb-4 font-bold text-waypoint-blue-dark">Invitations</p>
            <DataTable table={table} disableSearch />
        </div>
    );
}
