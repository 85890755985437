import { Coverage } from "../Coverage";
import { CoverageLinkedBusinessAuto } from "../CoverageLinkedBusinessAuto";
import { CoverageLinkedDriver } from "../CoverageLinkedDriver";
import { CoverageLinkedTool } from "../CoverageLinkedTool";
import { CoverageLinkedTractor } from "../CoverageLinkedTractor";
import { CoverageLinkedTrailer } from "../CoverageLinkedTrailer";
import { CoverageOption } from "../CoverageOption";
import { PolicyLayer } from "../PolicyLayer";
import { Comparison } from "./Comparison";

export class EquipmentComparison {
    static itemIsAdded(change: Comparison.Change<EquipmentComparison.EquipmentType>): boolean {
        return change.type === "new";
    }

    static itemIsRemoved(change: Comparison.Change<EquipmentComparison.EquipmentType>): boolean {
        return change.type === "remove";
    }

    #baseLayer: PolicyLayer;
    #comparisonLayer: PolicyLayer;
    #tractors: Map<number /** tractorId */, Map<CoverageOption.Id, CoverageLinkedTractor.IComparisonReturn>> = new Map();
    #trailers: Map<number /** trailerId */, Map<CoverageOption.Id, CoverageLinkedTrailer.IComparisonReturn>> = new Map();
    #busAutos: Map<number /** businessAutoId */, Map<CoverageOption.Id, CoverageLinkedBusinessAuto.IComparisonReturn>> = new Map();
    #tools: Map<number /** toolId */, Map<CoverageOption.Id, CoverageLinkedTool.IComparisonReturn>> = new Map();
    #drivers: Map<number /** toolId */, Map<CoverageOption.Id, CoverageLinkedDriver.IComparisonReturn>> = new Map();

    constructor(baseLayer: PolicyLayer, comparisonLayer: PolicyLayer) {
        this.#baseLayer = baseLayer;
        this.#comparisonLayer = comparisonLayer;
    }

    get isTractorsMatch(): boolean {
        return Array.from(this.#tractors.values()).every((e) => Array.from(e.values()).every((c) => c.isMatch));
    }

    get isTrailersMatch(): boolean {
        return Array.from(this.#trailers.values()).every((e) => Array.from(e.values()).every((c) => c.isMatch));
    }

    get isBusinessAutosMatch(): boolean {
        return Array.from(this.#busAutos.values()).every((e) => Array.from(e.values()).every((c) => c.isMatch));
    }

    get isToolsMatch(): boolean {
        return Array.from(this.#tools.values()).every((e) => Array.from(e.values()).every((c) => c.isMatch));
    }

    get isDriversMatch(): boolean {
        return Array.from(this.#drivers.values()).every((e) => Array.from(e.values()).every((c) => c.isMatch));
    }

    get isMatch(): boolean {
        return this.isTractorsMatch && this.isTrailersMatch && this.isBusinessAutosMatch && this.isToolsMatch && this.isDriversMatch;
    }

    getChangeList(): EquipmentComparison.EquipmentChangeMap {
        const changeMap = new Map<
            number,
            {
                entity: EquipmentComparison.EquipmentType;
                changeMap: Map<CoverageOption.Id, EquipmentComparison.IChangeMapValue>;
            }
        >();

        [this.#tractors, this.#trailers, this.#busAutos, this.#tools, this.#drivers].map((map) => {
            for (const [entityId, entityChangeMap] of map.entries()) {
                const equipChangeMap = changeMap.get(entityId) || {
                    entity: null,
                    changeMap: new Map<CoverageOption.Id, EquipmentComparison.IChangeMapValue>(),
                };
                for (const [coverageId, comparison] of entityChangeMap.entries()) {
                    if (comparison.isMatch) continue;
                    if (
                        equipChangeMap?.entity?.coverage?.coverageOptionId !== CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE || // the APD linked item will include the value (SV or ACV)
                        (equipChangeMap?.entity?.coverage?.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE &&
                            equipChangeMap?.entity?.coverage?.createdDate.getTime() <
                                (comparison.compare || comparison.base)?.coverage?.createdDate?.getTime()) // is a newer version of the APD linked item
                    ) {
                        equipChangeMap.entity = comparison.compare || comparison.base;
                    }
                    const existingCoverageChanges = equipChangeMap.changeMap.get(coverageId) ?? {
                        changes: [],
                    };

                    comparison.changeList.forEach((change) => {
                        existingCoverageChanges.changes.push(change as Comparison.Change<EquipmentComparison.EquipmentType>);
                    });
                    if (existingCoverageChanges.changes.length) {
                        equipChangeMap.changeMap.set(parseInt(coverageId as unknown as string), existingCoverageChanges);
                    }
                }
                if (equipChangeMap.changeMap.size) {
                    changeMap.set(entityId, equipChangeMap);
                }
            }
        });
        return changeMap;
    }

    #compareItems<T = EquipmentComparison.EquipmentType>({
        items,
        initEntityFn,
        compareFn,
    }: {
        items: "linkedTools" | "linkedTractors" | "linkedTrailers" | "linkedBusinessAutos" | "linkedDrivers";
        initEntityFn(args: { coverage: Coverage; entity: T }): T;
        compareFn(
            args: Comparison.IOptions<T> & {
                itemId: number;
            }
        ): void;
    }): void {
        const map = new Map<
            number,
            {
                base: Record<CoverageOption.Id, T> | null;
                compare: Record<CoverageOption.Id, T> | null;
            }
        >();
        const idField = items === "linkedBusinessAutos" ? "businessAutoId" : `${items.slice(6, -1).toLowerCase()}Id`;

        this.#comparisonLayer.policy.coverages.forEach((cvg) => {
            cvg[items].forEach((t) => {
                map.set(t[idField], {
                    base: null,
                    compare: {
                        ...map.get(t[idField])?.compare,
                        [cvg.coverageOptionId]: initEntityFn({ coverage: cvg, entity: t }),
                    },
                });
            });
        });
        this.#baseLayer.policy.coverages.forEach((cvg) => {
            cvg[items].forEach((t) => {
                if (map.has(t[idField])) {
                    map.set(t[idField], {
                        ...map.get(t[idField]),
                        base: {
                            ...map.get(t[idField])?.base,
                            [cvg.coverageOptionId]: initEntityFn({ coverage: cvg, entity: t }),
                        },
                    });
                    return;
                }
                map.set(t[idField], {
                    base: {
                        ...map.get(t[idField])?.base,
                        [cvg.coverageOptionId]: initEntityFn({ coverage: cvg, entity: t }),
                    },
                    compare: null,
                });
            });
        });

        for (const [itemId, item] of map.entries()) {
            compareFn({
                itemId,
                base: item.base,
                compare: item.compare,
                comparisonLayer: this.#comparisonLayer,
                baseLayer: this.#baseLayer,
            });
        }
    }

    compare(): this {
        this.#compareItems<CoverageLinkedTractor>({
            items: "linkedTractors",
            initEntityFn: ({ coverage, entity }) => CoverageLinkedTractor.init({ coverage, linkedTractor: entity }),
            compareFn: (options) => {
                const comparison = CoverageLinkedTractor.compare(options);
                this.#tractors.set(options.itemId, comparison);
            },
        });
        this.#compareItems<CoverageLinkedTrailer>({
            items: "linkedTrailers",
            initEntityFn: ({ coverage, entity }) => CoverageLinkedTrailer.init({ coverage, linkedTrailer: entity }),
            compareFn: (options) => {
                const comparison = CoverageLinkedTrailer.compare(options);
                this.#trailers.set(options.itemId, comparison);
            },
        });
        this.#compareItems<CoverageLinkedBusinessAuto>({
            items: "linkedBusinessAutos",
            initEntityFn: ({ coverage, entity }) => CoverageLinkedBusinessAuto.init({ coverage, linkedBusAuto: entity }),
            compareFn: (options) => {
                const comparison = CoverageLinkedBusinessAuto.compare(options);
                this.#busAutos.set(options.itemId, comparison);
            },
        });
        this.#compareItems<CoverageLinkedTool>({
            items: "linkedTools",
            initEntityFn: ({ coverage, entity }) => CoverageLinkedTool.init({ coverage, linkedTool: entity }),
            compareFn: (options) => {
                const comparison = CoverageLinkedTool.compare(options);
                this.#tools.set(options.itemId, comparison);
            },
        });
        this.#compareItems<CoverageLinkedDriver>({
            items: "linkedDrivers",
            initEntityFn: ({ coverage, entity }) => CoverageLinkedDriver.init({ coverage, linkedDriver: entity }),
            compareFn: (options) => {
                const comparison = CoverageLinkedDriver.compare(options);
                this.#drivers.set(options.itemId, comparison);
            },
        });

        return this;
    }
}

export namespace EquipmentComparison {
    export type EquipmentType =
        | CoverageLinkedTool
        | CoverageLinkedTractor
        | CoverageLinkedTrailer
        | CoverageLinkedBusinessAuto
        | CoverageLinkedDriver;

    export interface IChangeMapValue<T = EquipmentType> {
        changes: Comparison.Change<T>[];
    }

    export interface IIsComparable {
        comparison?: {
            isNewToPolicy?: boolean;
            isDeletedFromPolicy?: boolean;
        };
    }

    export interface EquipmentChange {
        entity: EquipmentComparison.EquipmentType;
        changeMap: Map<CoverageOption.Id, EquipmentComparison.IChangeMapValue>;
    }

    export type EquipmentChangeMap = Map<
        /** entity ID */
        number,
        EquipmentChange
    >;
}
