import { Zendesk } from "@deathstar/types/waypoint";
import { Checkbox, classNames, Dropzone, humanizeFileSize, InputField, PrimaryButton, RichTextEditor } from "@deathstar/ui";
import { ArrowUpTrayIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { sortBy } from "lodash";
import { useRef } from "react";
import { Controller, FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import Loader from "../../components/loader/Loader";

interface FormData {
    ticket_form_id: string;
    subject: string;
    comment: {
        html_body: string;
        files: File[];
    };
    custom_fields: Record<number, unknown>;
}

export default function NewRequestForm({ onSubmit, isLoading }: { onSubmit: (values: FormData) => void; isLoading: boolean }) {
    const accountId = useAccountId();
    const form = useForm<FormData>({
        defaultValues: {
            ticket_form_id: "",
            subject: "",
            comment: {
                html_body: "",
                files: [],
            },
            custom_fields: {},
        },
    });

    const fileInput = useRef<HTMLInputElement>(null);
    const ticketFormId = useWatch({ control: form.control, name: "ticket_form_id" });
    const files = useWatch({ control: form.control, name: "comment.files" });

    const { data: forms } = api.zendesk.useForms(accountId!);
    const { data: formOption } = api.zendesk.useForm(accountId!, parseInt(ticketFormId), {
        enabled: !!accountId && !!ticketFormId,
    });

    const customFields = formOption?.ticket_fields.filter((field) => field.type !== "description" && field.type !== "subject");
    const errors = [
        form.formState.errors.ticket_form_id?.message,
        form.formState.errors.subject?.message,
        form.formState.errors.comment?.files?.message,
        form.formState.errors.comment?.html_body?.message,
    ].filter(Boolean);

    return (
        <div className="w-screen max-w-[min(100vw,600px)] justify-center overflow-y-auto p-4">
            <FormProvider {...form}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <select {...form.register("ticket_form_id")} className="form-select mb-10 w-full rounded-lg border-stone-300 text-sm">
                        <option value="">Select a form</option>
                        {forms?.map((form) => (
                            <option key={form.id} value={form.id}>
                                {form.name}
                            </option>
                        ))}
                    </select>

                    {ticketFormId ? (
                        !formOption ? (
                            <Loader />
                        ) : (
                            <Dropzone id="new-request-dropzone" onFileDrop={(files) => form.setValue("comment.files", files)}>
                                {({ getProps, isDragActive, handleFileSelect }) => (
                                    <div
                                        className={classNames(
                                            "flex flex-col gap-4 border-2 border-dashed",
                                            isDragActive ? "border-blue-600" : "border-transparent"
                                        )}
                                        {...getProps()}
                                    >
                                        <input ref={fileInput} type="file" multiple onChange={handleFileSelect} className="hidden" />
                                        <InputField {...form.register("subject")} label="Subject (optional)" />
                                        {sortBy(customFields, "position").map((field) => (
                                            <TicketFieldEl key={field.id} field={field} />
                                        ))}
                                        <Controller
                                            name="comment.html_body"
                                            control={form.control}
                                            render={({ field }) => (
                                                <RichTextEditor
                                                    value={field.value}
                                                    onChange={(value) => form.setValue(field.name, value)}
                                                    placeholder="Describe your question or request..."
                                                    editorClassName="[&>*]:min-h-[6rem]"
                                                />
                                            )}
                                            rules={{ required: { value: true, message: "A description is required" } }}
                                        />
                                        <button
                                            className="flex w-full items-center justify-center gap-2 py-2 text-sm text-stone-500"
                                            onClick={() => fileInput.current?.click()}
                                        >
                                            <ArrowUpTrayIcon className="h-4 w-4" />
                                            Click or drop files to attach
                                        </button>
                                        <ul className="mt-2 space-y-1 text-sm">
                                            {files.map((file) => (
                                                <li key={file.name} className="flex items-center gap-2">
                                                    <PaperClipIcon className="h-4 w-4" />
                                                    <span className="line-clamp-1">{file.name}</span>
                                                    <span className="whitespace-pre">({humanizeFileSize(file.size)})</span>
                                                </li>
                                            ))}
                                            {files.length ? (
                                                <li>
                                                    <button
                                                        className="text-gray-500 small-caps hover:text-gray-800"
                                                        onClick={() => form.setValue("comment.files", [])}
                                                    >
                                                        Clear attachments
                                                    </button>
                                                </li>
                                            ) : null}
                                        </ul>
                                        <div className="mt-2 flex items-center justify-between">
                                            <p className="text-sm">
                                                By submitting you agree to our{" "}
                                                <Link
                                                    to="/legal/askanything-privacy-policy"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-blue-600 hover:text-blue-800"
                                                >
                                                    privacy policy
                                                </Link>
                                            </p>
                                            <PrimaryButton
                                                disabled={isLoading}
                                                onClick={() => {
                                                    form.handleSubmit((values) => {
                                                        onSubmit(values);
                                                    })();
                                                }}
                                                className="w-1/3"
                                            >
                                                Submit
                                            </PrimaryButton>
                                        </div>
                                        {errors.length >= 0 && <p className="py-2 text-red-600">{errors.join("; ")}</p>}
                                    </div>
                                )}
                            </Dropzone>
                        )
                    ) : null}
                </form>
            </FormProvider>
        </div>
    );
}

function TicketFieldEl({ field }: { field: Zendesk.TicketField }) {
    const form = useFormContext();
    switch (field.type) {
        case "checkbox": {
            return (
                <Checkbox label={field.title_in_portal} classes={{ input: "w-4 h-4" }} {...form.register(`custom_fields.${field.id}`)} />
            );
        }
        case "tagger": {
            return (
                <select {...form.register(`custom_fields.${field.id}`)} className="form-select rounded-lg border-stone-300 text-sm">
                    <option value="">Select {field.title_in_portal}...</option>
                    {field.custom_field_options?.map((option) => (
                        <option key={option.id} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
            );
        }
    }
    return null;
}
