export enum ResourceFileType {
    Driver = "Driver management",
    Contracts = "Sample contract",
    Equipment = "Equipment management",
    Policies = "Company policy",
}

export interface ResourceFile {
    name: string;
    type: ResourceFileType;
    path: string;
}
export const resourceFiles: ResourceFile[] = [
    {
        name: "Sample Safety Guidelines Policy",
        path: "/policies/sample-safety-guidelines-policy.docx",
        type: ResourceFileType.Policies,
    },
    {
        name: "Driver Qualification Checklist (Printer-Friendly)",
        path: "/drivers/driver-qualification-checklist.pdf",
        type: ResourceFileType.Driver,
    },
    {
        name: "Driver Qualification Checklist (FMCSA)",
        path: "/policies/driver-qualification-file-checklist.pdf",
        type: ResourceFileType.Driver,
    },
    { name: "Driver Ride Along Evaluation", path: "/drivers/ride-along-evaluation.pdf", type: ResourceFileType.Driver },
    {
        name: "SPE Renewal Application New SSC Address",
        path: "/drivers/spe-renewal-application-new-ssc-address.pdf",
        type: ResourceFileType.Driver,
    },
    {
        name: "Clearinghouse Sample Limited Consent Form",
        path: "/drivers/clearinghouse-sample-limited-consent-form.pdf",
        type: ResourceFileType.Driver,
    },
    { name: "Clearinghouse Query Information", path: "/drivers/clearinghouse-query-information.pdf", type: ResourceFileType.Driver },
    {
        name: "Certificate of Compliance MVR Review",
        path: "/drivers/certificate-of-compliance-mvr-review.pdf",
        type: ResourceFileType.Driver,
    },
    { name: "Drug & Alcohol Testing Chart", path: "/drivers/drug-alcohol-testing-chart.pdf", type: ResourceFileType.Driver },
    { name: "Sample DOT Phys Exam Notice", path: "/drivers/sample-dot-phys-exam-notice.rtf", type: ResourceFileType.Driver },
    { name: "Notice of Log Violations", path: "/drivers/notice-of-log-violations.doc", type: ResourceFileType.Driver },
    { name: "Driver Employment Application", path: "/drivers/drivers-employment-application.pdf", type: ResourceFileType.Driver },
    { name: "LCV Driver Training Certificate", path: "/drivers/lcv-driver-training-certificate.pdf", type: ResourceFileType.Driver },
    {
        name: "LCV Driver Training Certificate of Grandfathering",
        path: "/drivers/lcv-driver-training-certificate-of-grandfathering.pdf",
        type: ResourceFileType.Driver,
    },
    { name: "MVR Release Consent Form", path: "/drivers/mvr-release-consent-form.docx", type: ResourceFileType.Driver },
    { name: "Sample Driver License Renewal", path: "/drivers/sample-driver-license-renewal.rtf", type: ResourceFileType.Driver },
    { name: "Sample Speed Policy", path: "/drivers/sample-speed-policy.doc", type: ResourceFileType.Driver },
    { name: "Sample MVR Annual Review", path: "/drivers/sample-mvr-annual-review.rtf", type: ResourceFileType.Driver },
    { name: "Driver Road Test Certificate", path: "/drivers/driver-road-test-certificate.pdf", type: ResourceFileType.Driver },
    { name: "Drug Test Statement", path: "/drivers/drug-test-statement.pdf", type: ResourceFileType.Driver },
    { name: "Record of DOT Refusal", path: "/drivers/record-of-dot-refusal.pdf", type: ResourceFileType.Driver },
    { name: "On Duty Hours Statement", path: "/drivers/on-duty-hours-statement.pdf", type: ResourceFileType.Driver },
    { name: "Dash Cam Policy And Procedures", path: "/drivers/dash-cam-policy-and-procedures.pdf", type: ResourceFileType.Driver },
    { name: "Driver Statement of Hours", path: "/drivers/driver-statement-of-hours.doc", type: ResourceFileType.Driver },
    {
        name: "Sample DOT Scheduled Phys Exam Notice",
        path: "/drivers/sample-dot-scheduled-phys-exam-notice.rtf",
        type: ResourceFileType.Driver,
    },
    { name: "Limited Consent Form", path: "/drivers/limited-consent-form.docx", type: ResourceFileType.Driver },
    {
        name: "PSP Disclosure And Authorization Form",
        path: "/drivers/psp-disclosure-and-authorization-form.pdf",
        type: ResourceFileType.Driver,
    },
    { name: "Exit Interview Checklist", path: "/drivers/exit-interview-checklist.pdf", type: ResourceFileType.Driver },
    { name: "PSP Motor Carrier Enrollment", path: "/drivers/psp-motor-carrier-enrollment.pdf", type: ResourceFileType.Driver },
    { name: "Action Plan", path: "/drivers/action-plan.doc", type: ResourceFileType.Driver },
    { name: "Medical Examiner Certificate", path: "/drivers/medical-examiner-certificate.pdf", type: ResourceFileType.Driver },
    { name: "Sample Annual MVR", path: "/drivers/sample-annual-mvr.rtf", type: ResourceFileType.Driver },
    { name: "Sample Warning Letter", path: "/drivers/sample-warning-letter.rtf", type: ResourceFileType.Driver },
    { name: "SPE Application New Hire", path: "/drivers/spe-application-new-hire.pdf", type: ResourceFileType.Driver },
    { name: "Federally Regulated Alcohol Test", path: "/drivers/federally-regulated-alcohol-test.pdf", type: ResourceFileType.Driver },
    { name: "Training Session Log", path: "/drivers/training-session-log.doc", type: ResourceFileType.Driver },
    {
        name: "Pre-employment Drug Alcohol Questionnaire",
        path: "/drivers/pre-employment-drug-alcohol-questionnaire.pdf",
        type: ResourceFileType.Driver,
    },
    { name: "Annual Review of Driving Record", path: "/drivers/annual-review-of-driving-record.pdf", type: ResourceFileType.Driver },
    { name: "Sample Final Warning Letter", path: "/drivers/sample-final-warning-letter.rtf", type: ResourceFileType.Driver },
    { name: "Medical Examination Report", path: "/drivers/medical-examination-report.pdf", type: ResourceFileType.Driver },
    { name: "Trailer Interchange Agreement", path: "/contracts/trailer-interchange-agreement.doc", type: ResourceFileType.Contracts },
    {
        name: "Trailer Interchange Agreement Charges",
        path: "/contracts/trailer-interchange-agreement-charges.doc",
        type: ResourceFileType.Contracts,
    },
    { name: "Sample Owner Operator", path: "/contracts/sample-owner-operator.docx", type: ResourceFileType.Contracts },
    { name: "Observed Behavior", path: "/policies/observed-behavior.doc", type: ResourceFileType.Policies },
    { name: "Certificate of Driver Road Test", path: "/policies/certificate-of-driver-road-test.pdf", type: ResourceFileType.Policies },
    { name: "Driver Hiring Checklist", path: "/policies/driver-hiring-checklist.docx", type: ResourceFileType.Policies },
    {
        name: "General Safety Statement Guideline",
        path: "/policies/general-safety-statement-guideline.docx",
        type: ResourceFileType.Policies,
    },
    { name: "Eligibility List", path: "/policies/eligibility-list.pdf", type: ResourceFileType.Policies },
    { name: "Driver Road Test Exam", path: "/policies/driver-road-test-exam.pdf", type: ResourceFileType.Policies },
    { name: "Use of Electronic Equipment", path: "/policies/use-of-electronic-equipment.doc", type: ResourceFileType.Policies },
    {
        name: "Pre Post Vechicle Inspection Checklist",
        path: "/equipment/pre-post-vechicle-inspection-checklist.docx",
        type: ResourceFileType.Equipment,
    },
    { name: "Example Bill of Sale", path: "/equipment/example-bill-of-sale.docx", type: ResourceFileType.Equipment },
    { name: "Lease Purchase Agreement", path: "/equipment/lease-purchase-agreement.pdf", type: ResourceFileType.Equipment },
    { name: "Equipment Security Checklist", path: "/equipment/equipment-security-checklist.doc", type: ResourceFileType.Equipment },
    { name: "Inspection Lane Checklist", path: "/equipment/inspection-lane-checklist.docx", type: ResourceFileType.Equipment },
    { name: "Accident Report Form", path: "/equipment/accident-report-form.pdf", type: ResourceFileType.Equipment },
    { name: "Maintenance Checklist", path: "/equipment/maintenance-checklist.docx", type: ResourceFileType.Equipment },
    { name: "Vehicle Condition Checklist", path: "/equipment/vehicle-condition-checklist.docx", type: ResourceFileType.Equipment },
    { name: "CPDP Eligibility Guide", path: "/equipment/cpdp-eligibility-guide.pdf", type: ResourceFileType.Equipment },
    { name: "Crash Scene Tips", path: "/equipment/crash-scene-tips.pdf", type: ResourceFileType.Equipment },
];
