import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/reuse";
import { Tooltip } from "@deathstar/ui";
import { CheckIcon } from "@heroicons/react/24/outline";
import { AccessorColumnDef, ColumnDef } from "@tanstack/react-table";
import DataQIcon from "../../assets/icons/dataq-icon.png";
import { accessorFnGenerator } from "../utils/accessorFnGenerator";
import { formatInspectionTime, getTotalMinutesOfInspection } from "../utils/getTotalMinutesOfInspection";

const ascDateSorter = DateTime.Sorter("ASC");

const icons: AccessorColumnDef<MotorCarrier.IInspection, boolean> = {
    id: "icons",
    header: "",
    accessorFn: (row) => row.isValid,
    cell: (row) => {
        const insp = row.row.original;

        const icons: JSX.Element[] = [];

        if (!insp.isValid) {
            icons.push(<img src={DataQIcon} alt="DataQ Icon" className="m-[1px] h-[20px] w-[20px]" />);
        } else {
            if (insp.isClean) {
                icons.push(
                    <div className="flex justify-center rounded-xl bg-green-500 px-2 text-white">
                        <span>CLEAN</span>
                    </div>
                );
            }
            if (insp.isOutOfService) {
                icons.push(
                    <div className="flex justify-center rounded-xl bg-danger px-2 text-white">
                        <span>OOS</span>
                    </div>
                );
            }
        }

        // if (insp.isNew) icons.push(<img src={NewIcon} alt="New Icon" className="m-[1px] h-[20px] w-[20px]" />);
        // if (insp.hasExpiringWeight) icons.push(<img src={ExpiringIcon} alt="Expiring Icon" className="m-[1px] h-[20px] w-[20px]" />);
        // if (insp.carrier.inspections.filterByInvalid().total > 0) icons.push(<div className="m-[1px] h-[20px] w-[20px]"></div>);

        return (
            <div className="flex gap-1">
                {icons.map((icon, idx) => (
                    <div key={idx}>{icon}</div>
                ))}
            </div>
        );
    },
    meta: { classes: { cell: "tabular-nums p-0 min-w-[45px]" } },
    enableSorting: false,
};

const addDate: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "addDate",
    header: "Date",
    accessorFn: (row) => row.date.format("mm/dd/YYYY"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};
const removalDate: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "removalDate",
    header: "Removal",
    accessorFn: (row) => DateTime.addYears(row.date, 2).format("mm/dd/YYYY"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => {
        return ascDateSorter(a.original.date, b.original.date);
    },
};

const reportNumber: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "reportNumber",
    header: "Report Number",
    accessorFn: (row) => row.get("ReportNumber"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const status: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "status",
    accessorFn: (row) => row.get("Status"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const state: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "state",
    accessorFn: (row) => row.get("ReportState"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const county: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "county",
    accessorFn: (row) => row.county,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const latitude: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "latitude",
    accessorFn: (row) => row.get("CountyLatitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const longitude: AccessorColumnDef<MotorCarrier.ICrash> = {
    id: "longitude",
    accessorFn: (row) => row.get("CountyLongitude"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const inspStartTime: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "inspStartTime",
    header: "Start Time",
    accessorFn: (row) => formatInspectionTime(row, "start"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const inspEndTime: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "inspEndTime",
    header: "End Time",
    accessorFn: (row) => formatInspectionTime(row, "end"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const inspDuration: AccessorColumnDef<MotorCarrier.IInspection, string> = {
    id: "inspDuration",
    header: "Minutes",
    accessorFn: (row) => {
        return getTotalMinutesOfInspection(row);
    },
    cell: (row) => {
        const insp = row.row.original;
        const startRaw = insp.get("InspectionStartTime")?.toString(),
            endRaw = insp.get("InspectionEndTime")?.toString();
        if (!startRaw || !endRaw) return "";

        const startTime = DateTime.convert24HrTimeTo12HrTime(startRaw);
        const endTime = DateTime.convert24HrTimeTo12HrTime(endRaw);

        return (
            <Tooltip title={`${startTime} - ${endTime}`}>
                <span className="border-b border-dashed border-gray-400">{row.getValue()}</span>
            </Tooltip>
        );
    },
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const inspectionFacility: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "inspectionFacility",
    header: "Facility",
    accessorFn: (row) => row.get("InspectionFacility"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const locationDescription: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "locationDescription",
    header: "Location",
    accessorFn: (row) => row.get("LocationDescription"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const inspConfidenceLevel: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "inspConfidenceLevel",
    header: "DOT Match",
    accessorFn: (row) => row.get("InspectionConfidenceLevel"),
    meta: {
        tooltip: "Indicates the Inspector's level of confidence on the carrier / USDOT number match.",
        classes: { cell: "tabular-nums" },
    },
    sortingFn: "alphanumeric",
};

const isLocalEnforcementJurisdiction: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "isLocalEnforcementJurisdiction",
    header: "Locally Enforced",
    accessorFn: (row) => accessorFnGenerator(row.get("LocalEnforcedJurisdiction")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("LocalEnforcedJurisdiction"), true),
    meta: {
        tooltip: "Indicates whether the inspection was conducted by a local jurisdiction.",
        classes: {
            cell: "tabular-nums",
        },
    },
    sortingFn: "alphanumeric",
};

const trafficEnforced: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "trafficEnforced",
    header: "Traffic Enforced",
    accessorFn: (row) => accessorFnGenerator(row.get("TrafficEnforced")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("TrafficEnforced"), true),
    meta: {
        tooltip: "Indicates whether the inspection was conducted in conjunction with a traffic enforcement action.",
        classes: { cell: "tabular-nums" },
    },
    sortingFn: "alphanumeric",
};
const sizeWeightEnforced: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "sizeWeightEnforced",
    header: "Size/Weight Enforced",
    accessorFn: (row) => accessorFnGenerator(row.get("SizeWeightEnforced")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("SizeWeightEnforced"), true),
    meta: {
        tooltip: "Indicates whether the inspection was conducted in conjunction with a size/weight enforcement action.",
        classes: { cell: "tabular-nums" },
    },
    sortingFn: "alphanumeric",
};

const shipperName: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "shipperName",
    header: "Shipper Name",
    accessorFn: (row) => row.get("ShipperName"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};
const shippingPaperNumber: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "shippingPaperNumber",
    header: "Shipping Paper Number",
    accessorFn: (row) => row.get("ShippingPaperNumber"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

//#region unit 1
const unitVin: AccessorColumnDef<MotorCarrier.IInspection, string> = {
    id: "unitVin",
    header: "VIN",
    accessorFn: (row) => row.get("UnitVin"),
    cell: (row) => {
        const insp = row.row.original;
        const year = insp.primaryUnit?.year;
        const make = insp.primaryUnit?.make || insp.get("UnitMake");
        const license = insp.get("UnitLicense");
        return (
            <Tooltip title={`${year}, ${make}, ${license}`}>
                <span className="border-b border-dashed border-gray-400">{row.getValue()}</span>
            </Tooltip>
        );
    },
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitYear: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unitYear",
    header: "Year",
    accessorFn: (row) => row.primaryUnit?.year,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitMake: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unitMake",
    header: "Make",
    accessorFn: (row) => row.primaryUnit?.make,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unitLicenseNumber: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unitLicenseNumber",
    header: "License",
    accessorFn: (row) => row.get("UnitLicense"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitLicenseState: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unitLicenseState",
    header: "License State",
    accessorFn: (row) => row.get("UnitLicenseState"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitType: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unitType",
    header: "Type",
    accessorFn: (row) => row.get("UnitType"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const unitDecalNumber: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unitDecalNumber",
    header: "Decal Number",
    accessorFn: (row) => row.get("UnitDecalNumber"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unitWeight: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unitWeight",
    header: "Weight",
    accessorFn: (row) => row.get("GrossCombinedVehicleWeight"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
//#endregion unit 1

//#region unit 2
const unit2Vin: AccessorColumnDef<MotorCarrier.IInspection, string | undefined> = {
    id: "unit2Vin",
    header: "VIN (Trailer)",
    accessorFn: (row) => row.get("Unit2Vin"),
    cell: (row) => {
        const insp = row.row.original;
        const year = insp.secondaryUnit?.year;
        const make = insp.secondaryUnit?.make || insp.get("Unit2Make");
        const license = insp.get("Unit2License");
        const shipperName = insp.get("ShipperName");
        return (
            <Tooltip title={`${year}, ${make}, ${license} (Shipper: ${shipperName})`}>
                <span className="border-b border-dashed border-gray-400">{row.getValue()}</span>
            </Tooltip>
        );
    },
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unit2Year: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unit2Year",
    header: "Year (Trailer)",
    accessorFn: (row) => row.secondaryUnit?.year,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unit2Make: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unit2Make",
    header: "Make (Trailer)",
    accessorFn: (row) => row.secondaryUnit?.make,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
    filterFn: "fuzzy",
};

const unit2LicenseNumber: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unit2LicenseNumber",
    header: "License Number (Trailer)",
    accessorFn: (row) => row.get("Unit2License"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unit2LicenseState: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unit2LicenseState",
    header: "License State (Trailer)",
    accessorFn: (row) => row.get("Unit2LicenseState"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const unit2Type: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unit2Type",
    header: "Type (Trailer)",
    accessorFn: (row) => row.get("Unit2Type"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const unit2DecalNumber: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "unit2DecalNumber",
    header: "Decal Number (Trailer)",
    accessorFn: (row) => row.get("Unit2DecalNumber"),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
//#endregion unit 2

const hazmatPlacardRequiredIndicator: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "hazmatPlacardRequired",
    header: "Hazmat Placard Required",
    accessorFn: (row) => accessorFnGenerator(row.get("HazmatPlacardRequired")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("HazmatPlacardRequired"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const postAccidentIndicator: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "postAccidentIndicator",
    header: "Conducted Post Accident",
    accessorFn: (row) => accessorFnGenerator(row.get("PostAccidentIndicator")),
    getUniqueValues: (row) => accessorFnGenerator(row.get("PostAccidentIndicator"), true),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const timeWeight: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "timeWeight",
    header: "Time Weight",
    accessorFn: (row) => row.getTimeWeight(),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};
const totalPoints: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "totalPoints",
    header: "Total Points",
    accessorFn: (row) => row.violations.getTotalWeight(),
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalOutOfService: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "totalOutOfService",
    header: "Total OOS",
    accessorFn: (row) => row.violations.totalOutOfService,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalDriverOutOfService: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "totalDriverOutOfService",
    header: "Total Driver OOS",
    accessorFn: (row) => row.violations.filterByType().driver.totalOutOfService,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalVehicleOutOfService: AccessorColumnDef<MotorCarrier.IInspection> = {
    id: "totalVehicleOutOfService",
    header: "Total Vehicle OOS",
    accessorFn: (row) => {
        const { tractor, trailer } = row.violations.filterByType();
        return tractor.totalOutOfService + trailer.totalOutOfService;
    },
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const isUnsafeDrivingInspection: AccessorColumnDef<MotorCarrier.IInspection, boolean> = {
    id: "unsafeDrivingInsp",
    header: "Unsafe Drive",
    accessorFn: (row) => row.get("UnsafeDrivingInsp"),
    cell: (row) =>
        row.getValue() ? (
            <CheckIcon className="h-4 w-4 text-green-500" />
        ) : (
            <Tooltip title="Not assessed">
                <span className="h-4 w-4 text-gray-500">--</span>
            </Tooltip>
        ),
    meta: { classes: { cell: "tabular-nums" } },
    enableSorting: false,
    sortingFn: (a, b) => {
        if (a.original.get("UnsafeDrivingInsp") === b.original.get("UnsafeDrivingInsp")) return 0;
        if (a.original.get("UnsafeDrivingInsp")) return 1;
        return -1;
    },
};
const isHoursOfServiceInspection: AccessorColumnDef<MotorCarrier.IInspection, boolean> = {
    id: "hoursOfServiceInsp",
    header: "H.O.S.",
    accessorFn: (row) => row.get("HoursOfServiceInsp"),
    cell: (row) =>
        row.getValue() ? (
            <CheckIcon className="h-4 w-4 text-green-500" />
        ) : (
            <Tooltip title="Not assessed">
                <span className="h-4 w-4 text-gray-500">--</span>
            </Tooltip>
        ),
    meta: { classes: { cell: "tabular-nums" } },
    enableSorting: false,
    sortingFn: (a, b) => {
        if (a.original.get("HoursOfServiceInsp") === b.original.get("HoursOfServiceInsp")) return 0;
        if (a.original.get("HoursOfServiceInsp")) return 1;
        return -1;
    },
};
const isControlledSubstanceInspection: AccessorColumnDef<MotorCarrier.IInspection, boolean> = {
    id: "controlledSubstancesInsp",
    header: "Control Sub",
    accessorFn: (row) => row.get("ControlledSubstancesInsp"),
    cell: (row) =>
        row.getValue() ? (
            <CheckIcon className="h-4 w-4 text-green-500" />
        ) : (
            <Tooltip title="Not assessed">
                <span className="h-4 w-4 text-gray-500">--</span>
            </Tooltip>
        ),
    meta: { classes: { cell: "tabular-nums" } },
    enableSorting: false,
    sortingFn: (a, b) => {
        if (a.original.get("ControlledSubstancesInsp") === b.original.get("ControlledSubstancesInsp")) return 0;
        if (a.original.get("ControlledSubstancesInsp")) return 1;
        return -1;
    },
};
const isVehcileMaintenanceInspection: AccessorColumnDef<MotorCarrier.IInspection, boolean> = {
    id: "vehicleMaintenanceInsp",
    header: "Vehicl Maint",
    accessorFn: (row) => row.get("VehicleMaintenanceInsp"),
    cell: (row) =>
        row.getValue() ? (
            <CheckIcon className="h-4 w-4 text-green-500" />
        ) : (
            <Tooltip title="Not assessed">
                <span className="h-4 w-4 text-gray-500">--</span>
            </Tooltip>
        ),
    meta: { classes: { cell: "tabular-nums" } },
    enableSorting: false,
    sortingFn: (a, b) => {
        if (a.original.get("VehicleMaintenanceInsp") === b.original.get("VehicleMaintenanceInsp")) return 0;
        if (a.original.get("VehicleMaintenanceInsp")) return 1;
        return -1;
    },
};
const isDriverFitnessInspection: AccessorColumnDef<MotorCarrier.IInspection, boolean> = {
    id: "driverFitnessInsp",
    header: "Driv Fit",
    accessorFn: (row) => row.get("DriverFitnessInsp"),
    cell: (row) =>
        row.getValue() ? (
            <CheckIcon className="h-4 w-4 text-green-500" />
        ) : (
            <Tooltip title="Not assessed">
                <span className="h-4 w-4 text-gray-500">--</span>
            </Tooltip>
        ),
    meta: { classes: { cell: "tabular-nums" } },
    enableSorting: false,
    sortingFn: (a, b) => {
        if (a.original.get("DriverFitnessInsp") === b.original.get("DriverFitnessInsp")) return 0;
        if (a.original.get("DriverFitnessInsp")) return 1;
        return -1;
    },
};
const isHazmatInspection: AccessorColumnDef<MotorCarrier.IInspection, boolean> = {
    id: "hazmatInsp",
    header: "Hazmat",
    accessorFn: (row) => row.get("HazmatInsp"),
    cell: (row) =>
        row.getValue() ? (
            <CheckIcon className="h-4 w-4 text-green-500" />
        ) : (
            <Tooltip title="Not assessed">
                <span className="h-4 w-4 text-gray-500">--</span>
            </Tooltip>
        ),
    meta: { classes: { cell: "tabular-nums" } },
    enableSorting: false,
    sortingFn: (a, b) => {
        if (a.original.get("HazmatInsp") === b.original.get("HazmatInsp")) return 0;
        if (a.original.get("HazmatInsp")) return 1;
        return -1;
    },
};

export const columns = [
    icons,
    addDate,
    removalDate,
    reportNumber,
    status,
    state,
    county,
    latitude,
    longitude,
    inspConfidenceLevel,
    isLocalEnforcementJurisdiction,
    trafficEnforced,
    sizeWeightEnforced,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    unitLicenseState,
    unitType,
    unitDecalNumber,
    unitWeight,
    unit2Vin,
    unit2Year,
    unit2Make,
    unit2LicenseNumber,
    unit2LicenseState,
    unit2Type,
    unit2DecalNumber,
    shipperName,
    shippingPaperNumber,
    inspectionFacility,
    locationDescription,

    hazmatPlacardRequiredIndicator,
    postAccidentIndicator,
    timeWeight,
    totalPoints,
    totalOutOfService,
    totalDriverOutOfService,
    totalVehicleOutOfService,

    isUnsafeDrivingInspection,
    isHoursOfServiceInspection,
    isControlledSubstanceInspection,
    isVehcileMaintenanceInspection,
    isDriverFitnessInspection,
    isHazmatInspection,

    inspStartTime,
    inspEndTime,
    inspDuration,
] as ColumnDef<MotorCarrier.IInspection>[];

export default {
    icons,
    addDate,
    removalDate,
    reportNumber,
    status,
    state,
    county,
    latitude,
    longitude,
    inspConfidenceLevel,
    isLocalEnforcementJurisdiction,
    trafficEnforced,
    sizeWeightEnforced,
    unitVin,
    unitYear,
    unitMake,
    unitLicenseNumber,
    unitLicenseState,
    unitType,
    unitDecalNumber,
    unitWeight,
    unit2Vin,
    unit2Year,
    unit2Make,
    unit2LicenseNumber,
    unit2LicenseState,
    unit2Type,
    unit2DecalNumber,
    shipperName,
    shippingPaperNumber,
    inspectionFacility,
    locationDescription,

    hazmatPlacardRequiredIndicator,
    postAccidentIndicator,
    timeWeight,
    totalPoints,
    totalOutOfService,
    totalDriverOutOfService,
    totalVehicleOutOfService,

    isUnsafeDrivingInspection,
    isHoursOfServiceInspection,
    isControlledSubstanceInspection,
    isVehcileMaintenanceInspection,
    isDriverFitnessInspection,
    isHazmatInspection,

    inspStartTime,
    inspEndTime,
    inspDuration,
};
