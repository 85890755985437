import { DateTime, RawData } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";

import type { MotorCarrier } from "../MotorCarrier";

// ========================================================================
export class AuthorityHistory extends RawData<AuthorityHistory.Raw> {
    // ========================================================================
    #carrier: MotorCarrier;
    #exists: boolean;

    constructor(carrier: MotorCarrier, raw: AuthorityHistory.Raw) {
        super(raw);
        this.#carrier = carrier;
        this.#exists = Boolean(raw);
    }

    // ========================================================================
    get OriginalServedDate(): DateTime {
        return new DateTime(this.get("OriginalServedDate"));
    }

    // ========================================================================
    get exists(): boolean {
        return this.#exists;
    }

    // ========================================================================
    json(): AuthorityHistory.Raw {
        return super.json();
    }

    // ========================================================================
    raw(): AuthorityHistory.Raw {
        return super.json();
    }
}

export namespace AuthorityHistory {
    export interface Raw extends FMCSA.AuthorityHistory {}
}
