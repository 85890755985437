import { memoize } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { Inspections } from "../../Inspections/Inspections";
import { SafetyEventGroup } from "../../SafetyEventGroup/SafetyEventGroup";
import { Basic } from "../Basic";

export class UnsafeDriving extends Basic<SafetyEventGroup.GroupNames.UnsafeDriving> {
    readonly isBestAddressedRoadside = false;

    // ========================================================================
    static get thresholdDefinition(): Basic.IThresholdDefinition {
        return {
            passenger: 50,
            hazmat: 60,
            general: 65,
        };
    }

    // ========================================================================
    constructor(options: UnsafeDriving.Options) {
        super({
            ...options,
            safetyEventGroup: new SafetyEventGroup.Group.UnsafeDriving(options.carrier, options.totalUnsafeDrivingInspectionsWithViolation),
            basicName: FMCSA.BasicName.UNSAFE_DRIVING,
            relevantInspectionDefinition: null,
        });
    }

    // ========================================================================
    get averagePowerUnits(): number {
        return this.carrier.averagePowerUnits;
    }

    // ========================================================================
    @memoize()
    get utilizationFactor(): number {
        return Basic.calculateUtilizationFactor(this.carrier);
    }

    // ========================================================================
    @memoize()
    override getRelevantInspections(): Inspections {
        return this.smsResult.inspections;
    }

    // ========================================================================
    override getVariableData(): Record<string, string | number | null | undefined> {
        return {
            totalApplicableViolations: this.getViolations().total,
            totalWeightOfApplicableViolations: this.getTotalWeightOfApplicableViolations(),
            averageMilesTraveledPerUnit: this.carrier.averageMilesTraveledPerUnit,
            averagePowerUnits: this.averagePowerUnits,
            segment: this.carrier.segment,
            utilizationFactor: this.utilizationFactor,
        };
    }

    // ========================================================================
    override getThresholdDefinition(): Basic.IThresholdDefinition {
        return UnsafeDriving.thresholdDefinition;
    }

    // ========================================================================
    override getFmcsaCalculatedMeasure(): number | null {
        return typeof this.rawSmsData?.UnsafeDrivingMeasure === "number" ? this.rawSmsData?.UnsafeDrivingMeasure : null;
    }

    // ========================================================================
    protected override calculateMeasure(): number {
        if (!this.getViolations().total) return 0;

        const totalWeightOfApplicableViolations = this.getTotalWeightOfApplicableViolations(false);

        return totalWeightOfApplicableViolations / (this.averagePowerUnits * this.utilizationFactor);
    }

    // ========================================================================
    protected override getMeasure(): number {
        if (typeof this.rawSmsData?.UnsafeDrivingMeasure === "number" && !this.forceCalculateBasicMeasures) {
            return this.rawSmsData?.UnsafeDrivingMeasure;
        }

        return this.calculateMeasure();
    }

    // ========================================================================
    protected override async getScore(): Promise<number | null> {
        if (typeof this.rawSmsData?.UnsafeDrivingScore === "number" && !this.forceCalculateBasicScores) {
            return this.rawSmsData?.UnsafeDrivingScore;
        }

        return this.calculateScore();
    }

    // ========================================================================
    protected override async calculateScore(): Promise<number | null> {
        if (this.measure === 0 || this.safetyEventGroup.number === 0 || !this.safetyEventGroup.name) return 0;

        /*
            Get the number of inspections with at least one violation
        */
        const totalUnsafeDrivingInspectionsWithViolation = this.getTotalInspectionsWithViolation();
        if (totalUnsafeDrivingInspectionsWithViolation < Basic.getMinimumRelevant(FMCSA.BasicName.UNSAFE_DRIVING)) return 0;

        /*
            Get the total number of Unsafe Driving Violations within the previous 12 months.
        */
        const totalUnsafeDrivingViolationsWithinPreviousYear = this.getTotalViolationsWithinYear();

        if (totalUnsafeDrivingViolationsWithinPreviousYear === 0) return 0;

        return this.fetchSmsScore();
    }
}

export namespace UnsafeDriving {
    export interface Options extends Basic.BaseOptions {
        totalUnsafeDrivingInspectionsWithViolation: number;
    }
}
