import React from "react";

import type { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";

export function useThresholds({ motorCarrier }: { motorCarrier: MotorCarrier }): Map<FMCSA.BasicName, number> {
    return React.useMemo(() => {
        const { latest } = motorCarrier.smsResults;

        const thresholdsMap = new Map<FMCSA.BasicName, number>([
            [FMCSA.BasicName.CRASH_INDICATOR, latest.crashIndicator.threshold],
            [FMCSA.BasicName.HAZMAT, latest.hazmat.threshold],
            [FMCSA.BasicName.CONTROLLED_SUBSTANCES, latest.controlledSubstances.threshold],
            [FMCSA.BasicName.DRIVER_FITNESS, latest.driverFitness.threshold],
            [FMCSA.BasicName.VEHICLE_MAINTENANCE, latest.vehicleMaintenance.threshold],
            [FMCSA.BasicName.HOS, latest.hoursOfService.threshold],
            [FMCSA.BasicName.UNSAFE_DRIVING, latest.unsafeDriving.threshold],
        ]);

        return thresholdsMap;
    }, [motorCarrier.smsResults]);
}
