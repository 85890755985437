import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

enum EnvironmentType {
    Local = "local",
    Canary = "canary",
    Staging = "staging",
    Production = "production",
}

export class Environment {
    public static readonly Type = EnvironmentType;
    public readonly env: EnvironmentType = EnvironmentType.Local;
    constructor() {
        if (window.location.origin === "https://delightful-hill-04c763010-canary.centralus.3.azurestaticapps.net") {
            this.env = Environment.Type.Canary;
        } else if (window.location.origin === "https://delightful-hill-04c763010-staging.centralus.3.azurestaticapps.net") {
            this.env = Environment.Type.Staging;
        } else if (window.location.origin === "https://waypoint.navigatortruckinsurance.com") {
            this.env = Environment.Type.Production;
        } else {
            this.env = Environment.Type.Local;
        }
    }

    public get blazar(): { url: string } {
        switch (this.env) {
            case Environment.Type.Local:
                return { url: window.location.origin.match(/(.*):/)?.[0] + "3333/" };
            case Environment.Type.Canary:
                return { url: "https://blazar-canary.navigatortruckinsurance.com/" };
            case Environment.Type.Staging:
                return { url: "https://blazar-staging.navigatortruckinsurance.com/" };
            case Environment.Type.Production:
                return { url: "https://blazar.navigatortruckinsurance.com/" };
        }
    }

    public get stripe(): { publicKey: string } {
        switch (this.env) {
            case Environment.Type.Local:
            case Environment.Type.Canary:
            case Environment.Type.Staging:
                return {
                    publicKey:
                        "pk_test_51NBNmMAX5lF9rpLb2pdOaVrcsSH4OXlNMGNXCgGUgxkhG8Xq8ML2yjKBRmnv47XAbzbruaMuuvDn9iKUBdfSwBtb00Q24BPT7N",
                };
            case Environment.Type.Production:
                return {
                    publicKey:
                        "pk_live_51NBNmMAX5lF9rpLb4dYh1zJqT1Y5BjBXjMH2wxA5mO6WTNM1Nfz5u4UlnnJSENCSIVHHc10CFfGn9ViHhOOkX2jl00HmJE0ZDN",
                };
        }
    }

    public get auth0(): { clientId: string; domain: string; audience: string } {
        switch (this.env) {
            case Environment.Type.Local:
                return { domain: "dev-waypoint.us.auth0.com", clientId: "VjHQxn7CpVIfErQ0jNavoQgiHoqBJfZs", audience: "blazar" };
            case Environment.Type.Canary:
                return { domain: "canary-waypoint.us.auth0.com", clientId: "QwsbaOCsOq65VX4c0JOlMIXnboZX80rG", audience: "blazar" };
            case Environment.Type.Staging:
                return { domain: "staging-waypoint.us.auth0.com", clientId: "AfATwy8NiGnGLmAfpkzSBTLC1oXgbNvi", audience: "blazar" };
            case Environment.Type.Production:
                return { domain: "login.navigatortruckinsurance.com", clientId: "AHqKaPIy0mpnKyfB4w0x3NJZ6Zae3j4Q", audience: "blazar" };
        }
    }

    public get sentry(): {
        enabled: boolean;
        integrations: Parameters<typeof Sentry.init>[0]["integrations"];
        dsn: string;
        environment: string;
        release: string;
        tracesSampleRate: number;
        replaysSessionSampleRate: number;
        replaysOnErrorSampleRate: number;
    } {
        return {
            dsn: "https://a2da2299feb34d1189db1d93ef9e902d@o1047337.ingest.sentry.io/4504729827999744",
            release: import.meta.env.VITE_RELEASE_ID,
            integrations: [new Sentry.Replay(), new Integrations.BrowserTracing()],

            tracesSampleRate: 0.1,

            // This sets the sample rate to be 35%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: 0.35,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: 1.0,
            environment: environment.env,
            enabled: environment.env !== Environment.Type.Local,
        };
    }

    public get resources() {
        return { baseUrl: "https://apappblobstorage.blob.core.windows.net/resources" };
    }

    public get telematics() {
        switch (this.env) {
            case Environment.Type.Local:
                return { websiteId: "5d7245ca-a777-466d-b96d-05fd45449866", scriptUrl: "http://localhost:8080/tracker.js" };
            case Environment.Type.Production:
                return {
                    websiteId: "f5e87e83-293b-4a2f-b2ce-26808065a9a2",
                };
            default:
                return { websiteId: null, scriptUrl: null };
        }
    }
}

const environment = new Environment();

export default environment;
