import { FunnelIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Popover } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ActionButton } from "../actionButton/ActionButton";
import { classNames } from "../classNames/classNames";
import { PrimaryButton } from "../primaryButton/primaryButton";

export interface FilterButtonProps<T = never> {
    value: T;
    label: string;
    renderValue?(value: T): string | React.ReactNode | undefined;
    onChange: (value: T) => void;
    onClear: () => void;
    Menu: (props: { value: T; onChange(value: T): void }) => JSX.Element;
    getHasValue?(value: T): boolean;
}

export function FilterButton<T = never>({ value, onChange, onClear, Menu, renderValue, label, getHasValue }: FilterButtonProps<T>) {
    const [localValue, setLocalValue] = useState(value);
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const hasValue = getHasValue ? getHasValue(value) : !!value;

    return (
        <>
            <ActionButton
                className={classNames({
                    "!border-navigator-blue !bg-blue-50/60": hasValue,
                })}
                onClick={(e) => setAnchor(e.currentTarget)}
            >
                {!hasValue ? (
                    <FunnelIcon className="h-4 w-4" />
                ) : (
                    <div
                        className="rounded-full hover:text-gray-800"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClear();
                        }}
                    >
                        <XMarkIcon className="h-4 w-4" />
                    </div>
                )}
                <span>{label}</span>
                {hasValue ? (
                    <>
                        <div className="border-r" />
                        <span className="text-blue-900">{renderValue ? renderValue(value) : (value as React.ReactNode)}</span>
                    </>
                ) : null}
            </ActionButton>
            <Popover
                open={!!anchor}
                anchorEl={anchor}
                onClose={() => {
                    setAnchor(null);
                    setTimeout(() => setLocalValue(value), 100);
                }}
                classes={{ paper: "p-2 min-w-[200px]", root: "mt-2 -ml-4" }}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <Menu value={localValue} onChange={(v) => setLocalValue(v)} />
                <PrimaryButton
                    className="mt-2 w-full py-1 text-center text-sm font-medium"
                    onClick={() => {
                        onChange(localValue);
                        setAnchor(null);
                    }}
                >
                    Apply
                </PrimaryButton>
            </Popover>
        </>
    );
}
