import { FMCSA } from "@deathstar/types";
import { classNames } from "@deathstar/ui";
import { config } from "./ChartConfig";

export function BasicColorSwatch({
    basic,
    className,
    size,
}: {
    basic: FMCSA.BasicName;
    className?: string;
    size?: "xs" | "sm";
}): JSX.Element {
    return (
        <div
            className={classNames(
                "flex rounded-full print:h-3 print:w-3",
                {
                    "h-2 w-2 print:h-2 print:w-2": size === "xs",
                    "h-3 w-3": size === "sm",
                    "h-5 w-5": !size,
                },
                className
            )}
            style={{
                backgroundColor: config.colors[basic as FMCSA.BasicName.HAZMAT],
            }}
        />
    );
}
