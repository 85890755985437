import { PrimaryButton } from "@deathstar/ui";
import { Link, useParams } from "react-router-dom";
import src from "../../assets/images/not-found.svg";
import BaseErrorScreen from "./BaseErrorScreen";

export default function SolPageNotFound() {
    const params = useParams();
    return (
        <BaseErrorScreen
            title="Lose your way?"
            description="It looks like that page doesn't exist. Check the address and try again."
            primaryAction={
                <Link to={`/sol/${params.accountId || ""}`} className="block w-max">
                    <PrimaryButton>Point me back home</PrimaryButton>
                </Link>
            }
            image={<img src={src} alt="Not found" className="hidden w-1/2 lg:block" />}
        />
    );
}
