import { IsOptional, IsString, IsISO8601, ValidateBy, IsEmpty, IsInt, IsBoolean, ValidateNested, Matches } from "class-validator";
import type { Account } from "./Account";
import type { Proposal } from "./Proposal";
import moment from "moment";
import { CrudValidationGroups } from "@nestjsx/crud/lib/enums/crud-validation-groups.enum";
import { Transform, Type } from "class-transformer";
import { Tractor } from "./Tractor";
import { Company } from "./Company";

enum Status {
    Pending,
    NotSubmitted,
    Submitted,
    Declined,
    Quoted,
}

enum EmailType {
    Appeal = "appeal",
    Binder = "binder",
    Decline = "decline",
    FinanceAgreement = "finance agreement",
    PriceIndication = "price indication",
    Quote = "quote",
    RevisedQuote = "revised quote",
    Submission = "submission",
}

export class SubmissionReportQuery {
    @IsOptional()
    @IsString()
    accountName?: string;

    @IsOptional()
    @IsString()
    customerName?: string;

    @IsOptional()
    @IsString()
    policyType?: string;

    @IsOptional()
    @IsString()
    sfrom?: string;

    @IsOptional()
    @IsString()
    sto?: string;

    @IsOptional()
    @IsString()
    qfrom?: string;

    @IsOptional()
    @IsString()
    qto?: string;

    @IsOptional()
    @IsString()
    dfrom?: string;

    @IsOptional()
    @IsString()
    dto?: string;

    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : undefined))
    take?: number;

    @IsInt()
    @Transform(({ value }) => (value ? parseInt(value) : 0))
    page?: number;

    @IsOptional()
    @IsString()
    sort?: string;

    @IsOptional()
    @IsString()
    @Matches(/ASC|DESC/)
    order?: "ASC" | "DESC";

    @IsOptional()
    @IsBoolean()
    @Transform(({ value }) => value === true || value === "true" || value === "1")
    includeUnits?: boolean;
}

export interface SubmissionReportData {
    data: (Pick<
        Submission,
        | "id"
        | "accountId"
        | "company"
        | "policy"
        | "dateSubmitted"
        | "declineDate"
        | "quoteDate"
        | "premium"
        | "pending"
        | "revenue"
        | "declinations"
        | "effectiveDate"
        | "uwName"
    > & { account: Pick<Account, "id"> & { tractors: Omit<Tractor, "account">[]; company: Pick<Company, "name" | "accountId"> } })[];
    total: number;
    pageCount: number;
}

export class SubmissionAttachmentEmail {
    @IsInt()
    time: number;

    @IsString()
    type: EmailType;

    @IsString()
    emailID: string;
}

export class Submission {
    static EmailTypes = EmailType;
    static Status = Status;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    accountId: string;

    @IsEmpty({ always: true })
    id: number;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    dealId: string;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    company: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    policy: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    comments: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsISO8601()
    dateSubmitted: Date | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsISO8601()
    declineDate: Date | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsISO8601()
    quoteDate: Date | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsInt()
    premium: number | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    email1: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    email2: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    uwName: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsBoolean()
    pending: boolean;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @ValidateNested({ each: true })
    @Type(() => SubmissionAttachmentEmail)
    emails: SubmissionAttachmentEmail[] | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsInt()
    revenue: number | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    @IsString()
    @IsISO8601({ strict: true })
    // This is really just to help prevent users from typing a date like "01/01/20" and not realizing it's actually "01/01/0020"
    @ValidateBy({
        name: "isNotInFarPast",
        validator: (value: string) => {
            if (typeof value === "string") {
                const m = moment(value, moment.ISO_8601);
                return m.isSameOrAfter(moment().subtract(30, "years"));
            }
            return false;
        },
    })
    effectiveDate: string | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    documents: { type: string; fileID: string }[] | null;

    @IsOptional({ groups: [CrudValidationGroups.UPDATE] })
    declinations: string[];

    // submissionDeclinations: SubmissionDeclination[]; // TODO: Add this back when we actually use the relation

    @IsEmpty({ always: true })
    proposals: Proposal[];

    @IsEmpty({ always: true })
    account: Account;

    @IsEmpty({ always: true })
    status: Status;
}
