import { useAuth0 } from "@auth0/auth0-react";
import { classNames, PrimaryButton, ProgressiveImage, SecondaryButton } from "@deathstar/ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import graphicSrc from "../assets/images/road_sunset_lg.jpeg";
import graphicPlaceholder from "../assets/images/road_sunset_sm.jpeg";
import iconSrc from "../assets/images/wp_icon.svg";
import wordmarkSrc from "../assets/images/wp_wordmark.svg";
import SplashScreen from "./sol/SplashScreen";

const aspectRatio = 4100 / 2327;

export function Landing() {
    const { loginWithRedirect, user, isLoading } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/sol");
        }
    }, [user, navigate]);

    if (isLoading) {
        return <SplashScreen />;
    }

    return (
        <div>
            <img className="fixed left-4 top-4 z-10 w-10" src={iconSrc} alt="Waypoint icon" />
            <div className="absolute z-0 h-full min-h-[600px] w-full bg-white">
                <div
                    style={{ height: "calc(100% - 24px)" }}
                    className="flex w-full flex-col items-center justify-center gap-4 lg:w-1/2 lg:pl-8"
                >
                    <div className="mb-16 flex shrink-0 flex-col items-center gap-2 lg:mb-32">
                        <img src={wordmarkSrc} alt="Waypoint by Navigator" className="mt-4 w-3/4 animate-grow-in md:w-[400px]" />
                    </div>
                    <div className="space-y-2 text-center">
                        <PrimaryButton
                            className="w-48 !bg-waypoint-blue font-bold text-white"
                            onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: "signup" } })}
                        >
                            Create an account
                        </PrimaryButton>
                        <p className="text-stone-400">or</p>
                        <SecondaryButton className="w-48 !text-waypoint-blue" onClick={() => loginWithRedirect()}>
                            Sign in
                        </SecondaryButton>
                    </div>
                    <ul className="mt-12 shrink-0 list-disc text-stone-600">
                        <li>Certificates, Auto IDs, Policies</li>
                        <li>Legal guidance</li>
                        <li>Compliance guidance</li>
                        <li>Inspection Analysis</li>
                        <li>DataQ processing</li>
                    </ul>
                </div>
                <p className="py-1 text-center text-xs text-stone-600 lg:w-1/2">
                    © {new Date().getUTCFullYear()} Navigator Truck Insurance Agency
                </p>
            </div>
            <ProgressiveImage
                src={graphicSrc}
                placeholder={graphicPlaceholder}
                alt="Highway sunset"
                className={(blur) =>
                    classNames("invisible fixed left-0 top-0 z-0 transition duration-1000 ease-in lg:visible", blur && "blur-lg")
                }
                style={{
                    clipPath: "polygon(40% 0, 100% 0, 100% 100%, 20% 100%)",
                    marginLeft: "20%",
                    width: `max(100vw,${100 * aspectRatio}vh)`,
                    height: `max(100vh,${100 / aspectRatio}vw)`,
                    objectFit: "cover",
                }}
            />
        </div>
    );
}
