import { classNames } from "@deathstar/ui";
import { NavLink, To } from "react-router-dom";

export default function NavigationTabs({ tabs }: { tabs: { label: React.ReactNode; to: To }[] }) {
    return (
        <nav className="flex gap-4 border-b font-medium">
            {tabs.map((tab) => (
                <NavLink
                    end={tab.to.toString() === ""}
                    key={tab.to.toString()}
                    to={tab.to}
                    className={({ isActive }) =>
                        classNames(
                            "inline-block border-b-2 pb-1 tracking-wide transition-colors",
                            isActive ? "border-waypoint-blue text-waypoint-blue" : "border-transparent text-stone-500"
                        )
                    }
                >
                    {tab.label}
                </NavLink>
            ))}
        </nav>
    );
}
