export enum MapLocationType {
    PrimaryTerminal = "primary-terminal",
    Terminal = "terminal",
    Other = "other",
    CleanInspection = "clean-inspection",
    OutOfService = "out-of-service",
    Crash = "crash",
}

export interface USMapLocation {
    type?: MapLocationType;
    lng: number;
    lat: number;
    focus?: boolean;
    rings?: number[];
    size?: number;
    color?: string;
    onLocationClick?(lat: number, lng: number): void;
    tooltip?: string;
}
