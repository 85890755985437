import { classNames } from "@deathstar/ui";
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

export interface NavItemProps extends NavLinkProps {
    icon?: React.ReactNode | (({ isActive }: { isActive: boolean }) => React.ReactNode);
    label: string;
}
export function NavigationItem({ to, icon, label, ...props }: NavItemProps) {
    return (
        <li className="list-none">
            <NavLink
                {...props}
                to={to}
                className={({ isActive }) =>
                    classNames(
                        "flex w-full items-center gap-2 overflow-hidden whitespace-nowrap rounded-lg p-2 tracking-wide",
                        isActive ? "bg-navigator-primary text-white" : "text-stone-600 hover:text-navigator-primary"
                    )
                }
            >
                {(renderProps) => (
                    <>
                        <span className="flex-shrink-0">{typeof icon === "function" ? icon(renderProps) : icon}</span>
                        {label}
                    </>
                )}
            </NavLink>
        </li>
    );
}
