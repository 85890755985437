import { PrimaryButton, useActionState } from "@deathstar/ui";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";

export default function SimpleConfirmation({
    open,
    onClose,
    onConfirm,
    title,
    description,
    confirmLabel,
    cancelLabel,
}: {
    open: boolean;
    onClose(): void;
    onConfirm(): Promise<void>;
    title: React.ReactNode;
    description: React.ReactNode;
    cancelLabel?: React.ReactNode;
    confirmLabel?: React.ReactNode;
}) {
    const [triggerConfirm, confirmState] = useActionState(onConfirm, { multiple: true });
    // we use a local open state so we can control the transition better. The onClose prop will be called
    // after the transition is complete.
    const [localOpen, setLocalOpen] = useState(open);

    useEffect(() => {
        setLocalOpen(open);
    }, [open]);

    return (
        <Transition appear show={localOpen} as={Fragment} afterLeave={onClose}>
            <Dialog as="div" className="relative z-50" onClose={() => setLocalOpen(false)}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel className="w-max transform space-y-8 overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <DialogTitle as="h3" className="text-xl font-medium leading-6 text-navigator-primary-dark">
                                    {title}
                                </DialogTitle>
                                <div className="mt-2 max-w-prose text-stone-500">{description}</div>

                                <div className="mt-4 flex items-center justify-end gap-8">
                                    <button
                                        type="button"
                                        className="px-2 py-1 text-stone-500"
                                        onClick={() => {
                                            setLocalOpen(false);
                                        }}
                                    >
                                        {cancelLabel || "Cancel"}
                                    </button>

                                    <PrimaryButton
                                        type="button"
                                        className="min-w-[96px]"
                                        onClick={triggerConfirm}
                                        disabled={confirmState.isDisabled}
                                    >
                                        {confirmLabel || "Confirm"}
                                    </PrimaryButton>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
