export class Notation {
    id: string;
    description: Notation.Description;
}

export namespace Notation {
    export enum Description {
        BROKERAGE = "Brokerage",
        INDEPENDENT_CONTRACTOR = "Independent Contractor",
        NON_FLEET_1_4 = "Non-Fleet 1-4",
        NON_FLEET_5_9 = "Non-Fleet 5-9",
        FLEET_10_25 = "Fleet 10-25",
        FLEET_26_50 = "Fleet 26-50",
        FLEET_51_100 = "Fleet 51-100",
        FLEET_100_PLUS = "Fleet 100+",
        FINANCED = "Financed",
        REPORTER = "Reporter",
    }
}
