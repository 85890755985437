import { plainToClass } from "class-transformer";
import type { Coverage } from "./Coverage";

export class CoverageRateOwner {
    static createTemplateFrom(_owner: CoverageRateOwner): CoverageRateOwner {
        return plainToClass(CoverageRateOwner, {});
    }

    static duplicate(rate: CoverageRateOwner): CoverageRateOwner {
        const r = plainToClass(CoverageRateOwner, rate);

        r.id = null;
        r.createdDate = null;
        r.coverageId = null;
        r.coverageCreatedDate = null;
        r.coverage = null;

        return r;
    }

    id: number;
    createdDate: Date;

    coverageId?: number;
    coverageCreatedDate?: Date;
    coverage?: Coverage;

    name: string | null;
    dateOfBirth: Date | null;
    percentage: number | null;
    include: boolean;

    sortOrder: number;
    metadata?: Record<string, unknown>;
}
