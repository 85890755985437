import { Zendesk } from "@deathstar/types/waypoint";
import { classNames } from "@deathstar/ui";

export default function RequestStatus({ status, statusCategory }: { status: string; statusCategory: Zendesk.Request["status_category"] }) {
    return (
        <span
            className={classNames(
                "shrink-0 whitespace-nowrap rounded bg-white px-2 small-caps",
                (statusCategory === "new" || statusCategory === "open") && "border border-blue-700 text-blue-700",
                statusCategory === "solved" && "border border-emerald-700 text-emerald-700",
                statusCategory === "pending" && "border border-orange-700 text-orange-700",
                statusCategory === "hold" && "border border-purple-700 text-purple-700"
            )}
        >
            {status}
        </span>
    );
}
