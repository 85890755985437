import { Tooltip, classNames } from "@deathstar/ui";
import { Typography } from "@material-ui/core";
import React from "react";

export interface StopLightOption {
    label: string;
    selected: boolean;
    tooltip?: string;
    red?: boolean;
    yellow?: boolean;
    green?: boolean;
    gray?: boolean;
}

export function StopLight({ options }: { options: StopLightOption[] }): JSX.Element {
    const selected = React.useMemo(() => options.find((option) => option.selected), [options]);
    return (
        <div>
            {options.map((option, index) => (
                <StopLightSection key={index} {...option} label={selected?.label ?? ""} />
            ))}
        </div>
    );
}

function StopLightSection({
    label,
    selected,
    tooltip,
    red,
    yellow,
    green,
    gray,
}: {
    label: string | JSX.Element;
    selected?: boolean;
    tooltip?: string;
    red?: boolean;
    yellow?: boolean;
    green?: boolean;
    gray?: boolean;
}): JSX.Element {
    const ref = React.useRef(null);

    return (
        <div
            ref={ref}
            className={classNames("grid grid-cols-[max-content_1fr] items-center gap-2 rounded border-2 border-transparent p-1 px-4", {
                "bg-slate-100": selected,
                "!border-[#86202b]": selected && red,
                "!border-[#b49939]": selected && yellow,
                "!border-[#28672c]": selected && green,
                "!border-[#888888]": selected && gray,
            })}
        >
            <Tooltip title={selected && tooltip ? tooltip : ""}>
                <Label label={label} selected={selected} />
            </Tooltip>
            <Light red={red} yellow={yellow} green={green} gray={gray} selected={selected} />
        </div>
    );
}

function Label({ label, selected }: { label: string | JSX.Element; selected?: boolean }): JSX.Element | null {
    return (
        <div
            className={classNames("flex items-center gap-2", {
                invisible: !selected,
            })}
        >
            {typeof label === "string" ? <Typography className="text-md">{label}</Typography> : label}
        </div>
    );
}

function Light({
    red,
    yellow,
    green,
    gray,
    selected,
}: {
    red?: boolean;
    yellow?: boolean;
    green?: boolean;
    gray?: boolean;
    selected?: boolean;
}): JSX.Element {
    return (
        <div
            className={classNames("h-[25px] w-[25px] rounded-full ", {
                "bg-[#86202b]": red,
                "bg-[#b49939]": yellow,
                "bg-[#28672c]": green,
                "bg-[#888888]": gray,
                "opacity-30": !selected,
            })}
        ></div>
    );
}
