export class ResponseError extends Error {
    constructor(public status: number, message?: string, options?: ErrorOptions) {
        super(message, options);
    }
}

export class UnauthenticatedError extends ResponseError {
    constructor(message?: string, options?: ErrorOptions) {
        super(401, message || "Unauthenticated", options);
    }
}

export class ContentTooLargeError extends ResponseError {
    constructor(message?: string, options?: ErrorOptions) {
        super(413, message || "Content too large", options);
    }
}

export class ConnectionError extends Error {
    constructor(message?: string, options?: ErrorOptions) {
        super(message || "Connection error", options);
    }
}

export class ServerUnavailableError extends ResponseError {
    constructor(message?: string, options?: ErrorOptions) {
        super(503, message || "Server unavailable", options);
    }
}

export class UnauthorizedError extends ResponseError {
    constructor(message?: string, options?: ErrorOptions) {
        super(403, message || "Insufficient permissions", options);
    }
}

export class NoSubscriptionError extends UnauthorizedError {
    constructor(message?: string, options?: ErrorOptions) {
        super(message || "Subscription required", options);
    }
}

export class ThrottleError extends ResponseError {
    public retryAfter: number | undefined;
    constructor(response: Response, message?: string, options?: ErrorOptions) {
        super(429, message, options);
        const retryAfter = response.headers.get("Retry-After");
        if (retryAfter) {
            this.retryAfter = parseInt(retryAfter, 10);
        }
    }
}

export class ServerError extends ResponseError {
    constructor(message?: string, options?: ErrorOptions) {
        super(500, message, options);
    }
}

export class BadRequestError extends ResponseError {
    constructor(message?: string, options?: ErrorOptions) {
        super(400, message, options);
    }
}

export class NotFoundError extends ResponseError {
    constructor(message?: string, options?: ErrorOptions) {
        super(404, message, options);
    }
}
