import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import QueryString from "qs";
import { useEffect, useState } from "react";
import { useQuery } from '@tanstack/react-query';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "web-api-hooks";
import api from "../../api/api";
import { ResponseError, ThrottleError } from "../../api/util/exceptions";
import PageNotFound from "../../components/error-screens/PageNotFound";
import Loader from "../../components/loader/Loader";
import Logo from "../../components/logo/Logo";

function Invite() {
    const { logout } = useAuth0();
    const { data: user, isLoading: isLoadingUser, isError: isErrorUser, refetch } = api.user.useMe();
    let { inviteId } = useParams();
    const navigate = useNavigate();
    const [storedInviteId] = useLocalStorage<string>("waypoint-invite-id");

    inviteId = inviteId || storedInviteId;

    const [accepted, setAccepted] = useState(false);
    const { isError, isLoading, error } = useQuery({
        queryKey: ["invite", inviteId],

        queryFn: async () => {
            await api.invitations.accept(inviteId!);
            setAccepted(true);
            localStorage.removeItem("waypoint-invite-id");
            refetch().then(() => {
                navigate("/sol");
            });
        },

        staleTime: Infinity,

        retry: (count, err) => {
            if (err instanceof ResponseError) {
                if (err.status === 403 || err.status === 429) {
                    return false;
                }
            }
            return count < 3;
        },

        retryDelay: 3000,
        enabled: !!user && !!inviteId && !accepted
    });

    if (isErrorUser) {
        return <PageNotFound />;
    }

    if (isLoadingUser) {
        return (
            <div className="h-screen w-screen">
                <Loader />
            </div>
        );
    }

    if (!user) {
        return <Navigate to={{ pathname: "/member-setup", search: QueryString.stringify({ redirect: window.location.pathname }) }} />;
    }

    return (
        <div className="h-screen w-screen">
            {isError ? (
                error instanceof ResponseError && error.status === 403 ? (
                    <div className="flex h-screen w-screen flex-col items-center justify-center">
                        <Logo className="fixed left-4 top-4" />
                        <div className="space-y-6 rounded border p-4 shadow">
                            <p>It looks like this invitation was meant for another user.</p>
                            <p>
                                If you're trying to create a new user,{" "}
                                <button
                                    className="font-medium text-blue-600"
                                    onClick={() => {
                                        logout();
                                    }}
                                >
                                    logout
                                </button>
                            </p>
                            <p>
                                If you got here by mistake,{" "}
                                <Link to="/sol" className="text-blue-600">
                                    go back home
                                </Link>
                            </p>
                        </div>
                    </div>
                ) : error instanceof ResponseError && error.status === 429 ? (
                    <div className="flex h-screen w-screen flex-col items-center justify-center">
                        <Logo className="fixed left-4 top-4" />
                        <div className="space-y-6 rounded border p-4 shadow">
                            <p>You've tried to accept this invitation too many times.</p>
                            <p>Wait {(error as ThrottleError).retryAfter} seconds and try again</p>
                        </div>
                    </div>
                ) : null
            ) : isLoading ? (
                <Loader />
            ) : (
                <Navigate to="/sol" />
            )}
        </div>
    );
}

export default withAuthenticationRequired(Invite, {
    returnTo: () => window.location.pathname,
    onRedirecting: () => <RedirectPage />,
    loginOptions: {
        authorizationParams: {
            redirect_uri: window.location.origin + "/invites",
        },
    },
});

function RedirectPage() {
    const { inviteId } = useParams();

    useEffect(() => {
        if (inviteId) {
            localStorage.setItem("waypoint-invite-id", inviteId);
        }
    }, [inviteId]);

    return null;
}
