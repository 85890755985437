import { plainToClass, Type } from "class-transformer";
import { NumberFormatter } from "../util";
import { Attribute } from "./Attribute";
import { AttributeOption } from "./AttributeOption";
import { BusinessAuto } from "./BusinessAuto";
import { Comparison } from "./Comparison/Comparison";
import { Coverage } from "./Coverage";
import { CoverageLinkedVehicle } from "./CoverageLinkedVehicle";
import { CoverageOption } from "./CoverageOption";
import { EquipmentComparison } from "./PolicyLayer";

export class CoverageLinkedBusinessAuto implements CoverageLinkedVehicle, EquipmentComparison.IIsComparable {
    #initialized = false;
    static init(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): CoverageLinkedBusinessAuto {
        const { linkedBusAuto } = options;
        if (linkedBusAuto instanceof CoverageLinkedBusinessAuto && linkedBusAuto.#initialized) return linkedBusAuto;

        const e = plainToClass(CoverageLinkedBusinessAuto, linkedBusAuto);
        e.coverage = options.coverage;
        e.attributes = CoverageLinkedBusinessAuto.getAttributes(options);
        e.statedValue = CoverageLinkedBusinessAuto.getStatedValue(options);
        e.hasCollision = CoverageLinkedBusinessAuto.hasCollision(options);
        e.uniqueCollisionDeductible = CoverageLinkedBusinessAuto.getUniqueCollisionDeductible(options);
        e.hasComprehensive = CoverageLinkedBusinessAuto.hasComprehensive(options);
        e.uniqueComprehensiveDeductible = CoverageLinkedBusinessAuto.getUniqueComprehensiveDeductible(options);
        e.isSpareRated = CoverageLinkedBusinessAuto.getIsSpareRated(options);
        e.isOwnerOperator = CoverageLinkedBusinessAuto.getIsOwnerOperator(options);
        e.isValuationActualCashValue = CoverageLinkedBusinessAuto.getIsValuationActualCashValue(options);
        e.#initialized = true;
        return e;
    }

    static getAttributes({ coverage, linkedBusAuto }: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): Attribute[] {
        if (!coverage) return [];
        return coverage.attributes.filter((a) => a.businessAutoId === linkedBusAuto.businessAutoId);
    }

    static hasAttribute({
        coverage,
        linkedBusAuto,
        attributeOptionId,
    }: {
        coverage?: Coverage;
        linkedBusAuto: CoverageLinkedBusinessAuto;
        attributeOptionId: AttributeOption.Id;
    }): boolean {
        const tractorAttributes = CoverageLinkedBusinessAuto.getAttributes({ coverage, linkedBusAuto });
        return tractorAttributes.some((a) => a.attributeOptionId === attributeOptionId);
    }

    static #getAttributes({ coverage, linkedBusAuto }: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): Attribute[] {
        return linkedBusAuto.attributes || CoverageLinkedBusinessAuto.getAttributes({ coverage, linkedBusAuto });
    }

    static #getComprehensiveAttribute(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): Attribute {
        return CoverageLinkedBusinessAuto.#getAttributes(options).find(
            (a) => a.attributeOptionId === AttributeOption.Id.UNIT_COMPREHENSIVE
        );
    }

    static hasComprehensive(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): boolean {
        return CoverageLinkedBusinessAuto.#getAttributes(options).some(
            (a) => a.attributeOptionId === AttributeOption.Id.UNIT_COMPREHENSIVE
        );
    }

    static #getCollisionAttribute(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): Attribute {
        return CoverageLinkedBusinessAuto.#getAttributes(options).find((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COLLISION);
    }

    static hasCollision(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): boolean {
        return CoverageLinkedBusinessAuto.#getAttributes(options).some((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COLLISION);
    }

    static getUniqueComprehensiveDeductible(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): number | null {
        const compAttr = CoverageLinkedBusinessAuto.#getComprehensiveAttribute(options);
        if (!compAttr) return null;
        return typeof compAttr.valueNumber === "number" ? compAttr.valueNumber : null;
    }

    static getUniqueCollisionDeductible(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): number | null {
        const compAttr = CoverageLinkedBusinessAuto.#getCollisionAttribute(options);
        if (!compAttr) return null;
        return typeof compAttr.valueNumber === "number" ? compAttr.valueNumber : null;
    }

    static getStatedValue(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): number | null {
        const statedValueAttr = CoverageLinkedBusinessAuto.#getAttributes(options).find(
            (a) => a.attributeOptionId === AttributeOption.Id.STATED_VALUE
        );
        return statedValueAttr?.valueNumber || null;
    }

    static getIsSpareRated(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): boolean {
        return CoverageLinkedBusinessAuto.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.UNIT_SPARE_RATED });
    }

    static getIsOwnerOperator(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): boolean {
        return CoverageLinkedBusinessAuto.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.UNIT_OWNER_OPERATOR });
    }

    static getIsValuationActualCashValue(options: { coverage?: Coverage; linkedBusAuto: CoverageLinkedBusinessAuto }): boolean {
        return CoverageLinkedBusinessAuto.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE });
    }

    static compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Comparison.IOptions<CoverageLinkedBusinessAuto>): Map<CoverageOption.Id, CoverageLinkedBusinessAuto.IComparisonReturn> {
        const map = new Map<CoverageOption.Id, CoverageLinkedBusinessAuto.IComparisonReturn>();

        if (compare) {
            for (const [cvgOptId, linkedEntity] of Object.entries(compare) as unknown as [
                CoverageOption.Id,
                CoverageLinkedBusinessAuto
            ][]) {
                const from = base?.[cvgOptId];
                const to = linkedEntity;
                map.set(cvgOptId, CoverageLinkedBusinessAuto.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        if (base) {
            for (const [cvgOptId, linkedEntity] of Object.entries(base) as unknown as [CoverageOption.Id, CoverageLinkedBusinessAuto][]) {
                if (map.has(cvgOptId)) continue;
                const from = linkedEntity;
                const to = compare?.[cvgOptId];
                map.set(cvgOptId, CoverageLinkedBusinessAuto.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        return map;
    }

    static #compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Partial<Comparison.IOptions.Entity<CoverageLinkedBusinessAuto>>): CoverageLinkedBusinessAuto.IComparisonReturn {
        const comparison = new Comparison(CoverageLinkedBusinessAuto, base, compare) as CoverageLinkedBusinessAuto.IComparisonReturn;
        comparison.setField("businessAutoId", compare?.businessAutoId || base?.businessAutoId);
        comparison.setField("businessAuto", compare?.businessAuto || base?.businessAuto);

        CoverageLinkedVehicle.compare({
            comparison,
            base,
            compare,
            comparisonLayer,
            baseLayer,
            label: "Bus Auto",
            descriptions: {
                add: `Add business auto ${compare?.toString()}`,
                remove: `Delete business auto ${base?.businessAuto?.vin}`,
            },
        });

        return comparison;
    }

    id: number;
    createdDate: Date;

    businessAutoId: number;
    @Type(() => BusinessAuto)
    businessAuto?: BusinessAuto;

    coverageId: number;
    coverageCreatedDate: Date;
    coverage?: Coverage;

    attributes?: Attribute[];
    statedValue?: number;
    hasCollision?: boolean;
    uniqueCollisionDeductible?: number | null;
    hasComprehensive?: boolean;
    uniqueComprehensiveDeductible?: number | null;
    isSpareRated?: boolean;
    isOwnerOperator?: boolean;
    isValuationActualCashValue?: boolean;
    comparison?: {
        isNewToPolicy?: boolean;
        isDeletedFromPolicy?: boolean;
    };

    compare(
        comparisonLinkedTrailer: CoverageLinkedBusinessAuto,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CoverageLinkedBusinessAuto> {
        return CoverageLinkedBusinessAuto.#compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonLinkedTrailer,
                },
                options
            )
        );
    }

    toString(): string {
        if (!this.businessAuto) {
            return `CoverageLinkedBusinessAuto: ${this.businessAutoId}`;
        }
        const baseStringParts = [this.businessAuto.toString()];
        if (this.isValuationActualCashValue) {
            baseStringParts.push("(ACV)");
        } else if (typeof this.statedValue === "number") {
            baseStringParts.push(`(SV: ${NumberFormatter.Currency.format(this.statedValue)})`);
        }
        return baseStringParts.join(" ");
    }
}

export namespace CoverageLinkedBusinessAuto {
    export interface IComparisonReturn extends Comparison<CoverageLinkedBusinessAuto> {
        businessAutoId: number;
        businessAuto?: BusinessAuto;
    }
}
