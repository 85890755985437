export class Phone {
    readonly countryCode: number = 1;
    readonly areaCode: number;
    readonly number: number;
    readonly extension: number | null = null;

    constructor(phoneNumber: string) {
        const includesExtension = phoneNumber.includes("x");
        if (phoneNumber.length !== 10 && !includesExtension) {
            throw new Phone.ParseError(`Phone number must be 10 digits: "${phoneNumber}" invalid`);
        }

        if (phoneNumber.length === 10) {
            const { areaCode, number } = this.#parse10DigitPhone(phoneNumber);
            this.areaCode = areaCode;
            this.number = number;
        } else if (includesExtension) {
            const [actualPhoneNumber, extension] = phoneNumber.split("x");
            if (actualPhoneNumber.length !== 10) {
                throw new Phone.ParseError(`Phone number must be 10 digits: "${phoneNumber}" invalid`);
            }
            const { areaCode, number } = this.#parse10DigitPhone(actualPhoneNumber);
            this.areaCode = areaCode;
            this.number = number;
            this.extension = parseInt(extension);
        }
    }

    #parse10DigitPhone(phoneNumber: string): { number: number; areaCode: number } {
        const phoneRegex = /^(\d{3})(\d{7})$/;
        const match = phoneRegex.exec(phoneNumber);
        if (!match) {
            throw new Phone.ParseError();
        }
        return {
            areaCode: parseInt(match[1]),
            number: parseInt(match[2]),
        };
    }

    /**
     * A format with the following values:
     *
     * "aaa" is replaced with the area code
     *
     * "bbb" is replaced with the first 3 digits of the number
     *
     * "ccc" is replaced with the last 4 digits of the number
     *
     * "ddd" is replaced with the extension if it exists
     */
    toString(format?: string): string {
        if (!format) {
            const phoneNumber = `${this.areaCode}${this.number}`;
            if (this.extension) {
                return `${phoneNumber}x${this.extension}`;
            }
            return phoneNumber;
        }

        return format
            .replace("aaa", this.areaCode.toString())
            .replace("bbb", this.number.toString().substring(0, 3))
            .replace("cccc", this.number.toString().substring(3, 7))
            .replace("ddd", this.extension ? this.extension.toString() : "")
            .replace("x", this.extension ? "x" : "");
    }
}

export namespace Phone {
    export class ParseError extends Error {
        constructor(message = "Cannot parse phone number") {
            super(message);
            this.name = "ParseError";
        }
    }
}
