import { Typography } from "@material-ui/core";
import React from "react";
import { BasicColorSwatch } from "../BasicColorSwatch";
import { ChartContainer } from "../Charts/Container";
import { HistoricalScoreChart } from "../Charts/HistoricalScore";
import { ColorLegend } from "../ColorLegend";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { SummarySection } from "../Summary/SummarySection";
import { Transition } from "../Transition";
import { useHistoricalScoreData } from "./useHistoricalScoreData";

export function HistoricalScores(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);
    const datasets = useHistoricalScoreData({ motorCarrier });

    const includedBasics = React.useMemo(() => {
        const set = new Set<string | undefined>();
        datasets.forEach((dataset) => set.add(dataset.label));
        return set;
    }, [datasets]);

    return (
        <SummarySection>
            <div className=" flex flex-col">
                <TitleGroup
                    title={<Typography variant="h5">BASIC Safety History</Typography>}
                    // align="left"
                    className="mb-8 text-left"
                    description={`Score calculation charted across the previous ${2} years.`}
                />

                <div>
                    <ColorLegend motorCarrier={motorCarrier}>
                        {(legendArr) => {
                            return (
                                <table>
                                    <tbody className="text-gray-700 print:text-gray-700">
                                        {legendArr
                                            .filter((legend) => {
                                                if (!includedBasics.has(legend.name)) return null;
                                                return legend;
                                            })
                                            .sort((a, b) => b.score.raw - a.score.raw)
                                            .map((legend) => (
                                                <tr key={legend.name} className="">
                                                    <td>
                                                        <BasicColorSwatch basic={legend.name} />
                                                    </td>
                                                    <td className="px-4 text-left">
                                                        <Typography className="ia-transition-element print:leading-5">
                                                            {legend.name}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            );
                        }}
                    </ColorLegend>
                </div>
                <div className="grid h-full print:hidden">
                    <Typography className="self-end text-left" variant="body2">
                        * Hover over line to view score at specific point
                    </Typography>
                </div>
            </div>

            <Transition.Fade>
                <ChartContainer className="ia-transition-element">
                    <HistoricalScoreChart datasets={datasets} />
                </ChartContainer>
            </Transition.Fade>
        </SummarySection>
    );
}
