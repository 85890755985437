import { useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import tw from "twin.macro";

interface Tab<T> {
    label: ({ selected, isSelected, value }: { selected: T; isSelected: boolean; value: T }) => JSX.Element;
    value: T;
    buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}
export interface TabsProps<T extends string | number = string | number> extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
    value: T;
    onChange: (value: T) => void;
    tabs: Tab<T>[];
    borderClasses?: string;
    getIsSelected?(value: T, tabValue: Tab<T>["value"]): boolean;
}
export function Tabs<T extends string | number = string | number>({
    value,
    onChange,
    tabs,
    borderClasses,
    getIsSelected,
    ...props
}: TabsProps<T>) {
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const currentElement = useMemo<HTMLButtonElement | undefined>(() => {
        const index = tabs.findIndex((tab) => (getIsSelected ? getIsSelected(value, tab.value) : value === tab.value));
        const buttons = Array.from(container?.children || []).slice(1) as HTMLButtonElement[];
        return buttons[index];
    }, [getIsSelected, tabs, value, container]);

    const x = currentElement?.offsetLeft || 0;
    const width = currentElement?.getBoundingClientRect().width || 0;

    const [spring] = useSpring(
        {
            x,
            width,
            config: {
                friction: 18,
            },
        },
        [x, width]
    );

    return (
        <div ref={setContainer} css={tw`relative flex`} {...props}>
            <animated.div
                style={spring}
                css={tw`pointer-events-none absolute top-0 left-0 h-full border-b-2 border-current`}
                className={borderClasses}
            ></animated.div>
            {tabs.map(({ label: Label, buttonProps, ...tab }) => {
                const isSelected = getIsSelected ? getIsSelected(value, tab.value) : value === tab.value;
                return (
                    <button
                        key={tab.value}
                        onClick={() => {
                            onChange(tab.value);
                        }}
                        {...buttonProps}
                    >
                        <Label value={tab.value} selected={value} isSelected={isSelected} />
                    </button>
                );
            })}
        </div>
    );
}
