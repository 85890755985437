import { DataTable } from "@deathstar/ui";
import Columns from "./Columns";

export const hideableColumns: Array<DataTable.HideableColumn> = [
    {
        label: "Date",
        ids: [Columns.addDate.id!],
    },
    {
        label: "Removal Date",
        ids: [Columns.removalDate.id!],
    },
    {
        label: "Report Number",
        ids: [Columns.reportNumber.id!],
    },
    {
        label: "Status",
        ids: [Columns.status.id!],
    },
    {
        label: "State",
        ids: [Columns.state.id!],
    },
    {
        label: "County",
        ids: [Columns.county.id!],
    },
    {
        label: "Latitude",
        ids: [Columns.latitude.id!],
    },
    {
        label: "Longitude",
        ids: [Columns.longitude.id!],
    },
    {
        label: "DOT Match",
        ids: [Columns.inspConfidenceLevel.id!],
    },
    {
        label: "Locally Enforced",
        ids: [Columns.isLocalEnforcementJurisdiction.id!],
    },
    {
        label: "Traffic Enforced",
        ids: [Columns.trafficEnforced.id!],
    },
    {
        label: "Size/Weight Enforced",
        ids: [Columns.sizeWeightEnforced.id!],
    },
    {
        label: "Unit: VIN",
        ids: [Columns.unitVin.id!],
    },
    {
        label: "Unit: Year",
        ids: [Columns.unitYear.id!],
    },
    {
        label: "Unit: Make",
        ids: [Columns.unitMake.id!],
    },
    {
        label: "Unit: License",
        ids: [Columns.unitLicenseNumber.id!],
    },
    {
        label: "Unit: State",
        ids: [Columns.unitLicenseState.id!],
    },
    {
        label: "Unit: Type",
        ids: [Columns.unitType.id!],
    },
    {
        label: "Unit: Decal Number",
        ids: [Columns.unitDecalNumber.id!],
    },
    {
        label: "Unit: Combined Weight",
        ids: [Columns.unitWeight.id!],
    },
    {
        label: "Unit (Trailer): VIN",
        ids: [Columns.unit2Vin.id!],
    },
    {
        label: "Unit (Trailer): Year",
        ids: [Columns.unit2Year.id!],
    },
    {
        label: "Unit (Trailer): Make",
        ids: [Columns.unit2Make.id!],
    },
    {
        label: "Unit (Trailer): License",
        ids: [Columns.unit2LicenseNumber.id!],
    },
    {
        label: "Unit (Trailer): State",
        ids: [Columns.unit2LicenseState.id!],
    },
    {
        label: "Unit (Trailer): Type",
        ids: [Columns.unit2Type.id!],
    },
    {
        label: "Unit (Trailer): Decal Number",
        ids: [Columns.unit2DecalNumber.id!],
    },
    {
        label: "Shipper Name",
        ids: [Columns.shipperName.id!],
    },
    {
        label: "Shipping Paper Number",
        ids: [Columns.shippingPaperNumber.id!],
    },
    {
        label: "Facility",
        ids: [Columns.inspectionFacility.id!],
    },
    {
        label: "Location",
        ids: [Columns.locationDescription.id!],
    },
    {
        label: "HM Placard Required",
        ids: [Columns.hazmatPlacardRequiredIndicator.id!],
    },
    {
        label: "Post Accident",
        ids: [Columns.postAccidentIndicator.id!],
    },
    {
        label: Columns.timeWeight.header as string,
        ids: [Columns.timeWeight.id!],
    },
    {
        label: Columns.totalPoints.header as string,
        ids: [Columns.totalPoints.id!],
    },
    {
        label: "Total OOS",
        ids: [Columns.totalOutOfService.id!],
    },
    {
        label: "Total Driver OOS",
        ids: [Columns.totalDriverOutOfService.id!],
    },
    {
        label: "Total Vehicle OOS",
        ids: [Columns.totalVehicleOutOfService.id!],
    },
    {
        label: Columns.isUnsafeDrivingInspection.header as string,
        ids: [Columns.isUnsafeDrivingInspection.id!],
    },

    {
        label: Columns.isHoursOfServiceInspection.header as string,
        ids: [Columns.isHoursOfServiceInspection.id!],
    },

    {
        label: Columns.isControlledSubstanceInspection.header as string,
        ids: [Columns.isControlledSubstanceInspection.id!],
    },

    {
        label: Columns.isVehcileMaintenanceInspection.header as string,
        ids: [Columns.isVehcileMaintenanceInspection.id!],
    },

    {
        label: Columns.isDriverFitnessInspection.header as string,
        ids: [Columns.isDriverFitnessInspection.id!],
    },

    {
        label: Columns.isHazmatInspection.header as string,
        ids: [Columns.isHazmatInspection.id!],
    },
    {
        label: "Start Time",
        ids: [Columns.inspStartTime.id!],
    },
    {
        label: "End Time",
        ids: [Columns.inspEndTime.id!],
    },
    {
        label: "Minutes",
        ids: [Columns.inspDuration.id!],
    },
];
