import { ActionButton, classNames, DataTable, Tabs } from "@deathstar/ui";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { blazar } from "../../api/util/blazar";
import Subtitle from "../../components/title/Subtitle";
import Title from "../../components/title/Title";
import environment from "../../environment";
import { ResourceFile, resourceFiles, ResourceFileType } from "./resourceFiles";

export default function Resources() {
    const assetsDir = environment.resources.baseUrl;
    const { mutate: downloadZip, isPending } = useMutation({
        mutationFn: async () => {
            const res = await blazar.fetch("/waypoint/resources/zip");
            const blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Resources.zip";
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
    });

    const [type, setType] = useState<ResourceFileType>(ResourceFileType.Policies);
    const [search, setSearch] = useState<string>("");

    const table = useReactTable({
        data: resourceFiles,
        columns: useMemo<ColumnDef<ResourceFile>[]>(
            () => [
                { accessorKey: "name" },
                {
                    accessorKey: "type",
                    enableGlobalFilter: false,
                    header: search ? "Type" : undefined,
                    cell: (info) => (search ? <span className="text-stone-600">{info.getValue() as string}</span> : null),
                },
            ],
            [search]
        ),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: DataTable.fuzzyFilter,
        state: {
            globalFilter: search,
            columnFilters: useMemo(() => (search ? [] : [{ id: "type", value: type }]), [type, search]),
        },
        onGlobalFilterChange: (value) => {
            setSearch(value);
        },
    });

    return (
        <div className="space-y-6">
            <Title>Resources</Title>
            <Subtitle>
                Access documents we recommend for driver and equipment management, sample contracts, and template company policies
            </Subtitle>
            <ActionButton onClick={() => downloadZip()} disabled={isPending}>
                <ArrowDownTrayIcon className="h-4 w-4" />
                Download all files
            </ActionButton>
            <DataTable
                table={table}
                onRowClick={(row) => {
                    window.open(assetsDir + row.path, "_blank");
                }}
                searchPlaceholder="Search..."
                actions={[
                    search ? (
                        <p className="text-stone-600">Showing search results...</p>
                    ) : (
                        <Tabs
                            tabs={[
                                {
                                    label: ({ value, isSelected }) => (
                                        <p
                                            className={classNames(
                                                "px-2 py-1 text-stone-700",
                                                isSelected && "font-medium !text-waypoint-blue"
                                            )}
                                        >
                                            {value}
                                        </p>
                                    ),
                                    value: ResourceFileType.Policies,
                                },
                                {
                                    label: ({ value, isSelected }) => (
                                        <p
                                            className={classNames(
                                                "px-2 py-1 text-stone-700",
                                                isSelected && "font-medium !text-waypoint-blue"
                                            )}
                                        >
                                            {value}
                                        </p>
                                    ),
                                    value: ResourceFileType.Contracts,
                                },
                                {
                                    label: ({ value, isSelected }) => (
                                        <p
                                            className={classNames(
                                                "px-2 py-1 text-stone-700",
                                                isSelected && "font-medium !text-waypoint-blue"
                                            )}
                                        >
                                            {value}
                                        </p>
                                    ),
                                    value: ResourceFileType.Driver,
                                },
                                {
                                    label: ({ value, isSelected }) => (
                                        <p
                                            className={classNames(
                                                "px-2 py-1 text-stone-700",
                                                isSelected && "font-medium !text-waypoint-blue"
                                            )}
                                        >
                                            {value}
                                        </p>
                                    ),
                                    value: ResourceFileType.Equipment,
                                },
                            ]}
                            value={type}
                            onChange={(value) => {
                                setType(value);
                            }}
                            borderClasses="border-waypoint-blue"
                        />
                    ),
                ]}
            />
        </div>
    );
}
