import { Type, plainToClass } from "class-transformer";
import { NumberFormatter } from "../util/NumberFormatter/NumberFormatter";
import { Attribute } from "./Attribute";
import { Comparison } from "./Comparison/Comparison";
import { Coverage } from "./Coverage";
import { CoverageLimitOption } from "./CoverageLimitOption";
import { CoverageModification } from "./CoverageModification";
import { CoverageScheduleOfHazard } from "./CoverageScheduleOfHazard";
import { CoverageUnderlyingCoverage } from "./CoverageUnderlyingCoverage";
import { Property } from "./Property";

export class CoverageLimit {
    static readonly UNLIMITED = "Unlimited";
    static readonly STATUTORY_MINIMUM = "Statutory Minimum";

    static duplicate(coverageLimit: CoverageLimit): CoverageLimit {
        const c = plainToClass(CoverageLimit, coverageLimit);

        c.id = null;
        c.createdDate = null;
        c.coverageId = null;
        c.coverageCreatedDate = null;
        c.coverage = null;

        c.attributes = c.attributes?.map((a) => Attribute.duplicate(a)) ?? [];
        c.modifications = c.modifications?.map((m) => CoverageModification.duplicate(m)) ?? [];
        c.underlyingCoverages = c.underlyingCoverages?.map((u) => CoverageUnderlyingCoverage.duplicate(u)) ?? [];
        c.scheduleOfHazards = c.scheduleOfHazards?.map((s) => CoverageScheduleOfHazard.duplicate(s)) ?? [];

        return c;
    }

    static new(entity: Partial<CoverageLimit>, options?: { extractOptionFrom?: CoverageLimit }): CoverageLimit {
        return plainToClass(CoverageLimit, {
            ...entity,
            coverageLimitOptionId: entity.coverageLimitOptionId || options?.extractOptionFrom?.coverageLimitOptionId,
            coverageLimitOption: entity.coverageLimitOption || options?.extractOptionFrom?.coverageLimitOption,
        });
    }

    static compare({ base, compare }: { base?: CoverageLimit; compare?: CoverageLimit }): CoverageLimit.IComparisonReturn {
        const comparison = new Comparison(CoverageLimit) as CoverageLimit.IComparisonReturn;
        comparison.setField("coverageLimitOptionId", compare?.coverageLimitOptionId || base?.coverageLimitOptionId);
        comparison.setField("coverageLimitOption", comparison?.coverageLimitOption || base?.coverageLimitOption);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add Limit: ${compare.toString()}`,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete Limit: ${base.toString()}`,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        [
            {
                key: "limit1Text",
                label: "Limit 1",
                transform: (v) => v,
            },
            {
                key: "limit1Money",
                label: "Limit 1",
                transform: (v) => (v ? NumberFormatter.Currency.format(v) : "Included"),
            },
            {
                key: "limit2Text",
                label: "Limit 2",
                transform: (v) => v,
            },
            {
                key: "limit2Money",
                label: "Limit 2",
                transform: (v) => (v ? NumberFormatter.Currency.format(v) : "n/a"),
            },
            {
                key: "deductibleText",
                label: "Deductible",
                transform: (v) => v,
            },
            {
                key: "deductibleMoney",
                label: "Deductible",
                transform: (v) => (v ? NumberFormatter.Currency.format(v) : "n/a"),
            },
        ].forEach(({ key, label, transform }) => {
            // if both comparison values are null or undefined, skip
            if ((base[key] === null || base[key] === undefined) && (compare[key] === null || compare[key] === undefined)) return;

            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    actionLabel: "update",
                    type: "change",
                    description: `Change ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    priority: null,
                    fieldName: key as keyof CoverageLimit,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        Comparison.compareArrayFields<CoverageLimit, "attributes">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "attributes",
            fieldKey: "attributeOptionId",
            itemDescriptionName: "Attribute",
            getItemDescription(entity) {
                return entity.attributeOption.name;
            },
        });

        Comparison.compareArrayFields<CoverageLimit, "modifications">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "modifications",
            fieldKey: "modificationOptionId",
            itemDescriptionName: "Modification",
            getItemDescription(entity) {
                return entity.modificationOption.name;
            },
        });

        Comparison.compareArrayFields<CoverageLimit, "scheduleOfHazards">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "scheduleOfHazards",
            fieldKey: "propertyId",
            itemDescriptionName: "Location",
            getItemDescription(entity) {
                return entity.property?.street1 || entity.toString();
            },
        });

        Comparison.compareArrayFields<CoverageLimit, "underlyingCoverages">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "underlyingCoverages",
            fieldKey: "description",
            itemDescriptionName: "Underlying Coverage",
            getItemDescription(entity) {
                return entity.description;
            },
        });

        return comparison;
    }

    id: number;
    createdDate: Date;

    coverageLimitOptionId: number;
    coverageLimitOption: CoverageLimitOption;

    coverageId?: number;
    coverageCreatedDate?: Date;
    coverage?: Coverage;

    sortOrder: number;
    limit1Text?: string;
    limit1Money?: number;
    limit2Text?: string;
    limit2Money?: number;
    deductibleText?: string;
    deductibleMoney?: number;

    propertyId?: number;
    propertyEffectiveDate?: Date;
    @Type(() => Property)
    linkedProperty?: Property;

    metadata?: Record<string, unknown>;

    @Type(() => Attribute)
    attributes?: Attribute[];

    @Type(() => CoverageModification)
    modifications?: CoverageModification[];

    @Type(() => CoverageUnderlyingCoverage)
    underlyingCoverages?: CoverageUnderlyingCoverage[];

    @Type(() => CoverageScheduleOfHazard)
    scheduleOfHazards?: CoverageScheduleOfHazard[];

    get limit1(): number | string | null {
        return typeof this.limit1Money === "number" ? this.limit1Money : this.limit1Text || null;
    }

    get limit2(): number | string | null {
        return typeof this.limit2Money === "number" ? this.limit2Money : this.limit2Text || null;
    }

    get deductible(): number | string | null {
        return typeof this.deductibleMoney === "number" ? this.deductibleMoney : this.deductibleText || null;
    }

    get comparisonKey(): string {
        if (this.coverageLimitOptionId === CoverageLimitOption.Id.SPECIFIED_SHIPPER) {
            return `${this.coverageLimitOptionId}-${this.limit1Text}`;
        }
        return this.coverageLimitOptionId.toString();
    }

    toString(): string {
        const nf = NumberFormatter.Currency;
        const parts: string[] = [];

        if (this.coverageLimitOption?.name) parts.push(this.coverageLimitOption?.name);
        if (this.limit1) {
            const limit = [];
            limit.push(typeof this.limit1 === "number" ? nf.format(this.limit1) : this.limit1);
            if (this.limit2) limit.push(typeof this.limit2 === "number" ? nf.format(this.limit2) : this.limit2);
            if (this.deductible) limit.push(typeof this.deductible === "number" ? nf.format(this.deductible) : this.deductible);
            parts.push(limit.join("/"));
        }

        return parts.join(", ");
    }

    compare(comparisonLimit: CoverageLimit, options?: Comparison.getBaseAndComparisonObjects.IOptions): Comparison<CoverageLimit> {
        return CoverageLimit.compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonLimit,
                },
                options
            )
        );
    }
}

export namespace CoverageLimit {
    export interface IComparisonReturn extends Comparison<CoverageLimit> {
        coverageLimitOptionId: number;
        coverageLimitOption?: CoverageLimitOption;
    }
}
