import { ValidatorConstraint, ValidatorConstraintInterface } from "class-validator";
import { Policy } from '../Policy';

@ValidatorConstraint({
    name: "BillMethodValidator",
    async: false,
})
export class BillMethodValidator implements ValidatorConstraintInterface {
    validate(value: string): boolean {
        return (Object.values(Policy.BillMethod) as string[]).includes(value);
    }

    defaultMessage(): string {
        return "$property is invalid";
    }
}
