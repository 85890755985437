import { User } from "@deathstar/types/waypoint";
import { InputField, PrimaryButton, useSnackbar } from "@deathstar/ui";
import { useForm } from "react-hook-form";
import api from "../../../api/api";

export function UserProfileForm({ user }: { user: User }) {
    const form = useForm({
        defaultValues: {
            displayName: user.displayName,
        },
    });
    return (
        <form
            onSubmit={form.handleSubmit(async function onValid(data) {
                await api.user.update(data);
                api.user.invalidateQueries();
                useSnackbar.add("Profile updated", { variant: "success" });
            })}
            className="w-full max-w-md space-y-4"
        >
            <InputField {...form.register("displayName")} label="Name" />
            <InputField
                label="Email"
                value={user.email}
                disabled
                classes={{ input: "disabled:text-stone-500 pointer-events-none", inputContainerDisabled: "cursor-not-allowed" }}
            />
            <div className="flex justify-end">
                <PrimaryButton type="submit">Save</PrimaryButton>
            </div>
        </form>
    );
}
