import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { Basic } from "../Basic/Basic";
import { MotorCarrier } from "../MotorCarrier";

export abstract class Trend {
    readonly basic: FMCSA.BasicName;
    readonly carrier: MotorCarrier;
    readonly dateRange: MotorCarrier.IDateRange;

    constructor({ carrier, dateRange, basic }: Trend.Options) {
        this.carrier = carrier;
        this.dateRange = dateRange;
        this.basic = basic;
    }
    abstract readonly type: "Positive" | "Negative";
    abstract isValid(): boolean;
    abstract format(): string;

    protected _calculateTotalMonths(): number {
        const total = DateTime.differenceInCalendarMonths(this.dateRange.from, this.dateRange.to);
        if (total < 0) return total * -1;
        return total;
    }
}

export namespace Trend {
    export interface Options {
        dateRange: MotorCarrier.IDateRange;
        basic: FMCSA.BasicName;
        carrier: MotorCarrier;
    }
}

export class BasicAssessmentTrend extends Trend {
    #totalTimesAssessed: number;
    #totalMonths: number;
    #totalViolations: number;
    readonly type = "Positive";
    constructor(options: Trend.Options) {
        super(options);

        const basicAssessedInspections = this.carrier.inspections.filterByDateRange(this.dateRange).filterByBasicAssessed(this.basic);

        this.#totalTimesAssessed = basicAssessedInspections.total;
        this.#totalMonths = this._calculateTotalMonths();
        this.#totalViolations = basicAssessedInspections.filterByBasicViolation(this.basic).violations.filterByBasic(this.basic).total;
    }

    isValid(): boolean {
        return this.#totalViolations === 0 && this.#totalTimesAssessed > Basic.getMinimumRelevant(this.basic);
    }

    format(): string {
        return `${this.basic} inspected ${this.#totalTimesAssessed} times over the past ${this.#totalMonths} month with no violations.`;
    }
}

export abstract class BasicSubCategoryFrequencyTrend extends Trend {
    static new(options: BasicSubCategoryFrequencyTrend.Options): Trend | null {
        if (options.endingScore + options.minTrendDifference <= options.startingScore) {
            return new PositiveBasicSubCategoryFrequencyTrend(options);
        } else if (options.endingScore >= options.startingScore + options.minTrendDifference) {
            return new NegativeBasicSubCategoryFrequencyTrend(options);
        }
        return null;
    }

    readonly subCategoryName: string;
    readonly percentChange: number;
    readonly totalMonths: number;

    constructor(options: BasicSubCategoryFrequencyTrend.Options) {
        super(options);

        this.subCategoryName = options.subCategoryName;
        this.percentChange = options.startingScore - options.endingScore;
        if (this.percentChange < 0) {
            this.percentChange *= -1;
        }
        this.totalMonths = this._calculateTotalMonths();
    }
}

export namespace BasicSubCategoryFrequencyTrend {
    export interface Options extends Trend.Options {
        startingScore: number;
        endingScore: number;
        subCategoryName: string;
        minTrendDifference: number;
    }
}

export class NegativeBasicSubCategoryFrequencyTrend extends BasicSubCategoryFrequencyTrend {
    readonly type = "Negative";

    isValid(): boolean {
        return true;
    }

    format(): string {
        return `${this.subCategoryName} violations increased by ${this.percentChange}% over the past ${this.totalMonths} months.`;
    }
}

export class PositiveBasicSubCategoryFrequencyTrend extends BasicSubCategoryFrequencyTrend {
    readonly type = "Positive";

    isValid(): boolean {
        return true;
    }

    format(): string {
        return `${this.subCategoryName} violations decreased by ${this.percentChange}% over the past ${this.totalMonths} months.`;
    }
}
