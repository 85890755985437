import { MotorCarrier } from "@deathstar/motor-carrier";
import { fuzzyFilter } from "@deathstar/ui";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { useLocalStorage } from "web-api-hooks";
import Columns, { columns } from "./Columns";
import { hideableColumns } from "./hideableColumns";

function useColumnVisibility(): [Record<string, boolean>, React.Dispatch<React.SetStateAction<Record<string, boolean>>>] {
    const [columnVisibility, setColumnVisibility] = useLocalStorage<Record<string, boolean>>("ia-inspection-table-column-visibility-2", {
        [Columns.addDate.id as string]: true,
        [Columns.removalDate.id as string]: false,
        [Columns.reportNumber.id as string]: false,
        [Columns.status.id as string]: false,
        [Columns.state.id as string]: true,
        [Columns.county.id as string]: false,
        [Columns.latitude.id as string]: false,
        [Columns.longitude.id as string]: false,
        [Columns.inspConfidenceLevel.id as string]: false,
        [Columns.isLocalEnforcementJurisdiction.id as string]: false,
        [Columns.trafficEnforced.id as string]: false,
        [Columns.sizeWeightEnforced.id as string]: false,
        [Columns.unitVin.id as string]: true,
        [Columns.unitYear.id as string]: false,
        [Columns.unitMake.id as string]: false,
        [Columns.unitLicenseNumber.id as string]: false,
        [Columns.unitLicenseState.id as string]: false,
        [Columns.unitType.id as string]: false,
        [Columns.unitDecalNumber.id as string]: false,
        [Columns.unitWeight.id as string]: false,
        [Columns.unit2Vin.id as string]: true,
        [Columns.unit2Year.id as string]: false,
        [Columns.unit2Make.id as string]: false,
        [Columns.unit2LicenseNumber.id as string]: false,
        [Columns.unit2LicenseState.id as string]: false,
        [Columns.unit2Type.id as string]: false,
        [Columns.unit2DecalNumber.id as string]: false,
        [Columns.shipperName.id as string]: false,
        [Columns.shippingPaperNumber.id as string]: false,
        [Columns.inspectionFacility.id as string]: false,
        [Columns.locationDescription.id as string]: true,
        [Columns.hazmatPlacardRequiredIndicator.id as string]: false,
        [Columns.postAccidentIndicator.id as string]: false,
        [Columns.timeWeight.id as string]: false,
        [Columns.totalPoints.id as string]: false,
        [Columns.totalOutOfService.id as string]: false,
        [Columns.totalDriverOutOfService.id as string]: false,
        [Columns.totalVehicleOutOfService.id as string]: false,
        [Columns.isUnsafeDrivingInspection.id as string]: true,
        [Columns.isHoursOfServiceInspection.id as string]: true,
        [Columns.isControlledSubstanceInspection.id as string]: true,
        [Columns.isVehcileMaintenanceInspection.id as string]: true,
        [Columns.isDriverFitnessInspection.id as string]: true,
        [Columns.isHazmatInspection.id as string]: true,
        [Columns.inspStartTime.id as string]: false,
        [Columns.inspEndTime.id as string]: false,
        [Columns.inspDuration.id as string]: true,
    });

    React.useEffect(() => {
        setColumnVisibility((old) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newState: Record<string, any> = {};
            for (const key in old) {
                if (hideableColumns.some((c) => c.ids.includes(key))) {
                    newState[key] = old[key];
                }
            }
            return newState;
        });
    }, [setColumnVisibility]);

    return [columnVisibility, setColumnVisibility];
}

export function useTable(motorCarrier: MotorCarrier, filter?: string) {
    const data = React.useMemo(() => motorCarrier.inspections.sortByDate("DESC").array(), [motorCarrier]);

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = useColumnVisibility();

    const table = useReactTable<MotorCarrier.IInspection>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        globalFilterFn: fuzzyFilter,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,
        state: {
            columnVisibility,
            columnFilters,
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
    });

    React.useEffect(() => {
        table.setColumnFilters((filtersState) => {
            const latitudeFilter = {
                id: Columns.latitude.id!,
                value: filter,
            };
            if (!filtersState.length) {
                return [latitudeFilter];
            }
            return filtersState.map((filter) => {
                if (filter.id === latitudeFilter.id) {
                    return latitudeFilter;
                }
                return filter;
            });
        });
    }, [table, filter, data]);

    return {
        data,
        table,
    };
}
