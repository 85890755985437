import { plainToClass } from "class-transformer";
import { BusinessAuto, CoverageLimitOption, Driver, InlandMarineEquipment, Property, Tractor, Trailer } from "..";
import type { Comparison } from "../Comparison/Comparison";
import { EquipmentComparison } from "../Comparison/EquipmentComparison";
import { Coverage } from "../Coverage";
import { CoverageOption } from "../CoverageOption";
import { PolicyAdditionalInterest } from "../PolicyAdditionalInterest";
import { PolicyCommission } from "../PolicyCommission";
import type { PolicyLayer } from "./PolicyLayer";

export class PolicyLayerComparison {
    static getCoveragesWithChanges(layerComparisonResult: PolicyLayerComparison): CoverageOption.IMetadata[] {
        const changeList = Array.from(layerComparisonResult.equipment.getChangeList().entries());
        const coverageIds = new Set<CoverageOption.Id>();
        changeList.forEach(([_entityId, { changeMap }]) => {
            Array.from(changeMap.keys()).forEach((coverageId) => coverageIds.add(coverageId));
        });
        return Array.from(coverageIds)
            .map((coverageId) => {
                return CoverageOption.getMetadata(coverageId);
            })
            .sort((a, b) => a.defaultSortOrder - b.defaultSortOrder);
    }

    #baseLayer: PolicyLayer;
    #comparisonLayer: PolicyLayer;
    equipment: EquipmentComparison;
    coverages: Map<CoverageOption.Id, Coverage.IComparisonReturn> = new Map();
    additionalInterests: Map<number /* globalCertHolderId */, PolicyAdditionalInterest.IComparisonReturn> = new Map();
    commissions: Map<string /* commissionOptionCode */, PolicyCommission.IComparisonReturn> = new Map();
    #equipment = {
        drivers: new Map<number, Driver>(),
        businessAutos: new Map<number, BusinessAuto>(),
        tools: new Map<number, InlandMarineEquipment>(),
        tractors: new Map<number, Tractor>(),
        trailers: new Map<number, Trailer>(),
        properties: new Map<string, Property>(),
    };
    #optionsUsed: PolicyLayerComparison.IComparisonOptions = null;

    constructor(
        baseLayer: PolicyLayer,
        comparisonLayer: PolicyLayer,
        options?: {
            equipment?: PolicyLayerComparison.IEquipment;
            coverageLimitOptions?: CoverageLimitOption[];
        }
    ) {
        this.#baseLayer = baseLayer;
        this.#comparisonLayer = comparisonLayer;

        if (options?.equipment) {
            if (options.equipment?.drivers) {
                this.#equipment.drivers = new Map(options.equipment.drivers.map((driver) => [driver.id, driver]));
            }
            if (options.equipment?.businessAutos) {
                this.#equipment.businessAutos = new Map(options.equipment.businessAutos.map((ba) => [ba.id, ba]));
            }
            if (options.equipment?.tools) {
                this.#equipment.tools = new Map(options.equipment.tools.map((tool) => [tool.id, tool]));
            }
            if (options.equipment?.tractors) {
                this.#equipment.tractors = new Map(options.equipment.tractors.map((tractor) => [tractor.id, tractor]));
            }
            if (options.equipment?.trailers) {
                this.#equipment.trailers = new Map(options.equipment.trailers.map((trailer) => [trailer.id, trailer]));
            }
            if (options.equipment?.properties) {
                this.#equipment.properties = new Map(
                    options.equipment.properties.map((property) => [`${property.id}-${property.effectiveDate.valueOf()}`, property])
                );
            }
        }
        comparisonLayer.policy.coverages.forEach((cvg) => {
            const coverage = Coverage.new({
                ...cvg,
                limits: undefined,
                underlyingCoverages: undefined,
            });
            cvg.underlyingCoverages.forEach((uc) => {
                if (uc.coverage) return;
                uc.coverage = coverage;
            });
            cvg.limits.forEach((limit) => {
                if (!limit.coverage) {
                    limit.coverage = coverage;
                }

                if (!limit.coverageLimitOption && options?.coverageLimitOptions) {
                    limit.coverageLimitOption = options.coverageLimitOptions.find((clo) => clo.id === limit.coverageLimitOptionId);
                }
            });
        });
    }

    get isEquipmentMatch(): boolean {
        return this.equipment?.isMatch ?? true;
    }

    get isCoverageMatch(): boolean {
        if (this.coverages.size) {
            return Array.from(this.coverages.values()).every((cov) => cov.isMatch);
        }
        if (!this.coverages.size && (this.#baseLayer.policy.coverages.length || this.#comparisonLayer.policy.coverages.length)) {
            return false;
        }
        return true;
    }

    get isAdditionalInterestMatch(): boolean {
        if (this.additionalInterests.size) {
            return Array.from(this.additionalInterests.values()).every((ai) => ai.isMatch);
        }
        if (
            !this.additionalInterests.size &&
            (this.#baseLayer.policy.additionalInterests.length || this.#comparisonLayer.policy.additionalInterests.length)
        ) {
            return false;
        }
        return true;
    }

    get isAccountingMatch(): boolean {
        if (this.commissions.size) {
            return Array.from(this.commissions.values()).every((comm) => comm.isMatch);
        }
        if (!this.commissions.size && (this.#baseLayer.policy.commissions.length || this.#comparisonLayer.policy.commissions.length)) {
            return false;
        }
        return true;
    }

    get isMatch(): boolean {
        return this.isCoverageMatch && this.isAdditionalInterestMatch && this.equipment && this.isEquipmentMatch && this.isAccountingMatch;
    }

    #compareEquipment() {
        this.equipment = new EquipmentComparison(this.#baseLayer, this.#comparisonLayer);
        this.equipment.compare();
    }

    #compareCoverages({ separateEquipment }: { separateEquipment: boolean }) {
        const coveragesToCompare = new Map<CoverageOption.Type, { from: Coverage; to: Coverage }>();

        this.#baseLayer.policy.coverages.forEach((coverage) => {
            coveragesToCompare.set(coverage.coverageOption.type, { from: coverage, to: null });
        });
        this.#comparisonLayer.policy.coverages.forEach((coverage) => {
            const coverageToCompare = coveragesToCompare.get(coverage.coverageOption.type);
            if (coverageToCompare) {
                coverageToCompare.to = Coverage.init(coverage);
                coverageToCompare.from = Coverage.init(coverageToCompare.from);
            } else {
                coveragesToCompare.set(coverage.coverageOption.type, { from: null, to: Coverage.init(coverage) });
            }
        });

        return Array.from(coveragesToCompare.values()).map((coverageToCompare) =>
            Coverage.compare(
                {
                    base: coverageToCompare.from,
                    compare: coverageToCompare.to,
                },
                {
                    separateEquipment,
                }
            )
        );
    }

    #compareAdditionalInterests() {
        const additionalInterestsToCompare = new Map<
            number /* globalCertHolderId */,
            { from: PolicyAdditionalInterest; to: PolicyAdditionalInterest }
        >();

        this.#baseLayer.policy.additionalInterests.forEach((ai) => {
            additionalInterestsToCompare.set(ai.additionalInterestId, { from: ai, to: null });
        });
        this.#comparisonLayer.policy.additionalInterests.forEach((ai) => {
            const aiToCompare = additionalInterestsToCompare.get(ai.additionalInterestId);
            if (aiToCompare) {
                aiToCompare.to = ai;
            } else {
                additionalInterestsToCompare.set(ai.additionalInterestId, { from: null, to: ai });
            }
        });

        return Array.from(additionalInterestsToCompare.values()).map((aiToCompare) =>
            PolicyAdditionalInterest.compare({
                base: aiToCompare.from,
                compare: aiToCompare.to,
            })
        );
    }

    #compareAccounting() {
        const commissionsToCompare = new Map();

        this.#baseLayer.policy.commissions.forEach((comm) => {
            commissionsToCompare.set(comm.commissionOptionCode, { from: comm, to: null });
        });
        this.#comparisonLayer.policy.commissions.forEach((comm) => {
            const commToCompare = commissionsToCompare.get(comm.commissionOptionCode);
            if (commToCompare) {
                commToCompare.to = comm;
            } else {
                commissionsToCompare.set(comm.commissionOptionCode, { from: null, to: comm });
            }
        });

        return Array.from(commissionsToCompare.values()).map((commToCompare) =>
            PolicyCommission.compare({
                base: commToCompare.from,
                compare: commToCompare.to,
            })
        );
    }

    #setEquipment() {
        [
            ...this.#baseLayer.policy.coverages,
            ...this.#comparisonLayer.policy.coverages,
            ...this.#baseLayer.policy.additionalInterests,
            ...this.#comparisonLayer.policy.additionalInterests,
        ].forEach((item) => {
            item.linkedTractors?.forEach((it) => {
                it.tractor = this.#equipment.tractors.get(it.tractorId);
            });
            item.linkedTrailers?.forEach((it) => {
                it.trailer = this.#equipment.trailers.get(it.trailerId);
            });
            item.linkedTools?.forEach((it) => {
                it.tool = this.#equipment.tools.get(it.toolId);
            });
            item.linkedBusinessAutos?.forEach((it) => {
                it.businessAuto = this.#equipment.businessAutos.get(it.businessAutoId);
            });
            if ("linkedDrivers" in item) {
                item.linkedDrivers.forEach((it) => {
                    it.driver = this.#equipment.drivers.get(it.driverId);
                });
            }
            if ("linkedProperties" in item) {
                item.linkedProperties.forEach((it) => {
                    const prop = this.#equipment.properties.get(`${it.propertyId}-${it.propertyEffectiveDate.valueOf()}`);
                    if (!prop) return;
                    it.property = plainToClass(Property, prop);
                });
            }
        });
    }

    compare(comparisonOptions?: PolicyLayerComparison.IComparisonOptions): this {
        this.#optionsUsed = comparisonOptions || null;
        const compareCoverage = comparisonOptions?.coverages ?? true;

        if (compareCoverage || comparisonOptions?.separateEquipment) {
            this.#setEquipment();
        }

        if (compareCoverage) {
            const comparedCoverages = this.#compareCoverages({
                separateEquipment: comparisonOptions?.separateEquipment,
            });
            comparedCoverages.forEach((cov) => {
                this.coverages.set(cov.coverageOptionId, cov);
            });
        }

        const compareAdditionalInterests = comparisonOptions?.additionalInterests ?? true;
        if (compareAdditionalInterests) {
            const comparedAdditionalInterests = this.#compareAdditionalInterests();
            comparedAdditionalInterests.forEach((ai) => {
                this.additionalInterests.set(ai.additionalInterestId, ai);
            });
        }

        if (comparisonOptions?.separateEquipment) {
            this.#compareEquipment();
        }

        if (comparisonOptions?.accounting) {
            const compareCommission = this.#compareAccounting();
            compareCommission.forEach((comm) => {
                this.commissions.set(comm.commissionOptionCode, comm);
            });
        }

        return this;
    }

    getChangeList(options?: { includeEquipmentChanges?: boolean }): Comparison.IChange[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const changeList: Comparison.IChange<any>[] = [];
        const includeEquipmentChanges = options?.includeEquipmentChanges ?? this.#optionsUsed?.separateEquipment ?? true;

        if (this.equipment && includeEquipmentChanges) {
            for (const [_, { changeMap }] of this.equipment.getChangeList().entries()) {
                for (const comparison of changeMap.values()) {
                    changeList.push(...comparison.changes);
                }
            }
        }
        if (this.coverages) {
            for (const comparison of this.coverages.values()) {
                changeList.push(...comparison.changeList);
            }
        }
        if (this.additionalInterests) {
            for (const comparison of this.additionalInterests.values()) {
                changeList.push(...comparison.changeList);
            }
        }
        if (this.commissions) {
            for (const comparison of this.commissions.values()) {
                changeList.push(...comparison.changeList);
            }
        }

        return changeList;
    }
}

export namespace PolicyLayerComparison {
    export interface IComparisonOptions {
        coverages?: boolean;
        additionalInterests?: boolean;
        separateEquipment?: boolean;
        accounting?: boolean;
        data?: {
            equipment?: IEquipment;
            coverageLimitOptions?: CoverageLimitOption[];
        };
    }

    export interface IEquipment {
        drivers?: Driver[];
        businessAutos?: BusinessAuto[];
        tools?: InlandMarineEquipment[];
        tractors?: Tractor[];
        trailers?: Trailer[];
        properties?: Property[];
    }
}
