import { Type, plainToClass } from "class-transformer";
import { AttributeOption } from "./AttributeOption";
import type { BusinessAuto } from "./BusinessAuto";
import { Comparison } from "./Comparison/Comparison";
import type { Coverage } from "./Coverage";
import type { CoverageLimit } from "./CoverageLimit";
import type { Driver } from "./Driver";
import { InlandMarineEquipment } from "./InlandMarineEquipment";
import type { Policy } from "./Policy";
import type { Property } from "./Property";
import type { Tractor } from "./Tractor";
import type { Trailer } from "./Trailer";

export class Attribute implements Attribute.HasAttributeOptionId, Comparison.ICanCompare<Attribute> {
    static filterOutEquipmentAndDriverAttributes<T extends Attribute.HasAttributeOptionId>(attributes: T[]): T[] {
        if (!attributes?.length) return [];
        return attributes.filter((attr) => {
            if (Attribute.isEquipmentOrDriverAttribute(attr)) return null;
            return attr;
        });
    }
    static filterInEquipmentAndDriverAttributes<T extends Attribute.HasAttributeOptionId>(attributes: T[]): T[] {
        return attributes.filter((attr) => {
            if (Attribute.isEquipmentOrDriverAttribute(attr)) return attr;
            return null;
        });
    }

    static filterInCompAndCollAttributes<T extends Attribute.HasAttributeOptionId>(attributes: T[]): T[] {
        return attributes.filter((attr) => {
            if (Attribute.isCompOrCollAttribute(attr)) return attr;
            return null;
        });
    }

    static isCompOrCollAttribute(attr: Attribute.HasAttributeOptionId): boolean {
        return Attribute.#isAttribute(attr, [AttributeOption.Id.UNIT_COLLISION, AttributeOption.Id.UNIT_COMPREHENSIVE]);
    }

    static #isAttribute(attr: Attribute.HasAttributeOptionId, attributeOptionIds: string[]): boolean {
        return attributeOptionIds.includes(attr.attributeOptionId);
    }

    static isEquipmentOrDriverAttribute(attr: Attribute.HasAttributeOptionId): boolean {
        return Attribute.#isAttribute(attr, AttributeOption.EQUIPMENT_ATTRIBUTE_IDS);
    }

    static isValuationAttribute(attr: Attribute.HasAttributeOptionId): boolean {
        return Attribute.#isAttribute(attr, AttributeOption.VALUATION_ATTRIBUTE_IDS);
    }

    static isSymbolAttribute(attr: Attribute.HasAttributeOptionId): boolean {
        return Attribute.#isAttribute(attr, AttributeOption.SYMBOL_ATTRIBUTE_IDS);
    }

    static duplicate(attribute: Attribute): Attribute {
        const a = plainToClass(Attribute, attribute);
        a.id = null;
        a.createdDate = null;
        a.policyId = null;
        a.policyCreatedDate = null;
        a.policy = null;
        a.coverage = null;
        a.coverageId = null;
        a.coverageCreatedDate = null;
        a.coverageLimitId = null;
        a.coverageLimitCreatedDate = null;
        a.coverageLimit = null;
        a.linkedCoverageId = null;
        a.linkedCoverageCreatedDate = null;
        a.linkedCoverage = null;
        if (attribute.attributeOptionId === AttributeOption.Id.AGENCY_EXPENSE) {
            a.valueText = null;
        }

        return a;
    }

    static compare({ base, compare }: { base?: Attribute; compare?: Attribute }): Attribute.IComparisonReturn {
        const comparison = new Comparison(Attribute) as Attribute.IComparisonReturn;
        comparison.setField("attributeOptionId", compare?.attributeOptionId || base?.attributeOptionId);
        comparison.setField("attributeOption", compare?.attributeOption || base?.attributeOption);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add Modification: ${compare.toString()} `,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete Modification: ${base.toString()}`,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        [
            {
                key: "valueText",
                label: "Value",
                transform: (v) => v,
            },
            {
                key: "valueNumber",
                label: "Value",
                transform: (v) => v,
            },
        ].forEach(({ key, label, transform }) => {
            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Change ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    priority: null,
                    fieldName: key as keyof Attribute,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        return comparison;
    }

    id: number;
    createdDate: Date;

    attributeOptionId: AttributeOption.Id;
    @Type(() => AttributeOption)
    attributeOption?: AttributeOption;
    valueNumber?: number;
    valueText?: string;

    policyId?: string;
    policyCreatedDate?: Date;
    policy?: Policy;

    coverageId?: number;
    coverageCreatedDate?: Date;
    coverage?: Coverage;

    coverageLimitId?: number;
    coverageLimitCreatedDate?: Date;
    coverageLimit?: CoverageLimit;

    linkedCoverageId?: number;
    linkedCoverageCreatedDate?: Date;
    linkedCoverage?: Coverage;

    driverId?: number;
    driver?: Driver;

    businessAutoId?: number;
    businessAuto?: BusinessAuto;

    trailerId?: number;
    trailer?: Trailer;

    tractorId?: number;
    tractor?: Tractor;

    toolId?: number;
    tool?: InlandMarineEquipment;

    propertyId?: number;
    propertyEffectiveDate?: Date;
    property?: Property;

    metadata?: Record<string, unknown>;

    #addValutToStringParts(parts: (string | number)[]): void {
        if (typeof this.valueNumber === "number") {
            parts.push(...[": ", this.valueNumber]);
        } else if (typeof this.valueText === "string") {
            parts.push(...[": ", this.valueText]);
        }
    }

    toString(): string {
        if (this.attributeOption) {
            const parts = [this.attributeOption.name];
            this.#addValutToStringParts(parts);
            return parts.join("");
        }

        const parts = ["Attribute:", this.attributeOptionId];
        this.#addValutToStringParts(parts);
        return parts.join("");
    }

    compare(comparisonAttribute: Attribute, options?: Comparison.getBaseAndComparisonObjects.IOptions): Comparison<Attribute> {
        return Attribute.compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonAttribute,
                },
                options
            )
        );
    }
}

export namespace Attribute {
    export interface HasAttributeOptionId {
        attributeOptionId: string;
    }

    export interface IComparisonReturn extends Comparison<Attribute> {
        attributeOptionId: AttributeOption.Id;
        attributeOption?: AttributeOption;
    }
}
