import { classNames } from "@deathstar/ui";
import { Tooltip, Typography } from "@material-ui/core";
import React from "react";
import CrashIcon from "../../assets/icons/crash-icon.png";
import DriverIcon from "../../assets/icons/driver-icon.png";
import TractorIcon from "../../assets/icons/tractor-icon.png";
import TrailerIcon from "../../assets/icons/trailer-icon.png";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { Transition } from "../Transition";
import { pluralize } from "../utils/pluralize";


export function FmcsaReportedFigures({ className }: { className?: string }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const figures = [
        {
            src: TractorIcon,
            alt: "Power Units Icon",
            data: motorCarrier.details.TotalPowerUnits,
            label: pluralize(motorCarrier.details.TotalPowerUnits, "Power Unit", "s"),
            className: "sm:order-2 md:order-3 xl:order-1",
            tooltip: motorCarrier.isHazmat ? "Count does not include Hazmat rated equipment" : null,
        },
        {
            src: TrailerIcon,
            alt: "Trailer Icon",
            data: motorCarrier.details.TotalTrailers,
            label: pluralize(motorCarrier.details.TrailersOwned, "Trailer", "s"),
            className: "sm:order-3 md:order-5 xl:order-3",
            tooltip: motorCarrier.isHazmat ? "Count does not include Hazmat rated equipment" : null,
        },
        {
            src: DriverIcon,
            alt: "Driver Icon",
            data: motorCarrier.details.TotalDrivers,
            label: pluralize(motorCarrier.details.TotalDrivers, "Driver", "s"),
            className: "sm:order-4 md:order-7 xl:order-5",
        },

        {
            src: CrashIcon,
            alt: "Crash Icon",
            data: motorCarrier.crashes.filterByIncludedInSms().total,
            label: pluralize(motorCarrier.crashes.total, "Crash", "es"),
            className: "sm:order-6 md:order-4 xl:order-6",
        },
    ];

    return (
        <div className={className}>
            <TitleGroup title={<Typography variant="h5">Reported by FMCSA</Typography>} />
            <ul className="py-4">
                {figures.map((figure, index) => (
                    <Block key={index} {...figure} />
                ))}
            </ul>
        </div>
    );
}

interface IBlockOption {
    data: number | string;
    label: string;
    avatar?: string;
    src?: string;
    alt?: string;
    delay?: number;
    className?: string;
    secondary?: boolean;
    tooltip?: string | null;
}

function Block({ src, alt, tooltip, avatar, data, label, delay, className, secondary }: IBlockOption): JSX.Element {
    return (
        <li className={classNames("grid grid-cols-[25px_3ch_max-content] items-center gap-4", className)}>
            {!secondary && <Image src={src} alt={alt} avatar={avatar} />}
            <Tooltip title={tooltip || ""}>
                <>
                    <Transition.Fade delay={delay} className="justify-self-end">
                        <Typography className="ia-transition-element">{data}</Typography>
                    </Transition.Fade>
                    <Typography
                        className={classNames("text-md text-gray-400", {
                            "text-sm": secondary,
                        })}
                    >
                        {label}
                    </Typography>
                </>
            </Tooltip>
        </li>
    );
}

function Image({ src, alt, avatar }: Pick<IBlockOption, "src" | "alt" | "avatar">): JSX.Element {
    if (src) return <img src={src} alt={alt} className="h-[25px] w-[25px]" />;
    if (avatar) {
        return (
            <div className="flex h-[25px] w-[25px] items-center justify-center rounded-full bg-gray-100 text-center text-sm text-gray-500">
                {avatar}
            </div>
        );
    }
    return <div className="h-[25px] w-[25px] rounded-full bg-gray-100" />;
}
