import { MotorCarrier } from "@deathstar/motor-carrier";
import { fuzzyFilter } from "@deathstar/ui";
import {
    ColumnFiltersState,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { useLocalStorage } from "web-api-hooks";
import Columns, { columns } from "./Columns";
import { hideableColumns } from "./hideableColumns";

function useColumnVisibility(): [Record<string, boolean>, React.Dispatch<React.SetStateAction<Record<string, boolean>>>] {
    const [columnVisibility, setColumnVisibility] = useLocalStorage<Record<string, boolean>>("ia-crash-table-column-visibility-2", {
        [Columns.date.id as string]: true,
        [Columns.reportNumber.id as string]: false,
        [Columns.reportTime.id as string]: false,
        [Columns.state.id as string]: true,
        [Columns.city.id as string]: false,
        [Columns.county.id as string]: false,
        [Columns.location.id as string]: false,
        [Columns.latitude.id as string]: false,
        [Columns.longitude.id as string]: false,
        [Columns.unitVin.id as string]: true,
        [Columns.unitYear.id as string]: false,
        [Columns.unitMake.id as string]: false,
        [Columns.unitLicenseNumber.id as string]: false,
        [Columns.unitLicenseState.id as string]: false,
        [Columns.unitType.id as string]: false,
        [Columns.unitWeightRating.id as string]: false,
        [Columns.unitHazmatPlacardIndicator.id as string]: false,
        [Columns.cargoBodyType.id as string]: false,
        [Columns.totalFatalities.id as string]: false,
        [Columns.totalInjuries.id as string]: false,
        [Columns.towIndicator.id as string]: false,
        [Columns.totalVehiclesInAccident.id as string]: true,
        [Columns.hazmatReleaseIndicator.id as string]: false,
        [Columns.federalRecordableIndicator.id as string]: false,
        [Columns.stateRecordableIndicator.id as string]: false,
        [Columns.preventable.id as string]: false,
        [Columns.roadAccessControl.id as string]: false,
        [Columns.trafficway.id as string]: false,
        [Columns.lightCondition.id as string]: true,
        [Columns.roadSurfaceCondition.id as string]: false,
        [Columns.weatherCondition.id as string]: true,
        [Columns.reportingAgency.id as string]: false,
        [Columns.severityWeight.id as string]: false,
        [Columns.timeWeight.id as string]: false,
        [Columns.totalWeight.id as string]: true,
    });

    React.useEffect(() => {
        setColumnVisibility((old) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newState: Record<string, any> = {};
            for (const key in old) {
                if (hideableColumns.some((c) => c.ids.includes(key))) {
                    newState[key] = old[key];
                }
            }
            return newState;
        });
    }, [setColumnVisibility]);

    return [columnVisibility, setColumnVisibility];
}

export function useTable(motorCarrier: MotorCarrier, filter?: string) {
    const data = React.useMemo(() => motorCarrier.crashes.scored.sortByDate("DESC").array(), [motorCarrier]);

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = useColumnVisibility();

    const table = useReactTable<MotorCarrier.ICrash>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        autoResetPageIndex: false,
        globalFilterFn: fuzzyFilter,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,
        state: {
            columnVisibility,
            columnFilters,
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
    });

    React.useEffect(() => {
        // table.setGlobalFilter(crash?.get("CountyLatitude"));
        table.setColumnFilters((filtersState) => {
            const latitudeFilter = {
                id: Columns.latitude.id!,
                value: filter,
            };
            if (!filtersState.length) {
                return [latitudeFilter];
            }
            return filtersState.map((filter) => {
                if (filter.id === latitudeFilter.id) {
                    return latitudeFilter;
                }
                return filter;
            });
        });
    }, [table, filter, data]);

    return {
        data,
        table,
    };
}
