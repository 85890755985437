import { plainToClass } from "class-transformer";
import { NumberFormatter } from "../util/NumberFormatter/NumberFormatter";
import { Comparison } from "./Comparison/Comparison";
import { Coverage } from "./Coverage";
import type { CoverageLimit } from "./CoverageLimit";

export class CoverageUnderlyingCoverage {
    static duplicate(underlyingCoverage: CoverageUnderlyingCoverage): CoverageUnderlyingCoverage {
        const uc = plainToClass(CoverageUnderlyingCoverage, underlyingCoverage);

        uc.id = null;
        uc.createdDate = null;
        uc.coverageId = null;
        uc.coverageCreatedDate = null;
        uc.coverage = null;
        uc.coverageLimitId = null;
        uc.coverageLimitCreatedDate = null;
        uc.coverageLimit = null;

        return uc;
    }

    static compare({
        base,
        compare,
    }: {
        base?: CoverageUnderlyingCoverage;
        compare?: CoverageUnderlyingCoverage;
    }): Comparison<CoverageUnderlyingCoverage> {
        const comparison = new Comparison(CoverageUnderlyingCoverage);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add Underlying Coverage: ${compare.toString()} `,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete Underlying Coverage: ${base.toString()}`,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        [
            {
                key: "description",
                label: "Description",
                transform: (v) => v,
            },
            {
                key: "policyNumber",
                label: "Policy Number",
                transform: (v) => v,
            },
            {
                key: "tier",
                label: "Tier",
                transform: (v) => v,
            },
            {
                key: "limit",
                label: "Limit",
                transform: (v) => NumberFormatter.Currency.format(v),
            },
        ].forEach(({ key, label, transform }) => {
            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Change ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    priority: null,
                    fieldName: key as keyof CoverageUnderlyingCoverage,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        return comparison;
    }

    id: number;
    createdDate: Date;

    coverageId?: number;
    coverageCreatedDate?: Date;
    coverage?: Coverage;

    coverageLimitId?: number;
    coverageLimitCreatedDate?: Date;
    coverageLimit?: CoverageLimit;

    sortOrder: number;
    description: CoverageUnderlyingCoverage.Description;
    policyNumber?: string;
    tier?: number;
    limit?: number;
    metadata?: Record<string, unknown>;

    toString(): string {
        const parts: string[] = [this.description];
        if (this.policyNumber) parts.push(this.policyNumber);
        if (this.tier) parts.push(`Tier ${this.tier}`);
        if (typeof this.limit === "number") parts.push(NumberFormatter.Currency.format(this.limit));
        return parts.join(", ");
    }

    compare(
        comparisonUnderlyingCoverage: CoverageUnderlyingCoverage,
        options?: Comparison.getBaseAndComparisonObjects.IOptions,
        baseCoverage?: Coverage,
        compareCoverage?: Coverage
    ): Comparison<CoverageUnderlyingCoverage> {
        if (!this.coverage) {
            this.coverage = Coverage.new({
                ...baseCoverage,
                underlyingCoverages: undefined,
            });
        }

        if (comparisonUnderlyingCoverage && !comparisonUnderlyingCoverage.coverage) {
            comparisonUnderlyingCoverage.coverage = Coverage.new({
                ...compareCoverage,
                underlyingCoverages: undefined,
            });
        }

        return CoverageUnderlyingCoverage.compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonUnderlyingCoverage,
                },
                options
            )
        );
    }
}

export namespace CoverageUnderlyingCoverage {
    export enum Description {
        AUTO_LIABILITY = "Auto Liability",
        BUSINESS_AUTO_LIABILITY = "Business Auto Liability",
        CONTINGENT_AUTO_LIABILITY = "Contingent Auto Liability",
        CONTINGENT_CARGO = "Contingent Cargo",
        EMPLOYERS_LIABILITY = "Employers Liability",
        EXCESS = "Excess",
        GENERAL_LIABILITY = "General Liability",
        MOTOR_TRUCK_CARGO = "Motor Truck Cargo",
    }
}
