import { DataTable } from "@deathstar/ui";
import Columns from "./Columns";

export const hideableColumns: DataTable.HideableColumn[] = [
    {
        label: "Date",
        ids: [Columns.date.id!],
    },
    {
        label: "Report Number",
        ids: [Columns.reportNumber.id!],
    },
    {
        label: "Report Time",
        ids: [Columns.reportTime.id!],
    },
    {
        label: "State",
        ids: [Columns.state.id!],
    },
    {
        label: "City",
        ids: [Columns.city.id!],
    },
    {
        label: "County",
        ids: [Columns.county.id!],
    },
    {
        label: "Location",
        ids: [Columns.location.id!],
    },
    {
        label: "Latitude",
        ids: [Columns.latitude.id!],
    },
    {
        label: "Longitude",
        ids: [Columns.longitude.id!],
    },
    {
        label: "Unit: VIN",
        ids: [Columns.unitVin.id!],
    },
    {
        label: "Unit: Year",
        ids: [Columns.unitYear.id!],
    },
    {
        label: "Unit: Make",
        ids: [Columns.unitMake.id!],
    },
    {
        label: "Unit: License",
        ids: [Columns.unitLicenseNumber.id!],
    },
    {
        label: "Unit: State",
        ids: [Columns.unitLicenseState.id!],
    },
    {
        label: "Unit: Type",
        ids: [Columns.unitType.id!],
    },
    {
        label: "Unit: Weight Rating",
        ids: [Columns.unitWeightRating.id!],
    },
    {
        label: "Unit: Hazmat Placard",
        ids: [Columns.unitHazmatPlacardIndicator.id!],
    },
    {
        label: "Unit: Cargo Body TYpe",
        ids: [Columns.cargoBodyType.id!],
    },
    {
        label: "Fatalaties",
        ids: [Columns.totalFatalities.id!],
    },
    {
        label: "Injuries",
        ids: [Columns.totalInjuries.id!],
    },
    {
        label: "Tow",
        ids: [Columns.towIndicator.id!],
    },
    {
        label: "Vehicles Involved",
        ids: [Columns.totalVehiclesInAccident.id!],
    },

    {
        label: "Hazmat Released",
        ids: [Columns.hazmatReleaseIndicator.id!],
    },
    {
        label: "Federal Recordable",
        ids: [Columns.federalRecordableIndicator.id!],
    },
    {
        label: "State Recordable",
        ids: [Columns.stateRecordableIndicator.id!],
    },
    {
        label: "Preventable",
        ids: [Columns.preventable.id!],
    },
    {
        label: "Road Access Control",
        ids: [Columns.roadAccessControl.id!],
    },
    {
        label: "Trafficway",
        ids: [Columns.trafficway.id!],
    },
    {
        label: "Conditions: Light",
        ids: [Columns.lightCondition.id!],
    },
    {
        label: "Conditions: Road Surface",
        ids: [Columns.roadSurfaceCondition.id!],
    },
    {
        label: "Conditions: Weather",
        ids: [Columns.weatherCondition.id!],
    },
    {
        label: "Reporting Agency",
        ids: [Columns.reportingAgency.id!],
    },

    {
        label: "Severity Weight",
        ids: [Columns.severityWeight.id!],
    },
    {
        label: "Time Weight",
        ids: [Columns.timeWeight.id!],
    },
    {
        label: "Total Weight",
        ids: [Columns.totalWeight.id!],
    },
];
