import {
    getSubscriptionTierName,
    ScheduledSubscription,
    Subscription,
    SubscriptionPlan,
    SubscriptionTier,
} from "@deathstar/types/waypoint";
import { classNames, PrimaryButton } from "@deathstar/ui";
import moment from "moment";

export default function PlanHeader({
    price,
    onManage,
    onCancelScheduled,
    activeSubscriptionTier,
    scheduledSubscription,
    subscription,
    classes,
}: {
    price: SubscriptionPlan;
    onManage(): void;
    onCancelScheduled(): void;
    activeSubscriptionTier: SubscriptionTier | null;
    scheduledSubscription?: ScheduledSubscription | null;
    subscription?: Subscription | null;
    classes: {
        card: string;
        button: string;
    };
}) {
    const matchedSubscription =
        subscription && subscription.tier === price.tier && subscription.interval === price.interval ? subscription : null;
    return (
        <div>
            <div className={classNames("flex w-screen max-w-xs flex-col gap-4 rounded-lg p-4 text-left", classes.card)}>
                <div className="flex items-start justify-between">
                    <span className="text-lg capitalize">{getSubscriptionTierName(price.tier)}</span>
                    {matchedSubscription?.cancelDate ? (
                        <span className="text-xs font-light">Active until {moment(matchedSubscription.cancelDate).format("MM/DD/YY")}</span>
                    ) : matchedSubscription?.periodEnd ? (
                        <span className="text-xs font-light">Renews on {moment(matchedSubscription.periodEnd).format("MM/DD/YY")}</span>
                    ) : scheduledSubscription &&
                      scheduledSubscription.tier === price.tier &&
                      scheduledSubscription.interval === price.interval ? (
                        <div className="text-xs font-light">
                            <p className="">Begins on {moment(scheduledSubscription.startDate).format("MM/DD/YY")}</p>
                            <button
                                className="w-full text-right hover:opacity-80"
                                onClick={() => {
                                    onCancelScheduled();
                                }}
                            >
                                (cancel)
                            </button>
                        </div>
                    ) : null}
                </div>
                <span className="self-end text-4xl font-bold">
                    <span>
                        {price.price.toLocaleString("en-us", {
                            style: "currency",
                            currency: "usd",
                            maximumFractionDigits: 0,
                        })}{" "}
                    </span>
                    <span className="text-lg opacity-90">/ {price.interval}</span>
                </span>

                {matchedSubscription ? (
                    <PrimaryButton
                        onClick={() => {
                            onManage();
                        }}
                        className={classes.button}
                    >
                        Manage
                    </PrimaryButton>
                ) : !activeSubscriptionTier ? (
                    <PrimaryButton
                        onClick={() => {
                            onManage();
                        }}
                        className={classes.button}
                    >
                        Get started
                    </PrimaryButton>
                ) : activeSubscriptionTier === price.tier ? (
                    <PrimaryButton
                        onClick={() => {
                            onManage();
                        }}
                        className={classes.button}
                    >
                        Manage
                    </PrimaryButton>
                ) : null}
            </div>
        </div>
    );
}
