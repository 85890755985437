import { MotorCarrier } from "@deathstar/motor-carrier";
import { AccessorColumnDef, ColumnDef } from "@tanstack/react-table";

const vin: AccessorColumnDef<MotorCarrier.PrimaryScoreContributor> = {
    id: "vin",
    accessorFn: (row) => row.VIN,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "text",
    // cell: PrimaryCell,
};

const year: AccessorColumnDef<MotorCarrier.PrimaryScoreContributor> = {
    id: "year",
    accessorFn: (row) => row.Year,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const make: AccessorColumnDef<MotorCarrier.PrimaryScoreContributor> = {
    id: "make",
    accessorFn: (row) => row.Make,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "text",
};

const license: AccessorColumnDef<MotorCarrier.PrimaryScoreContributor> = {
    id: "license",
    accessorFn: (row) => row.License,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "text",
};

const percentOfTotalScore: AccessorColumnDef<MotorCarrier.PrimaryScoreContributor> = {
    id: "percentOfTotalScore",
    header: "% of Total Score",
    accessorFn: (row) => row.PercentOfTotalScoreFormatted,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: "alphanumeric",
};

const totalViolations: AccessorColumnDef<MotorCarrier.PrimaryScoreContributor> = {
    id: "totalViolations",
    header: "Total Violations",
    accessorFn: (row) => `x${row.TotalViolations}`,
    meta: { classes: { cell: "tabular-nums" } },
    sortingFn: (a, b) => a.original.TotalViolations - b.original.TotalViolations,
};

export const columns = [
    vin,
    year,
    make,
    license,
    percentOfTotalScore,
    totalViolations,
] as ColumnDef<MotorCarrier.PrimaryScoreContributor>[];

export default { vin, year, make, license, percentOfTotalScore, totalViolations };
