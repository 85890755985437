import { ValidatorConstraint, ValidatorConstraintInterface } from "class-validator";
import { Policy } from "../Policy";

@ValidatorConstraint({
    name: "TransactionTypeValidator",
    async: false,
})
export class TransactionTypeValidator implements ValidatorConstraintInterface {
    validate(value: string): boolean {
        return (Object.values(Policy.TransactionType) as string[]).includes(value);
    }

    defaultMessage(): string {
        return "$property is invalid";
    }
}
