import { useQuery } from "@tanstack/react-query";
import { geoMercator, GeoProjection } from "d3-geo";
import { throttle } from "lodash";
import { MouseEvent, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { type USMapLocation } from "./types";
import { coordinatesToScreenPosition, screenPositionToCoordinates } from "./utils";

interface IDragState {
    dragging: boolean;
    startX: number | null;
    startY: number | null;
    x: number;
    y: number;
}

const DEFAULT_WIDTH = 1025;
const DEFAULT_HEIGHT = 600;

export interface MapControllerOptions {
    locations?: USMapLocation[];
    scale?: number;
    dimensions?: { width: number; height: number };
    offset?: { x: number; y: number };
}

export interface MapController {
    localScale: number;
    dragState: IDragState;
    onPrint: () => () => void;
    ref: React.RefObject<SVGSVGElement>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapData: any;
    onMouseMove: (e: MouseEvent) => void;
    onMouseDown: MouseEventHandler<SVGSVGElement>;
    projection: GeoProjection;
    height: number;
    width: number;
    locations: USMapLocation[];
}

export function useMap({
    locations = [],
    scale,
    dimensions = { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT },
    offset = { x: 0, y: 0 },
}: MapControllerOptions): MapController {
    const ref = useRef<SVGSVGElement>(null);

    const [localScale, setLocalScale] = useState(1);
    const [dragState, setDragState] = useState<IDragState>({
        dragging: false,
        startX: null,
        startY: null,
        x: 0,
        y: 0,
    });

    const onPrint = () => {
        const currentScale = localScale;
        const currentDragState = { ...dragState };
        setLocalScale(0.76);
        setDragState((old) => ({ ...old, x: -125, y: -100 }));

        return () => {
            setLocalScale(currentScale);
            setDragState(currentDragState);
        };
    };

    const { data: mapData } = useQuery({
        queryKey: ["us-atlas@3/states-10m.json"],

        queryFn: () =>
            fetch("https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json").then((r) => {
                if (r.status === 200) {
                    return r.json();
                } else {
                    throw new Error("Failed to load D3 topography data");
                }
            }),
        gcTime: Infinity,
        staleTime: Infinity,
    });

    const finalScale = (scale || 1) * localScale;

    const dragX = dragState.x;
    const dragY = dragState.y;
    const { width, height } = { width: dimensions.width, height: dimensions.height };

    const projection = useMemo(() => {
        let projection = geoMercator();

        const focus = locations?.find((l) => l.focus);
        const center: [number, number] = focus ? [focus.lng, focus.lat] : [-95, 39.828403555140945];

        projection = projection.scale(975 * finalScale);
        const centerScreenPosition = coordinatesToScreenPosition({ lng: center[0], lat: center[1] }, projection);

        const { lat, lng } = screenPositionToCoordinates([centerScreenPosition[0] - dragX, centerScreenPosition[1] - dragY], projection);

        projection = projection.center([lng, lat]);
        projection = projection.translate([width / 2 + offset.x, height / 2 + offset.y]);
        return projection;
    }, [locations, dragX, dragY, finalScale, width, offset.x, offset.y, height]);

    const onMouseMove = useMemo(
        () =>
            throttle((e) => {
                setDragState((old) => {
                    if (old.dragging) {
                        return {
                            ...old,
                            startX: e.clientX,
                            startY: e.clientY,
                            x: old.startX !== null ? old.x - (old.startX - e.clientX) : old.x,
                            y: old.startY !== null ? old.y - (old.startY - e.clientY) : old.y,
                        };
                    }
                    return old;
                });
            }, 10),
        []
    );

    const onMouseDown = useCallback((e: MouseEvent<SVGSVGElement, MouseEvent>) => {
        setDragState((old) => ({ ...old, dragging: true, startX: e.clientX, startY: e.clientY }));
    }, []) as unknown as MouseEventHandler<SVGSVGElement>;

    useEffect(() => {
        const mouseup = () => {
            setDragState((old) => ({
                ...old,
                dragging: false,
                startX: null,
                startY: null,
            }));
        };

        window.addEventListener("mouseup", mouseup);
        window.addEventListener("mousemove", onMouseMove);
        return () => {
            window.removeEventListener("mouseup", mouseup);
            window.removeEventListener("mousemove", onMouseMove);
        };
    }, [onMouseMove]);

    useEffect(() => {
        function onWheel(e: WheelEvent) {
            e.preventDefault();
            e.stopPropagation();
            setLocalScale((old) => {
                return Math.min(10, Math.max(0.33, old - e.deltaY / 1000));
            });
        }
        if (ref.current) {
            const el = ref.current;
            el.addEventListener("wheel", onWheel);

            return () => {
                el.removeEventListener("wheel", onWheel);
            };
        }
        return undefined;
    }, []);

    return {
        localScale,
        dragState,
        ref,
        onPrint,
        mapData,
        onMouseMove,
        onMouseDown,
        projection,
        height,
        width,
        locations,
    };
}
