// import { plainToClass } from "class-transformer";
import type { Coverage } from "./Coverage";

export class CoverageCompositeRating {
    static createTemplateFrom(compositeRating: CoverageCompositeRating): CoverageCompositeRating {
        const rating = new CoverageCompositeRating();
        rating.sortOrder = compositeRating.sortOrder;
        rating.description = compositeRating.description;
        return rating;
    }

    id: number;
    createdDate: Date;

    coverageId: number;
    coverageCreatedDate: Date;
    coverage?: Coverage;

    sortOrder: number;
    description: string;
    basis?: Coverage.Basis;
    frequency?: Coverage.Frequency;
    rate?: number;
    premium?: number;
}
