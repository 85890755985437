export function classNames(...classNames: (string | boolean | Record<string, unknown> | undefined | null)[]): string {
    const value = classNames.filter(Boolean).reduce((str, currentValue) => {
        if (currentValue && typeof currentValue === "object") {
            const objClasses = Object.entries(currentValue)
                .filter(([_, val]) => val)
                .map(([key]) => key)
                .join(" ");
            return `${str} ${objClasses}`;
        }
        if (typeof currentValue === "boolean") return str;
        return `${str} ${currentValue}`;
    }, " ");
    return (value as string).trim();
}
