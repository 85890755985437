import { Transform, Type } from "class-transformer";
import {
    ArrayNotEmpty,
    IsArray,
    IsBoolean,
    IsDateString,
    IsEmpty,
    IsEnum,
    IsISO8601,
    IsNumber,
    IsOptional,
    IsString,
    Length,
    ValidateBy,
    ValidateNested,
} from "class-validator";
import type { Account } from "./Account";
import { Subtask } from "./Subtask";
import type { User } from "./User";
import { WidgetAttachment } from "./WidgetAttachment";
import type { WidgetComment } from "./WidgetComment";
import type { WidgetEvent } from "./WidgetEvent";
import type { WidgetPin } from "./WidgetPin";
import type { WidgetView } from "./WidgetView";

enum TaskType {
    ToDo = "todo",
    PolicyCheckIn = "policyCheckin",
    StartRenewal = "startRenewal",
}

enum SearchType {
    Account = "account",
    User = "user",
}

enum Priority {
    Low = 0,
    Normal = 1,
    High = 2,
    Critical = 3,
}

class UserDto {
    @IsString()
    guid: string;
}

export class Widget {
    static Type = TaskType;
    static SearchType = SearchType;
    static Priority = Priority;

    @IsEmpty({ groups: ["create"] })
    id: number;

    @IsString()
    @Length(32, 32)
    accountId: string | null;

    @IsString()
    subject: string;

    @IsDateString()
    @IsOptional()
    dateCreated?: Date;

    @IsISO8601()
    dueDateString: string;

    @IsNumber()
    priority: number | null;

    @IsEmpty()
    dateClosed?: Date | null;

    @IsEmpty()
    closedByGuid: string | null;

    @IsString()
    @IsOptional()
    type: TaskType | null;

    @IsEmpty()
    createdByGuid: string;

    @IsEmpty()
    createdBy: User;

    @IsArray()
    @ArrayNotEmpty()
    @ValidateNested({ each: true })
    @Type(() => UserDto)
    users: User[];

    @IsArray()
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => WidgetAttachment)
    attachments: WidgetAttachment[];

    closedBy: User;

    views: WidgetView[];

    pins: WidgetPin[];

    followers: User[];

    comments: WidgetComment[];

    subtasks: Subtask[];

    events: WidgetEvent[];

    account: Account;

    isClosed: boolean;

    policyId?: string;
    policyCreatedDate?: Date;
}

export class TaskFindOptions {
    @IsEnum(SearchType)
    searchType: string;

    @ValidateBy({
        name: "is-array-or-string",
        validator: {
            validate: (value) => (Array.isArray(value) && value.every((v) => typeof v === "string")) || typeof value === "string",
            defaultMessage: (args) => `${args.property} must be a string or an array of strings`,
        },
    })
    searchValue: string | string[];

    @IsOptional()
    @IsString()
    dueBefore?: string;

    @IsOptional()
    @IsString()
    dueAfter?: string;

    @IsOptional()
    @IsBoolean()
    @Transform(({ value }) => value === "true" || value === true)
    isClosed?: boolean;

    @IsOptional()
    @IsString()
    @IsEnum(TaskType)
    type?: TaskType;
}
