export enum UnitType {
  BUS = "BU - Bus",
  DOLLY_CONVERTER = "DC - Dolly Converter",
  FULL_TRAILER = "FT - Full Trailer",
  LIMO = "LM - Limousine",
  MOTOR_COACH = "MC - Motor Coach",
  OTHER = "OT - Other",
  POLE_TRAILER = "PT - Pole Trailer",
  SCHOOL_BUS = "SB - School Bus",
  SEMI_TRAILER = "ST - Semi Trailer",
  STRAIGHT_TRUCK = "TR - Straight Truck",
  TRUCK_TRACTOR = "TT -Truck Tractor",
  VAN = "VN - Van",
  UNKNOWN = "ZZ - Unknown",
  INTERMODAL_CHASSIS = "Intermodal Chassis",
  CRIB_LOG_TRAILER = "Crib Log Trailer",
}
