import { classNames } from "@deathstar/ui";
import tw, { styled } from "twin.macro";

const Header = styled.td(() => [tw`uppercase font-light pb-2 mb-2 text-xs`]);

export function PrintableTable<ColHeader extends string>({
    columnHeaders,
    data,
    className,
}: {
    columnHeaders: ColHeader[];
    data: Array<Record<ColHeader, string | number | JSX.Element> & { key: string | number; className?: string }>;
    className?: string;
}): JSX.Element {
    return (
        <table className={classNames(className, "w-full text-sm")}>
            <thead>
                <tr className="">
                    {columnHeaders.map((header) => (
                        <Header key={header}>{header.startsWith("_") ? "" : header}</Header>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row) => {
                    return (
                        <tr key={row.key} className="border-t border-gray-200">
                            {columnHeaders.map((header) => (
                                <td key={header} className={classNames("overflow-hidden truncate text-ellipsis p-2 pl-0", row.className)}>
                                    {row[header]}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
