export enum IssGroup {
    OUT_OF_SERVICE = "Out of Service",
    HIGH_RISK = "High Risk",
    THREE_PLUS_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED = "3+ BASICs Best Addressed Roadside Prioritized For Intervention",
    TWO_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED = "2 BASICs Best Addressed Roadside Prioritized For Intervention",
    MULTIPLE_PRIORITIZED_WITH_ONE_BASIC_BEST_ADDRESSED_ROADSIDE = "Multiple BASICs Prioritized For Intervention Including One Best Addressed Roadside",
    HOS_SCORE_OVER_THRESHOLD = "Hours of Service Score Over Threshold",
    HAS_ACUTE_CRITICAL_VIOLATION = "Has Acute/Critical Violation(s)",
    VM_ALERT = "Vehicle Maintenance Alert",
    HM_ALERT = "Hazmat Alert",
    DF_ALERT = "Driver Fitness Alert",
    CS_ALERT = "Controlled Substances Alert",
    UD_AND_CR_ALERT = "Unsafe Driving and Crash Indicator Alert",
    CR_ALERT = "Crash Indicator Alert",
    UD_ALERT = "Unsafe Driving Alert",
    SUFFICIENT_DATA_NO_ALERTS = "Sufficient Data, No Alerts",
    INSUFFICIENT_DATA = "Insufficient Data",
}

export namespace IssGroup {
    export const IssGroupLabels: Map<IssGroup, string> = new Map([
        [IssGroup.OUT_OF_SERVICE, "Carrier is out of service"],
        [IssGroup.HIGH_RISK, "Carrier is high risk"],
        [IssGroup.THREE_PLUS_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED, "Carrier has 3+ BASICs best addressed roadside in alert"],
        [IssGroup.TWO_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED, "Carrier has 2 BASICs best addressed roadside in alert"],
        [
            IssGroup.MULTIPLE_PRIORITIZED_WITH_ONE_BASIC_BEST_ADDRESSED_ROADSIDE,
            "Carrier has multiple BASICs in alert including one BASIC which is best addressed roadside",
        ],
        [IssGroup.HOS_SCORE_OVER_THRESHOLD, "Hours of Service score is over threshold"],
        [IssGroup.HAS_ACUTE_CRITICAL_VIOLATION, "Carrier has acute/critical violation(s)"],
        [IssGroup.VM_ALERT, "Vehicle Maintenance Alert"],
        [IssGroup.HM_ALERT, "Hazmat Alert"],
        [IssGroup.DF_ALERT, "Driver Fitness Alert"],
        [IssGroup.CS_ALERT, "Controlled Substances Alert"],
        [IssGroup.UD_AND_CR_ALERT, "Unsafe Driving and Crash Indicator Alert"],
        [IssGroup.CR_ALERT, "Crash Indicator Alert"],
        [IssGroup.UD_ALERT, "Unsafe Driving Alert"],
        [IssGroup.SUFFICIENT_DATA_NO_ALERTS, "Passed all ISS checks"],
        [IssGroup.INSUFFICIENT_DATA, "Insufficient data"],
    ]);

    export function getLabel(group: IssGroup | null): string {
        if (!group) return "None";
        return IssGroupLabels.get(group);
    }

    export enum Id {
        OUT_OF_SERVICE = 0,
        HIGH_RISK = 1,
        THREE_PLUS_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED = 2,
        TWO_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED = 3,
        MULTIPLE_PRIORITIZED_WITH_ONE_BASIC_BEST_ADDRESSED_ROADSIDE = 4,
        HOS_SCORE_OVER_THRESHOLD = 5,
        HAS_ACUTE_CRITICAL_VIOLATION = 6,
        VM_ALERT = 7,
        HM_ALERT = 8,
        DF_ALERT = 9,
        CS_ALERT = 10,
        UD_AND_CR_ALERT = 11,
        CR_ALERT = 12,
        UD_ALERT = 13,
        SUFFICIENT_DATA_NO_ALERTS = 14,
        INSUFFICIENT_DATA = 15,
    }

    export const IssGroupNumber: Map<IssGroup, number> = new Map([
        [IssGroup.OUT_OF_SERVICE, Id.OUT_OF_SERVICE],
        [IssGroup.HIGH_RISK, Id.HIGH_RISK],
        [IssGroup.THREE_PLUS_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED, Id.THREE_PLUS_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED],
        [IssGroup.TWO_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED, Id.TWO_BASICS_BEST_ADDRESSED_ROADSIDE_PRIORITIZED],
        [
            IssGroup.MULTIPLE_PRIORITIZED_WITH_ONE_BASIC_BEST_ADDRESSED_ROADSIDE,
            Id.MULTIPLE_PRIORITIZED_WITH_ONE_BASIC_BEST_ADDRESSED_ROADSIDE,
        ],
        [IssGroup.HOS_SCORE_OVER_THRESHOLD, Id.HOS_SCORE_OVER_THRESHOLD],
        [IssGroup.HAS_ACUTE_CRITICAL_VIOLATION, Id.HAS_ACUTE_CRITICAL_VIOLATION],
        [IssGroup.VM_ALERT, Id.VM_ALERT],
        [IssGroup.HM_ALERT, Id.HM_ALERT],
        [IssGroup.DF_ALERT, Id.DF_ALERT],
        [IssGroup.CS_ALERT, Id.CS_ALERT],
        [IssGroup.UD_AND_CR_ALERT, Id.UD_AND_CR_ALERT],
        [IssGroup.CR_ALERT, Id.CR_ALERT],
        [IssGroup.UD_ALERT, Id.UD_ALERT],
        [IssGroup.SUFFICIENT_DATA_NO_ALERTS, Id.SUFFICIENT_DATA_NO_ALERTS],
        [IssGroup.INSUFFICIENT_DATA, Id.INSUFFICIENT_DATA],
    ]);

    export function getNumber(group: IssGroup | null): number {
        if (!group) return NaN;
        return IssGroupNumber.get(group);
    }
}
