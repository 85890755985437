export class Commission {
    id: string;
    transactionType?: Commission.TransactionType;
    effectiveDate: Date;
    rate: number | null;
}

export namespace Commission {
    export enum TransactionType {
        RENEWAL = "Renewal",
        REWRITE = "Rewrite",
        NEW_BUSINESS = "New Business",
    }
}
