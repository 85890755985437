import {
    BillingInformation,
    BillingInformationUpdateDto,
    PayBillFormData,
    Payment,
    PaymentInterval,
    ScheduledSubscription,
    Subscription,
    SubscriptionPlans,
    SubscriptionTier,
} from "@deathstar/types/waypoint";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const billing = {
    createBillingSession: (accountId: string) => {
        return blazar.fetchJson<{ sessionUrl: string }>(`waypoint/orgs/${accountId}/billing/session`, {
            method: "POST",
        });
    },

    getProfile: (accountId: string) => blazar.fetchJson<BillingInformation>(`waypoint/orgs/${accountId}/billing`),

    getPlans: (accountId: string) => blazar.fetchJson<SubscriptionPlans>(`waypoint/orgs/${accountId}/subscription-plans`),

    getSubscription: async (accountId: string) => {
        return (await blazar.fetchJson<Subscription>(`waypoint/orgs/${accountId}/subscription`)) || null;
    },

    getProrationAmount: async (accountId: string, tier: SubscriptionTier, interval: PaymentInterval) => {
        return blazar.fetchJson<{ amount: number }>(`waypoint/orgs/${accountId}/subscription/proration?tier=${tier}&interval=${interval}`);
    },

    getPaymentHistory: (accountId: string) => blazar.fetchJson<Payment[]>(`waypoint/orgs/${accountId}/billing/history`),

    getScheduledSubscription: async (accountId: string) => {
        const scheduledSubscription = await blazar.fetchJson<ScheduledSubscription | null>(
            `waypoint/orgs/${accountId}/subscription/scheduled`
        );

        return scheduledSubscription || null;
    },

    cancelScheduledSubscription: (accountId: string, scheduleId: string) =>
        blazar.fetch(`waypoint/orgs/${accountId}/subscription/scheduled/${scheduleId}`, { method: "DELETE" }),

    /** If the customer does not have a default payment method a sessionUrl will be returned to redirect the user to */
    subscribe: (accountId: string, tier: SubscriptionTier, interval: PaymentInterval) =>
        blazar.fetchJson<{ sessionUrl: string | null }>(`waypoint/orgs/${accountId}/subscription`, {
            method: "POST",
            body: JSON.stringify({ tier, interval }),
        }),

    unsubscribe: (accountId: string) =>
        blazar.fetchJson(`waypoint/orgs/${accountId}/subscription`, {
            method: "DELETE",
        }),

    updateProfile: (accountId: string, changes: BillingInformationUpdateDto) => {
        return blazar.fetchJson<void>(`waypoint/orgs/${accountId}/billing`, {
            method: "PATCH",
            body: JSON.stringify(changes),
        });
    },

    useUpdateProfile: (accountId: string, config?: UseMutationOptions<void, ResponseError, BillingInformationUpdateDto>) => {
        return useMutation<void, ResponseError, BillingInformationUpdateDto>({
            mutationFn: (changes: BillingInformationUpdateDto) => billing.updateProfile(accountId, changes),
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: billing.queryKeys.profile(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },

    applyPromoCode: (accountId: string, code: string) => {
        return blazar.fetchJson(`waypoint/orgs/${accountId}/billing/promotion`, {
            method: "POST",
            body: JSON.stringify({ code }),
        });
    },
    createSetupIntent: (accountId: string) => {
        return blazar.fetchJson<{ clientSecret: string }>(`waypoint/orgs/${accountId}/billing/setup`, {
            method: "POST",
        });
    },

    removePaymentMethod: (accountId: string, paymentMethodId: string) => {
        return blazar.fetchJson<void>(`waypoint/orgs/${accountId}/billing/payment-methods/${paymentMethodId}`, {
            method: "DELETE",
        });
    },

    createAndPayInvoice: (accountId: string, body: PayBillFormData) => {
        return blazar.fetchJson<void>(`waypoint/orgs/${accountId}/billing/pay`, { method: "POST", body: JSON.stringify(body) });
    },

    createProfile: (accountId: string) => {
        return blazar.fetchJson(`waypoint/orgs/${accountId}/billing`, {
            method: "POST",
        });
    },

    queryKeys: {
        profile: (accountId: string) => ["billing", accountId, "profile"] as QueryKey,
        plans: (accountId: string) => ["billing", accountId, "plans"] as QueryKey,
        prorationAmount: (accountId: string, tier?: SubscriptionTier, interval?: PaymentInterval) =>
            ["billing", accountId, "prorationAmount", tier, interval].filter(Boolean) as QueryKey,
        subscription: (accountId: string) => ["billing", accountId, "subscription"] as QueryKey,
        paymentHistory: (accountId: string) => ["billing", accountId, "payment-history"] as QueryKey,
        scheduledSubscription: (accountId: string) => ["billing", accountId, "scheduled-subscription"] as QueryKey,
    },

    useProfile: (accountId: string, config?: Partial<UseQueryOptions<BillingInformation, ResponseError, BillingInformation>>) =>
        useQuery({
            queryKey: billing.queryKeys.profile(accountId),
            queryFn: () => billing.getProfile(accountId),
            staleTime: 60_000,
            refetchOnWindowFocus: ({ state }) => state.status !== "error",
            ...config,
        }),

    usePlans: (accountId: string, config?: Partial<UseQueryOptions<SubscriptionPlans, ResponseError, SubscriptionPlans>>) =>
        useQuery({
            queryKey: billing.queryKeys.plans(accountId),
            queryFn: () => billing.getPlans(accountId),
            staleTime: 60_000,
            refetchOnWindowFocus: ({ state }) => state.status !== "error",
            ...config,
        }),

    useProrationAmount: (
        accountId: string,
        tier: SubscriptionTier,
        interval: PaymentInterval,
        config?: Partial<UseQueryOptions<{ amount: number }, ResponseError, { amount: number }>>
    ) =>
        useQuery({
            queryKey: billing.queryKeys.prorationAmount(accountId, tier, interval),
            queryFn: () => billing.getProrationAmount(accountId, tier, interval),
            staleTime: 60_000,
            refetchOnWindowFocus: ({ state }) => state.status !== "error",
            ...config,
        }),

    useSubscription: (accountId: string) =>
        useQuery({
            queryKey: billing.queryKeys.subscription(accountId),
            queryFn: () => billing.getSubscription(accountId),
            staleTime: 15_000,
        }),

    usePaymentHistory: (accountId: string) =>
        useQuery({
            queryKey: billing.queryKeys.paymentHistory(accountId),
            queryFn: () => billing.getPaymentHistory(accountId),
            staleTime: 30_000,
        }),

    useScheduledSubscription: (accountId: string) =>
        useQuery({
            queryKey: billing.queryKeys.scheduledSubscription(accountId),
            queryFn: () => billing.getScheduledSubscription(accountId),
            staleTime: 30_000,
        }),

    useSetupIntent: (accountId: string, config?: UseMutationOptions<{ clientSecret: string }>) =>
        useMutation<{ clientSecret: string }>({ mutationFn: () => billing.createSetupIntent(accountId), ...config }),

    useCreateAndPayInvoice: (accountId: string, config?: UseMutationOptions<void, void, PayBillFormData>) =>
        useMutation<void, void, PayBillFormData>({
            mutationFn: (body: PayBillFormData) => billing.createAndPayInvoice(accountId, body),
            ...config,
        }),

    useSubscribe: (
        accountId: string,
        config?: UseMutationOptions<{ sessionUrl: string | null }, ResponseError, { tier: SubscriptionTier; interval: PaymentInterval }>
    ) =>
        useMutation<{ sessionUrl: string | null }, ResponseError, { tier: SubscriptionTier; interval: PaymentInterval }>({
            mutationFn: ({ tier, interval }) => billing.subscribe(accountId, tier, interval),
            ...config,
        }),

    useCreateProfile: (accountId: string, config?: UseMutationOptions) =>
        useMutation({ mutationFn: () => billing.createProfile(accountId), ...config }),
};
