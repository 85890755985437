export abstract class RawData<Raw> {
    // ========================================================================
    static set<K>(obj: RawData<K>, raw: K): RawData<K> {
        obj.#raw = raw;
        return obj;
    }

    // ========================================================================
    #raw;
    constructor(raw: Raw) {
        this.#raw = raw;
    }

    // ========================================================================
    get<K extends keyof Raw>(key: K): Raw[K] {
        return this.#raw[key];
    }

    // ========================================================================
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    json(): Raw & Record<string, any> {
        return this.#raw;
    }
}
