import { DateTime, memoize } from "@deathstar/reuse";
import { NationalAverageOutOfServiceRate as NationalAverageOutOfServiceRateDto } from "@deathstar/types/fmcsa";
import { MotorCarrier } from "../MotorCarrier";

export class NationalAverageOutOfServiceRate {
    #carrier: MotorCarrier;

    #recordDate: DateTime;
    #snapshotDate: DateTime;

    readonly dateRange: MotorCarrier.IDates;
    readonly vehicle: number;
    readonly driver: number;
    readonly hazmat: number;

    // ========================================================================
    constructor({ carrier, recordDate, snapshotDate, rate }: NationalAverageOutOfServiceRate.Options) {
        this.#carrier = carrier;
        this.#recordDate = recordDate;
        this.#snapshotDate = snapshotDate;

        this.dateRange = MotorCarrier.calculateDates({
            snapshotDate,
        });

        this.vehicle = rate.VehicleRate;
        this.driver = rate.DriverRate;
        this.hazmat = rate.HazmatRate;
    }

    // ========================================================================
    get recordDate(): DateTime {
        return this.#recordDate;
    }

    // ========================================================================
    get snapshotDate(): DateTime {
        return this.#snapshotDate;
    }

    // ========================================================================
    @memoize()
    convertToPercentage(): NationalAverageOutOfServiceRate.JSON {
        return {
            vehicle: Math.round(this.vehicle * 1000) / 10,
            driver: Math.round(this.driver * 1000) / 10,
            hazmat: Math.round(this.hazmat * 1000) / 10,
        };
    }

    // ========================================================================
    json(): NationalAverageOutOfServiceRate.JSON {
        return {
            vehicle: this.vehicle,
            driver: this.driver,
            hazmat: this.hazmat,
        };
    }

    // ========================================================================
    toString(): string {
        return JSON.stringify(this.json());
    }
}

export namespace NationalAverageOutOfServiceRate {
    export interface Options {
        carrier: MotorCarrier;
        recordDate: DateTime;
        snapshotDate: DateTime;
        rate: NationalAverageOutOfServiceRate.Raw;
    }

    export interface JSON {
        vehicle: number;
        driver: number;
        hazmat: number;
    }

    export interface Raw extends NationalAverageOutOfServiceRateDto {}
}
