import { RawData } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";

import type { MotorCarrier } from "../MotorCarrier";

// ========================================================================
export class Census extends RawData<Census.Raw> {
    // ========================================================================
    #carrier;
    #exists: boolean;

    constructor(carrier: MotorCarrier, raw: Census.Raw) {
        super(raw);
        this.#carrier = carrier;
        this.#exists = Boolean(raw);
    }

    // ========================================================================
    get mileage(): number {
        return this.get("Mcs150MileageTotal") || 0;
    }

    // ========================================================================
    get totalPowerUnits(): number {
        return this.get("TotalPowerUnits") || 0;
    }

    // ========================================================================
    get exists(): boolean {
        return this.#exists;
    }

    // ========================================================================
    json(): Census.Raw {
        return super.json();
    }

    // ========================================================================
    raw(): Census.Raw {
        return super.json();
    }
}

export namespace Census {
    export interface Raw extends FMCSA.Census {}
}
