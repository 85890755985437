import { Permission } from "@deathstar/types/waypoint";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import Unauthorized from "../../../components/error-screens/Unauthorized";
import Loader from "../../../components/loader/Loader";
import InvitationsTable from "./InvitationsTable";
import MembersTable from "./MembersTable";

export default function MemberPermissions() {
    const accountId = useAccountId();
    const { data: user } = api.user.useMe();
    const { data: hasAccess, isLoading } = api.user.useHasPermission(accountId!, Permission.Id.MEMBERS_READ);

    if (!user || isLoading) {
        return <Loader />;
    }

    if (!hasAccess) {
        return <Unauthorized />;
    }

    return (
        <div className="">
            <MembersTable />
            <hr className="my-12" />
            <InvitationsTable />
        </div>
    );
}
