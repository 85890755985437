export const HELP_CENTER_URL = "https://help.navigatortruckinsurance.com/";

export const States = [
    { abbr: "AK", name: "Alaska" },
    { abbr: "AL", name: "Alabama" },
    { abbr: "AR", name: "Arkansas" },
    { abbr: "AZ", name: "Arizona" },
    { abbr: "CA", name: "California" },
    { abbr: "CO", name: "Colorado" },
    { abbr: "CT", name: "Connecticut" },
    { abbr: "DE", name: "Delaware" },
    { abbr: "FL", name: "Florida" },
    { abbr: "GA", name: "Georgia" },
    { abbr: "HI", name: "Hawaii" },
    { abbr: "IA", name: "Iowa" },
    { abbr: "ID", name: "Idaho" },
    { abbr: "IL", name: "Illinois" },
    { abbr: "IN", name: "Indiana" },
    { abbr: "KS", name: "Kansas" },
    { abbr: "KY", name: "Kentucky" },
    { abbr: "LA", name: "Louisiana" },
    { abbr: "MA", name: "Massachusetts" },
    { abbr: "ME", name: "Maine" },
    { abbr: "MI", name: "Michigan" },
    { abbr: "MN", name: "Minnesota" },
    { abbr: "MS", name: "Mississippi" },
    { abbr: "MT", name: "Montana" },
    { abbr: "NC", name: "North Carolina" },
    { abbr: "ND", name: "North Dakota" },
    { abbr: "NE", name: "Nebraska" },
    { abbr: "NH", name: "New Hampshire" },
    { abbr: "NJ", name: "New Jersey" },
    { abbr: "NM", name: "New Mexico" },
    { abbr: "NV", name: "Nevada" },
    { abbr: "NY", name: "New York" },
    { abbr: "OH", name: "Ohio" },
    { abbr: "OK", name: "Oklahoma" },
    { abbr: "OR", name: "Oregon" },
    { abbr: "PA", name: "Pennsylvania" },
    { abbr: "RI", name: "Rhode Island" },
    { abbr: "SD", name: "South Dakota" },
    { abbr: "TN", name: "Tennessee" },
    { abbr: "TX", name: "Texas" },
    { abbr: "UT", name: "Utah" },
    { abbr: "VA", name: "Virginia" },
    { abbr: "VT", name: "Vermont" },
    { abbr: "WA", name: "Washington" },
    { abbr: "WI", name: "Wisconsin" },
    { abbr: "WV", name: "West Virginia" },
    { abbr: "WY", name: "Wyoming" },
    { abbr: "MO", name: "Missouri" },
    { abbr: "SC", name: "South Carolina" },
    { abbr: "MD", name: "Maryland" },
];

export const Territories = [
    { abbr: "AS", name: "American Samoa" },
    { abbr: "DC", name: "District of Columbia" },
    { abbr: "GU", name: "Guam" },
    { abbr: "VI", name: "Virgin Islands" },
    { abbr: "PR", name: "Puerto Rico" },
    { abbr: "MP", name: "Northern Marianas" },
];

//Canadian provinces
export const Provinces = [
    { abbr: "NL", name: "Newfoundland and Labrador" },
    { abbr: "PE", name: "Prince Edward Island" },
    { abbr: "NS", name: "Nova Scotia" },
    { abbr: "NB", name: "New Brunswick" },
    { abbr: "QC", name: "Quebec" },
    { abbr: "ON", name: "Ontario" },
    { abbr: "MB", name: "Manitoba" },
    { abbr: "SK", name: "Saskatchewan" },
    { abbr: "AB", name: "Alberta" },
    { abbr: "BC", name: "British Columbia" },
    { abbr: "YT", name: "Yukon" },
    { abbr: "NT", name: "Northwest Territories" },
    { abbr: "NU", name: "Nunavut" },
];

export const NTIA = {
    name: "Navigator Truck Insurance Agency",
    abbreviatedName: "NTIA",

    address: {
        street: "529 Baldwin Street",
        city: "Jenison",
        state: "MI",
        zip: "49428",
    },
    phone: "8005968782",
    fax: "6164577301",
    phoneFormatted: "(800) 596-8782",
    faxFormatted: "(616) 457-7301",
    email: "clientservice@navigatortruckinsurance.com",
};
