import { Transform, Type } from "class-transformer";
import type { Account } from "./Account";
import type { FileCategory } from "./FileCategory";

export interface SharepointDocument {
    fileId: string;

    categoryId: number;

    category: FileCategory | null;

    metadata: { type: string; id: string } | null;

    comment: string | null;

    accountId: string;

    account: Account;
}

class Join {
    @Transform(({ value }) => value === "true" || value === 1 || value === true)
    category: boolean;
}

class Filter {
    categoryId: number;
    submissionId: number;
}

export class FindSharepointDocumentOptions {
    @Type(() => Join)
    join?: Partial<Join>;
    @Type(() => Filter)
    filter?: Partial<Filter>;
}
