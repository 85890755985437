import { plainToClass } from "class-transformer";
import type { Coverage } from "./Coverage";
import type { CoverageLimit } from "./CoverageLimit";
import type { Property } from "./Property";
import { Comparison } from "./Comparison/Comparison";
import { NumberFormatter } from "../util/NumberFormatter/NumberFormatter";

export class CoverageScheduleOfHazard {
    static duplicate(scheduleOfHazard: CoverageScheduleOfHazard): CoverageScheduleOfHazard {
        const haz = plainToClass(CoverageScheduleOfHazard, scheduleOfHazard);

        haz.id = null;
        haz.createdDate = null;
        haz.coverageId = null;
        haz.coverageCreatedDate = null;
        haz.coverage = null;
        haz.coverageLimitId = null;
        haz.coverageLimitCreatedDate = null;
        haz.coverageLimit = null;

        return haz;
    }

    static compare({
        base,
        compare,
    }: {
        base?: CoverageScheduleOfHazard;
        compare?: CoverageScheduleOfHazard;
    }): Comparison<CoverageScheduleOfHazard> {
        const comparison = new Comparison(CoverageScheduleOfHazard);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add Location: ${compare.toString()} `,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete Location: ${base.toString()}`,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        [
            {
                key: "classification",
                label: "Classification",
                transform: (v) => v,
            },
            {
                key: "code",
                label: "Code",
                transform: (v) => v,
            },
            {
                key: "basis",
                label: "Basis",
                transform: (v) => v,
            },
            {
                key: "isIfAny",
                label: "If Any",
                transform: (v) => (v ? "Yes" : "No"),
            },
            {
                key: "exposure",
                label: "Exposure",
                transform: (v) => v,
            },
            {
                key: "premium",
                label: "Premium",
                transform: (v) => NumberFormatter.Currency.format(v),
            },
            {
                key: "rate",
                label: "Rate",
                transform: (v) => NumberFormatter.Percent.format(v),
            },
        ].forEach(({ key, label, transform }) => {
            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Change ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    priority: null,
                    fieldName: key as keyof CoverageScheduleOfHazard,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        return comparison;
    }

    id: number;
    createdDate: Date;

    coverageId?: number;
    coverageCreatedDate?: Date;
    coverage?: Coverage;

    coverageLimitId?: number;
    coverageLimitCreatedDate?: Date;
    coverageLimit?: CoverageLimit;

    propertyId?: number;
    propertyEffectiveDate?: Date;
    property: Property;
    sortOrder: number;
    classification?: CoverageScheduleOfHazard.Classification;
    code?: number;
    basis?: CoverageScheduleOfHazard.Basis;
    isIfAny: boolean;
    exposure?: number;
    premium?: number;
    rate?: number;
    metadata?: Record<string, unknown>;

    toString(): string {
        if (this.property) {
            const parts: (string | number)[] = [];
            if (this.property.street1) parts.push(this.property.street1);
            if (this.property.street2) parts.push(this.property.street2);
            if (this.property.buildingCode) parts.push(this.property.buildingCode);
            if (this.property.city) parts.push(this.property.city);
            if (this.property.state) parts.push(this.property.state);
            if (this.property.zip) parts.push(this.property.zip);

            return parts.join(" ");
        }
        return `CoverageScheduleOfHazard: ${this.id}`;
    }

    compare(
        comparisonLocation: CoverageScheduleOfHazard,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CoverageScheduleOfHazard> {
        return CoverageScheduleOfHazard.compare(Comparison.getBaseAndComparisonObjects(
            {
                initiatorEntity: this,
                comparisonEntity: comparisonLocation,
            },
            options
        ));
    }
}

export namespace CoverageScheduleOfHazard {
    export enum Classification {
        TRUCKER = "Trucker",
        GARAGE = "Garage",
        TRUCK_WASH = "Truck Wash",
        WAREHOUSE = "Warehouse",
        LRO_LESSORS_RISK = "LRO Lessors Risk",
        TRUCKING_OFFICE = "Trucking Office",
        MERCANTILE_OFFICE = "Mercantile Office",
        VACANT_LAND = "Vacant Land",
    }

    export enum Basis {
        NUMBER_OF_EMPLOYEES = "Number of Employees",
        AREA_ACRES = "Area Acres",
        AREA_SQUARE = "Area Square",
        PAYROLL = "Payroll",
        REVENUE = "Revenue",
        TOTAL_COST = "Total Cost",
    }
}
