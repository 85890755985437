import { Outlet, Route, Routes } from "react-router-dom";
import NavigationTabs from "../../components/navigation-tabs/NavigationTabs";
import BillingPage from "./BillingPage";
import PaymentHistory from "./PaymentHistory";

export default function Billing() {
    return (
        <Routes>
            <Route
                element={
                    <div className="space-y-4 p-4 lg:p-12">
                        <NavigationTabs
                            tabs={[
                                { label: "Settings", to: "" },
                                { label: "Payment history", to: "history" },
                            ]}
                        />
                        <Outlet />
                    </div>
                }
            >
                <Route index element={<BillingPage />} />
                <Route path="history" element={<PaymentHistory />} />
            </Route>
        </Routes>
    );
}
