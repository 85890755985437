import src from "../../assets/images/not-found.svg";
import BaseErrorScreen from "./BaseErrorScreen";

export default function NoInternetError() {
    return (
        <BaseErrorScreen
            title="We lost your signal"
            description="Check your internet connection and try again."
            primaryAction={null}
            image={<img src={src} alt="empty road" className="hidden w-1/3 lg:block" />}
        />
    );
}
