import { Collapse } from "@deathstar/ui";
import { ChevronDownIcon, ClockIcon, QuestionMarkCircleIcon, TrophyIcon } from "@heroicons/react/24/outline";
import { orderBy } from "lodash";
import { useState } from "react";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import jacketImageSrc from "../../assets/images/carhartt-jacket.jpg";
import certificateImageSrc from "../../assets/images/driver-of-the-year-certificate.png";
import { stringifyAddress } from "../certificates/certificatesUtil";
import { DriverOfTheYearForm } from "./DriverOfTheYearForm";

export default function DriverRewardProgram() {
    const accountId = useAccountId();
    const { data } = api.driverOfTheYear.useFind(accountId!);
    const [showMore, setShowMore] = useState(false);

    const thisYear = new Date().getFullYear();

    const thisYearWinner = data?.find((row) => row.year === thisYear);

    return (
        <div className="space-y-8">
            <p className="text-stone-700">
                Nominate your Driver of the Year and have a custom-embroidered Carhartt jacket and award certificate shipped right to their
                door.
            </p>
            <div className="lg:w-2/3">
                <button
                    className="flex items-center gap-2 text-lg text-blue-600 hover:text-blue-900"
                    onClick={() => setShowMore(!showMore)}
                >
                    <QuestionMarkCircleIcon className="h-5 w-5" />
                    What is the Driver of the Year program?
                    <ChevronDownIcon className="h-4 w-4" />
                </button>
                <Collapse isOpen={showMore}>
                    <div className="mt-2 space-y-4 rounded-lg bg-stone-50 p-4">
                        <p>
                            Give your drivers the recognition they deserve, and inspire a culture of excellence that sets your company apart
                            in the industry.
                        </p>
                        <p>
                            Navigator's Driver of the Year is our exclusive recognition program designed to celebrate excellence within your
                            trucking company. It's our opportunity to highlight drivers who consistently go the extra mile and embody the
                            core values of safety, professionalism, and reliability.
                        </p>

                        <p>
                            As part of this program, your selected Driver of the Year will receive a beautifully crafted, custom-embroidered
                            Carhartt<span className="text-xs">©</span> jacket. This personalized outerwear isn't meant to collect dust in a
                            dark closet. Its quality and craftsmanship should serve as a tangible symbol of your driver's exceptional
                            achievements and dedication. May the owner of this jacket wear it with dignity — reinforcing the strong sense of
                            pride both you and they have to work together in this profession we call trucking.
                        </p>
                    </div>
                </Collapse>
            </div>
            {data ? (
                <div className="grid justify-items-center gap-8 lg:grid-cols-[2fr,1fr]">
                    {thisYearWinner ? (
                        <div className="rounded border border-waypoint-blue/30 bg-waypoint-blue/5 p-4">
                            <div className="flex items-center gap-2 text-lg">
                                <ClockIcon className="h-5 w-5 text-waypoint-blue" />
                                {thisYearWinner.status === "processing" ? (
                                    <p>Your request is being processed</p>
                                ) : (
                                    <p>Your order has been shipped!</p>
                                )}
                            </div>
                            <div className="font-light">
                                <p>{thisYearWinner.driver?.name}</p>
                                <p>Size {thisYearWinner.size.toUpperCase()}</p>
                                <p>
                                    {stringifyAddress({
                                        street: thisYearWinner.addressLine1,
                                        street2: thisYearWinner.addressLine2,
                                        city: thisYearWinner.addressCity,
                                        state: thisYearWinner.addressState,
                                        zip: thisYearWinner.addressPostalCode,
                                    })}
                                </p>
                                <p className="mt-4 text-right text-sm">See an issue? Contact us!</p>
                            </div>
                        </div>
                    ) : (
                        <DriverOfTheYearForm />
                    )}
                    <div className="relative h-min">
                        <img src={jacketImageSrc} width={400} alt="Carhartt jacket showcase" className="rounded" />
                        <img
                            src={certificateImageSrc}
                            width={200}
                            alt="Driver of the Year award certificate"
                            className="absolute -bottom-2 -right-2 w-1/2 rounded shadow"
                        />
                    </div>
                    {data?.length ? (
                        <div className="h-max w-full space-y-2 rounded border border-waypoint-orange/30 bg-waypoint-orange/5 p-4">
                            <div className="flex items-center gap-2 text-lg">
                                <TrophyIcon className="h-5 w-5 text-waypoint-orange" />
                                <p className="">Previous winners</p>
                            </div>
                            <ul className="space-y-2 font-light">
                                {orderBy(data, "year", "desc").map((winner) => (
                                    <li key={winner.id} className="flex items-center gap-2">
                                        <p>{winner.year}</p>
                                        <p>{winner.driver?.name}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="h-96 w-full animate-pulse rounded bg-stone-100"></div>
            )}
        </div>
    );
}
