import { OOProgramDetails, OOProgramInvitation } from "@deathstar/types/waypoint";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const ooProgram = {
    queryKeys: {
        get: (accountId: string) => [accountId, "oo-program"] as QueryKey,
    },

    get: (accountId: string) => {
        return blazar.fetchJson<OOProgramDetails>(`/waypoint/orgs/${accountId}/oo-program`);
    },

    useGet: <T = OOProgramDetails>(accountId: string, config?: Partial<UseQueryOptions<OOProgramDetails, ResponseError, T>>) =>
        useQuery<OOProgramDetails, ResponseError, T>({
            queryKey: ooProgram.queryKeys.get(accountId),
            queryFn: () => ooProgram.get(accountId),
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            ...config,
        }),

    invite: (data: Partial<OOProgramInvitation>) => {
        return blazar.fetchJson<OOProgramInvitation>(`/waypoint/orgs/${data.accountId}/oo-program/invitations`, {
            method: "POST",
            body: JSON.stringify(data),
        });
    },

    useInvite: (config?: UseMutationOptions<OOProgramInvitation, ResponseError, Partial<OOProgramInvitation>>) => {
        return useMutation<OOProgramInvitation, ResponseError, Partial<OOProgramInvitation>>({
            mutationFn: ooProgram.invite,
            ...config,
            onSuccess: (data, vars, ctx) => {
                queryClient.invalidateQueries({ queryKey: ooProgram.queryKeys.get(vars.accountId!) });
                config?.onSuccess?.(data, vars, ctx);
            },
        });
    },

    remove: (accountId: string, memberId: string) => {
        return blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/oo-program/members/${memberId}`, {
            method: "DELETE",
        });
    },

    useRemove: (accountId: string, config?: UseMutationOptions<void, ResponseError, string>) => {
        return useMutation<void, ResponseError, string>({
            mutationFn: (memberId) => ooProgram.remove(accountId, memberId),
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: ooProgram.queryKeys.get(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },
};
