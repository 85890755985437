import { Doughnut } from "react-chartjs-2";

import { config } from "../ChartConfig";

export function EmptyDoughnutChart({ title }: { title?: string }): JSX.Element {
    return (
        <Doughnut
            className="h-full w-full"
            data={{
                datasets: [
                    {
                        label: "",
                        data: [1],
                        backgroundColor: [config.colors.lightGrey],
                        borderWidth: 0,
                    },
                ],
            }}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: "top",
                    },
                    title: {
                        display: !!title,
                        text: title,
                    },
                    tooltip: {
                        callbacks: {
                            label: () => "n/a",
                        },
                    },
                },
            }}
        />
    );
}
