import { IsBoolean, IsString } from "class-validator";
import { Permission, UserMembership } from ".";
import { Account } from "../northstar";

export interface UserPermission {
    userId: string;
    accountId: string;
    permissionId: string;

    membership: UserMembership;
    permission: Permission;
    account: Account;
}

export class EditUserPermissionsEntry {
    @IsString()
    userId: string;

    @IsString()
    permissionId: Permission["id"];

    @IsBoolean()
    enabled: boolean;
}
export type EditPermissionsBody = EditUserPermissionsEntry[];
