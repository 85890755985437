import { useIsMounted } from "./useIsMounted";
import { useState, useCallback, useMemo } from "react";

enum Status {
    IDLE = "idle",
    LOADING = "loading",
    SUCCESS = "success",
    ERROR = "error",
}

interface ActionState {
    status: Status;
    isLoading: boolean;
    isError: boolean;
    isIdle: boolean;
    isSuccess: boolean;
    isDisabled: boolean;
}

interface UseActionStateConfig<SuccessResult> {
    delayMs?: number;
    onSuccess?: (result: SuccessResult) => void;
    onError?: (error: unknown) => void;
    multiple?: boolean;
}

type HandlerFn<SuccessResult, Args extends unknown[] = unknown[]> = (...args: Args) => Promise<SuccessResult>;
type TriggerFn<Args extends unknown[] = unknown[]> = (...args: Args) => Promise<void>;

export function useActionState<SuccessResult, Args extends unknown[] = unknown[]>(
    memoizedHandler: HandlerFn<SuccessResult, Args>,
    config?: UseActionStateConfig<SuccessResult>
): [TriggerFn<Args>, ActionState] {
    const memoizedConfig: UseActionStateConfig<SuccessResult> = useMemo(
        () => ({
            delayMs: 1500,
            onSuccess: () => {
                // noop
            },
            onError: () => {
                // noop
            },
            multiple: true,
            ...config,
        }),
        [config]
    );
    const [status, setStatus] = useState<Status>(Status.IDLE);

    const mounted = useIsMounted();

    const trigger: TriggerFn<Args> = useCallback(
        async (...args) => {
            try {
                setStatus(Status.LOADING);
                const start = Date.now();
                const handlerResult = await memoizedHandler(...args);
                if (Date.now() - start < memoizedConfig.delayMs!) {
                    await wait(memoizedConfig.delayMs! - (Date.now() - start));
                }
                if (mounted.current) {
                    setStatus(Status.SUCCESS);
                }
                if (memoizedConfig.delayMs) {
                    await wait(memoizedConfig.delayMs);
                }
                if (mounted.current && memoizedConfig.multiple) {
                    setStatus(Status.IDLE);
                }
                memoizedConfig.onSuccess!(handlerResult);
            } catch (e) {
                if (mounted.current) {
                    setStatus(Status.ERROR);
                }
                if (memoizedConfig.delayMs) {
                    await wait(memoizedConfig.delayMs);
                }
                if (mounted.current) {
                    setStatus(Status.IDLE);
                }
                memoizedConfig.onError!(e);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [config, memoizedHandler]
    );

    const state = useMemo(
        () => ({
            status,
            isLoading: status === Status.LOADING,
            isError: status === Status.ERROR,
            isIdle: status === Status.IDLE,
            isSuccess: status === Status.SUCCESS,
            isDisabled: status !== Status.IDLE,
        }),
        [status]
    );

    return [trigger, state];
}

function wait(ms: number): Promise<void> {
    return new Promise((res) => {
        setTimeout(res, ms);
    });
}
