enum AttributeType {
    ACCOUNTING = "Accounting",
    COVERAGE = "Coverage",
    POLICY = "Policy",
}

enum AttributeId {
    ACCOUNTING_SURPLUS_LINES_AGENCY_PAID = "accountingSurplusLinesAgencyPaid",
    ACCOUNTING_SURPLUS_LINES_INSURANCE_COMPANY_PAID = "accountingSurplusLinesInsuranceCompanyPaid",
    ACTUAL_LOSS_SUSTAINED = "actualLossSustained",
    ACUITY_ADVANTAGES = "acuityAdvantages",
    ACUITY_EDGE = "acuityEdge",
    ACUITY_ENHANCEMENTS_GOLD = "acuityEnhancementsGold",
    ACUITY_ENHANCEMENTS_PLATINUM = "acuityEnhancementsPlatinum",
    ACUITY_ENHANCEMENTS_SILVER = "acuityEnhancementsSilver",
    ACUITY_TRUCKERS_ENHANCEMENTS = "acuityTruckersEnhancements",
    AGENCY_EXPENSE = "agencyExpense",
    AGREED_AMOUNT = "valuationAgreedAmount",
    ANY_AUTO_EXCEPT_PPV = "anyAutoExceptPpv",
    ANY_ONE_ACCIDENT_OR_OCCURRENCE = "anyOneAccidentOrOccurrence",
    ASSIGNED_RISK = "assignedRisk",
    BAILEES = "bailees",
    BASIC_DEDUCTIBLE = "basicDeductible",
    BLANKET_BUILDING = "blanketBuilding",
    BLANKET_BUILDING_AND_CONTENT = "blanketBuildingAndContent",
    BLANKET_CONTENT = "blanketContent",
    BOND_NAME = "bondName",
    BOX_TRUCK = "boxTruck",
    BROADENED_DEDUCTIBLE = "broadenedDeductible",
    BROADENED_POLLUTION = "broadenedPollution",
    BUSINESS_AUTO = "businessAuto",
    CAPTIVE_POWERTECH_ELITE = "captivePowerTechElite",
    CAPTIVE_VELOCITY = "captiveVelocity",
    CAPTIVE_VENTURE = "captiveVenture",
    CAPTIVE_VOYAGER = "captiveVoyager",
    CARGO_AND_GARAGE_TYPE_CLAIMS_MADE = "cargoAndGarageTypeClaimsMade",
    CARGO_AND_GARAGE_TYPE_OCCURRENCE = "cargoAndGarageTypeOccurrence",
    CARGO_DEPOT_TIME = "cargoDepotTime",
    CARGO_TRAILER_TIME = "cargoTrailerTime",
    CAUSE_OF_LOSS_BASIC = "causeOfLossBasic",
    CAUSE_OF_LOSS_BROAD = "causeOfLossBroad",
    CAUSE_OF_LOSS_SPECIAL = "causeOfLossSpecial",
    CHOICE_PLUS = "choicePlus",
    COINSURANCE_PERCENTAGE = "coinsurancePercentage",
    COINSURANCE_WAIVED = "coinsuranceWaived",
    COMPOSITE_ADVANCED = "compositeAdvanced",
    COMPOSITE_ARREARS = "compositeArrears",
    CONTRACTOR_BENEFITS_ADMIN = "contractorBenefitsAdmin",
    CONVOY = "convoy",
    COVERAGE_ENTENDED_TO_OWNER_OPERATOR = "coverageExtendedToOwnerOperator",
    DEDUCTIBLE_COMBINED_WITH_COVERAGE = "deductibleCombinedWithCoverage",
    DEDUCTIBLE_PER_AUTO = "deductiblePerAuto",
    DEFNESE_LIMITS_IN = "defenseLimitsIn",
    DEFNESE_LIMITS_OUT = "defenseLimitsOut",
    DELUXE = "deluxe",
    DIVIDEND_PLAN = "dividendPlan",
    DRIVE_OTHER_CAR = "driveOtherCar",
    DRIVER_HIRE_DATE = "driverHireDate",
    DRIVER_REQUIREMENTS = "driverRequirements",
    DRIVER_SURCHARGES = "driverSurcharges",
    DRIVER_YEARS_EXPERIENCE = "driverYearsExperience",
    EMPLOYERS_LIABILITY = "employersLiability",
    EQUIPMENT_BREAKDOWN = "equipmentBreakdown",
    EQUIPMENT_BREAKDOWN_TECH_ADVANTAGES = "equipmentBreakdownTechAdvantages",
    ERISA_PLAN = "erisaPlan",
    ERISA_VALUE = "erisaValue",
    EXTRA_EXPENSE = "extraExpense",
    FAMILY = "family",
    FIFTEEN_DAY_RULE_APPLIES = "fifteenDayRuleApplies",
    FIRST_DOLLAR_DEDUCTIBLE = "firstDollarDeductible",
    FOLLOWS_FORM = "followsForm",
    FUNCTIONAL_REPLACEMENT_COST = "valuationFunctionalReplacementCost",
    GARAGE_KEEPERS = "garageKeepers",
    GARAGE_LIABILITY = "garageLiability",
    GENERAL_LIABILITY_PLUS = "generalLiabilityPlus",
    GUARANTEED_FULL_REPLACEMENT_COST = "valuationGuaranteedFullReplacementCost",
    HERNIA_COVERAGE = "herniaCoverage",
    HIRED = "hired",
    HOUSE_ACCOUNT = "houseAccount",
    IMMIGRATION_PRACTICES_WRONGFUL_ACT = "immigrationPracticesWrongfulAct",
    INCURRAL_PERIOD = "incurralPeriod",
    INFLATION_GUARD_INCLUDED = "inflationGuardIncluded",
    INFLATION_GUARD_PERCENTAGE = "inflationGuardPercentage",
    LANCER_ADVANTAGES = "lancerAdvantages",
    LIABILITY_TYPE_DIRECT = "liabilityTypeDirect",
    LIABILITY_TYPE_LEGAL = "liabilityTypeLegal",
    LIMIT_COMBINED_WITH_COVERAGE = "limitCombinedWithCoverage",
    LIMITED_DEDUCTIBLE = "limitedDeductible",
    LOSS_COLLATERAL = "lossCollateral",
    MAX_DEDUCTIBLE_PER_AUTO = "maxDeductiblePerAuto",
    MIN_EARNED_PREMIUM_AMOUNT = "minimumEarnedPremiumAmount",
    MIN_EARNED_PREMIUM_PERCENTAGE = "minimumEarnedPremiumPercentage",
    MIN_PREMIUM_AMOUNT = "minimumPremiumAmount",
    MINIMUM_MONTHLY_PREMIUM_COVERAGE = "minimumMonthlyPremiumCoverage",
    MINIMUM_MONTHLY_PREMIUM = "minimumMonthlyPremium",
    MOTOR_CARRIER_PLUS = "motorCarrierPlus",
    NEW_TO_AGENCY_NEW = "newToAgencyNew",
    NEW_TO_AGENCY_RENEWAL = "newToAgencyRenewal",
    NON_OWNED = "nonOwned",
    NON_OWNED_TRACTORS = "nonOwnedTractors",
    NON_OWNED_TRAILERS = "nonOwnedTrailers",
    OCAC_WAITING_PERIOD = "ocacWaitingPeriod",
    ON_HOOK = "onHook",
    OWNER_OPERATOR_WAYPOINT_PROGRAM = "ownerOperatorWaypointProgram",
    PER_DEIM_DAY = "perDiemDay",
    PER_DEIM_MILE = "perDiemMile",
    POLLUTION = "pollution",
    PREMIER = "premier",
    PREMIER_PLUS = "premierPlus",
    PREMISES_OPERATIONS = "premisesOperations",
    PREMIUM_COLLATERAL = "premiumCollateral",
    PREMIUM_FINANCED_AFCO = "premiumFinancedAfco",
    PREMIUM_FINANCED_CEDAR = "premiumFinancedCedar",
    PREMIUM_FINANCED_GREEN_LIGHT = "premiumFinancedGreenLight",
    PREMIUM_FINANCED_OTHER = "premiumFinancedOther",
    PRIOR_ACTS = "priorActs",
    REEFER = "reefer",
    RENTAL_REIMBURSEMENT = "rentalReimbursement",
    RENTAL_VALUE = "rentalValue",
    REPLACEMENT_COST = "valuationReplacementCost",
    RESTORATION_DAYS = "restorationDays",
    SCHEDULED_DRIVER = "scheduledDriver",
    SELF_INSURED_RETENTION = "selfInsuredRetentionAmount",
    SELF_INSURED_RETENTION_DOES_NOT_APPLY = "selfInsuredRetentionDoesNotApply",
    SNOW_PLOW = "snowPlow",
    SPECIFIED_PERIL = "valuationSpecifiedPeril",
    SPECIFIED_SHIPPER = "specifiedShipper",
    STANDARD_DEDUCTIBLE = "standardDeductible",
    STATED_VALUE = "valuationStatedValue",
    STOCK = "stock",
    STOP_GAP = "stopGap",
    SUNA = "suna",
    SURPLUS_LINES = "surplusLines",
    SYMBOL_ANY_AUTO = "anyAuto",
    SYMBOL_ANY_AUTO_EXCEPT_PRIVATE_PASSENGER_AND_SERVICE_TYPE_AUTOS = "anyAutoExceptPrivatePassengerAndServiceTypeAutos",
    SYMBOL_AUTO_AUTO_EXCEPT_PRIVATE_PASSENGER_TYPE_AUTO = "anyAutoExceptPrivatePassengerTypeAuto",
    SYMBOL_BROKERED_AUTOS = "brokeredAutos",
    SYMBOL_HIRED_AUTO_ONLY = "hiredAutoOnly",
    SYMBOL_HIRED_COMMERCIAL_AUTOS_ONLY = "hiredCommercialAutosOnly",
    SYMBOL_LEASED_COMMERCIAL_AUTOS = "leasedCommercialAutos",
    SYMBOL_MOBILE_EQUIPMENT = "mobileEquipment",
    SYMBOL_NON_OWNED_AUTOS_ONLY = "nonOwnedAutosOnly",
    SYMBOL_OWNED_AUTOS_ONLY = "ownedAutosOnly",
    SYMBOL_OWNED_AUTOS_OTHER_THAN_PRIVATE_PASSENGERS_ONLY = "ownedAutosOtherThanPrivatePassengersOnly",
    SYMBOL_OWNED_AUTOS_SUBJECT_TO_COMPULSORY_UNINSURED_MOTORIST_LAW = "ownedAutosSubjectToACompulsoryUninsuredMotoristLaw",
    SYMBOL_OWNED_AUTOS_SUBJECT_TO_NO_FAULT = "ownedAutoSubjectToNoFault",
    SYMBOL_OWNED_COMMERCIAL_AUTOS_ONLY = "ownedCommercialAutosOnly",
    SYMBOL_OWNED_PRIVATE_PASSENGER_AUTOS_ONLY = "ownedPrivatePassengerAutosOnly",
    SYMBOL_REPORTED_AUTOS = "reportedAutos",
    SYMBOL_SCHEDULED = "scheduled",
    SYMBOL_SHORT_TERM_RENTALS_WITHOUT_DRIVER_UP_TO_30_DAYS_100K = "shortTermRentalsWithoutDriverUpTo30DaysAnd100k",
    SYMBOL_SPECIAL_WORDING = "specialWording",
    SYMBOL_SPECIFICALLY_DESCRIBED_AUTO = "specificallyDescribedAuto",
    SYMBOL_TRAILER_INTERCHANGE = "trailerInterchangeSymbol",
    SYMBOL_YOUR_TRAILERS_IN_POSSESSION_OF_ANYONE_ELSE_UNDER_WRITTEN_TRAILER_INTERCHANGE_AGREEMENT = "trailersPossessedByOtherUnderWrittenTIAgreement",
    TAKEN_BY_AOR_WITH_COMMISSION = "takenByAorWithCommission",
    TAKEN_BY_AOR_WITHOUT_COMMISSION = "takenByAorWithoutCommission",
    TANK_CAPACITY = "tankCapacity",
    TANK_CONTENTS = "tankContents",
    TERRORISM = "terrorism",
    THIRD_PARTY = "thirdParty",
    TOTAL_AUTOS = "totalAutos",
    TOWING_AND_LABOR = "towingAndLabor",
    TRAILER_INTERCHANGE = "trailerInterchange",
    UIIA_INTERMODAL = "uiiaIntermodal",
    UNIQUE_EQUIPMENT_DEDUCTIBLE = "uniqueEquipmentDeductible",
    UNIT_COLLISION = "unitCollision",
    UNIT_COMPREHENSIVE = "unitComprehensive",
    UNIT_OWNER_OPERATOR = "unitOwnerOperator",
    UNIT_SPARE_RATED = "unitSpareRated",
    VALUATION_ACTUAL_CASH_VALUE = "valuationActualCashValue",
    WAITING_PERIOD = "waitingPeriod",
}
export class AttributeOption {
    static EQUIPMENT_ATTRIBUTE_IDS = [
        AttributeId.VALUATION_ACTUAL_CASH_VALUE,
        AttributeId.STATED_VALUE,
        AttributeId.UNIT_OWNER_OPERATOR,
        AttributeId.UNIT_SPARE_RATED,
        AttributeId.UNIT_COMPREHENSIVE,
        AttributeId.UNIT_COLLISION,
        AttributeId.DRIVER_HIRE_DATE,
        AttributeId.DRIVER_YEARS_EXPERIENCE,
    ];

    static VALUATION_ATTRIBUTE_IDS = [
        AttributeId.VALUATION_ACTUAL_CASH_VALUE,
        AttributeId.AGREED_AMOUNT,
        AttributeId.FUNCTIONAL_REPLACEMENT_COST,
        AttributeId.GUARANTEED_FULL_REPLACEMENT_COST,
        AttributeId.REPLACEMENT_COST,
    ];

    static SYMBOL_ATTRIBUTE_IDS = [
        AttributeId.ANY_AUTO_EXCEPT_PPV,
        AttributeId.SYMBOL_ANY_AUTO_EXCEPT_PRIVATE_PASSENGER_AND_SERVICE_TYPE_AUTOS,
        AttributeId.SYMBOL_AUTO_AUTO_EXCEPT_PRIVATE_PASSENGER_TYPE_AUTO,
        AttributeId.SYMBOL_BROKERED_AUTOS,
        AttributeId.SYMBOL_HIRED_AUTO_ONLY,
        AttributeId.SYMBOL_HIRED_COMMERCIAL_AUTOS_ONLY,
        AttributeId.SYMBOL_LEASED_COMMERCIAL_AUTOS,
        AttributeId.SYMBOL_MOBILE_EQUIPMENT,
        AttributeId.SYMBOL_NON_OWNED_AUTOS_ONLY,
        AttributeId.SYMBOL_OWNED_AUTOS_ONLY,
        AttributeId.SYMBOL_OWNED_AUTOS_OTHER_THAN_PRIVATE_PASSENGERS_ONLY,
        AttributeId.SYMBOL_OWNED_AUTOS_SUBJECT_TO_COMPULSORY_UNINSURED_MOTORIST_LAW,
        AttributeId.SYMBOL_OWNED_AUTOS_SUBJECT_TO_NO_FAULT,
        AttributeId.SYMBOL_OWNED_COMMERCIAL_AUTOS_ONLY,
        AttributeId.SYMBOL_OWNED_PRIVATE_PASSENGER_AUTOS_ONLY,
        AttributeId.SYMBOL_REPORTED_AUTOS,
        AttributeId.SYMBOL_SCHEDULED,
        AttributeId.SYMBOL_SHORT_TERM_RENTALS_WITHOUT_DRIVER_UP_TO_30_DAYS_100K,
        AttributeId.SYMBOL_SPECIAL_WORDING,
        AttributeId.SYMBOL_SPECIFICALLY_DESCRIBED_AUTO,
        AttributeId.SYMBOL_TRAILER_INTERCHANGE,
        AttributeId.SYMBOL_YOUR_TRAILERS_IN_POSSESSION_OF_ANYONE_ELSE_UNDER_WRITTEN_TRAILER_INTERCHANGE_AGREEMENT,
    ];

    id: string;
    name: string;
    type: AttributeOption.Type;
}

export namespace AttributeOption {
    export type Type = AttributeType;
    export const Type = AttributeType;
    export type Id = AttributeId;
    export const Id = AttributeId;
    export type CompositeTypes = AttributeId.COMPOSITE_ADVANCED | AttributeId.COMPOSITE_ARREARS;
}
