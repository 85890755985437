import { StripeUtils } from "@deathstar/reuse";
import { Payment } from "@deathstar/types/waypoint";
import { classNames, DataTable } from "@deathstar/ui";
import { ColumnDef, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import React from "react";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";

const columns: ColumnDef<Payment>[] = [
    {
        accessorFn: (p) => moment.unix(p.created).format("MM/DD/YYYY"),
        header: "Date",
        meta: { align: "right" },
    },
    {
        accessorFn: (p) => StripeUtils.formatting.centsToDollarString(p.amountCents),
        header: "Amount",
        meta: { align: "right" },
    },
    {
        accessorKey: "status",
        cell: (info) => (
            <span
                className={classNames(
                    "capitalize",
                    info.getValue() === "succeeded" && "text-emerald-700",
                    info.getValue() === "cancelled" && "text-stone-700",
                    info.getValue() === "proccessing" && "text-blue-700"
                )}
            >
                {info.getValue() as string}
            </span>
        ),
    },
];

export default function PaymentHistory() {
    const accountId = useAccountId();
    const { data, isLoading } = api.billing.usePaymentHistory(accountId!);

    const memoizedData = React.useMemo(() => data || [], [data]);

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: 6,
            },
        },
    });

    return (
        <div className="rounded border p-2 md:w-max">
            <p className="font-medium text-waypoint-blue-dark">Previous payments</p>
            <DataTable table={table} disableSearch isLoading={isLoading} />
        </div>
    );
}
