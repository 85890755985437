import { Tooltip as MuiTooltip, TooltipProps } from "@material-ui/core";
import { ReactNode } from "react";
import { classNames } from "../classNames/classNames";

export function Tooltip(props: Omit<TooltipProps, "children"> & { children: ReactNode | string | number }): JSX.Element {
    const { children } = props;

    return (
        <MuiTooltip {...props}>
            <span
                className={classNames("border-dashed border-gray-400 print:border-none", {
                    "border-b": props.title,
                })}
            >
                {children}
            </span>
        </MuiTooltip>
    );
}
