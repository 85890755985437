import { DateTime } from "../DateTime/DateTime";

export class RevenueThreshold {
    static #dates = [
        {
            effectiveDate: DateTime.fromObject({ year: 2025, month: 1, day: 1 }),
            expirationDate: null,
            amount: 7500,
        },
        {
            effectiveDate: DateTime.fromObject({ year: 1000, month: 1, day: 1 }),
            expirationDate: DateTime.fromObject({ year: 2024, month: 12, day: 31 }),
            amount: 6000,
        },
    ];

    static getThreshold(date = new Date()): number | undefined {
        const requestedDate = DateTime.fromDate(date);

        const result = this.#dates.find((obj) => {
            const { effectiveDate, expirationDate } = obj;

            if (expirationDate && requestedDate.isBetween(effectiveDate, expirationDate)) return obj;
            if (!expirationDate && requestedDate.isAfter(effectiveDate)) return obj;
            return false;
        });

        return result?.amount || this.#dates[0].amount;
    }
}
