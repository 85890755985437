import { Driver } from "@deathstar/types/northstar";
import { DataTable, fuzzyFilter, selectColumn } from "@deathstar/ui";
import {
    ColumnDef,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";

export function OwnerOperatorSelector({
    available,
    selected,
    setSelected,
}: {
    available: Driver[];
    selected: number[];
    setSelected: (selected: number[]) => void;
}) {
    const rowSelection = Object.fromEntries(selected.map((id) => [id, true]));

    const table = useReactTable({
        data: available,
        columns: useMemo(
            () =>
                [
                    selectColumn,
                    createColumnHelper<{ id: number; name: string }>().accessor((row) => row.name || "", {
                        id: "name",
                        header: "Year",
                    }),
                    createColumnHelper<{ id: number; license: string }>().accessor((row) => row.license || "", {
                        id: "license",
                        header: "Make",
                    }),
                ] as ColumnDef<Driver>[],
            []
        ),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getRowId: (row) => String(row.id),
        onRowSelectionChange: (updater) => {
            if (typeof updater === "function") {
                const newState = updater(rowSelection);
                setSelected(
                    Object.keys(newState)
                        .filter((id) => newState[id] === true)
                        .map((id) => Number(id))
                );
            }
        },
        state: {
            rowSelection,
        },
        globalFilterFn: fuzzyFilter,
    });

    return (
        <DataTable
            table={table}
            className="w-full"
            onRowClick={(row) => {
                if (selected.includes(row.id)) {
                    setSelected(selected.filter((id) => id !== row.id));
                } else {
                    setSelected([...selected, row.id]);
                }
            }}
        />
    );
}
