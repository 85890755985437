import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Select, SelectProps } from "@material-ui/core";
import { FilterButton, FilterButtonProps } from "../filterButton/FilterButton";

export interface OptionFilterButtonProps<T = never> extends Omit<FilterButtonProps<T>, "Menu"> {
    menuTitle: string;
    SelectProps?: SelectProps;
    children: React.ReactNode;
    multiple?: boolean;
}
export function OptionFilterButton<T = never>(props: OptionFilterButtonProps<T>) {
    return (
        <FilterButton
            getHasValue={(value) => (props.multiple ? (value as unknown[]).length > 0 : !!value)}
            {...props}
            Menu={({ value, onChange }) => <Menu {...props} value={value} onChange={onChange} />}
        />
    );
}

export function Menu<T = never>({
    value,
    onChange,
    menuTitle,
    SelectProps,
    children,
    multiple,
}: Pick<OptionFilterButtonProps<T>, "menuTitle" | "SelectProps" | "children" | "multiple"> & {
    value: T;
    onChange: (value: T) => void;
}): JSX.Element {
    const _value = value || "";

    return (
        <div className="space-y-2">
            <p className="text-sm font-medium tracking-wide">{menuTitle}</p>
            <Select
                value={multiple && !Array.isArray(_value) ? [_value] : _value}
                onChange={(e) => {
                    const nextValue = Array.isArray(e.target.value) ? e.target.value.filter((v) => v !== "") : e.target.value;
                    onChange(nextValue as T);
                }}
                displayEmpty
                classes={{ outlined: "py-2 text-sm" }}
                fullWidth
                IconComponent={() => <ChevronUpDownIcon className="pointer-events-none absolute right-1 h-4 w-4" />}
                variant="outlined"
                multiple={multiple}
                {...SelectProps}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        horizontal: "right",
                        vertical: "bottom",
                    },
                    ...SelectProps?.MenuProps,
                }}
            >
                {children}
            </Select>
        </div>
    );
}
