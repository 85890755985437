import React from "react";
import { Context } from "../Context";
import { NegativeTrends } from "./NegativeTrends";
import { PositiveTrends } from "./PositiveTrends";

export function Trends(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);
    const trends = motorCarrier.getTrends({
        dateRange: {
            from: motorCarrier.dates.twelveMonthMark,
            to: motorCarrier.dates.to,
        },
        trends: {
            basicAssessmentTrend: true,
            basicSubCategoryFrequencyTrend: true,
        },
    });

    return (
        <>
            <PositiveTrends className="flex flex-col" trends={trends} />
            <NegativeTrends className="flex flex-col" trends={trends} />
        </>
    );
}
