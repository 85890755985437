import type { Account } from "./Account";
import { CoverageLinkedBusinessAuto } from "./CoverageLinkedBusinessAuto";
import { CoverageOption } from "./CoverageOption";
import type { EquipmentType } from "./EquipmentType";
import { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";
import { Property } from "./Property";
import { AccountEquipment, AccountEquipmentStatus } from "./Tractor";
import { User } from "./User";
import { Vehicle } from "./Vehicle";

export class BusinessAuto {
    id: number;

    createDate: Date;

    updateDate: Date;

    submit: boolean;

    isDeleted: boolean;

    accountId: string;

    year: number | null;

    make: string | null;

    typeId: EquipmentType["id"];

    type: EquipmentType;

    vin: string | null;

    acv: boolean;

    value: number | null;

    comprehensive: boolean;

    collision: boolean;

    ownerOperator: boolean;

    vinValid: number | null;

    comments: string | null;

    requiresApproval: boolean;
    requestComment: string;
    requestDate: string;
    isRequestNotificationSent: boolean;
    createdByWaypointUserId: string;

    unitNumber: string | null;

    account?: Account;

    /** This is omitted from any results and instead mapped onto the `coverages` property */
    coverageLinks?: CoverageLinkedBusinessAuto[];

    additionalInterests?: PolicyAdditionalInterest[];

    createdByGuid: string;

    createdBy: User;

    propertyId: number;

    property?: Property;

    policyUpcomingDate?: string | null | undefined;
    policyValue?: number | null | undefined;
    policyOwnerOperator?: boolean | null | undefined;
    policyAcv?: boolean | null | undefined;
    policyComprehensive?: boolean | null | undefined;
    policyCollision?: boolean | null | undefined;
    statuses?: AccountEquipmentStatus[];
    coverageOptions?: CoverageOption[];

    toString(): string {
        return Vehicle.toString(this);
    }
}

export type AccountAutos = AccountEquipment<
    BusinessAuto & {
        policyValue: number;
        policyOwnerOperator: boolean;
        policyAcv: boolean;
        policyComprehensive: boolean;
        policyCollision: boolean;
    }
>;
