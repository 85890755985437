import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ErrorBoundary } from "@sentry/react";
import { Link, Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import api from "../../api/api";
import { AccountIdProvider } from "../../api/useAccountId";
import { ConnectionError, ServerUnavailableError, UnauthorizedError } from "../../api/util/exceptions";
import AccountNotFound from "../../components/error-screens/AccountNotFound";
import NoInternetError from "../../components/error-screens/NoInternetError";
import ServerDownError from "../../components/error-screens/ServerDownError";
import SolPageNotFound from "../../components/error-screens/SolPageNotFound";
import UncaughtException from "../../components/error-screens/UncaughtException";
import NavigationTabs from "../../components/navigation-tabs/NavigationTabs";
import Certificates from "../../features/certificates/Certificates";
import CertificateView from "../../features/certificates/CertificateView";
import Drivers from "../../features/drivers/Drivers";
import Equipment from "../../features/equipment/Equipment";
import Policies from "../../features/policies/Policies";
import SplashScreen from "../sol/SplashScreen";

const OO = withAuthenticationRequired(
    function () {
        const params = useParams();
        const { data: user, isLoading: isLoadingUser, isError, error } = api.user.useMe();
        const {
            data: account,
            isLoading: isLoadingOrganization,
            error: organizationError,
        } = api.organization.useById(params.accountId!, {
            enabled: !!params.accountId,
        });

        if (isLoadingUser || isLoadingOrganization) {
            return <SplashScreen />;
        }
        if (isError) {
            if (error instanceof ConnectionError) {
                return <NoInternetError />;
            }
            if (error instanceof ServerUnavailableError) {
                return <ServerDownError />;
            }
            return <UncaughtException />;
        }
        if (!user) {
            return <AccountNotFound />;
        }
        if (!params.accountId) {
            return <AccountNotFound />;
        }
        if (organizationError) {
            if (organizationError instanceof UnauthorizedError) {
                return <AccountNotFound />;
            }
            return <UncaughtException />;
        }

        return (
            <AccountIdProvider accountId={params.accountId!}>
                <Routes>
                    <Route
                        element={
                            <div className="flex h-screen flex-col">
                                <div className="space-y-6 px-4 py-4">
                                    <div className="flex flex-col gap-2 md:flex-row-reverse md:items-center md:justify-end md:gap-8">
                                        <p className="overflow-hidden text-ellipsis whitespace-nowrap text-center text-base text-waypoint-blue-dark md:text-left">
                                            You are viewing <span className="font-bold">{account?.company.name}</span>
                                        </p>
                                        <Link
                                            to={account?.ooProgramHolderId ? `/sol/${account?.ooProgramHolderId}/drivers` : "/sol"}
                                            className="flex items-center gap-2 whitespace-nowrap text-blue-700 hover:text-blue-900"
                                        >
                                            <ChevronLeftIcon className="h-5 w-5" />
                                            Go back
                                        </Link>
                                    </div>
                                    <NavigationTabs
                                        tabs={[
                                            { label: "Policies", to: "policies" },
                                            { label: "Drivers", to: "drivers" },
                                            { label: "Equipment", to: "equipment" },
                                            { label: "Certificates", to: "certificates" },
                                        ]}
                                    />
                                </div>
                                <ErrorBoundary fallback={<UncaughtException />}>
                                    <div className="flex h-full grow flex-col overflow-y-auto">
                                        <Outlet />
                                    </div>
                                </ErrorBoundary>
                            </div>
                        }
                    >
                        <Route index element={<Navigate to="policies/active" />} />
                        <Route path="drivers/*" element={<Drivers hideRewardProgram />} />
                        <Route path="equipment/*" element={<Equipment />} />
                        <Route path="policies/*" element={<Policies />} />
                        <Route
                            path="certificates/*"
                            element={
                                <Routes>
                                    <Route index element={<Certificates />} />
                                    <Route path="/:certificateId" element={<CertificateView />} />
                                    <Route path="*" element={<SolPageNotFound />} />
                                </Routes>
                            }
                        />
                        <Route path="*" element={<SolPageNotFound />} />
                    </Route>
                </Routes>
            </AccountIdProvider>
        );
    },
    { returnTo: () => window.location.pathname }
);

export default OO;
