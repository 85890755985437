import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { Column, Table } from "@tanstack/react-table";
import React from "react";
import { Input } from "./Input";

export function ColumnFilter<T>({
    column,
    table,
    label,
    ...props
}: { column: Column<T, unknown>; table: Table<T>; label?: string } & Omit<
    AutocompleteProps<string, false, false, true>,
    "options" | "value" | "onChange" | "renderInput"
>) {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = React.useMemo(
        () => (typeof firstValue === "number" ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [column.getFacetedUniqueValues()]
    );

    return (
        <Autocomplete<string, false, false, true>
            {...props}
            freeSolo={props.freeSolo}
            options={sortedUniqueValues}
            disabled={sortedUniqueValues.length === 1 && sortedUniqueValues[0] === null}
            value={(columnFilterValue as string) || null}
            onChange={(_, value) => column.setFilterValue(value === null ? undefined : value)}
            renderInput={(params) => <Input {...params} type="text" label={label} />}
        />
    );
}
