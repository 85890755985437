import { plainToClass } from "class-transformer";
import type { Coverage } from "./Coverage";

export class CoverageRateClassification {
    static createTemplateFrom(classification: CoverageRateClassification): CoverageRateClassification {
        return plainToClass(CoverageRateClassification, {
            code: classification.code,
            sortOrder: classification.sortOrder,
        });
    }

    static duplicate(rate: CoverageRateClassification): CoverageRateClassification {
        const r = plainToClass(CoverageRateClassification, rate);

        r.id = null;
        r.createdDate = null;
        r.coverageId = null;
        r.coverageCreatedDate = null;
        r.coverage = null;

        return r;
    }

    id: number;
    createdDate: Date;

    coverageId?: number;
    coverageCreatedDate?: Date;
    coverage?: Coverage;

    description: string | null;
    state: string | null;
    code: string | null;
    payroll: number | null;
    isIfAny: boolean;
    rate: number | null;

    sortOrder: number;
    metadata?: Record<string, unknown>;
}
