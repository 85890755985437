import React from "react";
import NewInspectionIcon from "../../assets/icons/new-inspection-icon.png";
// import { NewPointsChart } from "../Charts/NewPoints";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { pluralize } from "../utils/pluralize";
import { ViolationSummaryLegend } from "./ViolationSummaryLegend";

export function NewViolations(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const { violations } = motorCarrier.inspections.filterNew();

    return (
        <div>
            <TitleGroup
                title="New Violations"
                variant="h5"
                className="mb-8"
                align="left"
                icon={<img src={NewInspectionIcon} alt="New Inspection Icon" className="h-[35px]" />}
                description={`${violations.total} new ${pluralize(violations.total, "violation", "s")} reported`}
            />

            <ViolationSummaryLegend violations={violations} />
        </div>
    );
}
