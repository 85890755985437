import { LinkIcon } from "@heroicons/react/24/outline";
import {
    FormatBoldRounded,
    FormatClearRounded,
    FormatItalicRounded,
    FormatListBulletedRounded,
    FormatListNumberedRounded,
    FormatStrikethroughRounded,
    RedoRounded,
    UndoRounded,
} from "@material-ui/icons";
import { Color } from "@tiptap/extension-color";
import { Link } from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProps } from "@tiptap/pm/view";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect } from "react";
import { classNames } from "../classNames/classNames";
import { FontSize } from "./fontSize";

const extensions = [
    StarterKit,
    Color,
    FontSize,
    TextStyle,
    Link.configure({
        openOnClick: false,
        HTMLAttributes: {
            rel: "noopener noreferrer",
            target: "_blank",
        },
    }),
];

export function RichTextEditor({
    value,
    onChange,
    placeholder,
    editorProps,
    className,
    editorClassName,
}: {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    className?: string;
    editorClassName?: string;
    editorProps?: EditorProps<unknown>;
}) {
    const editor = useEditor({
        content: value,
        editorProps: {
            ...editorProps,
            attributes: placeholder
                ? {
                      placeholder,
                  }
                : {},
        },
        onUpdate: ({ editor }) => {
            onChange(editor.getHTML());
        },
        extensions,
    });

    useEffect(() => {
        if (editor && editor.getHTML() !== value) {
            editor.chain().setContent(value).run();
        }
    }, [value, editor]);

    return (
        <div className={classNames("rounded border border-gray-300", className)}>
            <EditorToolbar editor={editor} />
            <hr />
            <EditorContent
                editor={editor}
                className={classNames("rich-text max-h-[200px] overflow-y-auto", editor?.isEmpty ? "rte-empty" : "", editorClassName)}
            />
        </div>
    );
}

const colors = [
    { name: "black", value: "" },
    { name: "gray", value: "dimgray" },
    { name: "green", value: "green" },
    { name: "red", value: "#D50000" },
    { name: "orange", value: "#D43900" },
];

const sizes = [
    { name: "small", value: "0.857em" },
    { name: "normal", value: "1em" },
    { name: "large", value: "1.2em" },
    { name: "huge", value: "1.4em" },
];

function EditorToolbar({ editor }: { editor?: Editor | null }) {
    if (!editor) {
        return null;
    }

    const activeColor = colors.find((color) => editor.isActive("textStyle", { color: color.value }))?.value;
    const activeSize = sizes.find((size) => editor.isActive("textStyle", { fontSize: size.value }))?.value;

    return (
        <div className="flex flex-wrap gap-2 p-1 text-gray-600">
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
                className={classNames(editor.isActive("bold") ? "text-blue-700" : "")}
            >
                <FormatBoldRounded className="h-5 w-5" />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run()}
                className={classNames(editor.isActive("italic") ? "text-blue-700" : "")}
            >
                <FormatItalicRounded className="h-5 w-5" />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={!editor.can().chain().focus().toggleStrike().run()}
                className={classNames(editor.isActive("strike") ? "text-blue-700" : "")}
            >
                <FormatStrikethroughRounded className="h-5 w-5" />
            </button>
            <button type="button" onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                <FormatClearRounded className="h-5 w-5" />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={classNames(editor.isActive("bulletList") ? "text-blue-700" : "")}
            >
                <FormatListBulletedRounded className="h-5 w-5" />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={classNames(editor.isActive("orderedList") ? "text-blue-700" : "")}
            >
                <FormatListNumberedRounded className="h-5 w-5" />
            </button>
            <button type="button" onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().chain().focus().undo().run()}>
                <UndoRounded className="h-5 w-5" />
            </button>
            <button type="button" onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().chain().focus().redo().run()}>
                <RedoRounded className="h-5 w-5" />
            </button>
            <button
                type="button"
                className={classNames(editor.isActive("link") ? "text-blue-700" : "")}
                onClick={() => {
                    if (editor.isActive("link")) {
                        editor.chain().focus().unsetLink().run();
                    } else {
                        const previousUrl = editor.getAttributes("link").href;
                        const url = window.prompt("URL", previousUrl);
                        // cancelled
                        if (url === null) {
                            return;
                        }
                        // empty
                        if (url === "") {
                            editor.chain().focus().extendMarkRange("link").unsetLink().run();

                            return;
                        }
                        // update link
                        editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
                    }
                }}
            >
                <LinkIcon className="h-4 w-4" />
            </button>
            <select
                value={activeColor || ""}
                onChange={(e) => {
                    if (e.target.value) {
                        editor.chain().focus().setColor(e.target.value).run();
                    } else {
                        editor.chain().focus().unsetColor().run();
                    }
                }}
                className="text-sm"
                style={{ color: activeColor }}
            >
                {colors.map((c) => (
                    <option key={c.value} value={c.value}>
                        {c.name}
                    </option>
                ))}
            </select>
            <select
                value={activeSize || "1em"}
                onChange={(e) => {
                    if (e.target.value) {
                        editor.chain().focus().setFontSize(e.target.value).run();
                    } else {
                        editor.chain().focus().unsetFontSize().run();
                    }
                }}
                className="text-sm"
            >
                {sizes.map((size) => (
                    <option key={size.value} value={size.value}>
                        {size.name}
                    </option>
                ))}
            </select>
        </div>
    );
}
