import { DriverRow } from "@deathstar/types/waypoint";
import { ActionButton, DataTable, fuzzyFilter, Tabs } from "@deathstar/ui";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import Loader from "../../components/loader/Loader";
import { columns, DriverTableMeta } from "./DriverColumns";
import { useDriverNotesDialog } from "./DriverNotesDialog";
import { NewDriverDialog } from "./NewDriverDialog";
import { TractorLinker, TrailerLinker } from "./TractorLinker";

const AnimatedDataTable = animated(DataTable<DriverRow>);

export default function DriversTable({ data }: { data: DriverRow[] | undefined }) {
    const accountId = useAccountId();
    const [filter, setFilter] = useState<"all" | "company" | "owner-operator">("all");
    const [adding, setAdding] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [linkingTractorToDriverId, setLinkingTractorToDriverId] = useState<number | null>(null);
    const [linkingTrailerToDriverId, setLinkingTrailerToDriverId] = useState<number | null>(null);
    const updateDriver = api.drivers.useUpdate(accountId!);
    const memoizedData = useMemo(() => data || [], [data]);
    const { open, component: driverNotesDialog } = useDriverNotesDialog(accountId!, memoizedData);

    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    const sortingState = useMemo(() => {
        return [{ id: "pending", desc: true }, ...sorting];
    }, [sorting]);

    const columnFilters = useMemo(() => {
        if (filter === "all") {
            return [];
        }
        return [{ id: "owner-operator", value: filter === "owner-operator" }];
    }, [filter]);

    const columnVisibility = useMemo<VisibilityState>(() => ({ "owner-operator": filter !== "company" }), [filter]);

    const meta: DriverTableMeta = useMemo(
        () => ({
            openTractorLinker(driverId: number) {
                setLinkingTractorToDriverId(driverId);
            },
            openTrailerLinker(driverId: number) {
                setLinkingTrailerToDriverId(driverId);
            },
            openDriverNotes(driverId: number) {
                const found = memoizedData.find((r) => r.id === driverId);
                if (found) {
                    open(driverId);
                }
            },
        }),
        [memoizedData, open]
    );

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: fuzzyFilter,
        state: {
            sorting: sortingState,
            columnFilters,
            columnVisibility,
        },
        initialState: {
            pagination: {
                pageSize: 30,
            },
        },
        onSortingChange: setSorting,
        meta,
    });

    return (
        <div>
            {data ? (
                <>
                    <AnimatedDataTable
                        style={spring}
                        table={table}
                        headerRow={
                            <div className="flex items-end gap-4">
                                <Tabs
                                    tabs={[
                                        {
                                            label: () => <p className="w-32 px-2 py-1">All drivers</p>,
                                            value: "all",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                        {
                                            label: () => <p className="w-32 px-2 py-1">Company</p>,
                                            value: "company",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                        {
                                            label: () => <p className="w-32 px-2 py-1">Owner Operators</p>,
                                            value: "owner-operator",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                    ]}
                                    value={filter}
                                    onChange={setFilter}
                                    className="rounded-md bg-stone-100 py-1 shadow-inner"
                                    borderClasses="bg-white border border-waypoint-blue z-[0] shadow rounded-md"
                                />
                                <div className="grow"></div>
                                <ActionButton onClick={() => setAdding(true)}>
                                    <PlusIcon className="h-5 w-5" />
                                    Add driver
                                </ActionButton>
                                <DataTable.Search table={table} />
                            </div>
                        }
                    />
                    <NewDriverDialog open={adding} onClose={() => setAdding(false)} />
                    <TractorLinker
                        open={!!linkingTractorToDriverId}
                        currentValue={memoizedData.find((r) => r.id === linkingTractorToDriverId)?.tractors || []}
                        onClose={() => setLinkingTractorToDriverId(null)}
                        onChange={async (tractors) => {
                            if (linkingTractorToDriverId) {
                                updateDriver.mutate({ id: linkingTractorToDriverId, tractors });
                                setLinkingTractorToDriverId(null);
                            }
                        }}
                    />
                    <TrailerLinker
                        open={!!linkingTrailerToDriverId}
                        currentValue={memoizedData.find((r) => r.id === linkingTrailerToDriverId)?.trailers || []}
                        onClose={() => setLinkingTrailerToDriverId(null)}
                        onChange={async (trailers) => {
                            if (linkingTrailerToDriverId) {
                                updateDriver.mutate({ id: linkingTrailerToDriverId, trailers });
                                setLinkingTrailerToDriverId(null);
                            }
                        }}
                    />
                    {driverNotesDialog}
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
