import { Policy, PolicyDocument } from "@deathstar/types/northstar";
import { InputField } from "@deathstar/ui";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import { matchSorter } from "match-sorter";
import { useState } from "react";
import { PolicyDocumentItem } from "./PolicyDocumentItem";

export default function PolicyDocumentsList({ documents, policy }: { documents: PolicyDocument[]; policy: Policy }) {
    const [search, setSearch] = useState("");

    const filtered = matchSorter(documents, search, { keys: ["name"], threshold: matchSorter.rankings.CONTAINS });

    return (
        <div className="">
            <InputField
                startAdornment={<MagnifyingGlassCircleIcon className="h-5 w-5 text-stone-400" />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                classes={{ inputContainer: "py-1 pl-2", root: "mb-1" }}
            />
            <ul className="w-[384px] text-sm">
                {filtered.length ? (
                    filtered.map((document) => <PolicyDocumentItem key={document.id} document={document} policy={policy} />)
                ) : (
                    <li className="my-4">No documents available</li>
                )}
            </ul>
        </div>
    );
}
