export class CoverageModificationOption {
    id: CoverageModificationOption.Id;
    name: string;
}

export namespace CoverageModificationOption {
    export enum Id {
        ACCIDENTAL_MEDICAL_EXPENSE = "accidentalMedicalExpense",
        ADDITIONAL_LIMIT_OF_CLAIMS_AGAINST_MANAGERS = "additionalLimitOfClamsAganistManagers",
        ANY_ONE_PERSON = "anyOnePerson",
        ANY_ONE_PERSON_ORGANIZATION = "anyOnePersonOrOrganization",
        ANY_ONE_PREMISES = "anyOnePremises",
        ANY_ONE_VEHICLE = "anyOneVehicle",
        AT_ANY_TERMINAL_LOCATION = "atAnyTerminalLocation",
        AT_ONE_LOSS_DISASTER_OR_CASUALTY = "atOneLossDisasterOrCasualty",
        COLLISION = "collision",
        COMMODITY = "commodity",
        COMPREHENSIVE = "comprehensive",
        CORPORATE_REIMBURSEMENT = "corporateReimbursement",
        DEBRIS_REMOVAL = "debrisRemoval",
        DENTAL_MAX_PER_TOOTH = "dentalMaxPerTooth",
        EACH_EMPLOYEE = "eachEmployee",
        EACH_TOOL = "eachTool",
        EARNED_FREIGHT = "earnedFreight",
        EMERGENCY_EVACUATION = "emergencyEvacuation",
        FREIGHT_CHARGES = "freightCharges",
        FUDICIARY_LIABILITY = "fiduciaryLiability",
        KIDNAM_RANSOM_EXTORTION = "kidnamRansomExtortion",
        NEWLY_ACQUIRED_TERMINALS = "newlyAcquiredTerminals",
        PER_ACCIDENT = "perAccident",
        PER_AUTO = "perAuto",
        PER_CATASTROPHE = "perCatastrophe",
        PER_CLAIM = "perClaim",
        PER_COVERED_ITEM = "perCoveredItem",
        PER_DISASTER = "perDisaster",
        PER_LOSS = "perLoss",
        PER_OCCURRENCE = "perOccurrence",
        PER_PERSON = "perPerson",
        PER_PERSON_ACCIDENT = "perPersonAccident",
        PER_TRAILER = "perTrailer",
        PER_TRIP = "perTrip",
        PER_VEHICLE = "perVehicle",
        POLLUTANT_CLEANUP_AND_REMOVAL = "pollutantCleanupAndRemoval",
        PROPERTY_OF_OTHERS = "propertyOfOthers",
        REPATRIATION_OF_ROMAINS = "repatriationOfRemains",
        SUBLIMIT_DATA_PRIVACY_EVEN_EXPENSE_COVERAGE = "sublimitDataPrivacyEvenExpenseCoverage",
        SUBLIMIT_FIRST_PARTY_WORKPLACE_VIOLENT_ACTS = "sublimitFirstPartyWorkplaceViolentActs",
        SUBLIMIT_HIPAA = "sublimitHipaa",
        SUBLIMIT_IMIGRATION_PRACTICES_WRONGFUL_ACTS = "sublimitImmigrationPracticesWrongfulActs",
        SUBLIMIT_INVESTIGATION_COSTS = "sublimitInvestigationCosts",
        SUBLIMIT_LEGAL_SERVICES_WRONGFUL_ACT = "sublimitLegalServicesWrongfulActs",
        SUBLIMIT_OTHER = "sublimitOther",
        SUBLIMIT_SETTLEMENT_PROGRAM_COVERAGE = "sublimitSettlementProgramCoverage",
        SUBLIMIT_WAGE_AND_HOUR_ENDORSEMENT = "sublimitWageAndHourEndorsement",
        THEFT_DEDUCTIBLE = "theftDeductible",
    }

    export const Name = {
        [Id.ACCIDENTAL_MEDICAL_EXPENSE]: "Accidental Medical Expense",
        [Id.ADDITIONAL_LIMIT_OF_CLAIMS_AGAINST_MANAGERS]: "Additional Limit of Claims against Managers",
        [Id.ANY_ONE_PERSON_ORGANIZATION]: "Any One Person or Organization",
        [Id.ANY_ONE_PERSON]: "Any One Person",
        [Id.ANY_ONE_PREMISES]: "Any One Premises",
        [Id.ANY_ONE_VEHICLE]: "Any One Vehicle",
        [Id.AT_ANY_TERMINAL_LOCATION]: "At Any Terminal Location",
        [Id.AT_ONE_LOSS_DISASTER_OR_CASUALTY]: "At One Loss, Disaster or Casualty",
        [Id.COLLISION]: "Collision",
        [Id.COMMODITY]: "Commodity",
        [Id.COMPREHENSIVE]: "Comprehensive",
        [Id.CORPORATE_REIMBURSEMENT]: "Corporate Reimbursement ",
        [Id.DEBRIS_REMOVAL]: "Debris Removal",
        [Id.DENTAL_MAX_PER_TOOTH]: "Dental Maximum per Tooth",
        [Id.EACH_EMPLOYEE]: "Each Employee",
        [Id.EACH_TOOL]: "Each Tool",
        [Id.EARNED_FREIGHT]: "Earned Freight",
        [Id.EMERGENCY_EVACUATION]: "Emergency Evacuation",
        [Id.FREIGHT_CHARGES]: "Freight Charges",
        [Id.FUDICIARY_LIABILITY]: "Fiduciary Liability",
        [Id.KIDNAM_RANSOM_EXTORTION]: "Kidnap & Ransom/Extortion",
        [Id.NEWLY_ACQUIRED_TERMINALS]: "Newly Acquired Terminals",
        [Id.PER_ACCIDENT]: "Per Accident",
        [Id.PER_AUTO]: "Per Auto",
        [Id.PER_CATASTROPHE]: "Per Catastrophe",
        [Id.PER_CLAIM]: "Per Claim",
        [Id.PER_COVERED_ITEM]: "Per Covered Item",
        [Id.PER_DISASTER]: "Per Disaster",
        [Id.PER_LOSS]: "Per Loss",
        [Id.PER_OCCURRENCE]: "Per Occurrence",
        [Id.PER_PERSON_ACCIDENT]: "Per Person/Accident",
        [Id.PER_PERSON]: "Per Person",
        [Id.PER_TRAILER]: "Per Trailer",
        [Id.PER_TRIP]: "Per Trip",
        [Id.PER_VEHICLE]: "Per Vehicle",
        [Id.POLLUTANT_CLEANUP_AND_REMOVAL]: "Pollutant Cleanup & Removal",
        [Id.PROPERTY_OF_OTHERS]: "Property of Others",
        [Id.REPATRIATION_OF_ROMAINS]: "Repatriation of Remains",
        [Id.SUBLIMIT_DATA_PRIVACY_EVEN_EXPENSE_COVERAGE]: "Sublimit: Data Privacy Even Expense Coverage",
        [Id.SUBLIMIT_FIRST_PARTY_WORKPLACE_VIOLENT_ACTS]: "Sublimit: First Party Workplace Violent Acts",
        [Id.SUBLIMIT_HIPAA]: "Sublimit: HIPAA",
        [Id.SUBLIMIT_IMIGRATION_PRACTICES_WRONGFUL_ACTS]: "Sublimit: Immigration Practices Wrongful Acts",
        [Id.SUBLIMIT_INVESTIGATION_COSTS]: "Sublimit: Investigation Costs",
        [Id.SUBLIMIT_LEGAL_SERVICES_WRONGFUL_ACT]: "Sublimit: Legal Services Wrongful Act",
        [Id.SUBLIMIT_OTHER]: "Sublimit: Other",
        [Id.SUBLIMIT_SETTLEMENT_PROGRAM_COVERAGE]: "Sublimit: Settlement Program Coverage",
        [Id.SUBLIMIT_WAGE_AND_HOUR_ENDORSEMENT]: "Sublimit: Wage and Hour Endorsement",
        [Id.THEFT_DEDUCTIBLE]: "Theft Deductible",
    };
}
