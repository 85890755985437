import { forwardRef } from "react";
import styles from "./avatar.module.css";

interface AvatarProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    src?: string;
    alt: string;
    classes?: Partial<Record<"root" | "img" | "svg", string>>;
}
export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(({ src, alt, children, classes, ...props }, ref) => {
    return (
        <div ref={ref} className={`${styles.root} ${classes?.root || ""}`} {...props}>
            {src ? (
                <img className={`${styles.img} ${classes?.img || ""}`} src={src} alt={alt} />
            ) : children ? (
                children
            ) : (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${styles.svg} ${classes?.svg || ""}`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
            )}
        </div>
    );
});
