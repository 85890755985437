import React, { useContext } from "react";

export function useAccountId(): string | undefined {
    return useContext(AccountIdContext);
}

const AccountIdContext = React.createContext<string | undefined>(undefined);

export function AccountIdProvider({ children, accountId }: { children: React.ReactNode; accountId: string }) {
    return <AccountIdContext.Provider value={accountId}>{children}</AccountIdContext.Provider>;
}
