import { forwardRef, ReactElement, useState } from "react";

interface ImgProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "children"> {
    children: ReactElement | ((reset: () => void) => ReactElement);
}

/**
 * A component that renders an image, but falls back to a child if the image fails to load.
 */
export const Img = forwardRef<HTMLImageElement, ImgProps>(function ({ children, alt, ...props }, ref) {
    const [isError, setIsError] = useState(false);
    return isError ? (
        typeof children === "function" ? (
            children(() => setIsError(false))
        ) : (
            children
        )
    ) : (
        <img
            {...props}
            alt={alt}
            ref={ref}
            onError={(e) => {
                setIsError(true);
                props?.onError?.(e);
            }}
        />
    );
});
