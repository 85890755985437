import { Alert, PrimaryButton, useSnackbar } from "@deathstar/ui";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, SetupIntent } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { ThrottleError } from "../../api/util/exceptions";
import environment from "../../environment";

const stripePromise = loadStripe(environment.stripe.publicKey);

function Form({ onSubmit }: { onSubmit(setupIntent: SetupIntent | null, error?: unknown): void }) {
    const accountId = useAccountId();
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const { mutate: getSetupIntent, data: initialSetupIntent } = api.billing.useSetupIntent(accountId!, {
        onError: (error) => {
            if (error instanceof ThrottleError) {
                useSnackbar.add(
                    `Too many attempted payment method additions. Try again in ${
                        error.retryAfter ? error.retryAfter + " seconds" : "a few minutes"
                    }.`,
                    { variant: "error" }
                );
            }
            onSubmit(null, error);
        },
    });

    useEffect(() => {
        getSetupIntent();
    }, [getSetupIntent]);

    return (
        <form
            onSubmit={async (event) => {
                event.preventDefault();

                if (!stripe || !elements || !initialSetupIntent) {
                    // Stripe.js hasn't yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return null;
                }

                elements.submit();

                const { error, setupIntent } = await stripe.confirmSetup({
                    // `Elements` instance that was used to create the Payment Element
                    elements,
                    redirect: "if_required",
                    clientSecret: initialSetupIntent.clientSecret,
                });

                if (error) {
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Show error to your customer (for example, payment
                    // details incomplete)
                    setErrorMessage(error.message);
                } else {
                    // Your customer will be redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer will be redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                    switch (setupIntent.status) {
                        case "succeeded":
                            useSnackbar.add("Success! Your payment method has been saved.", {
                                variant: "success",
                                autoHideDuration: 10000,
                            });
                            onSubmit(setupIntent);
                            break;

                        case "processing":
                            useSnackbar.add("Processing payment details. We'll update you when processing is complete.", {
                                variant: "info",
                                autoHideDuration: 10000,
                            });
                            onSubmit(setupIntent);
                            break;

                        case "requires_payment_method":
                            // Redirect your user back to your payment page to attempt collecting
                            // payment again
                            useSnackbar.add("Failed to process payment details. Please try another payment method.", {
                                variant: "error",
                                autoHideDuration: 10000,
                            });
                            onSubmit(null, new Error("Failed to process payment details. Please try another payment method."));
                            break;
                    }
                }
            }}
        >
            <PaymentElement />
            {errorMessage && <Alert variant="error">{errorMessage}</Alert>}

            <div className="flex justify-end py-4">
                <PrimaryButton type="submit">Save</PrimaryButton>
            </div>
        </form>
    );
}

export default function NewPaymentMethodForm({ onSubmit }: { onSubmit(setupIntent: SetupIntent | null, error?: unknown): void }) {
    return (
        <Elements stripe={stripePromise} options={{ mode: "setup", currency: "usd", payment_method_types: ["card", "us_bank_account"] }}>
            <Form onSubmit={onSubmit} />
        </Elements>
    );
}
