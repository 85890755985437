import { useAuth0 } from "@auth0/auth0-react";
import { Icon as WebIAIcon } from "@deathstar/inspection-analysis";
import { classNames } from "@deathstar/ui";
import {
    ArrowRightStartOnRectangleIcon,
    ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconOutline,
    CurrencyDollarIcon as CurrencyDollarIconOutline,
    DocumentTextIcon as DocumentTextIconOutline,
    HomeIcon as HomeIconOutline,
    ShieldCheckIcon as ShieldCheckIconOutline,
    TruckIcon as TruckIconOutline,
    UserCircleIcon,
} from "@heroicons/react/24/outline";
import {
    Bars3Icon,
    ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconSolid,
    Cog6ToothIcon,
    CurrencyDollarIcon as CurrencyDollarIconSolid,
    DocumentTextIcon as DocumentTextIconSolid,
    HomeIcon as HomeIconSolid,
    QuestionMarkCircleIcon,
    ShieldCheckIcon as ShieldCheckIconSolid,
    TruckIcon as TruckIconSolid,
} from "@heroicons/react/24/solid";
import { Badge } from "@material-ui/core";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import api from "../../api/api";
import Logo from "../../components/logo/Logo";
import SteeringWheelIcon from "../../components/steering-wheel/steering-wheel-icon";
import { HELP_CENTER_URL } from "../../util/constants";
import { NavigationItem } from "./NavigationItem";
import { OrgSwitcher } from "./OrgSwitcher";

type NavigationRoute = "home" | "policies" | "certificates" | "drivers" | "equipment" | "fmcsa-insights" | "advice" | "billing";

const navigationItems: Record<NavigationRoute, JSX.Element> = {
    home: (
        <NavigationItem
            key="home"
            to="."
            end
            label="Home"
            icon={({ isActive }) => (isActive ? <HomeIconSolid className="h-6 w-6" /> : <HomeIconOutline className="h-6 w-6" />)}
        />
    ),
    policies: (
        <NavigationItem
            key="policies"
            to="policies"
            label="Policies"
            icon={({ isActive }) =>
                isActive ? <ShieldCheckIconSolid className="h-6 w-6" /> : <ShieldCheckIconOutline className="h-6 w-6" />
            }
        />
    ),
    certificates: (
        <NavigationItem
            key="certificates"
            to="certificates"
            label="Certificates"
            icon={({ isActive }) =>
                isActive ? <DocumentTextIconSolid className="h-6 w-6" /> : <DocumentTextIconOutline className="h-6 w-6" />
            }
        />
    ),
    equipment: (
        <NavigationItem
            key="equipment"
            to="equipment"
            label="Equipment"
            icon={({ isActive }) => (isActive ? <TruckIconSolid className="h-6 w-6" /> : <TruckIconOutline className="h-6 w-6" />)}
        />
    ),
    drivers: (
        <NavigationItem
            key="drivers"
            to="drivers"
            label="Drivers"
            icon={({ isActive }) => (isActive ? <SteeringWheelIcon className="h-6 w-6" /> : <SteeringWheelIcon className="h-6 w-6" />)}
        />
    ),
    "fmcsa-insights": (
        <NavigationItem
            key="fmcsa-insights"
            to="fmcsa-insights"
            label="FMCSA Insights"
            icon={({ isActive }) =>
                isActive ? (
                    <WebIAIcon.FmcsaLogoIcon className="mx-0.5 !h-5 !w-5" />
                ) : (
                    <WebIAIcon.FmcsaLogoInvertedIcon className="mx-0.5 !h-5 !w-5" />
                )
            }
        />
    ),
    advice: (
        <NavigationItem
            key="advice"
            to="advice"
            label="Ask Anything"
            icon={({ isActive }) =>
                isActive ? <ChatBubbleLeftRightIconSolid className="h-6 w-6" /> : <ChatBubbleLeftRightIconOutline className="h-6 w-6" />
            }
        />
    ),
    billing: (
        <NavigationItem
            key="billing"
            to="billing"
            label="Billing"
            icon={({ isActive }) =>
                isActive ? <CurrencyDollarIconSolid className="h-6 w-6" /> : <CurrencyDollarIconOutline className="h-6 w-6" />
            }
        />
    ),
};

export function Navigation({ routes }: { routes?: NavigationRoute[] }) {
    const children = useMemo<JSX.Element[]>(() => {
        if (!routes) {
            return Object.values(navigationItems);
        } else {
            return routes
                .map((route) => {
                    return navigationItems[route];
                })
                .filter(Boolean) as JSX.Element[];
        }
    }, [routes]);

    return (
        <>
            <MobileNavigation>{children}</MobileNavigation>
            <WebNavigation>{children}</WebNavigation>
        </>
    );
}

type NavigationProps = {
    children: React.ReactNode[];
};

const WebNavigation = forwardRef<HTMLDivElement, NavigationProps>(function ({ children }, ref) {
    const { logout } = useAuth0();
    const { data: user } = api.user.useMe();
    const path = useMatch("/sol/:accountId/*")?.params["*"];

    return (
        <div
            className={classNames("border-right hidden h-screen w-56 shrink-0 flex-col space-y-2 overflow-visible bg-stone-50 p-4 lg:flex")}
            ref={ref}
        >
            <Logo />
            <div className="w-full">
                <hr className="my-4" />
            </div>
            <OrgSwitcher />
            <ul className="shrink grow space-y-1 overflow-auto">{children}</ul>

            <div className="sticky top-full">
                <Link to="/legal" className="mb-2 block w-full text-center text-sm text-stone-500 hover:text-blue-800">
                    Disclaimers & Terms
                </Link>
                <div className="grid grid-cols-2 items-center justify-evenly gap-1">
                    <Link to="/profile" className={classNames("text-center text-xs", "text-stone-500 hover:text-stone-700")}>
                        <Badge
                            badgeContent={user?.invitations.length}
                            variant="dot"
                            classes={{ badge: "bg-waypoint-orange animate-pulse" }}
                            overlap="rectangular"
                        >
                            <UserCircleIcon className="inline h-5 w-5" />
                        </Badge>
                        <p>Profile</p>
                    </Link>
                    <Link
                        to="settings"
                        className={classNames(
                            "text-center text-xs",
                            path?.startsWith("settings") ? "cursor-default text-waypoint-blue" : "text-stone-500 hover:text-stone-700"
                        )}
                    >
                        <Cog6ToothIcon className="inline h-5 w-5" />
                        <p>Settings</p>
                    </Link>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={HELP_CENTER_URL}
                        className="text-center text-xs text-stone-500 hover:text-stone-700"
                    >
                        <QuestionMarkCircleIcon className="inline h-5 w-5" />
                        <p>Help</p>
                    </a>
                    <button
                        className="flex flex-col items-center text-center text-xs text-stone-500 hover:text-stone-700"
                        onClick={() => {
                            logout();
                        }}
                    >
                        <ArrowRightStartOnRectangleIcon className="inline h-5 w-5" />
                        <span>Sign out</span>
                    </button>
                </div>
            </div>
        </div>
    );
});

function MobileNavigation({ children }: NavigationProps) {
    const { logout } = useAuth0();
    const { data: user } = api.user.useMe();
    const path = useMatch("/:_/:accountId/*")?.params["*"];

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(false);
    }, [path]);

    return (
        <div
            className={classNames(
                "sticky top-0 z-30 flex w-screen shrink-0 flex-col gap-2 bg-stone-50 p-4 transition-all duration-300 lg:hidden",
                expanded ? "h-screen overflow-y-auto" : "h-16 overflow-hidden"
            )}
        >
            <div className={classNames("flex w-full flex-row items-center justify-between gap-0")}>
                <Logo className="" width={120} />
                <button onClick={() => setExpanded(!expanded)} className="text-waypoint-orange">
                    <Bars3Icon className="h-7 w-7" />
                </button>
            </div>
            <div className="flex grow flex-col">
                <div className="w-full">
                    <hr className="my-4" />
                </div>
                <OrgSwitcher />
                <ul className="space-y-1">{children}</ul>
                <div className="flex grow flex-col justify-end">
                    <div className="grow"></div>
                    <Link to="/legal" className="mb-2 block w-full text-center text-sm text-stone-500 hover:text-blue-800">
                        Disclaimers & Terms
                    </Link>
                    <div className="grid grid-cols-4 justify-evenly">
                        <Link to="/profile" className={classNames("text-center text-xs", "text-stone-500 hover:text-stone-700")}>
                            <Badge
                                badgeContent={user?.invitations.length}
                                variant="dot"
                                classes={{ badge: "bg-waypoint-orange animate-pulse" }}
                                overlap="rectangular"
                            >
                                <UserCircleIcon className="inline h-5 w-5" />
                            </Badge>
                            <p>Profile</p>
                        </Link>
                        <Link
                            to="settings"
                            className={classNames(
                                "text-center text-xs",
                                path?.startsWith("settings") ? "cursor-default text-waypoint-blue" : "text-stone-500 hover:text-stone-700"
                            )}
                        >
                            <Cog6ToothIcon className="inline h-5 w-5" />
                            <p>Settings</p>
                        </Link>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={HELP_CENTER_URL}
                            className="text-center text-xs text-stone-500 hover:text-stone-700"
                        >
                            <QuestionMarkCircleIcon className="inline h-5 w-5" />
                            <p>Help</p>
                        </a>
                        <button
                            className="flex flex-col items-center text-center text-xs text-stone-500 hover:text-stone-700"
                            onClick={() => {
                                logout();
                            }}
                        >
                            <ArrowRightStartOnRectangleIcon className="inline h-5 w-5" />
                            <span>Sign out</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
