import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function FmcsaLogoInvertedIcon(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props} viewBox="0 0 145 147">
            <g transform="translate(0.000000,147.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                <path
                    d="M601 1458 c-101 -14 -215 -64 -305 -132 -102 -78 -167 -158 -221
-271 -97 -203 -99 -421 -6 -620 98 -208 262 -343 489 -401 83 -22 111 -25 192
-20 115 7 192 26 285 70 184 87 312 232 381 431 26 74 28 92 28 225 0 129 -3
153 -26 223 -82 246 -274 421 -532 487 -84 21 -176 24 -285 8z m351 -93 c234
-81 409 -303 433 -551 8 -78 -3 -117 -50 -172 -41 -49 -99 -74 -180 -80 -62
-4 -78 -1 -121 21 -61 31 -117 91 -134 143 -14 42 -16 78 -9 249 4 108 3 112
-29 177 -50 103 -131 167 -256 203 -31 9 -56 19 -53 21 14 14 107 23 207 19
92 -3 130 -9 192 -30z m-384 -68 c126 -58 180 -239 108 -361 -36 -61 -60 -79
-189 -141 -132 -64 -173 -102 -214 -202 -36 -88 -31 -220 13 -328 7 -17 4 -17
-22 5 -72 59 -144 178 -180 295 -28 93 -26 260 5 350 38 112 99 220 160 281
129 130 204 154 319 101z m128 -725 c21 -11 66 -41 99 -66 97 -76 155 -99 244
-100 65 0 86 4 144 31 37 18 92 55 122 84 67 63 70 49 15 -60 -82 -163 -219
-284 -384 -337 -70 -23 -99 -27 -216 -28 -117 -1 -145 2 -208 23 -140 46 -178
94 -176 222 1 124 55 203 164 243 57 21 142 15 196 -12z"
                />
            </g>
        </SvgIcon>
    );
}
