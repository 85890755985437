import type { MotorCarrier } from "../MotorCarrier";
import { Mcs150HistoryEntry } from "./Mcs150HistoryEntry";

export class Mcs150History {
    // ========================================================================
    static sort(inspections: Mcs150HistoryEntry[], order: "ASC" | "DESC"): Mcs150HistoryEntry[] {
        const sortFn = Mcs150HistoryEntry.sorter(order);
        return inspections.sort(sortFn);
    }

    #carrier: MotorCarrier;
    #dates = new Set<string>();
    #entries: Mcs150HistoryEntry[];

    constructor(carrier: MotorCarrier, raw: Mcs150HistoryEntry.Raw[]) {
        this.#carrier = carrier;

        this.#entries = raw
            .filter((entry) => {
                if (!entry.Mcs150Date) return null;
                const dateString = new Date(entry.Mcs150Date).toISOString();
                if (this.#dates.has(dateString)) return null;
                this.#dates.add(dateString);
                return entry;
            })
            .map((entry) => new Mcs150HistoryEntry(carrier, entry));

        this.sort();
    }

    // ========================================================================
    sort(order: "ASC" | "DESC" = "DESC"): void {
        Mcs150History.sort(this.#entries, order);
    }

    // ========================================================================
    array(): Mcs150HistoryEntry[] {
        return Array.from(this.#entries);
    }

    // ========================================================================
    json(): Mcs150HistoryEntry.Raw[] {
        return this.#entries.map((entry) => entry.json());
    }

    // ========================================================================
    raw(): Mcs150HistoryEntry.Raw[] {
        return this.#entries.map((entry) => entry.raw());
    }
}

export namespace Mcs150History {}
