import { DateTime } from "@deathstar/reuse";
import { classNames } from "@deathstar/ui";
import { Link as MuiLink, Typography } from "@material-ui/core";
import React from "react";
import { Context } from "./Context";

export function Footer({ className, gray }: { className?: string; gray?: boolean }): JSX.Element {
    return (
        <>
            <PrintFooter className={className} />
            <WebFooter className={className} gray={gray} />
        </>
    );
}

function PrintFooter({ className }: { className?: string }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const from = motorCarrier.dateRange.from.format("mm/dd/YY");
    const to = motorCarrier.dateRange.to.format("mm/dd/YY");

    return (
        <div className={classNames("hidden h-full items-center justify-between bg-[#214182] px-4 text-white print:flex", className)}>
            <Typography>Report generated {DateTime.today.format("mm/dd/YYYY")} for Navigator Truck Insurance Agency</Typography>
            <Typography>
                Report date range: {from} - {to}
            </Typography>
        </div>
    );
}

function WebFooter({ gray, className }: { gray?: boolean; className?: string }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const from = motorCarrier.dateRange.from.format("dd Mmm, YYYY");
    const to = motorCarrier.dateRange.to.format("dd Mmm, YYYY");

    return (
        <div
            className={classNames(
                "flex flex-col gap-4 px-8 py-4 text-gray-400 print:hidden print:hidden",
                {
                    "bg-gray-100": gray,
                },
                className
            )}
        >
            <div className={classNames("flex justify-between")}>
                <Typography>
                    Report Range: {from} - {to}
                </Typography>
                <div>
                    <Link
                        href={`https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${motorCarrier.dot}`}
                    >
                        <span className="text-gray-400 underline">Company Snapshot</span>
                    </Link>
                    <span className="px-3">|</span>
                    <Link href={`https://ai.fmcsa.dot.gov/SMS/Carrier/${motorCarrier.dot}/Overview.aspx?FirstView=True`}>
                        <span className="text-gray-400 underline">SMS</span>
                    </Link>
                    <span className="px-3">|</span>
                    <Link
                        href={`https://li-public.fmcsa.dot.gov/LIVIEW/pkg_carrquery.prc_carrlist?n_dotno=${motorCarrier.dot}&s_prefix=MX&s_prefix=LIVIEW`}
                    >
                        <span className="text-gray-400 underline">Licensing and Insurance</span>
                    </Link>
                </div>
                <Typography>Report Generated: {DateTime.today.format("mm/dd/YYYY")}</Typography>
            </div>
        </div>
    );
}

function Link({ href, children }: { href: string; children: React.ReactNode }): JSX.Element {
    return (
        <MuiLink className="text-gray-400 underline hover:text-gray-600" href={href} target="_blank">
            {children}
        </MuiLink>
    );
}
