import { CircularProgress } from "@material-ui/core";

export default function SplashScreen() {
    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center gap-2">
            <CircularProgress size="4rem" classes={{ circle: "text-navigator-primary" }} />
            <p className="text-xl text-stone-700">Loading...</p>
        </div>
    );
}
