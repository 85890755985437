import { MotorCarrier } from "@deathstar/motor-carrier";
import { classNames, DataTable, fuzzyFilter } from "@deathstar/ui";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import React from "react";
import { animated, useSpring } from "react-spring";
import { Panel } from "../../Components/Panel";
import { PrintableTable } from "../../Components/PrintableTable";
import { Section } from "../../Components/Section";
import { TitleGroup } from "../../Components/TitleGroup";
import { Context } from "../../Context";
import { columns } from "./Columns";
import { Table as TopDriverScoreContributorsTable } from "./Drivers";
import { Table as TopTractorScoreContributorsTable } from "./Tractors";
import { Table as TopTrailerScoreContributorsTable } from "./Trailers";

const AnimatedDataTable = animated(DataTable<MotorCarrier.PrimaryScoreContributor>);

function Table({ totalItemsPerTable }: { totalItemsPerTable: number }): JSX.Element {
    return (
        <>
            <WebTable className="print:hidden" totalItemsPerTable={totalItemsPerTable} />
            <PrintTable className="hidden print:block" totalItemsPerTable={totalItemsPerTable} />
        </>
    );
}

function WebTable({ totalItemsPerTable, className }: { totalItemsPerTable: number; className?: string }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const data = React.useMemo(() => motorCarrier.getPrimaryScoreContributors(totalItemsPerTable), [motorCarrier, totalItemsPerTable]);

    const table = useReactTable({
        data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: fuzzyFilter,
    });
    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    return <AnimatedDataTable className={className} style={spring} table={table} />;
}

export function PrintTable({ totalItemsPerTable, className }: { totalItemsPerTable: number; className?: string }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    const data = React.useMemo(
        () =>
            motorCarrier.getPrimaryScoreContributors(totalItemsPerTable).map((cont) => ({
                ...cont,
                key: `${cont.VIN}-${cont.License}`,
                VIN: cont.VIN.slice(-4),
                "% of Total Score": cont.PercentOfTotalScoreFormatted,
                "Total Violations": cont.TotalViolations,
            })),
        [motorCarrier, totalItemsPerTable]
    );

    return (
        <div className={className}>
            <PrintableTable columnHeaders={["VIN", "Year", "Make", "License", "% of Total Score", "Total Violations"]} data={data} />
        </div>
    );
}

export function PrimaryScoreContributorsTable(): JSX.Element {
    return (
        <>
            <WebVersion className="print:hidden" />
            <PrintVersion className="hidden print:block" />
        </>
    );
}

function Title(): JSX.Element {
    return (
        <TitleGroup
            title="Top Score Contributors"
            description="This insight helps identify equipment and drivers that contribute the most points toward CSA scores. It can assist you with determining specific maintenance issues like brakes, lighting and tires, or consistent driver issues like inaccurate logs, speeding or mobile device use."
        />
    );
}

function PrintVersion({ className }: { className?: string }): JSX.Element {
    const [totalItemsPerTable, _setTotalItemsPerTable] = React.useState(10);

    return (
        <Section className={classNames(className, "print:bg-white")}>
            <section data-page-break="after">
                <TitleGroup
                    title="Top Score Contributors"
                    description="This insight helps identify equipment and drivers that contribute the most points toward CSA scores. It can assist you with determining specific maintenance issues like brakes, lighting and tires, or consistent driver issues like inaccurate logs, speeding or mobile device use."
                    className="mb-4"
                />
                <Table totalItemsPerTable={totalItemsPerTable} />
            </section>

            <section data-page-break="after">
                <TitleGroup title="Top Score Contributors (Drivers)" className="mb-4" />
                <TopDriverScoreContributorsTable totalItemsPerTable={totalItemsPerTable} />
            </section>

            <section data-page-break="after">
                <TitleGroup title="Top Score Contributors (Tractors)" className="mb-4" />
                <TopTractorScoreContributorsTable totalItemsPerTable={totalItemsPerTable} />
            </section>

            <section data-page-break="after">
                <TitleGroup title="Top Score Contributors (Trailers)" className="mb-4" />
                <TopTrailerScoreContributorsTable totalItemsPerTable={totalItemsPerTable} />
            </section>
        </Section>
    );
}

function WebVersion({ className }: { className?: string }): JSX.Element {
    const [activeTab, setActiveTab] = React.useState(0);
    const [totalItemsPerTable, _setTotalItemsPerTable] = React.useState(10);

    return (
        <Section className={className}>
            <Title />

            <div className="flex gap-8">
                <Paper square elevation={0} className="mt-14">
                    <Tabs
                        value={activeTab}
                        orientation="vertical"
                        className="place-center"
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(_, value: number) => {
                            setActiveTab(value);
                        }}
                        aria-label="disabled tabs example"
                    >
                        <Tab label="All" />
                        <Tab label="Drivers" />
                        <Tab label="Tractors" />
                        <Tab label="Trailers" />
                    </Tabs>
                </Paper>

                <Panel panelIndex={0} activeTabIndex={activeTab} useHidden>
                    <Table totalItemsPerTable={totalItemsPerTable} />
                </Panel>

                <Panel panelIndex={1} activeTabIndex={activeTab} useHidden>
                    <TopDriverScoreContributorsTable totalItemsPerTable={totalItemsPerTable} />
                </Panel>

                <Panel panelIndex={2} activeTabIndex={activeTab} useHidden>
                    <TopTractorScoreContributorsTable totalItemsPerTable={totalItemsPerTable} />
                </Panel>

                <Panel panelIndex={3} activeTabIndex={activeTab} useHidden>
                    <TopTrailerScoreContributorsTable totalItemsPerTable={totalItemsPerTable} />
                </Panel>
            </div>
        </Section>
    );
}