export class NumberFormatter {
    static round(value: number, places = 2): number {
        const factor = parseInt([1, ...new Array<number>(places).fill(0)].join(""));
        return Math.round(value * factor) / factor;
    }

    static getFormatter(options: Intl.NumberFormatOptions) {
        return new Intl.NumberFormat("en-US", options);
    }

    static Plain = new Intl.NumberFormat("en-US", {
        // @ts-expect-error not in types lib yet
        trailingZeroDisplay: "stripIfInteger",
    });

    static Decimal = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 1,
    });

    static Currency = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        // @ts-expect-error not in types lib yet
        trailingZeroDisplay: "stripIfInteger",
    });

    static NonDollarSignCurrency = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        // @ts-expect-error not in types lib yet
        trailingZeroDisplay: "stripIfInteger",
    });

    static Percent = new Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: 2,
    });

    static #addCommas(str: string): string {
        let s = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        while ((s[0] === "0" && s.length > 1) || s[0] === ",") {
            s = s.substring(1);
        }
        return s;
    }

    static #parseInput(str: string, float = false): number {
        if (!str) return 0;

        const s = str.replace(/[^\d.KkMm]/g, "");
        let n = 0;
        if (s.search(/[Mm]/g) > -1) {
            const split = s.replace(/[Kk]/g, "").split(/[Mm]/);
            n = parseFloat(split[0]) * 1000000;
            if (float === false) {
                n = Math.round(n);
            }
        } else if (s.search(/[Kk]/g) > -1) {
            const split = s.replace(/[Mm]/g, "").split(/[Kk]/);
            n = parseFloat(split[0]) * 1000;
            if (float === false) {
                n = Math.round(n);
            }
        } else {
            n = float ? parseFloat(s) : parseInt(s);
        }
        if (isNaN(n)) return 0;
        return n;
    }

    static format(value: number, type: "%" | "$" | "", decimalPlaces = 0): string {
        let string = "";
        if (value !== null) {
            string = value.toString();
        }
        let valueStr = "0";
        let decimals = "";
        if (string.length) {
            const decimalIndex = string.indexOf(".");
            decimals =
                decimalPlaces > 0
                    ? decimalIndex > -1
                        ? string.substring(decimalIndex, decimalIndex + decimalPlaces + 1).padEnd(decimalPlaces + 1, "0")
                        : "." + Array(decimalPlaces).fill("0").join("")
                    : "";

            // parse it into a float and remove the symbols if necessary
            const value = NumberFormatter.#parseInput(string, true);

            valueStr = String(value).split(".")[0];
        }

        switch (type) {
            case "$":
                return `$${NumberFormatter.#addCommas(valueStr)}${decimals}`;
            case "%":
                return `${NumberFormatter.#addCommas(valueStr)}${decimals}%`;
            case "":
                return `${NumberFormatter.#addCommas(valueStr)}${decimals}`;
            default:
                return `${NumberFormatter.#addCommas(valueStr)}${decimals}`;
        }
    }

    static formatCurrencyShort(fullDollarValue: number): string {
        if (!fullDollarValue) return "";
        const [dollarStr, _cents] = fullDollarValue.toString().split(".");
        const dollars = parseInt(dollarStr);
        if (dollars < 1000) {
            return NumberFormatter.Currency.format(dollars);
        }
        const amountWithComma = NumberFormatter.Plain.format(dollars);
        if (amountWithComma.split(",").length === 2) {
            const [thousands, hundreds] = amountWithComma.split(",");
            if (dollars >= 1100 && dollars < 10000) {
                if (parseInt(hundreds.charAt(1)) >= 5) {
                    const decimal = parseInt(hundreds.charAt(0)) + 1;
                    if (decimal === 10) {
                        return `$${parseInt(thousands) + 1}k`;
                    }
                    return `$${thousands}.${decimal}k`;
                }

                const hundredth = hundreds.charAt(0);
                if (parseInt(hundredth) > 0) {
                    return `$${thousands}.${hundreds.charAt(0)}k`;
                }

                return `$${thousands}k`;
            }

            if (parseInt(hundreds.charAt(0)) >= 5) {
                return `$${parseInt(thousands) + 1}k`;
            }

            return `$${thousands}k`;
        } else if (amountWithComma.split(",").length === 3) {
            const [millions, thousands, _hundreds] = amountWithComma.split(",");

            const hundrendThousandth = parseInt(thousands.charAt(0));
            if (hundrendThousandth > 0) {
                return `$${millions}.${thousands}M`;
            }

            return `$${millions}M`;
        }

        return "$??";
    }
}
