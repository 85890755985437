import { SecondaryButton } from "@deathstar/ui";
import { Link } from "react-router-dom";
import { HELP_CENTER_URL } from "../../util/constants";
import Logo from "../logo/Logo";

export default function BaseErrorScreen({
    title,
    description,
    primaryAction,
    image,
    header,
}: {
    title: string;
    description: string;
    primaryAction: React.ReactNode;
    image: React.ReactNode;
    header?: boolean;
}) {
    return (
        <div className="overflow-x-clip p-4 lg:p-12">
            {header ? (
                <div>
                    <Link to="/" className="block text-xl text-navigator-primary">
                        <Logo />
                    </Link>
                </div>
            ) : null}
            <div className="flex justify-evenly">
                <div className="mt-16 flex max-w-lg flex-col lg:mt-32">
                    <div className="space-y-8">
                        <p className="text-3xl font-bold text-navigator-primary-dark lg:text-5xl">{title}</p>
                        <p className="text-stone-500">{description}</p>

                        {primaryAction}
                    </div>

                    <div className="mt-32 flex flex-col justify-end space-y-4">
                        <p>Having trouble? </p>
                        <div className="flex items-center gap-4">
                            <a target="_blank" rel="noreferrer" href={HELP_CENTER_URL} className="block w-max">
                                <SecondaryButton>Tutorials and help</SecondaryButton>
                            </a>
                            <span className="text-stone-600">or</span>
                            <a
                                href="https://help.navigatortruckinsurance.com/hc/en-us/articles/16035359687579-Contact-Us"
                                target="_blank"
                                rel="noreferrer"
                                className="block w-max"
                            >
                                <SecondaryButton>Contact us</SecondaryButton>
                            </a>
                        </div>
                    </div>
                </div>
                {image}
            </div>
        </div>
    );
}
