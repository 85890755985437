import { MonthlyReportHelper } from "@deathstar/reuse";
import { MonthlyReport, MonthlyReportData, MonthlyReportQueryResult } from "@deathstar/types/northstar";
import { Alert, classNames, PrimaryButton, printElement, useSnackbar } from "@deathstar/ui";
import { CheckCircleIcon, ChevronLeftIcon, CurrencyDollarIcon, MapIcon, PrinterIcon, TruckIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import { NotFoundError } from "../../../api/util/exceptions";
import Loader from "../../../components/loader/Loader";
import { queryClient } from "../../../util/queryClient";
import PaymentDialog from "../../billing/PaymentDialog";
import { MonthlyReportCalculations } from "./MonthlyReportCalculations";
import { MonthlyReportStatic } from "./MonthlyReportStatic";
import { MonthlyReportUnitsTable } from "./MonthlyReportUnitsTable";

const COUNTDOWN = 5;

interface Step {
    id: string;
    label: string;
}

export default function MonthlyReportView() {
    const navigate = useNavigate();

    const accountId = useAccountId();
    const { policyId, year: yearStr, month: monthStr, step } = useParams();
    const month = monthStr ? parseInt(monthStr, 10) : 0;
    const year = yearStr ? parseInt(yearStr, 10) : 0;

    const m = moment()
        .month(month - 1)
        .year(year);

    const { data: report, isLoading } = api.monthlyReports.useFindForMonth(accountId!, policyId!, year, month, {
        enabled: !!policyId && !!year && !!month,
        staleTime: 60_000,
        retry: (count, e) => (e instanceof NotFoundError ? false : count < 3),
    });

    const data = useMemo<MonthlyReportData | null>(() => {
        if (report && report.layers.length > 0) {
            return MonthlyReportHelper.getMonthlyReportData(report.report, report.layers, report.equipment);
        }
        return null;
    }, [report]);

    const ref = useRef<HTMLDivElement>(null);

    const [paying, setPaying] = useState(false);
    const [countdown, setCountdown] = useState(COUNTDOWN);

    const steps = useMemo<Step[]>(() => {
        if (!data) {
            return [];
        }
        const steps: Step[] = [];
        if (data.isUnitReporter) {
            const label =
                data.equipment.activeTractors.length || data.equipment.activeTrailers.length
                    ? "Equipment"
                    : data.equipment.activeDrivers.length
                    ? "Drivers"
                    : "Equipment";
            steps.push({ id: "equipment", label });
        }
        if (data.isMileageReporter || data.isRevenueReporter) {
            steps.push({
                id: "input",
                label: [data.isMileageReporter && "Mileage", data.isRevenueReporter && "Revenue"].filter(Boolean).join(" & "),
            });
        }
        steps.push({ id: "premium", label: "Review" });
        steps.push({ id: "submit", label: "Submit" });
        return steps;
    }, [data]);

    useEffect(() => {
        if (!!year && !!month && steps.length) {
            if (step) {
                if (!steps.some((s) => s.id === step)) {
                    navigate(`./../${steps[0].id}`, { replace: true });
                }
            } else {
                navigate(`./${steps[0].id}`, { replace: true });
            }
        }
    }, [step, navigate, year, month, steps]);

    useEffect(() => {
        if (step === "submit") {
            const int = setInterval(() => {
                setCountdown((c) => {
                    if (c === 1) {
                        clearInterval(int);
                    }
                    return c - 1;
                });
            }, 1000);
            return () => {
                clearTimeout(int);
            };
        }
        return undefined;
    }, [step]);

    const goNext = useCallback(() => {
        if (!data) {
            return;
        }
        navigate(`./../${steps[steps.findIndex((s) => s.id === step) + 1].id}`);
    }, [data, navigate, step, steps]);

    const goBack = useCallback(() => {
        if (!data) {
            return;
        }
        navigate(`./../${steps[steps.findIndex((s) => s.id === step) - 1].id}`);
    }, [data, navigate, step, steps]);

    const submit = api.monthlyReports.useSubmit(accountId!, {
        onSuccess: () => {
            // if (data.endLayer.policy.billMethod === Policy.BillMethod.AGENCY && billingProfile && !billingProfile.automaticBillingEnabled) {
            //     setPaying(true);
            // }
        },
    });

    if (isLoading) {
        return <Loader />;
    }

    if (!report) {
        return (
            <div className="flex w-full flex-col">
                <div className="flex items-center gap-4 bg-waypoint-blue p-4 pb-2 text-white">
                    <div className="flex shrink items-center gap-2 overflow-hidden">
                        <button
                            className="rounded-full p-2 hover:bg-black/10"
                            onClick={() => {
                                if (steps.findIndex((s) => s.id === step) <= 0) {
                                    navigate("../monthly-reports/" + policyId);
                                } else {
                                    goBack();
                                }
                            }}
                        >
                            <ChevronLeftIcon className="h-5 w-5" />
                        </button>
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap text-lg font-medium">
                            {m.format("MMMM YYYY")} report
                        </p>
                    </div>
                </div>
                <div className="flex w-full grow flex-col items-center justify-center text-xl">We could not find this report.</div>
            </div>
        );
    }

    if (!data) {
        return <Loader />;
    }

    return (
        <div className="flex w-full flex-col">
            <div className="hidden">
                <MonthlyReportStatic report={report.report} data={data} ref={ref} />
            </div>
            <div className="flex w-full shrink-0 items-center justify-between gap-2 overflow-hidden bg-waypoint-blue p-4 pb-2 text-white">
                <div className="flex shrink items-center gap-2 overflow-hidden">
                    <button
                        className="rounded-full p-2 hover:bg-black/10"
                        onClick={() => {
                            if (steps.findIndex((s) => s.id === step) <= 0) {
                                navigate("./../../..");
                            } else {
                                goBack();
                            }
                        }}
                    >
                        <ChevronLeftIcon className="h-5 w-5" />
                    </button>
                    <p className="overflow-hidden text-ellipsis whitespace-nowrap text-lg font-medium">{m.format("MMMM YYYY")} report</p>
                </div>
                <div className="flex shrink-0 grow items-center justify-center gap-6 text-sm">
                    {steps.map((s, i) => (
                        <div key={s.id} className={classNames("flex items-center gap-1", step !== s.id && "hidden text-white/80 md:flex")}>
                            <div
                                className={classNames(
                                    "flex h-5 w-5 items-center justify-center rounded-full transition-all",
                                    step === s.id ? "bg-white text-waypoint-blue" : "bg-waypoint-blue"
                                )}
                            >
                                {i + 1}
                            </div>
                            {s.label}
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex items-center justify-between gap-2 px-2">
                <p className="text-xs text-stone-600">Policy #{report.layers[0]?.policy.number}</p>
                <button
                    onClick={() => {
                        if (ref.current) {
                            printElement(ref.current);
                        }
                    }}
                    className="flex items-center gap-1 text-left text-sm text-blue-600 small-caps"
                >
                    <PrinterIcon className="h-3 w-3" />
                    Print report
                </button>
            </div>
            {step === "equipment" && (
                <div className="grow overflow-auto p-4">
                    <MonthlyReportUnitsTable data={data} report={report.report} />
                </div>
            )}
            {step === "input" && (
                <div className="grow overflow-auto p-4">
                    <MonthlyReportInputs report={report.report} data={data} />
                </div>
            )}
            {step === "premium" && (
                <div className="grow overflow-auto p-4">
                    <div className={classNames("flex h-full flex-col items-end justify-between gap-4")}>
                        <MonthlyReportCalculations data={data} />
                    </div>
                </div>
            )}
            {step === "submit" && (
                <div className="grow overflow-auto p-4">
                    <MonthlyReportSubmit report={report.report} />
                </div>
            )}
            <div className="flex flex-col items-center gap-6 border-t p-4 sm:flex-row sm:justify-end">
                <div className="w-full sm:w-40">
                    {step !== "submit" ? (
                        <PrimaryButton
                            className="w-full"
                            onClick={() => {
                                goNext();
                            }}
                        >
                            Continue
                        </PrimaryButton>
                    ) : report.report.isSubmitted || report.report.isFinalized ? (
                        <PrimaryButton disabled className="w-full bg-emerald-600">
                            <CheckCircleIcon className="h-4 w-4" />
                            Submitted
                        </PrimaryButton>
                    ) : (
                        <PrimaryButton
                            className="w-full"
                            onClick={() => {
                                if (countdown === 0) {
                                    submit.mutate({
                                        policyId: policyId!,
                                        year,
                                        month,
                                    });
                                }
                            }}
                            disabled={countdown > 0 || submit.isPending || submit.isSuccess}
                        >
                            {(submit.isPending || submit.isSuccess) && <CircularProgress size="1rem" classes={{ svg: "text-white" }} />}
                            {countdown || "Submit"}
                        </PrimaryButton>
                    )}
                </div>
            </div>
            <PaymentDialog
                open={paying}
                onClose={() => {
                    setPaying(false);
                }}
                defaultValues={{
                    amount: Math.round(data.totalPremium * 100) / 100,
                    description: `${moment()
                        .month(data.month - 1)
                        .year(data.year)
                        .format("MMMM YYYY")} report`,
                }}
            />
        </div>
    );
}

function MonthlyReportInputs({ report, data }: { report: MonthlyReport; data: MonthlyReportData }) {
    const accountId = useAccountId();
    const mileageRef = useRef<HTMLInputElement>(null);
    const revenueRef = useRef<HTMLInputElement>(null);

    const { mutate: _update } = api.monthlyReports.useUpdate(accountId!, report.policyId, report.year, report.month, {
        onMutate: (newData) => {
            if (
                queryClient.getQueryData(api.monthlyReports.queryKeys.findForMonth(accountId!, report.policyId, report.year, report.month))
            ) {
                queryClient.setQueryData<MonthlyReportQueryResult>(
                    api.monthlyReports.queryKeys.findForMonth(accountId!, report.policyId, report.year, report.month),
                    (oldData: MonthlyReportQueryResult | undefined) => {
                        if (!oldData) {
                            // this should never happen since we're checking if the querydata exists first
                            return oldData as unknown as MonthlyReportQueryResult;
                        }
                        return {
                            ...oldData,
                            report: { ...oldData.report, ...newData },
                        };
                    }
                );
            }
            if (mileageRef.current && newData.mileage !== undefined) {
                mileageRef.current.value = newData.mileage.toString();
            }
            if (revenueRef.current && newData.revenue !== undefined) {
                revenueRef.current.value = newData.revenue.toString();
            }
        },
        onError: () => {
            useSnackbar.add("An error occurred while updating your monthly report", { variant: "error" });
            queryClient.invalidateQueries({ queryKey: api.monthlyReports.queryKeys.find(accountId!) });
            if (mileageRef.current) {
                mileageRef.current.value = report.mileage.toString();
            }
            if (revenueRef.current) {
                revenueRef.current.value = report.revenue.toString();
            }
        },
    });

    const update = useMemo(
        () =>
            debounce(
                (values: Partial<Pick<MonthlyReport, "mileage" | "revenue">>) => {
                    _update(values);
                },
                500,
                {}
            ),
        [_update]
    );

    return (
        <div className={classNames("flex h-full w-full flex-col items-center justify-center gap-4")}>
            {data.isRevenueReporter && (
                <p className="text-center lg:text-xl">
                    <CurrencyDollarIcon className="-mt-1 mr-2 inline h-6 w-6 text-waypoint-blue lg:h-8 lg:w-8" />
                    {moment()
                        .month(report.month - 1)
                        .format("MMMM")}
                    's revenue was
                    <input
                        placeholder="0"
                        className="mx-2 inline w-36 rounded border px-2 disabled:cursor-not-allowed disabled:bg-stone-100 disabled:text-stone-600"
                        defaultValue={report.revenue}
                        onChange={(e) => {
                            update({
                                revenue: e.target.value ? Math.max(0, parseInt(e.target.value)) : 0,
                            });
                        }}
                        disabled={report.isSubmitted || report.isFinalized}
                        ref={revenueRef}
                        type="number"
                        min={0}
                    />
                    dollars
                </p>
            )}
            {data.isMileageReporter && (
                <p className="text-center lg:text-xl">
                    <MapIcon className="-mt-1 mr-2 inline h-6 w-6 text-waypoint-blue lg:h-8 lg:w-8" />
                    {moment()
                        .month(report.month - 1)
                        .format("MMMM")}
                    's mileage was
                    <input
                        placeholder="0"
                        className="mx-2 inline w-36 rounded border px-2 disabled:cursor-not-allowed disabled:bg-stone-100 disabled:text-stone-600"
                        defaultValue={report.mileage}
                        onChange={(e) => {
                            update({
                                mileage: e.target.value ? Math.max(0, parseInt(e.target.value)) : 0,
                            });
                        }}
                        disabled={report.isSubmitted || report.isFinalized}
                        ref={mileageRef}
                        type="number"
                        min={0}
                    />
                    miles
                </p>
            )}
            {data.isMileageReporter && (
                <div className="flex items-center gap-1 text-sm">
                    <TruckIcon className="h-4 w-4 text-stone-400" />
                    <span>
                        {data.equipment.activeTractors.length.toLocaleString("en-us", { maximumFractionDigits: 0 })}{" "}
                        <span className="text-stone-400">units</span>
                    </span>
                </div>
            )}
        </div>
    );
}

function MonthlyReportSubmit({ report }: { report: MonthlyReport }) {
    return (
        <div className={classNames("flex h-full flex-col items-center justify-center gap-4")}>
            {!report.isSubmitted && !report.isFinalized && (
                <Alert variant="warning" className="max-w-prose [&>p]:text-lg">
                    By submitting this report you are agreeing that the information presented is accurate and truthful to the best of your
                    knowledge.
                </Alert>
            )}
            {report.isFinalized ? (
                <div className="max-w-prose rounded border border-emerald-600 bg-emerald-50 p-4 text-emerald-700">
                    <p className="text-sm">{moment(report.finalizedDate).format("MM/DD/YY")}</p>
                    <p>Your report was reviewed and finalized.</p>
                </div>
            ) : (
                report.isSubmitted && (
                    <div className="max-w-prose rounded border border-blue-600 bg-blue-50 p-4 text-blue-700">
                        <p className="text-sm">{moment(report.submittedDate).format("MM/DD/YY")}</p>
                        <p>Your report was successfully submitted and is awaiting review by your account manager.</p>
                    </div>
                )
            )}
        </div>
    );
}
