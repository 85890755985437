import { classNames } from "@deathstar/ui";
import { forwardRef } from "react";

export const ActionButton = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(({ className, ...props }, ref) => {
    return (
        <button
            className={classNames(
                "flex items-center gap-2 rounded-lg border border-stone-300 bg-white px-2 py-1 text-sm font-medium text-stone-600 shadow-sm transition-all disabled:opacity-60 enabled:hover:text-navigator-primary enabled:hover:shadow-md",
                className
            )}
            type="button"
            {...props}
            ref={ref}
        />
    );
});
