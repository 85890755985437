/* eslint-disable @typescript-eslint/no-explicit-any */
import { classNames } from "@deathstar/ui";
import { Tooltip } from "@material-ui/core";
import { ExtendedFeature, ExtendedFeatureCollection, GeoGeometryObjects, geoPath, GeoPath, GeoProjection } from "d3-geo";
import React, { Fragment, useMemo } from "react";
import * as topojson from "topojson-client";
import { type STATE_FIPS } from "./constants";
import { MapLocationType } from "./types";
import { type useMap, MapController } from "./useMap";
import { coordinatesToScreenPosition, getStateById, milesToPixels, milesToRadius } from "./utils";

export type { MapController };

type LocationClickHandler = (lat: number, lng: number) => void;

const PRIMARY_TERMINAL_FILL = "#467ed2"; /* Blue */
const PRIMARY_TERMINAL_STROKE = "#adc6eb"; /* Blue */
const OTHER_TERMINAL_FILL = "#ff8000"; /* Darker Orangish */
const OTHER_TERMINAL_STROKE = "#ffb366"; /* Orangish */
export interface USMapOptions {
    getStateLabel?(state: (typeof STATE_FIPS)[string]): string;
    getStateColor?(state: (typeof STATE_FIPS)[string]): string;
    fontSize?: number;
    fontFamily?: string;
}

export interface MapProps {
    svgProps?: React.ClassAttributes<SVGSVGElement> & React.HTMLAttributes<SVGSVGElement>;
    backgroundColor?: string;
    mapController: ReturnType<typeof useMap>;
    options?: USMapOptions;
    legend?: {
        position: [number, number];
        items: LegendItem[];
    };
    selectedLatitude: number | null;
    className?: string;
}

const SMALL_STATES = ["33", "50", "44", "25", "09", "34", "10", "24", "11"]; // ["NH", "VT", "RI", "MA", "CT", "NJ", "DE", "MD", "DC"]
const SMALL_STATE_LABEL_POS = { lat: 39.94026642716271, lng: -68.83447821567968 }; // just off east coast

export function Map({ backgroundColor, mapController, svgProps, options, legend, selectedLatitude, className }: MapProps) {
    const { dragState, mapData, projection, ref, height, width, onMouseDown, locations } = mapController;

    const bgColor = useMemo(() => backgroundColor || "white", [backgroundColor]);
    const path = useMemo(() => geoPath(projection), [projection]);

    const contiguousStates = mapData
        ? (topojson.feature(mapData, mapData.objects.states) as unknown as ExtendedFeatureCollection).features.filter((x) => {
              return ![
                  "03",
                  "07",
                  "14",
                  "43",
                  "52",
                  "64",
                  "68",
                  "70",
                  "74",
                  "81",
                  "84",
                  "86",
                  "67",
                  "89",
                  "71",
                  "76",
                  "95",
                  "79",
                  "60",
                  "66",
                  "69",
                  "72",
                  "78",
                  "02",
                  "15",
              ].includes(x.id as string);
          })
        : [];

    const { style, ...otherSvgProps } = svgProps || {};

    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            className={classNames("d3-svg print:max-w-[750px]", className)}
            style={{
                backgroundColor: bgColor,
                userSelect: "none",
                cursor: dragState.dragging ? "grabbing" : "grab",
                ...style,
            }}
            {...otherSvgProps}
            onMouseDown={onMouseDown}
        >
            {contiguousStates.map((state) => (
                <State
                    key={state.id}
                    path={path}
                    data={state as USStateProps["data"]}
                    backgroundColor={bgColor}
                    colors={{
                        fill: options?.getStateColor?.(getStateById(state.id as string)) || "#f7f9f6",
                    }}
                    label={options?.getStateLabel?.(getStateById(state.id as string)) || getStateById(state.id as string).abbr}
                />
            ))}
            <ScaleBar projection={projection} mapWidth={width} />
            {legend && <Legend items={legend.items} position={legend.position} />}
            {locations?.map((location, i) => (
                <LocationMarker
                    key={i}
                    lng={location.lng}
                    lat={location.lat}
                    rings={location.rings}
                    projection={projection}
                    type={location.type}
                    size={location.size}
                    color={location.color}
                    onLocationClick={location.onLocationClick}
                    tooltip={location.tooltip}
                    selected={location.lat === selectedLatitude}
                />
            ))}
        </svg>
    );
}

interface LocationMarkerProps {
    type?: MapLocationType;
    lng: number;
    lat: number;
    projection: GeoProjection;
    rings?: number[];
    size?: number;
    color?: string;
    onLocationClick?: LocationClickHandler;
    selected?: boolean;
    tooltip?: string;
}

function LocationMarker(options: LocationMarkerProps) {
    const { lng, lat, rings, projection, type, onLocationClick, size, tooltip, selected } = options;
    const results = projection([lng, lat]);

    const clickHandler = React.useCallback(() => {
        onLocationClick?.(lat, lng);
    }, [onLocationClick, lat, lng]);

    if (!results) return null;
    const [x, y] = results;

    return (
        <Tooltip title={tooltip || ""}>
            <g>
                {type === MapLocationType.PrimaryTerminal ? (
                    <PrimaryTerminalStar x={x} y={y} onClick={clickHandler} />
                ) : type === MapLocationType.Terminal ? (
                    <TerminalCircle x={x} y={y} onClick={clickHandler} />
                ) : type === MapLocationType.Crash ? (
                    <CrashExplosion x={x} y={y} onClick={clickHandler} size={size} selected={selected} />
                ) : (
                    <DefaultCircle x={x} y={y} {...options} onClick={clickHandler} />
                )}
                {rings
                    ? rings.map((miles) => {
                          const textY = y - milesToPixels(miles, projection) - 2;
                          return (
                              <Fragment key={miles}>
                                  <circle cx={x} cy={y} r={milesToPixels(miles, projection)} stroke="#000" strokeWidth={0.5} fill="none" />
                                  <rect x={x - 20} y={textY - 10} width={40} height={12} fill="#FFF9" rx={2}></rect>
                                  <text x={x} y={textY} textAnchor="middle" fontSize="0.75rem" fontFamily="monospace">
                                      {miles}mi
                                  </text>
                              </Fragment>
                          );
                      })
                    : null}
            </g>
        </Tooltip>
    );
}

function ScaleBar({ projection, mapWidth }: { projection: GeoProjection; mapWidth: number }) {
    const projectionA = projection([milesToRadius(300), 0]);
    const projectionB = projection([0, 0]);
    if (!projectionA || !projectionB) return null;

    const distance = projectionA[0] - projectionB[0];

    const rightMargin = 30;
    const topMargin = 30;
    const x1 = mapWidth - rightMargin - distance;
    const x2 = mapWidth - rightMargin;

    return (
        <g>
            <line x1={x1} x2={x2} y1={topMargin} y2={topMargin} stroke="#000" strokeWidth="1px" />
            <line x1={x1} x2={x1} y1={topMargin} y2={topMargin + 6} stroke="#000" strokeWidth="1px" />
            <line x1={x2} x2={x2} y1={topMargin} y2={topMargin + 6} stroke="#000" strokeWidth="1px" />
            <text x={x2} y={topMargin - 2} fontSize="10px" fill="#333" textAnchor="end">
                300 mi
            </text>
        </g>
    );
}

export type LegendItem = (props: { x: number; y: number }) => JSX.Element;
interface LegendProps {
    items: LegendItem[];
    position: [number, number];
}
function Legend({ items, position }: LegendProps) {
    return (
        <g>
            {items.map((Item, index) => {
                return (
                    <g key={index}>
                        <Item x={position[0]} y={position[1] + index * 20} />
                    </g>
                );
            })}
        </g>
    );
}

export function ColorLegendElement({ color, x, y }: { color: string; x: number; y: number }) {
    return <rect rx="4" x={x} y={y} width={12} transform="translate(-6, -6)" height={12} fill={color} stroke="#444" strokeWidth="1px" />;
}

export function DefaultCircle({
    x,
    y,
    type,
    size,
    color,
    onClick,
    selected,
}: LocationMarkerProps & { x: number; y: number; onClick?: () => void }): JSX.Element {
    const [isHovering, setIsHovering] = React.useState(false);

    return (
        <circle
            className="hover:cursor-pointer"
            cx={x}
            cy={y}
            r={size || 5}
            fill={
                selected
                    ? "lightblue"
                    : color
                    ? color
                    : type === MapLocationType.CleanInspection
                    ? "green"
                    : type === MapLocationType.OutOfService
                    ? "red"
                    : "orange"
            }
            onClick={onClick}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            stroke={(Boolean(onClick) && isHovering) || selected ? "white" : "black"}
            strokeWidth={selected ? "2" : "1"}
        />
    );
}

export function PrimaryTerminalStar({ x, y, onClick }: { x: number; y: number; onClick?: () => void }) {
    const [_isHovering, setIsHovering] = React.useState(false);
    const size = 16;
    const scale = size / 12;

    return (
        <path
            className={classNames({
                "hover:cursor-pointer": Boolean(onClick),
            })}
            transform={`translate(${x - 12 * scale}, ${y - 12 * scale}), scale(${scale})`}
            fill={PRIMARY_TERMINAL_FILL}
            stroke={PRIMARY_TERMINAL_STROKE}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
            onClick={onClick}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        />
    );
}

export function CrashExplosion({
    x,
    y,
    onClick,
    size: incomingSize,
    selected,
}: {
    x: number;
    y: number;
    onClick?: () => void;
    size?: number;
    selected?: boolean;
}): JSX.Element {
    const [isHovering, setIsHovering] = React.useState(false);

    const size = 16;
    const scale = size / 12;

    const { translateX, translateY, scaleFactor } = React.useMemo(() => {
        if (incomingSize === 5) {
            return {
                translateX: x - 14 * scale,
                translateY: y - 12 * scale,
                scaleFactor: (0.0094 + 0.01) * scale,
            };
        }

        if (incomingSize === 4) {
            return {
                translateX: x - 12 * scale,
                translateY: y - 10 * scale,
                scaleFactor: (0.0094 + 0.0075) * scale,
            };
        }

        if (incomingSize === 3) {
            return {
                translateX: x - 10 * scale,
                translateY: y - 9 * scale,
                scaleFactor: (0.0094 + 0.005) * scale,
            };
        }

        if (incomingSize === 2) {
            return {
                translateX: x - 8 * scale,
                translateY: y - 7 * scale,
                scaleFactor: (0.0094 + 0.0025) * scale,
            };
        }

        return {
            translateX: x - 6 * scale,
            translateY: y - 5 * scale,
            scaleFactor: 0.0094 * scale,
        };
    }, [incomingSize, scale, x, y]);

    return (
        <svg
            className={classNames({
                "hover:cursor-pointer": Boolean(onClick),
            })}
            width="1280"
            height="1280"
            viewBox="0 0 1280 1280"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <path
                transform={`translate(${translateX}, ${translateY}), scale(${scaleFactor})`}
                d="M682.7 85.125L841.1 416.725L1194.4 97.925L1013.8 554.325L1210.5 501.225L1024 766.325L1194.3 825.025L1020.3 907.725L1189.6 1198.03L867.7 998.925L798.7 1214.32L696.5 984.025L406.5 1201.82L494.7 953.325L95.0004 1033.73L394.2 811.025L78.9004 584.025L438.5 615.125L106 105.225L640 455.125L682.7 85.125Z"
                fill={selected ? "lightblue" : "#FFA000"}
                stroke={isHovering || selected ? "white" : "#F44336"}
                strokeWidth={isHovering || selected ? "80" : "30"}
            />
        </svg>
    );
}

export function TerminalCircle({ x, y, onClick }: { x: number; y: number; onClick?: () => void }) {
    const [_isHovering, setIsHovering] = React.useState(false);

    return (
        <circle
            className={classNames({
                "hover:cursor-pointer": Boolean(onClick),
            })}
            cx={x}
            cy={y}
            r={4}
            fill={OTHER_TERMINAL_FILL}
            stroke={OTHER_TERMINAL_STROKE}
            strokeWidth="1.5"
            onClick={onClick}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        />
    );
}

interface USStateProps {
    data: ExtendedFeature<
        GeoGeometryObjects,
        {
            [name: string]: any;
        }
    >;
    path: GeoPath;
    colors?: {
        fill?: string;
        label?: string;
    };
    label: string;
    backgroundColor: string;
}
function State({ data, path, colors, label }: USStateProps) {
    const smallStateIndex = SMALL_STATES.indexOf(data.id as string);
    const [x, y, x2, y2] = useMemo(() => {
        const center = path.centroid(data);
        if (smallStateIndex > -1) {
            const labelPosition = coordinatesToScreenPosition(SMALL_STATE_LABEL_POS, path.projection());
            return [labelPosition[0], labelPosition[1] + smallStateIndex * 18, center[0], center[1]];
        } else {
            let xOffset = 0;
            let yOffset = 0;

            if (data.id === "21") xOffset = 2.5; // KY
            if (data.id === "12") xOffset = 12.5; // FL
            if (data.id === "26") {
                // MI
                yOffset = 20;
                xOffset = 7;
            }
            if (data.id === "36") xOffset = 1; // NY
            if (data.id === "22") xOffset = -13; // LA
            return [center[0] + xOffset, center[1] + yOffset, null, null];
        }
    }, [data, path, smallStateIndex]);

    return (
        <g>
            <path className="state" d={path(data)!} stroke="#999" strokeWidth="0.5px" fill={colors?.fill || "#FFF"} />
            <text
                x={x}
                y={y}
                startOffset={smallStateIndex > -1 ? "0%" : "50%"}
                textAnchor={smallStateIndex > -1 ? "start" : "middle"}
                fontFamily="sans-serif"
                fontSize="12"
                fill={smallStateIndex >= 0 ? "black" : "white"}
                className="label"
            >
                {label}
            </text>
            {smallStateIndex > -1 && <line x1={x - 3} y1={y - 5} x2={x2!} y2={y2!} stroke="#666" strokeWidth={0.5} />}
        </g>
    );
}
