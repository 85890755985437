import { forwardRef, SVGProps } from "react";

const SteeringWheelIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    return (
        <svg {...props} ref={ref} viewBox="0 0 24 24" width="24" height="24" strokeWidth="1.5" stroke="currentColor" fill="none">
            <ellipse cx="12" cy="12" rx="9" ry="9" />
            <ellipse cx="12" cy="12" rx="2" ry="2" fill="currentColor" stroke="none" />
            <path d="M 12.008 12.014 L 11.993 21.014" />
            <path d="M 4 7.973 C 3.866 13.467 19.866 13.505 20 8.014" />
        </svg>
    );
});

export default SteeringWheelIcon;
