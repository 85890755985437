import { InputField, PrimaryButton } from "@deathstar/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Dialog } from "../../components/dialog/Dialog";

export default function OOProgramInviteForm({
    open,
    onClose,
    onSubmit,
    defaultValues,
}: {
    open: boolean;
    onClose(): void;
    onSubmit(values: OOProgramInviteFormValues): void;
    defaultValues?: Partial<OOProgramInviteFormValues>;
}) {
    const form = useForm<OOProgramInviteFormValues>({
        defaultValues: {
            name: "",
            phone: "",
            email: "",
            vin: "",
            license: "",
            ...defaultValues,
        },
    });

    useEffect(() => {
        if (open) {
            form.reset({
                name: "",
                phone: "",
                email: "",
                vin: "",
                license: "",
                ...defaultValues,
            });
        }
    }, [defaultValues, form, open]);

    return (
        <Dialog open={open} onClose={onClose} className="w-full p-4 md:max-w-md">
            <div className="space-y-4">
                <h2 className="text-xl font-bold">Invite to your owner operator program</h2>
                <p className="text-sm text-stone-600">
                    Navigator will reach out to this Owner Operator within 48 hours with instructions for purchasing aninsurance policy. If
                    the owner operator purchases a policy through Navigator, their coverage will appear in Waypoint.
                </p>
                <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4">
                    <InputField
                        classes={{ inputContainer: "py-2" }}
                        label="Name"
                        {...form.register("name", { required: true })}
                        required
                        disabled={!!defaultValues?.name}
                    />
                    <InputField
                        classes={{ inputContainer: "py-2" }}
                        label="Phone"
                        {...form.register("phone", { required: true })}
                        required
                    />
                    <InputField
                        classes={{ inputContainer: "py-2" }}
                        label="Email"
                        {...form.register("email", { required: true })}
                        required
                    />
                    <InputField classes={{ inputContainer: "py-2" }} label="VIN #" {...form.register("vin")} />
                    <InputField
                        classes={{ inputContainer: "py-2" }}
                        label="License #"
                        {...form.register("license", { required: true })}
                        required
                        disabled={!!defaultValues?.license}
                    />

                    <div className="flex flex-row-reverse">
                        <PrimaryButton type="submit">Invite</PrimaryButton>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}

interface OOProgramInviteFormValues {
    name: string;
    phone: string;
    email: string;

    vin: string;
    license: string;
}
