import { DataTable } from "@deathstar/ui";
import Columns from "./Columns";

export const hideableColumns: Array<DataTable.HideableColumn> = [
    {
        label: "Date",
        ids: [Columns.addDate.id!],
    },
    {
        label: "Removal Date",
        ids: [Columns.removalDate.id!],
    },
    {
        label: "State",
        ids: [Columns.state.id!],
    },
    {
        label: "Unit: VIN",
        ids: [Columns.unitVin.id!],
    },
    {
        label: "Unit: Year",
        ids: [Columns.unitYear.id!],
    },
    {
        label: "Unit: Make",
        ids: [Columns.unitMake.id!],
    },
    {
        label: "Unit: License",
        ids: [Columns.unitLicenseNumber.id!],
    },
    {
        label: "Unit: Number",
        ids: [Columns.unitNumber.id!],
    },
    {
        label: "Unit: Type",
        ids: [Columns.unitType.id!],
    },
    {
        label: "Offender",
        ids: [Columns.offender.id!],
    },
    {
        label: "BASIC Category",
        ids: [Columns.basic.id!],
    },
    {
        label: "Violation Code",
        ids: [Columns.violationCode.id!],
    },
    {
        label: "Group",
        ids: [Columns.groupDescription.id!],
    },
    {
        label: "Section",
        ids: [Columns.sectionDescription.id!],
    },
    {
        label: "Severity Weight",
        ids: [Columns.severityWeight.id!],
    },
    {
        label: "Time Weight",
        ids: [Columns.timeWeight.id!],
    },
    {
        label: "Total Weight",
        ids: [Columns.totalWeight.id!],
    },
    {
        label: "Weight Calculation",
        ids: [Columns.weightCalculation.id!],
    },
    {
        label: "County",
        ids: [Columns.county.id!],
    },
    {
        label: "Latitude",
        ids: [Columns.latitude.id!],
    },
    {
        label: "Longitude",
        ids: [Columns.longitude.id!],
    },
];
