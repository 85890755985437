import { useState, useEffect } from "react";

/**
 * Code modified from https://github.com/kripod/react-hooks/tree/master/packages/web-api-hooks
 *
 * Tracks size of an element.
 *
 * ⚗️ _The underlying technology is experimental. Please be aware about browser compatibility before using this in production._
 *
 * @param ref Attribute attached to the element under observation.
 * @param includePadding If true, the size returned will include the element's padding, using the borderBoxSize. Otherwise uses contentBoxSize. Note: this does not include margins
 *
 * @returns Dimensions `[width, height]`, falling back to `[0, 0]` when unavailable.
 *
 * @example
 * function Component() {
 *   const ref = useRef<HTMLElement>(null);
 *   const [width, height] = useSize(ref);
 *   // ...
 *   return <ElementToObserve ref={ref} />;
 * }
 */
export function useSize(ref: React.RefObject<HTMLElement>, includePadding?: boolean): Readonly<[number, number]> {
    const [size, setSize] = useState<Readonly<[number, number]>>([0, 0]);

    useEffect(() => {
        if (!ResizeObserver || !ref.current) return undefined;

        const observer = new ResizeObserver(([entry]) => {
            if (includePadding) {
                const { inlineSize, blockSize } = entry.borderBoxSize[0];
                setSize([inlineSize, blockSize]);
            } else {
                const { inlineSize, blockSize } = entry.contentBoxSize[0];
                setSize([inlineSize, blockSize]);
            }
        });
        observer.observe(ref.current);

        return (): void => {
            observer.disconnect();
        };
    }, [includePadding, ref]);

    return size;
}
