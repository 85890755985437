import { DateTime } from "@deathstar/reuse";
import { MotorCarrier } from "../MotorCarrier";
import { CarrierOutOfServiceRate } from "./CarrierOutOfServiceRate";

export class CarrierOutOfServiceRates {
    // ========================================================================
    static from(smsResults: CarrierOutOfServiceRates, recordDate: Date): CarrierOutOfServiceRates {
        const newSmsResults = new CarrierOutOfServiceRates(smsResults.#carrier);
        const desiredResult = smsResults.#results.find((result) => {
            if (result.recordDate.isEqual(recordDate)) return result;
            return null;
        });
        if (desiredResult) {
            newSmsResults.#results = [desiredResult];
        }
        return newSmsResults;
    }

    // ========================================================================
    /**
     * Async constructor
     */
    static async new(carrier: MotorCarrier, dates: Date[]): Promise<CarrierOutOfServiceRates> {
        const smsResults = new CarrierOutOfServiceRates(carrier);

        const smsResultPromises: Promise<CarrierOutOfServiceRate>[] = [];
        dates
            .map((d) => DateTime.fromDate(d))
            .forEach((date) => {
                const recordDate = date.last().freeze();
                const snapshotDate = MotorCarrier.getSnapshotDateFromRecordDate(carrier, recordDate).freeze();

                smsResultPromises.push(Promise.resolve(new CarrierOutOfServiceRate({ carrier, recordDate, snapshotDate })));
            });
        const results = await Promise.all(smsResultPromises);

        results.forEach((result) => {
            smsResults.#results.push(result);
        });

        return smsResults;
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #results: CarrierOutOfServiceRate[] = [];
    private constructor(carrier: MotorCarrier) {
        this.#carrier = carrier;
    }

    // ========================================================================
    *[Symbol.iterator](): IterableIterator<CarrierOutOfServiceRate> {
        for (const smsResult of this.#results) {
            yield smsResult;
        }
    }

    // ========================================================================
    get total(): number {
        return this.#results.length;
    }

    // ========================================================================
    get latest(): CarrierOutOfServiceRate {
        const sorted = this.#results.sort((v1, v2) => {
            if (v1.recordDate.isBefore(v2.recordDate)) {
                return 1;
            }
            if (v1.recordDate.isAfter(v2.recordDate)) {
                return -1;
            }
            return 0;
        });
        return sorted[0];
    }

    // ========================================================================
    getByDate(date: DateTime | Date | string): CarrierOutOfServiceRate | undefined {
        const dateStr =
            date instanceof DateTime
                ? date.format("YYYYMMDD")
                : date instanceof Date
                ? DateTime.fromDate(date).format("YYYYMMDD")
                : new DateTime(date).format("YYYYMMDD");
        return this.#results.find((result) => {
            if (result.recordDate.format("YYYYMMDD") === dateStr) return result;
            return null;
        });
    }

    // ========================================================================
    json(): CarrierOutOfServiceRate.JSON[] {
        return this.#results.map((result) => result.json());
    }

    // ========================================================================
    sortByDate(order: "ASC" | "DESC" = "DESC"): this {
        this.#results = this.#results.sort((v1, v2) => {
            if (v1.recordDate.isBefore(v2.recordDate)) {
                return order === "ASC" ? -1 : 1;
            }
            if (v1.recordDate.isAfter(v2.recordDate)) {
                return order === "ASC" ? 1 : -1;
            }
            return 0;
        });
        return this;
    }
}

// ========================================================================
export namespace CarrierOutOfServiceRates {
    export interface Raw {}
}
