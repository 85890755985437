import { DateTime, RawData } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";

import type { MotorCarrier } from "../MotorCarrier";

// ========================================================================
export class InsuranceHistoryEntry extends RawData<InsuranceHistoryEntry.Raw> {
    // ========================================================================
    static sorter(order: "ASC" | "DESC"): (e1: InsuranceHistoryEntry, e2: InsuranceHistoryEntry) => number {
        return (e1: InsuranceHistoryEntry, e2: InsuranceHistoryEntry) => {
            if (e1.effectiveDate.isBefore(e2.effectiveDate)) {
                return order === "ASC" ? -1 : 1;
            }
            if (e1.effectiveDate.isAfter(e2.effectiveDate)) {
                return order === "ASC" ? 1 : -1;
            }
            return 0;
        };
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #exists: boolean;

    constructor(carrier: MotorCarrier, raw: InsuranceHistoryEntry.Raw) {
        super(raw);
        this.#carrier = carrier;
        this.#exists = Boolean(raw);
    }

    // ========================================================================
    get effectiveDate(): DateTime {
        return new DateTime(this.get("EffectiveDate"));
    }

    // ========================================================================
    get exists(): boolean {
        return this.#exists;
    }

    // ========================================================================
    json(): InsuranceHistoryEntry.Raw {
        return super.json();
    }

    // ========================================================================
    raw(): InsuranceHistoryEntry.Raw {
        return super.json();
    }
}

export namespace InsuranceHistoryEntry {
    export interface Raw extends FMCSA.InsuranceHistory {}
}
