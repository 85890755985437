import { Type } from "class-transformer";

import { Property } from "./Property";
import type { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";

export class PolicyAdditionalInterestLinkedProperty implements PolicyAdditionalInterest.LinkedItem {
    id: number;
    createdDate: Date;

    propertyId: number;
    propertyEffectiveDate: Date;
    @Type(() => Property)
    property?: Property;

    policyAdditionalInterestId: number;
    policyAdditionalInterestCreatedDate: Date;
    policyAdditionalInterest?: PolicyAdditionalInterest;

    toString(): string {
        return this.property?.toString() || `PolicyAdditionalInterestLinkedProperty: ${this.propertyId}`;
    }
}
