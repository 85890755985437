import { classNames } from "@deathstar/ui";
import { Typography } from "@material-ui/core";
import React from "react";
import { PrintableTable } from "../Components/PrintableTable";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";

export function InsuranceHistory(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    return (
        <Section gray>
            <TitleGroup title={<Typography variant="h5">Insurance History</Typography>} className="mb-8" description={``} />
            <PrintableTable
                columnHeaders={["date", "company", "limit", "action"]}
                data={motorCarrier.insuranceHistory.array().map((ins) => {
                    const action = ins.get("ActionTypeDescription");
                    return {
                        key: ins.get("Id"),
                        date: ins.effectiveDate.format("mm/dd/YY"),
                        company: ins.get("InsuranceCompany"),
                        limit: `$${ins.get("MinimumCoverageAmount")}k`,
                        action: (
                            <span
                                className={classNames({
                                    "text-green-500": action === "Replaced",
                                    "text-red-500": action === "Cancelled",
                                })}
                            >
                                {action}
                            </span>
                        ),
                    };
                })}
            />
        </Section>
    );
}
