import { Tractor } from "./Tractor";
import { NumberFormatter } from "../util/NumberFormatter/NumberFormatter";
import type { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";

export class PolicyAdditionalInterestLinkedTractor implements PolicyAdditionalInterest.LinkedItem {
    id: number;
    createdDate: Date;

    tractorId: number;
    tractor?: Tractor;

    policyAdditionalInterestId: number;
    policyAdditionalInterestCreatedDate: Date;
    policyAdditionalInterest?: PolicyAdditionalInterest;

    toString(): string {
        if (this.tractor) {
            const { year, make, value, vin } = this.tractor;
            const base: (number | string)[] = [];
            if (year) base.push(year);
            if (make) base.push(make);
            if (value) base.push(NumberFormatter.Currency.format(value));
            if (vin) base.push(vin);

            return base.join(" ");
        }

        return `PolicyAdditionalInterestLinkedTractor: ${this.tractorId}`;
    }
}
