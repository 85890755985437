import { Coverage, CoverageOption } from "@deathstar/types/northstar";
import { classNames } from "@deathstar/ui";
import { CheckIcon } from "@heroicons/react/24/outline";
import { uniq } from "lodash";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import api from "../../api/api";
import { EquipmentTypeString } from "../../api/queries/equipment";
import { useAccountId } from "../../api/useAccountId";

const COMPANY_DRIVER_COVERAGES = [CoverageOption.Id.TRUCKERS_AUTO_LIABILITY];
const OWNER_OPERATOR_COVERAGES = [
    CoverageOption.Id.TRUCKERS_AUTO_LIABILITY,
    CoverageOption.Id.OCCUPATIONAL_ACCIDENT,
    CoverageOption.Id.CONTINGENT_LIABILITY,
    CoverageOption.Id.NON_TRUCKING_LIABILITY,
];

const TRACTOR_COVERAGES = [
    CoverageOption.Id.TRUCKERS_AUTO_LIABILITY,
    CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE,
    CoverageOption.Id.MOTOR_TRUCK_CARGO,
    CoverageOption.Id.EXCESS_CARGO,
];
const TRAILER_COVERAGES = [CoverageOption.Id.TRUCKERS_AUTO_LIABILITY, CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE];
const BUSINESS_AUTO_COVERAGES = [CoverageOption.Id.BUSINESS_AUTO_LIABILITY];
const TOOLS_COVERAGES = [CoverageOption.Id.INLAND_MARINE];

export default function CoverageSelector({ dataType }: { dataType: EquipmentTypeString | "drivers" }) {
    const form = useFormContext<{ coverages: CoverageOption.Id[]; ownerOperator: boolean }>();
    const accountId = useAccountId();
    const { data } = api.policies.useFind<Coverage["coverageOptionId"][]>(accountId!, "active", {
        select: (policies) =>
            uniq(
                policies
                    .flatMap((policy) =>
                        policy.coverages
                            .map((coverage) => coverage.coverageOptionId)
                            .filter((coverageOptionId) => {
                                switch (dataType) {
                                    case "tractors":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).tractors;
                                    case "trailers":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).trailers;
                                    case "autos":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).businessAutos;
                                    case "tools":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).tools;
                                    case "drivers":
                                        return CoverageOption.getAvailableAttachments(coverageOptionId).drivers;
                                    default:
                                        return false;
                                }
                            })
                    )
                    .sort()
            ),
    });

    useEffect(() => {
        if (data && !form.formState.touchedFields.coverages) {
            if (dataType === "drivers") {
                if (form.getValues("ownerOperator")) {
                    form.setValue(
                        "coverages",
                        data.filter((c) => OWNER_OPERATOR_COVERAGES.includes(c)),
                        { shouldTouch: false }
                    );
                } else {
                    form.setValue(
                        "coverages",
                        data.filter((c) => COMPANY_DRIVER_COVERAGES.includes(c)),
                        { shouldTouch: false }
                    );
                }
            } else if (dataType === "tractors") {
                form.setValue(
                    "coverages",
                    data.filter((c) => TRACTOR_COVERAGES.includes(c)),
                    { shouldTouch: false }
                );
            } else if (dataType === "trailers") {
                form.setValue(
                    "coverages",
                    data.filter((c) => TRAILER_COVERAGES.includes(c)),
                    { shouldTouch: false }
                );
            } else if (dataType === "autos") {
                form.setValue(
                    "coverages",
                    data.filter((c) => BUSINESS_AUTO_COVERAGES.includes(c)),
                    { shouldTouch: false }
                );
            } else if (dataType === "tools") {
                form.setValue(
                    "coverages",
                    data.filter((c) => TOOLS_COVERAGES.includes(c)),
                    { shouldTouch: false }
                );
            }
        }
    }, [data, dataType, form]);

    const coverages = form.watch("coverages");

    return (
        <div className="w-max">
            <p>
                Desired coverages <span className="text-xs text-stone-500">(click to select one or more)</span>
            </p>
            {data?.map((coverageOptionId) => (
                <button
                    type="button"
                    key={coverageOptionId}
                    className={classNames(
                        "flex w-full items-center gap-2 rounded px-2 py-1 text-stone-500 disabled:opacity-50 enabled:hover:bg-stone-100",
                        coverages.includes(coverageOptionId) ? "font-medium text-waypoint-blue" : ""
                    )}
                    onClick={() => {
                        if (coverages.includes(coverageOptionId)) {
                            form.setValue(
                                "coverages",
                                coverages.filter((c) => c !== coverageOptionId),
                                { shouldTouch: true }
                            );
                        } else {
                            form.setValue("coverages", [...coverages, coverageOptionId], { shouldTouch: true });
                        }
                    }}
                >
                    <div className="h-4 w-4">{coverages.includes(coverageOptionId) ? <CheckIcon className="h-4 w-4" /> : ""}</div>
                    {CoverageOption.getMetadata(coverageOptionId).name}
                </button>
            ))}
        </div>
    );
}
