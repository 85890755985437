import { InlandMarineEquipment } from "./InlandMarineEquipment";
import { NumberFormatter } from "../util/NumberFormatter/NumberFormatter";
import type { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";

export class PolicyAdditionalInterestLinkedTool implements PolicyAdditionalInterest.LinkedItem {
    id: number;
    createdDate: Date;

    toolId: number;
    tool?: InlandMarineEquipment;

    policyAdditionalInterestId: number;
    policyAdditionalInterestCreatedDate: Date;
    policyAdditionalInterest?: PolicyAdditionalInterest;

    toString(): string {
        if (this.tool) {
            const { year, description, make, model, value } = this.tool;
            const base: (number | string)[] = [];
            if (year) base.push(year);
            if (description) base.push(description);
            if (make) base.push(make);
            if (model) base.push(model);
            if (value) base.push(NumberFormatter.Currency.format(value));

            return base.join(" ");
        }

        return `PolicyAdditionalInterestLinkedTool: ${this.toolId}`;
    }
}
