import { MotorCarrier } from "@deathstar/motor-carrier";
import Columns from "./Columns";
import { useViolationMap } from "./useViolationMap";
import { useTable } from "./useViolationTable";

export function useViolationPage(motorCarrier: MotorCarrier, globalTableFilter?: string) {
    const { data, table } = useTable(motorCarrier, globalTableFilter);
    const { mapController } = useViolationMap({
        motorCarrier,
        setFilter: (filter) => {
            // table.setGlobalFilter(filter);
            table.resetColumnFilters();
            table.setColumnFilters((filterState) => {
                filterState.push({
                    id: Columns.latitude.id as string,
                    value: filter,
                });

                return filterState;
            });
        },
    });

    return {
        data,
        table,
        mapController,
        tableFilter: table.getState().globalFilter,
        setTableFilter: (filter?: string | number) => table.setGlobalFilter(filter),
    };
}
