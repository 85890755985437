export function centsToDollarString(cents: number): string {
    return (cents / 100).toLocaleString("en-us", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export function dollarsToCents(amount: number): number {
    return amount * 100;
}

export function centsToDollars(amount: number): number {
    return amount / 100;
}
