import { Typography } from "@material-ui/core";
import React from "react";
import { BasicColorSwatch } from "../BasicColorSwatch";
import { ChartContainer } from "../Charts/Container";
import { CurrentScoreChart } from "../Charts/CurrentScore";
import { ColorLegend } from "../ColorLegend";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";
import { Transition } from "../Transition";
import { SummarySection } from "./SummarySection";

export function CurrentScores(): JSX.Element {
    const { motorCarrier } = React.useContext(Context);

    return (
        <SummarySection className=" items-center gap-8 bg-gray-100 print:gap-2">
            <div className="">
                <TitleGroup
                    title={<Typography variant="h5">BASIC Scores Today</Typography>}
                    className="mb-8 text-left print:mb-2"
                    description="Calculated based on public census, inspection, and crash data from the previous two years."
                />
                <div className="self-center">
                    <ColorLegend motorCarrier={motorCarrier}>
                        {(legendArr) => {
                            return (
                                <table>
                                    <tbody className="text-gray-700 print:text-gray-700">
                                        {legendArr.map((legend, i) => (
                                            <tr key={legend.name} className="">
                                                <td>
                                                    <BasicColorSwatch basic={legend.name} />
                                                </td>
                                                <td className="px-4 text-left">
                                                    <Transition.Slide
                                                        delay={i * 200}
                                                        SlideProps={{
                                                            direction: "left",
                                                        }}
                                                    >
                                                        <Typography className="ia-transition-element print:leading-5">
                                                            {legend.name}
                                                        </Typography>
                                                    </Transition.Slide>
                                                </td>
                                                <td className="">
                                                    <Transition.Fade delay={(i + 1) * 200 + legendArr.length * 200}>
                                                        <Typography className="ia-transition-element print:leading-5">
                                                            {legend.score.formatted}
                                                        </Typography>
                                                    </Transition.Fade>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            );
                        }}
                    </ColorLegend>
                </div>
            </div>

            <Transition.Fade className="flex h-full items-end overflow-hidden print:h-[225px]">
                <ChartContainer className="ia-transition-element flex h-full h-full w-full items-center print:h-full print:w-full">
                    <CurrentScoreChart motorCarrier={motorCarrier} />
                </ChartContainer>
            </Transition.Fade>
        </SummarySection>
    );
}
