import { Zendesk } from "@deathstar/types/waypoint";
import { humanizeFileSize } from "@deathstar/ui";
import { PaperClipIcon } from "@heroicons/react/24/outline";

export default function AttachmentLink({ attachment }: { attachment: Zendesk.Attachment }) {
    return (
        <a href={attachment.content_url} target="_blank" rel="noreferrer" className="text-blue-600 hover:text-blue-800">
            <PaperClipIcon className="-mt-1 mr-2 inline h-4 w-4" />
            <span>{attachment.file_name}</span>
            <span> ({humanizeFileSize(attachment.size)})</span>
        </a>
    );
}
