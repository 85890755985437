import { GeoProjection } from "d3-geo";
import { EARTH_RADIUS, STATE_FIPS } from "./constants";

export function coordinatesToScreenPosition(coordinates: { lat: number; lng: number }, projection: GeoProjection): [number, number] {
    const results = projection([coordinates.lng, coordinates.lat]);
    if (!results) return [0, 0];
    return results;
}

export function screenPositionToCoordinates(position: [number, number], projection: GeoProjection): { lat: number; lng: number } {
    const invertedProjection = projection.invert?.(position);
    if (!invertedProjection) return { lat: 0, lng: 0 };

    return { lat: invertedProjection[1], lng: invertedProjection[0] };
}

export function milesToRadius(miles: number): number {
    return ((miles / EARTH_RADIUS) * 360) / (2 * Math.PI);
}

export function milesToPixels(miles: number, projection: GeoProjection): number {
    const projectionA = projection([milesToRadius(miles), 0]);
    const projectionB = projection([0, 0]);
    if (!projectionA || !projectionB) return 0;
    return projectionA[0] - projectionB[0];
}

export function pixelsToMiles(pixels: number, projection: GeoProjection): number {
    const projectionA = projection([milesToRadius(1), 0]);
    const projectionB = projection([0, 0]);
    if (!projectionA || !projectionB) return 0;
    return pixels / (projectionA[0] - projectionB[0]);
}

export function getStateById(id: string) {
    return STATE_FIPS[id];
}

export function hexToLuma(hexColor: string): number {
    const hex = hexColor.replace(/#/, "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return [0.299 * r, 0.587 * g, 0.114 * b].reduce((a, b) => a + b) / 255;
}
