import { FMCSA } from "@deathstar/types";
import { Inspection } from "../../Inspection/Inspection";
import { SafetyEventGroup } from "../../SafetyEventGroup/SafetyEventGroup";
import { Basic } from "../Basic";

export class ControlledSubstances extends Basic<SafetyEventGroup.GroupNames.ControlledSubstances> {
    readonly isBestAddressedRoadside = true;

    // ========================================================================
    static get thresholdDefinition(): Basic.IThresholdDefinition {
        return {
            passenger: 65,
            hazmat: 75,
            general: 80,
        };
    }

    // ========================================================================
    constructor(options: ControlledSubstances.Options) {
        super({
            ...options,
            safetyEventGroup: new SafetyEventGroup.Group.ControlledSubstances(
                options.carrier,
                options.totalControlledSubstanceInspectionsWithViolation
            ),
            basicName: FMCSA.BasicName.CONTROLLED_SUBSTANCES,
            relevantInspectionDefinition: {
                levels: [1, 2, 3, 6],
                type: Inspection.Type.DRIVER,
            },
        });
    }

    // ========================================================================
    override getThresholdDefinition(): Basic.IThresholdDefinition {
        return ControlledSubstances.thresholdDefinition;
    }

    // ========================================================================
    override getFmcsaCalculatedMeasure(): number | null {
        return typeof this.rawSmsData?.ControlledSubstanceMeasure === "number" ? this.rawSmsData?.ControlledSubstanceMeasure : null;
    }

    // ========================================================================
    override getVariableData(): Record<string, string | number> {
        return {
            totalViolations: this.getTotalNumberOfViolations(),
            totalWeightOfApplicableViolations: this.getTotalWeightOfApplicableViolations(false),
            totalTimeWeightOfRelevantInspections: this.getTotalTimeWeightOfRelevantInspections(),
        };
    }

    // ========================================================================
    protected override calculateMeasure(): number {
        if (!this.getViolations().total) return 0;

        const totalWeightOfApplicableViolations = this.getTotalWeightOfApplicableViolations(false);
        const totalTimeWeightOfRelevantInspections = this.getTotalTimeWeightOfRelevantInspections();

        return totalWeightOfApplicableViolations / totalTimeWeightOfRelevantInspections;
    }

    // ========================================================================
    protected override getMeasure(): number {
        if (typeof this.rawSmsData?.ControlledSubstanceMeasure === "number" && !this.forceCalculateBasicMeasures) {
            return this.rawSmsData?.ControlledSubstanceMeasure;
        }

        return this.calculateMeasure();
    }

    // ========================================================================
    protected override async getScore(): Promise<number | null> {
        if (typeof this.rawSmsData?.ControlledSubstanceScore === "number" && !this.forceCalculateBasicScores) {
            return this.rawSmsData?.ControlledSubstanceScore;
        }

        return this.calculateScore();
    }

    // ========================================================================
    protected override async calculateScore(): Promise<number | null> {
        if (
            this.measure === 0 ||
            this.safetyEventGroup.number === 0 ||
            !this.safetyEventGroup.name ||
            this.smsResult.totalControlledSubstanceInspectionsWithViolation === 0
        )
            return 0;

        /*
            Get the total number of inspections with a Controlled Substance violation within the previous 12 months
        */
        const totalControlledSubstanceViolationsWithinPreviousYear = this.getTotalViolationsWithinYear();

        if (totalControlledSubstanceViolationsWithinPreviousYear === 0) return 0;

        return this.fetchSmsScore();
    }
}

export namespace ControlledSubstances {
    export interface Options extends Basic.BaseOptions {
        totalControlledSubstanceInspectionsWithViolation: number;
    }
}
