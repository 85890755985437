import { WaypointDirectory } from "@deathstar/types/waypoint";
import { MutationOptions, QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import * as zipjs from "@zip.js/zip.js";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const files = {
    queryKeys: {
        get: (accountId: string, directory: string) => [accountId, "files", directory] as QueryKey,
    },

    get: (accountId: string, directory: string) => {
        return blazar.fetchJson<WaypointDirectory>(`/waypoint/orgs/${accountId}/files?path=${encodeURIComponent(directory)}`);
    },

    download: async (accountId: string, path: string, splitSize?: number): Promise<File> => {
        const response = await blazar.fetch(`/waypoint/orgs/${accountId}/files/download?path=${encodeURIComponent(path)}`);
        const name = response.headers.get("Content-Disposition")?.match(/filename="(.*)"/)?.[1] ?? "download";
        const blob = await response.blob();
        if (splitSize && blob.size > splitSize) {
            const newZip = await files.splitZip(blob, splitSize);
            return new File([newZip], name);
        } else {
            return new File([blob], name);
        }
    },

    async splitZip(zip: Blob, maxSize: number): Promise<Blob> {
        const zipReader = new zipjs.ZipReader(new zipjs.BlobReader(zip));

        const writers: zipjs.BlobWriter[] = [];
        function* blobWriterGenerator() {
            while (true) {
                const writer = new zipjs.BlobWriter();
                // @ts-expect-error - maxSize is not in the types but seems to work fine
                writer.maxSize = maxSize;
                writers.push(writer);
                yield writer;
            }
        }

        // @ts-expect-error works fine
        const zipWriter = new zipjs.ZipWriter(new zipjs.SplitDataWriter(blobWriterGenerator()));
        const entries = await zipReader.getEntries();
        for (const entry of entries) {
            const writer = new zipjs.BlobWriter();
            if (entry.getData) {
                const data = await entry.getData(writer);
                await zipWriter.add(entry.filename, new zipjs.BlobReader(data));
            }
        }

        await zipWriter.close();

        const resultZip = new zipjs.ZipWriter(new zipjs.BlobWriter());
        for await (const writer of writers) {
            const i = writers.indexOf(writer);
            const blob = await writer.getData();

            const name = i === writers.length - 1 ? "part.zip" : `part.z${(i + 1).toString().padStart(2, "0")}`;
            await resultZip.add(name, new zipjs.BlobReader(blob), { level: 0 });
        }

        const blob = await resultZip.close();
        return blob;
    },

    upload: async (accountId: string, path: string, files: File[]) => {
        const formData = new FormData();
        formData.append("path", path);
        files.forEach((file) => {
            formData.append("files", file);
        });
        await blazar.fetch(`/waypoint/orgs/${accountId}/files/upload`, {
            method: "POST",
            body: formData,
        });
    },

    useUpload(accountId: string, config?: MutationOptions<void, ResponseError, { path: string; files: File[] }>) {
        return useMutation<void, ResponseError, { path: string; files: File[] }>({
            mutationFn: (data) => {
                return files.upload(accountId, data.path, data.files);
            },
            ...config,
            onSuccess: (data, args, ctx) => {
                queryClient.invalidateQueries({ queryKey: files.queryKeys.get(accountId, args.path) });
                config?.onSuccess?.(data, args, ctx);
            },
        });
    },

    move: async (accountId: string, source: string, destination: string) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/files/move`, {
            method: "POST",
            body: JSON.stringify({ source, destination }),
        });
    },

    createDirectory: async (accountId: string, path: string, name: string) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/files/create-directory`, {
            method: "POST",
            body: JSON.stringify({ path, name }),
        });
    },

    delete: async (accountId: string, path: string) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/files?path=${encodeURIComponent(path)}`, {
            method: "DELETE",
        });
    },

    useGet: <T = WaypointDirectory>(
        accountId: string,
        directory: string,
        config?: Partial<UseQueryOptions<WaypointDirectory, ResponseError, T>>
    ) =>
        useQuery<WaypointDirectory, ResponseError, T>({
            queryKey: files.queryKeys.get(accountId, directory),
            queryFn: () => files.get(accountId, directory),
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            ...config,
        }),

    useCreateDirectory: (accountId: string, config?: UseMutationOptions<void, ResponseError, { path: string; name: string }>) => {
        return useMutation<void, ResponseError, { path: string; name: string }>({
            mutationFn: (data) => {
                return files.createDirectory(accountId, data.path, data.name);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: files.queryKeys.get(accountId, args[1].path) });
                config?.onSuccess?.(...args);
            },
        });
    },

    useMove: (accountId: string, config?: UseMutationOptions<void, ResponseError, { source: string; target: string }>) => {
        return useMutation<void, ResponseError, { source: string; target: string }>({
            mutationFn: (data) => {
                return files.move(accountId, data.source, data.target);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({
                    queryKey: files.queryKeys.get(accountId, args[1].source.split("/").slice(0, -1).join("/")),
                });
                queryClient.invalidateQueries({
                    queryKey: files.queryKeys.get(accountId, args[1].target.split("/").slice(0, -1).join("/")),
                });
                config?.onSuccess?.(...args);
            },
        });
    },

    useDelete: (accountId: string, config?: UseMutationOptions<void, ResponseError, string>) => {
        return useMutation<void, ResponseError, string>({
            mutationFn: (path) => {
                return files.delete(accountId, path);
            },
            ...config,
            onSuccess: (...args) => {
                const fullPath = args[1];
                const path = fullPath.split("/").slice(0, -1).join("/");
                queryClient.invalidateQueries({ queryKey: files.queryKeys.get(accountId, path) });
                config?.onSuccess?.(...args);
            },
        });
    },

    useDownload: (accountId: string, config?: UseMutationOptions<File, ResponseError, { path: string; splitSize?: number }>) => {
        return useMutation<File, ResponseError, { path: string; splitSize?: number }>({
            mutationFn: ({ path, splitSize }) => {
                return files.download(accountId, path, splitSize);
            },
            ...config,
        });
    },
};
