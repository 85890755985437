import { useRef } from "react";
import { useProgressiveImage } from "../hooks/useProgressiveImage";

export function ProgressiveImage({
    src,
    placeholder,
    alt,
    className,
    ...props
}: { src: string; placeholder: string; alt: string; className?: (blur: boolean) => string } & Omit<
    React.ImgHTMLAttributes<HTMLImageElement>,
    "className"
>) {
    const ref = useRef<HTMLImageElement>(null);
    const shouldBlur = useProgressiveImage(ref, src, placeholder);
    return <img {...props} ref={ref} alt={alt} className={className?.(shouldBlur)} />;
}
