import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";

import { EnforcementCases } from "../EnforcementCases/EnforcementCases";
import { Inspection } from "../Inspection/Inspection";
import { Inspections } from "../Inspections/Inspections";
import { MotorCarrier } from "../MotorCarrier";
import { SafetyEventGroup } from "../SafetyEventGroup/SafetyEventGroup";
import { Violations } from "../Violations/Violations";
import { SmsResult } from "./SmsResult";

export abstract class Basic<SafetyEventSubGroup> {
    static getMinimumRelevant(basic: FMCSA.BasicName): number {
        switch (basic) {
            case FMCSA.BasicName.CONTROLLED_SUBSTANCES:
                return 1;
            case FMCSA.BasicName.CRASH_INDICATOR:
                return 2;
            case FMCSA.BasicName.UNSAFE_DRIVING:
            case FMCSA.BasicName.HOS:
                return 3;
            case FMCSA.BasicName.DRIVER_FITNESS:
            case FMCSA.BasicName.HAZMAT:
            case FMCSA.BasicName.VEHICLE_MAINTENANCE:
                return 5;
            default:
                return 1;
        }
    }

    // ========================================================================
    static resetScore(basic: Basic<unknown>, newScore: number): void {
        basic.#score = newScore;
    }

    // ========================================================================
    /**
     * Calculates the total weight of applicable violations as defined in the SMS Methodolgy.
     */
    static calculateTotalWeightOfApplicableViolations({
        violations,
        considerOosInSeverityWeightCalculation,
        dateRange,
    }: Basic.CalculateTotalWeightOfApplicableViolationsOptions): number {
        const { carrier } = violations;
        const totalsMap = Array.from(violations).reduce((totalsMap, violation) => {
            let currentTotal = 0;
            if (totalsMap.has(violation.inspection.id)) {
                currentTotal = totalsMap.get(violation.inspection.id) ?? 0;
            }
            const nextTotal =
                currentTotal + (considerOosInSeverityWeightCalculation ? violation.severityWeight : violation.get("SeverityWeight"));
            totalsMap.set(violation.inspection.id, nextTotal > 30 ? 30 : nextTotal);
            return totalsMap;
        }, new Map<number, number>());

        return Array.from(totalsMap.entries()).reduce((total, [inspectionId, inspectionTotal]) => {
            const inspection = carrier.inspections.getById(inspectionId);
            total += inspection.getTimeWeight(dateRange.to) * inspectionTotal;
            return total;
        }, 0);
    }

    // ========================================================================
    /**
     * Calculates the Utilization Factor used in BASIC measure calculations as defined in
     *  the SMS Methodology.
     *
     * The Unsafe Driving and Crash Indicator BASICs incorporate the utlitization factor
     *  in their respective measure calculation formulas.
     */
    static calculateUtilizationFactor(carrier: MotorCarrier): number {
        const { segment, averageMilesTraveledPerUnit } = carrier;
        if (segment === FMCSA.Segment.COMBINATION) {
            if (averageMilesTraveledPerUnit >= 80000 && averageMilesTraveledPerUnit < 160000) {
                return MotorCarrier.round(1 + (averageMilesTraveledPerUnit - 80000) / 133333, 4);
            }
            if (averageMilesTraveledPerUnit >= 160000 && averageMilesTraveledPerUnit <= 200000) {
                return 1.6;
            }
            return 1;
        }

        if (segment === FMCSA.Segment.STRAIGHT) {
            if (averageMilesTraveledPerUnit >= 20000 && averageMilesTraveledPerUnit < 60000) {
                return MotorCarrier.round(averageMilesTraveledPerUnit / 20000, 4);
            }
            if (averageMilesTraveledPerUnit >= 60000 && averageMilesTraveledPerUnit <= 200000) {
                return 3;
            }
            return 1;
        }

        return 1;
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #smsResult: SmsResult;
    #measure = NaN;
    #score: number | null = null;
    #threshold: number;
    #rawSmsData: MotorCarrier.Raw.SmsData | null;
    #safetyEventGroup: SafetyEventGroup.Group<SafetyEventSubGroup>;
    #fetcher: MotorCarrier.IFetcher;
    #basicName: FMCSA.BasicName;
    #thresholdDefinition: Basic.IThresholdDefinition;
    relevantInspectionDefinition: Basic.IRelevantInspection;
    #relevantEnforcementCases: EnforcementCases;
    #recordDate: DateTime;
    #snapshotDate: DateTime;
    public constructor(options: Basic.Options<SafetyEventSubGroup>) {
        this.#carrier = options.carrier;
        this.#smsResult = options.smsResult;
        this.#rawSmsData = options.rawSmsData;
        this.#recordDate = options.recordDate;
        this.#snapshotDate = options.snapshotDate;
        this.#safetyEventGroup = options.safetyEventGroup;
        this.forceCalculateBasicMeasures = options.forceCalculateBasicMeasures;
        this.forceCalculateBasicScores = options.forceCalculateBasicScores;
        this.#fetcher = MotorCarrier.getFetcher(this.#carrier);
        this.#thresholdDefinition = this.getThresholdDefinition();
        this.#threshold = this.#getThresholdForCarrier(this.#thresholdDefinition);
        this.#basicName = options.basicName;
        this.relevantInspectionDefinition = options.relevantInspectionDefinition || {
            levels: [],
            type: null,
        };
        this.#relevantEnforcementCases = this.carrier.enforcementCases
            .filterByDateRange({
                from: this.#carrier.dates.twelveMonthMark,
                to: this.#carrier.dates.to,
            })
            .filterByBasic(this.#basicName);
    }

    // ========================================================================
    abstract getFmcsaCalculatedMeasure(): number | null;
    protected abstract getMeasure(): number;
    protected abstract getScore(): Promise<number | null>;
    protected abstract calculateScore(): Promise<number | null>;
    protected abstract getThresholdDefinition(): Basic.IThresholdDefinition;
    protected readonly forceCalculateBasicMeasures: boolean;
    protected readonly forceCalculateBasicScores: boolean;
    abstract readonly isBestAddressedRoadside: boolean;
    abstract getVariableData(): Record<string, string | number | null | undefined>;

    // ========================================================================
    getViolations(): Violations {
        return this.getRelevantInspections().violations.filterByBasic(this.#basicName);
    }

    // ========================================================================
    getTotalNumberOfViolations(): number {
        return this.smsResult.inspections.violations.filterByBasic(this.#basicName).total;
    }

    // ========================================================================
    getTotalViolationsWithinYear(): number {
        return this.smsResult.inspectionsWithinPreviousYear.violations.filterByBasic(this.#basicName).total;
    }

    // ========================================================================
    getTotalWeightOfApplicableViolations(considerOosInSeverityWeightCalculation = true): number {
        return Basic.calculateTotalWeightOfApplicableViolations({
            violations: this.getViolations(),
            considerOosInSeverityWeightCalculation,
            dateRange: MotorCarrier.calculateDateRange({ snapshotDate: this.snapshotDate }),
        });
    }

    // ========================================================================
    getRelevantInspections(): Inspections {
        if (!this.relevantInspectionDefinition.type) return Inspections.of(this.carrier, []);
        return this.smsResult.inspections
            .filterByLevel({ levelIds: this.relevantInspectionDefinition.levels })
            .filterByType(this.relevantInspectionDefinition.type);
    }

    // ========================================================================
    getTotalInspectionsWithViolation(): number {
        return this.smsResult.inspections.violations.filterByBasic(this.#basicName).inspections.total;
    }

    // ========================================================================
    getLatestRelevantInspectionIncludesViolation(): boolean {
        const latestRelevantInspection = Array.from(this.getRelevantInspections().sortByDate("DESC"))[0];
        if (!latestRelevantInspection || latestRelevantInspection.isClean) return false;
        return latestRelevantInspection.hasBasicViolation(this.#basicName) || false;
    }

    // ========================================================================
    getTotalTimeWeightOfRelevantInspections(): number {
        return this.getRelevantInspections().getTotalTimeWeight(this.snapshotDate);
    }

    // ========================================================================
    fetchSmsScore(): Promise<number> {
        if (!this.safetyEventGroup.name) return Promise.resolve(0);

        return MotorCarrier.fetchSmsScore({
            carrier: this.carrier,
            recordDate: this.recordDate,
            safetyEventGroupName: this.safetyEventGroup.name as SafetyEventGroup.GroupNames,
            measure: this.measure,
        });
    }

    // ========================================================================
    protected calculateMeasure(): number {
        if (!this.getViolations().total) return 0;

        const totalWeightOfApplicableViolations = this.getTotalWeightOfApplicableViolations();
        const totalTimeWeightOfRelevantInspections = this.getTotalTimeWeightOfRelevantInspections();

        return totalWeightOfApplicableViolations / totalTimeWeightOfRelevantInspections;
    }

    // ========================================================================
    protected get carrierFetcher(): MotorCarrier.IFetcher {
        return this.#fetcher;
    }

    // ========================================================================
    protected get smsResult(): SmsResult {
        return this.#smsResult;
    }

    // ========================================================================
    get safetyEventGroup(): SafetyEventGroup.Group<SafetyEventSubGroup> {
        return this.#safetyEventGroup;
    }

    // ========================================================================
    protected get rawSmsData(): MotorCarrier.Raw.SmsData | null {
        return this.#rawSmsData;
    }

    // ========================================================================
    #getThresholdForCarrier(thresholdDefinition: Basic.IThresholdDefinition = this.getThresholdDefinition()): number {
        return this.#carrier.isHazmat ? thresholdDefinition.hazmat : thresholdDefinition.general;
    }

    // ========================================================================
    get recordDate(): DateTime {
        return this.#recordDate;
    }

    // ========================================================================
    get snapshotDate(): DateTime {
        return this.#snapshotDate;
    }

    // ========================================================================
    protected get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    get measure(): number {
        return this.#measure;
    }

    // ========================================================================
    get threshold(): number {
        return this.#threshold;
    }

    // ========================================================================
    get score(): number | null {
        return this.#score;
    }

    // ========================================================================
    get isScoreAboveThreshold(): boolean {
        if (typeof this.#score !== "number") return false;
        return this.#score >= this.#threshold;
    }

    // ========================================================================
    get hasAcuteCriticalViolation(): boolean {
        if (this.#relevantEnforcementCases.total) return true;
        return false;
    }

    // ========================================================================
    get alert(): boolean {
        return this.hasAcuteCriticalViolation || this.isScoreAboveThreshold;
    }

    // ========================================================================
    public json(): Basic.JSON {
        return {
            threshold: this.threshold,
            measure: this.measure,
            score: this.score,
            alert: this.alert,
        };
    }

    // ========================================================================
    async calculate(): Promise<this> {
        this.#measure = this.getMeasure();
        const calculatedScore = await this.getScore();
        this.#score = typeof calculatedScore === "number" && calculatedScore >= 100 ? 99 : calculatedScore;
        return this;
    }
}

export declare namespace Basic {
    export interface CalculateTotalWeightOfApplicableViolationsOptions {
        violations: Violations;
        considerOosInSeverityWeightCalculation: boolean;
        dateRange: MotorCarrier.IDateRange;
    }
    export interface BaseOptions {
        carrier: MotorCarrier;
        rawSmsData: MotorCarrier.Raw.SmsData | null;
        smsResult: SmsResult;
        recordDate: DateTime;
        snapshotDate: DateTime;
        forceCalculateBasicMeasures: boolean;
        forceCalculateBasicScores: boolean;
    }

    export interface IRelevantInspection {
        levels: number[];
        type: Inspection.Type | null;
    }

    export interface Options<SafetyEventSubGroup> extends BaseOptions {
        safetyEventGroup: SafetyEventGroup.Group<SafetyEventSubGroup>;
        basicName: FMCSA.BasicName;
        relevantInspectionDefinition: Basic.IRelevantInspection | null;
    }
    export interface IThresholdDefinition {
        passenger: number;
        hazmat: number;
        general: number;
    }

    export interface JSON {
        threshold: number;
        measure: number;
        score: number | null;
        alert: boolean | null;
    }
    export interface IInterventionThresholds {
        unsafeDriving: number;
        hos: number;
        vehicleMx: number;
        controlledSubstances: number;
        hazmat: number;
        driverFitness: number;
        crash: number;
    }
}
