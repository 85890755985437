import { MotorCarrier } from "@deathstar/motor-carrier";
import { Tooltip, classNames } from "@deathstar/ui";
import { Typography } from "@material-ui/core";
import { BasicColorSwatch } from "../BasicColorSwatch";
import { Section } from "../Components/Section";
import { TitleGroup } from "../Components/TitleGroup";

export function NegativeTrends({ className, trends }: { className?: string; trends: MotorCarrier.ITrends }): JSX.Element {
    return (
        <Section gray className={classNames(className, "print:bg-white")}>
            <TitleGroup title={<Typography variant="h5">Negative Trends</Typography>} className="mb-8" description={``} />
            {!trends.negative.length && <p>No negative trends identified</p>}
            {trends.negative.length > 0 && (
                <ul>
                    {trends.negative.map((trend, i) => (
                        <li key={i} className="flex list-none items-center gap-4">
                            <Tooltip title={trend.basic}>
                                <BasicColorSwatch className="mb-1" size="xs" basic={trend.basic} />
                            </Tooltip>
                            <p>{trend.format()}</p>
                        </li>
                    ))}
                </ul>
            )}
        </Section>
    );
}
