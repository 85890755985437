import { OrganizationInviteCode } from "@deathstar/types/waypoint";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const inviteCodes = {
    accept: async (code: string) => {
        return await blazar.fetchJson<OrganizationInviteCode>(`waypoint/invite-codes/${code}/accept`, { method: "POST" });
    },

    useAccept: (config?: UseMutationOptions<OrganizationInviteCode, ResponseError, string>) => {
        return useMutation<OrganizationInviteCode, ResponseError, string>({
            mutationFn: (code) => {
                return inviteCodes.accept(code);
            },
            ...config,
        });
    },
};
