import { plainToClass } from "class-transformer";
import type { Coverage } from "./Coverage";
import type { CoverageLimit } from "./CoverageLimit";
import type { CoverageModificationOption } from "./CoverageModificationOption";
import { NumberFormatter } from "../util/NumberFormatter/NumberFormatter";
import { Comparison } from "./Comparison/Comparison";

export class CoverageModification implements Comparison.ICanCompare<CoverageModification> {
    static duplicate(modification: CoverageModification): CoverageModification {
        const m = plainToClass(CoverageModification, modification);

        m.id = null;
        m.createdDate = null;
        m.coverageId = null;
        m.coverageCreatedDate = null;
        m.coverage = null;
        m.coverageLimitId = null;
        m.coverageLimitCreatedDate = null;
        m.coverageLimit = null;

        return m;
    }

    static new(mod: Partial<CoverageModification>): CoverageModification {
        return plainToClass(CoverageModification, mod || {});
    }

    static compare({
        base,
        compare,
    }: {
        base?: CoverageModification;
        compare?: CoverageModification;
    }): CoveargeModification.IComparisonReturn {
        const comparison = new Comparison(CoverageModification) as CoveargeModification.IComparisonReturn;
        comparison.setField("modificationOptionId", compare?.modificationOptionId || base?.modificationOptionId);
        comparison.setField("modificationOption", compare?.modificationOption || base?.modificationOption);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add Modification: ${compare.toString()} `,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete Modification: ${base.toString()}`,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        [
            {
                key: "limit1Text",
                label: "Limit 1",
                transform: (v) => v,
            },
            {
                key: "limit1Money",
                label: "Limit 1",
                transform: (v) => NumberFormatter.Currency.format(v),
            },
            {
                key: "limit2Text",
                label: "Limit 2",
                transform: (v) => v,
            },
            {
                key: "limit2Money",
                label: "Limit 2",
                transform: (v) => NumberFormatter.Currency.format(v),
            },
            {
                key: "deductibleText",
                label: "Deductible",
                transform: (v) => v,
            },
            {
                key: "deductibleMoney",
                label: "Deductible",
                transform: (v) => NumberFormatter.Currency.format(v),
            },
        ].forEach(({ key, label, transform }) => {
            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Change ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    priority: null,
                    fieldName: key as keyof CoverageModification,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        return comparison;
    }

    id: number;
    createdDate: Date;
    modificationOptionId: string;
    modificationOption?: CoverageModificationOption;

    coverageId?: number;
    coverageCreatedDate?: Date;
    coverage?: Coverage;

    coverageLimitId?: number;
    coverageLimitCreatedDate?: Date;
    coverageLimit?: CoverageLimit;

    sortOrder: number;
    limit1Text?: string;
    limit1Money?: number;
    limit2Text?: string;
    limit2Money?: number;
    deductibleText?: string;
    deductibleMoney?: number;
    metadata?: Record<string, unknown>;

    toString(): string {
        const nf = NumberFormatter.Currency;
        const parts: (string | number)[] = [];

        const limit1 = this.limit1Money || this.limit1Text;
        const limit2 = this.limit2Money || this.limit2Text;
        const deductible = this.deductibleMoney || this.deductibleText;

        if (this.modificationOption?.name) parts.push(this.modificationOption?.name);

        const limit: (string | number)[] = [];
        limit.push(typeof limit1 === "number" ? nf.format(limit1) : limit1 || "-");
        limit.push(typeof limit2 === "number" ? nf.format(limit2) : limit2 || "-");
        limit.push(typeof deductible === "number" ? nf.format(deductible) : deductible || "-");
        const limitString = limit.join("/");
        if (limitString !== "-/-/-") parts.push(limitString);

        return parts.join(", ");
    }

    compare(
        comparisonModification: CoverageModification,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CoverageModification> {
        return CoverageModification.compare(Comparison.getBaseAndComparisonObjects(
            {
                initiatorEntity: this,
                comparisonEntity: comparisonModification,
            },
            options
        ));
    }
}

export namespace CoveargeModification {
    export interface IComparisonReturn extends Comparison<CoverageModification> {
        modificationOptionId: string;
        modificationOption?: CoverageModificationOption;
    }
}