import { Policy, PolicyDocument } from "@deathstar/types/northstar";
import { useSnackbar } from "@deathstar/ui";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { blazar } from "../../../api/util/blazar";

const PolicyDocumentTypeLabels = Object.fromEntries(
    Object.entries(PolicyDocument.Type).map(([key, value]) => [value, key.replaceAll("_", " ")])
);

export function PolicyDocumentItem({ document, policy }: { document: PolicyDocument; policy: Policy }) {
    return (
        <button
            className="group flex w-full gap-2 rounded p-2 text-left hover:bg-stone-100 hover:text-navigator-primary"
            onClick={async () => {
                const response = await blazar.fetch(`waypoint/orgs/${policy.accountId}/policies/${policy.id}/documents/${document.id}`);
                if (response.ok) {
                    const blob = await response.blob();
                    window.open(URL.createObjectURL(blob), "_blank");
                } else {
                    useSnackbar.add("Could not open document. Please try again later.", { variant: "error" });
                }
            }}
        >
            <DocumentTextIcon className="mt-1 inline h-5 w-5" />
            <div className="w-full">
                <span
                    className="inline overflow-hidden text-ellipsis text-left text-stone-800 group-hover:text-navigator-primary"
                    title={document.name}
                >
                    {document.name}
                </span>
                <div className="flex justify-between gap-8 text-stone-500">
                    <span className="overflow-hidden text-ellipsis text-left capitalize">{PolicyDocumentTypeLabels[document.type]}</span>
                    <span className="text-right">Added on {moment(document.createdDate).format("MMM D, YYYY")}</span>
                </div>
            </div>
        </button>
    );
}
