import { IsEmpty, IsString, IsInt, IsOptional, IsObject, ValidateNested } from "class-validator";
import type { SharepointDocument } from "./SharepointDocument";
import { Type } from "class-transformer";
import type { FileCategory } from ".";

export interface DriveItemListResponse {
    value: SharepointDocumentResult[];
    "@odata.context": string;
    "@odata.nextLink"?: string;
}

export class SharepointDocumentResult {
    @IsEmpty({ always: true })
    "@microsoft.graph.downloadUrl": string;

    @IsEmpty({ always: true })
    createdDateTime: string;

    @IsEmpty({ always: true })
    eTag: string;

    @IsEmpty({ always: true })
    id: string;

    @IsEmpty({ always: true })
    lastModifiedDateTime: string;

    @IsOptional({ always: true })
    @IsString({ always: true })
    name: string;

    @IsEmpty({ always: true })
    image: unknown;

    @IsEmpty({ always: true })
    webUrl: string;

    @IsEmpty({ always: true })
    cTag: string;

    @IsEmpty({ always: true })
    size: number;

    @IsEmpty({ always: true })
    createdBy: CreatedBy;

    @IsEmpty({ always: true })
    lastModifiedBy: LastModifiedBy;

    @IsEmpty({ always: true })
    parentReference: ParentReference;

    @IsEmpty({ always: true })
    file: File;

    @IsEmpty({ always: true })
    fileSystemInfo: FileSystemInfo;

    @IsEmpty({ always: true })
    folder: Folder;
}

export class DocumentRecord implements Partial<SharepointDocument> {
    @IsEmpty({ groups: ["UPDATE"] })
    @IsString({ groups: ["CREATE"] })
    fileId: string;

    @IsEmpty({ groups: ["UPDATE"] })
    @IsString({ groups: ["CREATE"] })
    accountId: string;

    @IsOptional({ always: true })
    @IsInt({ always: true })
    categoryId?: number;

    @IsOptional({ always: true })
    @IsString({ always: true })
    comment?: string;

    @IsOptional({ always: true })
    @IsInt({ always: true })
    submissionId?: number;

    @IsOptional({ always: true })
    metadata?: { type: string; id: string } | null;

    @IsEmpty({ groups: ["CREATE"] })
    @IsOptional({ groups: ["UPDATE"] })
    @IsObject({ groups: ["UPDATE"] })
    @ValidateNested({ groups: ["UPDATE"] })
    @Type(() => SharepointDocumentResult)
    document?: SharepointDocumentResult | null;

    @IsEmpty({ always: true })
    category?: FileCategory;
}

export class UploadDocumentBody {
    @IsString()
    accountId: string;

    @IsOptional()
    @IsInt()
    categoryId?: number;

    @IsOptional()
    @IsInt()
    submissionId?: number;

    @IsOptional()
    @IsString()
    comment?: string;

    @IsOptional()
    metadata?: { type: string; id: string } | null;

    @IsOptional()
    @IsString()
    fileName?: string;
}

interface Application {
    id: string;
    displayName: string;
}

interface User {
    email: string;
    id: string;
    displayName: string;
}

interface CreatedBy {
    application: Application;
    user: User;
}

interface LastModifiedBy {
    application: Application;
    user: User;
}

interface ParentReference {
    driveType: string;
    driveId: string;
    id: string;
    path: string;
}

interface File {
    mimeType: string;
    hashes: {
        quickXorHash: string;
    };
}

interface FileSystemInfo {
    createdDateTime: string;
    lastModifiedDateTime: string;
}

interface Folder {
    childCount: number;
}
