import { useSize } from "../hooks/useSize";
import { useRef } from "react";
import { useSpring, animated, UseSpringProps } from "react-spring";

interface CollapseProps {
    isOpen: boolean;
    children: React.ReactNode;
    springConfig?: UseSpringProps["config"];
    containerProps?: React.HTMLAttributes<HTMLDivElement>;
}
export function Collapse({ isOpen, springConfig, containerProps, children }: CollapseProps): React.ReactElement {
    const ref = useRef<HTMLDivElement>(null);
    const [, height] = useSize(ref, true);

    const springProps = useSpring({
        height: isOpen ? height : 0,
        config: springConfig,
    });

    return (
        <animated.div className="text-gray-600" style={{ ...springProps, contain: "paint" }} {...containerProps}>
            <div ref={ref}>{children}</div>
        </animated.div>
    );
}
