import { DateTime } from "@deathstar/reuse";
import { SmsResult } from "../Basic/SmsResult";
import { CensusHistorical } from "../CensusHistorical/CensusHistorical";
import { Inspection } from "../Inspection/Inspection";
import { Inspections } from "../Inspections/Inspections";
import { MotorCarrier } from "../MotorCarrier";

export class CarrierOutOfServiceRate {
    #carrier: MotorCarrier;
    #totalBasicsOverThreshold: number;
    #totalBasicsOverThresholdBestAddressedRoadside: number;

    #recordDate: DateTime;
    #snapshotDate: DateTime;
    #inspections: Inspections;
    #smsResult: SmsResult;
    #censusData: CensusHistorical;

    readonly dateRange: MotorCarrier.IDates;
    readonly vehicle: number = 0;
    readonly driver: number = 0;
    readonly hazmat: number = 0;

    // ========================================================================
    constructor({ carrier, recordDate, snapshotDate }: CarrierOutOfServiceRate.Options) {
        this.#carrier = carrier;
        this.#recordDate = recordDate;
        this.#snapshotDate = snapshotDate;

        this.dateRange = MotorCarrier.calculateDates({
            snapshotDate,
        });

        this.#censusData = this.#carrier.getHistoricalCensusData(this.#recordDate);
        this.#inspections = carrier.inspections.filterByDateRange(this.dateRange);
        this.#smsResult = this.#carrier.smsResults.getByDate(this.#recordDate) || SmsResult.empty(this.#recordDate, this.#carrier);
        this.#totalBasicsOverThreshold = this.#smsResult.getTotalBasicsOverThreshold();
        this.#totalBasicsOverThresholdBestAddressedRoadside = this.#smsResult.getTotalBasicsOverThresholdBestAddressedRoadside();

        const results = this.#calculate();
        this.vehicle = results.vehicle;
        this.driver = results.driver;
        this.hazmat = results.hazmat;
    }

    // ========================================================================
    get recordDate(): DateTime {
        return this.#recordDate;
    }

    // ========================================================================
    get snapshotDate(): DateTime {
        return this.#snapshotDate;
    }

    // ========================================================================
    #calculate(): {
        vehicle: number;
        driver: number;
        hazmat: number;
    } {
        const vehicleInspections = this.#inspections.filterByType(Inspection.Type.VEHICLE);
        const oosVehicleInspections = vehicleInspections.filterByTypeOutOfService(Inspection.Type.VEHICLE);
        const totalVehicleInspections = vehicleInspections.total;
        const totalVehicleOosInspections = oosVehicleInspections.total;

        const driverInspections = this.#inspections.filterByType(Inspection.Type.DRIVER);
        const oosDriverInspections = driverInspections.filterByTypeOutOfService(Inspection.Type.DRIVER);
        const totalDriverInspections = driverInspections.total;
        const totalDriverOosInspections = oosDriverInspections.total;

        const hazmatInspections = this.#inspections.filterByType(Inspection.Type.HAZMAT);
        const oosHazmatInspections = hazmatInspections.filterByTypeOutOfService(Inspection.Type.HAZMAT);
        const totalHazmatInspections = hazmatInspections.total;
        const totalHazmatOosInspections = oosHazmatInspections.total;

        const vehicleRate = Math.round((totalVehicleOosInspections / totalVehicleInspections) * 1000) / 10;
        const driverRate = Math.round((totalDriverOosInspections / totalDriverInspections) * 1000) / 10;
        const hzamatRate = Math.round((totalHazmatOosInspections / totalHazmatInspections) * 1000) / 10;

        return {
            vehicle: isNaN(vehicleRate) ? 0 : vehicleRate,
            driver: isNaN(driverRate) ? 0 : driverRate,
            hazmat: isNaN(hzamatRate) ? 0 : hzamatRate,
        };
    }

    // ========================================================================
    json(): CarrierOutOfServiceRate.JSON {
        return {
            vehicle: this.vehicle,
            driver: this.driver,
            hazmat: this.hazmat,
        };
    }

    // ========================================================================
    toString(): string {
        return JSON.stringify(this.json());
    }
}

export namespace CarrierOutOfServiceRate {
    export interface Options {
        carrier: MotorCarrier;
        recordDate: DateTime;
        snapshotDate: DateTime;
    }

    export interface JSON {
        vehicle: number;
        driver: number;
        hazmat: number;
    }
}
