import { ExpiredViolations } from "./ExpiredViolations";
import { NewViolations } from "./NewViolations";
import { SummarySection } from "./SummarySection";

export function ViolationSummary(): JSX.Element {
    return (
        <SummarySection className="flex gap-4 px-12 print:bg-white ">
            <NewViolations />
            <ExpiredViolations />
        </SummarySection>
    );
}
