import { plainToClass, Type } from "class-transformer";
import { NumberFormatter } from "../util";
import { Attribute } from "./Attribute";
import { AttributeOption } from "./AttributeOption";
import { Comparison } from "./Comparison/Comparison";
import { Coverage } from "./Coverage";
import { CoverageLinkedVehicle } from "./CoverageLinkedVehicle";
import { CoverageOption } from "./CoverageOption";
import { EquipmentComparison } from "./PolicyLayer";
import { Trailer } from "./Trailer";

export class CoverageLinkedTrailer implements CoverageLinkedVehicle, EquipmentComparison.IIsComparable {
    static init(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): CoverageLinkedTrailer {
        const { linkedTrailer } = options;
        if (linkedTrailer instanceof CoverageLinkedTrailer && linkedTrailer.#initialized) return linkedTrailer;

        const e = plainToClass(CoverageLinkedTrailer, linkedTrailer);
        e.coverage = options.coverage;
        e.attributes = CoverageLinkedTrailer.getAttributes(options);
        e.statedValue = CoverageLinkedTrailer.getStatedValue(options);
        e.hasCollision = CoverageLinkedTrailer.hasCollision(options);
        e.uniqueCollisionDeductible = CoverageLinkedTrailer.getUniqueCollisionDeductible(options);
        e.hasComprehensive = CoverageLinkedTrailer.hasComprehensive(options);
        e.uniqueComprehensiveDeductible = CoverageLinkedTrailer.getUniqueComprehensiveDeductible(options);
        e.isSpareRated = CoverageLinkedTrailer.getIsSpareRated(options);
        e.isOwnerOperator = CoverageLinkedTrailer.getIsOwnerOperator(options);
        e.isValuationActualCashValue = CoverageLinkedTrailer.getIsValuationActualCashValue(options);
        e.#initialized = true;
        return e;
    }

    static getAttributes({ coverage, linkedTrailer }: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): Attribute[] {
        if (!coverage) return [];
        return coverage.attributes.filter((a) => a.trailerId === linkedTrailer.trailerId);
    }

    static hasAttribute({
        coverage,
        linkedTrailer,
        attributeOptionId,
    }: {
        coverage?: Coverage;
        linkedTrailer: CoverageLinkedTrailer;
        attributeOptionId: AttributeOption.Id;
    }): boolean {
        const tractorAttributes = CoverageLinkedTrailer.getAttributes({ coverage, linkedTrailer });
        return tractorAttributes.some((a) => a.attributeOptionId === attributeOptionId);
    }

    static #getAttributes({ coverage, linkedTrailer }: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): Attribute[] {
        return linkedTrailer.attributes || CoverageLinkedTrailer.getAttributes({ coverage, linkedTrailer });
    }

    static #getComprehensiveAttribute(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): Attribute {
        return CoverageLinkedTrailer.#getAttributes(options).find((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COMPREHENSIVE);
    }

    static hasComprehensive(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): boolean {
        return CoverageLinkedTrailer.#getAttributes(options).some((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COMPREHENSIVE);
    }

    static #getCollisionAttribute(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): Attribute {
        return CoverageLinkedTrailer.#getAttributes(options).find((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COLLISION);
    }

    static hasCollision(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): boolean {
        return CoverageLinkedTrailer.#getAttributes(options).some((a) => a.attributeOptionId === AttributeOption.Id.UNIT_COLLISION);
    }

    static getUniqueComprehensiveDeductible(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): number | null {
        const compAttr = CoverageLinkedTrailer.#getComprehensiveAttribute(options);
        if (!compAttr) return null;
        return typeof compAttr.valueNumber === "number" ? compAttr.valueNumber : null;
    }

    static getUniqueCollisionDeductible(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): number | null {
        const compAttr = CoverageLinkedTrailer.#getCollisionAttribute(options);
        if (!compAttr) return null;
        return typeof compAttr.valueNumber === "number" ? compAttr.valueNumber : null;
    }

    static getStatedValue(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): number | null {
        const statedValueAttr = CoverageLinkedTrailer.#getAttributes(options).find(
            (a) => a.attributeOptionId === AttributeOption.Id.STATED_VALUE
        );
        return statedValueAttr?.valueNumber || null;
    }

    static getIsSpareRated(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): boolean {
        return CoverageLinkedTrailer.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.UNIT_SPARE_RATED });
    }

    static getIsOwnerOperator(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): boolean {
        return CoverageLinkedTrailer.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.UNIT_OWNER_OPERATOR });
    }

    static getIsValuationActualCashValue(options: { coverage?: Coverage; linkedTrailer: CoverageLinkedTrailer }): boolean {
        return CoverageLinkedTrailer.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE });
    }

    static compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Comparison.IOptions<CoverageLinkedTrailer>): Map<CoverageOption.Id, CoverageLinkedTrailer.IComparisonReturn> {
        const map = new Map<CoverageOption.Id, CoverageLinkedTrailer.IComparisonReturn>();

        if (compare) {
            for (const [cvgOptId, linkedTrailer] of Object.entries(compare) as unknown as [CoverageOption.Id, CoverageLinkedTrailer][]) {
                const from = base?.[cvgOptId];
                const to = linkedTrailer;
                map.set(cvgOptId, CoverageLinkedTrailer.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        if (base) {
            for (const [cvgOptId, linkedTrailer] of Object.entries(base) as unknown as [CoverageOption.Id, CoverageLinkedTrailer][]) {
                if (map.has(cvgOptId)) continue;
                const from = linkedTrailer;
                const to = compare?.[cvgOptId];
                map.set(cvgOptId, CoverageLinkedTrailer.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        return map;
    }

    static #compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Partial<Comparison.IOptions.Entity<CoverageLinkedTrailer>>): CoverageLinkedTrailer.IComparisonReturn {
        const comparison = new Comparison(CoverageLinkedTrailer, base, compare) as CoverageLinkedTrailer.IComparisonReturn;
        comparison.setField("trailerId", compare?.trailerId || base?.trailerId);
        comparison.setField("trailer", compare?.trailer || base?.trailer);

        CoverageLinkedVehicle.compare({
            comparison,
            base,
            compare,
            comparisonLayer,
            baseLayer,
            label: "Trailer",
            descriptions: {
                add: `Add trailer ${compare?.toString()}`,
                remove: `Delete trailer ${base?.trailer?.vin}`,
            },
        });

        return comparison;
    }

    #initialized = false;

    id: number;
    createdDate: Date;

    trailerId: number;
    @Type(() => Trailer)
    trailer?: Trailer;

    coverageId: number;
    coverageCreatedDate: Date;
    coverage?: Coverage;

    attributes?: Attribute[];
    statedValue?: number;
    hasCollision?: boolean;
    uniqueCollisionDeductible?: number | null;
    hasComprehensive?: boolean;
    uniqueComprehensiveDeductible?: number | null;
    isSpareRated?: boolean;
    isOwnerOperator?: boolean;
    isValuationActualCashValue?: boolean;
    comparison?: {
        isNewToPolicy?: boolean;
        isDeletedFromPolicy?: boolean;
    };

    compare(
        comparisonLinkedTrailer: CoverageLinkedTrailer,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CoverageLinkedTrailer> {
        return CoverageLinkedTrailer.#compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonLinkedTrailer,
                },
                options
            )
        );
    }

    toString(): string {
        if (!this.trailer) {
            return `CoverageLinkedTrailer: ${this.trailerId}`;
        }
        const baseStringParts = [this.trailer.toString()];
        if (this.isValuationActualCashValue) {
            baseStringParts.push("(ACV)");
        } else if (typeof this.statedValue === "number") {
            baseStringParts.push(`(SV: ${NumberFormatter.Currency.format(this.statedValue)})`);
        }
        return baseStringParts.join(" ");
    }
}

export namespace CoverageLinkedTrailer {
    export interface IComparisonReturn extends Comparison<CoverageLinkedTrailer> {
        trailerId: number;
        trailer?: Trailer;
    }
}
