import { OrganizationInvitation } from "@deathstar/types/waypoint";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const invitations = {
    queryKeys: {
        find: (accountId: string) => [accountId, "invitations"] as QueryKey,
    },

    find: (accountId: string) => {
        return blazar.fetchJson<OrganizationInvitation[]>(`/waypoint/invites/${accountId}`);
    },

    accept: (id: string) => {
        return blazar.fetch(`waypoint/invites/${id}/accept`, { method: "POST" });
    },

    decline: (id: string) => {
        return blazar.fetch(`waypoint/invites/${id}/decline`, { method: "POST" });
    },

    send: async (accountId: string, body: { email: string }) => {
        return await blazar.fetchJson<void>(`/waypoint/invites/${accountId}`, {
            method: "POST",
            body: JSON.stringify(body),
        });
    },

    cancel: async (accountId: string, id: string) => {
        return await blazar.fetchJson<void>(`/waypoint/invites/${accountId}/${id}`, {
            method: "DELETE",
        });
    },

    useFind: <T = OrganizationInvitation[]>(
        accountId: string,
        config?: Partial<UseQueryOptions<OrganizationInvitation[], ResponseError, T>>
    ) =>
        useQuery<OrganizationInvitation[], ResponseError, T>({
            queryKey: invitations.queryKeys.find(accountId),
            queryFn: () => invitations.find(accountId),
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            ...config,
        }),

    useSend: (accountId: string, config?: UseMutationOptions<void, ResponseError, { email: string }>) => {
        return useMutation<void, ResponseError, { email: string }>({
            mutationFn: (data) => {
                return invitations.send(accountId, data);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: invitations.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },

    useCancel: (accountId: string, config?: UseMutationOptions<void, ResponseError, string>) => {
        return useMutation<void, ResponseError, string>({
            mutationFn: (id) => {
                return invitations.cancel(accountId, id);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: invitations.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },
};
