import { EnvelopeIcon, MapPinIcon, PhoneIcon, PrinterIcon } from "@heroicons/react/24/solid";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

const Footer = forwardRef<HTMLElement>(function (_, ref) {
    return (
        <footer ref={ref} className="mt-4 shrink bg-stone-50 px-12 pb-4 pt-8 text-xs text-gray-600 lg:text-sm">
            <div className="grid grid-flow-row gap-8 overflow-auto lg:grid-cols-3">
                <div>
                    <p className="px-2 text-sm lg:text-base">Contact</p>
                    <hr />
                    <ul className="mt-2 space-y-2 px-2">
                        <li>
                            <a href="tel:+18005968782">
                                <PhoneIcon className="-mt-1 mr-2 inline h-3 w-3 opacity-80" />
                                1-800-596-TRUCK
                            </a>
                        </li>
                        <li>
                            <a href="tel:+16164577301">
                                <PrinterIcon className="-mt-1 mr-2 inline h-3 w-3 opacity-80" />
                                1-616-457-7301
                            </a>
                        </li>
                        <li>
                            <a href="mailto:clientservice@navigatortruckinsurance.com" className="flex items-center gap-2">
                                <EnvelopeIcon className="h-3 w-3 opacity-80" />
                                <span>clientservice@navigatortruckinsurance.com</span>
                            </a>
                        </li>
                        <li>
                            <a
                                className="flex items-center gap-2"
                                href="https://maps.google.com/?saddr=My+Location&daddr=529+Baldwin+Street+Jenison+MI+49428"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <MapPinIcon className="h-3 w-3 opacity-80" />
                                <span>529 Baldwin Street, Jenison, MI 49428</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <p className="px-2 text-sm lg:text-base">Legal</p>
                    <hr />
                    <ul className="mt-2 space-y-2 px-2">
                        <li>
                            <Link to="/legal/policy-disclaimer">Policy disclaimer</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
});

export default Footer;
