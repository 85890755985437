import {
    CertificateBuilderConfigItem,
    CertificateWithHolderCount,
    DirectSendCertificateResult,
    GlobalCertificateHolder,
    MasterCertificate,
} from "@deathstar/types/northstar";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

interface CertificateSendBody {
    holder: number | Pick<GlobalCertificateHolder, "name" | "street" | "street2" | "city" | "state" | "zip">;
    email: {
        to: string;
        ccAddress?: string;
    };
    options: CertificateBuilderConfigItem[];
}

interface CertificateDownloadBody {
    holder: number | Pick<GlobalCertificateHolder, "name" | "street" | "street2" | "city" | "state" | "zip"> | null;
    options: CertificateBuilderConfigItem[];
}

export const certificates = {
    queryKeys: {
        find: (accountId: string) => [accountId, "certificates"] as QueryKey,
        findOne: (accountId: string, certificateId: number) => [accountId, "certificates", certificateId] as QueryKey,
        preview: (accountId: string, certificateId: number) => [accountId, "certificates", certificateId, "preview"] as QueryKey,
        previewUrl: (accountId: string, certificateId: number) => [accountId, "certificates", certificateId, "preview", "url"] as QueryKey,
    },

    find: (accountId: string) => {
        return blazar.fetchJson<CertificateWithHolderCount[]>(`/waypoint/orgs/${accountId}/certificates`);
    },

    useFind: (accountId: string, config?: Partial<UseQueryOptions<CertificateWithHolderCount[], ResponseError>>) =>
        useQuery<CertificateWithHolderCount[], ResponseError>({
            queryKey: certificates.queryKeys.find(accountId),
            queryFn: () => {
                return certificates.find(accountId);
            },
            staleTime: 30000,
            retry: (count, error) => {
                return count < 3 && error.status !== 401 && error.status !== 403 && error.status !== 404 && error.status !== 429;
            },
            ...config,
        }),

    findOne: (accountId: string, certificateId: number) => {
        return blazar.fetchJson<MasterCertificate>(`/waypoint/orgs/${accountId}/certificates/${certificateId}`);
    },

    useFindOne: (accountId: string, certificateId: number, config?: Partial<UseQueryOptions<MasterCertificate, ResponseError>>) =>
        useQuery<MasterCertificate, ResponseError>({
            queryKey: certificates.queryKeys.findOne(accountId, certificateId),
            queryFn: () => {
                return certificates.findOne(accountId, certificateId);
            },
            staleTime: 30000,
            retry: (count, error) => {
                return count < 3 && error.status !== 401 && error.status !== 403 && error.status !== 404 && error.status !== 429;
            },
            ...config,
        }),

    preview: async (accountId: string, certificateId: number) => {
        const response = await blazar.fetch(`/waypoint/orgs/${accountId}/certificates/${certificateId}/preview`);
        return response.blob();
    },

    usePreview: <T = Blob>(accountId: string, certificateId: number, config?: Partial<UseQueryOptions<Blob, ResponseError, T>>) =>
        useQuery<Blob, ResponseError, T>({
            queryKey: certificates.queryKeys.preview(accountId, certificateId),
            queryFn: () => {
                return certificates.preview(accountId, certificateId);
            },
            staleTime: 30000,
            retry: (count, error) => {
                return count < 3 && error.status !== 401 && error.status !== 403 && error.status !== 404 && error.status !== 429;
            },
            ...config,
        }),

    usePreviewUrl: (accountId: string, certificateId: number, config?: Partial<UseQueryOptions<string, ResponseError>>) =>
        useQuery<string, ResponseError>({
            queryKey: certificates.queryKeys.previewUrl(accountId, certificateId),
            queryFn: async () => {
                const blob = await certificates.preview(accountId, certificateId);
                return URL.createObjectURL(blob);
            },
            staleTime: 30000,
            retry: (count, error) => {
                return count < 3 && error.status !== 401 && error.status !== 403 && error.status !== 404 && error.status !== 429;
            },
            ...config,
        }),

    send: async (accountId: string, certificateId: number, body: CertificateSendBody) => {
        return await blazar.fetchJson<DirectSendCertificateResult>(`/waypoint/orgs/${accountId}/certificates/${certificateId}/send`, {
            method: "POST",
            body: JSON.stringify(body),
        });
    },

    useSend: (
        accountId: string,
        certificateId: number,
        config?: UseMutationOptions<DirectSendCertificateResult, ResponseError, CertificateSendBody>
    ) => {
        return useMutation({
            mutationFn: (body) => {
                return certificates.send(accountId, certificateId, body);
            },
            ...config,
        });
    },

    download: async (accountId: string, certificateId: number, body: CertificateDownloadBody) => {
        const response = await blazar.fetch(`/waypoint/orgs/${accountId}/certificates/${certificateId}/download`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const contentDisposition = response.headers.get("Content-Disposition");
        const fileName = contentDisposition?.match(/filename="(.*)"/)?.[1] || "certificate.pdf";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    },

    useDownload: (accountId: string, certificateId: number, config?: UseMutationOptions<void, ResponseError, CertificateDownloadBody>) => {
        return useMutation({
            mutationFn: (body) => {
                return certificates.download(accountId, certificateId, body);
            },
            ...config,
        });
    },
};
