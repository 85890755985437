import { Policy } from "@deathstar/types/northstar";
import api from "../../../api/api";
import { useAccountId } from "../../../api/useAccountId";
import Unauthorized from "../../../components/error-screens/Unauthorized";
import UncaughtException from "../../../components/error-screens/UncaughtException";
import Loader from "../../../components/loader/Loader";
import PoliciesListItem from "./PoliciesListItem";

export interface PolicyWithBillingInfo extends Policy {
    billingInfo: {
        companyName: string;
        details: {
            description?: string;
            address?: {
                line1: string;
                line2?: string;
                city: string;
                state: string;
                postalCode: string;
            };
            phone?: string;
            website?: string;
        }[];
    };
    claimsInfo: {
        companyName: string;
        phone?: string;
        website?: string;
        email?: string;
        fax?: string;
    };
    driverStandardsUrl: string;
}

export default function PoliciesList({ filter }: { filter: "active" | "inactive" }) {
    const accountId = useAccountId();
    const { data, error, isError, isLoading } = api.policies.useFind(accountId!, filter);

    return isError ? (
        error.status === 403 ? (
            <Unauthorized />
        ) : (
            <UncaughtException />
        )
    ) : isLoading ? (
        <Loader />
    ) : (
        <ul className="w-full">
            {data?.length ? data?.map((policy) => <PoliciesListItem key={policy.id} policy={policy} />) : <li>No policies</li>}
        </ul>
    );
}
