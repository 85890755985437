import { PrimaryButton } from "@deathstar/ui";
import { Link } from "react-router-dom";
import src from "../../assets/images/not-found.svg";
import BaseErrorScreen from "./BaseErrorScreen";

export default function UncaughtException() {
    return (
        <BaseErrorScreen
            title="We took a wrong turn somewhere"
            description="It looks like you ran into a bug in the system. We've automatically captured some details about this issue. If you continue to have problems, please contact our help team."
            primaryAction={
                <Link to="/sol" className="block w-max">
                    <PrimaryButton>Drive back home</PrimaryButton>
                </Link>
            }
            image={<img src={src} alt="empty road" className="hidden w-1/3 lg:block" />}
        />
    );
}
