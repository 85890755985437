import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import type { MotorCarrier } from "../../../MotorCarrier";
import type { Violation } from "../../../Violation/Violation";
import { Violations } from "../../Violations";
import type { Breakdown } from "../Breakdown";
import { BASIC } from "./BASIC";

export class DriverFitness extends BASIC<DriverFitness.GroupNames, DriverFitness.SubGroupNames> {
    constructor(breakdown: Breakdown, carrier: MotorCarrier, date: DateTime, vmViolations: Violations) {
        if (!Array.from(vmViolations).every((viol) => viol.basic === FMCSA.BasicName.DRIVER_FITNESS)) {
            throw new Error("Not all violations are Driver Fitness violations");
        }

        const violations: Record<string, Violation[]> = {
            licenseHigh: [],
            licenseMedium: [],
            licenseLow: [],
            medCert: [],
            physical: [],
            genDriverQual: [],
            fraud: [],
            other: [],
        };

        Array.from(vmViolations).forEach((violation) => {
            const groupDescription = violation.get("GroupDescription");

            switch (groupDescription) {
                case "License-related: High":
                    violations.licenseHigh.push(violation);
                    break;
                case "License-related: Medium":
                    violations.licenseMedium.push(violation);
                    break;
                case "License-related: Low":
                    violations.licenseLow.push(violation);
                    break;
                case "Medical Certificate":
                    violations.medCert.push(violation);
                    break;
                case "Physical":
                    violations.physical.push(violation);
                    break;
                case "General Driver Qualification":
                    violations.genDriverQual.push(violation);
                    break;
                case "Fraud":
                    violations.fraud.push(violation);
                    break;
                default:
                    violations.other.push(violation);
                    break;
            }
        });

        super(breakdown, carrier, date, vmViolations, [
            {
                name: "License",
                subGroups: [
                    { name: "License-related: High", violations: Violations.of(carrier, violations.licenseHigh) },
                    { name: "License-related: Medium", violations: Violations.of(carrier, violations.licenseMedium) },
                    { name: "License-related: Low", violations: Violations.of(carrier, violations.licenseLow) },
                ],
            },
            {
                name: "Health",
                subGroups: [
                    { name: "Medical Certificate", violations: Violations.of(carrier, violations.medCert) },
                    { name: "Physical", violations: Violations.of(carrier, violations.physical) },
                ],
            },
            {
                name: "General Driver Qualification",
                subGroups: [{ name: "General Driver Qualification", violations: Violations.of(carrier, violations.genDriverQual) }],
            },
            {
                name: "Other",
                subGroups: [
                    { name: "Fraud", violations: Violations.of(carrier, violations.fraud) },
                    { name: "Other", violations: Violations.of(carrier, violations.other) },
                ],
            },
        ]);
    }

    // ========================================================================
    get(groupName: "License"): Breakdown.Group<DriverFitness.GroupNames, DriverFitness.SubGroupNames.LicenseRelated>;
    get(groupName: "Health"): Breakdown.Group<DriverFitness.GroupNames, DriverFitness.SubGroupNames.Health>;
    get(
        groupName: "General Driver Qualification"
    ): Breakdown.Group<DriverFitness.GroupNames, DriverFitness.SubGroupNames.GeneralDriverQualification>;
    get(groupName: "Other"): Breakdown.Group<DriverFitness.GroupNames, DriverFitness.SubGroupNames.Other>;
    get(groupName: DriverFitness.GroupNames): Breakdown.Group<DriverFitness.GroupNames, DriverFitness.SubGroupNames> {
        return super.get(groupName);
    }

    // ========================================================================
    getHistory(): DriverFitness[] {
        return this.breakdown.getHistory().driverFitness;
    }
}

export namespace DriverFitness {
    export type GroupNames = "License" | "Health" | "General Driver Qualification" | "Other";

    export namespace SubGroupNames {
        export type LicenseRelated = "License-related: High" | "License-related: Medium" | "License-related: Low";

        export type Health = "Medical Certificate" | "Physical";

        export type GeneralDriverQualification = "General Driver Qualification";

        export type Other = "Fraud" | "Other";
    }

    export type SubGroupNames =
        | SubGroupNames.LicenseRelated
        | SubGroupNames.Health
        | SubGroupNames.GeneralDriverQualification
        | SubGroupNames.Other;
}
