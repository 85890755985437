import { MotorCarrier } from "@deathstar/motor-carrier";
import { FMCSA } from "@deathstar/types";
import { classNames } from "@deathstar/ui";
import { Typography } from "@material-ui/core";
import { BasicColorSwatch } from "../BasicColorSwatch";
import { config } from "../ChartConfig";
import { Transition } from "../Transition";

export function ViolationSummaryLegend({
    violations,
    className,
}: {
    violations: MotorCarrier.IViolations;
    className?: string;
}): JSX.Element | null {
    if (!violations.total) return null;

    const { hazmat, controlledSubstances, driverFitness, vehicleMaintenance, hoursOfService, unsafeDriving } = violations.filterByBasics();

    const legendData = [
        {
            name: FMCSA.BasicName.HAZMAT,
            score: hazmat.total,
            color: config.colors[FMCSA.BasicName.HAZMAT],
        },
        {
            name: FMCSA.BasicName.CONTROLLED_SUBSTANCES,
            score: controlledSubstances.total,
            color: config.colors[FMCSA.BasicName.CONTROLLED_SUBSTANCES],
        },
        {
            name: FMCSA.BasicName.DRIVER_FITNESS,
            score: driverFitness.total,
            color: config.colors[FMCSA.BasicName.DRIVER_FITNESS],
        },
        {
            name: FMCSA.BasicName.VEHICLE_MAINTENANCE,
            score: vehicleMaintenance.total,
            color: config.colors[FMCSA.BasicName.VEHICLE_MAINTENANCE],
        },
        {
            name: FMCSA.BasicName.HOS,
            score: hoursOfService.total,
            color: config.colors[FMCSA.BasicName.HOS],
        },
        {
            name: FMCSA.BasicName.UNSAFE_DRIVING,
            score: unsafeDriving.total,
            color: config.colors[FMCSA.BasicName.UNSAFE_DRIVING],
        },
    ].filter(({ score }) => score > 0);

    return (
        <div className={classNames("flex w-fit flex-col", className, {})}>
            <table>
                <tbody className="text-gray-800 print:text-gray-700">
                    {legendData.map((legend, i) => (
                        <tr key={legend.name}>
                            <td>
                                <BasicColorSwatch basic={legend.name} />
                            </td>
                            <td className="px-4">
                                <Transition.Slide
                                    delay={i * 200}
                                    className="text-left"
                                    SlideProps={{
                                        direction: "left",
                                    }}
                                >
                                    <Typography className="ia-transition-element">{legend.name}</Typography>
                                </Transition.Slide>
                            </td>
                            <td>
                                <Transition.Fade delay={(i + 1) * 200 + legendData.length * 200}>
                                    <Typography className="ia-transition-element">{legend.score}</Typography>
                                </Transition.Fade>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
