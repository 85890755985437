import { DateTime } from "@deathstar/reuse";
import { MotorCarrier } from "../MotorCarrier";

export class TimeWeight {
    // ========================================================================
    static of(options: TimeWeight.Options): number {
        return new TimeWeight(options).#calculate();
    }

    // ========================================================================
    #dates: MotorCarrier.IDates;
    #inspectionDate: DateTime;
    #value: number;
    constructor({ inspectionDate, targetDate }: TimeWeight.Options) {
        this.#inspectionDate = inspectionDate;
        this.#dates = MotorCarrier.calculateDates({ snapshotDate: targetDate });
        this.#value = this.#calculate();
    }

    // ========================================================================
    get value(): number {
        return this.#value;
    }

    // ========================================================================
    /**
     * Calculates the Time Weight.
     * According to the SMS Methodology:
     * - Violations recorded in the past six months receive a time weight of 3.
     * - Violations recorded over six months and up to 12 months ago receive a
     *  time weight of 2.
     * - All violations recorded earlier (older than 12 months but within the
     *  past 24 months) receive a time weight of 1.
     */
    #calculate(): number {
        let timeWeight = 0;

        if (this.#inspectionDate.isAfterOrEqual(this.#dates.sixMonthMark, { excludeTime: true })) {
            timeWeight = 3;
        } else if (this.#inspectionDate.isAfterOrEqual(this.#dates.twelveMonthMark, { excludeTime: true })) {
            timeWeight = 2;
        } else if (this.#inspectionDate.isAfterOrEqual(this.#dates.from, { excludeTime: true })) {
            timeWeight = 1;
        }

        return timeWeight;
    }
}

export declare namespace TimeWeight {
    interface Options {
        inspectionDate: DateTime;
        targetDate: DateTime;
    }
}
