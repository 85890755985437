/* eslint-disable @typescript-eslint/no-explicit-any */
import { NumberFormatter } from "../util";
import { CoverageOption } from "./CoverageOption";
import { GeneralLedgerTransaction } from "./GeneralLedgerTransaction";
import { InvoiceClientPremium } from "./InvoiceClientPremium";
import { InvoiceCommission } from "./InvoiceCommission";
import { InvoiceCommissionBreakout } from "./InvoiceCommissionBreakout";
import { InvoiceCustomerPremium } from "./InvoiceCustomerPremium";
import type { InvoiceTransaction } from "./InvoiceTransaction";
import type { Policy } from "./Policy";
import { User } from "./User";

enum InvoiceStatus {
    Paid = "Paid",
    Overdue = "Overdue",
    Due = "Due",
    Pending = "Pending",
}

export class Invoice {
    static calculateTotalCharged({ invoice }: { invoice: Invoice }): number {
        const totalCharged = invoice.transactions?.reduce((tot, tran) => {
            const invalidDescriptionSnippets = ["escrow deposit", "memo/spt", "amount financed", "reversal of"];
            const lcDesc = tran.description?.toLowerCase();
            for (const snip of invalidDescriptionSnippets) {
                if (lcDesc.includes(snip)) return tot;
            }
            return tot + tran.premium;
        }, 0);

        return NumberFormatter.round(totalCharged) || 0;
    }

    static calculateTotalPaid({ invoice, isDirectBill }: { invoice: Invoice; isDirectBill: boolean }): number {
        const totalPaid = isDirectBill
            ? invoice.transactions?.reduce((acc2, t) => {
                  return acc2 + t.premium;
              }, 0)
            : Invoice.#calculateTotalPaidOnDirectBill({ invoice });

        const smallBalanceWaivedAmount = invoice.generalLedgerTransactions?.reduce((tot, tran) => {
            if (tran.description.toLowerCase() !== "small balance waived") return tot;
            return tot + tran.creditAmount;
        }, 0);

        return NumberFormatter.round(totalPaid + smallBalanceWaivedAmount) || 0;
    }

    static #calculateTotalPaidOnDirectBill({ invoice }: { invoice: Invoice }): number {
        const escrowDepositTrans = invoice.generalLedgerTransactions.find(
            (glt) => glt.description?.toLowerCase().includes("escrow deposit") && glt.debitAmount > 0
        );
        const escrowPaymentTranFromInvoice =
            escrowDepositTrans &&
            invoice.generalLedgerTransactions.filter(
                (glt) => glt.description?.toLowerCase().startsWith("from invoice") && glt.creditAmount > 0
            );
        const totalEscrowPaidFromOtherInvoices =
            escrowDepositTrans && escrowPaymentTranFromInvoice.reduce((tot, glt) => tot + glt.creditAmount, 0);

        const total = invoice.generalLedgerTransactions.reduce((tot, glt) => {
            const lcDesc = glt.description.toLowerCase();
            (glt as any).ignored = true;
            if (glt.sourceId <= 1) return tot;
            if (glt.sourceId === 2 && glt.sourceTypeId !== 5) return tot;
            if (glt.sourceId > 4) return tot;
            if (lcDesc === "company statement") return tot;

            (glt as any).ignored = false;
            (glt as any).totalBefore = tot;

            // refund check
            if (glt.sourceId === 2 && glt.sourceTypeId === 5) {
                (glt as any).action = `${tot} - ${glt.debitAmount}`;
                const newTotal = tot - glt.debitAmount;
                (glt as any).totalAfter = newTotal;
                return newTotal;
            }

            // refund EFT
            if (glt.sourceId === 3 && glt.sourceTypeId === 24) {
                (glt as any).action = `${tot} - ${glt.creditAmount}`;
                const newTotal = tot - glt.creditAmount;
                (glt as any).totalAfter = newTotal;
                return newTotal;
            }

            (glt as any).action = glt.sourceId === 4 ? `${tot} + ${glt.creditAmount} - ${glt.debitAmount}` : `${tot} + ${glt.creditAmount}`;
            const newTotal = glt.sourceId === 4 ? tot + glt.creditAmount - glt.debitAmount : tot + glt.creditAmount;
            (glt as any).totalAfter = newTotal;
            return newTotal;
        }, 0);

        if (totalEscrowPaidFromOtherInvoices && totalEscrowPaidFromOtherInvoices === escrowDepositTrans?.debitAmount) {
            return total - totalEscrowPaidFromOtherInvoices;
        }
        return total;
    }

    id: string;
    number: number;
    transactions?: InvoiceTransaction[];
    generalLedgerTransactions?: GeneralLedgerTransaction[];
    clientPremiums?: InvoiceClientPremium[];
    commissions?: InvoiceCommission[];
    commissionBreakouts?: InvoiceCommissionBreakout[];
    customerPremium?: InvoiceCustomerPremium[];
    debitAmount?: number;
    creditAmount?: number;
    isPaid?: boolean;
    policyNumber: string;
    policyId: string;
    agentCode?: string;
    agent?: User;
    accountManagerCode?: string;
    accountManager?: User;
    isInstallment?: boolean;
    isCancelled?: boolean;
    isPosted?: boolean;
    billMethod: Policy.BillMethod;
    effectiveDate: Date;
    invoiceDate: Date;
    dueDate: Date;
    changedDate: Date;
    enteredDate: Date;
}

export class InvoiceWithStatementDate extends Invoice {
    public static Status = InvoiceStatus;
    statementDate: string | null;
    status: InvoiceStatus;
    coverageOptions: CoverageOption["id"][];
}
