/**
 * @example accessorFnGenerator(it.get("FieldName")) => "Yes" | "No" | null
 * @example accessorFnGenerator(it.get("FieldName"), true) => ["Yes"] | ["No"] | [null]
 * @param value the value
 * @param array if the return value should be in an array
 */
export function accessorFnGenerator(value: boolean | null | undefined, array?: never | false): "Yes" | "No" | null;
export function accessorFnGenerator(value: boolean | null | undefined, array?: true): string[] | null[];
export function accessorFnGenerator(value: boolean | null | undefined, array?: boolean): "No" | string[] | "Yes" | null[] | null {
    if (value === false) return array ? ["No"] : "No";
    if (value === true) return array ? ["Yes"] : "Yes";
    return array ? [null] : null;
}
