import { Paper } from "@material-ui/core";
import { PrintSectionName } from "../Components/PrintSectionName";
import { PrimaryScoreContributorsTable } from "./PrimaryScoreContributorsTable/PrimaryScoreContributorsTable";
import { TopViolationsBreakdown } from "./TopViolationsBreakdown/TopViolationsBreakdown";

export function Insights(): JSX.Element {
    return (
        <div className="page relative">
            <PrintSectionName name="Insights" />
            <Paper data-page-break="after" className="w-full" elevation={0}>
                <PrimaryScoreContributorsTable />
                <TopViolationsBreakdown />
            </Paper>
        </div>
    );
}
