import { BusinessAuto } from "./BusinessAuto";
import { NumberFormatter } from "../util/NumberFormatter/NumberFormatter";
import type { PolicyAdditionalInterest } from "./PolicyAdditionalInterest";

export class PolicyAdditionalInterestLinkedBusinessAuto implements PolicyAdditionalInterest.LinkedItem {
    id: number;
    createdDate: Date;

    businessAutoId: number;
    businessAuto?: BusinessAuto;

    policyAdditionalInterestId: number;
    policyAdditionalInterestCreatedDate: Date;
    policyAdditionalInterest?: PolicyAdditionalInterest;

    toString(): string {
        if (this.businessAuto) {
            const { year, make, value, vin } = this.businessAuto;
            const base: (number | string)[] = [];
            if (year) base.push(year);
            if (make) base.push(make);
            if (value) base.push(NumberFormatter.Currency.format(value));
            if (vin) base.push(vin);

            return base.join(" ");
        }

        return `PolicyAdditionalInterestLinkedBusinessAuto: ${this.businessAutoId}`;
    }
}
