import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import type { MotorCarrier } from "../../../MotorCarrier";
import type { Violation } from "../../../Violation/Violation";
import { Violations } from "../../Violations";
import type { Breakdown } from "../Breakdown";
import { BASIC } from "./BASIC";

export class HoursOfService extends BASIC<HoursOfService.GroupNames, HoursOfService.SubGroupNames> {
    constructor(breakdown: Breakdown, carrier: MotorCarrier, date: DateTime, hosViolations: Violations) {
        if (!Array.from(hosViolations).every((viol) => viol.basic === FMCSA.BasicName.HOS)) {
            throw new Error("Not all violations are HOS violations");
        }

        const violations: Record<string, Violation[]> = {
            falseLog: [],
            incompleteLog: [],
            hours: [],
            hoursNominal: [],
            jumpingOos: [],
            docRelated: [],
            operatorError: [],
            installation: [],
            eldOther: [],
            otherLogManner: [],
            other: [],
        };

        Array.from(hosViolations).forEach((violation) => {
            const groupDescription = violation.get("GroupDescription");
            const sectionDescription = violation.get("SectionDescription");

            if (groupDescription === "False Log") {
                violations.falseLog.push(violation);
            } else if (groupDescription === "Incomplete/Wrong Log") {
                violations.incompleteLog.push(violation);
            } else if (groupDescription === "Hours") {
                violations.hours.push(violation);
            } else if (groupDescription === "hours Nominal") {
                violations.hoursNominal.push(violation);
            } else if (groupDescription === "Jumping OOS/Driving Fatigued") {
                violations.jumpingOos.push(violation);
            } else if (groupDescription === "Other Log/Form & Manner") {
                violations.otherLogManner.push(violation);
            } else if (groupDescription === "EOBR Related" && sectionDescription === "Onboard recording device info not available") {
                violations.eldOther.push(violation);
            } else if (
                groupDescription === "EOBR Related" &&
                sectionDescription === "Portable ELD not mounted in a fixed position and visible to driver"
            ) {
                violations.installation.push(violation);
            } else if (
                groupDescription === "EOBR Related" &&
                (sectionDescription ===
                    "Automatic on-board recording device failed to provide means to immediately check drivers hours of service as required." ||
                    sectionDescription === "Driver not adequately trained in the operation of the automatic on-board recording device.")
            ) {
                violations.operatorError.push(violation);
            } else if (
                groupDescription === "EOBR Related" &&
                (sectionDescription ===
                    "Driver failed to have instructions on-board CMV for installed automatic on-board recording device." ||
                    sectionDescription ===
                        "Driver failed to have on-board a CMV a sufficient supply of blank records of duty status graph-grids." ||
                    sectionDescription === "Driver failed to maintain instruction sheet for ELD malfunction reporting requirements" ||
                    sectionDescription === "Driver failed to maintain supply of blank drivers records of duty status graph-grids" ||
                    sectionDescription === "Driver failing to maintain ELD instruction sheet" ||
                    sectionDescription === "Driver failing to maintain ELD user's manual")
            ) {
                violations.docRelated.push(violation);
            } else {
                violations.other.push(violation);
            }
        });

        super(breakdown, carrier, date, hosViolations, [
            {
                name: "Log Related",
                subGroups: [
                    { name: "False Log", violations: Violations.of(carrier, violations.falseLog) },
                    { name: "Incomplete/Wrong Log", violations: Violations.of(carrier, violations.incompleteLog) },
                ],
            },
            {
                name: "Hours Related",
                subGroups: [
                    { name: "Hours", violations: Violations.of(carrier, violations.hours) },
                    { name: "Hours Nominal", violations: Violations.of(carrier, violations.hoursNominal) },
                    { name: "OOS/Fatigue", violations: Violations.of(carrier, violations.jumpingOos) },
                ],
            },
            {
                name: "ELD",
                subGroups: [
                    { name: "Documentation Related", violations: Violations.of(carrier, violations.docRelated) },
                    { name: "Operator Error", violations: Violations.of(carrier, violations.operatorError) },
                    { name: "Installation", violations: Violations.of(carrier, violations.installation) },
                    { name: "Other", violations: Violations.of(carrier, violations.eldOther) },
                ],
            },
            {
                name: "Other",
                subGroups: [
                    { name: "Other Log/Form & Manner", violations: Violations.of(carrier, violations.otherLogManner) },
                    { name: "Other", violations: Violations.of(carrier, violations.other) },
                ],
            },
        ]);
    }

    // ========================================================================
    get(groupName: "Log Related"): Breakdown.Group<HoursOfService.GroupNames, HoursOfService.SubGroupNames.LogRelated>;
    get(groupName: "Hours Related"): Breakdown.Group<HoursOfService.GroupNames, HoursOfService.SubGroupNames.HoursRelated>;
    get(groupName: "ELD"): Breakdown.Group<HoursOfService.GroupNames, HoursOfService.SubGroupNames.ELD>;
    get(groupName: "Other"): Breakdown.Group<HoursOfService.GroupNames, HoursOfService.SubGroupNames.Other>;
    get(groupName: HoursOfService.GroupNames): Breakdown.Group<HoursOfService.GroupNames, HoursOfService.SubGroupNames> {
        return super.get(groupName);
    }

    // ========================================================================
    getHistory(): HoursOfService[] {
        return this.breakdown.getHistory().hoursOfService;
    }
}

export namespace HoursOfService {
    export type GroupNames = "Log Related" | "Hours Related" | "ELD" | "Other";

    export namespace SubGroupNames {
        export type LogRelated = "False Log" | "Incomplete/Wrong Log";

        export type HoursRelated = "Hours" | "Hours Nominal" | "OOS/Fatigue";

        export type ELD = "Documentation Related" | "Operator Error" | "Installation" | "Other";

        export type Other = "Other Log/Form & Manner";
    }

    export type SubGroupNames = SubGroupNames.LogRelated | SubGroupNames.HoursRelated | SubGroupNames.ELD | SubGroupNames.Other;
}
