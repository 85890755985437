import { GlobalCertificateHolder } from "@deathstar/types/northstar";
import { classNames, InputField } from "@deathstar/ui";
import { MagnifyingGlassIcon, PlusIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { PaperAirplaneIcon as PaperAirplaneIconSolid } from "@heroicons/react/24/solid";
import { matchSorter } from "match-sorter";
import { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { NotFoundError, UnauthorizedError } from "../../api/util/exceptions";
import { ActionButton } from "../../components/action-button/ActionButton";
import Unauthorized from "../../components/error-screens/Unauthorized";
import UncaughtException from "../../components/error-screens/UncaughtException";
import Loader from "../../components/loader/Loader";
import CertificatePreview from "./CertificatePreview";
import HolderSelectDialog from "./HolderSelectDialog";
import SendCertificateDialog, { SendCertificateDialogProps } from "./SendCertificateDialog";

export default function CertificateView() {
    const certificateId = useParams<{ certificateId: string }>().certificateId;
    const accountId = useAccountId();
    const { data, refetch, isLoading, isError, error } = api.certificates.useFindOne(accountId!, parseInt(certificateId!), {
        enabled: !!accountId && !!certificateId,
    });

    const [search, setSearch] = useState("");

    const filtered = useMemo(
        () => matchSorter(data?.holders || [], search, { keys: ["globalHolder.name", "globalHolder.email", "globalHolder.fax"] }),
        [data?.holders, search]
    );

    const [sendDialogState, setSendDialogState] = useState<{
        open: boolean;
        downloadOpen: boolean;
        globalHolderId: SendCertificateDialogProps["globalHolderId"] | null;
        defaultValues: SendCertificateDialogProps["defaultValues"];
    }>({
        open: false,
        downloadOpen: false,
        globalHolderId: null,
        defaultValues: undefined,
    });
    const [holderDialogOpen, setHolderDialogOpen] = useState(false);

    if (isError) {
        if (error instanceof NotFoundError) {
            return (
                <div className="m-auto flex h-full w-full flex-col items-center justify-center">
                    <p>We couldn't find that certificate</p>
                    <Link to=".." className="text-navigator-primary-light">
                        Go back
                    </Link>
                </div>
            );
        } else if (error instanceof UnauthorizedError) {
            return <Unauthorized />;
        }
        return <UncaughtException />;
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="flex h-full flex-col overflow-hidden p-4 lg:p-12">
            <div className="mb-4 flex gap-2 text-sm">
                <Link to="../." className="text-navigator-primary-light">
                    Certificates
                </Link>
                <span className="text-stone-500">/</span>
                <span>{data?.coveragesString}</span>
            </div>
            <div className="grid w-full grid-cols-6 gap-2 overflow-hidden">
                <div className="col-span-full min-h-[30rem] w-full overflow-y-auto rounded border border-stone-300 lg:col-span-4">
                    <div className="sticky top-0 flex w-full flex-col items-center justify-between border-b bg-white lg:flex-row">
                        <div className="flex w-full grow items-center justify-between px-4 py-2">
                            <p className="text-waypoint-blue-dark small-caps">Choose a holder</p>
                            <ActionButton
                                onClick={() => {
                                    setHolderDialogOpen(true);
                                }}
                                className="h-min"
                            >
                                <PlusIcon className="h-4 w-4" />
                                New holder
                            </ActionButton>
                        </div>
                        <div className="flex h-full w-full items-center gap-4 lg:w-max">
                            <InputField
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                classes={{
                                    root: "w-full lg:w-48 text-sm h-full",
                                    inputContainer: "py-2 bg-white shadow-none h-full",
                                    inputContainerFocused: "ring-inset",
                                }}
                                placeholder="Search..."
                                startAdornment={<MagnifyingGlassIcon className="h-5 w-5 shrink-0 text-stone-500" />}
                                endAdornment={
                                    search && (
                                        <button
                                            onClick={() => setSearch("")}
                                            className={classNames(
                                                "transition-opacity",
                                                search ? "opacity-100" : "pointer-events-none opacity-0"
                                            )}
                                        >
                                            <XCircleIcon className="h-4 w-4 text-stone-500" />
                                        </button>
                                    )
                                }
                            />
                        </div>
                    </div>
                    <ul className="">
                        {filtered.length ? (
                            filtered.map((holder) => {
                                const isAI = holder.globalHolder.policyAdditionalInterests?.some((pai) => pai.isAdditionalInsured);
                                const isLP = holder.globalHolder.policyAdditionalInterests?.some((pai) => pai.isLossPayee);
                                return (
                                    <li key={holder.globalHolderId}>
                                        <button
                                            onClick={() => {
                                                setSendDialogState({
                                                    open: true,
                                                    downloadOpen: false,
                                                    globalHolderId: holder.globalHolderId,
                                                    defaultValues: {
                                                        holderName: holder.globalHolder.name,
                                                        holderStreet: holder.globalHolder.street,
                                                        holderStreet2: holder.globalHolder.street2,
                                                        holderCity: holder.globalHolder.city,
                                                        holderState: holder.globalHolder.state,
                                                        holderZip: holder.globalHolder.zip,
                                                        to: holder.email || holder.globalHolder.email || holder.globalHolder.fax,
                                                    },
                                                });
                                            }}
                                            className="group flex w-full rounded px-4 py-4 text-left outline-none ring-inset ring-navigator-primary enabled:hover:bg-navigator-primary-light/5 enabled:focus:ring-2 enabled:active:bg-navigator-primary-light/10 lg:py-2"
                                            onKeyDown={(e) => {
                                                if (e.key === "ArrowDown") {
                                                    e.preventDefault();
                                                    // focus the next sibling
                                                    const next = e.currentTarget.parentElement?.nextElementSibling?.firstElementChild;
                                                    if (next) {
                                                        (next as HTMLElement).focus();
                                                    }
                                                } else if (e.key === "ArrowUp") {
                                                    e.preventDefault();
                                                    // focus the previous sibling
                                                    const prev = e.currentTarget.parentElement?.previousElementSibling?.firstElementChild;
                                                    if (prev) {
                                                        (prev as HTMLElement).focus();
                                                    }
                                                }
                                            }}
                                        >
                                            <div className="flex grow flex-col items-baseline gap-0 lg:flex-row lg:gap-4">
                                                <span className="whitespace-nowrap font-medium">{holder.globalHolder.name}</span>
                                                <span className="whitespace-nowrap pl-4 text-sm font-light lg:pl-0">
                                                    {holder.email || holder.globalHolder.email || holder.globalHolder.fax}
                                                </span>
                                                <span className="w-full pl-4 font-light text-waypoint-blue-dark small-caps lg:mr-4 lg:pl-0 lg:text-right">
                                                    {isAI && isLP
                                                        ? "Additional insured & Loss payee"
                                                        : isAI
                                                        ? "Additional insured"
                                                        : isLP
                                                        ? "Loss payee"
                                                        : null}
                                                </span>
                                            </div>
                                            <PaperAirplaneIconSolid className="h-6 w-6 place-self-end self-center text-navigator-primary-light opacity-0 transition-opacity group-hover:opacity-100 group-focus:opacity-100" />
                                        </button>
                                    </li>
                                );
                            })
                        ) : (
                            <p className="w-full py-4 text-center text-stone-500">No holders</p>
                        )}
                    </ul>
                </div>
                <div className="col-span-2 hidden lg:block">
                    <CertificatePreview
                        certificate={data}
                        onDownload={() => setSendDialogState((old) => ({ ...old, downloadOpen: true }))}
                    />
                </div>
                {!!data && (
                    <>
                        <SendCertificateDialog
                            certificate={data}
                            open={sendDialogState.open}
                            closeDialog={() => {
                                setSendDialogState((old) => ({ ...old, open: false }));
                            }}
                            afterClose={() => {
                                setSendDialogState({ open: false, downloadOpen: false, globalHolderId: null, defaultValues: undefined });
                                refetch();
                            }}
                            globalHolderId={sendDialogState.globalHolderId || undefined}
                            defaultValues={sendDialogState.defaultValues}
                            primaryAction="send"
                        />
                        <SendCertificateDialog
                            certificate={data}
                            open={sendDialogState.downloadOpen}
                            closeDialog={() => {
                                setSendDialogState((old) => ({ ...old, downloadOpen: false }));
                            }}
                            afterClose={() => {
                                setSendDialogState({ open: false, downloadOpen: false, globalHolderId: null, defaultValues: undefined });
                                refetch();
                            }}
                            globalHolderId={undefined}
                            defaultValues={undefined}
                            primaryAction="download"
                        />
                    </>
                )}
                {!!data && (
                    <HolderSelectDialog
                        certificate={data}
                        open={holderDialogOpen}
                        onClose={() => {
                            setHolderDialogOpen(false);
                        }}
                        onAdd={() => {
                            refetch();
                        }}
                        openSendDialog={(globalHolder: GlobalCertificateHolder) => {
                            setHolderDialogOpen(false);
                            setSendDialogState({
                                globalHolderId: globalHolder.id,
                                open: true,
                                downloadOpen: false,
                                defaultValues: {
                                    holderName: globalHolder.name,
                                    holderStreet: globalHolder.street,
                                    holderStreet2: globalHolder.street2,
                                    holderCity: globalHolder.city,
                                    holderState: globalHolder.state,
                                    holderZip: globalHolder.zip,
                                    to: globalHolder.email || globalHolder.fax,
                                },
                            });
                        }}
                        openCustomSendDialog={(name) => {
                            setHolderDialogOpen(false);
                            setSendDialogState({
                                defaultValues: {
                                    holderName: name,
                                },
                                globalHolderId: null,
                                open: true,
                                downloadOpen: false,
                            });
                        }}
                    />
                )}
            </div>
        </div>
    );
}
