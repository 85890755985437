/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTime, memoize } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { MotorCarrier } from "../../MotorCarrier";
import { Violations } from "../Violations";
import { ControlledSubstances } from "./BASIC/ControlledSubstances";
import { DriverFitness } from "./BASIC/DriverFitness";
import { Hazmat } from "./BASIC/Hazmat";
import { HoursOfService } from "./BASIC/HoursOfService";
import { UnsafeDriving } from "./BASIC/UnsafeDriving";
import { VehicleMaintenance } from "./BASIC/VehicleMaintenance";
import { Breakdown } from "./Breakdown";

// ========================================================================
export class Breakdowns {
    // ========================================================================
    #numberOfHistoricalBreakdowns: number;
    #carrier: MotorCarrier;
    #breakdowns: Breakdown[] = [];
    #violations: Violations;

    constructor(options: Breakdowns.Options) {
        this.#carrier = options.carrier;
        this.#violations = options.violations;

        this.#numberOfHistoricalBreakdowns = options.numberOfHistoricalBreakdowns ?? 6;
        let date = options.date ?? this.#carrier.dateRange.to;

        for (let i = 0; i < this.#numberOfHistoricalBreakdowns; i++) {
            this.#breakdowns.push(new Breakdown(this, this.#carrier, date, this.#violations));
            date = DateTime.subtractMonths(date, 1);
        }

        this.sort("DESC");
    }

    // ========================================================================
    *[Symbol.iterator](): IterableIterator<Breakdown> {
        for (const breakdown of this.#breakdowns) {
            yield breakdown;
        }
    }

    // ========================================================================
    get breakdowns(): Breakdown[] {
        return this.#breakdowns;
    }

    // ========================================================================
    get total(): number {
        return this.#breakdowns.length;
    }

    // ========================================================================
    sort(order: "ASC" | "DESC" = "ASC"): this {
        this.#breakdowns.forEach((bd) => bd.sort(order));
        return this;
    }

    // ========================================================================
    @memoize()
    getByBasic(): {
        unsafeDriving: UnsafeDriving[];
        hoursOfService: HoursOfService[];
        vehicleMaintenance: VehicleMaintenance[];
        driverFitness: DriverFitness[];
        hazmat: Hazmat[];
        controlledSubstances: ControlledSubstances[];
    } {
        return {
            unsafeDriving: this.#breakdowns.map((bd) => bd.unsafeDriving),
            hoursOfService: this.#breakdowns.map((bd) => bd.hoursOfService),
            vehicleMaintenance: this.#breakdowns.map((bd) => bd.vehicleMaintenance),
            driverFitness: this.#breakdowns.map((bd) => bd.driverFitness),
            hazmat: this.#breakdowns.map((bd) => bd.hazmat),
            controlledSubstances: this.#breakdowns.map((bd) => bd.controlledSubstances),
        };
    }

    // ========================================================================
    getLatest(): {
        unsafeDriving: UnsafeDriving;
        hoursOfService: HoursOfService;
        vehicleMaintenance: VehicleMaintenance;
        driverFitness: DriverFitness;
        hazmat: Hazmat;
        controlledSubstances: ControlledSubstances;

        [FMCSA.BasicName.UNSAFE_DRIVING]: UnsafeDriving;
        [FMCSA.BasicName.HOS]: HoursOfService;
        [FMCSA.BasicName.VEHICLE_MAINTENANCE]: VehicleMaintenance;
        [FMCSA.BasicName.DRIVER_FITNESS]: DriverFitness;
        [FMCSA.BasicName.HAZMAT]: Hazmat;
        [FMCSA.BasicName.CONTROLLED_SUBSTANCES]: ControlledSubstances;
    } {
        const byBasic = this.getByBasic();

        return {
            unsafeDriving: byBasic.unsafeDriving[0],
            hoursOfService: byBasic.hoursOfService[0],
            vehicleMaintenance: byBasic.vehicleMaintenance[0],
            driverFitness: byBasic.driverFitness[0],
            hazmat: byBasic.hazmat[0],
            controlledSubstances: byBasic.controlledSubstances[0],

            [FMCSA.BasicName.UNSAFE_DRIVING]: byBasic.unsafeDriving[0],
            [FMCSA.BasicName.HOS]: byBasic.hoursOfService[0],
            [FMCSA.BasicName.VEHICLE_MAINTENANCE]: byBasic.vehicleMaintenance[0],
            [FMCSA.BasicName.DRIVER_FITNESS]: byBasic.driverFitness[0],
            [FMCSA.BasicName.HAZMAT]: byBasic.hazmat[0],
            [FMCSA.BasicName.CONTROLLED_SUBSTANCES]: byBasic.controlledSubstances[0],
        };
    }

    // ========================================================================
    getHistory(): {
        unsafeDriving: UnsafeDriving[];
        hoursOfService: HoursOfService[];
        vehicleMaintenance: VehicleMaintenance[];
        driverFitness: DriverFitness[];
        hazmat: Hazmat[];
        controlledSubstances: ControlledSubstances[];
    } {
        const {
            unsafeDriving: [_ud, ...historicalUnsafeDriving],
            hoursOfService: [_hos, ...historicalHoursOfService],
            vehicleMaintenance: [_vm, ...historicalVehicleMaintenance],
            driverFitness: [_df, ...historicalDriverFitness],
            hazmat: [_hm, ...historicalHazmat],
            controlledSubstances: [_cs, ...historicalControlledSubstances],
        } = this.getByBasic();

        return {
            unsafeDriving: historicalUnsafeDriving,
            hoursOfService: historicalHoursOfService,
            vehicleMaintenance: historicalVehicleMaintenance,
            driverFitness: historicalDriverFitness,
            hazmat: historicalHazmat,
            controlledSubstances: historicalControlledSubstances,
        };
    }
}

// ========================================================================
export namespace Breakdowns {
    export interface Options {
        carrier: MotorCarrier;
        violations: Violations;
        numberOfHistoricalBreakdowns?: number;
        date?: DateTime;
    }
}
