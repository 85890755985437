import React, { forwardRef } from "react";
import tw from "twin.macro";
export interface ButtonProps
    extends Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "className"> {
    classes?: Partial<Record<"root", string>>;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ classes, css, ...props }, ref) => {
    return (
        <button
            className={classes?.root}
            {...props}
            css={[
                tw`h-8 px-2 text-sm uppercase transition-all border min-w-[8rem] border-navigator-blue rounded ring-offset-2 ring-navigator-blue enabled:hover:(bg-navigator-blue text-white shadow) disabled:(cursor-auto opacity-70)`,
                css,
            ]}
            ref={ref}
        ></button>
    );
});
