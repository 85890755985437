import { DateTime } from "@deathstar/reuse";
import { MotorCarrier } from "../../../MotorCarrier";
import { Violations } from "../../Violations";
import { Breakdown } from "../Breakdown";

// ========================================================================
export abstract class BASIC<GroupNames extends string, SubGroupNames extends string> {
    // ========================================================================
    /**
     * The total weight of applicable violations
     */
    readonly total: number;

    readonly groups: Breakdown.Group<GroupNames, SubGroupNames>[];
    #breakdown: Breakdown;
    #date: DateTime;
    #carrier: MotorCarrier;
    #violations: Violations;
    constructor(
        breakdown: Breakdown,
        carrier: MotorCarrier,
        date: DateTime,
        violations: Violations,
        groups: { name: GroupNames; subGroups: { name: SubGroupNames; violations: Violations }[] }[]
    ) {
        this.#breakdown = breakdown;
        this.#date = date;
        this.#carrier = carrier;
        this.#violations = violations;
        this.total = this.#violations.getTotalWeight();
        this.groups = groups.map((group) => new Breakdown.Group(carrier, this, group.name, group.subGroups));
    }

    // ========================================================================
    protected get breakdown(): Breakdown {
        return this.#breakdown;
    }

    // ========================================================================
    get date(): DateTime {
        return this.#date;
    }

    // ========================================================================
    get carrier(): MotorCarrier {
        return this.#carrier;
    }

    // ========================================================================
    get violations(): Violations {
        return this.#violations;
    }

    // ========================================================================
    *[Symbol.iterator](): IterableIterator<Breakdown.Group<GroupNames, SubGroupNames>> {
        for (const grouping of this.groups) {
            yield grouping;
        }
    }

    // ========================================================================
    sort(order: "ASC" | "DESC" = "ASC"): this {
        this.groups.sort((g1, g2) => {
            if (g1.name.includes("Other")) {
                return 1;
            }

            if (g1.total > g2.total) {
                return order === "ASC" ? 1 : -1;
            }

            if (g1.total < g2.total) {
                return order === "ASC" ? -1 : 1;
            }

            if (g1.name > g2.name) {
                return 1;
            }

            if (g1.name < g2.name) {
                return -1;
            }

            return 0;
        });

        return this;
    }

    // ========================================================================
    get(groupName: GroupNames): Breakdown.Group<GroupNames, SubGroupNames> {
        return this.groups.find((group) => group.name === groupName)!;
    }

    // ========================================================================
    getTopFourGroups(): Breakdown.Group<GroupNames, SubGroupNames>[] {
        return this.sort("DESC").groups.slice(0, 4);
    }

    // ========================================================================
    json(): { total: number; groups: ReturnType<Breakdown.Group<GroupNames, SubGroupNames>["json"]>[] } {
        return {
            total: this.total,
            groups: this.groups.map((grp) => grp.json()),
        };
    }

    // ========================================================================
    abstract getHistory(): BASIC<GroupNames, SubGroupNames>[];
}
