import { plainToClass } from "class-transformer";
import { Policy } from "../Policy";
import { User } from "../User";

export class PolicyLayerMetadata {
    static #isFinancialImpactLayerEffectiveDateMatch(p: Policy): boolean {
        if (p.layerTransactionType === Policy.TransactionType.UPDATE || !p.amsLayerId) return true;

        return (
            new Date(p.layerEffectiveDate).getUTCDate() === new Date(p.amsLayerId).getUTCDate() &&
            new Date(p.layerEffectiveDate).getUTCMonth() === new Date(p.amsLayerId).getUTCMonth() &&
            new Date(p.layerEffectiveDate).getUTCFullYear() === new Date(p.amsLayerId).getUTCFullYear()
        );
    }

    static fromPolicy(p: Policy): PolicyLayerMetadata {
        return plainToClass(PolicyLayerMetadata, {
            policyId: p.id,
            accountId: p.accountId,
            amsClientNumber: p.amsClientNumber,
            status: p.status,
            isBackdatedEndorsementLayer: p.isBackdatedEndorsementLayer,
            isBackdatedEditLayer: p.isBackdatedEditLayer,
            isBackdatedUpdateLayer: p.isBackdatedUpdateLayer,
            isFinancialImpactLayerEffectiveDateMatch: PolicyLayerMetadata.#isFinancialImpactLayerEffectiveDateMatch(p),
            financialImpacLayerEffectiveDate: p.amsLayerId,
            isCascadeLayer: p.isCascadeLayer,
            accounting: {
                total: {
                    commissionableAmount: p.commissions.reduce((total, line) => total + line.premium, 0),
                    billedCommissionableAmount: p.commissions.reduce((total, line) => total + line.billedPremium, 0),
                    feeAmount: p.fees.reduce((total, fee) => total + fee.premium, 0),
                    billedFeeAmount: p.fees.reduce((total, fee) => total + fee.billedPremium, 0),
                },
                commissions: p.commissions?.map((c) => ({
                    id: c.id,
                    commissionOptionCode: c.commissionOptionCode,
                    premium: c.premium,
                    fullTermPremium: c.fullTermPremium,
                    description: c.description,
                })),
                fees: p.fees?.map((f) => ({
                    id: f.id,
                    feeOptionCode: f.feeOptionCode,
                    premium: f.premium,
                    fullTermPremium: f.fullTermPremium,
                    description: f.description,
                })),
            },
            effectiveDate: p.layerEffectiveDate,
            createdDate: p.createdDate,
            createdBy: p.createdBy,
            description: p.layerDescription,
            isEdit: p.layerTransactionType === Policy.TransactionType.EDIT,
            layerTransactionType: p.layerTransactionType,
            entityTransactionType: p.entityTransactionType,
            policyTransactionType: p.policyTransactionType,
        });
    }

    policyId: string;
    accountId: string;
    amsClientNumber: number;
    status: Policy.Status;
    isBackdatedEndorsementLayer: boolean;
    isBackdatedEditLayer: boolean;
    isBackdatedUpdateLayer: boolean;
    isFinancialImpactLayerEffectiveDateMatch: boolean;
    financialImpacLayerEffectiveDate?: Date;
    isCascadeLayer: boolean;
    accounting: {
        total: {
            commissionableAmount: number;
            feeAmount: number;
            billedCommissionableAmount: number;
            billedFeeAmount: number;
        };
        commissions: {
            id: string;
            commissionOptionCode: string;
            premium: number;
            fullTermPremium: number;
            description: string;
        }[];
        fees: {
            id: string;
            feeOptionCode: string;
            premium: number;
            fullTermPremium: number;
            description: string;
        }[];
    };
    effectiveDate: Date;
    createdDate: Date;
    createdBy: User;
    description: string;
    isEdit: boolean;
    layerTransactionType: Policy.TransactionType | null;
    entityTransactionType: Policy.TransactionType;
    policyTransactionType: Policy.TransactionType;
}
