import { classNames } from "@deathstar/ui";
import { forwardRef } from "react";
import { ActionButton } from "./ActionButton";

export const PrimaryActionButton = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ className, ...props }, ref) => {
        return (
            <ActionButton
                className={classNames("border-none !bg-waypoint-blue text-white enabled:hover:!text-white", className)}
                {...props}
                ref={ref}
            />
        );
    }
);
