import { plainToClass } from "class-transformer";
import type { Policy } from "./Policy";
import type { PolicyFeeOption } from "./PolicyFeeOption";

export class PolicyFee {
    static Id = {
        AAE: "!!I",
    } as const;

    static duplicate(fee: PolicyFee): PolicyFee {
        const f = plainToClass(PolicyFee, fee);

        f.id = null;
        f.createdDate = null;
        f.policyId = null;
        f.policyCreatedDate = null;
        f.policy = null;

        return f;
    }

    static cancel(fee: PolicyFee): PolicyFee {
        const f = PolicyFee.duplicate(fee);
        f.premium = 0;
        return f;
    }

    static reinstate(fee: PolicyFee): PolicyFee {
        const f = PolicyFee.duplicate(fee);
        f.premium = 0;
        return f;
    }

    id: number;
    createdDate: Date;
    effectiveDate: Date;

    policyId: string;
    policyCreatedDate: Date;
    policy?: Policy;

    feeOptionCode: string;
    feeOption: PolicyFeeOption;

    sortOrder: number;
    premium: number;
    billedPremium: number;
    writtenPremium: number;
    fullTermPremium: number;
    includePremium: boolean;
    isCorrected: boolean;
    isPosted: boolean;
    isSuspended: boolean;
    isReconciled: boolean;
    estimatedRevenue: boolean;

    description: string;
    metadata?: Record<string, unknown>;
}
