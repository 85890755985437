import { Type } from "class-transformer";
import { IsDate, IsOptional, IsString, IsUUID } from "class-validator";
import { EmailBlobMetadata } from "./EmailBlobMetadata";
import type { Policy } from "./Policy";
import { User } from "./User";

export class PolicyEmail {
    @IsUUID("4")
    id: string;

    @IsString()
    accountId: string;

    @IsString()
    policyId: string;

    @IsOptional()
    @IsDate()
    @Type(() => Date)
    policyCreatedDate: Date;

    policy?: Policy;

    @IsString()
    createdByGuid: string;
    createdBy: User;
    createdDate: Date;

    metadata?: Partial<EmailBlobMetadata>;
}
