export namespace PolicyEnums {
    export enum TransactionType {
        // Non-Endorsement types
        PRE_BIND = "Pre-Bind",
        UPDATE = "Update",
        EDIT = "Edit",

        // Endorsement types
        MONTHLY_REPORT = "Monthly Report",
        MONTHLY_REPORT_FINAL = "Monthly Report - Final",
        ANNIVERSARY_RE_RATE = "Anniversary Re-Rate",
        BINDER_NEW_BUSINESS = "Binder - New Business",
        BINDER_BILLABLE = "Binder - Billable",
        BINDER_ENDORSEMENT = "Binder - Endorsement",
        BINDER_RENEWAL = "Binder - Renewal",
        QUOTE = "Quote",
        NEW = "New",
        NON_RENEWAL_NOTIFIED_AGENCY = "Non-Renewal Notified Agency",
        PREMIUM_AUDIT = "Premium Audit",
        CHANGE = "Change",
        CHANGE_QUOTE = "Change Quote",
        INQUIRY = "Inquiry",
        OTHER = "Other",
        REINSTATEMENT = "Reinstatement",
        REWRITE = "Rewrite",
        REISSUE = "Reissue",
        REVERSAL_OF_NON_RENEWAL = "Reversal of Non-Renewal",
        RENEWAL_RE_QUOTE = "Renewal Re-Quote",
        RENEWAL = "Renewal",
        RENEWAL_QUOTE = "Renewal Quote",
        RENEWAL_REQUEST = "Renewal Request",
        NON_RENEWAL_NOTIFIED_POLICY_HOLDER = "Non-Renewal Notified Policy Holder",
        POLICY_SYNCHRONIZATION = "Policy Synchronization",
        POLICY_SYNCHRONIZATION_REQUEST = "Policy Synchronization Request",
        CANCELLATION_CONFIRMATION = "Cancellation Confirmation",
        CANCELLATION_REQUEST = "Cancellation Request",
    }
    export enum Status {
        DRAFT = "Draft",
        ACTIVE = "Active",
        QUOTE = "Quote",
        RENEWED = "Renewed",
        REWRITTEN = "Rewritten",
        PRE_CANCELLATION = "Pre-Cancellation",
        CANCELLED = "Cancelled",
        EXPIRED = "Expired",
        NON_RENEWED = "Non-Renewed",
        NOT_TAKEN = "Not taken",
        OTHER = "Other",
    }

    export enum BusinessType {
        PERSONAL_LINES = 1,
        COMMERCIAL_LINES = 2,
        NON_PROPERTY_CASUALTY = 3,
        BENEFITS = 4,
        LIFE = 5,
        HEALTH = 6,
        FINANCIAL_SERVICES = 7,
    }
    export enum BillMethod {
        DIRECT = "Direct",
        AGENCY = "Agency",
        FIRST_TIME_AGENCY = "First Time Agency",
    }
    export enum ParentCompanyType {
        INSURANCE = "Insurance",
        BROKERAGE = "Brokerage",
    }
    export enum CoveragePackage {
        TRUCKERS = "Truckers",
        NON_TRUCKING = "Non-Trucking",
        BUSINESS_OWNERS = "Business Owners",
        OWNER_OPERATOR = "Owner Operator",
        TRAVELERS_CARGOPAK = "Travelers CargoPak",
        LOGISTIQ_BROKER_SHIELD = "Logistiq Broker Shield",
        NONE = "None",
    }
    export enum ValidationGroups {
        COMMISSION = "COMMISSION",
        PRE_BIND_DTO = "PRE_BIND_DTO",
        UPDATE_DTO = "UPDATE_DTO",
        CREATE_DTO = "CREATE_DTO",
        BOUND = "BOUND",
        UI_BUILDER = "UI_BUILDER",
    }
}
