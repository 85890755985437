import React, { useRef, useEffect } from "react";

/**
 * A hook for using a forwarded ref. This is useful if you have a component inside a React.forwardRef which needs to make use of the same
 * ref that is being passed to it. The hook creates a new ref and assigns the forwarded ref to it, you then attach the new ref to your
 * element instead of the forwarded one.
 *
 * See https://bedrock-layout.dev/src-components-use-forwarded-ref
 */

export function useForwardedRef<T>(
    ref: React.MutableRefObject<T | null> | React.RefCallback<T | null> | null
): React.MutableRefObject<T | null> {
    const innerRef = useRef<T>(null);
    useEffect(() => {
        if (!ref) return;
        if (typeof ref === "function") {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return innerRef;
}
