import type { Address } from "./Address";
import type { User } from "./User";

export type AccountStatus = "Suspect" | "Target" | "InPursuit" | "Engaged" | "Active" | "Client";

enum RecordTypes {
    MotorCarrier = "012f4000000z6yN",
    IndpendentContractor = "0125G000000XBjW",
    OtherEntity = "0125G000000XC0r",
    InsuranceCompany = "012f4000000zLV2",
    InsuranceBroker = "012f4000000bEhs",
}

/** Also known as "Authority types" */
enum EntityTypes {
    "Broker" = "B - Broker",
    "Carrier" = "C - Carrier",
    "FreightForwarder" = "F - Freight Forwarder",
    "IntermodalEquipmentProvider" = "I - Intermodal Equipment Provider (IEP)",
    "Registrant" = "R - Registrant (entity who registers vehicles but is not a carrier)",
    "Shipper" = "S - Shipper",
    "CargoTank" = "T - Cargo Tank",
}

enum EntitySubtypes {
    AssetHoldingCompany = "Asset Holding Company",
    Brokerage = "Brokerage",
    BuildingManagementCompany = "Building Management Company",
    Company = "Company",
    EmployeeLeasingCompany = "Employee Leasing Company",
    Individual = "Individual",
    PartsFacility = "Parts Facility",
    PrivateGarage = "Private Garage",
    PublicGarage = "Public Garage",
    WarehouseFacility = "Warehouse Facility",
}

export class Account {
    static RecordTypes = RecordTypes;
    static EntityTypes = EntityTypes;
    static EntitySubtypes = EntitySubtypes;

    attributes: {
        type: "Account";
        url: string;
    };

    Account_Manager_Assistant__c: string;
    Account_Manager_Ret__c: string;
    Account_Status__c: AccountStatus;
    AL_Insurance_Effective_Date__c: string;
    AMS_Customer_Number__c: number;
    AP_Account_Manager__c: string;
    AP_Account_Plan_ID__c: string;
    AP_Annual_Miles__c: number;
    AP_Annual_Revenue__c: number;
    AP_Any_Losses__c: boolean;
    AP_Authority_Date__c: string;
    AP_Auto_Liability__c: number;
    AP_Cargo_Limit__c: number;
    AP_Cell__c: string;
    AP_Cities_Entered__c: string;
    AP_Contact_Name_Text__c: string;
    AP_Email__c: string;
    AP_Excess_Limit__c: number;
    AP_Fed_ID__c: string;
    AP_General_Liability__c: string;
    AP_Hired_Non_Owned_Liability__c: boolean;
    AP_Hired_PhysDam__c: boolean;
    AP_Last_4_of_Soc__c: string;
    AP_Notes__c: string;
    AP_NTL_Limit__c?: number;
    AP_Operations__c?: string;
    AP_Owner_DOB__c: string;
    AP_Owner_Name_Text__c: string;
    AP_PD_Deductible__c?: number;
    AP_Radius_0_75__c: number;
    AP_Radius_151_300__c: number;
    AP_Radius_301_500__c: number;
    AP_Radius_501__c: number;
    AP_Radius_76_150__c: number;
    AP_Reefer_Breakdown__c: boolean;
    AP_State_Filing__c: boolean;
    AP_States_Entered__c: string;
    AP_Target_Market_s__c: string;
    AP_umuim_Limit__c?: number;
    AP_Work_Comp_Carrier__c: string;
    AvgTripLeasedDriversPerMonth__c?: number;
    BillingAddress: Address;
    Broker_Authority__c: string;
    Common_Authority__c: string;
    Contract_Authority__c: string;
    Current_AL_Insurance__c: string;
    DBA_Name__c: string;
    Last_FMCSA_Update__c: string;
    Docket__c: string;
    DOT_Duplicate_Checker__c: string;
    DOT_Number__c: string;
    Entity_Sub_Type__c?: EntitySubtypes;
    /** String of EntityTypes joined by semicolons, i.e. "B - Broker;C - Carrier" */
    Entity_Type__c?: string;
    Fax: string;
    FMCSA_Email__c?: string;
    FMCSA_Email_Invalid__c?: string;
    Id: string;
    Insur_Comp_Short__c: string;
    InterstateDriversGt100Mi__c?: number;
    InterstateDriversLt100Mi__c?: number;
    IntrastateDriversGt100Mi__c?: number;
    IntrastateDriversLt100Mi__c?: number;
    MC_Lease_DOT__c?: string;
    MC_Lease_Name__c?: string;
    MC_Lease_Notes__c?: string;
    Name: string;
    New_Authority__c: boolean;
    organization_type__c?: string;
    Owner: User;
    OwnerId: string;
    ParentId: string;
    Phone: string;
    Billing_Phone__c: string;
    Claims_Phone__c: string;
    RecordTypeId: RecordTypes;
    Relationship_Value__c: number;
    Account_Value__c: number;
    ShippingAddress: Address;
    Total_of_Trucks__c: number;
    TotalCdlDrivers__c?: number;
    TotalDrivers__c?: number;
    TotalIntersateDrivers__c?: number;
    TotalIntrastateDrivers__c?: number;
    Tractor_Count__c: number;
    Upcoming_Effective_Date__c: string;
    Verified_cargo__c?: string;
    HazMat__c?: boolean;
    // Multi-Picklist, string values separated by semicolons
    Company_Operations__c?: string;
    TRMTRACT__c?: number;
    State_Appetite__c?: string;
    Motor_Carrier_Quality__c?: "Clean" | "Fuzzy" | "Bad";
    Market_Focus_1_3__c?: "Focused" | "Not Focused" | "Unlikely";
    X4_9__c?: "Focused" | "Not Focused" | "Unlikely";
    X10_25__c?: "Focused" | "Not Focused" | "Unlikely";
    X26_50__c?: "Focused" | "Not Focused" | "Unlikely";
    X51_75__c?: "Focused" | "Not Focused" | "Unlikely";
    X76_100__c?: "Focused" | "Not Focused" | "Unlikely";
    X100__c?: "Focused" | "Not Focused" | "Unlikely";
    Driver_Retention__c?: "0%" | "10%" | "20%" | "30%" | "40%" | "50%" | "60%" | "70%" | "80%" | "90%" | "100%";
    Insurance_Companies_Represented__c?: string;
    /** Multi-picklist with options of "Short", "Regional", "Long" */
    Radius_Appetite__c?: string;
}
