import { Paper } from "@material-ui/core";
import { PrintSectionName } from "../Components/PrintSectionName";
import { HistoricalScores } from "./HistoricalScores";
import { InsuranceHistory } from "./InsuranceHistory";
import { Mcs150History } from "./Mcs150History";

export function History(): JSX.Element {
    return (
        <div className="page relative">
            <PrintSectionName name="History" />
            <Paper data-page-break="after" className="w-full" elevation={0}>
                <HistoricalScores />
                <div className="flex flex-col gap-4 print:flex">
                    <InsuranceHistory />
                    <Mcs150History />
                </div>
            </Paper>
        </div>
    );
}
