import { RawData } from "@deathstar/reuse";

import type { Census } from "../Census/Census";
import type { MotorCarrier } from "../MotorCarrier";

// ========================================================================
export class CensusHistorical extends RawData<CensusHistorical.Raw> {
    // ========================================================================
    static empty(carrier: MotorCarrier): CensusHistorical {
        return new CensusHistorical(carrier, {
            IsHazmatCarrier: false,
            IsPassengerCarrier: false,
            RecentMileage: NaN,
            RecentMileageYear: new Date().getFullYear(),
            RecordDate: new Date(),
            TotalDrivers: NaN,
            TotalPowerUnits: NaN,
            Mcs150Date: undefined,
            Mcs150MileageTotal: undefined,
            Mcs150MileageYear: undefined,
        });
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #exists: boolean;

    constructor(carrier: MotorCarrier, raw: CensusHistorical.Raw) {
        super(raw);
        this.#carrier = carrier;
        this.#exists = Boolean(raw);
    }

    // ========================================================================
    get mileage(): number {
        return this.get("RecentMileage") || this.get("Mcs150MileageTotal") || 0;
    }

    // ========================================================================
    get totalPowerUnits(): number {
        return this.get("TotalPowerUnits") || 0;
    }

    // ========================================================================
    get exists(): boolean {
        return this.#exists;
    }

    // ========================================================================
    json(): CensusHistorical.Raw {
        return super.json();
    }

    // ========================================================================
    raw(): CensusHistorical.Raw {
        return super.json();
    }
}

export namespace CensusHistorical {
    export interface Raw
        extends Pick<
            Census.Raw,
            | "Mcs150Date"
            | "Mcs150MileageTotal"
            | "Mcs150MileageYear"
            | "TotalPowerUnits"
            | "TotalDrivers"
            | "IsHazmatCarrier"
            | "IsOutOfService"
        > {
        RecordDate: Date;
        IsPassengerCarrier: boolean;
        RecentMileage: number;
        RecentMileageYear: number;
    }
}
