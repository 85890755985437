import { withAuthenticationRequired } from "@auth0/auth0-react";
import ResourcesComponent from "../../features/resources/Resources";

const Resources = withAuthenticationRequired(
    () => {
        return (
            <div className="p-4 lg:p-12">
                <ResourcesComponent />
            </div>
        );
    },
    {
        returnTo: window.location.pathname,
    }
);

export default Resources;
