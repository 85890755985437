export function stringifyAddress({
    street,
    city,
    zip,
    state,
    street2,
}: {
    street: string;
    city: string;
    zip: string;
    state: string;
    street2?: string;
}): string {
    return `${street}${street2 ? " " + street2 : ""} ${city}, ${state} ${zip}`;
}
