import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { PolicyWithBillingInfo } from "../../features/policies/policies-list/PoliciesList";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";

export const policies = {
    queryKeys: {
        find: (accountId: string) => [accountId, "driver-of-the-year"] as QueryKey,
    },

    find: (accountId: string, filter: "active" | "inactive") => {
        return blazar.fetchJson<PolicyWithBillingInfo[]>(`/waypoint/orgs/${accountId}/policies/${filter}`);
    },

    useFind: <T = PolicyWithBillingInfo[]>(
        accountId: string,
        filter: "active" | "inactive",
        config?: Partial<UseQueryOptions<PolicyWithBillingInfo[], ResponseError, T>>
    ) =>
        useQuery<PolicyWithBillingInfo[], ResponseError, T>({
            queryKey: ["policies", accountId!, filter],
            queryFn: () => policies.find(accountId, filter),
            ...config,
            enabled: !!accountId!,
            retry: (count, error) => {
                return count < 3 && error.status !== 403;
            },
        }),
};
