import { DriverRow } from "@deathstar/types/waypoint";
import { InputField } from "@deathstar/ui";
import { CheckIcon } from "@heroicons/react/24/outline";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import api from "../../api/api";
import { Dialog, DialogProps } from "../../components/dialog/Dialog";

interface DriverNotesDialogReturn {
    isOpen: boolean;
    id: number | null;
    open: (id: number | null) => void;
    component: JSX.Element;
}

export function useDriverNotesDialog(
    accountId: string,
    drivers: Pick<DriverRow, "id" | "name" | "publicNotes">[],
    dialogProps?: Partial<Omit<DialogProps, "open" | "onClose" | "afterLeave">>
): DriverNotesDialogReturn {
    const [id, setId] = useState<number | null>(null);
    // secondary open state so we don't have content flash when closing
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");
    const updateDriver = api.drivers.useUpdate(accountId);
    const updateDriverMutate = updateDriver.mutate;
    const updateDriverComments = useMemo(
        () =>
            debounce((id, publicNotes) => {
                updateDriverMutate({ id, publicNotes });
            }, 500),
        [updateDriverMutate]
    );

    const driver = drivers.find((d) => d.id === id);
    const driverName = driver?.name;
    const publicNotes = driver?.publicNotes || "";

    const component = useMemo(
        () => (
            <Dialog open={isOpen && !!id} onClose={() => setIsOpen(false)} afterLeave={() => setId(null)} {...dialogProps}>
                <div className="space-y-4 p-4">
                    <p>Custom notes for {driverName}</p>
                    <InputField
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                            updateDriverComments(id, e.target.value);
                        }}
                        helperText={`${value.length} / 255`}
                        endAdornment={
                            updateDriver.isPending || value !== publicNotes ? (
                                <CircularProgress size="1.5rem" classes={{ svg: "text-waypoint-blue" }} />
                            ) : updateDriver.isIdle ? null : updateDriver.isSuccess ? (
                                <Tooltip title="Saved">
                                    <CheckIcon className="h-6 w-6 text-emerald-600" />
                                </Tooltip>
                            ) : null
                        }
                    />
                    <p className="text-sm text-stone-500">
                        Changes to coverages cannot be made using this field. Use this field for your personal reference only.
                    </p>
                </div>
            </Dialog>
        ),
        [
            dialogProps,
            driverName,
            id,
            isOpen,
            updateDriver.isIdle,
            updateDriver.isPending,
            updateDriver.isSuccess,
            updateDriverComments,
            value,
            publicNotes,
        ]
    );

    return useMemo(
        () => ({
            open: (id: number | null) => {
                setIsOpen(true);
                setId(id);
                setValue(drivers.find((d) => d.id === id)?.publicNotes || "");
            },
            isOpen,
            id,
            component,
        }),
        [component, drivers, id, isOpen]
    );
}
