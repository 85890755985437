import { DateTime } from "@deathstar/reuse";
import { MotorCarrier } from "./MotorCarrier";

export class Configuration {
    #config: Configuration.Config;

    constructor(dateRange: MotorCarrier.IDateRange, options?: Configuration.Options) {
        const isExpiringIfWithinNumberOfMonths = options?.isExpiringIfWithinNumberOfMonths ?? 1;
        const isNewIfWithinNumberOfMonths = options?.isNewIfWithinNumberOfMonths ?? 1;
        this.#config = {
            forceCalculateBasicMeasures: options?.forceCalculateBasicMeasures ?? false,
            primaryScoreContributorThresholdAmount: options?.primaryScoreContributorThresholdAmount ?? 8,
            isExpiringIfWithinNumberOfMonths: isExpiringIfWithinNumberOfMonths,
            isNewIfWithinNumberOfMonths: isNewIfWithinNumberOfMonths,
            inspectionIsExpiringIfBeforeDate: DateTime.addMonths(dateRange.from, isExpiringIfWithinNumberOfMonths),
            inspectionIsNewIfAfterDate: DateTime.subtractMonths(dateRange.to, isNewIfWithinNumberOfMonths),
        };
    }

    get(key?: undefined): Configuration.Config;
    get<Key extends keyof Configuration.Config>(key: Key): Configuration.Config[Key];
    get<Key extends keyof Configuration.Config>(key?: Key): Configuration.Config | Configuration.Config[Key] {
        if (key) {
            return this.#config[key];
        }
        return this.#config;
    }
}

export namespace Configuration {
    export interface Config {
        forceCalculateBasicMeasures: boolean;
        isNewIfWithinNumberOfMonths: number;
        isExpiringIfWithinNumberOfMonths: number;
        /**
         * The number of units that will be consided "Top Contributors"
         */
        primaryScoreContributorThresholdAmount: number;
        inspectionIsExpiringIfBeforeDate: DateTime;
        inspectionIsNewIfAfterDate: DateTime;
    }

    export interface Options extends Partial<Omit<Config, "inspectionIsExpiringIfBeforeDate" | "inspectionIsNewIfAfterDate">> {}
}
