import { AttributeOption, Policy } from "@deathstar/types/northstar";
import { TrophyIcon } from "@heroicons/react/24/outline";
import { Outlet, Route, Routes, To } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import Unauthorized from "../../components/error-screens/Unauthorized";
import UncaughtException from "../../components/error-screens/UncaughtException";
import NavigationTabs from "../../components/navigation-tabs/NavigationTabs";
import Title from "../../components/title/Title";
import DriverRewardProgram from "../driver-reward-program/DriverRewardProgram";
import DriversTable from "./DriversTable";

export default function Drivers({ hideRewardProgram = false }: { hideRewardProgram?: boolean }) {
    const accountId = useAccountId();
    const { data, error, isError } = api.drivers.useFind(accountId!, {
        enabled: !!accountId,
    });

    const policies = api.policies.useFind(accountId!, "active");
    const showMonthlyReport = policies.data?.some(
        (policy) =>
            Policy.isMonthlyReporter(policy) &&
            policy.attributes.some((a) => a.attributeOptionId === AttributeOption.Id.OWNER_OPERATOR_WAYPOINT_PROGRAM)
    );

    return (
        <Routes>
            <Route
                element={
                    <div className="space-y-4 p-4 lg:p-12">
                        <Title className="mb-2">Drivers</Title>
                        <NavigationTabs
                            tabs={
                                [
                                    { label: "Driver list", to: "" },
                                    hideRewardProgram
                                        ? null
                                        : {
                                              label: (
                                                  <span>
                                                      <TrophyIcon className="-mt-1 mr-2 inline h-4 w-4" />
                                                      Reward program
                                                  </span>
                                              ),
                                              to: "rewards",
                                          },
                                ].filter(Boolean) as { label: string; to: To }[]
                            }
                        />
                        <Outlet />
                    </div>
                }
            >
                <Route
                    index
                    element={
                        isError ? (
                            error.status === 403 ? (
                                <Unauthorized />
                            ) : (
                                <UncaughtException />
                            )
                        ) : (
                            <DriversTable data={data} showMonthlyReport={showMonthlyReport} />
                        )
                    }
                />
                {hideRewardProgram ? null : <Route path="rewards" element={<DriverRewardProgram />} />}
            </Route>
        </Routes>
    );
}
