import React, { forwardRef, useEffect } from "react";
import { useForwardedRef } from "../hooks/useForwardedRef";
import styles from "./checkbox.module.css";

export interface CheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: React.ReactNode;
    indeterminate?: boolean;
    classes?: Partial<Record<"root" | "input" | "label", string>>;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ label, name, classes, indeterminate, ...props }, ref) => {
    const innerRef = useForwardedRef(ref);

    useEffect(() => {
        if (innerRef.current) {
            innerRef.current.indeterminate = Boolean(indeterminate);
        }
    }, [indeterminate, innerRef]);

    return (
        <div className={`${styles.root} ${classes?.root || ""}`}>
            <input
                name={name}
                type="checkbox"
                className={`peer ${styles.input} ${classes?.input || ""}`}
                {...props}
                autoComplete="off"
                ref={innerRef}
            />
            {label && (
                <label
                    className={`peer-enabled:cursor-pointer ${classes?.label || ""}`}
                    htmlFor={name}
                    onClick={() => {
                        if (innerRef.current) {
                            innerRef.current.click();
                        }
                    }}
                >
                    {label}
                </label>
            )}
        </div>
    );
});
