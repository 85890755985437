import { Line } from "react-chartjs-2";

import { config } from "../ChartConfig";

export function HistoricalViolationBreakdownChart({
    data,
    color,
    printVersion,
}: {
    data: { score: number; label: string }[];
    color: string;
    printVersion?: boolean;
}): JSX.Element {
    return (
        <Line
            data-print-height="130px"
            width={!printVersion ? "100%" : undefined}
            data={{
                labels: data.map((it) => it.label),
                datasets: [
                    {
                        label: "Historical Scores",
                        data: data.map((it) => it.score || null),
                        borderColor: color,
                        borderWidth: 2,
                    },
                ],
            }}
            options={{
                maintainAspectRatio: false,
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        beginAtZero: config.chart.line.axis.y.beginAtZero,
                        min: config.chart.line.axis.y.min,
                        max: config.chart.line.axis.y.max,
                        ticks: {
                            display: true,
                        },
                        grid: {
                            display: true,
                        },
                    },
                    x: {
                        ticks: {
                            color: config.chart.line.labels.font.color,
                        },
                        grid: {
                            display: true,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: config.chart.line.legend.display,
                    },
                },
            }}
        />
    );
}
