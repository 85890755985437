import { WaypointDirectory, WaypointFile } from "@deathstar/types/waypoint";
import {
    ArrowDownTrayIcon,
    ArrowUturnUpIcon,
    ChevronLeftIcon,
    DocumentIcon,
    EllipsisVerticalIcon,
    FolderIcon,
    PencilSquareIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import { CircularProgress } from "@material-ui/core";
import { keepPreviousData } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { escapeRegExp } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSnackbar } from "../hooks/useSnackbar";
import { InputField } from "../inputField/inputField";
import { Popover } from "../popover/popover";
import { PrimaryButton } from "../primaryButton/primaryButton";
import { humanizeFileSize } from "../utilities";
import { AzureStorageBrowserContext } from "./AzureStorageBrowserContext";

interface FolderActionsProps {
    root: string;
    item: WaypointDirectory | WaypointFile;
    refetch: () => void;
}

function FolderActions({ item, refetch, root }: FolderActionsProps) {
    const { api } = useContext(AzureStorageBrowserContext);
    const [moving, setMoving] = useState(false);
    const [moveTarget, setMoveTarget] = useState("");

    const [renaming, setRenaming] = useState(false);
    const [newName, setNewName] = useState("");
    const extension = item.name.lastIndexOf(".") >= 0 ? item.name.slice(item.name.lastIndexOf(".")) : "";

    const { data: targetDirectory, isLoading } = api.useDirectory(root + moveTarget, { placeholderData: keepPreviousData });
    const download = api.useDownload({
        onSuccess(file) {
            const url = window.URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        },
    });

    const reset = useCallback(() => {
        setMoving(false);
        setMoveTarget("");
        setRenaming(false);
        setNewName("");
    }, []);

    const move = api.useMove();
    const deleteFile = api.useDelete();

    const renameFile = useCallback(
        async (onSuccess: () => void) => {
            if (!newName) {
                useSnackbar.add("Invalid file name", { variant: "error" });
                return;
            }
            if (newName.match(/[/\\]/)) {
                useSnackbar.add("Invalid file name", { variant: "error" });
                return;
            }
            move.mutate(
                {
                    source: item.type === "file" ? item.path : item.path + "/",
                    target: item.path.replace(new RegExp(escapeRegExp(item.name) + "$"), newName + extension),
                },
                {
                    onSuccess: () => {
                        onSuccess();
                        refetch();
                        reset();
                    },
                }
            );
        },
        [extension, item.name, item.path, item.type, move, newName, refetch, reset]
    );

    return (
        <Popover className="cursor-default">
            <Popover.Button className="rounded-md p-1 hover:bg-black/5" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                <EllipsisVerticalIcon className="h-4 w-4 text-stone-500" />
            </Popover.Button>
            <Popover.Content
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                transitionProps={{
                    afterLeave: () => {
                        reset();
                    },
                }}
                className="overflow-hidden"
                style={{ width: moving ? 240 : "unset" }}
            >
                {({ close }) =>
                    moving ? (
                        <div className="flex h-full flex-col gap-4">
                            <div className="flex items-center gap-2">
                                {moveTarget && moveTarget !== root && (
                                    <button
                                        onClick={() => {
                                            setMoveTarget(moveTarget.split("/").slice(0, -1).join("/") || "");
                                        }}
                                    >
                                        <ChevronLeftIcon className="h-5 w-5 text-stone-500" />
                                    </button>
                                )}
                                <p>{targetDirectory?.name}</p>
                            </div>
                            <div className="relative rounded border">
                                {isLoading && (
                                    <div className="absolute inset-0 bg-black/5">
                                        <CircularProgress className="absolute inset-1/2" size="2rem" />
                                    </div>
                                )}
                                <ul className="overflow-y-auto" style={{ height: 170 }}>
                                    {targetDirectory?.children.length ? (
                                        targetDirectory.children.map((child) => (
                                            <li key={child.path}>
                                                {child.type === "directory" ? (
                                                    <button
                                                        className="flex w-full items-center gap-2 overflow-hidden rounded px-2 py-1 text-left text-stone-700 disabled:opacity-50 enabled:hover:bg-stone-100"
                                                        onClick={() => {
                                                            setMoveTarget(child.path.replace(root, ""));
                                                        }}
                                                        disabled={child.path === item.path}
                                                    >
                                                        <FolderIcon className="h-4 w-4 shrink-0 text-waypoint-orange" />
                                                        <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                            {child.name}
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <div className="flex w-full items-center gap-2 overflow-hidden rounded px-2 py-1 text-left text-stone-700 opacity-50">
                                                        <DocumentIcon className="h-4 w-4 shrink-0 text-waypoint-blue" />
                                                        <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                            {child.name}
                                                        </span>
                                                    </div>
                                                )}
                                            </li>
                                        ))
                                    ) : (
                                        <p className="italic text-stone-500"></p>
                                    )}
                                </ul>
                            </div>
                            <div className="flex grow items-end">
                                <PrimaryButton
                                    className="h-max w-full overflow-hidden"
                                    onClick={async () => {
                                        move.mutate(
                                            { source: item.path, target: targetDirectory!.path + "/" + item.name },
                                            {
                                                onSuccess: () => {
                                                    refetch();
                                                    reset();
                                                    close();
                                                },
                                            }
                                        );
                                    }}
                                >
                                    <ArrowUturnUpIcon className="h-4 w-4" />
                                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">Move to here</span>
                                </PrimaryButton>
                            </div>
                        </div>
                    ) : renaming ? (
                        <div className="flex w-max">
                            <InputField
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                placeholder="Name"
                                classes={{ inputContainer: "rounded-r-none ring-0 w-[32ch]" }}
                                autoFocus
                                onFocus={(e) => e.currentTarget.select()}
                                onKeyDown={async (e) => {
                                    if (e.key === "Enter") {
                                        renameFile(() => {
                                            close();
                                        });
                                    }
                                }}
                            />
                            <PrimaryButton
                                className="!rounded-l-none"
                                onClick={async () => {
                                    renameFile(() => {
                                        close();
                                    });
                                }}
                            >
                                Rename
                                <PencilSquareIcon className="ml-2 h-4 w-4" />
                            </PrimaryButton>
                        </div>
                    ) : (
                        <>
                            <Popover.Item
                                className="py-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    download.mutate({ path: item.path });
                                }}
                                disabled={download.isPending}
                            >
                                <ArrowDownTrayIcon className="h-4 w-4 text-stone-500" />
                                <span>Download</span>
                            </Popover.Item>
                            <Popover.Item
                                className="py-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setMoving(true);
                                    setMoveTarget(item.path.replace(root, "").split("/").slice(0, -1).join("/") || "");
                                }}
                            >
                                <ArrowUturnUpIcon className="h-4 w-4 text-stone-500" />
                                <span>Move</span>
                            </Popover.Item>
                            <Popover.Item
                                className="py-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setRenaming(true);
                                    setNewName(item.name.split(".").slice(0, -1).join(".") || item.name);
                                }}
                            >
                                <PencilSquareIcon className="h-4 w-4 text-stone-500" />
                                <span>Rename</span>
                            </Popover.Item>
                            <Popover.Item
                                className="py-2"
                                onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    deleteFile.mutate(item.path, {
                                        onSuccess: () => {
                                            refetch();
                                            reset();
                                            close();
                                        },
                                    });
                                }}
                            >
                                <TrashIcon className="h-4 w-4 text-red-600" />
                                <span>Delete</span>
                            </Popover.Item>
                        </>
                    )
                }
            </Popover.Content>
        </Popover>
    );
}

export function useAzureStorageBrowserColumns(refetch: () => void) {
    const { root } = useContext(AzureStorageBrowserContext);

    return useMemo<ColumnDef<WaypointDirectory | WaypointFile>[]>(
        () => [
            {
                id: "mobile",
                cell: ({ row }) => (
                    <div className="grid grid-cols-[max-content,1fr,max-content] items-center gap-2">
                        {row.original.type === "directory" ? (
                            <FolderIcon className="h-4 w-4 text-waypoint-orange" />
                        ) : (
                            <DocumentIcon className="h-4 w-4 text-waypoint-blue" />
                        )}
                        <p>{row.original.name}</p>
                        <FolderActions item={row.original} refetch={refetch} root={root} />
                    </div>
                ),
                meta: {
                    responsive: true,
                },
            },
            {
                id: "path",
                accessorKey: "path",
                meta: {
                    hidden: true,
                },
                filterFn: (row, _columnId, filterValue) => {
                    let prefix = row.original.path.split("/").slice(0, -1).join("/");
                    if (prefix === "") {
                        prefix = "/";
                    }
                    return filterValue === prefix;
                },
                enableGlobalFilter: false,
            },
            {
                id: "icon",
                cell: ({ row }) =>
                    row.original.type === "directory" ? (
                        <FolderIcon className="h-4 w-4 text-waypoint-orange" />
                    ) : (
                        <DocumentIcon className="h-4 w-4 text-waypoint-blue" />
                    ),
                meta: { classes: { header: "w-[24px] !px-1", cell: "!px-1" } },
                enableGlobalFilter: false,
            },
            {
                id: "name",
                accessorKey: "name",
                sortingFn: "alphanumeric",
                cell: ({ getValue }) => <p className="overflow-hidden text-ellipsis whitespace-nowrap">{getValue() as string}</p>,
                meta: { classes: { header: "w-[512px]" } },
            },
            {
                id: "createdOn",
                header: "Date added",
                accessorFn: (row) => (row.type === "file" ? row.properties.createdOn : undefined),
                cell: ({ getValue }) => (
                    <span className="tabular-nums text-stone-500">
                        {getValue() ? moment(getValue() as string).format("MMM D, YYYY [at] h:mm A") : null}
                    </span>
                ),
                meta: { classes: { header: "w-[212px]" } },
            },
            {
                id: "contentLength",
                header: "Size",
                accessorFn: (row) => (row.type === "file" ? row.properties.contentLength : undefined),
                cell: ({ getValue }) => (
                    <span className="tabular-nums text-stone-500">{getValue() ? humanizeFileSize(getValue() as number) : null}</span>
                ),
                meta: { align: "right", classes: { header: "w-[128px]" } },
            },
            {
                id: "actions",
                cell: ({ row }) => <FolderActions item={row.original} refetch={refetch} root={root} />,
            },
        ],
        [refetch, root]
    );
}
