import { classNames, Popover } from "@deathstar/ui";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";

export function OrgSwitcher() {
    const { data: accounts } = api.user.useMe({
        select: (x) => x.memberships?.map((m) => ({ accountId: m.accountId, name: m.account.company.name })),
    });
    const accountId = useAccountId();
    const { data: selectedAccount } = api.organization.useById(accountId!);

    return (
        <Popover>
            <Popover.Button className="flex w-full items-center gap-2 rounded p-2 text-stone-800 hover:text-navigator-primary">
                <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-stone-600">
                    {selectedAccount?.company.name}
                </p>
                <ChevronDownIcon className="h-4 w-4 shrink-0" />
            </Popover.Button>
            <Popover.Content>
                {({ close }) => (
                    <div className="relative grid bg-white">
                        {accounts?.map((account) => (
                            <Link
                                key={account.accountId}
                                to={`/sol/${account.accountId}`}
                                onClick={() => close()}
                                className={classNames(
                                    "whitespace-nowrap rounded p-2 text-left",
                                    selectedAccount?.id === account.accountId
                                        ? "bg-navigator-primary text-white"
                                        : "hover:text-navigator-primary"
                                )}
                            >
                                {account.name}
                            </Link>
                        ))}
                    </div>
                )}
            </Popover.Content>
        </Popover>
    );
}
