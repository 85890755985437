interface Deferred {
    promise: Promise<() => Promise<string>>;
    resolve: (value: () => Promise<string>) => void;
}

/* A hack to get the access token outside of React Context.
 * See https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c
 */
export const auth0: {
    deferred: Deferred;
    getAccessToken: () => Promise<string>;
} = {
    deferred: (() => {
        const props = {} as unknown as Deferred;
        props.promise = new Promise((resolve) => (props.resolve = resolve));
        return props;
    })(),

    getAccessToken: async () => {
        const getToken = await auth0.deferred.promise;
        return getToken();
    },
};
