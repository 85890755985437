import { ArrowDownIcon, ArrowUpIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { RankingInfo, rankings, rankItem } from "@tanstack/match-sorter-utils";
import { CellContext, ColumnDef, FilterFn, flexRender, RowData, Table } from "@tanstack/react-table";
import React, { CSSProperties, useRef } from "react";
import { ActionButton } from "../actionButton/ActionButton";
import { Checkbox } from "../checkbox/checkbox";
import { classNames } from "../classNames/classNames";
import { useSize } from "../hooks/useSize";
import { ColumnEditor, IHideableColumn } from "./ColumnEditor";
import { ColumnFilter } from "./ColumnFilter";
import { ColumnFilterButton, ColumnFilterButtonProps as IColumnFilterButtonProps } from "./ColumnFilterButton";
import { ColumnOptionFilterButton, ColumnOptionFilterButtonProps as IColumnOptionFilterButtonProps } from "./ColumnOptionFilterButton";
import { DebouncedInput } from "./DebounceInput";
import { Search } from "./Search";

declare module "@tanstack/react-table" {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    export interface ColumnMeta<TData, TValue> {
        classes?: {
            header?: string;
            cell?: string;
            footer?: string;
        };
        tooltip?: string;
        align?: "left" | "center" | "right";
        responsive?: boolean;
        hidden?: boolean;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    export interface TableOptions<TData extends RowData> {
        filterFns?: FilterFns;
    }
    interface FilterFns {
        fuzzy: FilterFn<unknown>;
    }
    interface FilterMeta {
        itemRank: RankingInfo;
    }
}

interface DataTableAction {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    getIsDisabled?: () => boolean;
    getIsLoading?: () => boolean;
}

export interface DataTableProps<T> {
    table: Table<T>;
    actions?: (DataTableAction | React.ReactNode)[];
    className?: string;
    style?: CSSProperties;
    onRowClick?: (row: T) => void;
    getRowClassName?: (row: T) => string;
    searchPlaceholder?: string;
    disableSearch?: boolean;
    filters?: JSX.Element;
    hideableColumns?: IHideableColumn[];
    headerRow?: JSX.Element;
    isLoading?: boolean;
    noDataLabel?: React.ReactNode;
    customPagination?: React.ReactNode;
    onClearFilters?: () => void;
}

export const selectColumn: ColumnDef<never> = {
    id: "select",
    cell: ({ row, table }) => (
        <Checkbox
            classes={{ input: "w-4 h-4 border-stone-300 !border shadow-sm enabled:hover:shadow" }}
            checked={table.getState().rowSelection[row.id] ?? false}
            onChange={(e) => {
                table.setRowSelection((old) => ({
                    ...old,
                    [row.id]: e.target.checked,
                }));
            }}
        />
    ),
    header: ({ table }) => (
        <Checkbox
            classes={{ input: "w-4 h-4 border-stone-300 !border shadow-sm enabled:hover:shadow" }}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
        />
    ),
};

export function PrimaryCell<T, X>({ getValue }: CellContext<T, X>) {
    return <p className="whitespace-nowrap font-bold">{getValue() ? String(getValue()) : null}</p>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fuzzyFilter: FilterFn<any> = (row, columnId, value: string, addMeta) => {
    const rowValue = row.getValue(columnId);
    const itemRank = rankItem(rowValue, value, { threshold: rankings.MATCHES });
    addMeta({ itemRank });
    return itemRank.passed;
};

export function DataTable<T>({
    table,
    actions,
    className,
    style,
    onRowClick,
    getRowClassName,
    disableSearch,
    filters,
    hideableColumns,
    headerRow,
    searchPlaceholder,
    isLoading,
    noDataLabel,
    customPagination,
    onClearFilters,
}: DataTableProps<T>) {
    const rows = table.getRowModel().rows;
    const tableState = table.getState();
    const selected = tableState.rowSelection;

    const isResponsive = table.getAllColumns().some((column) => column.columnDef.meta?.responsive);

    const showActionHeader = !!headerRow || !!actions?.length || !!filters || !disableSearch || !!hideableColumns;
    const actionHeaderRef = useRef(null);

    const [, actionHeaderHeight] = useSize(actionHeaderRef);

    const footerGroups = table.getFooterGroups();

    return (
        <table
            className={classNames(
                "w-full table-auto bg-inherit text-sm table-sticky col-spacing-4 row-p-2 row-spacing-2 print:text-xs",
                className
            )}
            style={style}
        >
            <thead>
                {showActionHeader && (
                    <tr ref={actionHeaderRef}>
                        <th colSpan={table.getAllColumns().length} className="!px-0 font-normal">
                            {headerRow ? (
                                headerRow
                            ) : (
                                <div className="flex w-full grow flex-col-reverse gap-2 md:flex-row md:justify-between">
                                    <div className="flex items-end gap-2">
                                        {actions?.map((action, i) => {
                                            if (!action) {
                                                return null;
                                            } else if (React.isValidElement(action)) {
                                                return <React.Fragment key={i}>{action}</React.Fragment>;
                                            } else {
                                                const actionDef = action as DataTableAction;
                                                return (
                                                    <ActionButton
                                                        key={actionDef.label}
                                                        disabled={actionDef.getIsDisabled?.()}
                                                        onClick={actionDef.onClick}
                                                    >
                                                        {actionDef.icon}
                                                        {actionDef.getIsLoading?.() ? <CircularProgress size="1rem" /> : actionDef.label}
                                                    </ActionButton>
                                                );
                                            }
                                        })}
                                        {filters && filters}
                                    </div>
                                    <div className="sticky right-0 flex justify-end gap-2 print:hidden">
                                        {!disableSearch && (
                                            <Search table={table} placeholder={searchPlaceholder} onClearFilters={onClearFilters} />
                                        )}
                                        {hideableColumns && <ColumnEditor table={table} hideableColumns={hideableColumns} />}
                                    </div>
                                </div>
                            )}
                        </th>
                    </tr>
                )}
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="border-b border-stone-200">
                        {headerGroup.headers.map((header) => (
                            <th
                                key={header.id}
                                colSpan={header.colSpan}
                                style={{ top: showActionHeader ? actionHeaderHeight : 0 }}
                                className={classNames(
                                    "pt-2 text-left text-base font-normal text-stone-600 small-caps first:rounded-tl last:rounded-tr",
                                    header.column.columnDef.meta?.hidden && "!hidden",
                                    isResponsive &&
                                        (header.column.columnDef.meta?.responsive ? "table-cell md:hidden" : "hidden md:table-cell"),
                                    header.column.columnDef.meta?.classes?.header,
                                    header.column.getCanSort() && table.options.getSortedRowModel && "cursor-pointer select-none"
                                )}
                                onClick={table.options.getSortedRowModel ? header.column.getToggleSortingHandler() : undefined}
                            >
                                {header.isPlaceholder ? null : (
                                    <Tooltip title={header.column.columnDef.meta?.tooltip || ""}>
                                        <div
                                            className={classNames(
                                                "flex items-center",
                                                header.column.columnDef.meta?.align === "right" && "justify-end text-right",
                                                header.column.columnDef.meta?.align === "center" && "justify-center text-center"
                                            )}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {{
                                                asc: <ArrowUpIcon className="inline-block h-4 w-4" />,
                                                desc: <ArrowDownIcon className="inline-block h-4 w-4" />,
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    </Tooltip>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {rows.length ? (
                    rows.map((row) => (
                        <tr
                            key={row.id}
                            className={classNames(
                                "rounded border-b border-stone-200 py-1 transition-colors",
                                selected[row.id] && "bg-stone-100",
                                onRowClick && "cursor-pointer hover:bg-stone-50",
                                getRowClassName?.(row.original)
                            )}
                            onClick={() => {
                                onRowClick?.(row.original);
                            }}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <td
                                    key={cell.id}
                                    className={classNames(
                                        cell.column.columnDef.meta?.classes?.cell,
                                        cell.column.columnDef.meta?.hidden && "!hidden",
                                        cell.column.columnDef.meta?.align === "right" && "text-right",
                                        cell.column.columnDef.meta?.align === "center" && "text-center",
                                        isResponsive &&
                                            (cell.column.columnDef.meta?.responsive ? "table-cell md:hidden" : "hidden md:table-cell")
                                    )}
                                    colSpan={cell.column.columnDef.meta?.responsive ? row.getVisibleCells().length : undefined}
                                >
                                    {cell.getIsGrouped() ? (
                                        <div className="flex items-center gap-1">
                                            <button onClick={row.getToggleExpandedHandler()}>
                                                <ChevronRightIcon
                                                    className={classNames(
                                                        "h-4 w-4 transition-transform",
                                                        row.getIsExpanded() ? "rotate-90" : "rotate-0"
                                                    )}
                                                />
                                            </button>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </div>
                                    ) : cell.getIsAggregated() ? (
                                        flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                                    ) : cell.getIsPlaceholder() ? null : (
                                        flexRender(cell.column.columnDef.cell, cell.getContext())
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={table.getAllColumns().length} className="p-4 text-center text-gray-400">
                            {isLoading ? <CircularProgress size="2rem" /> : noDataLabel ?? "No rows to show"}
                        </td>
                    </tr>
                )}
            </tbody>
            {((footerGroups.length > 0 && footerGroups.some((f) => f.headers.some((h) => h.column.columnDef.footer))) ||
                table.options.getPaginationRowModel) && (
                <tfoot>
                    {footerGroups.map((footerGroup) => (
                        <tr key={footerGroup.id}>
                            {footerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    className={classNames(
                                        "pt-2 text-left font-normal text-stone-600 first:rounded-tl last:rounded-tr",
                                        header.column.columnDef.meta?.hidden && "!hidden",
                                        isResponsive &&
                                            (header.column.columnDef.meta?.responsive ? "table-cell md:hidden" : "hidden md:table-cell"),
                                        header.column.columnDef.meta?.classes?.footer
                                    )}
                                >
                                    {header.isPlaceholder ? null : (
                                        <div
                                            className={classNames(
                                                "flex items-center",
                                                header.column.columnDef.meta?.align === "right" && "justify-end text-right",
                                                header.column.columnDef.meta?.align === "center" && "justify-center text-center"
                                            )}
                                        >
                                            {flexRender(header.column.columnDef.footer, header.getContext())}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    {table.options.getPaginationRowModel &&
                        (customPagination || (
                            <tr className="print:hidden">
                                <td colSpan={table.getAllColumns().length}>
                                    <div className="flex items-center justify-between p-4">
                                        <span className="text-stone-600">
                                            <span className="font-medium">{table.getFilteredRowModel().rows.length}</span> results
                                        </span>
                                        <div className="flex gap-2">
                                            <ActionButton
                                                onClick={() => {
                                                    table.previousPage();
                                                }}
                                                disabled={!table.getCanPreviousPage()}
                                            >
                                                Previous
                                            </ActionButton>
                                            <ActionButton
                                                onClick={() => {
                                                    table.nextPage();
                                                }}
                                                disabled={!table.getCanNextPage()}
                                            >
                                                Next
                                            </ActionButton>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tfoot>
            )}
        </table>
    );
}

DataTable.fuzzyFilter = fuzzyFilter;
DataTable.ColumnFilter = ColumnFilter;
DataTable.Search = Search;
DataTable.ColumnEditor = ColumnEditor;
DataTable.DebouncedInput = DebouncedInput;
DataTable.ColumnFilterButton = ColumnFilterButton;
DataTable.ColumnOptionFilterButton = ColumnOptionFilterButton;

export interface DataTable {
    fuzzyFilter: typeof fuzzyFilter;
    ColumnFilter: typeof ColumnFilter;
    Search: typeof Search;
    ColumnEditor: typeof ColumnEditor;
    DebouncedInput: typeof DebouncedInput;
    ColumnFilterButton: typeof ColumnFilterButton;
    ColumnOptionFilterButton: typeof ColumnOptionFilterButton;
}

export namespace DataTable {
    export type HideableColumn = IHideableColumn;
    export interface ColumnFilterButtonProps<RowData = unknown, ColumnData = unknown>
        extends IColumnFilterButtonProps<RowData, ColumnData> {}
    export interface ColumnOptionFilterButtonProps<RowData = unknown, ColumnData = unknown>
        extends IColumnOptionFilterButtonProps<RowData, ColumnData> {}
}
