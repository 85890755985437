import React, { forwardRef } from "react";
import tw from "twin.macro";

/**
 * Classes to be used with Twin.Macro styling
 * @example <button css={[tw`${secondaryButtonClasses}]}>Foobar</button>
 *
 */
export const secondaryButtonClasses =
    "text-blue-600 flex items-center border justify-center gap-2 px-4 py-2 border-current rounded shadow enabled:active:bg-none active:shadow-none focus:ring-2 ring-current ring-offset-1 enabled:hover:shadow-lg outline-none disabled:shadow-none disabled:pointer-events-none disabled:opacity-50";

export const SecondaryButton = forwardRef<
    HTMLButtonElement,
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>(({ css, ...props }, ref) => {
    return (
        <button
            {...props}
            css={[
                {
                    transitionProperty: "box-shadow, color, background-color, border-color, text-decoration-color, fill-stroke",
                    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                    transitionDuration: "150ms",
                },
                tw`${secondaryButtonClasses}`,
                css,
            ]}
            ref={ref}
        ></button>
    );
});
