export class PolicyReconciliation {
    static get<T extends keyof PolicyReconciliation.FieldValueMap>(
        reconciliation: PolicyReconciliation,
        property: T
    ): PolicyReconciliation.CheckedValue<T> {
        return reconciliation.checkedValues.find((value) => value.field === property) as PolicyReconciliation.CheckedValue<T>;
    }

    accountId: string;
    companyName: string;
    policyId: string;
    policyNumber: string;
    policyEffectiveDate: Date;
    policyCreatedDate: Date;
    amsPolicyId: string;
    checkedValues: PolicyReconciliation.CheckedValue[];
    isReconciled: boolean;
}

export namespace PolicyReconciliation {
    export interface FieldValueMap {
        policyNumber: IPrimativeValue;
        effectiveDate: IPrimativeValue;
        expirationDate: IPrimativeValue;
        accountManager: IPrimativeValue;
        agent: IPrimativeValue;
        parentCompany: IPrimativeValue;
        writingCompany: IPrimativeValue;
        fullTermPremium: IPrimativeValue;
        layers: IAccountingLayerValue[];
    }
    export interface IPrimativeValue {
        ams: string | number | boolean;
        ns: string | number | boolean;
    }
    export interface IAccountingValue {
        code: string;
        description: string;
        ams: number;
        ns: number;
        isReconciled: boolean;
    }
    export interface IAccountingLayerValue {
        isReconciled: boolean;
        layerEffectiveDate: {
            isReconciled: boolean;
            ams: Date;
            ns: Date;
        };
        layerDescription: IPrimativeValue & { isReconciled: boolean };
        layerTransactionType: IPrimativeValue & { isReconciled: boolean };
    }
    export class CheckedValue<T extends keyof FieldValueMap = keyof FieldValueMap> {
        field: T;
        isReconciled: boolean;
        values: FieldValueMap[T];
    }
}
