import { dollarsToCents } from "./formatting";

/**
 * @param amount The bill amount in cents
 * @param method The payment method being used. This determines the fees that will be taken out.
 * @returns The fee in cents that will be taken out of the bill amount.
 * @throws If the amount is over $100,000, an error will be thrown because we don't support card payments over that amount.
 */
export function getFeeAmount(amount: number, method: "card" | "us_bank_account"): number {
    switch (method) {
        case "us_bank_account": {
            return 160;
        }
        case "card": {
            if (amount <= dollarsToCents(3_000)) {
                return dollarsToCents(100);
            }
            if (amount <= dollarsToCents(5_000)) {
                return dollarsToCents(150);
            }
            if (amount <= dollarsToCents(7_000)) {
                return dollarsToCents(200);
            }
            if (amount <= dollarsToCents(9_000)) {
                return dollarsToCents(250);
            }
            if (amount <= dollarsToCents(11_000)) {
                return dollarsToCents(300);
            }
            if (amount <= dollarsToCents(13_000)) {
                return dollarsToCents(350);
            }
            if (amount <= dollarsToCents(15_000)) {
                return dollarsToCents(450);
            }
            if (amount <= dollarsToCents(17_000)) {
                return dollarsToCents(525);
            }
            if (amount <= dollarsToCents(19_000)) {
                return dollarsToCents(575);
            }
            if (amount <= dollarsToCents(22_000)) {
                return dollarsToCents(650);
            }
            if (amount <= dollarsToCents(25_000)) {
                return dollarsToCents(750);
            }
            if (amount <= dollarsToCents(30_000)) {
                return dollarsToCents(900);
            }
            if (amount <= dollarsToCents(35_000)) {
                return dollarsToCents(1_050);
            }
            if (amount <= dollarsToCents(40_000)) {
                return dollarsToCents(1_200);
            }
            if (amount <= dollarsToCents(45_000)) {
                return dollarsToCents(1_350);
            }
            if (amount <= dollarsToCents(50_000)) {
                return dollarsToCents(1_500);
            }
            if (amount <= dollarsToCents(55_000)) {
                return dollarsToCents(1_650);
            }
            if (amount <= dollarsToCents(60_000)) {
                return dollarsToCents(1_800);
            }
            if (amount <= dollarsToCents(65_000)) {
                return dollarsToCents(1_950);
            }
            if (amount <= dollarsToCents(70_000)) {
                return dollarsToCents(2_100);
            }
            if (amount <= dollarsToCents(75_000)) {
                return dollarsToCents(2_250);
            }
            if (amount <= dollarsToCents(80_000)) {
                return dollarsToCents(2_400);
            }
            if (amount <= dollarsToCents(85_000)) {
                return dollarsToCents(2_550);
            }
            if (amount <= dollarsToCents(90_000)) {
                return dollarsToCents(2_700);
            }
            if (amount <= dollarsToCents(95_000)) {
                return dollarsToCents(2_850);
            }
            if (amount <= dollarsToCents(100_000)) {
                return dollarsToCents(3_000);
            }
            throw new Error("Card payments over $100,000 are not supported");
        }
    }
}

/**
 * Get whether or not the fee should be included in the total. Navigator passes CC fees on to the customer, but
 * does not pass on ACH fees, for instance.
 */
export function shouldIncludeFeeInTotal(method: "card" | "us_bank_account") {
    switch (method) {
        case "card":
            return true;
        case "us_bank_account":
            return false;
    }
}
