import { Zendesk } from "@deathstar/types/waypoint";
import { classNames, Dropzone, humanizeFileSize, PrimaryButton, RichTextEditor, SecondaryButton } from "@deathstar/ui";
import { ArrowUpTrayIcon, ChevronLeftIcon, ChevronUpIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import dompurify from "dompurify";
import { uniqBy } from "lodash";
import moment from "moment";
import { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import Loader from "../../components/loader/Loader";
import AttachmentLink from "./AttachmentLink";
import RequestStatus from "./RequestStatus";

export default function Request() {
    const navigate = useNavigate();
    const accountId = useAccountId();
    const { requestId } = useParams();

    const [expanded, setExpanded] = useState(false);
    const fileInput = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<File[]>([]);
    const [comment, setComment] = useState("");

    const { data, isError } = api.zendesk.useRequest(accountId!, parseInt(requestId!));

    const { data: comments, isError: isErrorComments } = api.zendesk.useComments(accountId!, parseInt(requestId!));

    const { mutate: createComment, isPending } = api.zendesk.useCreateComment(accountId!, parseInt(requestId!), {
        onSuccess: () => {
            setComment("");
            setFiles([]);
        },
    });

    const attachments = useMemo(() => comments?.comments.flatMap((comment) => comment.attachments) || [], [comments]);

    return (
        <div className="flex grow flex-col">
            <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-8">
                <button
                    onClick={() => {
                        if ((comment && comment !== "<p><br></p>") || files.length) {
                            if (!window.confirm("Are you sure you want to leave? Your changes will not be saved.")) {
                                return;
                            }
                        }
                        navigate("../requests");
                    }}
                    className="flex w-max items-center gap-2 text-sm text-blue-600 hover:text-blue-800"
                >
                    <ChevronLeftIcon className="h-4 w-4" />
                    <span>Back to questions</span>
                </button>
                {data && <p className="text-xl font-medium">{data.request.subject || data.request.description}</p>}
            </div>
            {isError ? (
                <div className="flex h-full min-h-[50vh] w-full items-center justify-center">
                    <p>Question not found</p>
                </div>
            ) : data ? (
                <div className="mt-4 grow">
                    <div className="flex h-full flex-col-reverse gap-8 lg:flex-row">
                        <div className="flex-grow md:border-t">
                            {isErrorComments ? (
                                <div className="flex h-full w-full items-center justify-center">
                                    <p>Unable to load comments</p>
                                </div>
                            ) : comments ? (
                                <div className="space-y-4">
                                    <ul>
                                        {comments.comments.map((comment) => (
                                            <Comment key={comment.id} data={{ comment, users: comments.users }} />
                                        ))}
                                    </ul>
                                    <Dropzone
                                        id="request-comment-dropzone"
                                        onFileDrop={(newFiles) => {
                                            setFiles((old) => uniqBy([...old, ...newFiles], (file) => file.name));
                                        }}
                                    >
                                        {({ getProps, handleFileSelect, isDragActive }) => (
                                            <div
                                                className={classNames(
                                                    "rounded border-2 border-dashed",
                                                    isDragActive ? "border-blue-700" : "border-transparent"
                                                )}
                                                {...getProps()}
                                            >
                                                <RichTextEditor
                                                    value={comment}
                                                    onChange={(value) => setComment(value)}
                                                    placeholder="Add to the conversation..."
                                                    editorClassName="[&>*]:min-h-[100px]"
                                                />
                                                <input
                                                    ref={fileInput}
                                                    type="file"
                                                    multiple
                                                    className="hidden"
                                                    onChange={handleFileSelect}
                                                />
                                                <ul className="mt-2 space-y-1 text-sm">
                                                    {files.map((file) => (
                                                        <li key={file.name} className="flex items-center gap-2">
                                                            <PaperClipIcon className="h-4 w-4" />
                                                            <span className="line-clamp-1">{file.name}</span>
                                                            <span className="whitespace-pre">({humanizeFileSize(file.size)})</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="mt-2 flex flex-col justify-between gap-2 md:flex-row">
                                                    <div className="flex flex-col gap-2 md:flex-row">
                                                        <SecondaryButton
                                                            className="h-max !py-1 text-sm !text-gray-600"
                                                            onClick={() => fileInput.current?.click()}
                                                        >
                                                            <ArrowUpTrayIcon className="h-4 w-4 shrink-0" />
                                                            Click or drag files to attach
                                                        </SecondaryButton>
                                                        {files.length ? (
                                                            <button
                                                                onClick={() => {
                                                                    setFiles([]);
                                                                }}
                                                            >
                                                                Clear attachments
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                    <PrimaryButton
                                                        disabled={isPending || !comment}
                                                        onClick={() => {
                                                            createComment({ html_body: comment, files });
                                                        }}
                                                    >
                                                        Submit
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            ) : (
                                <Loader />
                            )}
                        </div>
                        <div className="h-max border-y text-sm md:border-y-0">
                            <button
                                className="flex w-full items-center justify-between p-2 text-base font-medium text-stone-600 md:hidden"
                                onClick={() => setExpanded(!expanded)}
                            >
                                <span>Question details</span>
                                <ChevronUpIcon
                                    className={classNames("h-4 w-4 transition-transform", expanded ? "rotate-0" : "rotate-180")}
                                />
                            </button>
                            <div
                                className={classNames(
                                    "w-full overflow-y-hidden transition-all md:h-max md:rounded md:bg-stone-100",
                                    expanded ? "" : "h-0"
                                )}
                            >
                                <table className={classNames("w-full table-auto col-spacing-4 row-p-4 row-spacing-2")}>
                                    <tbody>
                                        <tr>
                                            <td className="text-stone-600">Asked by</td>
                                            <td>{data.users.find((u) => u.id === data.request.requester_id)?.name}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-stone-600">Created</td>
                                            <td>{moment(data.request.created_at).fromNow()}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-stone-600">Last activity</td>
                                            <td>{moment(data.request.updated_at).fromNow()}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-stone-600">Question ID</td>
                                            <td>#{data.request.id}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-stone-600">Status</td>
                                            <td>
                                                <RequestStatus status={data.request.status} statusCategory={data.request.status_category} />
                                            </td>
                                        </tr>
                                        {data.request.fields.map((field) => (
                                            <tr key={field.id}>
                                                <td className="text-stone-600">{field.ticket_field?.title || "[Unknown field]"}</td>
                                                <td>
                                                    {field.value === null
                                                        ? "-"
                                                        : field.ticket_field?.type === "tagger"
                                                        ? field.ticket_field.custom_field_options?.find(
                                                              (option) => option.value === field.value
                                                          )?.name || String(field.value)
                                                        : field.value === true
                                                        ? "Yes"
                                                        : field.value === false
                                                        ? "No"
                                                        : String(field.value)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {attachments.length ? (
                                    <div className="border-t px-4 py-2">
                                        <p className="text-stone-700">Attachments</p>
                                        <ul className="ml-2 mt-2 space-y-2">
                                            {attachments.map((attachment) => (
                                                <li key={attachment.id}>
                                                    <AttachmentLink attachment={attachment} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
}

function Comment({ data }: { data: { comment: Zendesk.RequestComment; users: Zendesk.User[] } }) {
    return (
        <li className="w-full space-y-4 border-b py-4 text-sm">
            <div className="flex justify-between">
                <span className="font-medium">{data.users.find((u) => u.id === data.comment.author_id)?.name}</span>
                <span className="text-stone-500">{moment(data.comment.created_at).fromNow()}</span>
            </div>
            <div
                className="rich-text ml-4 max-w-prose text-stone-700"
                dangerouslySetInnerHTML={{ __html: dompurify.sanitize(data.comment.html_body) }}
            />
            <ul className="ml-4 space-y-1">
                {data.comment.attachments.map((attachment) => (
                    <li key={attachment.id}>
                        <AttachmentLink attachment={attachment} />
                    </li>
                ))}
            </ul>
        </li>
    );
}
