import { plainToClass } from "class-transformer";
import { Validate, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from "class-validator";
import { NumberFormatter } from "../util";
import type { CommissionOption } from "./CommissionOption";
import { Comparison } from "./Comparison/Comparison";
import type { Policy } from "./Policy";
import { PolicyEnums } from "./Policy/Enums";

@ValidatorConstraint({
    name: "CommissionsRequiredValidator",
    async: false,
})
export class CommissionsRequiredValidator implements ValidatorConstraintInterface {
    validate(value: PolicyCommission[], args: ValidationArguments): boolean {
        const policy = args.object as Policy;
        const { commissions } = policy;

        if (!commissions?.length) return false;

        return true;
    }

    defaultMessage(_args: ValidationArguments): string {
        return "At least one commission line is required.";
    }
}

@ValidatorConstraint({
    name: "PremiumValidator",
    async: false,
})
export class PremiumValidator implements ValidatorConstraintInterface {
    validate(premium: number, args: ValidationArguments): boolean {
        if ((args.object as PolicyCommission).commissionOptionCode && typeof premium !== "number") return false;
        return true;
    }

    defaultMessage(_args: ValidationArguments): string {
        return "Premium required";
    }
}

export class PolicyCommission {
    static duplicate(accountingLine: PolicyCommission): PolicyCommission {
        const line = plainToClass(PolicyCommission, accountingLine);

        line.id = null;
        line.createdDate = null;
        line.policyId = null;
        line.policyCreatedDate = null;
        line.policy = null;

        return line;
    }

    static cancel(accountingLine: PolicyCommission): PolicyCommission {
        const line = PolicyCommission.duplicate(accountingLine);
        line.premium = 0;
        return line;
    }

    static reinstate(accountingLine: PolicyCommission): PolicyCommission {
        const line = PolicyCommission.duplicate(accountingLine);
        line.premium = 0;
        return line;
    }

    static compare({ base, compare }: { base?: PolicyCommission; compare?: PolicyCommission }) {
        const comparison = new Comparison(PolicyCommission, base, compare) as PolicyCommission.IComparisonReturn;
        comparison.setField("commissionOptionCode", compare?.commissionOptionCode || base?.commissionOptionCode);
        comparison.setField("commissionOption", compare?.commissionOption || base?.commissionOption);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add ${compare.commissionOption.description} commission`,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete ${base.commissionOption.description} commission`,
                subComparison: comparison as Comparison<unknown>,
            });
        }

        [
            {
                key: "premium",
                label: "Premium",
                transform: (v) => NumberFormatter.Currency.format(v),
            },
            {
                key: "effectiveDate",
                label: "Effective Date",
                transform: (v) => (!v ? "n/a" : new Date(v).toISOString().substring(0, 10)),
                evaluate: (baseValue, compareValue) => baseValue.valueOf() !== compareValue.valueOf(),
            },
        ].forEach(({ key, label, transform, evaluate }) => {
            if (evaluate?.(base[key], compare[key]) ?? base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Update ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    priority: null,
                    fieldName: key as keyof PolicyCommission,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        return comparison;
    }

    id: string;
    createdDate: Date;
    effectiveDate: Date;

    policyId: string;
    policyCreatedDate: Date;
    policy?: Policy;

    commissionOptionCode: string;
    commissionOption: CommissionOption;

    sortOrder: number;
    @Validate(PremiumValidator, {
        groups: [PolicyEnums.ValidationGroups.COMMISSION],
    })
    premium: number;
    billedPremium: number;
    writtenPremium: number;
    fullTermPremium: number;
    includePremium: boolean;
    isCorrected: boolean;
    isPosted: boolean;
    isSuspended: boolean;
    isReconciled: boolean;
    estimatedRevenue: boolean;

    description: string;
    metadata?: Record<string, unknown>;
}

export declare namespace PolicyCommission {
    export interface IComparisonReturn extends Comparison<PolicyCommission> {
        commissionOptionCode: string;
        commissionOption?: CommissionOption;
    }
}

export interface TotalTransactionPremium {
    totalTransactionPremium: number | null;
}
