import { PrimaryButton } from "@deathstar/ui";
import { Link } from "react-router-dom";
import src from "../../assets/images/not-found.svg";
import BaseErrorScreen from "./BaseErrorScreen";

export default function AccountNotFound() {
    return (
        <BaseErrorScreen
            header
            title="Lose your way?"
            description="That account does not exist or you do not have permission to access it"
            primaryAction={
                <Link to="/sol" className="block w-max">
                    <PrimaryButton>Point me back home</PrimaryButton>
                </Link>
            }
            image={<img src={src} alt="Not found" className="hidden w-1/2 lg:block" />}
        />
    );
}
