import { Tractor, Trailer } from "@deathstar/types/northstar";
import { DataTable, fuzzyFilter, selectColumn } from "@deathstar/ui";
import {
    ColumnDef,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";

export function EquipmentSelector({
    available,
    selected,
    setSelected,
}: {
    available: Tractor[] | Trailer[];
    selected: number[];
    setSelected: (selected: number[]) => void;
}) {
    const rowSelection = Object.fromEntries(selected.map((id) => [id, true]));

    const table = useReactTable({
        data: available,
        columns: useMemo(
            () =>
                [
                    selectColumn,
                    createColumnHelper<{ id: number; year: number }>().accessor((row) => row.year || "", {
                        id: "year",
                        header: "Year",
                    }),
                    createColumnHelper<{ id: number; make: string }>().accessor((row) => row.make || "", {
                        id: "make",
                        header: "Make",
                    }),
                    createColumnHelper<{ id: number; vin: string }>().accessor((row) => row.vin || "", {
                        id: "vin",
                        header: "VIN",
                    }),
                ] as ColumnDef<Tractor>[],
            []
        ),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getRowId: (row) => String(row.id),
        onRowSelectionChange: (updater) => {
            if (typeof updater === "function") {
                const newState = updater(rowSelection);
                setSelected(
                    Object.keys(newState)
                        .filter((id) => newState[id] === true)
                        .map((id) => Number(id))
                );
            }
        },
        state: {
            rowSelection,
        },
        globalFilterFn: fuzzyFilter,
    });

    return (
        <DataTable
            table={table}
            className="w-full"
            onRowClick={(row) => {
                if (selected.includes(row.id)) {
                    setSelected(selected.filter((id) => id !== row.id));
                } else {
                    setSelected([...selected, row.id]);
                }
            }}
        />
    );
}
