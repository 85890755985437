import { SecondaryButton } from "@deathstar/ui";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import Loader from "../../components/loader/Loader";

export default function HelpCenterCard() {
    const accountId = useAccountId();

    const { data, isError, isLoading } = api.zendesk.useArticles(accountId!);

    return (
        <div className="space-y-3">
            <p className="text-lg font-medium">Looking for something?</p>
            <p className="max-w-prose text-sm font-light">
                Search our extensive tutorials and help articles so you get the most out of Waypoint.
            </p>

            <div className="ml-8 text-sm">
                <ul className="space-y-2">
                    {isError ? (
                        <>
                            <li>
                                <a
                                    href="https://help.navigatortruckinsurance.com/hc/en-us/articles/16127813084443-How-do-I-view-or-print-my-policy-or-Dec-Page-"
                                    className="block text-waypoint-blue hover:text-waypoint-blue-dark"
                                >
                                    How do I view my dec page?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://help.navigatortruckinsurance.com/hc/en-us/articles/17644162041371-How-do-I-invite-a-coworker-to-Waypoint-"
                                    className="block text-waypoint-blue hover:text-waypoint-blue-dark"
                                >
                                    How do I invite a coworker to my account?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://help.navigatortruckinsurance.com/hc/en-us/articles/16126725800859-How-do-I-send-a-certificate-to-a-holder-"
                                    className="block text-waypoint-blue hover:text-waypoint-blue-dark"
                                >
                                    How do I print or send a certificate?
                                </a>
                            </li>
                        </>
                    ) : isLoading ? (
                        <Loader />
                    ) : (
                        data?.map((article) => (
                            <li key={article.html_url}>
                                <a
                                    href={article.html_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="block text-waypoint-blue hover:text-waypoint-blue-dark"
                                >
                                    {article.title}
                                </a>
                            </li>
                        ))
                    )}
                </ul>
            </div>
            <a href="https://help.navigatortruckinsurance.com" className="block">
                <SecondaryButton className="!border-waypoint-blue !py-1 !text-sm !text-waypoint-blue">
                    Visit the help center
                    <ChevronRightIcon className="h-4 w-4" />
                </SecondaryButton>
            </a>
        </div>
    );
}
