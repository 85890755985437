import { MenuItem, SelectProps } from "@material-ui/core";
import { Row } from "@tanstack/react-table";
import React from "react";
import { Menu } from "../optionFilterButton/OptionFilterButton";
import { ColumnFilterButton, ColumnFilterButtonProps } from "./ColumnFilterButton";

export interface ColumnOptionFilterButtonProps<RowData = unknown, ColumnData = unknown>
    extends Omit<ColumnFilterButtonProps<RowData, ColumnData>, "Menu"> {
    menuTitle: string;
    SelectProps?: SelectProps;
    emptyValue?: ColumnData;
    children: React.ReactNode;
    multiple?: boolean;
}

export function ColumnOptionFilterButton<RowData = unknown, ColumnData = unknown>({
    SelectProps,
    children,
    emptyValue,
    ...props
}: ColumnOptionFilterButtonProps<RowData, ColumnData>) {
    const renderValueFn = React.useCallback(
        (value: unknown) => {
            if (SelectProps?.renderValue) {
                return SelectProps?.renderValue?.(value);
            }
            if (Array.isArray(value)) {
                if (emptyValue && value.length === 1 && value[0] === "") {
                    return emptyValue.toString();
                }
                return value.map((v) => String(v)).join(" / ");
            }
            if (emptyValue) return String(value || emptyValue);
            return typeof value === "string" ? value : String(value);
        },
        [SelectProps, emptyValue]
    );
    return (
        <ColumnFilterButton
            {...props}
            renderValue={renderValueFn}
            Menu={({ value, onChange }) => (
                <Menu
                    {...props}
                    value={value}
                    onChange={onChange}
                    SelectProps={{
                        renderValue: renderValueFn,
                    }}
                >
                    {emptyValue && <MenuItem value={undefined}>{String(emptyValue)}</MenuItem>}
                    {children}
                </Menu>
            )}
        />
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterFn(row: Row<any>, columnId: string, filterValue: any): boolean {
    const isArray = Array.isArray(filterValue);
    if (!filterValue || (isArray && !filterValue.length)) {
        return true;
    } else if (isArray) {
        return filterValue.includes(row.getValue(columnId));
    }
    return true;
}

ColumnOptionFilterButton.filterFn = filterFn;

export interface ColumnOptionFilterButton {
    filterFn: typeof filterFn;
}
