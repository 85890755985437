import React, { forwardRef, useState } from "react";
import { classNames } from "../classNames/classNames";
import { useForwardedRef } from "../hooks/useForwardedRef";
import styles from "./inputField.module.css";

export interface InputFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: React.ReactNode;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    classes?: Partial<
        Record<
            "root" | "label" | "input" | "inputContainer" | "inputContainerFocused" | "inputContainerDisabled" | "helperText" | "adornment",
            string
        >
    >; // uses twin.macro for styling
    helperText?: React.ReactNode;
    error?: boolean;
    rootProps?: Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "className">;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
    ({ label, startAdornment, endAdornment, classes, helperText, error, onFocus, onBlur, className, rootProps, ...props }, ref) => {
        const innerRef = useForwardedRef(ref);
        const [isFocused, setIsFocused] = useState(false);

        return (
            <div
                {...rootProps}
                onClick={(e) => {
                    if (innerRef.current) {
                        innerRef.current.focus();
                    }
                    if (rootProps?.onClick) {
                        rootProps.onClick(e);
                    }
                }}
                className={classNames(classes?.root, className)}
            >
                <label className={classes?.label}>
                    <span>{label ? label : null}</span>
                    {props?.required ? <span className="text-sm text-red-600"> *</span> : null}
                </label>
                <div
                    className={classNames(
                        styles["input-container"],
                        isFocused ? styles["input-container-focus"] : error ? styles["input-container-error"] : "",
                        classes?.inputContainer,
                        isFocused && classes?.inputContainerFocused,
                        props.disabled && classes?.inputContainerDisabled
                    )}
                >
                    {typeof startAdornment === "string" ? (
                        <span className={`${styles["adornment"]} ${classes?.adornment || ""}`}>{startAdornment}</span>
                    ) : (
                        startAdornment
                    )}
                    <input
                        className={classNames(styles["input"], classes?.input)}
                        autoComplete="off"
                        // ignore LastPass autocomplete
                        data-lpignore="true"
                        ref={innerRef}
                        onFocus={(e) => {
                            setIsFocused(true);
                            if (onFocus) {
                                onFocus(e);
                            }
                        }}
                        onBlur={(e) => {
                            setIsFocused(false);
                            if (onBlur) {
                                onBlur(e);
                            }
                        }}
                        {...props}
                    />

                    {typeof endAdornment === "string" ? (
                        <span className={classNames(styles["adornment"], classes?.adornment)}>{endAdornment}</span>
                    ) : (
                        endAdornment
                    )}
                </div>
                {helperText ? <span className={classNames(styles["helper-text"], classes?.helperText)}>{helperText}</span> : null}
            </div>
        );
    }
);
