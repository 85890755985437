import { plainToClass, Type } from "class-transformer";
import { NumberFormatter } from "../util";
import { Attribute } from "./Attribute";
import { AttributeOption } from "./AttributeOption";
import { Comparison } from "./Comparison/Comparison";
import { CoverageCompositeRating } from "./CoverageCompositeRating";
import { CoverageLimit } from "./CoverageLimit";
import { CoverageLimitOption } from "./CoverageLimitOption";
import { CoverageLinkedBusinessAuto } from "./CoverageLinkedBusinessAuto";
import { CoverageLinkedDriver } from "./CoverageLinkedDriver";
import { CoverageLinkedTool } from "./CoverageLinkedTool";
import { CoverageLinkedTractor } from "./CoverageLinkedTractor";
import { CoverageLinkedTrailer } from "./CoverageLinkedTrailer";
import { CoverageModification } from "./CoverageModification";
import { CoverageOption } from "./CoverageOption";
import { CoverageRateClassification } from "./CoverageRateClassification";
import { CoverageRateOwner } from "./CoverageRateOwner";
import { CoverageScheduleOfHazard } from "./CoverageScheduleOfHazard";
import { CoverageUnderlyingCoverage } from "./CoverageUnderlyingCoverage";
import { Policy } from "./Policy";

namespace CoverageEnums {
    export enum Basis {
        MILEAGE = "Mileage",
        VALUES = "Values",
        UNITS = "Units",
        REVENUE = "Revenue",
        PAYROLL = "Payroll",
        FLAT = "Flat",
        NONE = "None",
        OTHER = "Other",
    }

    export enum Frequency {
        MONTHLY = "Monthly",
        QUARTERLY = "Quarterly",
        YEARLY = "Yearly",
    }
}

class _Summary<T extends Coverage.ICanGenerateAbbreviation> {
    #coverage: T;
    #policy: Partial<Pick<Policy, "description">>;
    readonly id: CoverageOption.Id;
    readonly abbreviation: CoverageOption.Abbreviation;
    readonly name: CoverageOption.Name;
    readonly sortOrder: number;
    constructor(coverage: T, policy?: Partial<Pick<Policy, "description">>) {
        this.#coverage = coverage;
        this.#policy = policy;

        if (this.#coverage.limits) {
            this.#coverage.limits = this.#coverage.limits.filter((l) => typeof l.coverageLimitOptionId === "number");
        }

        const coverageOptionMetadata = CoverageOption.getMetadata(coverage.coverageOptionId);

        this.id = coverageOptionMetadata.id;
        this.abbreviation = Coverage.getAbbreviation({ coverage: this.#coverage, policyDescription: this.#policy?.description });
        this.name = coverageOptionMetadata.name;
        this.sortOrder = coverageOptionMetadata.defaultSortOrder;
    }
}

class _Summaries<T extends Coverage.ICanGenerateAbbreviation> {
    #coverages: T[];
    #policy: Partial<Pick<Policy, "description">>;
    readonly summaries: _Summary<T>[];
    #valid = false;
    constructor(coverages?: T[], policy?: Partial<Pick<Policy, "description">>) {
        this.#coverages = (coverages || []).filter((c) => typeof c.coverageOptionId === "number");
        this.#policy = policy;
        this.#valid = this.#coverages.length > 0;

        this.summaries = this.#coverages.filter((c) => typeof c.coverageOptionId === "number").map((c) => new _Summary<T>(c, policy));
    }

    sort(): this {
        this.summaries.sort((a, b) => {
            if (a.sortOrder < b.sortOrder) return -1;
            if (a.sortOrder > b.sortOrder) return 1;
            return 0;
        });
        return this;
    }

    getDescription(): string {
        if (!this.#valid) return this.#policy.description || "";

        return this.getAbbreviations().join(", ");
    }

    getAbbreviations(): string[] {
        if (!this.#valid) return [];
        this.sort();
        return Array.from(new Set(this.summaries.map((m) => m.abbreviation)));
    }
}

export class Coverage implements Coverage.IHasCoverageOptionId, Comparison.ICanCompare<Coverage> {
    static Basis = CoverageEnums.Basis;
    static Frequency = CoverageEnums.Frequency;
    static Summary = _Summary;
    static Summaries = _Summaries;

    #initialized = false;
    static init(coverage: Coverage): Coverage {
        if (coverage.#initialized) return coverage;

        const e = plainToClass(Coverage, coverage);
        e.linkedTractors = e.linkedTractors.map((linkedTractor) => CoverageLinkedTractor.init({ coverage, linkedTractor }));
        e.linkedTrailers = e.linkedTrailers.map((linkedTrailer) => CoverageLinkedTrailer.init({ coverage, linkedTrailer }));
        e.linkedBusinessAutos = e.linkedBusinessAutos.map((linkedBusAuto) => CoverageLinkedBusinessAuto.init({ coverage, linkedBusAuto }));
        e.linkedTools = e.linkedTools.map((linkedTool) => CoverageLinkedTool.init({ coverage, linkedTool }));
        e.linkedDrivers = e.linkedDrivers.map((linkedDriver) => CoverageLinkedDriver.init({ coverage, linkedDriver }));
        e.limits = e.limits.filter((l) => typeof l.coverageLimitOptionId === "number").map((limit) => CoverageLimit.new(limit));

        e.#initialized = true;

        return e;
    }

    static getMetadataFromAbbreviation(abbreviation: CoverageOption.Abbreviation): CoverageOption.IMetadataWithAbbreviation {
        return CoverageOption.getMetadataFromAbbreviation(abbreviation);
    }

    static isComposite(policy: Policy, coverage: Coverage): boolean {
        return Policy.isComposite(policy) && (coverage.basis !== Coverage.Basis.NONE || coverage.compositeRatings?.length > 0);
    }

    static getAbbreviation({
        coverage,
        policyDescription,
        options,
    }: {
        coverage: Coverage.ICanGenerateAbbreviation;
        policyDescription?: Policy["description"];
        options?: {
            includeBondNameOnly: true;
        };
    }): CoverageOption.Abbreviation | null {
        if (!coverage) return null;

        const coverageOptionMetadata = CoverageOption.getMetadata(coverage.coverageOptionId);
        if (!coverageOptionMetadata) {
            if (policyDescription) return Coverage.#getAbbreviationFromDescription(coverage, policyDescription);
            return null;
        }

        const hasRelated = {
            businessAutos: (coverage.linkedBusinessAutos?.length || 0) > 0,
            tractors: (coverage.linkedTractors?.length || 0) > 0,
            trailers: (coverage.linkedTrailers?.length || 0) > 0,
        };
        const hasLinkedItems = Boolean(coverage.linkedBusinessAutos && coverage.linkedTractors && coverage.linkedTrailers);
        if (coverage.underlyingCoverages && coverage.coverageOptionId === CoverageOption.Id.EXCESS_LIABILITY) {
            const underlyingCoverages = coverage.underlyingCoverages.map((uc) => uc.description) || [];
            if (underlyingCoverages.includes(CoverageUnderlyingCoverage.Description.AUTO_LIABILITY)) {
                return "XSAL";
            } else if (underlyingCoverages.includes(CoverageUnderlyingCoverage.Description.GENERAL_LIABILITY)) {
                return "XSGL";
            }

            return coverageOptionMetadata.defaultAbbreviation;
        } else if (
            coverage.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE &&
            coverage.limits?.length === 1 &&
            coverage.limits[0].coverageLimitOptionId === CoverageLimitOption.Id.TRAILER_INTERCHANGE
        ) {
            return "TI";
        } else if (hasLinkedItems && coverage.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE) {
            if (!hasRelated.businessAutos && !hasRelated.tractors && !hasRelated.trailers) {
                // When Physical Damage has no equipment related, change product abbreviation to "HAPD"
                return "HAPD";
            } else if (hasRelated.businessAutos && !hasRelated.tractors && !hasRelated.trailers) {
                // When Physical Damage has only Business Autos related, change product abbreviation to "BAPD"
                return "BAPD";
            }
            return coverageOptionMetadata.defaultAbbreviation;
        } else if (
            hasLinkedItems &&
            coverage.coverageOptionId === CoverageOption.Id.TRUCKERS_AUTO_LIABILITY &&
            hasRelated.businessAutos &&
            !hasRelated.tractors &&
            !hasRelated.trailers
        ) {
            // When Auto Liability has only Business Autos related, change product abbreviation to "BAL"
            return "BAL";
        } else if (coverage.coverageOptionId === CoverageOption.Id.BONDS) {
            const bondNameAttribute = coverage?.attributes?.find(
                (bondNameAttribute) => bondNameAttribute.attributeOptionId === AttributeOption.Id.BOND_NAME
            );
            if (!bondNameAttribute) {
                return "BOND";
            }
            if (options?.includeBondNameOnly) {
                return bondNameAttribute.valueText as CoverageOption.Abbreviation;
            }
            return `BOND (${bondNameAttribute.valueText})`;
        } else {
            if (policyDescription) return Coverage.#getAbbreviationFromDescription(coverage, policyDescription);

            return coverageOptionMetadata.defaultAbbreviation;
        }
    }

    static #getAbbreviationFromDescription(
        coverage: Coverage.IHasCoverageOptionId,
        description: string
    ): CoverageOption.Abbreviation | null {
        if (!coverage || !description) return null;
        const coverageOptionMetadata = CoverageOption.getMetadata(coverage.coverageOptionId);
        if (!coverageOptionMetadata) return null;

        const parts = new Set(description.split(",").map((p) => p.trim()) as CoverageOption.Abbreviation[]);
        if (coverage.coverageOptionId === CoverageOption.Id.EXCESS_LIABILITY) {
            if (parts.has("XSAL")) {
                return "XSAL";
            } else if (parts.has("XSGL")) {
                return "XSGL";
            } else {
                return "XS";
            }
        } else if (coverage.coverageOptionId === CoverageOption.Id.TRUCKERS_PHYSICAL_DAMAGE) {
            if (parts.has("HAPD")) {
                return "HAPD";
            } else if (parts.has("BAPD")) {
                return "BAPD";
            } else {
                return "APD";
            }
        } else if (coverage.coverageOptionId === CoverageOption.Id.TRUCKERS_AUTO_LIABILITY && parts.has("BAL")) {
            return "BAL";
        }
        return CoverageOption.getMetadata(coverage.coverageOptionId).defaultAbbreviation;
    }

    static duplicate(coverage: Coverage): Coverage {
        const c = plainToClass(Coverage, {
            ...coverage,
            attributes: coverage.attributes || [],
            limits: coverage.limits || [],
            modifications: coverage.modifications || [],
            underlyingCoverages: coverage.underlyingCoverages || [],
            scheduleOfHazards: coverage.scheduleOfHazards || [],
            rates: coverage.rates || {
                owners: [],
                classifications: [],
            },
        });

        c.id = null;
        c.createdDate = null;
        c.policyId = null;
        c.policyCreatedDate = null;
        c.policy = null;
        c.attributes = c.attributes.map((a) => Attribute.duplicate(a));
        c.limits = c.limits.map((l) => CoverageLimit.duplicate(l));
        c.modifications = c.modifications.map((m) => CoverageModification.duplicate(m));
        c.rates = {
            owners: c.rates.owners.map((o) => CoverageRateOwner.duplicate(o)),
            classifications: c.rates.classifications.map((c) => CoverageRateClassification.duplicate(c)),
        };
        c.underlyingCoverages = c.underlyingCoverages.map((u) => CoverageUnderlyingCoverage.duplicate(u));
        c.scheduleOfHazards = c.scheduleOfHazards.map((s) => CoverageScheduleOfHazard.duplicate(s));

        return c;
    }

    static createTemplateFrom(coverage: Coverage): Coverage {
        const c = Coverage.duplicate(coverage);

        c.basis = null;
        c.rate = null;
        c.frequency = null;
        c.premium = null;
        c.compositeRatings = coverage.compositeRatings?.map((r) => CoverageCompositeRating.createTemplateFrom(r));
        c.linkedTractors = [];
        c.linkedDrivers = [];
        c.linkedTrailers = [];
        c.linkedBusinessAutos = [];
        c.linkedTools = [];
        c.scheduleOfHazards = [];
        c.underlyingCoverages = [];
        c.rates = {
            owners: [],
            classifications: coverage.rates.classifications.map((c) => CoverageRateClassification.createTemplateFrom(c)),
        };
        c.limits = coverage.limits?.filter((l) => {
            if (l.coverageLimitOptionId === CoverageLimitOption.Id.PROPERTY) return null;
            return l;
        });

        return c;
    }

    static compare(
        { base, compare }: { base?: Coverage; compare?: Coverage },
        options?: { separateEquipment?: boolean }
    ): Coverage.IComparisonReturn {
        const comparison = new Comparison(Coverage, base, compare) as Coverage.IComparisonReturn;
        comparison.setField("coverageOptionId", compare?.coverageOptionId || base?.coverageOptionId);
        comparison.setField("coverageOption", compare?.coverageOption || base?.coverageOption);

        const separateEquipment = options?.separateEquipment ?? true;

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add ${compare.coverageOption.name} coverage`,
                subComparison: comparison as Comparison<unknown>,
                label: "Coverage",
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete ${base.coverageOption.name} coverage`,
                subComparison: comparison as Comparison<unknown>,
                label: "Coverage",
            });
        }

        [
            {
                key: "premium",
                label: "Premium",
                transform: (v) => (v ? NumberFormatter.Currency.format(v) : "n/a"),
            },
            {
                key: "basis",
                label: "Basis",
                transform: (v) => (v ? v : "n/a"),
            },
            {
                key: "rate",
                label: "Rate",
                transform: (v) => (v ? NumberFormatter.Percent.format(v) : "n/a"),
            },
            {
                key: "frequency",
                label: "Frequency",
                transform: (v) => (v ? v : "n/a"),
            },
        ].forEach(({ key, label, transform }) => {
            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Update ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    priority: null,
                    fieldName: key as keyof Coverage,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        if (!separateEquipment) {
            Comparison.compareArrayFields<Coverage, "linkedTractors">({
                comparison,
                baseEntity: base,
                compareEntity: compare,
                fieldName: "linkedTractors",
                fieldKey: "tractorId",
                itemDescriptionName: "Tractor",
                getItemDescription(entity) {
                    return entity.toString();
                },
            });

            Comparison.compareArrayFields<Coverage, "linkedTrailers">({
                comparison,
                baseEntity: base,
                compareEntity: compare,
                fieldName: "linkedTrailers",
                fieldKey: "trailerId",
                itemDescriptionName: "Trailer",
                getItemDescription(entity) {
                    return entity.toString();
                },
            });

            Comparison.compareArrayFields<Coverage, "linkedBusinessAutos">({
                comparison,
                baseEntity: base,
                compareEntity: compare,
                fieldName: "linkedBusinessAutos",
                fieldKey: "businessAutoId",
                itemDescriptionName: "Bus Auto",
                getItemDescription(entity) {
                    return entity.toString();
                },
            });

            Comparison.compareArrayFields<Coverage, "linkedTools">({
                comparison,
                baseEntity: base,
                compareEntity: compare,
                fieldName: "linkedTools",
                fieldKey: "toolId",
                itemDescriptionName: "Tool",
                getItemDescription(entity) {
                    return entity.toString();
                },
            });

            Comparison.compareArrayFields<Coverage, "linkedDrivers">({
                comparison,
                baseEntity: base,
                compareEntity: compare,
                fieldName: "linkedDrivers",
                fieldKey: "driverId",
                itemDescriptionName: "Driver",
                getItemDescription(entity) {
                    return entity.toString();
                },
            });
        }

        Comparison.compareArrayFields<Coverage, "attributes">({
            comparison,
            baseEntity: plainToClass(Coverage, {
                coverageOption: base.coverageOption,
                coverageOptionId: base.coverageOptionId,
                attributes: Attribute.filterOutEquipmentAndDriverAttributes(base.attributes),
            }),
            compareEntity: plainToClass(Coverage, {
                coverageOption: compare.coverageOption,
                coverageOptionId: compare.coverageOptionId,
                attributes: Attribute.filterOutEquipmentAndDriverAttributes(compare.attributes),
            }),
            fieldName: "attributes",
            fieldKey: "attributeOptionId",
            itemDescriptionName: "Attribute",
            getItemDescription(entity) {
                return entity.attributeOption.name;
            },
        });

        Comparison.compareArrayFields<Coverage, "modifications">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "modifications",
            fieldKey: "modificationOptionId",
            itemDescriptionName: "Modification",
            getItemDescription(entity) {
                return entity.modificationOption.name;
            },
        });

        Comparison.compareArrayFields<Coverage, "scheduleOfHazards">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "scheduleOfHazards",
            fieldKey: "propertyId",
            itemDescriptionName: "Location",
            getItemDescription(entity) {
                return entity.property?.street1 || entity.toString();
            },
        });

        Comparison.compareArrayFields<Coverage, "underlyingCoverages">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "underlyingCoverages",
            fieldKey: "description",
            itemDescriptionName: "Underlying Coverage",
            getItemDescription(entity) {
                return entity.description;
            },
        });

        Comparison.compareArrayFields<Coverage, "limits">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "limits",
            fieldKey: "comparisonKey",
            itemDescriptionName: "Limit",
            getItemDescription(entity) {
                return entity.toString();
            },
        });

        return comparison;
    }

    static new(cvg: Partial<Coverage>): Coverage {
        return plainToClass(Coverage, cvg || {});
    }

    id: number;
    createdDate: Date;

    policyId: string;
    policyCreatedDate: Date;
    policy?: Policy;

    sortOrder: number;
    premium?: number;
    basis: CoverageEnums.Basis;
    rate: number;
    frequency: CoverageEnums.Frequency;
    coverageOptionId: CoverageOption.Id;
    @Type(() => CoverageOption)
    coverageOption?: CoverageOption;

    @Type(() => CoverageCompositeRating)
    compositeRatings?: CoverageCompositeRating[];

    @Type(() => Attribute)
    attributes: Attribute[];

    @Type(() => CoverageLimit)
    limits: CoverageLimit[];

    @Type(() => CoverageLinkedBusinessAuto)
    linkedBusinessAutos?: CoverageLinkedBusinessAuto[];

    @Type(() => CoverageLinkedTractor)
    linkedTractors?: CoverageLinkedTractor[];

    @Type(() => CoverageLinkedTrailer)
    linkedTrailers?: CoverageLinkedTrailer[];

    @Type(() => CoverageLinkedDriver)
    linkedDrivers?: CoverageLinkedDriver[];

    @Type(() => CoverageLinkedTool)
    linkedTools?: CoverageLinkedTool[];

    @Type(() => CoverageModification)
    modifications?: CoverageModification[];
    rates?: {
        owners: CoverageRateOwner[];
        classifications: CoverageRateClassification[];
    };

    @Type(() => CoverageUnderlyingCoverage)
    underlyingCoverages?: CoverageUnderlyingCoverage[];

    @Type(() => CoverageScheduleOfHazard)
    scheduleOfHazards?: CoverageScheduleOfHazard[];
    metadata?: Record<string, unknown>;

    compare(comparisonCoverage: Coverage, options?: { isBase?: boolean; separateEquipment?: boolean }): Comparison<Coverage> {
        return Coverage.compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonCoverage,
                },
                {
                    isBase: options?.isBase,
                }
            ),
            {
                separateEquipment: options?.separateEquipment,
            }
        );
    }

    toString(): string {
        const nf = NumberFormatter.Currency;
        const parts: string[] = [];

        if (this.coverageOption?.name) parts.push(this.coverageOption?.name);
        if (this.premium) parts.push(nf.format(this.premium));
        if (this.basis) parts.push(this.basis);
        if (this.rate) parts.push(nf.format(this.rate));
        if (this.frequency) parts.push(this.frequency);

        return parts.join(", ");
    }
}

export declare namespace Coverage {
    export interface IHasCoverageOptionId {
        coverageOptionId: CoverageOption.Id;
    }
    export type Basis = CoverageEnums.Basis;
    export type Frequency = CoverageEnums.Frequency;
    export interface Summary<T extends Coverage.ICanGenerateAbbreviation> extends _Summary<T> {}
    export interface Summaries<T extends Coverage.ICanGenerateAbbreviation> extends _Summaries<T> {}
    export interface IComparisonReturn extends Comparison<Coverage> {
        coverageOptionId: CoverageOption.Id;
        coverageOption?: CoverageOption;
    }

    export interface ICanGenerateAbbreviation extends Coverage.IHasCoverageOptionId {
        linkedBusinessAutos?: unknown[];
        linkedTractors?: unknown[];
        linkedTrailers?: unknown[];
        underlyingCoverages?: Pick<CoverageUnderlyingCoverage, "description">[];
        attributes?: Attribute[];
        limits?: Pick<CoverageLimit, "coverageLimitOptionId">[];
    }
}
