import { Tooltip, classNames } from "@deathstar/ui";
import { Typography } from "@material-ui/core";

import React from "react";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";

export function OutOfServiceRates({ className }: { className?: string }): JSX.Element | null {
    const { motorCarrier } = React.useContext(Context);
    const oosRates = motorCarrier.outOfServiceRates.latest;
    const nationalAverageOosRates = React.useMemo(() => {
        return motorCarrier.getLatestNationalAverageOutOfServiceRate().convertToPercentage();
    }, [motorCarrier]);

    return (
        <div className={classNames("bg-inherit ", className)}>
            <TitleGroup title={<Typography variant="h5">Out of Service Rates</Typography>} />
            <ul className="flex w-fit flex-col py-4">
                <ListItem label="Vehicle" carrierRate={oosRates.vehicle} nationalRate={nationalAverageOosRates.vehicle} />
                <ListItem label="Driver" carrierRate={oosRates.driver} nationalRate={nationalAverageOosRates.driver} />
                <ListItem label="Hazmat" carrierRate={oosRates.hazmat} nationalRate={nationalAverageOosRates.hazmat} />
            </ul>
        </div>
    );
}

function ListItem({ label, carrierRate, nationalRate }: { label: string; carrierRate: number; nationalRate: number }): JSX.Element {
    const isHigh = carrierRate > nationalRate;
    return (
        <li
            className={classNames("grid grid-cols-[1fr_min-content] gap-4 rounded border-2 border-transparent p-1 px-4", {
                "!border-[#86202b] bg-slate-100": isHigh,
            })}
        >
            <p>
                <Tooltip title={`National average is ${nationalRate}%`}>{label}</Tooltip>
            </p>
            <div className="flex gap-2">
                <Typography
                    className={classNames("self-start", {
                        "text-[#86202b]": isHigh,
                    })}
                >
                    {carrierRate}%
                </Typography>
            </div>
        </li>
    );
}
