/* eslint-disable @typescript-eslint/no-explicit-any */
import { Unpacked } from "../../util";
import { CoverageLimit } from "../CoverageLimit";
import { CoverageLinkedBusinessAuto } from "../CoverageLinkedBusinessAuto";
import { CoverageLinkedDriver } from "../CoverageLinkedDriver";
import { CoverageLinkedTool } from "../CoverageLinkedTool";
import { CoverageLinkedTractor } from "../CoverageLinkedTractor";
import { CoverageLinkedTrailer } from "../CoverageLinkedTrailer";
import type { CoverageOption } from "../CoverageOption";
import type { PolicyLayer } from "../PolicyLayer";

export class Comparison<T> {
    static isCoverageLinkedEquipmentEntity(entity: any): boolean {
        return [CoverageLinkedBusinessAuto, CoverageLinkedDriver, CoverageLinkedTool, CoverageLinkedTractor, CoverageLinkedTrailer].some(
            (cls) => entity instanceof cls
        );
    }

    static getBaseAndComparisonObjects<T>(
        { initiatorEntity, comparisonEntity }: { initiatorEntity: T; comparisonEntity: T },
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): { base: T; compare: T } {
        const isBase = options?.isBase ?? true;
        const base = isBase ? initiatorEntity : comparisonEntity;
        const compare = isBase ? comparisonEntity : initiatorEntity;
        return { base, compare };
    }

    static compareArrayFields<T, FieldName extends keyof T = any>({
        comparison,
        itemDescriptionName,
        baseEntity,
        compareEntity,
        fieldName,
        fieldKey,
        getItemDescription,
    }: {
        comparison: Comparison<T>;
        itemDescriptionName: string;
        baseEntity: T;
        compareEntity: T;
        fieldName: keyof T;
        fieldKey: keyof Unpacked<T[FieldName]>;
        getItemDescription(entity: Unpacked<T[FieldName]>): string;
    }): void {
        if (!compareEntity[fieldName]) {
            (compareEntity[fieldName] as any[]) = [];
        }
        if (!baseEntity[fieldName]) {
            (baseEntity[fieldName] as any[]) = [];
        }

        const deletedItems: any[] = [];
        const addedItems: any[] = [];

        const linkedItemIds = new Set<number>();

        const baseIds = new Set((baseEntity[fieldName] as any[]).map((t) => t[fieldKey]));
        (compareEntity[fieldName] as any[]).forEach((e) => {
            //   if the base coverage has the same item
            if (baseIds.has(e[fieldKey])) {
                linkedItemIds.add(e[fieldKey]);
                const _baseEntity = (baseEntity[fieldName] as any[]).find((t) => t[fieldKey] === e[fieldKey]);

                const subComparison: Comparison<unknown> = _baseEntity?.compare?.(e, null, baseEntity, compareEntity);
                if (!subComparison?.changeList.length) return;

                comparison.addSubComparison({
                    fieldName,
                    subComparison,
                    itemDescriptionName,
                    itemDescription: getItemDescription(e),
                    entity: e,
                    base: _baseEntity,
                    compare: e,
                });

                return;
            }

            addedItems.push(e);
        });

        (baseEntity[fieldName] as any[]).forEach((e) => {
            // If the item has already been added, skip it
            if (linkedItemIds.has(e[fieldKey])) {
                // console.log(`2 Compare ${itemDescriptionName}`, e);
                return;
            }

            deletedItems.push(e);
        });

        (baseEntity[fieldName] as any[]).forEach((t) => {
            if (linkedItemIds.has(t[fieldKey])) return;
        });

        deletedItems.forEach((e) => {
            comparison.addDiff({
                description: `Remove ${itemDescriptionName}: ${getItemDescription(e)}`,
                label: itemDescriptionName,
                type: "remove",
                priority: null,
                fieldName: fieldName,
                isArrayField: true,
                subComparison: e.compare?.(null, null, baseEntity, compareEntity),
                value: {
                    from: e,
                    to: null,
                    base: e,
                    compare: null,
                },
            });
        });
        addedItems.forEach((e) => {
            comparison.addDiff({
                description: `Add ${itemDescriptionName}: ${getItemDescription(e)}`,
                label: itemDescriptionName,
                type: "add",
                priority: null,
                fieldName: fieldName,
                isArrayField: true,
                subComparison: e.constructor.compare?.({
                    base: null,
                    compare: e,
                }),
                value: {
                    from: null,
                    to: e,
                    base: null,
                    compare: e,
                },
            });
        });
    }

    additions: T | null = null;
    removals: T | null = null;
    changes: {
        from: T | null;
        to: T | null;
    } = {
        from: null,
        to: null,
    };
    changeList: Comparison.Change<T>[] = [];
    #ctor: { new (): T };
    constructor(ctor: { new (): T }, readonly base?: T, readonly compare?: T) {
        this.#ctor = ctor;
    }

    get isMatch(): boolean {
        return !this.hasAdditions && !this.hasRemovals && !this.hasChanges && !this.isNew && !this.isDeleted;
    }
    get hasAdditions(): boolean {
        return this.additions instanceof this.#ctor;
    }
    get hasRemovals(): boolean {
        return this.removals instanceof this.#ctor;
    }
    get hasChanges(): boolean {
        return this.changes.from instanceof this.#ctor && this.changes.to instanceof this.#ctor;
    }
    get isNew(): boolean {
        return this.changes.to instanceof this.#ctor && this.changes.from === null;
    }
    get isDeleted(): boolean {
        return this.changes.from instanceof this.#ctor && this.changes.to === null;
    }

    get changeDescriptions(): string[] {
        return this.changeList.map((c) => c.description);
    }

    #initiate(changeType: Comparison.ChangeType): void {
        if (changeType === "change") {
            if (!this.changes.from) {
                this.changes.from = new this.#ctor();
            }
            if (!this.changes.to) {
                this.changes.to = new this.#ctor();
            }
        } else if (changeType === "add" && !this.additions) {
            this.additions = new this.#ctor();
        } else if (changeType === "remove" && !this.removals) {
            this.removals = new this.#ctor();
        }
    }

    #addDiff(change: Pick<Comparison.Change<T>, "type" | "fieldName" | "isArrayField" | "value">): void {
        if (change.type === "add") {
            if (change.isArrayField) {
                if (Array.isArray(this.additions[change.fieldName])) {
                    (this.additions[change.fieldName] as any[]).push(change.value.to);
                } else {
                    (this.additions[change.fieldName] as any) = [change.value.to];
                }
            } else {
                this.additions[change.fieldName] = change.value.to;
                if ("id" in (this.additions as any) && this.additions.constructor.name === change.value.compare?.constructor.name) {
                    (this.additions as any).id = (change.value.compare as any).id;
                }
            }
        } else if (change.type === "remove") {
            if (change.isArrayField) {
                if (Array.isArray(this.removals[change.fieldName])) {
                    (this.removals[change.fieldName] as any[]).push(change.value.from);
                } else {
                    (this.removals[change.fieldName] as any) = [change.value.from];
                }
            } else {
                this.removals[change.fieldName] = change.value.from;
                if ("id" in (this.removals as any) && this.removals.constructor.name === change.value.base?.constructor.name) {
                    (this.removals as any).id = (change.value.base as any).id;
                }
            }
        } else if (change.type === "change") {
            if (change.isArrayField) {
                if (Array.isArray(this.changes.to[change.fieldName])) {
                    (this.changes.to[change.fieldName] as any[]).push(change.value.to);
                    (this.changes.from[change.fieldName] as any[]).push(change.value.from);
                } else {
                    (this.changes.to[change.fieldName] as any) = [change.value.to];
                    (this.changes.from[change.fieldName] as any) = [change.value.from];
                }
            } else {
                this.changes.to[change.fieldName] = change.value.to;
                if ("id" in (this.changes.to as any) && this.changes.to?.constructor.name === change.value.compare?.constructor.name) {
                    (this.changes.to as any).id = (change.value.compare as any).id;
                }
                this.changes.from[change.fieldName] = change.value.from;
                if ("id" in (this.changes.from as any) && this.changes.from?.constructor.name === change.value.base?.constructor.name) {
                    (this.changes.from as any).id = (change.value.base as any).id;
                }
            }
        }
    }

    addSubComparison({
        subComparison,
        itemDescriptionName,
        itemDescription,
        entity,
        fieldName,
        base,
        compare,
    }: {
        subComparison: Comparison<unknown>;
        itemDescriptionName: string;
        itemDescription: string;
        entity: any;
        fieldName: any;
        base: T;
        compare: T;
    }): void {
        if (subComparison.additions) {
            const toEntity =
                entity.constructor.new?.(
                    {
                        ...(subComparison.additions as any),
                        id: entity.id,
                    },
                    { extractOptionFrom: entity }
                ) || subComparison.additions;
            subComparison.changeList
                .filter((x) => x.type === "add")
                .forEach((change) => {
                    this.changeList.push(
                        new Comparison.Change({
                            ...change,
                            description: `Update ${itemDescriptionName}: ${itemDescription} - ${change.description}`,
                            fieldName,
                            isArrayField: true,
                            subComparison,
                            value: {
                                from: null,
                                to: toEntity,
                                base,
                                compare,
                            },
                        })
                    );
                });
            this.#initiate("add");
            this.#addDiff({
                type: "add",
                fieldName,
                isArrayField: true,
                value: {
                    from: null,
                    to: toEntity,
                    base,
                    compare,
                },
            });
        }

        if (subComparison.removals) {
            const fromEntity =
                entity.constructor.new?.(
                    {
                        ...(subComparison.removals as any),
                        id: entity.id,
                    },
                    { extractOptionFrom: entity }
                ) || subComparison.removals;
            subComparison.changeList
                .filter((x) => x.type === "remove")
                .forEach((change) => {
                    this.changeList.push(
                        new Comparison.Change({
                            ...change,
                            description: `Update ${itemDescriptionName}: ${itemDescription} - ${change.description}`,
                            fieldName,
                            isArrayField: true,
                            subComparison,
                            value: {
                                from: fromEntity,
                                to: null,
                                base,
                                compare,
                            },
                        })
                    );
                });
            this.#initiate("remove");
            this.#addDiff({
                type: "remove",
                fieldName,
                isArrayField: true,
                value: {
                    from: fromEntity,
                    to: null,
                    base,
                    compare,
                },
            });
        }

        if (subComparison.changes.from) {
            const toEntity =
                entity.constructor.new?.(
                    {
                        ...(subComparison.changes.to as any),
                        id: entity.id,
                    },
                    { extractOptionFrom: entity }
                ) || subComparison.changes.to;
            const fromEntity =
                entity.constructor.new?.(
                    {
                        ...(subComparison.changes.from as any),
                        id: entity.id,
                    },
                    { extractOptionFrom: entity }
                ) || subComparison.changes.from;
            subComparison.changeList
                .filter((x) => x.type === "change")
                .forEach((change) => {
                    this.changeList.push(
                        new Comparison.Change({
                            ...change,
                            description: `Update ${itemDescriptionName}: ${itemDescription} - ${change.description}`,
                            fieldName,
                            isArrayField: true,
                            subComparison,
                            value: {
                                from: fromEntity,
                                to: toEntity,
                                base,
                                compare,
                            },
                        })
                    );
                });
            this.#initiate("change");
            this.#addDiff({
                type: "change",
                fieldName,
                isArrayField: true,
                value: {
                    from: fromEntity,
                    to: toEntity,
                    base,
                    compare,
                },
            });
        }
    }

    addDiff(change: Comparison.IChange<T>): this {
        this.#initiate(change.type);

        this.#addDiff(change);
        this.changeList.push(new Comparison.Change(change));

        return this;
    }

    setNew({
        obj,
        description,
        subComparison,
        label,
    }: {
        obj: T;
        description: string;
        subComparison: Comparison<unknown>;
        label?: string;
    }): this {
        this.changes = {
            from: null,
            to: obj,
        };
        this.changeList.push(
            new Comparison.Change({
                description,
                type: "new",
                label: label || null,
                priority: null,
                fieldName: null,
                isArrayField: null,
                subComparison,
                value: {
                    from: null,
                    to: obj,
                    base: null,
                    compare: obj,
                },
            })
        );
        return this;
    }

    setDelete({
        obj,
        description,
        subComparison,
        label,
    }: {
        obj: T;
        description: string;
        subComparison: Comparison<unknown>;
        label?: string;
    }): this {
        this.changes = {
            from: obj,
            to: null,
        };
        this.changeList.push(
            new Comparison.Change({
                description,
                type: "delete",
                label: label || null,
                priority: null,
                fieldName: null,
                isArrayField: null,
                subComparison,
                value: {
                    from: obj,
                    to: null,
                    base: obj,
                    compare: null,
                },
            })
        );
        return this;
    }

    setField<Field extends keyof T>(field: Field, value: T[Field]): void {
        (this as any)[field] = value;
    }

    toString(): string {
        return this.changeList.map((change) => change.description).join(",\n");
    }

    getFieldChanges(fieldName: keyof T): Comparison.Change<T>[] {
        return this.changeList.filter((c) => c.fieldName === fieldName);
    }

    filterFieldChangeList<Key extends keyof T>(fieldName: Key): Comparison.Change<Unpacked<T[Key]>>[] {
        return this.changeList.filter((c) => c.fieldName === fieldName) as Comparison.Change<Unpacked<T[Key]>>[];
    }

    getUniqueChangeList<Key extends keyof T>(fieldName: Key): Comparison.Change<T[Key]>[] {
        return this.changeList
            .filter((change) => change.type === "change" && change.fieldName === fieldName)
            .reduce((acc, change) => {
                if (
                    acc.some((c) => {
                        if (c.value.compare instanceof CoverageLimit && change.value.compare instanceof CoverageLimit) {
                            return c.value.compare.comparisonKey === change.value.compare.comparisonKey;
                        }
                        return false;
                    })
                ) {
                    return acc;
                }
                acc.push(change as any);
                return acc;
            }, [] as Comparison.Change<T[Key]>[]);
    }
}

export namespace Comparison {
    export interface IOptions<T> {
        base: Record<CoverageOption.Id, T> | null;
        compare: Record<CoverageOption.Id, T> | null;
        comparisonLayer: PolicyLayer;
        baseLayer: PolicyLayer;
    }
    export namespace IOptions {
        export interface Entity<T> {
            base: T | null;
            compare: T | null;
            comparisonLayer: PolicyLayer;
            baseLayer: PolicyLayer;
        }
    }

    export interface ICanCompare<T> {
        compare(compare: T, options?: { isBase?: boolean }): Comparison<T>;
    }
    export type ChangeType = "add" | "remove" | "change" | "new" | "delete";
    export type ActionLabel = "add" | "remove" | "update";
    export interface IChange<T = unknown> {
        readonly type: ChangeType;
        readonly description: string;
        readonly actionLabel?: ActionLabel;
        readonly label: string | null;
        readonly priority: number;
        readonly fieldName: keyof T | null;
        readonly isArrayField: boolean;
        readonly subComparison?: Comparison<unknown>;
        readonly value: {
            from: any;
            to: any;
            base: T | null;
            compare: T | null;
        };
    }
    export class Change<T = unknown> {
        readonly actionLabel: ActionLabel;
        readonly type: ChangeType;
        readonly description: string;
        readonly label: string | null;
        readonly priority: number;
        readonly fieldName: keyof T | null;
        readonly isArrayField: boolean;
        readonly subComparison?: Comparison<unknown>;
        readonly value: {
            from: any;
            to: any;
            base: T | null;
            compare: T | null;
        };

        constructor(options: {
            type: ChangeType;
            description: string;
            actionLabel?: ActionLabel;
            label: string | null;
            priority: number;
            fieldName: keyof T | null;
            isArrayField: boolean;
            subComparison?: Comparison<unknown>;
            value: {
                from: any;
                to: any;
                base: T | null;
                compare: T | null;
            };
        }) {
            this.type = options.type;
            this.description = options.description;
            this.label = options.label;
            this.priority = options.priority;
            this.fieldName = options.fieldName;
            this.isArrayField = options.isArrayField;
            this.subComparison = options.subComparison;
            this.value = options.value;

            if (options.actionLabel) {
                this.actionLabel = options.actionLabel;
            } else {
                if (
                    this.type === "add" ||
                    this.type === "new" ||
                    (this.type === "change" && (this.value.from === null || this.value.from === undefined))
                ) {
                    this.actionLabel = "add";
                } else if (
                    this.type === "remove" ||
                    this.type === "delete" ||
                    (this.type === "change" && (this.value.to === null || this.value.to === undefined))
                ) {
                    this.actionLabel = "remove";
                } else {
                    this.actionLabel = "update";
                }
            }
        }
    }
    export interface ISubChange<T = unknown> {
        type: ChangeType;
        descriptions: string[];
        priority: number;
        fieldName: keyof T | null;
        isArrayField: boolean;
        value: {
            from: any;
            to: any;
        };
    }

    export namespace getBaseAndComparisonObjects {
        export interface IOptions {
            isBase?: boolean;
        }
    }
}
