import { DateTime } from "@deathstar/reuse";
import { FMCSA } from "@deathstar/types";
import { classNames } from "@deathstar/ui";
import { Typography } from "@material-ui/core";
import React from "react";
import { StopLight, StopLightOption } from "../Components/StopLight";
import { TitleGroup } from "../Components/TitleGroup";
import { Context } from "../Context";

export function AuditResult({ className }: { className?: string }): JSX.Element | null {
    const { motorCarrier } = React.useContext(Context);

    const { SafetyReviewRating, SafetyReviewType, SafetyReviewDate } = motorCarrier.details;

    const options: StopLightOption[] = React.useMemo(() => {
        return (
            [
                {
                    label: "Unsatisfactory",
                    red: true,
                    selected: SafetyReviewRating === FMCSA.SafetyReviewRating.UNSATISFACTORY,
                },
                {
                    label: "Conditional",
                    yellow: true,
                    selected: SafetyReviewRating === FMCSA.SafetyReviewRating.CONDITIONAL,
                },
                {
                    label: "Satisfactory",
                    green: true,
                    selected: SafetyReviewRating === FMCSA.SafetyReviewRating.SATISFACTORY,
                },
                {
                    label: "Not Rated",
                    gray: true,
                    selected: !SafetyReviewRating || SafetyReviewRating === FMCSA.SafetyReviewRating.NOT_RATED,
                },
            ] as StopLightOption[]
        ).map((o) => ({
            ...o,
            tooltip:
                SafetyReviewType && SafetyReviewDate
                    ? `${SafetyReviewType}, ${DateTime.fromDate(SafetyReviewDate).format("mm/dd/YYYY")}`
                    : null,
        })) as StopLightOption[];
    }, [SafetyReviewRating, SafetyReviewType, SafetyReviewDate]);

    return (
        <div className={classNames("bg-inherit ", className)}>
            <TitleGroup title={<Typography variant="h5">Audit Result</Typography>} />
            <div className="flex flex-col py-4">
                <StopLight options={options} />
            </div>
        </div>
    );
}
