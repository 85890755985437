export class CoverageLimitOption {
    id: CoverageLimitOption.Id;
    name: CoverageLimitOption.Name;
}

export namespace CoverageLimitOption {
    export enum Id {
        ACCIDENTAL_DEATH = 1,
        ACCIDENTAL_DEATH_DISMEMBERMENT = 2,
        ACCIDENTAL_DENTAL_BENEFIT = 3,
        ACCIDENTAL_DISMEMBERMENT = 4,
        ACCIDENTAL_MEDICAL_EXPENSE = 5,
        ADDITIONAL_PIP = 6,
        AGGREGATE = 7,
        BAILEES = 8,
        BLANKET_BUILDING_AND_CONTENT = 9,
        BLANKET_BUILDING = 10,
        BLANKET_CONTENT = 11,
        BROADENED_POLLUTION = 12,
        BUILDING = 13,
        BUSINESS_INCOME = 14,
        BUSINESS_PERSONAL_PROPERTY = 17,
        CLAIM_EXPENSE = 18,
        CLEANUP = 19,
        COLLISION = 20,
        COMBINED_SINGLE_LIMIT = 21,
        COMBINED_UNINSURED_UNDERINSURED_MOTORIST = 22,
        COMPREHENSIVE = 23,
        COMPUTER_ATTACK_AND_CYBER_EXTORTION = 24,
        COMPUTER_CRIME = 25,
        COMPUTER_FRAUD = 26,
        CONTINUOUS_TOTAL_DISABILITY = 27,
        CONTRACTORS_TOOLS = 28,
        CYBER_LIABILITY_STANDARD = 29,
        DAMAGE_TO_RENTED_PREMISES = 30,
        DATA_BREACH_ANNUAL_AGGREGATE = 31,
        DATA_COMPROMISE_LIABILITY = 32,
        DATA_COMPROMISE_RESPONSE_EXPENSE = 33,
        DEFENSE = 34,
        DISEASE_EACH_EMPLOYEE = 35,
        DISEASE_POLICY = 36,
        EACH_ACCIDENT = 37,
        EACH_OCCURRENCE = 38,
        EDP_HARDWARE = 39,
        EDP_SOFTWARE = 40,
        ELECTRONIC_MEDIA_LIABILITY = 41,
        EMPLOYEE_DISHONESTY = 42,
        EMPLOYEE_TOOLS = 43,
        EMPLOYEE_BENEFIT = 44,
        EQUIPMENT_BREAKDOWN = 45,
        ERISA = 46,
        EXTORTION = 47,
        EXTRA_EXPENSE = 48,
        FORGERY_OR_ALTERATION = 49,
        FUNDS_TRANSFER_FRAUD = 50,
        GARAGE_KEEPERS = 51,
        GARAGE_LIABILITY = 52,
        GENERAL_AGGREGATE = 53,
        GLASS = 54,
        HIRED = 55,
        IDENTITY_RECOVERY_ANNUAL_AGGREGATE = 56,
        IDENTITY_RECOVERY = 57,
        IN_TRANSIT_LIMIT = 58,
        IN_TRANSIT = 59,
        MEDICAL_PAYMENTS = 60,
        MONEY_AND_SECURITY_INSIDE_PREMISES = 61,
        MONEY_AND_SECURITY_OUTSIDE_PREMISES = 62,
        MONEY_ORDERS_AND_COUNTERFEIT_MONEY = 63,
        NETWORK_SECURITY_LIABILITY = 64,
        NON_OWNED = 65,
        ON_PREMISES = 66,
        ORDINANCE_OF_LAW = 67,
        PD_BUY_BACK_MINI_TORT = 68,
        PERSONAL_AND_ADVERTISING_INJURY = 69,
        PERSONAL_ACCOUNTS_PROTECTION = 70,
        PERSONAL_INJURY_PROTECTION = 71,
        PERSONAL_PROPERTY_OF_OTHERS = 72,
        PREMISES_BURGLARY = 73,
        PREMISES_ROBBERY_OUTSIDE = 74,
        PREMISES_THEFT_OUTSIDE = 75,
        PRODUCTS_COMPLETED_OPERATIONS = 76,
        PROPERTY_DAMAGE = 77,
        PROPERTY_PROTECTION = 78,
        ACCOUNTS_RECEIVABLE = 79,
        RENTAL_REIMBURSEMENT_COLLISION = 80,
        RENTAL_REIMBURSEMENT_COMPREHENSIVE = 81,
        RENTAL_VALUE = 82,
        RENTED_LEASED_EQUIPMENT = 83,
        ROBBERY_AND_SAFE_BURGLARY_INSIDE_CUSTODIANS = 84,
        ROBBERY_AND_SAFE_BURGLARY_INSIDE_PREMISES = 85,
        ROBBERY_AND_SAFE_BURGLARY_MONEY_AND_SECURITIES_INSIDE = 86,
        ROBBERY_AND_SAFE_BURGLARY_MONEY_AND_SECURITIES_OUTSIDE = 87,
        ROBBERY_AND_SAFE_BURGLARY_OUTSIDE_PREMISES = 88,
        SCHEDULED_EQUIPMENT = 89,
        SINGLE_CONVEYANCE = 90,
        SPECIFIED_CAUSE_OF_LOSS = 91,
        SPECIFIED_SHIPPER = 92,
        TEMPORARY_TOTAL_DISABILITY = 93,
        THEFT_DISAPPEARANCE_AND_DESTRUCTION_INSIDE_PREMISES = 94,
        THEFT_DISAPPEARANCE_AND_DESTRUCTION_OUTSIDE_PREMISES = 95,
        THEFT = 96,
        TOWING_AND_LABOR = 97,
        TRAILER_INTERCHANGE = 98,
        UNDERINSURED_MOTORIST = 99,
        UNINSURED_MOTORIST = 100,
        UNSCHEDULED_EQUIPMENT = 101,
        UTILITY_SERVICE_TIME_ELEMENT = 102,
        VALUABLE_PAPERS = 103,
        PROPERTY = 104,
        REEFER = 105,
        HIRED_CARGO = 106,
        BODILY_INJURY_PROPERTY_DAMAGE_LIABILITY = 107,
        PREMISES_OPERATIONS_LIABILITY = 108,
        EXCESS_ATTENDANT_CARE = 109,
        ANNUAL_AGGREGATE_LIMIT = 110,
        UNINSURED_MOTORIST_PROPERTY_DAMAGE = 111,
        GARAGE_LEGAL_LIABILITY = 112,
        COMPUTER_HARDWARE = 113,
        COMPUTER_SOFTWARE = 114,
        BUSINESS_INCOME_EXTRA_EXPENSE = 115,
        CLAIMS_MADE = 116,
        UNDERINSURED_MOTORIST_PROPERTY_DAMAGE = 117,
        THIRD_PARTY_COVERAGE = 118,
        EMPLOYEE_THEFT_OF_PROPERTY = 119,
        PRODUCTS_COMPLETED_OPERATION_AGGREGATE = 120,
        UNDERINSURED_UNINSURED_PROPERTY_DAMAGE = 121,
    }

    export type Name =
        | "Accidental Death"
        | "Accidental Death/Dismemberment"
        | "Accidental Dental Benefit"
        | "Accidental Dismemberment"
        | "Accidental Medical Expense"
        | "Accounts Receivable"
        | "Additional PIP"
        | "Aggregate"
        | "Annual Aggregate Limit"
        | "Bailees"
        | "Blanket: Building and Content"
        | "Blanket: Building"
        | "Blanket: Content"
        | "Broadened Pollution"
        | "Building"
        | "Business Income & Extra Expense"
        | "Business Income"
        | "Business Personal Property"
        | "Claim Expense"
        | "Claims Made"
        | "Cleanup"
        | "Collision"
        | "Combined Single Limit"
        | "Combined Uninsured/Underinsured Motorist"
        | "Comprehensive"
        | "Computer Attack and Cyber Extortion"
        | "Computer Crime"
        | "Computer Fraud"
        | "Continuous Total Disability"
        | "Contractors Tools"
        | "Cyber Liability – Standard"
        | "Damage to Rented Premises"
        | "Data Breach - Annual Aggregate"
        | "Data Compromise Liability"
        | "Data Compromise Response Expense"
        | "Defense"
        | "Disease Each Employee"
        | "Disease Policy"
        | "Each Accident"
        | "Each Occurrence"
        | "EDP Hardware"
        | "EDP Software"
        | "Electronic Media Liability"
        | "Employee Dishonesty"
        | "Employee Theft of Property"
        | "Employee Tools"
        | "Employee Benefits"
        | "Equipment Breakdown"
        | "ERISA"
        | "Extortion"
        | "Extra Expense"
        | "Forgery or Alteration"
        | "Funds Transfer Fraud"
        | "Garage Keepers"
        | "Garage Liability"
        | "General Aggregate"
        | "Glass"
        | "Hired"
        | "Identity Recovery - Annual Aggregate"
        | "Identity Recovery"
        | "In Transit Limit"
        | "In Transit"
        | "Medical Payments"
        | "Money & Security Inside Premises"
        | "Money & Security outside Premises"
        | "Money Orders and Counterfeit Money"
        | "Network Security Liability"
        | "Non-Owned"
        | "On Premises"
        | "Ordinance of Law"
        | "PD Buy Back / Mini Tort"
        | "Personal & Advertising Injury"
        | "Personal Accounts Protection"
        | "Personal Injury Protection"
        | "Personal Property of Others"
        | "Premises Burglary"
        | "Premises Robbery Outside"
        | "Premises Theft Outside"
        | "Products Completed Operation Aggregate"
        | "Products/Completed Operations"
        | "Property"
        | "Property Damage"
        | "Property Protection"
        | "Rental Reimbursement Collision"
        | "Rental Reimbursement Comprehensive"
        | "Rental Value"
        | "Rented/Leased Equipment"
        | "Robbery and Safe Burglary Inside Custodians"
        | "Robbery and Safe Burglary Inside Premises"
        | "Robbery and Safe Burglary Money and Securities Inside"
        | "Robbery and Safe Burglary Money and Securities Outside"
        | "Robbery and Safe Burglary Outside Premises"
        | "Scheduled Equipment"
        | "Single Conveyance"
        | "Specified Causes of Loss"
        | "Specified Shipper"
        | "Temporary Total Disability"
        | "Theft Disappearance and Destruction Inside Premises"
        | "Theft Disappearance and Destruction Outside Premises"
        | "Theft"
        | "Third Party Coverage"
        | "Towing & Labor"
        | "Trailer Interchange"
        | "Underinsured Motorist Property Damage"
        | "Underinsured Motorist"
        | "Uninsured Motorist"
        | "Uninsured Motorist Property Damage"
        | "Underinsured/Uninsured Property Damage"
        | "Unscheduled Equipment"
        | "Utility Service Time Element"
        | "Valuable Papers"
        | "Reefer"
        | "Hired Cargo"
        | "Bodily Injury/Property Damage Liability"
        | "Premises/Operations Liability"
        | "Excess Attendant Care"
        | "Garage Legal Liability"
        | "Computer Hardware"
        | "Computer Software";

    export const Name: Record<CoverageLimitOption.Id, CoverageLimitOption.Name> = {
        [CoverageLimitOption.Id.ACCIDENTAL_DEATH]: "Accidental Death",
        [CoverageLimitOption.Id.ACCIDENTAL_DEATH_DISMEMBERMENT]: "Accidental Death/Dismemberment",
        [CoverageLimitOption.Id.ACCIDENTAL_DENTAL_BENEFIT]: "Accidental Dental Benefit",
        [CoverageLimitOption.Id.ACCIDENTAL_DISMEMBERMENT]: "Accidental Dismemberment",
        [CoverageLimitOption.Id.ACCIDENTAL_MEDICAL_EXPENSE]: "Accidental Medical Expense",
        [CoverageLimitOption.Id.ACCOUNTS_RECEIVABLE]: "Accounts Receivable",
        [CoverageLimitOption.Id.ADDITIONAL_PIP]: "Additional PIP",
        [CoverageLimitOption.Id.AGGREGATE]: "Aggregate",
        [CoverageLimitOption.Id.ANNUAL_AGGREGATE_LIMIT]: "Annual Aggregate Limit",
        [CoverageLimitOption.Id.BAILEES]: "Bailees",
        [CoverageLimitOption.Id.BLANKET_BUILDING_AND_CONTENT]: "Blanket: Building and Content",
        [CoverageLimitOption.Id.BLANKET_BUILDING]: "Blanket: Building",
        [CoverageLimitOption.Id.BLANKET_CONTENT]: "Blanket: Content",
        [CoverageLimitOption.Id.BROADENED_POLLUTION]: "Broadened Pollution",
        [CoverageLimitOption.Id.BUILDING]: "Building",
        [CoverageLimitOption.Id.BUSINESS_INCOME_EXTRA_EXPENSE]: "Business Income & Extra Expense",
        [CoverageLimitOption.Id.BUSINESS_INCOME]: "Business Income",
        [CoverageLimitOption.Id.BUSINESS_PERSONAL_PROPERTY]: "Business Personal Property",
        [CoverageLimitOption.Id.CLAIM_EXPENSE]: "Claim Expense",
        [CoverageLimitOption.Id.CLAIMS_MADE]: "Claims Made",
        [CoverageLimitOption.Id.CLEANUP]: "Cleanup",
        [CoverageLimitOption.Id.COLLISION]: "Collision",
        [CoverageLimitOption.Id.COMBINED_SINGLE_LIMIT]: "Combined Single Limit",
        [CoverageLimitOption.Id.COMBINED_UNINSURED_UNDERINSURED_MOTORIST]: "Combined Uninsured/Underinsured Motorist",
        [CoverageLimitOption.Id.COMPREHENSIVE]: "Comprehensive",
        [CoverageLimitOption.Id.COMPUTER_ATTACK_AND_CYBER_EXTORTION]: "Computer Attack and Cyber Extortion",
        [CoverageLimitOption.Id.COMPUTER_CRIME]: "Computer Crime",
        [CoverageLimitOption.Id.COMPUTER_FRAUD]: "Computer Fraud",
        [CoverageLimitOption.Id.COMPUTER_HARDWARE]: "Computer Hardware",
        [CoverageLimitOption.Id.COMPUTER_SOFTWARE]: "Computer Software",
        [CoverageLimitOption.Id.CONTINUOUS_TOTAL_DISABILITY]: "Continuous Total Disability",
        [CoverageLimitOption.Id.CONTRACTORS_TOOLS]: "Contractors Tools",
        [CoverageLimitOption.Id.CYBER_LIABILITY_STANDARD]: "Cyber Liability – Standard",
        [CoverageLimitOption.Id.DAMAGE_TO_RENTED_PREMISES]: "Damage to Rented Premises",
        [CoverageLimitOption.Id.DATA_BREACH_ANNUAL_AGGREGATE]: "Data Breach - Annual Aggregate",
        [CoverageLimitOption.Id.DATA_COMPROMISE_LIABILITY]: "Data Compromise Liability",
        [CoverageLimitOption.Id.DATA_COMPROMISE_RESPONSE_EXPENSE]: "Data Compromise Response Expense",
        [CoverageLimitOption.Id.DEFENSE]: "Defense",
        [CoverageLimitOption.Id.DISEASE_EACH_EMPLOYEE]: "Disease Each Employee",
        [CoverageLimitOption.Id.DISEASE_POLICY]: "Disease Policy",
        [CoverageLimitOption.Id.EACH_ACCIDENT]: "Each Accident",
        [CoverageLimitOption.Id.EACH_OCCURRENCE]: "Each Occurrence",
        [CoverageLimitOption.Id.EDP_HARDWARE]: "EDP Hardware",
        [CoverageLimitOption.Id.EDP_SOFTWARE]: "EDP Software",
        [CoverageLimitOption.Id.ELECTRONIC_MEDIA_LIABILITY]: "Electronic Media Liability",
        [CoverageLimitOption.Id.EMPLOYEE_DISHONESTY]: "Employee Dishonesty",
        [CoverageLimitOption.Id.EMPLOYEE_THEFT_OF_PROPERTY]: "Employee Theft of Property",
        [CoverageLimitOption.Id.EMPLOYEE_TOOLS]: "Employee Tools",
        [CoverageLimitOption.Id.EMPLOYEE_BENEFIT]: "Employee Benefits",
        [CoverageLimitOption.Id.EQUIPMENT_BREAKDOWN]: "Equipment Breakdown",
        [CoverageLimitOption.Id.ERISA]: "ERISA",
        [CoverageLimitOption.Id.EXTORTION]: "Extortion",
        [CoverageLimitOption.Id.EXTRA_EXPENSE]: "Extra Expense",
        [CoverageLimitOption.Id.FORGERY_OR_ALTERATION]: "Forgery or Alteration",
        [CoverageLimitOption.Id.FUNDS_TRANSFER_FRAUD]: "Funds Transfer Fraud",
        [CoverageLimitOption.Id.GARAGE_KEEPERS]: "Garage Keepers",
        [CoverageLimitOption.Id.GARAGE_LIABILITY]: "Garage Liability",
        [CoverageLimitOption.Id.GENERAL_AGGREGATE]: "General Aggregate",
        [CoverageLimitOption.Id.GLASS]: "Glass",
        [CoverageLimitOption.Id.HIRED]: "Hired",
        [CoverageLimitOption.Id.IDENTITY_RECOVERY_ANNUAL_AGGREGATE]: "Identity Recovery - Annual Aggregate",
        [CoverageLimitOption.Id.IDENTITY_RECOVERY]: "Identity Recovery",
        [CoverageLimitOption.Id.IN_TRANSIT_LIMIT]: "In Transit Limit",
        [CoverageLimitOption.Id.IN_TRANSIT]: "In Transit",
        [CoverageLimitOption.Id.MEDICAL_PAYMENTS]: "Medical Payments",
        [CoverageLimitOption.Id.MONEY_AND_SECURITY_INSIDE_PREMISES]: "Money & Security Inside Premises",
        [CoverageLimitOption.Id.MONEY_AND_SECURITY_OUTSIDE_PREMISES]: "Money & Security outside Premises",
        [CoverageLimitOption.Id.MONEY_ORDERS_AND_COUNTERFEIT_MONEY]: "Money Orders and Counterfeit Money",
        [CoverageLimitOption.Id.NETWORK_SECURITY_LIABILITY]: "Network Security Liability",
        [CoverageLimitOption.Id.NON_OWNED]: "Non-Owned",
        [CoverageLimitOption.Id.ON_PREMISES]: "On Premises",
        [CoverageLimitOption.Id.ORDINANCE_OF_LAW]: "Ordinance of Law",
        [CoverageLimitOption.Id.PD_BUY_BACK_MINI_TORT]: "PD Buy Back / Mini Tort",
        [CoverageLimitOption.Id.PERSONAL_AND_ADVERTISING_INJURY]: "Personal & Advertising Injury",
        [CoverageLimitOption.Id.PERSONAL_ACCOUNTS_PROTECTION]: "Personal Accounts Protection",
        [CoverageLimitOption.Id.PERSONAL_INJURY_PROTECTION]: "Personal Injury Protection",
        [CoverageLimitOption.Id.PERSONAL_PROPERTY_OF_OTHERS]: "Personal Property of Others",
        [CoverageLimitOption.Id.PREMISES_BURGLARY]: "Premises Burglary",
        [CoverageLimitOption.Id.PREMISES_ROBBERY_OUTSIDE]: "Premises Robbery Outside",
        [CoverageLimitOption.Id.PREMISES_THEFT_OUTSIDE]: "Premises Theft Outside",
        [CoverageLimitOption.Id.PRODUCTS_COMPLETED_OPERATION_AGGREGATE]: "Products Completed Operation Aggregate",
        [CoverageLimitOption.Id.PRODUCTS_COMPLETED_OPERATIONS]: "Products/Completed Operations",
        [CoverageLimitOption.Id.PROPERTY]: "Property",
        [CoverageLimitOption.Id.PROPERTY_DAMAGE]: "Property Damage",
        [CoverageLimitOption.Id.PROPERTY_PROTECTION]: "Property Protection",
        [CoverageLimitOption.Id.RENTAL_REIMBURSEMENT_COLLISION]: "Rental Reimbursement Collision",
        [CoverageLimitOption.Id.RENTAL_REIMBURSEMENT_COMPREHENSIVE]: "Rental Reimbursement Comprehensive",
        [CoverageLimitOption.Id.RENTAL_VALUE]: "Rental Value",
        [CoverageLimitOption.Id.RENTED_LEASED_EQUIPMENT]: "Rented/Leased Equipment",
        [CoverageLimitOption.Id.ROBBERY_AND_SAFE_BURGLARY_INSIDE_CUSTODIANS]: "Robbery and Safe Burglary Inside Custodians",
        [CoverageLimitOption.Id.ROBBERY_AND_SAFE_BURGLARY_INSIDE_PREMISES]: "Robbery and Safe Burglary Inside Premises",
        [CoverageLimitOption.Id.ROBBERY_AND_SAFE_BURGLARY_MONEY_AND_SECURITIES_INSIDE]:
            "Robbery and Safe Burglary Money and Securities Inside",
        [CoverageLimitOption.Id.ROBBERY_AND_SAFE_BURGLARY_MONEY_AND_SECURITIES_OUTSIDE]:
            "Robbery and Safe Burglary Money and Securities Outside",
        [CoverageLimitOption.Id.ROBBERY_AND_SAFE_BURGLARY_OUTSIDE_PREMISES]: "Robbery and Safe Burglary Outside Premises",
        [CoverageLimitOption.Id.SCHEDULED_EQUIPMENT]: "Scheduled Equipment",
        [CoverageLimitOption.Id.SINGLE_CONVEYANCE]: "Single Conveyance",
        [CoverageLimitOption.Id.SPECIFIED_CAUSE_OF_LOSS]: "Specified Causes of Loss",
        [CoverageLimitOption.Id.SPECIFIED_SHIPPER]: "Specified Shipper",
        [CoverageLimitOption.Id.TEMPORARY_TOTAL_DISABILITY]: "Temporary Total Disability",
        [CoverageLimitOption.Id.THEFT_DISAPPEARANCE_AND_DESTRUCTION_INSIDE_PREMISES]: "Theft Disappearance and Destruction Inside Premises",
        [CoverageLimitOption.Id.THEFT_DISAPPEARANCE_AND_DESTRUCTION_OUTSIDE_PREMISES]:
            "Theft Disappearance and Destruction Outside Premises",
        [CoverageLimitOption.Id.THEFT]: "Theft",
        [CoverageLimitOption.Id.THIRD_PARTY_COVERAGE]: "Third Party Coverage",
        [CoverageLimitOption.Id.TOWING_AND_LABOR]: "Towing & Labor",
        [CoverageLimitOption.Id.TRAILER_INTERCHANGE]: "Trailer Interchange",
        [CoverageLimitOption.Id.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE]: "Underinsured Motorist Property Damage",
        [CoverageLimitOption.Id.UNDERINSURED_MOTORIST]: "Underinsured Motorist",
        [CoverageLimitOption.Id.UNINSURED_MOTORIST]: "Uninsured Motorist",
        [CoverageLimitOption.Id.UNINSURED_MOTORIST_PROPERTY_DAMAGE]: "Uninsured Motorist Property Damage",
        [CoverageLimitOption.Id.UNDERINSURED_UNINSURED_PROPERTY_DAMAGE]: "Underinsured/Uninsured Property Damage",
        [CoverageLimitOption.Id.UNSCHEDULED_EQUIPMENT]: "Unscheduled Equipment",
        [CoverageLimitOption.Id.UTILITY_SERVICE_TIME_ELEMENT]: "Utility Service Time Element",
        [CoverageLimitOption.Id.VALUABLE_PAPERS]: "Valuable Papers",
        [CoverageLimitOption.Id.REEFER]: "Reefer",
        [CoverageLimitOption.Id.HIRED_CARGO]: "Hired Cargo",
        [CoverageLimitOption.Id.BODILY_INJURY_PROPERTY_DAMAGE_LIABILITY]: "Bodily Injury/Property Damage Liability",
        [CoverageLimitOption.Id.PREMISES_OPERATIONS_LIABILITY]: "Premises/Operations Liability",
        [CoverageLimitOption.Id.EXCESS_ATTENDANT_CARE]: "Excess Attendant Care",
        [CoverageLimitOption.Id.GARAGE_LEGAL_LIABILITY]: "Garage Legal Liability",
    };
}
