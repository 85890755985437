import { PrimaryButton } from "@deathstar/ui";
import { Link, useParams } from "react-router-dom";
import img from "../../assets/images/unauthorized.svg";
import BaseErrorScreen from "./BaseErrorScreen";

export default function Unauthorized() {
    const params = useParams();
    return (
        <BaseErrorScreen
            title="Whoops!"
            description="Looks like you've driven into some private information. Ask your organization administrator for access."
            image={<img src={img} alt="Vault" className="hidden w-1/3 lg:block" />}
            primaryAction={
                <Link to={`/sol/${params.accountId || ""}`} className="block w-max">
                    <PrimaryButton>Point me back home</PrimaryButton>
                </Link>
            }
        />
    );
}
