import { IsString, IsOptional, Length, IsPhoneNumber, IsMobilePhone, IsEmpty } from "class-validator";
import { Transform } from "class-transformer";
import type { Address } from "./Address";
import { User } from "./User";

enum RecordType {
    Insured = "012f4000000zru4",
    Customer = "012f4000000zru9",
    Competitor = "012f4000000zruE",
}
export class Contact {
    static RecordType = RecordType;
    attributes: {
        type: "Contact";
        url: string;
    };
    AccountId: string;
    Description: string;
    Email: string;
    Fax: string;
    FirstName: string;
    HomePhone: string;
    Id: string;
    LastName: string;
    MailingAddress: Address;
    MiddleName: string;
    MobilePhone: string;
    Name: string;
    Nickname__c: string;
    OtherAddress: Address;
    OtherPhone: string;
    Phone: string;
    Account_Agent__c: string;
    Responsibility__c: string;
    Pinned__c: boolean;
    Salutation: string;
    Suffix: string;
    Title: string;
    RecordTypeId: RecordType;
    OwnerId: string;
    Owner: User;
    Gift_Level__c: string;
    Christmas_Gift__c: boolean;
    Gift_Comments__c: string;
}

export interface CreateContactEntityResponse {
    Entity: "Contact";
    Contact: {
        Id: string;
    };
}
export interface CreateContactRelationResponse {
    Entity: "AccountContactRelation";
    AccountContactRelation: {
        Id: string;
        AccountId: string;
        ContactId: string;
    };
}

export type CreateContactResponse = CreateContactEntityResponse | CreateContactRelationResponse;

export class ContactCreateDto implements Partial<Contact> {
    @IsString()
    @Length(1, 255)
    LastName: string;

    @IsString()
    OwnerId: string;

    @IsString()
    @IsOptional()
    FirstName?: string;

    @IsOptional()
    @IsString()
    Email?: string;

    @Transform(({ value }) => (value === "" ? null : value))
    @IsOptional()
    @IsString()
    @IsPhoneNumber("US")
    Phone?: string;

    @Transform(({ value }) => (value === "" ? null : value))
    @IsOptional()
    @IsString()
    @IsMobilePhone("en-US")
    MobilePhone?: string;

    @IsOptional()
    @IsString()
    Title?: string;

    @IsOptional()
    @IsString()
    Description?: string;

    @IsString()
    @Length(15)
    AccountId?: string;

    @IsOptional()
    @IsString()
    Nickname__c?: string;

    @IsOptional()
    @IsString()
    Responsibility__c?: string;

    @IsEmpty()
    Id?: string;

    @IsEmpty()
    Name?: string;
}

export class ContactUpdateDto implements Partial<Contact> {
    @IsString()
    @IsOptional()
    FirstName?: string;

    @IsOptional()
    @IsString()
    LastName?: string;

    @IsOptional()
    @IsString()
    Email?: string;

    @Transform(({ value }) => (value === "" ? null : value))
    @IsOptional()
    @IsString()
    @IsPhoneNumber("US")
    Phone?: string;

    @Transform(({ value }) => (value === "" ? null : value))
    @IsOptional()
    @IsString()
    @IsMobilePhone("en-US")
    MobilePhone?: string;

    @IsOptional()
    @IsString()
    Title?: string;

    @IsOptional()
    @IsString()
    Description?: string;

    @IsOptional()
    @IsString()
    OwnerId?: string;

    @IsOptional()
    @IsString()
    AccountId?: string;

    @IsOptional()
    @IsString()
    Nickname__c?: string;

    @IsOptional()
    @IsString()
    Responsibility__c?: string;

    @IsEmpty({ message: "`Id` is readonly." })
    Id?: string;

    @IsEmpty({ message: "`Name` is readonly. Use `FirstName` and `LastName` instead." })
    Name?: string;
}
