import { SnackbarProvider } from "@deathstar/ui";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "./components/error-screens/PageNotFound";
import Invite from "./features/invites/invites";
import About from "./pages/about/About";
import { Landing } from "./pages/Landing";
import Legal from "./pages/legal/Legal";
import MemberSetup from "./pages/member-setup/MemberSetup";
import OO from "./pages/owner-operator/OO";
import Profile from "./pages/profile/Profile";
import Sol from "./pages/sol/Sol";

export function App() {
    return (
        <>
            <SnackbarProvider align="center" />
            <Routes>
                <Route index element={<Landing />} />
                <Route path="profile/*" element={<Profile />} />
                <Route path="sol/:accountId?/*" element={<Sol />} />
                <Route path="oo/:accountId?/*" element={<OO />} />
                <Route path="invites/:inviteId?" element={<Invite />} />
                <Route path="member-setup/*" element={<MemberSetup />} />
                <Route path="about/*" element={<About />} />
                <Route path="legal/*" element={<Legal />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </>
    );
}
