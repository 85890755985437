export enum BasicName {
    UNSAFE_DRIVING = "Unsafe Driving",
    HOS = "Hours of Service",
    VEHICLE_MAINTENANCE = "Vehicle Maintenance",
    CONTROLLED_SUBSTANCES = "Controlled Substances",
    HAZMAT = "Hazmat",
    DRIVER_FITNESS = "Driver Fitness",
    CRASH_INDICATOR = "Crash Indicator",
    MULTIPLE = "Multiple",
    INSURANCE_OTHER = "Insurance/Other",
    UNKNOWN = "Unknown",
}

export namespace BasicName {
    export enum Abbreviation {
        UNSAFE_DRIVING = "UD",
        HOS = "HOS",
        VEHICLE_MAINTENANCE = "VM",
        CONTROLLED_SUBSTANCES = "CS",
        HAZMAT = "HM",
        DRIVER_FITNESS = "DF",
        CRASH_INDICATOR = "CR",
        INSURANCE_OTHER = "IO",
        MULTIPLE = "Multiple",
        UNKNOWN = "Unknown",
    }

    const abbreviationMap: Map<BasicName, Abbreviation> = new Map([
        [BasicName.UNSAFE_DRIVING, Abbreviation.UNSAFE_DRIVING],
        [BasicName.HOS, Abbreviation.HOS],
        [BasicName.VEHICLE_MAINTENANCE, Abbreviation.VEHICLE_MAINTENANCE],
        [BasicName.CONTROLLED_SUBSTANCES, Abbreviation.CONTROLLED_SUBSTANCES],
        [BasicName.HAZMAT, Abbreviation.HAZMAT],
        [BasicName.DRIVER_FITNESS, Abbreviation.DRIVER_FITNESS],
        [BasicName.CRASH_INDICATOR, Abbreviation.CRASH_INDICATOR],
        [BasicName.INSURANCE_OTHER, Abbreviation.INSURANCE_OTHER],
        [BasicName.MULTIPLE, Abbreviation.MULTIPLE],
        [BasicName.UNKNOWN, Abbreviation.UNKNOWN],
    ]);

    export function getAbbreviation(basic: BasicName): Abbreviation {
        return abbreviationMap.get(basic) ?? Abbreviation.UNKNOWN;
    }

    export function array(): [
        BasicName.UNSAFE_DRIVING,
        BasicName.HOS,
        BasicName.VEHICLE_MAINTENANCE,
        BasicName.CONTROLLED_SUBSTANCES,
        BasicName.HAZMAT,
        BasicName.DRIVER_FITNESS,
        BasicName.CRASH_INDICATOR
    ] {
        return [
            BasicName.UNSAFE_DRIVING,
            BasicName.HOS,
            BasicName.VEHICLE_MAINTENANCE,
            BasicName.CONTROLLED_SUBSTANCES,
            BasicName.HAZMAT,
            BasicName.DRIVER_FITNESS,
            BasicName.CRASH_INDICATOR,
        ];
    }
}
