import { produce } from "immer";
import { merge, uniqueId } from "lodash";
import { useEffect } from "react";
import { animated, useTransition } from "react-spring";
import create, { StoreApi, UseBoundStore } from "zustand";
import { Alert, AlertProps } from "../alert/alert";
import { classNames } from "../classNames/classNames";

type SnackbarOptions = {
    message: string;
    autoHideDuration?: number;
    persist?: boolean;
} & AlertProps;

type AddSnackbarProps = Omit<SnackbarOptions, "message">;
type GetAddSnackbarProps = (id: string) => AddSnackbarProps;

export type SnackbarStore = {
    snacks: { id: string; props: SnackbarOptions; element?: HTMLElement; timeout?: number; height?: number }[];
    add(message: string, props?: AddSnackbarProps | GetAddSnackbarProps): string;
    close(id: string): void;
    update(id: string, props: Partial<SnackbarOptions>): void;

    maxSnacks: number;
    enqueueSnackbar: SnackbarStore["add"];
    closeSnackbar: SnackbarStore["close"];
    updateSnackbar: SnackbarStore["update"];
};
const store = create<SnackbarStore>((set, get) => ({
    maxSnacks: 3,
    snacks: [],
    add(message, props) {
        const id = uniqueId();
        const snackProps = Object.assign(
            { persist: false, autoHideDuration: 5000, variant: "info" },
            typeof props === "function" ? props(id) : props,
            {
                message,
            }
        );
        const snacks = [...get().snacks];
        if (snacks.length >= get().maxSnacks) {
            snacks.shift();
        }

        let timeout: number | undefined = undefined;
        if (!snackProps.persist) {
            timeout = window.setTimeout(() => {
                const { close, snacks } = get();
                if (snacks.find((s) => s.id === id)) {
                    close(id);
                }
            }, snackProps.autoHideDuration);
        }
        snacks.push({ id, props: snackProps, timeout });
        set({ snacks });
        return id;
    },
    close(id) {
        set(
            produce<SnackbarStore>((draft) => {
                const index = draft.snacks.findIndex((s) => s.id === id);
                if (index >= 0) {
                    if (draft.snacks[index].timeout) {
                        clearTimeout(draft.snacks[index].timeout);
                    }
                    draft.snacks.splice(index, 1);
                }
            })
        );
        set((state) => {
            const index = state.snacks.findIndex((s) => s.id === id);
            if (index >= 0) {
                if (state.snacks[index].timeout) {
                    clearTimeout(state.snacks[index].timeout);
                }
                return {
                    snacks: state.snacks.filter((_, i) => i !== index),
                };
            }
            return state;
        });
    },
    update(id, props) {
        const { snacks, close } = get();
        set({
            snacks: produce(snacks, (draft) => {
                const snack = draft.find((s) => s.id === id);
                if (!snack) return;

                snack.props = merge(snack.props, props);

                if (props.persist && snack.timeout) {
                    clearTimeout(snack.timeout);
                } else if (props.persist === false && !snack.timeout) {
                    snack.timeout = window.setTimeout(() => {
                        close(id);
                    }, snack.props.autoHideDuration || 5000);
                }
            }),
        });
    },

    enqueueSnackbar: (...args) => get().add(...args),
    closeSnackbar: (...args) => get().close(...args),
    updateSnackbar: (...args) => get().update(...args),
}));

export const useSnackbar: UseBoundStore<SnackbarStore, StoreApi<SnackbarStore>> & {
    add: SnackbarStore["add"];
    close: SnackbarStore["close"];
    update: SnackbarStore["update"];
} = Object.assign(store, {
    add: store.getState().add,
    close: store.getState().close,
    update: store.getState().update,
});

export function SnackbarProvider({ maxSnacks, align }: { maxSnacks?: number; align?: "left" | "center" }): JSX.Element {
    const snacks = useSnackbar((s) => s.snacks);
    useEffect(() => {
        useSnackbar.setState({ maxSnacks: maxSnacks || 3 });
    }, [maxSnacks]);
    const transition = useTransition(snacks, {
        from: { opacity: 0, bottom: 0, scale: 0, x: align === "center" ? "-50%" : undefined },
        enter: { opacity: 1, bottom: 16, scale: 1 },
        update: (item) => async (next, _cancel) => {
            const bottom = snacks
                .slice(snacks.findIndex((s) => s.id === item.id) + 1)
                .reduce((total, snack) => total + (snack.height || 100) + 16, 16);
            await next({
                opacity: 1,
                bottom,
            });
        },
        leave: { opacity: 0 },
        keys: snacks.map((x) => x.id),
        config: {
            tension: 150,
            friction: 16,
        },
    });

    return (
        <div>
            {transition((values, item, _, _index) => {
                const { persist: _persist, message, ref: _ref, autoHideDuration: _autoHideDuration, ...props } = item.props;
                return (
                    <animated.div
                        style={{ ...values, position: "fixed", zIndex: 1500 }}
                        children={
                            <Alert key={item.id} {...props}>
                                {message}
                            </Alert>
                        }
                        className={classNames(
                            "whitespace-pre-line md:w-max md:min-w-[256px] md:max-w-prose",
                            align === "center" && "left-1/2 right-0 w-[calc(100%-2rem)]",
                            (align === "left" || !align) && "left-4"
                        )}
                        ref={(el: HTMLElement | null) => {
                            if (el) {
                                useSnackbar.setState(
                                    produce<SnackbarStore>((draft) => {
                                        const snack = draft.snacks.find((s) => s.id === item.id);
                                        if (snack) {
                                            snack.height = el.offsetHeight;
                                        }
                                    })
                                );
                            }
                        }}
                    />
                );
            })}
        </div>
    );
}
