import { NumberFormatter } from "../util/NumberFormatter/NumberFormatter";
import { Attribute } from "./Attribute";
import { Comparison } from "./Comparison/Comparison";
import { CoverageLinkedBusinessAuto } from "./CoverageLinkedBusinessAuto";
import { CoverageLinkedTractor } from "./CoverageLinkedTractor";
import { CoverageLinkedTrailer } from "./CoverageLinkedTrailer";
import { PolicyLayer } from "./PolicyLayer";

export interface CoverageLinkedVehicle {
    attributes?: Attribute[];
    statedValue?: number;
    hasCollision?: boolean;
    uniqueCollisionDeductible?: number | null;
    hasComprehensive?: boolean;
    uniqueComprehensiveDeductible?: number | null;
    isSpareRated?: boolean;
    isOwnerOperator?: boolean;
    isValuationActualCashValue?: boolean;
}
export class CoverageLinkedVehicle {
    static compare<Type extends CoverageLinkedTractor | CoverageLinkedTrailer | CoverageLinkedBusinessAuto>({
        comparison,
        base,
        compare,
        descriptions,
        comparisonLayer,
        baseLayer,
        label,
    }: {
        comparison: Comparison<Type>;
        base: Type;
        compare: Type;
        descriptions: {
            add: string;
            remove: string;
        };
        comparisonLayer?: PolicyLayer;
        baseLayer?: PolicyLayer;
        label: string;
    }): void {
        const ENTITY = compare || base;
        const ID_FIELD_NAME =
            ENTITY instanceof CoverageLinkedBusinessAuto
                ? "businessAutoId"
                : ENTITY instanceof CoverageLinkedTractor
                ? "tractorId"
                : "trailerId";
        const LINKED_ENTITY_FIELD_NAME =
            ID_FIELD_NAME === "businessAutoId"
                ? "linkedBusinessAutos"
                : ID_FIELD_NAME === "tractorId"
                ? "linkedTractors"
                : "linkedTrailers";

        if (!base) {
            if (baseLayer) {
                const allPreviousBaIds = new Set(
                    baseLayer.policy.coverages
                        .map((cvg) => cvg[LINKED_ENTITY_FIELD_NAME])
                        .flat()
                        .map((l) => l[ID_FIELD_NAME])
                );

                compare.comparison = {
                    isNewToPolicy: !allPreviousBaIds.has(compare[ID_FIELD_NAME]),
                };
            }

            comparison.setNew({
                obj: compare,
                description: descriptions.add,
                subComparison: comparison as Comparison<unknown>,
                label,
            });

            return;
        }

        if (!compare) {
            if (comparisonLayer) {
                const allPreviousBaIds = new Set(
                    comparisonLayer.policy.coverages
                        .map((cvg) => cvg[LINKED_ENTITY_FIELD_NAME])
                        .flat()
                        .map((l) => l[ID_FIELD_NAME])
                );

                base.comparison = {
                    isDeletedFromPolicy: !allPreviousBaIds.has(base[ID_FIELD_NAME]),
                };
            }

            comparison.setDelete({
                obj: base,
                description: descriptions.remove,
                subComparison: comparison as Comparison<unknown>,
                label,
            });
            return;
        }

        [
            {
                key: "hasComprehensive",
                label: "Comp",
                transform: (v) => (v ? "Included" : "Excluded"),
                getActionLabel: (v): Comparison.ActionLabel => (v ? "add" : "remove"),
            },
            {
                key: "uniqueComprehensiveDeductible",
                label: "Unique Comp Deductible",
                transform: (v) => (typeof v === "number" ? NumberFormatter.Currency.format(v) : "n/a"),
            },
            {
                key: "hasCollision",
                label: "Coll",
                transform: (v) => (v ? "Included" : "Excluded"),
                getActionLabel: (v): Comparison.ActionLabel => (v ? "add" : "remove"),
            },
            {
                key: "uniqueCollisionDeductible",
                label: "Unique Coll Deductible",
                transform: (v) => (typeof v === "number" ? NumberFormatter.Currency.format(v) : "n/a"),
            },
            {
                key: "isSpareRated",
                label: "Spare Rated",
                transform: (v) => (v ? "Yes" : "No"),
                getActionLabel: (v): Comparison.ActionLabel => (v ? "add" : "remove"),
            },
            {
                key: "isOwnerOperator",
                label: "O.O.",
                transform: (v) => (v ? "Yes" : "No"),
                getActionLabel: (v): Comparison.ActionLabel => (v ? "add" : "remove"),
            },
            {
                key: "isValuationActualCashValue",
                label: "ACV",
                transform: (v) => (v ? "Yes" : "No"),
                getActionLabel: (v): Comparison.ActionLabel => (v ? "add" : "remove"),
            },
            {
                key: "statedValue",
                label: "Stated Value",
                transform: (v) => NumberFormatter.Currency.format(v),
            },
        ].forEach(({ key, label, transform, getActionLabel }) => {
            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Update ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    actionLabel: getActionLabel?.(compare[key]),
                    priority: null,
                    fieldName: key as keyof Type,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });
    }
}
