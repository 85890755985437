export interface AuthorityHistory {
    DocketNumber: string;
    DotNumber: number;
    SequenceNumber?: number;
    AuthorityTypeDescription?: string;
    OriginalActionDescription?: string;
    OriginalServedDate?: Date | string;
    DispensationActionDescription?: string;
    DispensationDecidedDate?: Date | string;
    DispensationServedDate?: Date | string;
}

export namespace AuthorityHistory {
    export enum OriginalActionDescription {
        CANCELLED = "CANCELLED",
        DISMISSED = "DISMISSED",
        REJECTED = "REJECTED",
        WITHDRAWN = "WITHDRAWN",
        SUSPENDED = "SUSPENDED",
        DENIED = "DENIED",
        REINSTATED = "REINSTATED",
        INVOLUNTARY_REVOCATION = "INVOLUNTARY REVOCATION",
        SPECIAL_INVOLUNTARY_REVOCATION = "SPECIAL INVOLUNTARY REVOCATION",
        GRANTED = "GRANTED",
    }

    export enum DispensationActionDescription {
        ADMINISTRATIVE_INACTIVATION = "ADMINISTRATIVE INACTIVATION",
        CANCELLED_AND_REISSUED = "CANCELLED AND REISSUED",
        TERM_EXPIRED = "TERM EXPIRED",
        TRANSFERRED = "TRANSFERRED",
        SAFETY_SUSPENSION = "SAFETY SUSPENSION",
        TRANSFER_CONSUMMATED = "TRANSFER CONSUMMATED",
        EXPIRED_ETA_OR_TA = "EXPIRED ETA OR TA",
        REPLACED_BY_PROVISIONAL_AUTHORITY = "REPLACED BY PROVISIONAL AUTHORITY",
        OUT_OF_SERVICE = "OUT OF SERVICE",
        REVOKED = "REVOKED",
        RENUMBERED = "RENUMBERED",
        FAILURE_TO_REAPPLY = "FAILURE TO REAPPLY",
        SAFETY_REVOCATION = "SAFETY REVOCATION",
        DISCONTINUED_REVOCATION = "DISCONTINUED REVOCATION",
        SUPERSEDED = "SUPERSEDED",
    }
}
