import { plainToClass, Type } from "class-transformer";
import { NumberFormatter } from "../util/NumberFormatter/NumberFormatter";
import { Attribute } from "./Attribute";
import { AttributeOption } from "./AttributeOption";
import { Comparison } from "./Comparison/Comparison";
import { Coverage } from "./Coverage";
import { CoverageOption } from "./CoverageOption";
import { InlandMarineEquipment } from "./InlandMarineEquipment";
import { EquipmentComparison } from "./PolicyLayer";

export class CoverageLinkedTool implements EquipmentComparison.IIsComparable {
    #initialized = false;
    static init(options: { coverage?: Coverage; linkedTool: CoverageLinkedTool }): CoverageLinkedTool {
        const { linkedTool } = options;
        if (linkedTool instanceof CoverageLinkedTool && linkedTool.#initialized) return linkedTool;

        const e = plainToClass(CoverageLinkedTool, linkedTool);
        e.coverage = options.coverage;
        e.statedValue = CoverageLinkedTool.getStatedValue(options);
        e.isValuationActualCashValue = CoverageLinkedTool.getIsValuationActualCashValue(options);

        e.#initialized = true;
        return e;
    }

    static #getAttributes({ coverage, linkedTool }: { coverage?: Coverage; linkedTool: CoverageLinkedTool }): Attribute[] {
        return linkedTool.attributes || CoverageLinkedTool.getAttributes({ coverage, linkedTool });
    }

    static getAttributes({ coverage, linkedTool }: { coverage?: Coverage; linkedTool: CoverageLinkedTool }): Attribute[] {
        if (!coverage) return [];
        return coverage.attributes.filter((a) => a.toolId === linkedTool.toolId);
    }

    static getIsValuationActualCashValue(options: { coverage?: Coverage; linkedTool: CoverageLinkedTool }): boolean {
        return CoverageLinkedTool.hasAttribute({ ...options, attributeOptionId: AttributeOption.Id.VALUATION_ACTUAL_CASH_VALUE });
    }

    static hasAttribute({
        coverage,
        linkedTool,
        attributeOptionId,
    }: {
        coverage?: Coverage;
        linkedTool: CoverageLinkedTool;
        attributeOptionId: AttributeOption.Id;
    }): boolean {
        const tractorAttributes = CoverageLinkedTool.getAttributes({ coverage, linkedTool });
        return tractorAttributes.some((a) => a.attributeOptionId === attributeOptionId);
    }
    static getStatedValue(options: { coverage?: Coverage; linkedTool: CoverageLinkedTool }): number | null {
        const statedValueAttr = CoverageLinkedTool.#getAttributes(options).find(
            (a) => a.attributeOptionId === AttributeOption.Id.STATED_VALUE
        );
        return statedValueAttr?.valueNumber || null;
    }

    static compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Comparison.IOptions<CoverageLinkedTool>): Map<CoverageOption.Id, CoverageLinkedTool.IComparisonReturn> {
        const map = new Map<CoverageOption.Id, CoverageLinkedTool.IComparisonReturn>();

        if (compare) {
            for (const [cvgOptId, linkedTool] of Object.entries(compare) as unknown as [CoverageOption.Id, CoverageLinkedTool][]) {
                const from = base?.[cvgOptId];
                const to = linkedTool;
                map.set(cvgOptId, CoverageLinkedTool.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        if (base) {
            for (const [cvgOptId, linkedTool] of Object.entries(base) as unknown as [CoverageOption.Id, CoverageLinkedTool][]) {
                if (map.has(cvgOptId)) continue;
                const from = linkedTool;
                const to = compare?.[cvgOptId];
                map.set(cvgOptId, CoverageLinkedTool.#compare({ base: from, compare: to, comparisonLayer, baseLayer }));
            }
        }

        return map;
    }

    static #compare({
        base,
        compare,
        comparisonLayer,
        baseLayer,
    }: Partial<Comparison.IOptions.Entity<CoverageLinkedTool>>): CoverageLinkedTool.IComparisonReturn {
        const comparison = new Comparison(CoverageLinkedTool, base, compare) as CoverageLinkedTool.IComparisonReturn;
        comparison.setField("toolId", compare?.toolId || base?.toolId);
        comparison.setField("tool", compare?.tool || base?.tool);

        if (!base) {
            if (baseLayer) {
                const allPreviousBaIds = new Set(
                    baseLayer.policy.coverages
                        .map((cvg) => cvg.linkedTools)
                        .flat()
                        .map((l) => l.toolId)
                );

                compare.comparison = {
                    isNewToPolicy: !allPreviousBaIds.has(compare.toolId),
                };
            }

            return comparison.setNew({
                obj: compare,
                description: `Add tool ${compare?.toString()}`,
                subComparison: comparison as Comparison<unknown>,
                label: "Tool",
            });
        }

        if (!compare) {
            if (comparisonLayer) {
                const allPreviousBaIds = new Set(
                    comparisonLayer.policy.coverages
                        .map((cvg) => cvg.linkedTools)
                        .flat()
                        .map((l) => l.toolId)
                );

                base.comparison = {
                    isDeletedFromPolicy: !allPreviousBaIds.has(base.toolId),
                };
            }

            return comparison.setDelete({
                obj: base,
                description: `Delete tool ${base?.tool?.serialNumber}`,
                subComparison: comparison as Comparison<unknown>,
                label: "Tool",
            });
        }

        [
            {
                key: "isValuationActualCashValue",
                label: "ACV",
                transform: (v) => (v ? "Yes" : "No"),
                getActionLabel: (v): Comparison.ActionLabel => (v ? "add" : "remove"),
            },
            {
                key: "statedValue",
                label: "Stated Value",
                transform: (v) => NumberFormatter.Currency.format(v),
            },
        ].forEach(({ key, label, transform }) => {
            if (base[key] !== compare[key]) {
                comparison.addDiff({
                    type: "change",
                    description: `Update ${label} from ${transform(base[key])} to ${transform(compare[key])}`,
                    label,
                    priority: null,
                    fieldName: key as keyof CoverageLinkedTool,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        return comparison;
    }

    id: number;
    createdDate: Date;

    toolId: number;
    @Type(() => InlandMarineEquipment)
    tool?: InlandMarineEquipment;

    attributes: Attribute[];
    statedValue?: number;
    isValuationActualCashValue?: boolean;

    coverageId: number;
    coverageCreatedDate: Date;
    coverage?: Coverage;

    comparison?: {
        isNewToPolicy?: boolean;
        isDeletedFromPolicy?: boolean;
    };

    toString(): string {
        if (!this.tool) {
            return `CoverageLinkedTool: ${this.toolId}`;
        }
        const baseStringParts = [this.tool.toString()];
        if (this.isValuationActualCashValue) {
            baseStringParts.push("(ACV)");
        } else if (typeof this.statedValue === "number") {
            baseStringParts.push(`(SV: ${NumberFormatter.Currency.format(this.statedValue)})`);
        }
        return baseStringParts.join(" ");
    }

    compare(
        comparisonlinkedTool: CoverageLinkedTool,
        options?: Comparison.getBaseAndComparisonObjects.IOptions
    ): Comparison<CoverageLinkedTool> {
        return CoverageLinkedTool.#compare(
            Comparison.getBaseAndComparisonObjects(
                {
                    initiatorEntity: this,
                    comparisonEntity: comparisonlinkedTool,
                },
                options
            )
        );
    }
}

export namespace CoverageLinkedTool {
    export interface IComparisonReturn extends Comparison<CoverageLinkedTool> {
        toolId: number;
        tool?: InlandMarineEquipment;
    }
}
