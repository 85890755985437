import { Paper } from "@material-ui/core";
import { PrintSectionName } from "../Components/PrintSectionName";
import { CurrentScores } from "./CurrentScores";
import { FmcsaSnapshot } from "./FmcsaSnapshot";
import { Trends } from "./Trends";
import { ViolationSummary } from "./ViolationSummary";

export function Summary(): JSX.Element {
    return (
        <>
            <div className="page relative">
                <PrintSectionName name="Summary" />
                <Paper className="page-height flex flex-col print:justify-around" elevation={0}>
                    <FmcsaSnapshot />
                    <CurrentScores />
                    <ViolationSummary />
                </Paper>
            </div>
            <div className="page relative">
                <PrintSectionName name="Trends" />
                <Paper className="grid grid-cols-2" elevation={0}>
                    <Trends />
                </Paper>
            </div>
        </>
    );
}
