import React from "react";
import { Line } from "react-chartjs-2";
import { config } from "../ChartConfig";
import { Context } from "../Context";
import { IDataset } from "../History/useHistoricalScoreData";

export function HistoricalScoreChart({ datasets }: { datasets: IDataset[] }): JSX.Element {
    const { motorCarrier } = React.useContext(Context);
    const basics = Array.from(motorCarrier.smsResults.sortByDate("ASC"));

    return (
        <Line
            className="h-full w-full"
            data={{
                labels: basics.map((basic) => basic.recordDate.getMonthString({ abbreviate: true })),
                datasets,
            }}
            options={{
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    y: {
                        suggestedMin: 0,
                        beginAtZero: config.chart.line.axis.y.beginAtZero,
                        min: config.chart.line.axis.y.min,
                        max: config.chart.line.axis.y.max,
                        ticks: {
                            format: {
                                style: "unit",
                                unit: "percent",
                            },
                            color: config.chart.line.labels.font.color,
                            // font: {
                            //     size: config.chart.line.labels.font.size,
                            // },
                        },
                        grid: config.chart.line.grid,
                    },
                    x: {
                        ticks: {
                            color: config.chart.line.labels.font.color,
                            // font: {
                            //     size: config.chart.line.labels.font.size,
                            // },
                        },
                        grid: config.chart.line.grid,
                    },
                },
                plugins: {
                    legend: {
                        display: config.chart.line.legend.display,
                    },
                    tooltip: {
                        filter: (_tooltipItem, i, _tooltipArr) => {
                            if (i % 2 > 0) return false;
                            return true;
                        },
                    },
                },
            }}
        />
    );
}
