import { Type, plainToClass } from "class-transformer";
import { Validate, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from "class-validator";
import { Comparison } from "./Comparison/Comparison";
import { Coverage } from "./Coverage";
import { CoverageOption } from "./CoverageOption";
import type { GlobalCertificateHolder } from "./GlobalCertificateHolder";
import type { Policy } from "./Policy";
import { PolicyAdditionalInterestLinkedBusinessAuto } from "./PolicyAdditionalInterestLinkedBusinessAuto";
import { PolicyAdditionalInterestLinkedProperty } from "./PolicyAdditionalInterestLinkedProperty";
import { PolicyAdditionalInterestLinkedTool } from "./PolicyAdditionalInterestLinkedTool";
import { PolicyAdditionalInterestLinkedTractor } from "./PolicyAdditionalInterestLinkedTractor";
import { PolicyAdditionalInterestLinkedTrailer } from "./PolicyAdditionalInterestLinkedTrailer";

@ValidatorConstraint({
    name: "GlobalCertificateHolderValidator",
    async: false,
})
export class GlobalCertificateHolderValidator implements ValidatorConstraintInterface {
    validate(value: PolicyAdditionalInterest[], _args: ValidationArguments): boolean {
        try {
            for (const ai of value) {
                const linkedEntity = ai.additionalInterest;
                if (!ai.isBlanket && (!linkedEntity.street || !linkedEntity.city || !linkedEntity.state || !linkedEntity.zip)) {
                    throw new Error();
                }
            }
        } catch (e: unknown) {
            return false;
        }

        return true;
    }

    defaultMessage(_args: ValidationArguments): string {
        return "Invalid address";
    }
}

@ValidatorConstraint({
    name: "AppliedCoveragesValidator",
    async: false,
})
export class AppliedCoveragesValidator implements ValidatorConstraintInterface {
    validate(value: PolicyAdditionalInterest, args: ValidationArguments): boolean {
        const additionalInterest = args.object as PolicyAdditionalInterest;
        const { policy } = additionalInterest;
        const coverageOptionIdSet = new Set(policy.coverages.map((c) => c.coverageOptionId));

        try {
            additionalInterest.coveragesAppliedTo.forEach((c) => {
                if (!coverageOptionIdSet.has(c.coverageOptionId)) {
                    throw new Error();
                }
            });
        } catch (e: unknown) {
            return false;
        }

        return true;
    }

    defaultMessage(_args: ValidationArguments): string {
        return `Invalid coverages applied`;
    }
}

namespace PolicyAdditionalInterestEnums {
    export enum Type {
        ADDITIONL_INSURED = "Additional Insured",
        ADDITIONAL_NAMED_INSURED = "Additional Named Insured",
        LOSS_PAYEE = "Loss Payee",
        MORTGAGEE = "Mortgagee",
        MOTOR_CARRIER = "Motor Carrier",
        PRIMARY_NON_CONTRIBUTORY = "Primary and Non-Contributory",
        WAIVER_OF_SUBROGATION = "Waiver of Subrogation",
        CANCEL_NOTICE_30_DAY = "30 Day Cancellation Notice",
    }

    export enum Abbreviation {
        ADDITIONL_INSURED = "AI",
        ADDITIONAL_NAMED_INSURED = "ANI",
        LOSS_PAYEE = "LP",
        MORTGAGEE = "MORT",
        MOTOR_CARRIER = "MC",
        PRIMARY_NON_CONTRIBUTORY = "PNC",
        WAIVER_OF_SUBROGATION = "WOS",
        CANCEL_NOTICE_30_DAY = "30-Day-NOCC",
    }
}

export class PolicyAdditionalInterest {
    static Type = PolicyAdditionalInterestEnums.Type;
    static Abbreviation = PolicyAdditionalInterestEnums.Abbreviation;

    static duplicate(additionalInterest: PolicyAdditionalInterest): PolicyAdditionalInterest {
        const ai = plainToClass(PolicyAdditionalInterest, additionalInterest);

        ai.id = null;
        ai.createdDate = null;
        ai.policyId = null;
        ai.policyCreatedDate = null;
        ai.policy = null;

        return ai;
    }

    static createTemplateFrom(addInt: PolicyAdditionalInterest): PolicyAdditionalInterest {
        const ai = PolicyAdditionalInterest.duplicate(addInt);

        ai.linkedBusinessAutos = [];
        ai.linkedTrailers = [];
        ai.linkedTractors = [];
        ai.linkedTools = [];
        ai.linkedProperties = [];

        return ai;
    }

    static hasDefinedType(additionalInterest: PolicyAdditionalInterest): boolean {
        return [
            additionalInterest.isBlanket,
            additionalInterest.isAdditionalInsured,
            additionalInterest.isAdditionalNamedInsured,
            additionalInterest.isLossPayee,
            additionalInterest.isMortgagee,
            additionalInterest.isMotorCarrier,
            additionalInterest.isPrimaryAndNonContributory,
            additionalInterest.isWaiverOfSubrogation,
            additionalInterest.isCancelNotice30Day,
        ].some((x) => x);
    }

    static #getAdditionalInterestTypesAsString(additionalInterest: PolicyAdditionalInterest): string {
        return PolicyAdditionalInterest.getAdditionalInterestTypes(additionalInterest).join(", ");
    }

    static getAdditionalInterestTypes(additionalInterest: PolicyAdditionalInterest): PolicyAdditionalInterestEnums.Type[] {
        if (!additionalInterest) return [];
        const types: PolicyAdditionalInterestEnums.Type[] = [];
        if (additionalInterest.isAdditionalInsured) {
            types.push(PolicyAdditionalInterestEnums.Type.ADDITIONL_INSURED);
        }
        if (additionalInterest.isAdditionalNamedInsured) {
            types.push(PolicyAdditionalInterestEnums.Type.ADDITIONAL_NAMED_INSURED);
        }
        if (additionalInterest.isLossPayee) {
            types.push(PolicyAdditionalInterestEnums.Type.LOSS_PAYEE);
        }
        if (additionalInterest.isMortgagee) {
            types.push(PolicyAdditionalInterestEnums.Type.MORTGAGEE);
        }
        if (additionalInterest.isMotorCarrier) {
            types.push(PolicyAdditionalInterestEnums.Type.MOTOR_CARRIER);
        }
        if (additionalInterest.isPrimaryAndNonContributory) {
            types.push(PolicyAdditionalInterestEnums.Type.PRIMARY_NON_CONTRIBUTORY);
        }
        if (additionalInterest.isWaiverOfSubrogation) {
            types.push(PolicyAdditionalInterestEnums.Type.WAIVER_OF_SUBROGATION);
        }
        if (additionalInterest.isCancelNotice30Day) {
            types.push(PolicyAdditionalInterestEnums.Type.CANCEL_NOTICE_30_DAY);
        }
        return types;
    }

    static getAdditionalInterestTypeAbbrevioations(
        additionalInterest: PolicyAdditionalInterest
    ): PolicyAdditionalInterestEnums.Abbreviation[] {
        if (!additionalInterest) return [];
        const abbrs: PolicyAdditionalInterestEnums.Abbreviation[] = [];
        if (additionalInterest.isAdditionalInsured) {
            abbrs.push(PolicyAdditionalInterestEnums.Abbreviation.ADDITIONL_INSURED);
        }
        if (additionalInterest.isAdditionalNamedInsured) {
            abbrs.push(PolicyAdditionalInterestEnums.Abbreviation.ADDITIONAL_NAMED_INSURED);
        }
        if (additionalInterest.isLossPayee) {
            abbrs.push(PolicyAdditionalInterestEnums.Abbreviation.LOSS_PAYEE);
        }
        if (additionalInterest.isMortgagee) {
            abbrs.push(PolicyAdditionalInterestEnums.Abbreviation.MORTGAGEE);
        }
        if (additionalInterest.isMotorCarrier) {
            abbrs.push(PolicyAdditionalInterestEnums.Abbreviation.MOTOR_CARRIER);
        }
        if (additionalInterest.isPrimaryAndNonContributory) {
            abbrs.push(PolicyAdditionalInterestEnums.Abbreviation.PRIMARY_NON_CONTRIBUTORY);
        }
        if (additionalInterest.isWaiverOfSubrogation) {
            abbrs.push(PolicyAdditionalInterestEnums.Abbreviation.WAIVER_OF_SUBROGATION);
        }
        if (additionalInterest.isCancelNotice30Day) {
            abbrs.push(PolicyAdditionalInterestEnums.Abbreviation.CANCEL_NOTICE_30_DAY);
        }
        return abbrs;
    }

    static getAppliedCoverages(additionalInterest: PolicyAdditionalInterest): string[] {
        return additionalInterest.coveragesAppliedTo.map((c) => {
            return CoverageOption.getMetadata(c.coverageOptionId).name;
        });
    }

    static #getAppliedCoveragesAsString(additionalInterest: PolicyAdditionalInterest): string {
        return PolicyAdditionalInterest.getAppliedCoverages(additionalInterest).join(", ");
    }

    static getName(additionalInterest: PolicyAdditionalInterest): string {
        if (!additionalInterest) return "Additional Interest";
        return additionalInterest.isBlanket ? "Blanket" : additionalInterest.additionalInterest?.name || "Additional Interest";
    }

    static compare({
        base,
        compare,
    }: {
        base?: PolicyAdditionalInterest;
        compare?: PolicyAdditionalInterest;
    }): PolicyAdditionalInterest.IComparisonReturn {
        const comparison = new Comparison(PolicyAdditionalInterest, base, compare) as PolicyAdditionalInterest.IComparisonReturn;
        comparison.setField("additionalInterestId", compare?.additionalInterestId || base?.additionalInterestId);
        comparison.setField("additionalInterest", compare?.additionalInterest || base?.additionalInterest);

        if (!base) {
            return comparison.setNew({
                obj: compare,
                description: `Add ${PolicyAdditionalInterest.getName(
                    compare
                )} as ${PolicyAdditionalInterest.#getAdditionalInterestTypesAsString(compare)}`,
                subComparison: comparison as Comparison<unknown>,
                label: "Additional Interest",
            });
        }

        if (!compare) {
            return comparison.setDelete({
                obj: base,
                description: `Delete ${PolicyAdditionalInterest.getName(base)} additional interest`,
                subComparison: comparison as Comparison<unknown>,
                label: "Additional Interest",
            });
        }

        [
            {
                key: "isBlanket",
                label: "Blanket",
            },
            {
                key: "isAdditionalInsured",
                label: "Additional Insured",
            },
            {
                key: "isAdditionalNamedInsured",
                label: "Additional Named Insured",
            },
            {
                key: "isLossPayee",
                label: "Loss Payee",
            },
            {
                key: "isMortgagee",
                label: "Mortgagee",
            },
            {
                key: "isMotorCarrier",
                label: "Motor Carrier",
            },
            {
                key: "isPrimaryAndNonContributory",
                label: "Primary and Non-Contributory",
            },
            {
                key: "isWaiverOfSubrogation",
                label: "Waiver of Subrogation",
            },
            {
                key: "isCancelNotice30Day",
                label: "30 Day Cancellation Notice",
            },
        ].forEach(({ key, label }) => {
            if (base[key] !== compare[key]) {
                const type = compare[key] ? "add" : "remove";
                const description =
                    type === "add"
                        ? `Add ${label} to ${compare.additionalInterest.name}`
                        : `Remove ${label} from ${compare.additionalInterest.name}`;
                comparison.addDiff({
                    type,
                    description,
                    label,
                    priority: null,
                    fieldName: key as keyof PolicyAdditionalInterest,
                    isArrayField: false,
                    value: {
                        from: base[key],
                        to: compare[key],
                        base,
                        compare,
                    },
                });
            }
        });

        Comparison.compareArrayFields<PolicyAdditionalInterest, "coveragesAppliedTo">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "coveragesAppliedTo",
            fieldKey: "coverageOptionId",
            itemDescriptionName: "Applied Coverage",
            getItemDescription(entity) {
                return CoverageOption.getMetadata(entity.coverageOptionId)?.name;
            },
        });

        Comparison.compareArrayFields<PolicyAdditionalInterest, "linkedTractors">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "linkedTractors",
            fieldKey: "tractorId",
            itemDescriptionName: "Tractor",
            getItemDescription(entity) {
                return entity.toString();
            },
        });

        Comparison.compareArrayFields<PolicyAdditionalInterest, "linkedTrailers">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "linkedTrailers",
            fieldKey: "trailerId",
            itemDescriptionName: "Trailer",
            getItemDescription(entity) {
                return entity.toString();
            },
        });

        Comparison.compareArrayFields<PolicyAdditionalInterest, "linkedBusinessAutos">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "linkedBusinessAutos",
            fieldKey: "businessAutoId",
            itemDescriptionName: "Bus Auto",
            getItemDescription(entity) {
                return entity.toString();
            },
        });

        Comparison.compareArrayFields<PolicyAdditionalInterest, "linkedTools">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "linkedTools",
            fieldKey: "toolId",
            itemDescriptionName: "Tool",
            getItemDescription(entity) {
                return entity.toString();
            },
        });

        Comparison.compareArrayFields<PolicyAdditionalInterest, "linkedProperties">({
            comparison,
            baseEntity: base,
            compareEntity: compare,
            fieldName: "linkedProperties",
            fieldKey: "propertyId",
            itemDescriptionName: "Location",
            getItemDescription(entity) {
                return entity.toString();
            },
        });

        return comparison;
    }

    id: number;
    createdDate: Date;

    policyId: string;
    policyCreatedDate: Date;
    policy?: Policy;

    additionalInterestId: number;
    additionalInterest?: GlobalCertificateHolder;

    sortOrder: number;
    isBlanket: boolean;
    isAdditionalInsured: boolean;
    isAdditionalNamedInsured: boolean;
    isLossPayee: boolean;
    isMortgagee: boolean;
    isMotorCarrier: boolean;
    isPrimaryAndNonContributory: boolean;
    isWaiverOfSubrogation: boolean;
    isCancelNotice30Day: boolean;
    @Validate(AppliedCoveragesValidator, {
        always: true,
        context: { fieldNameOverride: "appliesToCoverages" },
    })
    coveragesAppliedTo?: Pick<Coverage, "id" | "coverageOptionId" | "createdDate">[];

    @Type(() => PolicyAdditionalInterestLinkedBusinessAuto)
    linkedBusinessAutos?: PolicyAdditionalInterestLinkedBusinessAuto[];

    @Type(() => PolicyAdditionalInterestLinkedTrailer)
    linkedTrailers?: PolicyAdditionalInterestLinkedTrailer[];

    @Type(() => PolicyAdditionalInterestLinkedTractor)
    linkedTractors?: PolicyAdditionalInterestLinkedTractor[];

    @Type(() => PolicyAdditionalInterestLinkedTool)
    linkedTools?: PolicyAdditionalInterestLinkedTool[];

    @Type(() => PolicyAdditionalInterestLinkedProperty)
    linkedProperties?: PolicyAdditionalInterestLinkedProperty[];

    metadata?: Record<string, unknown>;

    toString(): string {
        const types = PolicyAdditionalInterest.#getAdditionalInterestTypesAsString(this);
        const coverages = PolicyAdditionalInterest.#getAppliedCoveragesAsString(this);
        return `PolicyAdditionalInterest: ${this.additionalInterest?.name}, ${types}, ${coverages}`;
    }
}

export declare namespace PolicyAdditionalInterest {
    export interface LinkedItem {
        id: number;
        effectiveDate?: Date;
        createdDate: Date;
    }

    export type Type = PolicyAdditionalInterestEnums.Type;

    export interface IComparisonReturn extends Comparison<PolicyAdditionalInterest> {
        additionalInterestId: number;
        additionalInterest?: GlobalCertificateHolder;
    }
}
