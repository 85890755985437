import src from "../../assets/images/not-found.svg";
import BaseErrorScreen from "./BaseErrorScreen";

export default function ServerDownError() {
    return (
        <BaseErrorScreen
            title="Our server is in the shop"
            description="The server is currently down for maintenance. We'll be back on the road shortly."
            primaryAction={null}
            image={<img src={src} alt="empty road" className="hidden w-1/3 lg:block" />}
        />
    );
}
