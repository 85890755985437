import React from "react";

import type { MotorCarrier } from "@deathstar/motor-carrier";

import { config } from "./ChartConfig";

const INCONCLUSIVE = "Inconclusive";

export function ColorLegend({
    motorCarrier,
    children,
}: {
    motorCarrier: MotorCarrier;
    children?: (legend: ReturnType<typeof useLegend>) => React.ReactNode;
}): JSX.Element {
    const legend = useLegend({ motorCarrier });

    if (children) return <>{children(legend)}</>;

    return (
        <ul className="flex list-none flex-col justify-center gap-2">
            {legend.map((l) => (
                <li key={l.name} className="flex items-center gap-2">
                    <div
                        className="inline-block h-5 w-5 rounded-full"
                        style={{
                            backgroundColor: l.color,
                        }}
                    ></div>
                    <p>
                        {l.name} - {l.score.formatted}
                    </p>
                </li>
            ))}
        </ul>
    );
}

function useLegend({ motorCarrier }: { motorCarrier: MotorCarrier }) {
    return React.useMemo(() => {
        return config.order.map((basic) => {
            const score = motorCarrier.smsResults.latest.getBasic(basic).score;

            return {
                name: basic,
                score:
                    typeof score === "number" && !globalThis.isNaN(score)
                        ? {
                              raw: score,
                              formatted: `${score}%`,
                          }
                        : {
                              raw: NaN,
                              formatted: INCONCLUSIVE,
                          },
                color: config.colors[basic],
            };
        });
    }, [motorCarrier.smsResults.latest]);
}
