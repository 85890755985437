import { IsString, Matches, IsInt } from "class-validator";
import { User } from "./User";
import type { Widget } from "./Widget";

export class WidgetAttachment {
    id: number;

    createDate: Date;

    updateDate: Date;

    deleteDate: Date;

    updatedByGuid: string;

    updatedBy: User;

    deletedByGuid: string;

    deletedBy: User;

    @IsString()
    attachmentId: string;

    @IsInt()
    widgetId: number;

    widget: Widget;

    @IsString()
    @Matches(/^(contact)|(email)|(document)$/)
    type: "contact" | "email" | "document";

    @IsString()
    createdByGuid: string;

    createdBy: User;
}
