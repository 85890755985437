import { FMCSA } from "@deathstar/types";
import { Inspection } from "../../Inspection/Inspection";
import { SafetyEventGroup } from "../../SafetyEventGroup/SafetyEventGroup";
import { Basic } from "../Basic";

export class DriverFitness extends Basic<SafetyEventGroup.GroupNames.DriverFitness> {
    readonly isBestAddressedRoadside = true;

    // ========================================================================
    static get thresholdDefinition(): Basic.IThresholdDefinition {
        return {
            passenger: 65,
            hazmat: 75,
            general: 80,
        };
    }

    // ========================================================================
    constructor(options: DriverFitness.Options) {
        super({
            ...options,
            safetyEventGroup: new SafetyEventGroup.Group.DriverFitness(options.carrier, options.totalDriverInspections),
            basicName: FMCSA.BasicName.DRIVER_FITNESS,
            relevantInspectionDefinition: {
                levels: [1, 2, 3, 6],
                type: Inspection.Type.DRIVER,
            },
        });
    }

    // ========================================================================
    override getThresholdDefinition(): Basic.IThresholdDefinition {
        return DriverFitness.thresholdDefinition;
    }

    // ========================================================================
    override getFmcsaCalculatedMeasure(): number | null {
        return typeof this.rawSmsData?.DriverFitnessMeasure === "number" ? this.rawSmsData?.DriverFitnessMeasure : null;
    }

    // ========================================================================
    override getVariableData(): Record<string, string | number> {
        return {
            totalViolations: this.getTotalNumberOfViolations(),
            totalWeightOfApplicableViolations: this.getTotalWeightOfApplicableViolations(),
            totalTimeWeightOfRelevantInspections: this.getTotalTimeWeightOfRelevantInspections(),
        };
    }

    // ========================================================================
    protected override getMeasure(): number {
        if (typeof this.rawSmsData?.DriverFitnessMeasure === "number" && !this.forceCalculateBasicMeasures) {
            return this.rawSmsData?.DriverFitnessMeasure;
        }

        return this.calculateMeasure();
    }

    // ========================================================================
    protected override async getScore(): Promise<number | null> {
        if (typeof this.rawSmsData?.DriverFitnessScore === "number" && !this.forceCalculateBasicScores) {
            return this.rawSmsData?.DriverFitnessScore;
        }

        return this.calculateScore();
    }

    // ========================================================================
    protected override async calculateScore(): Promise<number | null> {
        if (
            this.measure === 0 ||
            this.safetyEventGroup.number === 0 ||
            !this.safetyEventGroup.name ||
            this.smsResult.totalDriverInspections < 5
        )
            return 0;

        /*
            Get the total number of Driver Fitness violations
        */
        const totalDriverFitnessViolations = this.getTotalNumberOfViolations();

        if (totalDriverFitnessViolations === 0) return 0;

        /*
            Get the total number of inspections with a Driver Fitness violation
        */
        const totalDriverFitnessInspectionsWithViolation = this.getTotalInspectionsWithViolation();

        if (totalDriverFitnessInspectionsWithViolation < Basic.getMinimumRelevant(FMCSA.BasicName.DRIVER_FITNESS)) return 0;

        /*
            Get the total Driver Fitness violations within the previous year
        */
        const totalDriverFitnessViolationsWithinPreviousYear = this.getTotalViolationsWithinYear();

        if (totalDriverFitnessViolationsWithinPreviousYear === 0) {
            const latestRelevantDriverInspectionIncludesViolation = this.getLatestRelevantInspectionIncludesViolation();

            if (!latestRelevantDriverInspectionIncludesViolation) return 0;
        }

        return this.fetchSmsScore();
    }
}

export namespace DriverFitness {
    export interface Options extends Basic.BaseOptions {
        totalDriverInspections: number;
    }
}
