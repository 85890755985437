export const EARTH_RADIUS = 3959; // mean radius in miles

export const STATE_FIPS: Record<string, { abbr: string; name: string }> = {
    "02": { abbr: "AK", name: "Alaska" },
    "01": { abbr: "AL", name: "Alabama" },
    "05": { abbr: "AR", name: "Arkansas" },
    "60": { abbr: "AS", name: "American Samoa" },
    "04": { abbr: "AZ", name: "Arizona" },
    "06": { abbr: "CA", name: "California" },
    "08": { abbr: "CO", name: "Colorado" },
    "09": { abbr: "CT", name: "Connecticut" },
    "11": { abbr: "DC", name: "District of Columbia" },
    "10": { abbr: "DE", name: "Delaware" },
    "12": { abbr: "FL", name: "Florida" },
    "13": { abbr: "GA", name: "Georgia" },
    "15": { abbr: "HI", name: "Hawaii" },
    "19": { abbr: "IA", name: "Iowa" },
    "16": { abbr: "ID", name: "Idaho" },
    "17": { abbr: "IL", name: "Illinois" },
    "18": { abbr: "IN", name: "Indiana" },
    "20": { abbr: "KS", name: "Kansas" },
    "21": { abbr: "KY", name: "Kentucky" },
    "22": { abbr: "LA", name: "Louisiana" },
    "25": { abbr: "MA", name: "Massachusetts" },
    "23": { abbr: "ME", name: "Maine" },
    "26": { abbr: "MI", name: "Michigan" },
    "27": { abbr: "MN", name: "Minnesota" },
    "28": { abbr: "MS", name: "Mississippi" },
    "30": { abbr: "MT", name: "Montana" },
    "37": { abbr: "NC", name: "North Carolina" },
    "38": { abbr: "ND", name: "North Dakota" },
    "31": { abbr: "NE", name: "Nebraska" },
    "33": { abbr: "NH", name: "New Hampshire" },
    "34": { abbr: "NJ", name: "New Jersey" },
    "35": { abbr: "NM", name: "New Mexico" },
    "32": { abbr: "NV", name: "Nevada" },
    "36": { abbr: "NY", name: "New York" },
    "39": { abbr: "OH", name: "Ohio" },
    "40": { abbr: "OK", name: "Oklahoma" },
    "41": { abbr: "OR", name: "Oregon" },
    "42": { abbr: "PA", name: "Pennsylvania" },
    "72": { abbr: "PR", name: "Puerto Rico" },
    "44": { abbr: "RI", name: "Rhode Island" },
    "46": { abbr: "SD", name: "South Dakota" },
    "47": { abbr: "TN", name: "Tennessee" },
    "48": { abbr: "TX", name: "Texas" },
    "49": { abbr: "UT", name: "Utah" },
    "51": { abbr: "VA", name: "Virginia" },
    "78": { abbr: "VI", name: "Virgin Islands" },
    "50": { abbr: "VT", name: "Vermont" },
    "53": { abbr: "WA", name: "Washington" },
    "55": { abbr: "WI", name: "Wisconsin" },
    "54": { abbr: "WV", name: "West Virginia" },
    "56": { abbr: "WY", name: "Wyoming" },
    "29": { abbr: "MO", name: "Missouri" },
    "45": { abbr: "SC", name: "South Carolina" },
    "66": { abbr: "GU", name: "Guam" },
    "69": { abbr: "MP", name: "Northern Marianas" },
    "24": { abbr: "MD", name: "Maryland" },
};
