import type { MotorCarrier } from "../MotorCarrier";
import { InsuranceHistoryEntry } from "./InsuranceHistoryEntry";

// ========================================================================
export class InsuranceHistory {
    // ========================================================================
    static sort(inspections: InsuranceHistoryEntry[], order: "ASC" | "DESC"): InsuranceHistoryEntry[] {
        const sortFn = InsuranceHistoryEntry.sorter(order);
        return inspections.sort(sortFn);
    }

    // ========================================================================
    #carrier: MotorCarrier;

    #entries: InsuranceHistoryEntry[] = [];
    constructor(carrier: MotorCarrier, raw: InsuranceHistoryEntry.Raw[]) {
        this.#carrier = carrier;

        this.#entries = raw.map((entry) => new InsuranceHistoryEntry(carrier, entry));
        this.sort();
    }

    // ========================================================================
    sort(order: "ASC" | "DESC" = "DESC"): void {
        InsuranceHistory.sort(this.#entries, order);
    }

    // ========================================================================
    array(): InsuranceHistoryEntry[] {
        return Array.from(this.#entries) ?? [];
    }

    // ========================================================================
    json(): InsuranceHistoryEntry.Raw[] {
        return this.#entries.map((entry) => entry.json());
    }

    // ========================================================================
    raw(): InsuranceHistoryEntry.Raw[] {
        return this.#entries.map((entry) => entry.raw());
    }
}

export namespace InsuranceHistory {}
