import { MotorCarrier } from "@deathstar/motor-carrier";
import { DateTime } from "@deathstar/reuse";
import moment from "moment";

export function formatInspectionTime(insp: MotorCarrier.IInspection, value: "start" | "end"): string {
    const timeStr = insp.get(value === "start" ? "InspectionStartTime" : "InspectionEndTime")?.toString();
    if (!timeStr) return "";

    return DateTime.convert24HrTimeTo12HrTime(timeStr);
}

export function getTotalMinutesOfInspection(insp: MotorCarrier.IInspection): string {
    const startRaw = insp.get("InspectionStartTime")?.toString(),
        endRaw = insp.get("InspectionEndTime")?.toString();
    if (!startRaw || !endRaw) return "";

    const startArr = Array.from(startRaw);
    if (startArr.length === 3) {
        startArr.unshift("0");
    }
    startArr.splice(2, 0, ":");
    const startParsed = startArr.join("");

    const endArr = Array.from(endRaw);
    if (endArr.length === 3) {
        endArr.unshift("0");
    }
    endArr.splice(2, 0, ":");
    const endParsed = endArr.join("");

    const start = moment.utc(startParsed, "HH:mm");
    const end = moment.utc(endParsed, "HH:mm");

    return moment.utc(+moment.duration(end.diff(start))).format("mm");
}
