import { MotorCarrier } from "@deathstar/motor-carrier";
import React from "react";
import { MapLocationType, USMapLocation } from "../Map/types";
import { useMap } from "../Map/useMap";
import { pluralize } from "../utils/pluralize";

export function useInspectionMap({ motorCarrier, setFilter }: { motorCarrier: MotorCarrier; setFilter: (filter?: string) => void }) {
    const locations: USMapLocation[] = React.useMemo(() => {
        const locs: USMapLocation[] = [];

        if (motorCarrier.details.PhysicalCountyLatitude && motorCarrier.details.PhysicalCountyLongitude) {
            locs.push({
                lat: parseFloat(motorCarrier.details.PhysicalCountyLatitude),
                lng: parseFloat(motorCarrier.details.PhysicalCountyLongitude),
                type: MapLocationType.PrimaryTerminal,
                tooltip: "Primary Terminal",
            });
        }

        if (motorCarrier.inspections.total) {
            const plots = Array.from(motorCarrier.inspections).reduce((map, insp) => {
                const lat = insp.get("CountyLatitude");
                const long = insp.get("CountyLongitude");

                if (!lat || !long) {
                    console.warn("Missing lat or long for inspection", insp);
                }
                const key = `${lat},${long}`;
                const current = map.get(key)?.total || 0;
                map.set(key, {
                    lat,
                    long,
                    total: current + 1,
                });
                return map;
            }, new Map<string, { lat: string; long: string; total: number }>());

            Array.from(plots.values())
                .sort((a, b) => b.total - a.total)
                .forEach(({ lat, long, total }) => {
                    if (!lat || !long) return;
                    locs.push({
                        lat: parseFloat(lat as string),
                        lng: parseFloat(long as string),
                        // type: MapLocationType.Other,
                        // color: "#0e324d",
                        // color: "#00fff3",
                        color: "orange",
                        size: getPlotRadius(total),
                        onLocationClick(lat, _lng) {
                            setFilter(lat.toString());
                        },
                        tooltip: `${total} ${pluralize(total, "inspection", "s")}`,
                    });
                });
        }
        return locs;
    }, [motorCarrier.details.PhysicalCountyLatitude, motorCarrier.details.PhysicalCountyLongitude, motorCarrier.inspections, setFilter]);

    const mapController = useMap({
        locations,
    });

    return { mapController };
}

const PLOT_SIZES = [
    [1, 1, 4],
    [2, 5, 6],
    [6, 9, 10],
    [10, 19, 15],
    [20, Infinity, 20],
];

function getPlotRadius(total: number): number {
    for (const [min, max, size] of PLOT_SIZES) {
        if (total >= min && total <= max) {
            return size;
        }
    }

    return 3;
}
