import { MotorCarrier } from "../MotorCarrier";
import { Unit } from "../Unit/Unit";
import { Violations } from "../Violations/Violations";

export class Units {
    // ========================================================================
    static of(carrier: MotorCarrier, units?: Unit[], violations?: Violations): Units {
        const newUnits = new Units(carrier);
        if (units) {
            newUnits.#addUnits(units.map((unit) => Unit.from(unit, violations)));
        }
        return newUnits;
    }

    // ========================================================================
    static from(units: Units, violations?: Violations): Units {
        const newUnits = new Units(units.#carrier);
        if (units.#units) {
            newUnits.#addUnits(units.#units.map((unit) => Unit.from(unit, violations)));
        }
        return newUnits;
    }

    // ========================================================================
    static new(carrier: MotorCarrier): Units {
        const { rawInspections, rawUnits } = MotorCarrier.getCachedFetcherResults(carrier);
        if (!rawInspections) throw new Error("Raw Inspections not found");
        if (!rawUnits) throw new Error("Raw Units not found");

        const newUnits = new Units(carrier);

        const rawFmcsaUnitsMap = MotorCarrier.getRawFmcsaUnits(carrier);

        const units = rawUnits.map((rawUnit) => {
            const fmcsaData = rawFmcsaUnitsMap.get(rawUnit.VIN)!;
            return Unit.new({
                vin: rawUnit.VIN,
                rawUnit,
                units: newUnits,
                carrier,
                fmcsa: {
                    license: fmcsaData?.UnitLicense,
                    make: fmcsaData?.UnitMake,
                    number: fmcsaData?.UnitDecalNumber ?? "",
                    type: fmcsaData?.UnitType,
                },
            });
        });

        newUnits.#addUnits(units);
        return newUnits;
    }

    // ========================================================================
    #carrier: MotorCarrier;
    #unitVins: Set<string> = new Set();
    #units: Unit[] = [];
    private constructor(carrier: MotorCarrier) {
        this.#carrier = carrier;
    }

    // ========================================================================
    *[Symbol.iterator](): IterableIterator<Unit> {
        if (!this.#units) return [];
        for (const unit of this.#units) {
            yield unit;
        }
    }

    // ========================================================================
    #addUnits(units: Unit[]): void {
        units.forEach((unit) => {
            if (this.#unitVins.has(unit.key)) return;
            this.#unitVins.add(unit.key);
            this.#units.push(unit);
        });
    }

    // ========================================================================
    get total(): number {
        return this.#units?.length ?? 0;
    }

    // ========================================================================
    has(unit: Unit): boolean {
        return this.#unitVins.has(unit.key);
    }

    // ========================================================================
    findByVin(vin: string): Unit | undefined {
        return this.#units?.find((unit) => unit.vin === vin);
    }

    // ========================================================================
    findByLicense(license: string): Unit | undefined {
        return this.#units?.find((unit) => unit.license === license);
    }

    // ========================================================================
    find({ vin, license }: { vin: string | undefined; license: string | undefined }): Unit | undefined {
        return this.#units?.find((unit) => {
            if (vin && vin === unit.vin && license && license === unit.license) return unit;
            return null;
        });
    }

    // ========================================================================
    get vinNumbers(): Set<string> {
        return Array.from(this.#unitVins).reduce((set, key) => {
            const [vin] = key.split("-");
            set.add(vin);
            return set;
        }, new Set<string>());
    }

    // ========================================================================
    /**
     * Returns the `Unit`s with the largest share of the `MotorCarrier`'s Total Score
     * @param max the maximum number of units to return, default is 7
     */
    sortByPercentOfScore(sortOrder: "ASC" | "DESC" = "DESC"): this {
        this.#units?.sort((unitA, unitB) => {
            if (unitA.percentOfTotalScore < unitB.percentOfTotalScore) {
                return sortOrder === "ASC" ? -1 : 1;
            }
            if (unitA.percentOfTotalScore > unitB.percentOfTotalScore) {
                return sortOrder === "ASC" ? 1 : -1;
            }
            return 0;
        });

        return this;
    }

    // ========================================================================
    sortByViolationCount(sortOrder: "ASC" | "DESC" = "DESC"): this {
        this.#units?.sort((unitA, unitB) => {
            const unitAViolations = unitA.violations;
            const unitBViolations = unitB.violations;
            if (unitAViolations.total < unitBViolations.total) {
                return sortOrder === "ASC" ? -1 : 1;
            }
            if (unitAViolations.total > unitBViolations.total) {
                return sortOrder === "ASC" ? 1 : -1;
            }
            return 0;
        });

        return this;
    }

    // ========================================================================
    #filteredByCategory?: {
        tractor: Units;
        trailer: Units;
        unknown: Units;
    };
    #calculateFilteredByCategory(): {
        tractor: Units;
        trailer: Units;
        unknown: Units;
    } {
        this.#filteredByCategory = (this.#units ?? []).reduce(
            (obj, unit) => {
                switch (unit.category) {
                    case Unit.Category.TRACTOR:
                        obj.tractor.#addUnits([unit]);
                        break;
                    case Unit.Category.TRAILER:
                        obj.trailer.#addUnits([unit]);
                        break;
                    default:
                        obj.unknown.#addUnits([unit]);
                        break;
                }

                return obj;
            },
            {
                tractor: new Units(this.#carrier),
                trailer: new Units(this.#carrier),
                unknown: new Units(this.#carrier),
            }
        );
        return this.#filteredByCategory;
    }
    /**
     * Filters the units by category (Tractor, Trailer, Unknown)
     */
    filterByCategory(): {
        tractor: Units;
        trailer: Units;
        unknown: Units;
    } {
        return this.#filteredByCategory ?? this.#calculateFilteredByCategory();
    }

    // ========================================================================
    take(amount: number): Units {
        const newUnits = new Units(this.#carrier);
        newUnits.#addUnits(Array.from(this).slice(0, amount));
        return newUnits;
    }

    // ========================================================================
    array(): Unit[] {
        return Array.from(this.#units) ?? [];
    }

    // ========================================================================
    json(): Unit.JSON[] {
        return this.#units?.map((unit) => unit.json()) ?? [];
    }

    // ========================================================================
    raw(): Unit.Raw[] {
        return this.#units?.map((unit) => unit.raw()) ?? [];
    }
}

// ========================================================================
export namespace Units {}
