import { Type } from "class-transformer";
import { IsBoolean, IsOptional } from "class-validator";
import { Account, User } from ".";

export class PropertyType {
    id: number;
    name: string;
}

export class Property {
    id: number;
    isDeleted: boolean;

    accountId: string;
    account: Account;

    createdByGuid: string;
    createdBy: User;

    effectiveDate: Date;

    locationNumber: number;

    types: PropertyType[];
    typeString?: string;

    latitude?: number;
    longitude?: number;

    street1: string;
    street2: string;
    buildingCode: string; // ie "1", "1A", "A", etc.
    city: string;
    state: string;
    zip: string;

    statedValue: number;
    personalPropertyValueBusiness: number;
    personalPropertyValueOther: number;
    businessIncome: number;
    extraExpenses: number;
    electricalDataProcessingHardwareValue: number;
    electricalDataProcessingSoftwareValue: number;

    constructionType: BuildingConstructionType;
    constructionTypeId: BuildingConstructionType["id"];

    roofType: BuildingRoofType;
    roofTypeId: BuildingRoofType["id"];

    yearBuilt: number;
    stories: number;
    hasBasement: boolean;
    totalAreaSquareFeet: number;
    occupiedPercent: number;

    improvementWiringYear: number;
    improvementRoofingYear: number;
    improvementPlumbingYear: number;
    improvementHeatingYear: number;

    @IsOptional()
    @IsBoolean()
    @Type(() => Boolean)
    sprinklerInstalled: boolean;

    sprinklerCoveragePercent: number;
    fireHydrantDistanceFeet: number;
    fireStationDistanceMiles: number;

    alarmFireType: BuildingAlarmFireType;
    alarmFireTypeId: BuildingAlarmFireType["id"];

    alarmBurglarType: BuildingAlarmBurglarType;
    alarmBurglarTypeId: BuildingAlarmBurglarType["id"];

    leftExposureDescription: string;
    leftExposureDistanceFeet: number;
    rightExposureDescription: string;
    rightExposureDistanceFeet: number;
    frontExposureDescription: string;
    frontExposureDistanceFeet: number;
    rearExposureDescription: string;
    rearExposureDistanceFeet: number;

    toString(): string {
        const parts: string[] = [];
        if (this.street1) parts.push(this.street1);
        if (this.street2) parts.push(this.street2);
        if (this.buildingCode) parts.push(this.buildingCode);
        if (this.city) parts.push(this.city);
        if (this.state) parts.push(this.state);
        if (this.zip) parts.push(this.zip);
        return parts.join(" ");
    }
}

export class BuildingConstructionType {
    id: number;
    name: string;
}

export class BuildingRoofType {
    id: number;
    name: string;
}

export class BuildingAlarmBurglarType {
    id: number;
    name: string;
}

export class BuildingAlarmFireType {
    id: number;
    name: string;
}
