import {
    Account,
    AccountEquipment,
    AccountEquipmentStatus,
    CoverageLinkedTool,
    CoverageOption,
    PolicyAdditionalInterest,
    Property,
    User,
} from ".";

export class InlandMarineEquipment {
    id: number;
    isDeleted: boolean;

    submit: boolean;

    accountId: string;
    account: Account;

    createdByGuid: string;
    createdBy: User;

    createDate: Date;
    updateDate: Date;

    year: number;

    make: string;

    model: string;

    description: string;

    serialNumber: string;

    value: number;
    acv: boolean;

    requiresApproval: boolean;
    requestComment: string;
    requestDate: string;
    isRequestNotificationSent: boolean;

    createdByWaypointUserId: string;

    propertyId: number;

    property: Property;

    coverageLinks?: CoverageLinkedTool[];
    additionalInterests?: PolicyAdditionalInterest[];

    policyUpcomingDate?: string | null | undefined;
    policyValue?: number | null | undefined;
    policyAcv?: boolean | null | undefined;
    statuses?: AccountEquipmentStatus[];
    coverageOptions?: CoverageOption[];

    toString(): string {
        const _parts: (number | string)[] = [];
        if (this.year) _parts.push(this.year);
        if (this.make) _parts.push(this.make);
        if (this.model) _parts.push(this.model);
        if (this.description) _parts.push(this.description);
        if (this.serialNumber) _parts.push(this.serialNumber);

        return _parts.join(" ");
    }
}

export type AccountTools = AccountEquipment<
    InlandMarineEquipment & {
        policyValue: number;
        policyAcv: boolean;
    }
>;
