import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { To, useNavigate } from "react-router-dom";

/**
 * A button that goes back one page (`history.back()`). If going back would leave the site
 * then redirect to `fallback` instead.
 *
 * @see https://github.com/remix-run/react-router/discussions/9922 for examples of this and to see if
 * it has been implemented natively into react-router-dom.
 */
export default function BackButton({ fallback = ".." }: { fallback?: To }) {
    const navigate = useNavigate();
    return (
        <button
            onClick={() => {
                if (window.history.state.idx) {
                    navigate(-1);
                } else {
                    navigate(fallback);
                }
            }}
            className="flex items-center gap-1 mb-4 text-stone-600 hover:text-navigator-primary w-fit"
        >
            <ChevronLeftIcon className="w-4 h-4" />
            Back
        </button>
    );
}
