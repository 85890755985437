import { Column, Row } from "@tanstack/react-table";
import { FilterButton, FilterButtonProps } from "../filterButton/FilterButton";

export interface ColumnFilterButtonProps<RowData = unknown, ColumnData = unknown>
    extends Pick<FilterButtonProps<ColumnData>, "renderValue"> {
    column: Column<RowData, ColumnData>;
    Menu: (props: { value: ColumnData; onChange(value: ColumnData): void }) => JSX.Element;
    label?: string;
}

export function ColumnFilterButton<RowData = unknown, ColumnData = unknown>({
    column,
    label,
    Menu,
    renderValue,
}: ColumnFilterButtonProps<RowData, ColumnData>) {
    return (
        <FilterButton<ColumnData>
            value={column.getFilterValue() as ColumnData}
            Menu={Menu}
            label={label || ""}
            onChange={(v) => column.setFilterValue(v)}
            onClear={() => column.setFilterValue(undefined)}
            renderValue={renderValue}
        />
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterFn(row: Row<any>, columnId: string, filterValue: any): boolean {
    return row.getValue(columnId) === filterValue;
}

ColumnFilterButton.filterFn = filterFn;

export interface ColumnOptionFilterButton {
    filterFn: typeof filterFn;
}
