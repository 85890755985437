import { DriverOfTheYear, DriverOfTheYearDto, SubscriptionTier } from "@deathstar/types/waypoint";
import { Collapse, InputFormatter, PhoneTemplate, PrimaryButton } from "@deathstar/ui";
import QueryString from "qs";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";
import { Provinces, States, Territories } from "../../util/constants";

export function DriverOfTheYearForm() {
    const accountId = useAccountId();
    const { data: drivers } = api.drivers.useFind(accountId!);
    const { data: organization } = api.organization.useById(accountId!);

    const form = useForm<DriverOfTheYearDto>({
        defaultValues: {
            size: DriverOfTheYear.Size.L,
            addressCountry: "USA",
            phone: "",
        },
    });

    const { mutate } = api.driverOfTheYear.useCreate(accountId!, {
        onError: (error) => {
            console.error(error);
            form.setError("root", { message: "Could not process order. Please try again later." });
        },
    });

    const driverId = form.watch("driverId");
    const errors = Object.values(form.formState.errors);

    return (
        <form
            className="max-w-md"
            onSubmit={form.handleSubmit((data) => {
                mutate(data);
            })}
        >
            <div className="space-y-2">
                <select
                    {...form.register("driverId", { valueAsNumber: true, required: { value: true, message: "Driver is required" } })}
                    className="form-select w-full rounded-lg border-waypoint-blue text-lg shadow-lg transition-all hover:shadow-md"
                >
                    <option value="">Choose a winner for {new Date().getUTCFullYear()}...</option>
                    {drivers?.map((driver) => (
                        <option key={driver.id} value={driver.id}>
                            {driver.name}
                        </option>
                    ))}
                </select>
                <Collapse isOpen={!!driverId}>
                    <div className="relative space-y-4 p-4">
                        {organization?.activeSubscriptionTier !== SubscriptionTier.Enterprise && (
                            <div className="absolute inset-0 z-10 bg-white/50">
                                <div className="flex h-full w-full items-center justify-center">
                                    <div className="mx-2 space-y-4 rounded-lg bg-stone-50 p-4 shadow-lg">
                                        <p>
                                            This is an exclusive feature available only for <span className="font-medium">Enterprise</span>{" "}
                                            subscribers
                                        </p>
                                        <div className="flex justify-center">
                                            <Link
                                                to={{
                                                    pathname: "../../settings/subscription",
                                                    search: QueryString.stringify({ purchase: SubscriptionTier.Enterprise }),
                                                }}
                                            >
                                                <PrimaryButton>
                                                    {organization?.activeSubscriptionTier ? "Upgrade" : "Subscribe"} now
                                                </PrimaryButton>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="space-y-2">
                            <p>Jacket size</p>
                            <select
                                {...form.register("size", { required: { value: true, message: "Size is required" } })}
                                className="form-select w-full rounded-lg border-stone-300 shadow-sm lg:w-1/2"
                            >
                                <option value={DriverOfTheYear.Size.XS}>X-Small</option>
                                <option value={DriverOfTheYear.Size.S}>Small</option>
                                <option value={DriverOfTheYear.Size.M}>Medium</option>
                                <option value={DriverOfTheYear.Size.L}>Large</option>
                                <option value={DriverOfTheYear.Size.LT}>Large (Tall)</option>
                                <option value={DriverOfTheYear.Size.XL}>X-Large</option>
                                <option value={DriverOfTheYear.Size.XLT}>X-Large (Tall)</option>
                                <option value={DriverOfTheYear.Size.XXL}>2X-Large</option>
                                <option value={DriverOfTheYear.Size.XXLT}>2X-Large (Tall)</option>
                                <option value={DriverOfTheYear.Size.XXXL}>3X-Large</option>
                                <option value={DriverOfTheYear.Size.XXXLT}>3X-Large (Tall)</option>
                                <option value={DriverOfTheYear.Size.XXXXL}>4X-Large</option>
                            </select>
                        </div>

                        <div className="space-y-2">
                            <p>Shipping address</p>
                            <input
                                placeholder="Address line 1"
                                className="w-full rounded-lg border border-stone-300 p-2"
                                {...form.register("addressLine1", { required: { value: true, message: "Address Line 1 is required" } })}
                            />
                            <input
                                placeholder="Address line 2"
                                className="w-full rounded-lg border border-stone-300 p-2"
                                {...form.register("addressLine2")}
                            />
                            <input
                                placeholder="City"
                                className="w-full rounded-lg border border-stone-300 p-2"
                                {...form.register("addressCity", { required: { value: true, message: "City is required" } })}
                            />
                            <select
                                {...form.register("addressState", { required: { value: true, message: "State is required" } })}
                                className="form-select w-full rounded-lg border-stone-300 shadow-sm"
                            >
                                <option>State</option>
                                {States.map((state) => (
                                    <option key={state.abbr} value={state.abbr}>
                                        {state.name}
                                    </option>
                                ))}

                                {Territories.map((territory) => (
                                    <option key={territory.abbr} value={territory.abbr}>
                                        {territory.name}
                                    </option>
                                ))}

                                {Provinces.map((province) => (
                                    <option key={province.abbr} value={province.abbr}>
                                        {province.name}
                                    </option>
                                ))}
                            </select>
                            <input
                                placeholder="Postal code"
                                className="w-full rounded-lg border border-stone-300 p-2"
                                {...form.register("addressPostalCode", { required: { value: true, message: "Postal code is required" } })}
                            />
                            <select
                                {...form.register("addressCountry", { required: { value: true, message: "Country is required" } })}
                                className="form-select w-full rounded-lg border-stone-300 shadow-sm placeholder:text-gray-400"
                            >
                                {/* <option>Country</option> */}
                                <option value="USA">USA</option>
                                {/* <option value="CAN">Canada</option> */}
                            </select>
                        </div>
                        <div className="space-y-2">
                            <p>Contact number</p>
                            <div>
                                <Controller
                                    name="phone"
                                    control={form.control}
                                    render={({ field }) => (
                                        <PhoneInput value={field.value || ""} onChange={(value) => form.setValue(field.name, value)} />
                                    )}
                                />
                                <p className="ml-4 text-xs font-light text-stone-700">
                                    This number will only be contacted for shipment updates
                                </p>
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <PrimaryButton type="submit" className="">
                                Submit winner
                            </PrimaryButton>
                        </div>
                    </div>
                </Collapse>
                <p className="text-red-600">
                    {errors
                        .map((error) => error.message)
                        .filter(Boolean)
                        .join("; ")}
                </p>
            </div>
        </form>
    );
}

function PhoneInput({ value, onChange }: { value: string; onChange: (value: string) => void }) {
    const props = InputFormatter.use(value, onChange, PhoneTemplate);
    return (
        <div className="relative">
            <p className="absolute left-2 top-1/2 -translate-y-1/2">+1</p>
            <input {...props} className="w-full rounded-lg border border-stone-300 p-2 pl-8" placeholder="(888) 555-1234" />
        </div>
    );
}
