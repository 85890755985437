// Code used from Ben Honeywill
// @link https://benhoneywill.com/progressive-image-loading-with-react-hooks/

import { useEffect, useRef, useState } from "react";

export function useProgressiveImage(ref: React.MutableRefObject<HTMLImageElement | null>, highQualitySrc: string, lowQualitySrc: string) {
    const [blur, setBlur] = useState(true);
    const hasRun = useRef(false);
    useEffect(() => {
        if (!hasRun.current && ref.current) {
            ref.current.src = lowQualitySrc;
            hasRun.current = true;
            ref.current.onload = () => {
                if (ref.current) {
                    ref.current.src = highQualitySrc;
                    ref.current.onload = () => {
                        setBlur(false);
                    };
                }
            };
        }
    }, [highQualitySrc, lowQualitySrc, ref]);

    return blur;
}
