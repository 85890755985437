import { ActionButton, printElement } from "@deathstar/ui";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PrinterIcon } from "@heroicons/react/24/solid";
import { useRef } from "react";
import { Dialog } from "../../../components/dialog/Dialog";
import PolicyView from "../policy-view/PolicyView";

export default function PolicyViewDialog({ policyId, open, onClose }: { policyId: string; open: boolean; onClose(): void }) {
    const decPageRef = useRef<HTMLDivElement>(null);
    return (
        <Dialog open={open} onClose={onClose} className="h-screen w-max min-w-[calc(100vw-64px)] p-4 md:min-w-[800px]" style={{}}>
            <div className="flex w-full flex-col">
                <div className="flex items-start justify-between">
                    <ActionButton
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <XMarkIcon className="h-4 w-4 shrink-0" />
                        <span className="text-sm small-caps">Close</span>
                    </ActionButton>
                    <ActionButton
                        onClick={() => {
                            if (decPageRef.current) {
                                printElement(decPageRef.current);
                            }
                        }}
                        className="border-waypoint-blue-light text-waypoint-blue"
                    >
                        <PrinterIcon className="h-5 w-5 shrink-0" />
                        <span className="text-base small-caps">Print</span>
                    </ActionButton>
                </div>
                <PolicyView policyId={policyId} ref={decPageRef} />
            </div>
        </Dialog>
    );
}
