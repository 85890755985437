import { useAuth0 } from "@auth0/auth0-react";
import { classNames } from "@deathstar/ui";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { Link, Outlet, Route, Routes, useMatch } from "react-router-dom";
import logo from "../../assets/images/wp_logo.png";
import Footer from "../../features/footer/Footer";

export default function About() {
    const auth0 = useAuth0();
    const path = useMatch("/about/*")?.params["*"];
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(false);
    }, [path]);

    const footer = useRef<HTMLElement>(null);
    const [footerHeight, setFooterHeight] = useState<number | null>(null);
    useEffect(() => {
        if (footer.current) {
            setFooterHeight(footer.current.offsetHeight);
        }
    }, []);

    return (
        <Routes>
            <Route
                element={
                    <div className={classNames("flex h-screen flex-col", expanded && "overflow-hidden")}>
                        <div
                            className={classNames(
                                "sticky top-0 z-30 flex w-screen shrink-0 flex-col gap-2 overflow-hidden bg-stone-50 transition-all duration-300 lg:flex-row",
                                expanded ? "h-screen" : "h-16"
                            )}
                        >
                            <div className={classNames("flex w-full flex-row items-center justify-between gap-0 bg-stone-50 p-4")}>
                                <Link to="/">
                                    <img src={logo} width={120} alt="Waypoint logo" />
                                </Link>
                                <button onClick={() => setExpanded(!expanded)} className="text-waypoint-orange lg:hidden">
                                    <Bars3Icon className="h-7 w-7" />
                                </button>
                            </div>
                            <hr className="lg:hidden" />
                            <nav>
                                <ul className="w-max px-4 lg:flex lg:h-full lg:items-center lg:gap-8">
                                    <li className="lg:inline-block">
                                        <a
                                            className="text-stone-700 hover:text-blue-800"
                                            target="_blank"
                                            href="https://navigatortruckinsurance.com"
                                            rel="noreferrer"
                                        >
                                            Navigator Truck Insurance
                                        </a>
                                    </li>
                                    <li className="lg:inline-block">
                                        {auth0.isAuthenticated ? (
                                            <Link className="text-stone-700 hover:text-blue-800" to="/sol">
                                                Log in
                                            </Link>
                                        ) : (
                                            <button
                                                className="text-stone-700 hover:text-blue-800"
                                                onClick={() => auth0.loginWithRedirect()}
                                            >
                                                Log in
                                            </button>
                                        )}
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div
                            className={`grow overflow-y-auto`}
                            style={{ minHeight: `max(${footerHeight}px, calc(100vh - 64px - ${footerHeight}px))` }}
                        >
                            <Outlet />
                        </div>
                        <Footer ref={footer} />
                    </div>
                }
            ></Route>
        </Routes>
    );
}
